'use strict';

var BasePortsStaticView = require('actions/ports/StaticView');
var _ = require('lodash');

/**
 * Renders a text-only version of the "bond ports" component.
 */
module.exports = BasePortsStaticView.extend({
  /**
   * Overrides the supportsTagging property in the template context
   * as bonds are not networks, and tagged vlans would be set up in the vlan card.
   *
   * @return {Object}
   */
  templateContext: function() {
    var context = BasePortsStaticView.prototype.templateContext.apply(this);
    context.supportsTagging = false;
    return context;
  },

  /**
   * Template helper to return a formatted list of ports assigned to the bond.
   *
   * @method templateContext.formatPorts
   * @return {String}
   */
  formatPorts: function() {
    var ports = _.reduce(this.ports, function(memo, port) {
      if (port.isSelected === true) {
        memo.push(port.portNumber);
      }
      return memo;
    }, []);

    if (ports.length > 0) {
      return ports.join(', ');
    }

    return this.emptyVal();
  },

});
