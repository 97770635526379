'use strict';

var $ = require('jquery');
var Marionette = require('backbone.marionette');
var tpl = require('manage/configRestore/configuration.html');
var twig = require('twig').twig;
var moment = require('moment');
var Radio = require('backbone.radio');
var apiChannel = Radio.channel('apiChannel');
var buttonSpinner = require('lib/buttonSpinner');
var i18n = require('i18next');

module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  className: 'config-restore-row',

  templateContext: function() {
    return {
      name: this._getFormattedName(),
      date: this._getFormattedDate(),
      canRestore: this.canRestore !== false,
      canRemove: this.canRemove !== false,
    };
  },

  ui: {
    'name': '.config-name',
    'edit': '.edit-config',
    'save': '.save-config',
    'cancel': '.cancel-edit',
    'restore': '[name="restore"]',
    'remove': '[name="remove-config"]',
  },

  events: {
    'click @ui.edit': 'toggleState',
    'click @ui.cancel': 'toggleState',
    'click @ui.save': 'saveConfig',
    'click @ui.restore': 'restoreConfig',
    'click @ui.remove': 'removeConfig',
  },

  modelEvents: {
    'change:remove': 'render',
    'change': function() {
      if (this.model.get('disabled')) {
        this.ui.restore.prop('disabled', true);
      } else {
        this.ui.restore.prop('disabled', false);
      }
    },
  },

  initialize: function(options) {
    this.canRestore = options.canRestore;
    this.canRemove = options.canRemove;
  },

  toggleState: function() {
    this.ui.edit.toggleClass('hidden');
    this.ui.save.toggleClass('hidden');
    this.ui.cancel.toggleClass('hidden');
    this.ui.restore.toggle();
    this.ui.name.attr('readonly', !this.ui.name.attr('readonly'));
    this.ui.name.val(this._getFormattedName());
  },

  saveConfig: function() {
    var self = this;
    var oldName = this.model.get('name');
    var newName = this.ui.name.val();

    this.model.set('name', newName);

    buttonSpinner.showButtonSpinner(this.ui.save, 'dark');
    this.ui.cancel.hide();

    return apiChannel.request('send', 'DNA.Portal.ConfigRestore.saveMetadata', {
      configurationId: this.model.get('configId'),
      metadata: {
        name: newName,
      },
    }).then(function(resp) {
      if (resp.result !== true) {
        return $.Deferred().reject(resp); // treat success as failure based on flag
      }
      return resp;
    }).fail(function() {
      self.model.set('name', oldName);
      if (self.isAttached()) {
        self.ui.name.val(oldName);
      }
    }).always(function() {
      if (self.isAttached()) {
        self.toggleState();
        self.ui.cancel.show();
        buttonSpinner.hideButtonSpinner(self.ui.save);
      }
    });
  },

  restoreConfig: function() {
    var self = this;

    this.trigger('start:restore', this);
    buttonSpinner.showButtonSpinner(this.ui.restore);

    apiChannel.request('send', 'DNA.Portal.ConfigRestore.restoreConfig', {
      mac: this.model.collection.mac,
      configurationId: this.model.get('configId'),
    }).done(function() {
      window.location.reload();
    }).fail(function() {
      self._showRestoreFailure();
      self.trigger('end:restore');
    });
  },

  removeConfig: function() {
    var shouldRemove = !this.model.get('remove');
    this.model.set({remove: shouldRemove});
    this.trigger('remove:config', this);
  },

  _showRestoreFailure: function() {
    var self = this;

    this.ui.restore
    .addClass('failed')
    .text(i18n.t('configRestore.failed'));

    setTimeout(function() {
      self.ui.restore
      .removeClass('failed')
      .text(i18n.t('configRestore.restore'));

      buttonSpinner.hideButtonSpinner(self.ui.restore);
    }, 2000);
  },

  _getFormattedName: function() {
    return this.model.get('name') || this._getFormattedNameFromDate();
  },

  _getFormattedNameFromDate: function() {
    return moment.unix(this.model.get('timestamp')).format('l');
  },

  _getFormattedDate: function() {
    return moment.unix(this.model.get('timestamp')).format('l') +
      ' ' +
      moment.unix(this.model.get('timestamp')).format('LTS');
  },
});
