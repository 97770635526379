'use strict';

var _ = require('lodash');
var i18n = require('i18next');

/**
 * Collection of common functions used to help format the 'status card'
 * @return {Function}
 */

module.exports = {

  /**
   * Gets the status of a network interface
   *
   * @param {Backbone.Model} statusCard
   * @param {String} iface
   * @return {String}
   */
  networkInterfaceStatus: function(statusCard, iface) {
    var active = false;
    var lteSQ;

    if (statusCard.get('connected') === false) {
      // If we are not connected at all, no interface can possibly be up
      return 'unavailable';
    }

    if (statusCard.has('activeWan')) {
      active = statusCard.get('activeWan') === iface;
    }

    if (iface === 'lte') {
      lteSQ = statusCard.get('signalQuality') > 0;

      if (active && lteSQ) {
        return 'active';
      }

      // TODO verify that all states are handled correctly here
      if (!active && statusCard.get('lteState') === 'connected') {
        return 'standby';
      }

      return statusCard.get('lteState');
    }

    if (active) {
      return 'active';
    }

    var primaryWan = statusCard.get('primaryWan');
    if (primaryWan.id === iface
        && primaryWan.up
        && !primaryWan.hasInternet) {
      return 'degraded';
    }
    var secondaryWan = statusCard.get('secondaryWan');
    if (secondaryWan.id === iface
        && secondaryWan.up
        && !secondaryWan.hasInternet) {
      return 'degraded';
    }

    if (this.isWanUp(statusCard, iface)) {
      if (statusCard.get('loadBalanceVlans') && _.contains(statusCard.get('loadBalanceVlans'), iface)) {
        return 'active';
      }
      return 'standby';
    }

    return 'unavailable';
  },

  /**
   * @param {Backbone.Model} statusCard
   * @return {String}
   */
  getWifiText: function(statusCard) {
    if (statusCard.get('wifiStatus') === 'disabled') {
      return i18n.t('healthWidget.disabled');
    }

    return i18n.t('healthWidget.connected', {
      count: statusCard.get('connectedDevicesWifi'),
    });
  },

  /**
   * @param {Backbone.Model} statusCard
   * @param {Boolean} vlanConflict
   * @return {String}
   */
  getLanText: function(statusCard, vlanConflict) {
    if (vlanConflict === true) {
      return i18n.t('healthWidget.error');
    }

    return i18n.t('healthWidget.connected', {
      count: statusCard.get('connectedDevices'),
    });
  },

  /**
   * This method exists merely to satisfy the i18next-scanner
   * by having all the explicit translation
   *
   * @param {String} status
   * @param {Boolean} shortVersion
   * @return {String}
   */
  getWANText: function(status, shortVersion) {
    switch (status) {
      case 'active':
        return i18n.t('healthWidget.active');
      case 'standby':
        return i18n.t('healthWidget.standby');
      case 'degraded':
        return i18n.t('healthWidget.degraded');
      case 'unavailable':
        return i18n.t('healthWidget.unavailable');
      case 'enabling':
      case 'connecting':
      case 'rebooting':
      case 'disconnected':
        return i18n.t('healthWidget.connecting');
      case 'incompatible_carriers':
        return shortVersion ? i18n.t('healthWidget.error') : i18n.t('healthWidget.incompatible_carriers');
      case 'no_carriers':
        return shortVersion ? i18n.t('healthWidget.error') : i18n.t('healthWidget.no_carriers');
      case 'airplane_mode':
        return shortVersion ? i18n.t('healthWidget.error') : i18n.t('healthWidget.airplane_mode');
      case 'scan_carriers':
        return shortVersion ? i18n.t('healthWidget.scanShortVersion') : i18n.t('healthWidget.scan_carriers');
      case 'persistent_error':
      case 'modem_unresponsive':
        return shortVersion ? i18n.t('healthWidget.error') : i18n.t('healthWidget.critical_error');
      case 'in_error':
        return i18n.t('healthWidget.in_error');
      default:
        return i18n.t('healthWidget.unknown');
    }
  },

  /**
   * @param {Backbone.Model} statusCard
   * @return {Number}
   */
  cellularQuality: function(statusCard) {
    if (statusCard.get('connected') === false) {
      return 0;
    }

    var quality = statusCard.get('signalQuality') || 0;

    // note, the backend now reports signal as only 0, 24, 74 or 100.

    if (quality && quality > 0) {
      if (quality <= 24) {
        return 1;
      } else if (quality >= 25 && quality <= 74) {
        // this range encompasses both 2 and 3 bars
        return 3;
      } else if (quality >= 75) {
        return 4;
      }
    }

    return 0;
  },

  /**
   * @param {Backbone.Model} statusCard
   * @return {String}
   */
  getWifiStatusClass: function(statusCard) {
    if (statusCard.get('connected') === false) {
      return 'wifi-down';
    }

    return 'wifi-' + statusCard.get('wifiStatus'); // up, down or disabled
  },

  /**
   * @param {Backbone.Model} statusCard
   * @param {Boolean} vlanConflict
   * @return {String}
   */
  getLanStatusClass: function(statusCard, vlanConflict) {
    if (statusCard.get('connected') === false) {
      return 'lan-down';
    }

    if (statusCard.get('lanUp') === true && !vlanConflict) {
      return 'lan-up';
    }

    return 'lan-down';
  },

  /**
   * Checks if the passed primary or secondary WAN is up.
   *
   * @param {Backbone.Model} statusCard
   * @param {String} iface
   * @return {Boolean}
   */
  isWanUp: function(statusCard, iface) {
    var wans = statusCard.pick('primaryWan', 'secondaryWan');
    return _.some(wans, function(wan) {
      return wan.id === iface && wan.up === true;
    });
  },
};
