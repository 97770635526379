'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var ActionItem = require('actions/shared/ActionItem');
var networkUtils = require('lib/network');

/**
 * View-model representing the custom mac address for WANs.
 */
module.exports = ActionItem.extend({
  /**
   * @name actions/wanCustomMac/EditWanCustomMac#attributes
   * @type {Object}
   * @property {String} id
   *   The interface name (e.g. vlan40)
   * @property {String} customMac
   */

  _snapshotAttributes: ['customMac', 'pendingDelete'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    var vlan = this.deviceConfig.get('networks').get(this.id);
    return _.isUndefined(vlan) || vlan.isNew();
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   *   The modified response.
   */
  parse: function(resp, options) {
    if (options && options.fromConfig && !this.isNew()) {
      var vlan = this.deviceConfig.get('networks').get(this.id);

      return {
        customMac: vlan.get('customMac'),
      };
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks').get(this.id);
      },
      events: 'change:customMac',
    },
  ],

  validate: function(attrs, options) {
    var errors = {};
    var vlanId = this.id;
    var vlans = this.deviceConfig.getExternalVlans();

    var otherWan = vlans.find(function(network) {
      return network.id !== vlanId;
    });

    if (_.has(attrs, 'customMac')) {
      if (!networkUtils.validMacAddress(attrs.customMac)) {
        errors.customMac = i18n.t('actionWanCustomMac.invalidMac');
      }

      if (networkUtils.isMulticastMacAddress(attrs.customMac)) {
        errors.customMac = i18n.t('actionWanCustomMac.unsupportedMac');
      }

      if (otherWan.get('customMac') === attrs.customMac) {
        errors.customMac = i18n.t('actionWanCustomMac.duplicateMac');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object}
   */
  getTask: function() {
    var data = {
      customMac: this.get('customMac'),
    };

    if (!this.isNew()) {
      data.id = this.id;
    }

    if (this.get('pendingDelete') === true) {
      data.customMac = null;
    }

    return {
      name: 'vlan.create',
      data: data,
    };
  },

 /**
   * Returns the message to use when custom MAC is being removed.
   *
   * @see actions/shared/removeConfig/RemoveConfig
   * @see manage/edit/config/action/ActionView
   * @return {String}
   */
  getDeleteMsg: function() {
    return i18n.t('actionWanCustomMac.taskDeleteCustomMac');
  },
});
