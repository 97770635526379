'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var console2 = require('lib/Console');
var LogMessage = require('lib/models/LogMessage');
var ConfigAction = require('config/Action');

/**
 * Collection of {@link config/Action|config items}.
 */
module.exports = Backbone.Collection.extend({
  /**
   * @type {config/Action}
   */
  model: ConfigAction,

  /**
   * @param {Object} options
   * @property {Boolean} options.caching
   *   Set to True if saving configuration state (for restoring later).
   * @return {Array}
   */
  toJSON: function(options) {
    var attrs = ['deviceMac', 'id'];

    if (options && options.caching === true) {
      return this.map(function(actionItem) {
        var data = actionItem.pick(attrs);
        data.actionModelCache = actionItem.get('actionModel').toJSON(options);
        delete data.actionModelCache.actionTitle;
        return data;
      });
    }

    return Backbone.Collection.prototype.toJSON.apply(this, arguments);
  },

  /**
   * Returns the list of action tasks.
   *
   * @throws Error
   *   Thrown if an action returns an empty task entity.
   * @param {Array} skipTasksWithNoChange
   *   Tasks to skip if all source actions have no changes
   * @see {config/Group} #skipTasksWithNoChange
   * @return {Array}
   */
  getTasks: function(skipTasksWithNoChange) {
    // convert skip list to a map where all items are set to be skipped initially
    // ['foo', 'bar'] => {foo: true, bar: true}
    var skipList = skipTasksWithNoChange || [];
    var initial = _.fill(new Array(skipList.length), true);
    var tasksToSkip = _.zipObject(skipList, initial);

    // create a reducer that will receive the tasksToSkip list as an extra third arg
    var collectTasks = _.partial(this._collectTasksForAction, _, _, tasksToSkip);

    return this
      .reduce(collectTasks, [], this)
      .filter(function(task) {
        // allow through all tasks not still set to be skipped
        return tasksToSkip[task.name] !== true;
      });
  },

  /**
   * Collects valid tasks from a given action model and updates a provided list
   * of tasks to skip based on whether their source action(s) had changes
   *
   * @param {Array} accumulator
   * @param {config/Action} actionConfigModel
   *  The action from which to collection tasks
   * @param {Object} taskSkipList
   *  Map of task name => skip status
   * @returns {Array} updated accumulator
   * @private
   */
  _collectTasksForAction: function(accumulator, actionConfigModel, taskSkipList) {
    var actionEditModel = actionConfigModel.get('actionModel');
    var tasks;
    var partitioned;

    if (!actionEditModel.providesTask) {
      return accumulator;
    }

    tasks = actionEditModel.getTask();

    if (!_.isArray(tasks)) {
      tasks = [tasks];
    }

    // winnow out empty tasks
    partitioned = _.partition(tasks, _.isEmpty);
    this._sanityCheckEmptyTasks(partitioned[0], actionConfigModel);
    tasks = partitioned[1];

    // if a changed action was associated with these tasks, exempt them from the skip list
    if (actionConfigModel.actionHasChanges()) {
      tasks.forEach(function(task) {
        taskSkipList[task.name] = false;
      });
    }

    return accumulator.concat(tasks);
  },

  /**
   * Checks whether a provided set of empty tasks might represent unexpected
   * application behavior based on the state of their source action
   *
   * @param {Array} tasks
   *  Empty tasks to sanity check
   * @param {config/Action} actionConfigModel
   *  The source action model for these tasks
   * @private
   */
  _sanityCheckEmptyTasks: function(tasks, actionConfigModel) {
    var actionEditModel = actionConfigModel.get('actionModel');
    tasks.forEach(function(task) {
      if (actionConfigModel.actionHasChanges()) {
        var serialized = JSON.stringify(actionConfigModel.pick('deviceMac', 'id'));
        var serializedAction = JSON.stringify(actionEditModel, null, 2);
        console2.log('warn', 'Action with changes yielded empty task ', serializedAction);
        (new LogMessage({
          file: 'config/Actions.js',
          level: 'WARNING',
          message: 'Action with changes yielded empty task! ' + serialized,
        })).save();
      }
    });
  },

});
