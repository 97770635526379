'use strict';

var i18n = require('i18next');
var EditGlobalWifi = require('actions/router/wifi/EditWifi');
var FormView = require('actions/router/wifi/FormView');
var StaticView = require('actions/router/wifi/StaticView');

/**
 * Action configuration for the Router WiFi settings action component.
 */
module.exports = {
  title: i18n.t('actionGlobalWifi.title'),
  Model: EditGlobalWifi,
  FormView: FormView,
  TextView: StaticView,
};
