'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/wanPppoe/static.html');
var twig = require('twig').twig;

/**
 * Renders a text-only version of the "PPPoE" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/wanPppoe/StaticView#model
   * @type {actions/wanPppoe/EditWanPppoe}
   */

  template: twig({data: tpl}),
});
