'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/trafficPolicyRules/staticRule.html');
var i18n = require('i18next');
var twig = require('twig').twig;

/**
 * Renders a text-only version of an individual traffic policy rule.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/trafficPolicyRules/StaticRuleView#model
   * @type {actions/trafficPolicyRules/EditTrafficPolicyRule}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      protocolText: this._protocolText,
      actionText: this._actionText,
      descriptionText: this._descriptionText,
    });
  },

  /**
   * Format model's protocol value for display
   *
   * @returns {String}
   * @private
   */
  _protocolText: function() {
    var valueMap = {
      tcp: i18n.t('trafficPolicy.protocolType.tcp'),
      udp: i18n.t('trafficPolicy.protocolType.udp'),
      tcpudp: i18n.t('trafficPolicy.protocolType.tcpudp'),
      icmp: i18n.t('trafficPolicy.protocolType.icmp'),
      udplite: i18n.t('trafficPolicy.protocolType.udplite'),
      esp: i18n.t('trafficPolicy.protocolType.esp'),
      ah: i18n.t('trafficPolicy.protocolType.ah'),
      sctp: i18n.t('trafficPolicy.protocolType.sctp'),
      ospf: i18n.t('trafficPolicy.protocolType.ospf'),
      all: i18n.t('trafficPolicy.protocolType.all'),
      other: i18n.t('trafficPolicy.protocolType.other'),
    };

    var protocolDisplayText = valueMap[this.proto];
    if (this.proto === 'other') {
      protocolDisplayText = i18n.t('trafficPolicy.protocolType.otherWithColon', {protocolNumber: this.protocolNumber});
    }

    return protocolDisplayText;
  },

  /**
   * Format model's description selection for display
   *
   * @returns {String}
   * @private
   */
  _descriptionText: function() {
    if (this.description) {
      if (this.description.length > 8) {
        return (this.description.substring(0, 7) + '...');
      }
    }
    return this.description;
  },

  /**
   * Format model's action selection for display
   *
   * @returns {String}
   * @private
   */
  _actionText: function() {
    if (this.action) {
      return this.action.toLowerCase();
    }
  },

});
