'use strict';

var i18n = require('i18next');
var EditWanPppoe = require('actions/wanPppoe/EditWanPppoe');
var FormView = require('actions/wanPppoe/FormView');
var StaticView = require('actions/wanPppoe/StaticView');

/**
 * Action configuration for the WAN PPPoE action component.
 */
module.exports = {
  title: i18n.t('actionWanPppoe.title'),
  Model: EditWanPppoe,
  FormView: FormView,
  TextView: StaticView,
};
