'use strict';

var i18n = require('i18next');
var EditLanPolicy = require('actions/lanPolicy/LanPolicy');
var FormView = require('actions/lanPolicy/FormView');
var StaticView = require('actions/lanPolicy/StaticView');

/**
 * Action configuration for lan policy
 */
module.exports = {
  title: i18n.t('actionLanPolicy.title'),
  Model: EditLanPolicy,
  FormView: FormView,
  TextView: StaticView,
};
