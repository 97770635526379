'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');

/**
 * A sort of state respsonse system for a {lib/model/Job} Model
 * in the {lib/collection/Jobs} Collection
 *
 * Any Job whose state is tracked through the EventStream should
 * use this behavior to handle success and failure status changes
 *
 * This behavior also fires up and burns down the appropriate listeners
 * for jobs that are added or removed after the initial RPC call
 * that populates the {lib/collections/Jobs} Collection
 */
module.exports = Marionette.Behavior.extend({
  defaults: {
    onFailure: function() {
      return this.onFailure();
    },

    onSuccess: function() {
      return this.toggleSpinner();
    },

    getJobs: function() {
      return this.model.get('jobs');
    },
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    if (_.isFunction(this.options.onFailure)) {
      this.options.onFailure = this.options.onFailure.bind(this.view);
    }

    if (_.isFunction(this.options.onSuccess)) {
      this.options.onSuccess = this.options.onSuccess.bind(this.view);
    }

    if (_.isFunction(this.options.getJobs)) {
      this.options.getJobs = this.options.getJobs.bind(this.view.options);
    }

    var jobs = this.options.getJobs();

    this.setupChangeListener();
    this.listenTo(jobs, 'add', this.onJobAdd);
    this.listenTo(jobs, 'remove', this.onJobRemove);
  },

  setupChangeListener: function() {
    var jobs = this.options.getJobs();
    var job = jobs.getJobOfType(this.options.jobType);

    if (job) {
      this.listenTo(job, 'change:status', this.onStatusChange);
    }
  },

  onJobAdd: function(job) {
    if (job.get('jobType') === this.options.jobType) {
      this.setupChangeListener();
      this.onStatusChange(job, job.get('status'));
    }
  },

  onJobRemove: function(job) {
    this.stopListening(job);
  },

  onStatusChange: function(model, value) {
    if (value === 'failed') {
      this.options.onFailure();
    } else if (value === 'applied' || value === 'pending' || value === 'applying') {
      this.options.onSuccess();
      if (value === 'applied' && _.isFunction(this.view.onApplied)) {
        this.view.onApplied();
      }
    }

    if (model.isDone()) {
      model.stopTimeout();
    }
  },
});
