'use strict';

var Backbone = require('backbone');
var DataColors = require('components/usageShared/DataColors');

/**
 * Collection of classified apps or clients with usage info
 */
module.exports = Backbone.Collection.extend({
  /**
   * @name {Backbone.Model} model
   * @property {String} name
   * @property {String} type
   * @property {Number} bytes
   */

  /**
   * Formats collection contents as a chart data object
   *
   * @return {Object}
   */
  getChartData: function() {
    // it's a bit unusual to have visual stuff like colors
    // defined in a model, but in this case the colors are
    // closely tied to the definition of the data set
    var itemColors = this.map(function(app, index) {
      if (app.get('name') === 'other') {
        return DataColors.otherColor;
      }
      return DataColors.colorForIndex(index);
    });

    return {
      datasets: [
        {
          data: this.pluck('bytes'),
          backgroundColor: itemColors,
          hoverBackgroundColor: itemColors,
          borderWidth: 0,
        },
      ],
    };
  },

});
