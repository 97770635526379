'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var Entities = require('html-entities').XmlEntities;
var entities = new Entities();

/**
 * Edit model for Load Balance.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/loadBalance/EditLoadBalance#attributes
   * @property {boolean} enabled
   * @property {string} wan1Load
   * @property {string} wan2Load
   *  enabled or disabled
   */

  _snapshotAttributes: ['enabled', 'wan1Load', 'wan2Load'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  getOnOffState: function() {
    return !!this.get('enabled');
  },

  /**
   * Parse response for IP.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var loadBalance = this.deviceConfig.get('loadBalance');
      var primary;
      if (this.deviceConfig.get('networks').get('vlan3').get('wanPriority') === 1) {
        primary = 'vlan3';
      } else {
        primary = 'vlan1';
      }

      return {
        enabled: loadBalance.get('enabled'),
        wan1Load: loadBalance.get('wan1Load'),
        wan2Load: loadBalance.get('wan2Load'),
        wan1Name: entities.decode(this.deviceConfig.get('networks').get('vlan1').getName()),
        wan2Name: entities.decode(this.deviceConfig.get('networks').get('vlan3').getName()),
        primary: primary,
      };
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('loadBalance');
      },
      events: 'change',
    },
    {
      getDispatcher: function(config) {
        return config.get('networks').get('vlan1');
      },
      events: 'change:description change:wanPriority',
    },
    {
      getDispatcher: function(config) {
        return config.get('networks').get('vlan3');
      },
      events: 'change:description change:wanPriority',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};
    if (attrs.enabled === false) {
      return;
    }
    // Since both percentages are set at the same time, we don't know
    // which one the user updated, so we put any errors on wan1Load but
    // display it as a groupedFieldError.
    if (attrs.enabled === true || this.get('enabled') === true) {
      if (attrs.wan1Load !== undefined) {
        var load1 = Number(attrs.wan1Load);
        if (load1 !== parseInt(load1) || load1 < 1 || load1 > 99) {
          return {'wan1Load': i18n.t('actionLoadBalance.invalidLoad')};
        }
      }
      if (attrs.wan2Load !== undefined) {
        var load2 = Number(attrs.wan2Load);
        if (load2 !== parseInt(load2) || load2 < 1 || load2 > 99) {
          return {'wan1Load': i18n.t('actionLoadBalance.invalidLoad')};
        }
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    return {
      name: 'loadBalance.edit',
      data: {
        enabled: this.get('enabled'),
        wan1Load: this.get('wan1Load'),
        wan2Load: this.get('wan2Load'),
      },
    };
  },
});
