'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/vpn/routes/static.html');
var twig = require('twig').twig;

/**
 * Look up names for allowed networks and combine as a string
 *
 * @param {Array} allowed
 *  list of ids for allowed networks
 * @param {Array} known
 *  list of known networks (as lib/models/Network objects)
 * @returns {String}
 */
var formatAllowedLans = function(allowed, known) {
  return known
    .filter(function(lan) {
      return _.includes(allowed, lan.id);
    })
    .map(function(lan) {
      return lan.get('description');
    })
    .join(', ') || i18n.t('actionVpn.noneSelected');
};

/**
 * Renders a text-only version of the "VPN Allow Access To" (Routes) component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/vpn/routes/StaticView#model
   * @type {actions/vpn/routes/EditVpnRoutes
   */

  template: twig({data: tpl}),

  modelEvents: {
    // re-render on any config re-parse even if this model didn't change,
    // because available LANs may have changed
    'sync:fromConfig': 'render',
  },

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      allowedLansText: this._allowedLansText,
      hostAccessText: this._hostAccessText.bind(this),
      // utility methods; should not be used in templates
      _getAllLans: this.getAllLans.bind(this),
    });
  },

  /**
   * @return {string}
   * @private
   */
  _hostAccessText: function() {
    var text = '';
    if (this.model.has('hostAccessEnabled')) {
      if (this.model.get('hostAccessEnabled')) {
        text = i18n.t('actionVpn.allRemoteSites');
      } else {
        text = i18n.t('actionVpn.noneSelected');
      }
    }

    return text;
  },

  /**
   * Format model's allowed networks value for display
   *
   * @returns {String}
   * @private
   */
  _allowedLansText: function() {
    var lans = this._getAllLans();

    return formatAllowedLans(this.allowedNetworks, lans);
  },

  /**
   * Get all available LANs
   *
   * @returns {Array}
   */
  getAllLans: function() {
    return this.model.deviceConfig.getVlans({external: false});
  },
});
