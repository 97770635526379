'use strict';

var Marionette = require('backbone.marionette');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/alertConfiguration/static.html');
var twig = require('twig').twig;

var SubscriptionStaticView = require('actions/alertConfiguration/subscriber/StaticSubscriberView');

var SubscriptionsListView = Marionette.CollectionView.extend({
  childView: SubscriptionStaticView,
  filter: function(child, index) {
    return index >= 0 && index < 5;
  },
});

module.exports = BaseStaticView.extend({
  template: twig({data: tpl}),

  regions: {
    subs: '.rg-subscriptions',
  },

  onRender: function() {
    this.initChildViews();
  },

  initChildViews: function() {
    this.showChildView('subs', new SubscriptionsListView({
      collection: this.model.get('subscribers'),
    }));
  },
});
