'use strict';

/**
 * Registry of action and group configurations for building and rendering the
 * workbench page.
 */

module.exports = {
  actions: {
    dhcpPool: require('actions/dhcpPool/config'),
    dhcpRelay: require('actions/dhcpRelay/config'),
    dhcpAdvOptions: require('actions/dhcpAdvOptions/config'),
    editWifi: require('actions/wifi/config'),
    hostname: require('actions/router/hostname/config'),
    lanAddress: require('actions/staticAddress/lan/config'),
    lanPorts: require('actions/ports/lan/config'),
    ntp: require('actions/router/ntp/config'),
    timezone: require('actions/router/timezone/config'),
    vlanDescription: require('actions/description/vlan/config'),
    dhcpReservations: require('actions/dhcpReservations/config'),
    lanAllowVpn: require('actions/lanAllowVpn/config'),
    wanAddress: require('actions/staticAddress/wan/ipv4/config'),
    wanAddressIpv6: require('actions/staticAddress/wan/ipv6/config'),
    wanCustomMac: require('actions/wanCustomMac/config'),
    wanDns: require('actions/wanDns/config'),
    wanPorts: require('actions/ports/wan/config'),
    wanPriority: require('actions/wanPriority/config'),
    wanMTU: require('actions/wanMTU/config'),
    wanProtocol: require('actions/wanProtocol/config'),
    wanPppoe: require('actions/wanPppoe/config'),
    wifiDescription: require('actions/description/wifi/config'),
    siteVpnDescription: require('actions/description/siteVpn/config'),
    wifiGlobal: require('actions/router/wifi/config'),
    portForwardingRules: require('actions/portForwardingRules/config'),
    trafficPolicyRules: require('actions/trafficPolicyRules/config'),
    macFilter: require('actions/macFilter/config'),
    dmz: require('actions/dmz/config'),
    vpnEnable: require('actions/vpn/enable/config'),
    vpnEnableOpenVpn: require('actions/vpn/enableOpenVpn/config'),
    vpnAddress: require('actions/staticAddress/vpn/config'),
    vpnRoutes: require('actions/vpn/routes/config'),
    vpnAuth: require('actions/vpn/auth/config'),
    vpnUsers: require('actions/vpn/users/config'),
    intrusionDetection: require('actions/intrusionDetection/config'),
    editSiteVpn: require('actions/siteVpn/config'),
    lanIsolationGlobal: require('actions/lanIsolationGlobal/config'),
    webFilters: require('actions/webFilters/config'),
    domainFilters: require('actions/domainFilters/config'),
    userTimezone: require('actions/router/userTimezone/config.js'),
    updateSchedule: require('actions/router/updateSchedule/config.js'),
    siteToSiteRekeyTime: require('actions/router/siteToSiteRekeyTime/config.js'),
    oneoneNat: require('actions/oneoneNat/config'),
    onemanyNat: require('actions/onemanyNat/config'),
    outboundNat: require('actions/outboundNat/config'),
    advancedNat: require('actions/advancedNat/config'),
    trafficShaping: require('actions/trafficShaping/config'),
    conditionalDns: require('actions/conditionalDns/config'),
    alertConfiguration: require('actions/alertConfiguration/config'),
    staticHostMapping: require('actions/staticHostMapping/config'),
    editNetworkObject: require('actions/networkObject/config'),
    networkObjectDescription: require('actions/description/networkObject/config'),
    staticRoutes: require('actions/staticRoutes/config'),
    bondDescription: require('actions/description/bond/config'),
    bondPorts: require('actions/ports/bond/config'),
    lteDns: require('actions/lteDns/config'),
    wanDnsPlaceholder: require('actions/wanDnsPlaceholder/config'),
    loadBalance: require('actions/loadBalance/config'),
    lanPolicy: require('actions/lanPolicy/config'),
    speedDuplexSettings: require('actions/speedDuplexSettings/config'),
    failoverPolicyControl: require('actions/failoverPolicyControl/config'),
    webFiltersTitanHqEnable: require('actions/webFiltersTitanHq/enable/config'),
    titanHqCategories: require('actions/webFiltersTitanHq/category/config'),
    titanHqSettings: require('actions/webFiltersTitanHq/settings/config'),
    dnaDescription: require('actions/description/dna/config'),
    operatorPing: require('actions/operatorPing/config'),
    traceroute: require('actions/traceroute/config'),
    dnsLookup: require('actions/dnsLookup/config'),
    wanWebFilterDns: require('actions/wanWebFilterDns/config'),
    domainFiltersTitanHq: require('actions/domainFiltersTitanHq/config'),
    globalDns: require('actions/globalDns/config'),
    configClone: require('actions/configClone/config'),
  },
  groups: {
    globalDns: require('actionGroups/globalDns/config'),
    createLan: require('actionGroups/createLan/config'),
    createWifi: require('actionGroups/createWifi/config'),
    createSiteVpnGroup: require('actionGroups/createSiteVpnGroup/config'),
    editLan: require('actionGroups/editLan/config'),
    editWan: require('actionGroups/editWan/config'),
    editWifi: require('actionGroups/editWifi/config'),
    router: require('actionGroups/router/config'),
    wifiGlobal: require('actionGroups/wifiGlobal/config'),
    portForwarding: require('actionGroups/portForwarding/config'),
    trafficPolicy: require('actionGroups/trafficPolicy/config'),
    dmz: require('actionGroups/dmz/config'),
    vpn: require('actionGroups/vpn/config'),
    intrusionDetection: require('actionGroups/intrusionDetection/config'),
    editSiteVpn: require('actionGroups/editSiteVpn/config'),
    lanIsolation: require('actionGroups/lanIsolation/config'),
    webFilters: require('actionGroups/webFilters/config'),
    webFiltersTitanHq: require('actionGroups/webFiltersTitanHq/config'),
    oneoneNat: require('actionGroups/oneoneNat/config'),
    onemanyNat: require('actionGroups/onemanyNat/config'),
    outboundNat: require('actionGroups/outboundNat/config'),
    advancedNat: require('actionGroups/advancedNat/config'),
    conditionalDns: require('actionGroups/conditionalDns/config'),
    alertConfiguration: require('actionGroups/alertConfiguration/config'),
    staticHostMapping: require('actionGroups/staticHostMapping/config'),
    createNetworkObject: require('actionGroups/createNetworkObject/config'),
    editNetworkObject: require('actionGroups/editNetworkObject/config'),
    staticRoutes: require('actionGroups/staticRoutes/config'),
    createNicBond: require('actionGroups/createNicBond/config'),
    editNicBond: require('actionGroups/editNicBond/config'),
    editLte: require('actionGroups/editLte/config'),
    loadBalance: require('actionGroups/loadBalance/config'),
    portConfigurationOverride: require('actionGroups/portConfigurationOverride/config'),
    failoverPolicyControl: require('actionGroups/failoverPolicyControl/config'),
    operatorPing: require('actionGroups/operatorPing/config'),
    traceroute: require('actionGroups/traceroute/config'),
    dnsLookup: require('actionGroups/dnsLookup/config'),
    configClone: require('actionGroups/configClone/config'),
  },
};
