'use strict';

var ActionItem = require('actions/shared/ActionItem');

module.exports = ActionItem.extend({

  _snapshotAttributes: ['enabled'],

  defaults: function() {
    return {
      enabled: false,
    };
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  getOnOffState: function() {
    return !!this.get('enabled');
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('webFiltersTitanHq');
      },
      events: 'change:enabled',
    },
  ],

  /**
   * Parse response for configuration.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var webFilters = this.deviceConfig.get('webFiltersTitanHq');

      if (webFilters) {
        return {
          enabled: webFilters.get('enabled'),
        };
      }

      return this.defaults();
    }

    return resp;
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    return {
      name: 'webFiltersTitanHq.setOptions',
      data: {
        enabled: this.get('enabled'),
      },
    };
  },
});
