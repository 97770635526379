'use strict';

var i18n = require('i18next');
var EditSiteVpn = require('actions/siteVpn/EditSiteVpn');
var FormView = require('actions/siteVpn/FormView');
var StaticView = require('actions/siteVpn/StaticView');

/**
 * Action configuration for the Site to Site VPN action component.
 */
module.exports = {
  title: i18n.t('actionSiteVpn.title'),
  Model: EditSiteVpn,
  FormView: FormView,
  TextView: StaticView,
};
