'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/intrusionDetection/static.html');
var twig = require('twig').twig;
var i18n = require('i18next');
var tplHelpers = require('lib/tplHelpers');

/**
 * Renders a text-only version of the "intrusionDetection" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/intrusionDetection/StaticView#model
   * @type {actions/intrusionDetection/EditIntrusionDetection}
   */

  template: twig({data: tpl}),

  ui: {
    prevention: '.intrusion-detection-prevention',
    sensitivity: '.intrusion-detection-sensitivity',
    inspectionLimit: '.intrusion-detection-inspection-mode',
  },

  onRender: function() {
    this.hideDisabled();
  },

  hideDisabled: function() {
    if (!this.model.get('enabled')) {
      this.ui.prevention.addClass('hidden');
      this.ui.sensitivity.addClass('hidden');
      this.ui.inspectionLimit.addClass('hidden');
    }
  },

  templateContext: function() {
    var prevention = this.model.get('prevention');
    var sensitivity = this.model.get('sensitivity');
    var inspectionLimit = this.model.get('inspectionLimit');

    var context = {
      detectOrPrevent: prevention ?
      i18n.t('actionIntrusionDetection.detectAndPrevent') : i18n.t('actionIntrusionDetection.detectOnly'),
      sensitivity: (sensitivity === 'low') ?
      i18n.t('actionIntrusionDetection.highRiskOnly') : i18n.t('actionIntrusionDetection.allEvents'),
      packetFlow: (inspectionLimit === '0') ?
      i18n.t('actionIntrusionDetection.packet') : i18n.t('actionIntrusionDetection.flow'),
    };
    _.extend(context, tplHelpers.apply(this));
    return context;
  },
});
