'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/configClone/form.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
var DNARecordView = require('actions/configClone/DNARecord/DNARecordView');

var DNARecordListView = Marionette.CollectionView.extend({
  childView: DNARecordView,
});

/**
 * Renders the "Configuration Clone" form.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/configClone/FormView#model
   * @type {actions/configClone/ConfigClone}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
  ],

  template: twig({data: tpl}),

  regions: {
    dnaRecords: '.rg-dna-record',
  },

  ui: {
    availableDNAs: '[name="availableDNA"]',
  },

  events: {
    'click @ui.availableDNAs': 'toggleDNAChosen',
  },

  /**
   * Provide necessary data to render template.
   * @returns {Object}
   */
  templateContext: function() {
    return _.extend(tplHelpers.apply(this), {
      deviceCount: this.model.get('items').length,
    });
  },

  /**
   * Initialize the DNA entry views.
   */
  initChildViews: function() {
    this.showChildView('dnaRecords', new DNARecordListView({
      collection: this.model.get('items'),
    }));
  },

  /**
   * Initialize the child views on render of view.
   */
  onRender: function() {
    this.initChildViews();
  },
});
