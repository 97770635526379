'use strict';

var MultiActionItem = require('actions/shared/MultiActionItem');
var EditUser = require('actions/vpn/users/User');

/**
 * Edit model for VPN's "Users" settings.
 */
module.exports = MultiActionItem.extend({

  collectionModel: EditUser,

  /**
   * Overridden to always return false (VPN is a fixed group)
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  toggledOnOffBy: 'vpnEnable',

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var users = this.deviceConfig.get('vpnUsers');
      return {
        items: users ? users.toJSON() : [],
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('vpnUsers');
      },
      events: 'add remove change',
    },
  ],

});
