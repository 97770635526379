'use strict';

var MultiActionItem = require('actions/shared/MultiActionItem');
var EditNat = require('actions/outboundNat/Nat');

module.exports = MultiActionItem.extend({

  collectionModel: EditNat,

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var natRules = this.deviceConfig.get('outboundNat').toJSON();
      var secondaryIps = this._getSecondaryIps();

      return {
        items: natRules,
        secondaryIps: secondaryIps,
      };
    }

    return resp;
  },

  _getSecondaryIps: function() {
    var secondaryIps = [];
    var vlans = this.deviceConfig.getExternalVlans();

    vlans = vlans.filter(function(network) {
      return network.get('secondaryIps') !== undefined;
    });

    vlans.forEach(function(network) {
      secondaryIps = secondaryIps.concat(network.get('secondaryIps'));
    });

    return secondaryIps;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('outboundNat');
      },
      events: 'add remove change',
    },
    {
      getDispatcher: function(config) {
        return config.get('networks');
      },
      events: 'change:secondaryIps',
    },
  ],
});
