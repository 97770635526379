'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/webFilters/static.html');
var twig = require('twig').twig;
var i18n = require('i18next');

/**
 * Renders a text-only version of the "Web Filters" component.
 *
 * Localized content category names:
 * i18n.t('actionWebFilters.category.content_malicious')
 * i18n.t('actionWebFilters.category.content_porn')
 * i18n.t('actionWebFilters.category.content_ads')
 * i18n.t('actionWebFilters.category.content_dating')
 * i18n.t('actionWebFilters.category.content_piracy')
 * i18n.t('actionWebFilters.category.content_proxies')
 * i18n.t('actionWebFilters.category.content_social')
 * i18n.t('actionWebFilters.category.content_gambling')
 * i18n.t('actionWebFilters.category.content_illegal')
 * i18n.t('actionWebFilters.category.content_pharma')
 * i18n.t('actionWebFilters.category.content_racism')
 *
 * DO NOT REMOVE THE COMMENT ABOVE - it's required for `grunt i18next`
 * to leave these names in the translation files!!
 */
module.exports = BaseStaticView.extend({
  /**
   * @name #model
   * @type {actions/webFilters/EditWebFilters}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      formatEnabledCategories: this._formatEnabledCategories,
    });
  },

  /**
   * Formats list of enabled filter categories into a text string
   *
   * @param {Array} categories
   *  Categories list from #model
   * @return {String}
   * @private
   */
  _formatEnabledCategories: function(categories) {
    var formatted = _.chain(categories)
      .filter({enabled: true})
      .map(function(category) {
        return i18n.t('actionWebFilters.category.' + category.id);
      })
      .value() // end _.chain()
      .sort()
      .join(i18n.t('general.listSeparator'));

    return formatted || i18n.t('configEdit.fieldNoValues');
  },

});
