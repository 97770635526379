'use strict';

var i18n = require('i18next');
var DhcpReservations = require('actions/dhcpReservations/DhcpReservations');
var FormView = require('actions/dhcpReservations/FormView');
var StaticView = require('actions/dhcpReservations/StaticView');

/**
 * Action configuration for the DHCP reservations action component.
 */
module.exports = {
  title: i18n.t('actionDhcpReservations.title'),
  Model: DhcpReservations,
  FormView: FormView,
  TextView: StaticView,
  dependsOn: ['lanAddress'],
};
