'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var RenderPositions = require('config/RenderPositions');
var ActionView = require('manage/edit/config/action/ActionView');
var console2 = require('lib/Console');

/**
 * Renders a list of actions.
 */
var ActionListView = Marionette.CollectionView.extend({
  /**
   * One of the {@link config/RenderPosition} types. Used to filter the
   * collection, as well as adjust the layout.
   * @member {String} listType
   */

  /**
   * @type {manage/edit/config/action/ActionView}
   */
  childView: ActionView,

  childViewOptions: function(model, index) {
    return {
      removeCallback: this.options.removeCallback,
      calculateOnOffState: this.calculateOnOffStateForActionModel.bind(this),
      listenForOnOffStateChange: this.listenForOnOffStateChange.bind(this),
      stopListeningForOnOffStateChange: this.stopListeningForOnOffStateChange.bind(this),
    };
  },

  filter: function(child, index, collection) {
    return child.get('actionConfig').hideAction === false &&
      child.get('renderPosition') === RenderPositions[this.listType];
  },

  calculateOnOffStateForActionModel: function(actionModel) {
    if (_.isFunction(actionModel.getOnOffState)) {
      return actionModel.getOnOffState();
    }
    if (actionModel.toggledOnOffBy) {
      var masterAction = this.collection.get(actionModel.toggledOnOffBy);
      var masterActionModel;
      if (masterAction) {
        masterActionModel = masterAction.get('actionModel');
      }
      if (masterActionModel && _.isFunction(masterActionModel.getOnOffState)) {
        return masterActionModel.getOnOffState();
      }
    }
    console2.log('error',
      'Cannot determine on/off state for ' + actionModel.get('actionTitle') +
      '. Verify that the action model implements a getOnOffState() method, or has a ' +
      'toggledOnOffBy property that identifies another action that exists in the same ' +
      'card and whose action model implements getOnOffState()'
    );
    return null;
  },

  listenForOnOffStateChange: function(actionView, callback) {
    actionView.listenTo(this.collection, 'actionModel:onoff:toggled', function(srcAction) {
      // notify actions about on/off changes initiated by themselves or
      // by the action they have declared as their on/off control source
      var action = actionView.model;
      if (srcAction === action || srcAction.id === action.get('actionModel').toggledOnOffBy) {
        callback.apply(actionView);
      }
    });
  },

  stopListeningForOnOffStateChange: function(actionView) {
    actionView.stopListening(this.collection, 'actionModel:onoff:toggled');
  },
});

module.exports = {
  Full: ActionListView.extend({
    listType: RenderPositions.full,
  }),

  Header: ActionListView.extend({
    listType: RenderPositions.header,
  }),

  First: ActionListView.extend({
    listType: RenderPositions.first,
  }),

  Middle: ActionListView.extend({
    listType: RenderPositions.middle,
  }),

  Last: ActionListView.extend({
    listType: RenderPositions.last,
  }),
};
