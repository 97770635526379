'use strict';

var i18n = require('i18next');
var EditShaping = require('actions/trafficShaping/EditShaping');
var FormView = require('actions/trafficShaping/FormView');
var StaticView = require('actions/trafficShaping/StaticView');

/**
 * Action configuration for Traffic Shaping (QoS)
 */
module.exports = {
  title: i18n.t('actionTrafficShaping.title'),
  Model: EditShaping,
  FormView: FormView,
  TextView: StaticView,
};
