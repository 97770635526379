'use strict';

var _ = require('lodash');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var networkUtils = require('lib/network');
var snapshotMixin = require('lib/mixins/snapshot');
var i18n = require('i18next');

/**
 * Represents an individual 1 to 1 NAT rule
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({
  /**
   * @member {Object} actions/oneoneNat/EditNat#attributes
   * @property {Boolean} remove
   *   Whether the rule should be removed on save
   * @property {string} ip
   * @property {string} srcDip
   * @property {boolean} enabled
   *  enabled or disabled
   */

  _snapshotAttributes: ['dest_ip', 'src_dip', 'enabled', 'remove'],

  defaults: {
    remove: false,
    enabled: true,
  },

  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true) {
      // rules pending removal are never considered invalid
      return;
    }

    if (_.has(attrs, 'dest_ip')) {
      if (!networkUtils.validInternalIPv4(attrs.dest_ip)) {
        errors.destIp = i18n.t('actionOneOneNat.badDestIp');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return {
        name: 'firewall.removeFirewallSetting',
        data: {
          id: this.id,
        },
      };
    }

    if (this.isNew() || this.getSnapshotDiff()) {
      var task = {
        name: 'firewall.addOneToOneNat',
        data: {
          ip: this.get('dest_ip'),
          srcDip: this.get('src_dip'),
          enabled: this.get('enabled'),
        },
      };

      if (!this.isNew()) {
        task.data.id = this.id;
      }

      return task;
    }
  },
});
