'use strict';

var i18n = require('i18next');
var EditPorts = require('actions/ports/bond/EditPorts');
var FormView = require('actions/ports/bond/BondPortsFormView');
var StaticView = require('actions/ports/bond/BondPortsStaticView');

/**
 * Action configuration for the bond ports action component.
 */
module.exports = {
  title: i18n.t('actionPorts.title'),
  Model: EditPorts,
  FormView: FormView,
  TextView: StaticView,
};
