'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('manage/details/wifi/wifi-details.html');
var EditWifi = require('actions/wifi/EditWifi');
var Ip = require('lib/Ip');
var i18n = require('i18next');
var Modal = require('components/modal/ModalView');
var ModalChildView = require('components/modal/GenericChildView');
var Radio = require('backbone.radio');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');

/**
 * Renders the Wi-Fi configuration and status details View.
 */
module.exports = Marionette.View.extend({
  /**
   * @member {manage/details/wifi/WifiView} manage/details/wifi/WifiDetailsView#model
   */

  template: twig({data: tpl}),

  events: {
    'click [href="#wifi-passphrase"]': 'showPassphrase',
  },

  /**
   * Shows the encryption key to the user.
   *
   * @param {Event} ev
   */
  showPassphrase: function(ev) {
    ev.preventDefault();

    var wifi = this.model.get('deviceConfig').get('networks').get(this.model.id);

    if (wifi.get('encryptionType') === 'wep') {
      return;
    }

    new Modal({
      model: new Backbone.Model(
        {
          callback: null,
          title: i18n.t('wifiDetails.currentPassword'),
          childView: new ModalChildView({
            content: '<p class="display-password">' + wifi.get('encryptionKey') + '</p>',
          }),
          actionButtonText: '',
          closeText: i18n.t('common.close'),
        }
      ),
    }).render();
  },

  /**
   * @return {Object}
   */
  serializeData: function() {
    var data = Marionette.View.prototype.serializeData.apply(this, arguments);
    var deviceConfig = this.model.get('deviceConfig');

    // clean up the following props from returned data to lighten the object
    // being sent to the template
    delete data.deviceConfig;
    delete data.deviceStatus;
    delete data.bandwidth;
    delete data.statusCard;

    data.config = deviceConfig.get('networks').get(this.model.id).toJSON();
    data.globalConfig = deviceConfig.get('wifi').toJSON();
    data.status = this.model.get('deviceStatus').get('wifi').toJSON();

    return data;
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    var deviceConfig = this.model.get('deviceConfig');

    return {
      channel: this.tplChannel,
      parentNetwork: _.partial(this.tplParentNetworkName, deviceConfig),
      encryptionTypeName: this.tplEncryptionTypeName,
      wifiDisabledMsg: _.partial(this.tplWifiDisabledMsg, deviceConfig),
    };
  },

  /**
   * Gets the Wi-Fi channel being used.
   * @memberof! manage/details/wifi/WifiDetailsView#
   * @method templateContext.channel
   * @return {String}
   */
  tplChannel: function() {
    if (this.globalConfig.channel === 'auto') {
      var currentChannel = this.status.channel;
      return i18n.t('wifiDetails.autoChannel', {currentChannel: currentChannel});
    }

    return this.globalConfig.channel;
  },

  /**
   * Gets the name of the VLAN this Wi-Fi network is attached to.
   * @memberof! manage/details/wifi/WifiDetailsView#
   * @method templateContext.parentNetwork
   * @param {lib/models/DeviceConfiguration} deviceConfig
   * @return {String}
   */
  tplParentNetworkName: function(deviceConfig) {
    var vlan = deviceConfig.get('networks').get(this.config.parentVlan);
    var subnet;
    var ip;
    var pool;

    var data = {name: vlan.getName()};

    subnet = deviceConfig.getSubnet(vlan.id);
    pool = deviceConfig.getDhcpPool(vlan.id);
    ip = new Ip(subnet.get('address') + '/' + subnet.get('size'));

    data.subnet = ip.network() + ' - ' + ip.broadcast();

    if (!_.isUndefined(pool)) {
      data.pool = pool.get('startIp') + ' - ' + pool.get('endIp');
    }

    return data;
  },

  /**
   * Gets the encryption type description.
   * @memberof! manage/details/wifi/WifiDetailsView#
   * @method templateContext.encryptionTypeName
   * @return {String}
   */
  tplEncryptionTypeName: function() {
    var type = _.findWhere(EditWifi.encryptionTypes, {value: this.config.encryptionType});
    return type.label;
  },

  tplWifiDisabledMsg: function(deviceConfig) {
    var href = deviceConfigChannel.request('get:group:url', 'wifiGlobal');
    var link = '<a href="' + href + '" class="link-inline">' + i18n.t('groups.wifiGlobal') + '</a>';
    return i18n.t('actionWifi.wifiDisabledWarning', {link: link});
  },
});
