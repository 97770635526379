'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var tpl = require('manage/details/dna/wanDetails/wans-lte.html');
var scHelpers = require('lib/tplHelpers/statusCardHelpers');

/**
 * Renders the LTE details.
 */
module.exports = Marionette.View.extend({
  /**
   * @member {Backbone.Model} model
   * @property {Backbone.Model} attributes.statusCard
   */

  template: twig({data: tpl}),

  lteSignalLabels: [
    i18n.t('dnaDetails.lteQuality.0'),
    i18n.t('dnaDetails.lteQuality.1'),
    i18n.t('dnaDetails.lteQuality.2'),
    i18n.t('dnaDetails.lteQuality.3'),
    i18n.t('dnaDetails.lteQuality.4'),
  ],

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    var debounced = _.debounce(this.render, 1);
    this.listenTo(this.model.get('statusCard'), {
      'change:signalQuality': debounced,
      'change:connected': debounced,
      'change:activeWan': debounced,
    });
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    return {
      lte: this.tplLteDetails.bind(this),
    };
  },

  /**
   * Gets the LTE details.
   *
   * @method templateContext.lte
   * @return {Object}
   */
  tplLteDetails: function() {
    var statusCard = this.model.get('statusCard');

    return {
      status: scHelpers.getWANText(scHelpers.networkInterfaceStatus(statusCard, 'lte'), false),
      signal: this.getLteSignalQualityLabel(),
      warning: this.getLteWarning(),
    };
  },

  /**
   * Gets the LTE signal label.
   *
   * @return {String}
   */
  getLteSignalQualityLabel: function() {
    var statusCard = this.model.get('statusCard');
    var signal = scHelpers.cellularQuality(statusCard);
    return this.lteSignalLabels[signal];
  },

  getLteWarning: function() {
    var statusCard = this.model.get('statusCard');
    var state = scHelpers.networkInterfaceStatus(statusCard, 'lte');
    if (state === 'no_carriers') {
      return i18n.t('healthWidget.check_antennas');
    }
    return '';
  },

});
