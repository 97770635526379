'use strict';

var i18n = require('i18next');
var EditNat = require('actions/onemanyNat/EditNat');
var FormView = require('actions/onemanyNat/FormView');
var StaticView = require('actions/onemanyNat/StaticView');

/**
 * Action configuration for One to One NAT
 */
module.exports = {
  title: i18n.t('groups.onemanyNat'),
  Model: EditNat,
  FormView: FormView,
  TextView: StaticView,
};
