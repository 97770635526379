'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Radio = require('backbone.radio');
var console2 = require('lib/Console');
var apiChannel = Radio.channel('apiChannel');
var LogMessage = require('lib/models/LogMessage');

/**
 * Declaratively map CRUD operations to RPC-format.
 *
 * @mixin lib/mixins/rpc2
 */

module.exports = {
  /**
   * Maps CRUD operations to specific RPC call signatures.
   * - "create" maps to model.save() when model.isNew() equals true
   * - "read" maps to model.fetch()
   * - "update" maps to model.save() when model.isNew() equals false
   * - "delete" maps to model.destroy()
   *
   * Multiple RPC call signatures can be associated with a crud operation. In
   * fact, under the hood, all CRUD ops are treated as if they are a list of
   * signatures.
   *
   * Pro-tip! If the RPC call signature setup for some object is not straight-
   * forward (e.g. the "update" sync action requires calling a "delete"
   * followed by a "create"), then forgo declaratively defining this rpc
   * property and, instead, override the getRpcDetails() method.
   *
   * For RPC calls that do not require parameters, omit "params" or pass an
   * empty object.
   *
   * @type {Object}
   * @property {Array|Object|undefined} rpc.create
   * @property {Array|Object|undefined} rpc.read
   * @property {Array|Object|undefined} rpc.update
   * @property {Array|Object|undefined} rpc.delete
   */
  rpc: {
    /*
    create: {
      method: 'DNA.Something.Something',
      params: function() {
        return {
          name: somemodel.get('name'),
        };
      }
    },
    read: [
      {
        method: 'DNA.Something.Something2',
        params: {}
      },
      {
        method: 'DNA.Something.Something3',
        params: {}
      }
    ],
    update: [{}],
    'delete': [{}]
    */
  },

  /**
   * Overrides Backbone.sync to perform communication via an RPC server.
   *
   * @param {string} method
   * @param {Backbone.Model} model
   * @param {Object} options
   * @return {jQuery.Promise}
   */
  sync: function(method, model, options) {
    var rpcDetails;
    var deferred;
    var promise;

    try {
      rpcDetails = this.getRpcDetails(method);
      promise = this._sendRpc(rpcDetails, options).then(function() {
        var args = arguments;

        if (args.length > 1) {
          return _.toArray(args);
        } else if (args.length === 1) {
          return args[0];
        }
      });

      model.trigger('request', model, promise, options);
    } catch (e) {
      var logMessage = new LogMessage({
        'message': 'RPC error (' + method + '): ' +
        e.message + ' at ' + e.fileName,
        'file': 'rpc2.js',
      });
      logMessage.save();
      console2.log('error', logMessage.get('message'));

      deferred = new $.Deferred();
      deferred.reject(); // TODO pass some meaningful data.
      promise = deferred.promise();
    }

    if (options.dependencies) {
      // wrap promise in another one that resolves the original value once all dependencies are resolved
      promise = promise.then(function(result) {
        return $.when.apply($, options.dependencies)
          .then(function() {
            return result;
          });
      });
    }

    // Call options.success (created in Backbone.Model.fetch/save/destroy) so
    // that the model is updated and related events are triggered.
    promise.done(options.success);

    // Call options.error (created in Backbone.Model.fetch/save/destroy so
    // that the error event is triggered.
    promise.fail(options.error);

    return promise;
  },

  /**
   * Constructs the list of RPC method/parameter pairs from the model's "rpc"
   * property for the passed sync method (create, read, etc.).
   *
   * @param {String} syncMethod
   *   The CRUD action to get details for.
   * @return {Array}
   *   Returns an array of objects with the keys "method" (the RPC method
   *   name) and "params" (the RPC parameters).
   */
  getRpcDetails: function(syncMethod) {
    var self = this;
    var details = [];

    if (typeof this.rpc[syncMethod] == 'undefined') {
      throw new Error('No RPC method defined for ' + syncMethod);
    }

    if (!_.isArray(this.rpc[syncMethod])) {
      this.rpc[syncMethod] = new Array(this.rpc[syncMethod]);
    }

    _.each(this.rpc[syncMethod], function(request, index, list) {
      var method = request.method || null;
      var params = request.params || {};

      if (method === null) {
        throw new Error('No RPC method defined for ' + syncMethod);
      }

      if (typeof method == 'function') {
        method = method.call(self);
      }

      if (typeof params == 'function') {
        params = params.call(self);
      }

      details.push({
        method: method,
        params: params,
      });
    });

    return details;
  },

  /**
   * Executes the passed list of RPC calls. If there are more than one RPC
   * calls, the response objects for each will be combined into an array so
   * that Backbone.Model.parse() doesn't need to be overridden.
   *
   * @private
   * @param {Array} rpcList
   *   List of objects representing API calls. Use getRpcDetails().
   * @param {Object} options
   *   Global options to pass along to the request processor
   * @return {jQuery.Promise}
   */
  _sendRpc: function(rpcList, options) {
    /*
     * This bit of fun is for chaining a series of API calls that execute
     * one after the other.
     */
    return $.when.apply($, _.map(rpcList, function(apiCall) {
      return apiChannel.request('send', apiCall.method, apiCall.params, options);
    }));
  },

};
