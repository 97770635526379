'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var DHCPOption = require('actions/dhcpAdvOptions/DHCPOption/DHCPOption');
var MultiActionItem = require('actions/shared/MultiActionItem');

/**
 * Edit Model for creating/managing DHCP advanced options.
 *
 * Note, DHCP is unimplemented for IPv6 at this time.
 *
 */
module.exports = MultiActionItem.extend({
  collectionModel: DHCPOption,

  defaults: {
    'pendingDelete': false,
    'items': {},
    'availableOptions': {
      '6': i18n.t('actionDhcp.advOptions.descriptions.dnsServer'),
      '33': i18n.t('actionDhcp.advOptions.descriptions.staticRoute'),
      '42': i18n.t('actionDhcp.advOptions.descriptions.timeServer'),
      '43': i18n.t('actionDhcp.advOptions.descriptions.vendorSpecific'),
      '66': i18n.t('actionDhcp.advOptions.descriptions.tftpServer'),
    },
    'usedOptions': [],
    'noMoreOptions': false,
    'pendingEmptying': false,
    'pendingDeleteCount': 0,
  },

  _snapshotAttributes: [
    'pendingDelete',
    'pendingDeleteCount',
    'pendingEmptying',
    'items',
  ],

  initialize: function() {
    this.listenTo(this.get('items'), 'change:optionId add remove', this.updateAvailableOptions);
    this.listenTo(this.get('items'), 'change:remove', this.pendingRemovalOfItem);
  },

  isNew: function() {
    return _.isUndefined(this.get('id'));
  },

  /**
   * Parse the options coming from the JUCI parser.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig) {
      resp = {};
      var dhcpOptions = this.deviceConfig.get('dhcpAdvOptions').toJSON();
      this.set('items', _.filter(dhcpOptions, function(option) {
        option.availableOptions = this.get('availableOptions');
        option.description = this.get('availableOptions')[option.optionId];
        return option.vlanId === this.id;
      }.bind(this)), {silent: true});

      this.updateAvailableOptions();
      this.trigger('reparsedConfigForOptions');
    }

    return resp;
  },

  /**
   * Triggers for re-running parse the config
   */
  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('dhcpAdvOptions');
      },
      events: 'update',
    },
  ],

  /**
   * Overridden to get the diff of the items collection
   *
   * @return {Boolean|Object}
   */
  getSnapshotDiff: function() {
    return this.get('items').getSnapshotDiff() || this.get('pendingDelete') === true;
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    var tasks = [];

    if (!this.isNew()) {
      tasks.push({
        name: 'dhcpAdvOptions.delete',
        data: {
          id: this.get('id'),
        },
      });
    }

    if (this.get('pendingDelete') === true) {
      return tasks;
    }

    return tasks.concat(MultiActionItem.prototype.getTask.apply(this, arguments));
  },

  addDHCPOption: function() {
    if (this.get('noMoreOptions')) {
      return;
    }

    this.get('items').add(new DHCPOption({
      vlanId: this.id,
    }));

    this.updateAvailableOptions();
  },

  updateAvailableOptions: function() {
    this.computeUsedOptions();
    this.setAvailableOptionsForChildren();
    this.set('noMoreOptions', _.size(this.get('usedOptions')) === _.size(this.get('availableOptions')));
  },

  computeUsedOptions: function() {
    var usedOptions = [];

    _.forEach(this.get('items').models, function(dhcpOption) {
      if (!_.isUndefined(dhcpOption) && !_.isNull(dhcpOption.get('optionId'))) {
        usedOptions.push({id: dhcpOption.cid, 'optionId': dhcpOption.get('optionId')});
      }
    });

    this.set('usedOptions', usedOptions);
  },

  /**
   * Update the available options for each of the DHCP option entries.
   */
  setAvailableOptionsForChildren: function() {
    _.forEach(this.get('items').models, function(dhcpOption) {
      var availableOptions = {};
      var usedOptionsForItemId = [];

      _.forEach(this.get('usedOptions'), function(option) {
        if (!_.isUndefined(option) && option.id !== dhcpOption.cid) {
          usedOptionsForItemId.push(option.optionId);
        }
      });

      _.forEach(_.keys(this.get('availableOptions')), function(optionId) {
        if (!_.contains(usedOptionsForItemId, optionId)) {
          availableOptions[optionId] = this.get('availableOptions')[optionId];
        }
      }.bind(this));

      dhcpOption.set('availableOptions', availableOptions);
    }.bind(this));
  },

  pendingRemovalOfItem: function() {
    var removedOptions = _.filter(this.get('items').models, function(dhcpOption) {
      return dhcpOption.get('remove') === true;
    });

    var pendingRemovalCount = removedOptions.length;
    var removalCount = 0;

    if (_.size(removedOptions) > 0) {
      _.forEach(removedOptions, function(removedDHCPOption) {
        if (removedDHCPOption.isNew()) {
          this.get('items').remove(removedDHCPOption);
          removalCount += 1;
          pendingRemovalCount -= 1;
        }
      }.bind(this));
    }

    if (removalCount > 0) {
      this.updateAvailableOptions();
    }

    this.set('pendingDeleteCount', pendingRemovalCount);
    this.set('pendingEmptying', _.size(this.get('usedOptions')) === pendingRemovalCount);
  },
});
