'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('manage/details/ports/native/native-ports-list.html');
var nativePortView = require('manage/details/ports/native/NativePortView');
var LibPorts = require('lib/ports');
var _ = require('lodash');

module.exports = Marionette.View.extend({
  template: twig({allowInlineIncludes: true, data: tpl}),

  ui: {
    header: 'h2',
  },

  regions: {
    nativePortsList: {
      el: '.rg-native-ports-list',
      replaceElement: true,
    },
  },

  initialize: function(options) {
    this.deviceStatus = options.deviceStatus;
    this.deviceConfig = options.deviceConfig;

    var ports = LibPorts.getUnboundPorts(this.deviceConfig, this.deviceStatus);

    this.collection = new Backbone.Collection(ports, {comparator: 'id'});
    this.listenTo(this.collection, 'reset add remove', this.toggleHeader);
    this.listenTo(this.deviceConfig.get('nicBonds'), 'remove', this._onNicBondRemoved);
    this.listenTo(this.deviceConfig.get('nicBonds'), 'add', this._onNicBondAdded);
    this.listenTo(this.deviceConfig.get('nicBonds'), 'change:portsMap', this._onNicBondSlaveChange);
    this.listenTo(this.deviceStatus.get('ports'), 'change add remove', this._onPortChange);
  },

  onRender: function() {
    var NativePortsList = Marionette.CollectionView.extend({
      collection: this.collection,
      childView: nativePortView,
    });

    this.toggleHeader();
    this.showChildView('nativePortsList', new NativePortsList(this.options));
  },

  toggleHeader: function() {
    this.ui.header.toggleClass('hidden', this.collection.length === 0);
  },

  _onNicBondSlaveChange: function() {
    var ports = LibPorts.getUnboundPorts(this.deviceConfig, this.deviceStatus);
    this.collection.set(ports);
  },

  _onNicBondAdded: function(nicBondModel) {
    var usedPorts = nicBondModel.get('portsMap');
    var self = this;
    _.each(usedPorts, function(port) {
      self.collection.remove(port);
    });
  },

  _onNicBondRemoved: function(nicBondModel) {
    var freedUpPorts = nicBondModel.get('portsMap');
    var self = this;
    _.each(freedUpPorts, function(port) {
      self.collection.add(LibPorts.formatNativePort(self.deviceConfig, self.deviceStatus, port));
    });
  },

  _onPortChange: function(port) {
    // We check for the config instead of status because a bond is not guaranteed to have a slaves in the
    // status when a port change occurs.
    // Checking the config guarantees us to not duplicate the ports on the native list and on the children
    // list.
    var nicBonds = this.deviceConfig.get('nicBonds');
    var self = this;
    if (nicBonds && nicBonds.length > 0) {
      var availablePortsList = [];
      nicBonds.forEach(function(bondConfig) {
        availablePortsList = availablePortsList.concat(bondConfig.get('portsMap'));
      });

      // Only updated / add ports that are free from a bond.
      if (_.indexOf(availablePortsList, port.id) === -1) {
        self.collection.set(
            [LibPorts.formatNativePort(self.deviceConfig, self.deviceStatus, port.id)], {remove: false}
        );
      }
    } else {
      self.collection.set(
        [LibPorts.formatNativePort(self.deviceConfig, self.deviceStatus, port.id)], {remove: false}
      );
    }
  },
});
