'use strict';

var i18n = require('i18next');
var EditNetworkObject = require('actions/networkObject/EditNetworkObject');
var FormView = require('actions/networkObject/FormView');
var StaticView = require('actions/networkObject/StaticView');

/**
 * Action configuration for the "Network Object"  action component.
 */
module.exports = {
  title: i18n.t('actionNetworkObject.title'),
  Model: EditNetworkObject,
  FormView: FormView,
  TextView: StaticView,
};
