'use strict';

var Backbone = require('backbone');
var BaseUsersListView = require('components/connectedUsersList/ConnectedUsersListView');

/**
 * Displays the list of connected users associated with a VPN
 */
module.exports = BaseUsersListView.extend({
  /**
   * @param {Object} options
   * @property {lib/models/DeviceStatus} options.deviceStatus
   * @property {String} options.vlanId
   *   The network id for which to display connected users.
   * @property {Boolean} options.emptyMessage
   *   Custom message to display if the list is empty (optional)
   */
  initialize: function(options) {
    this.mergeOptions(options, ['deviceStatus', 'emptyMessage']);

    if (options.vlanId) {
      this.vlanId = options.vlanId;
      this.initCollectionByNetwork();
    }
  },

  /**
   * Defines the View's collection property, which will be the list of connected
   * users for the associated network.
   */
  initCollectionByNetwork: function() {
    var self = this;
    var networkClientLists = this.deviceStatus.get('connectedDevices');

    var networkClientInfo = networkClientLists.get(this.vlanId);
    var users = networkClientInfo ? networkClientInfo.get('devices') : [];

    this.collection = new Backbone.Collection(users);

    this.listenTo(networkClientLists, 'add remove change:devices', function() {
      var networkClientInfo = networkClientLists.get(this.vlanId);
      var users = networkClientInfo ? networkClientInfo.get('devices') : [];
      self.collection.reset(users);
    });
  },
});
