'use strict';

var GroupView = require('manage/edit/config/group/GroupView');
var LanSegregationAlert = require('actionGroups/createLan/LanSegregationAlertView');

/**
 * Custom Group View for the Create LAN config cards.
 */
module.exports = GroupView.extend({
  onAttach: function() {
    this.listenTo(this.model.deviceConfig.get('firewall').get('vlanRouting'), 'change', this.toggleMessage);
    GroupView.prototype.onAttach.apply(this, arguments);
  },

  /**
   * Overridden to display the LAN Segregation reminder notice.
   *
   * @param {actionGroups/createLan/CreateLan} model
   * @param {Boolean} isFocused
   * @param {Object} options
   */
  onFocusChange: function(model, isFocused, options) {
    GroupView.prototype.onFocusChange.apply(this, arguments);
    this.toggleMessage();
  },

  /**
   * Updates the LAN Segregation message
   */
  toggleMessage: function() {
    var mode = this.model.deviceConfig.get('firewall').get('vlanRouting').get('mode');
    var isEnabled = mode !== 'allow';
    var isEditing = this.model.get('isFocused') || this.model.get('isDirty');
    var warningsView = this.getChildView('configWarnings');

    warningsView.triggerMethod('remove:alert', LanSegregationAlert.lanSegregationAlertId);

    if (isEditing) {
      warningsView.triggerMethod('add:custom:alert', new LanSegregationAlert({
        deviceMac: this.model.get('deviceMac'),
        isEnabled: isEnabled,
      }));
    }
  },
});
