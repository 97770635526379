'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/dhcpRelay/static.html');
var twig = require('twig').twig;
var RemoveConfig = require('manage/edit/config/action/removeConfig/RemoveConfig');

/**
 * Renders a text-only version of the "dhcp relay" form action component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/dhcpRelay/StaticView#model
   * @type {actions/dhcpRelay/EditDhcpRelay}
   */

  behaviors: [{
    behaviorClass: RemoveConfig,
  }],

  template: twig({data: tpl}),
});
