'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var rpcMixin = require('lib/mixins/rpc2');
var DeviceModel = require('lib/models/Device');
var Radio = require('backbone.radio');

/**
 * List of devices the current user has access too.
 *
 * @constructor
 * @augments {Backbone.Collection}
 * @mixes {lib/mixins/rpc2}
 * @name lib/collections/Devices
 */
module.exports = Backbone.Collection.extend(rpcMixin).extend({
  /**
   * @type {lib/models/Device}
   */
  model: DeviceModel,

  rpc: {
    read: {
      method: 'DNA.Portal.Fleet.getOverview',
      params: {},
    },
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    var self = this;

    Radio.on('socket', 'fleet-overview', function(newFleetOverview) {
      self.set(self.parse({result: newFleetOverview.devices}), {parse: true});
    });
  },

  /**
   * @param {Object} resp
   * @return {Array}
   */
  parse: function(resp) {
    if (typeof resp.result !== 'undefined') {
      resp = _.map(resp.result, function(device, index, list) {
        device.deviceMac = device.mac.toUpperCase();
        delete device.mac;
        return device;
      });
    }

    return resp;
  },
});
