'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var formTpl = require('actions/wanProtocol/form.html');
var RenderChanges = require('lib/behaviors/RenderChanges');

/**
 * Renders the protocol options available to an external VLAN.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/wanProtocol/FormView#model
   * @type {actions/wanProtocol/EditWanProtocol}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: formTpl}),

  ui: {
    method: '[name="wan-method"]',
  },

  events: {
    'change @ui.method': 'handleFormChange',
  },

  modelEvents: {
    'availableMethodsChanged': 'render',
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    return _.extend(tplHelpers.apply(this), {
      availableMethods: this.model.getAvailableMethods(),
    });
  },

  /**
   * @param {Event} ev
   */
  handleFormChange: function(ev) {
    var name = ev.currentTarget.name;
    var value = ev.currentTarget.value;
    var data = {};

    if (name === 'wan-method') {
      data.method = value;
    }

    this.model.set(data, {commit: true});
  },

});
