'use strict';

var AbstractEditWanAddress = require('actions/staticAddress/wan/AbstractEditWanAddress');

/**
 * View-Model for creating/editing a a static WAN address.
 */
module.exports = AbstractEditWanAddress.extend({
  _snapshotAttributes: ['af', 'method', 'address', 'size', 'gateway', 'pendingDelete', 'secondaryIps'],

  defaults: {
    usage: 'client',
    af: 'ipv4',
    role: 'external',
    method: 'static',
    pendingDelete: false,
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('pendingDelete') === true) {
      return;
    }

    return AbstractEditWanAddress.prototype.getTask.apply(this, arguments);
  },
});
