'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var ConnectedUserView = require('components/connectedUsersList/ConnectedUserView');
var tpl = require('components/connectedUsersList/connected-users-layout.html');

/**
 * Simple View for displaying the list of users connected to a VPN
 */
module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  className: 'items-list items-list--flat',

  regions: {
    usersList: {
      el: '.rg-users-detail-list',
      replaceElement: true,
    },
  },

  onRender: function() {
    var EmptyView = Marionette.View.extend({
      template: twig({data: this.emptyMessage || i18n.t('itemsList.noConnectedUsers')}),
      className: 'items-list__row items-list__row--flush',
    });

    var invalidUsernames = ['*', 'UNDEF'];

    var UsersListView = Marionette.CollectionView.extend({
      collection: this.collection,
      childView: ConnectedUserView,
      emptyView: EmptyView,

      filter: function(child) {
        return invalidUsernames.indexOf(child.get('username')) < 0;
      },
    });

    this.showChildView('usersList', new UsersListView(this.options));
  },

});
