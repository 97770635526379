'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var tpl = require('actions/onemanyNat/form.html');
var twig = require('twig').twig;
var Navbar = require('components/navbar/NavbarView');
var NatView = require('actions/shared/portForwarding/RuleView');
var NatRule = require('actions/onemanyNat/Nat');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');

var NatsListView = Marionette.CollectionView.extend({
  childView: NatView.extend({

    /**
     * This keeps validation errors preserved
     * when changing an Ingress IP
     */
    onAttach: function() {
      if (!this.model.isNew() || this.model.hasChanged()) {
        this.model.isValid();
      }
    },
  }),

  // wire child views to ToggleFormOff
  childViewTriggers: {
    'render': 'item:render',
    'onoff:status:change': 'item:onoff:status:change',
  },
});

module.exports = Marionette.View.extend({
  behaviors: [
    {
      behaviorClass: ToggleFormOff,
      enableCallback: function(ruleView) {
        ruleView.toggleDestPort();
      },
    },
  ],

  template: twig({data: tpl}),

  regions: {
    navbar: '.rg-navbar',
    rules: '.rg-rules',
  },

  ui: {
    add: '.btn-add',
  },

  events: {
    'click @ui.add': 'addRule',
  },

  onError: function(model) {
    var navbarView = this.getRegion('navbar').currentView;

    var navItem = navbarView.children.find(function(child) {
      return child.model.get('label') === model.get('srcIp');
    });

    if (model.validationError && model.validationError !== null) {
      navItem.setInvalid();
    }
  },

  initialize: function() {
    this.listenTo(this.model.get('items'), {
      'change': this.allItemsValid,
      'invalid': this.onError,
    });
  },

  allItemsValid: function() {
    var items = this.model.get('items');
    var invalidItems = items.models.filter(function(model) {
      return model.validationError !== null;
    });
    var invalidSrcIps = invalidItems.map(function(item) {
      return item.get('srcIp');
    });
    var navbarView = this.getRegion('navbar').currentView;

    navbarView.children.each(function(navItem) {
      if (!invalidSrcIps.includes(navItem.model.get('label'))) {
        navItem.unsetInvalid();
      }
    });
  },

  onRender: function() {
    this.showNavbarView();
    this.showRulesView();
  },

  showNavbarView: function() {
    var collection = new Backbone.Collection(this._getSecondaryIpsNavCollection());
    var navbar = new Navbar({
      collection: collection,
      large: true,
      grid: true,
    });

    this.showChildView('navbar', navbar);

    this.stopListening(null, 'navbarItem:selected');

    this.listenTo(navbar, {
      'navbarItem:selected': this.onIngressIpChange.bind(this),
    });
  },

  showRulesView: function() {
    this.selectedIp = this.selectedIp || this.model.get('secondaryIps')[0];

    var rule = new NatsListView({
      collection: this.model.get('items'),
      ip: this.selectedIp,
    });

    rule.setFilter(this._getRulesFilter(this.selectedIp));

    this.showChildView('rules', rule);
  },

  onIngressIpChange: function(model) {
    this.selectedIp = model.get('label');
    this.getRegion('rules').currentView.setFilter(this._getRulesFilter(this.selectedIp));
  },

  addRule: function() {
    var rule = new NatRule({
      'srcIp': this.selectedIp,
    });

    this.model.get('items').add(rule);
  },

  _getRulesFilter: function(ip) {
    return function(child) {
      return child.get('srcIp') === ip;
    };
  },

  _getSecondaryIpsNavCollection: function() {
    var secondaryIps = this.model.get('secondaryIps');
    var items = this.model.get('items');
    var ips = _.union(secondaryIps, _.pluck(items.toJSON(), 'srcIp'));

    var collection = _.map(ips, function(ip, key) {
      var item = {
        id: 'ip-' + key,
        label: ip,
      };

      return item;
    });

    if (collection.length) {
      collection[0].isActive = true;
    }

    return collection;
  },
});
