'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/dhcpReservations/formReservation.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var networkUtils = require('lib/network');
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the editable form elements for a single DHCP reservation
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/dhcpReservations/FormView#model
   * @type {actions/dhcpReservations/Reservation}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    deviceMac: '[name="deviceMac"]',
    deviceName: '[name="deviceName"]',
    ip: '[name="ip"]',
    remove: '.btn-remove',
    messages: '.help-block',
  },

  events: {
    'blur @ui.deviceMac': 'formatMacAndUpdateModel',
    'blur @ui.deviceName': 'updateModel',
    'blur @ui.ip': 'updateModel',
    'click @ui.remove': 'handleRemoveButton',
  },

  modelEvents: {
    'change:remove': 'render',
    'invalid': 'onError',
    'noConflicts': 'onNoConflicts',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var context = {};
    return _.extend(context, tplHelpers.apply(this));
  },

  formatMacAndUpdateModel: function(event) {
    var value = event.currentTarget.value;
    var formattedValue = networkUtils.formatMacAddress(value);
    if (formattedValue) {
      event.currentTarget.value = formattedValue;
    }

    this.updateModel(event);
  },

  /**
   * Sync changes in UI input elements to the model and trigger validation
   *
   * @param {Object} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'deviceMac':
        this.clearError('deviceMac');
        this.model.set({deviceMac: value}, {commit: true});
        break;

      case 'deviceName':
        this.clearError('deviceName');
        this.model.set({deviceName: value}, {commit: true});
        break;

      case 'ip':
        this.clearError('ip');
        this.model.set({ip: value}, {commit: true});
        break;

      default:
        break;
    }
  },

  /**
   * Remove this reservation
   */
  handleRemoveButton: function() {
    if (this.model.isNew()) {
      // remove never-saved rules immediately
      this.model.collection.remove(this.model);
    } else {
      var shouldRemove = !this.model.get('remove');
      if (shouldRemove) {
        // if you ask to remove an existing reservation, roll back any local changes
        this.model.applySnapshot();
      }
      this.model.set({remove: shouldRemove});
    }
  },

  /**
   * Decorates the view to indicate error.
   *
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

  /**
   * Remove an error message from the view (if present)
   *
   * @param {String} key
   *   Identifier for the error message
   */
  clearError: function(key) {
    this.ui[key].bs3ui('clearGroupedFieldError', key, this.ui.messages);
  },

  /**
   * Clear any "special" errors related to domain conflicts
   */
  onNoConflicts: function() {
    this.clearError('deviceMac');
  },
});
