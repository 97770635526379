'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var tplError = require('layouts/appError/error.html');

var classProps = {
  ALL_SYSTEMS_DOWN: 2,
  BROWSER_UNSUPPORTED: 4,
};

/**
 * View for displaying app start-up errors.
 */
module.exports = Marionette.View.extend({
  template: twig({allowInlineIncludes: true, data: tplError}),

  /**
   * @param {Object} options
   * @property {Number} options.errorCode
   *   Any AppError.* error code.
   */
  initialize: function(options) {
    this.model = new Backbone.Model({
      errorCode: options.errorCode,
    });
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    var self = this;
    return {
      /**
       * Gets error message to display.
       * @memberof! layouts/appError/AppError#
       * @method templateContext.error
       * @return {String}
       */
      error: function() {
        return self.getErrorMessage(this.errorCode);
      },
    };
  },

  /**
   * Gets the error message matching the passed error code.
   *
   * @param {Number} errorCode
   * @return {String}
   */
  getErrorMessage: function(errorCode) {
    var msg;

    switch (errorCode) {
      case classProps.BROWSER_UNSUPPORTED:
        msg = i18n.t('appLayout.unsupportedBrowser');
        break;
      case classProps.ALL_SYSTEMS_DOWN:
      default:
        msg = i18n.t('appLayout.mothershipUnreachable');
        break;
    }

    return msg;
  },
},
classProps);
