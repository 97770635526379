'use strict';

var _ = require('lodash');
var AbstractConfigPageView = require('manage/edit/page/AbstractConfigPageView');
var twig = require('twig').twig;
var i18n = require('i18next');
var tplLayout = require('manage/edit/page/systemConfigPage.html');
var ScrollTo = require('lib/behaviors/ScrollTo');
var ConfigRestore = require('manage/configRestore/ConfigRestoreView');
var configPage = require('lib/configPage');

var PAGE_KEY = configPage.SYSTEM;

module.exports = AbstractConfigPageView.extend({

  // ----- begin config restore specialization -----

  template: twig({data: tplLayout, allowInlineIncludes: true}),

  behaviors: [{
    behaviorClass: ScrollTo,
  }],

  regions: _.extend({}, AbstractConfigPageView.prototype.regions, {
    configRestore: '.rg-config-restore',
  }),

  ui: {
    'restore': '.config-restore',
  },

  events: {
    'click @ui.restore': function() {
      this.triggerMethod('scroll:me', this.ui.restore);
    },
  },

  initialize: function(options) {
    AbstractConfigPageView.prototype.initialize.apply(this, arguments);
    this.configurations = options.configurations;
  },

  onRender: function() {
    AbstractConfigPageView.prototype.onRender.apply(this, arguments);
    this.showChildView('configRestore', new ConfigRestore({
      deviceMac: this.model.get('deviceMac'),
      configurations: this.configurations,
    }));
  },

  // ----- end config restore specialization -----

  /**
   * @see AbstractConfigPageView#getActiveCardList
   * @return {Backbone.Collection}
   */
  getActiveCardList: function() {
    return this.model.get(PAGE_KEY);
  },

  /**
   * @see AbstractConfigPageView#getToolboxTitle
   * @return {String}
   */
  getToolboxTitle: function() {
    return i18n.t('toolbox.title.system');
  },

  /**
   * @see AbstractConfigPageView#getCreationalCardFilter
   * @return {Function}
   */
  getCreationalCardFilter: function() {
    return function(GroupModel) {
      return GroupModel.isCreational === true &&
        GroupModel.creationalPage === PAGE_KEY;
    };
  },

});
