'use strict';

var GroupView = require('manage/edit/config/group/GroupView');
var i18n = require('i18next');
var NetworkDisruptionAlert = require('actionGroups/shared/NetworkDisruptionView');

/**
 * Custom Group View for the Edit LAN config cards.
 */
module.exports = GroupView.extend({
  initialize: function(options) {
    GroupView.prototype.initialize.call(this, options);

    this.model.on('change:dhcpOptionPendingDeletionCount', this.toggleNetworkDisruptionAlert.bind(this));
  },

  /**
   * Warn the user that removing this LAN will also remove child
   * WiFi networks (if any exist)
   *
   * @returns {String}
   */
  getRemovalSubMessage: function() {
    var staticRoutes = this.model.deviceConfig.getStaticRoutesForVlan(this.model.get('typeId'));
    var wifis = this.model.deviceConfig.getWifisForVlan(this.model.get('typeId'));
    if (staticRoutes.length > 0 && wifis.length > 0) {
      return i18n.t('editLan.deleteWithWifiAndStaticRoutes');
    }
    if (staticRoutes.length > 0) {
      return i18n.t('editLan.deleteWithStaticRoutes');
    }
    if (wifis.length > 0) {
      return i18n.t('editLan.deleteWithWifi', {
        count: wifis.length,
        firstWifiName: wifis[0].getName(),
      });
    }
    return GroupView.prototype.getRemovalSubMessage.apply(this);
  },

  getRemovalIsDangerous: function() {
    return true;
  },

  /**
   * If this LAN has child WiFi networks, removal has extra side
   * effects, so default to the safer choice of "No"
   *
   * @returns {Boolean}
   */
  getRemovalDefaultIsYes: function() {
    var wifis = this.model.deviceConfig.getWifisForVlan(this.model.get('typeId'));
    return wifis.length === 0;
  },

  toggleNetworkDisruptionAlert: function() {
    var warningsView = this.getChildView('configWarnings');

    warningsView.triggerMethod('remove:alert', NetworkDisruptionAlert.networkDisruptionAlertId);
    if (this.model.get('dhcpOptionPendingDeletionCount')) {
      warningsView.triggerMethod('add:custom:alert', new NetworkDisruptionAlert());
    }
  },
});
