'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var Backbone = require('backbone');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var networkUtils = require('lib/network');

/**
 * Represents an individual VPN User
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({

  /**
   * @member {Object} #attributes
   * @property {Boolean} remove
   *   Whether the user should be removed on save
   * @property {String} id
   *   ID of the user
   * @property {Boolean} enabled
   *   Whether the user is enabled
   * @property {String} description
   *   Description for the user (typically real name)
   * @property {String} username
   *   Login username for this user
   * @property {Boolean} setPassword
   *   Whether user password should be set on save with value of newPassword
   * @property {String} newPassword
   *   New login password to be set for this user on save (if any)
   */

  _snapshotAttributes: [
    'remove',
    'enabled',
    'description',
    'username',
    'setPassword',
    'newPassword',
  ],

  defaults: {
    remove: false,
    enabled: true,
    description: null,
    username: null,
    setPassword: false,
    newPassword: null,
  },

  /**
   * @param {Object} attributes
   * @param {Object} options
   */
  constructor: function(attributes, options) {
    Backbone.Model.call(this, attributes, options);

    if (this._snapshotAttributes !== false) {
      // set snapshot base by capturing default and initial attributes
      this.takeSnapshot();
    }
  },

  /**
   * @fires noConflicts
   *   Validation has determined this user is free of conflicts with other users
   *
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true) {
      // users pending removal are never considered invalid
      return;
    }

    if (_.has(attrs, 'description')) {
      if (!attrs.description) {
        errors.description = i18n.t('actionVpn.users.badDescription');
      }
    }

    if (_.has(attrs, 'username')) {
      if (!attrs.username || !networkUtils.validEmail(attrs.username)) {
        errors.username = i18n.t('actionVpn.users.badUsername');
      } else if (options.allItems) {
        if (_.any(options.allItems, this.conflictsWithUser, this)) {
          errors.username = i18n.t('actionVpn.users.usernameConflict');
        } else {
          // since user conflict errors can be eliminated by editing the _other_
          // user(s) involved, and backbone has no "valid" event, provide a way
          // for views to know that conflict errors on this user should be cleared
          this.trigger('noConflicts');
        }
      }
    }

    if (_.has(attrs, 'newPassword') && (this.isNew() || this.get('setPassword') === true)) {
      if (!attrs.newPassword || attrs.newPassword.length < 8) {
        errors.newPassword = i18n.t('actionVpn.users.badPassword');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return {
        name: 'vpn.deleteUser',
        data: {
          id: this.id,
        },
      };
    }

    if (this.isNew()) {
      return {
        name: 'vpn.createUser',
        data: {
          enabled: this.get('enabled'),
          description: this.get('description'),
          username: this.get('username'),
          password: this.get('newPassword'),
        },
      };
    }

    if (this.getSnapshotDiff()) {
      var task = {
        name: 'vpn.updateUser',
        data: {
          id: this.id,
          enabled: this.get('enabled'),
          description: this.get('description'),
          username: this.get('username'),
        },
      };
      if (this.get('setPassword') === true) {
        // update includes a password change
        task.data.password = this.get('newPassword');
      }
      return task;
    }
  },

  /**
   * Checks whether this user conflicts with the provided user.
   *
   * @param {actions/vpn/users/user} user
   *   An instance of this model to check for conflicts
   * @return {Boolean}
   */
  conflictsWithUser: function(user) {
    var username;

    // a user never conflicts with itself
    if (user === this) {
      return false;
    }

    if (user.get('remove') === true) {
      // don't consider users that are about to be removed
      return false;
    }

    username = user.get('username');
    if (!username || !networkUtils.validEmail(username)) {
      // cannot determine conflict if other user has an invalid user name
      return false;
    }

    // currently only the username has to be unique
    return this.get('username') === username;
  },

});
