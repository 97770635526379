'use strict';

module.exports = {
  FAILED_VALIDATION: 10,
  REQUIRE_DNS: 20,
  TASKS_ERROR: 30,
  NO_CHANGES: 40,
  CLONE_CONFIG_NO_CHANGES: 41,
  QUEUE_APPLIED: 50,
  JOB_ERRORS: 60,
  JOB_TIMEOUT: 70,
  TEST_TIMEOUT: 71,
  DHCP_ERROR: 80,
  CAPABILITY_UNSUPPORTED: 90,
  INVALID_UCI: 100,
};
