'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('manage/edit/toolbox/config-card-action.html');
var tooltipTpl = require('manage/edit/toolbox/tooltip.html');
var i18n = require('i18next');

/**
 * Renders a single action item in the toolbox nav
 */
module.exports = Marionette.View.extend({
  /**
   * @member {Backbone.Model} manage/edit/toolbox/ConfigCardActionView#model
   */

  tagName: 'li',
  template: twig({data: tpl}),
  tooltipTemplate: twig({data: tooltipTpl}),

  ui: {
    configLinkChild: '.config-link__child',
  },

  events: {
    'click @ui.configLinkChild': 'selectAction',
  },

  modelEvents: {
    'change:configured': 'render',
  },

  /**
   * @param {Object} options
   * @property {Radio.Channel} options.channel
   *   A private Radio channel to communicate item selection events.
   */
  initialize: function(options) {
    this.mergeOptions(options, ['channel']);
  },

  /**
   * @return {Objects}
   */
  templateContext: function() {
    return {
      testId: this.tplTestId,
      tooltip: this.tooltipHelper.bind(this),
    };
  },

  /**
   * Signals that an action has been selected
   *
   * @fires channel.request~add:action
   * @fires channel.request~focus:action
   */
  selectAction: function() {
    if (this.model.get('disableConfiguration').length > 0) {
      return;
    }

    var eventName = 'add:action';

    if (this.model.get('configured') === true) {
      eventName = 'focus:action';
    }

    this.channel.request(
      eventName, this.model.get('type'), this.model.get('typeId'), this.model.get('actionId')
    );
  },

  onRender: function() {
    if (this.model.get('disableConfiguration').length > 0) {
      this.ui.configLinkChild.tooltip({trigger: 'hover'});
      this.ui.configLinkChild.addClass('config-link--disabled');
    } else if (this.model.get('configured')) {
      this.ui.configLinkChild.addClass('config-link__edit');
    }
  },

  /**
   * Builds the tooltip message to indicate the port is unavailable and to
   * which vlan it belongs to.
   *
   * @param {Object} port
   * @return {String}|{null}
   */
  tooltipHelper: function() {
    if (!this.model.get('disableConfiguration')) {
      return;
    }

    var reasons = this.model.get('disableConfiguration').map(function(reason) {
      return reason.reason;
    }).join(i18n.t('general.listSeparator'));

    var data = {'reasons': reasons};

    return this.tooltipTemplate.render(data);
  },

  /**
   * Testing Id
   *
   * @method templateContext.testId
   * @return {String}
   */
  tplTestId: function() {
    return 'go-config-option-' + _.toArray(_.pick(this, 'type', 'typeId', 'actionId')).join('-');
  },
});
