'use strict';

var _ = require('lodash');
var BaseGroup = require('config/Group');
var i18n = require('i18next');
var RenderPositions = require('config/RenderPositions');
var TrafficManagementDisruptionAlert = require('actionGroups/shared/TrafficManagementDisruptionView');
var Entities = require('html-entities').XmlEntities;
var entities = new Entities();

/**
 * "Manage WiFi" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'wifiDescription',
      position: RenderPositions.header,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'editWifi',
      position: RenderPositions.first,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: true,
    },
    {
      name: 'macFilter',
      position: RenderPositions.last,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
  ],
  deleteTaskName: 'wifi.delete',

  isConnectivityDisrupted: function(type, target) {
    // Checks whether wifi is globally disabled or not. If its already globally disabled
    // then there can not possibly be any network disruption.
    if (this.deviceConfig.get('wifi').get('disabled')) {
      return false;
    }

    if (type === 'input') {
      var affectsConnectivity = (
        target.name === 'wifi-broadcast' ||
        target.name === 'wifi-encryption-type' ||
        target.name === 'wifi-ssid' ||
        target.name === 'wifi-encryption-key' ||
        target.name === 'wifi-parentVlan'
      );
      // if connectivity isn't outright disrupted, there will still be a udshape restart
      return affectsConnectivity || TrafficManagementDisruptionAlert;
    }

    return false;
  },

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return false;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.editWifi');
  },

  /**
   * @return {String}
   */
  description: function() {
    var wifi = this.deviceConfig.get('networks').get(this.get('typeId'));
    return wifi !== undefined ? entities.decode(wifi.getName()) : '';
  },

  /**
   * Request a callback when the description of this group changes
   *
   * @param {Backbone.Events} listener
   * @param {Function} callback
   */
  onDescriptionChanged: function(listener, callback) {
    var wifi = this.deviceConfig.get('networks').get(this.get('typeId'));
    if (wifi) {
      wifi.onNameChanged(listener, callback);
    }
  },

  /**
   * Overridden to include the "id".
   *
   * @return {Object}
   */
  _getTasks: function() {
    var tasks = BaseGroup.prototype._getTasks.apply(this, arguments);
    var vlanTask = _.find(tasks, {name: 'wifi.create'});

    if (!_.isUndefined(vlanTask)) {
      vlanTask.data.id = this.get('typeId');
    }

    return tasks;
  },
});
