'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tplModal = require('components/modal/modal.html');

/**
 * Simple (Bootstrap-styled) modal window to be used for all your modal window needs
 */
module.exports = Marionette.View.extend({
  /**
   * @member {Backbone.Model} model
   * @property {Function} attributes.callback
   * @property {String} attributes.title
   * @property {String} attributes.actionButtonText
   * @property {String} attributes.closeText
   * @property {Marionette.View} attributes.childView
   */

  template: twig({data: tplModal}),

  ui: {
    actionBtn: '.modal-action-button',
    closeBtn: '.modal-close-button',
  },

  regions: {
    body: '.rg-body',
  },

  events: {
    'click @ui.actionBtn': 'doAction',
  },

  attributes: {
    'class': 'modal fade',
    tabIndex: '-1',
    role: 'dialog',
  },

  doAction: function() {
    var callback = this.model.get('callback');
    if (_.isFunction(callback)) {
      callback();
    }
    this.$el.modal('hide');
  },

  onRender: function() {
    this.initModal();
  },

  /**
   * Sets up the View as a Bootstrap modal and displays it.
   */
  initModal: function() {
    var self = this;

    this.showChildView('body', this.model.get('childView'));

    if (!(this.model.get('callback') instanceof Function)) {
      this.ui.actionBtn.addClass('hide');
      this.ui.closeBtn.removeClass('btn-link').addClass('btn-primary');
    }

    this.$el.modal('show');

    // destroy the View when it is closed
    this.$el.on('hidden.bs.modal', function() {
      self.destroy();
    });
  },
});
