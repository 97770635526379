'use strict';

var CreateWifi = require('actionGroups/createWifi/CreateWifi');
var FormView = require('actionGroups/editWifi/EditWifiView');

module.exports = {
  Model: CreateWifi,

  // The GroupView is currently shared with the Edit WiFi config card. It will
  // need to be split up if behavior specific to creating or editing is added.
  GroupView: FormView,
};
