'use strict';

var i18n = require('i18next');
var EditFailoverPolicyControl = require('actions/failoverPolicyControl/EditFailoverPolicyControl');
var FormView = require('actions/failoverPolicyControl/FormView');
var StaticView = require('actions/failoverPolicyControl/StaticView');

module.exports = {
  title: i18n.t('failoverPolicyControl.policyTitle'),
  Model: EditFailoverPolicyControl,
  FormView: FormView,
  TextView: StaticView,
};
