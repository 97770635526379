'use strict';

var BaseGroup = require('config/Group');
var RenderPositions = require('config/RenderPositions');
var i18n = require('i18next');

/**
 * "Load Balance" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'loadBalance',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'lanPolicy',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  isConnectivityDisrupted: function(type, target) {
    if (type === 'input') {
      return target.name === 'load-balance-enable';
    }
  },

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.loadBalance');
  },
});
