'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/trafficShaping/formCategory.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the Traffic Shaping (QoS) form component for a single traffic category.
 */
module.exports = Marionette.View.extend({
  /**
   * @name #model
   * @type {actions/trafficShaping/Category}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  className: 'form-list__row-wrapper',

  ui: {
    percent: '[name="percent"]',
    priority: '[name="priority"]',
    messages: '.help-block',
  },

  events: {
    'blur @ui.percent': 'saveForm',
    'change @ui.priority': 'saveForm',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  templateContext: function() {
    var context = {
      label: this.model.getLabel(),
    };

    return _.extend(context, tplHelpers.apply(this));
  },

  onBeforeRender: function() {
    this.el.setAttribute('data-tag', 'config-subOption-category-' + this.model.get('name'));
  },

  /**
   * Saves modified values
   *
   * @param {Object} event
   */
  saveForm: function(event) {
    var control = event.target;
    var name = control.name;
    var value = control.value;

    switch (name) {
      case 'percent':
        var parsed = parseInt(value);
        this.ui.percent.bs3ui('clearGroupedFieldError', 'percent', this.ui.messages);
        if (!isNaN(parsed)) {
          this.ui.percent.val(parsed);
        }
        this.model.set({percent: parsed}, {commit: true});
        break;

      case 'priority':
        this.ui.priority.bs3ui('clearGroupedFieldError', 'priority', this.ui.messages);
        this.model.set({priority: value}, {commit: true});
        break;

      default:
        break;
    }
  },

  /**
   * Decorates the view to indicate error.
   *
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

});
