'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/dmz/static.html');
var twig = require('twig').twig;

/**
 * Renders a text-only version of the "dmz" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/dmz/StaticView#model
   * @type {actions/dmz/EditDmz}
   */

  template: twig({data: tpl}),
});
