'use strict';

/**
 * Properties and methods for allowing a Model to extend classes with
 * the snapshot mixin to take advantage of those additional methods without
 * having to include methods for snapshotting
 * @mixin lib/mixins/noSnapshot
 */

module.exports = {
  /**
   * Overrides the snapshot mixin's functions with functions that do no work
   */

  takeSnapshot: function() {
    return;
  },

  applySnapshot: function() {
    return;
  },

  /**
   * Returning False would mean the model has not changed
   * Therefore, we return a dummy object here
   * so that the model has "always changed"
   * @returns {Object}
   */
  getSnapshotDiff: function() {
    return {changed: true};
  },

  getFromSnapshot: function(attribute) {
    return;
  },

  getLastSnapshot: function() {
    return {};
  },
};
