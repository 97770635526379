
'use strict';

var LanAddressView = require('actions/staticAddress/lan/LanAddressView');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
var networkUtils = require('lib/network');

/**
 * VPN address form.
 */
module.exports = LanAddressView.extend({

  behaviors: [
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  /**
   * @return {Object}
   */
  serializeData: function() {
    var data = LanAddressView.prototype.serializeData.apply(this, arguments);
    data.subnetMasks = networkUtils.getSubnetMasks(16, 30);
    return data;
  },

  /**
   * If the address is not at the beginning of the subnet, adjust it.
   */
  checkForNonStandardIp: function() {
    var suggested = this.model.getSuggestedNetworkIp();
    if (suggested !== false && suggested !== this.model.get('address')) {
      this.model.trigger('updateAddress', suggested);
    }
  },

});
