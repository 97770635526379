'use strict';

var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var _ = require('lodash');
var Entities = require('html-entities').XmlEntities;
var entities = new Entities();

/**
 * Model for creating/managing LAN policies
 */
module.exports = ActionItem.extend({
  _snapshotAttributes: [
    'lanPolicies',
  ],

  validate: function(attrs, options) {
    var errors = {};
    var duplicates = [];
    var lanInfo = this.get('lanInfo');
    _.each(attrs.lanPolicies.vlan1, function(lanId) {
      if (_.includes(attrs.lanPolicies.vlan3, lanId)) {
        duplicates.push(lanInfo[lanId]);
      }
    });
    if (_.size(duplicates) > 0) {
      var duplicateList = duplicates.join(i18n.t('general.listSeparator'));
      errors.messages = entities.decode(i18n.t('actionLanPolicy.duplicateLan', {duplicateList: duplicateList}));
      return errors;
    }
  },

  /**
   * Overridden to always return false
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var lans = this.deviceConfig.getInternalVlans();
      var wans = this.deviceConfig.getExternalVlans();
      var wanInfo = {};
      wans.forEach(function(wan) {
        wanInfo[wan.id] = wan.getName();
      });
      var lanInfo = {};
      lans.forEach(function(lan) {
        lanInfo[lan.id] = lan.getName();
      });

      return {
        lanPolicies: this.deviceConfig.get('loadBalancePolicies').toJSON(),
        wanInfo: wanInfo,
        lanInfo: lanInfo,
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('loadBalancePolicies');
      },
      events: 'change',
    },
    {
      getDispatcher: function(config) {
        return config.get('networks');
      },
      events: 'add remove change:description',
    },
  ],

  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    return {
      name: 'loadBalance.editLanPolicy',
      data: {
        policies: this.get('lanPolicies'),
      },
    };
  },
});
