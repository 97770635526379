'use strict';

module.exports = {
  /**
   * isNumeric helper used to determine if a value is number-like,
   * ex: "22" and 22 return true
   *
   * @param {String|Number} val
   * @return {Boolean}
   */
  isNumeric: function(val) {
    return !isNaN(parseInt(val), 10) && isFinite(val);
  },

  /**
   * Polyfill for Number.isInteger
   * version of PhantomJS used in unit tests doesn't support Number.isInteger
   * credit:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger
   *
   * isInteger helper returns true if a value is an integer,
   * otherwise return false. NaN or Infinity return false
   *
   * @param {String|Number} val
   * @return {Boolean}
   */
  isInteger: function(val) {
    return typeof val === 'number' && isFinite(val) && Math.floor(val) === val;
  },

};
