'use strict';

var _ = require('lodash');
var collectionSnapshotMixin = require('lib/mixins/snapshotCollection');
var ConfigItems = require('lib/collections/ConfigItems');
var Filter = require('actions/macFilter/Filter');

/**
 * Collection of {@link actions/macFilter/Filter} models.
 */
module.exports = ConfigItems.extend(collectionSnapshotMixin).extend({
  /**
   * @type {actions/macFilter/Filter}
   */
  model: Filter,

  initialize: function(attributes, options) {
    if (!_.isUndefined(options.wifiId)) {
      this.wifiId = options.wifiId;
    }
  },

  /**
   * Overridden to snapshot the collection itself after a fetch
   *
   * @param {Object} options
   * @return {jQuery.Promise}
   */
  fetch: function(options) {
    var result = ConfigItems.prototype.fetch.apply(this, arguments);

    if (!options || !options.fromCache) {
      this.takeSnapshot();
    }

    return result;
  },

  /**
   * @param {Array} resp
   * @param {Object} options
   * @return {Array}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      if (this.wifiId) {
        return this._getMacListFromConfig();
      }
      return [];
    }

    return resp;
  },

  /**
   * @return {Array}
   */
  _getMacListFromConfig: function() {
    var config = this.deviceConfig;
    var networks = config.get('networks');
    var network = networks.get(this.wifiId);

    var filters = [];

    _.each(network.get('macWhitelist'), function(mac) {
      filters.push({id: mac, mac: mac});
    });

    return filters;
  },

});
