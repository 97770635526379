'use strict';

var _ = require('lodash');

/**
 * {@link lib/models/DeviceConfiguration} mixin for Subnet related methods.
 *
 * Using a mixin to keep DeviceConfiguration from getting too large.
 *
 * @mixin lib/mixins/filterSubnets
 */
module.exports = {
  /**
   * Gets the subnet for a VLAN.
   *
   * @param {String} vlanId
   * @return {Backbone.Model|undefined}
   */
  getSubnet: function(vlanId) {
    var subnets;
    var vlan = this.get('networks').get(vlanId);
    var subnetsList = this.get('subnets');
    var subnetMap;

    if (_.isUndefined(vlan)) {
      return;
    }

    subnetMap = vlan.get('addressMap');

    if (subnetMap.length === 0) {
      return;
    }

    subnets = _.map(subnetMap, function(subnetId) {
      return subnetsList.get(subnetId);
    });

    return _.first(subnets);
  },
};
