'use strict';

var Backbone = require('backbone');
var $ = require('jquery');

/**
 * View-Model for {@link manage/details/vlan/VlanView}.
 */
module.exports = Backbone.Model.extend({
  /**
   * @member {Object} manage/details/vlan/VlanViewModel#attributes
   * @property {string} id
   *   The vlan ID (e.g. "vlan1")
   * @property {String} role
   *   The vlan's role.
   * @property {String} deviceMac
   * @property {lib/models/DeviceConfig} deviceConfig
   * @property {lib/models/DeviceStatus} deviceStatus
   */

  /**
   * Overridden to always get the data from DeviceConfiguration.
   *
   * @param {Object} options
   * @return {jQuery.Deferred}
   */
  fetch: function(options) {
    var config = this.get('deviceConfig');
    var vlan = config.get('networks').get(this.id);

    // Note, the data being set here is only being used by the main template
    // (manage/details/vlan/vlan-details.html). For the Views rendered via
    // ConfigListView, they use DeviceConfiguration and DeviceStatus to query
    // the information they need to render.

    this.set(vlan.pick('role'));

    return ($.Deferred().resolve()).promise();
  },
});
