'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tplDeviceCard = require('manage/list/device.html');
var DeviceStatsView = require('components/deviceStats/DeviceStatsView');
var GlobalDeviceMessageView = require('manage/detailsLayout/globalDeviceMessage/GlobalDeviceMessageView');

/**
 * Renders a single Router info card.
 */
module.exports = Marionette.View.extend({
  template: twig({data: tplDeviceCard}),

  className: 'col-sm-12 col-md-6',

  regions: {
    stats: '.rg-stats',
    usageGraph: '.rg-usage',
    message: '.rg-message',
  },

  events: {
    'click': 'onSelected',
    'keyup': 'onSelected',
  },

  onBeforeRender: function() {
    this.el.setAttribute('data-tag', 'device-' + this.model.get('deviceMac'));
  },

  onRender: function() {
    var view = new DeviceStatsView({model: this.model});
    view.model.set('page', 'fleetOverview');
    this.showChildView('stats', view);

    var messageView = new GlobalDeviceMessageView({
      model: new Backbone.Model({
        statusCard: this.model.get('statusCard'),
        page: 'fleetOverview',
      }),
    });
    this.showChildView('message', messageView);
  },

  /**
   * Bubble selection to parent list.
   *
   * @param {Event} ev
   */
  onSelected: function(ev) {
    ev.preventDefault();
    this.triggerMethod('device:selected', this.model.get('deviceMac'));
  },
});
