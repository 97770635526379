'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var layoutTpl = require('manage/details/dna/dna-layout.html');
var NetworksListView = require('manage/details/NetworksListView');
var DeviceStatsView = require('components/deviceStats/DeviceStatsView');
var WanDetailsView = require('manage/details/dna/wanDetails/WanDetailsView');
var DnaDetailsView = require('manage/details/dna/dnaDetails/DnaDetailsView');
var SecurityDetailsView = require('manage/details/dna/securityDetails/SecurityDetailsView');
var GraphItem = require('components/usage/GraphItem');
var TopAppsView = require('components/usageTopApps/TopAppsView');
var TopClientsView = require('components/usageTopClients/TopClientsView');
var TabPanelView = require('components/tabPanel/TabPanelView');
var RemoteSitesView = require('components/remoteSites/RemoteSitesView');
var DrilledDownPortsOverView = require('manage/details/ports/DrillablePortsOverview');

// Note, the following defines the base-template for manage/details/* pages.
// Once defined, it (via the 'id') is available to any View.
twig({id: 'baseDetailsLayout', data: require('manage/details/base-details-layout.html')});

/**
 * Renders the "DNA overview" View. Items displayed includes total usage
 * for all networks, list of networks, list of devices and configuration.
 */
module.exports = Marionette.View.extend({
  /**
   * View-Model.
   * @member manage/details/dna/DnaView#model
   * @type {manage/details/dna/DnaViewModel}
   */

  template: twig({allowInlineIncludes: true, data: layoutTpl}),

  className: 'row',

  regions: {
    networksList: '.rg-networks-list',
    remoteSites: '.rg-remote-sites',
    graphs: '.rg-graphs',
    networkHealth: '.rg-device-health-view',
    wanDetails: '.rg-wan-details',
    dnaDetails: '.rg-device-details',
    portsDetailsOverview: '.rg-ports-details-overview',
    securityDetails: '.rg-security-details',
  },

  ui: {
    remoteSites: '.remote-sites',
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.model.fetch();
  },

  onRender: function() {
    this.addNetworksList();
    this.addRemoteSites();
    this.addNetworkHealth();
    this.addWanDetails();
    this.addPortsOverView();
    this.addDnaDetails();
    this.addSecurityDetails();
    this.addUsageDetails();
  },

  /**
   * Adds the list of networks (vlans) associated with the current DNA.
   */
  addNetworksList: function() {
    var attrs = ['deviceMac', 'deviceConfig', 'deviceStatus'];
    var listView = new NetworksListView(this.model.pick(attrs));

    this.showChildView('networksList', listView);
  },

  /**
   * Adds the remote sites associated with the current DNA.
   */
  addRemoteSites: function() {
    var attrs = ['deviceMac', 'deviceConfig', 'deviceStatus'];
    var options = this.model.pick(attrs);
    options.parentRegion = this.ui.remoteSites;

    var sites = new RemoteSitesView(options);

    this.showChildView('remoteSites', sites);
  },

  /**
   * Adds the device status card
   */
  addNetworkHealth: function() {
    var healthView = new DeviceStatsView({
      deviceConfig: this.model.get('deviceConfig'),
      model: this.model,
    });
    healthView.model.set('page', 'device');

    this.showChildView('networkHealth', healthView);
  },

  /**
   * Adds the WAN details.
   */
  addWanDetails: function() {
    var wanView = new WanDetailsView({
      model: new Backbone.Model(this.model.pick(
        'deviceMac', 'deviceConfig', 'deviceStatus', 'statusCard', 'jobs'
      )),
    });

    this.showChildView('wanDetails', wanView);
  },

  /**
   * Adds the Ports overview section
   */
  addPortsOverView: function() {
    var attrs = ['deviceMac', 'deviceStatus', 'deviceConfig'];
    var viewOptions = this.model.pick(attrs);
    viewOptions.isVlanView = false;
    var view = new DrilledDownPortsOverView(viewOptions);

    this.showChildView('portsDetailsOverview', view);
  },

  /**
   * Adds the DNA config details.
   */
  addDnaDetails: function() {
    var detailsView = new DnaDetailsView({
      model: new Backbone.Model(this.model.pick(
        'deviceMac', 'deviceConfig', 'deviceStatus', 'statusCard', 'jobs'
      )),
    });

    this.showChildView('dnaDetails', detailsView);
  },

  /**
   * Adds the security details view.
   */
  addSecurityDetails: function() {
    var securityView = new SecurityDetailsView({
      model: new Backbone.Model(this.model.pick('deviceMac', 'deviceConfig', 'configState')),
    });

    this.showChildView('securityDetails', securityView);
  },

  /**
   * Adds the usage graphs.
   */
  addUsageDetails: function() {
    var mac = this.model.get('deviceMac').toLowerCase();
    var usage = this.model.get('bandwidth');
    var deviceStatus = this.model.get('deviceStatus');

    var trendView = new GraphItem({
      id: mac,
      usage: usage,
      deviceStatus: deviceStatus,
      graphType: 'dna',
    });

    var appsView = new TopAppsView(
      this.model.pick('deviceMac', 'deviceStatus')
    );

    var clientsView = new TopClientsView(
      this.model.pick('deviceMac', 'deviceStatus')
    );

    var tabPanel = new TabPanelView({
      tabs: [
        {
          id: 'trend',
          label: i18n.t('usageGraph.title'),
        },
        {
          id: 'apps',
          label: i18n.t('usageTopApps.title'),
        },
        {
          id: 'clients',
          label: i18n.t('usageTopClients.title'),
        },
      ],
      views: {
        trend: trendView,
        apps: appsView,
        clients: clientsView,
      },
    });

    this.showChildView('graphs', tabPanel);
  },
});
