'use strict';

/**
 * Enum for config card pages
 *
 * Important: these are assumed to match the category
 * keys provided by the server in the config outline
 */

module.exports = {
  SYSTEM: 'system',
  NETWORKS: 'networks',
  FIREWALL: 'firewall',
  APPLICATIONS: 'applications',
  FLEETMANAGEMENT: 'fleetManagement',
  DIAGNOSTICS: 'diagnostics',
};
