'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('manage/details/dna/dnaDetails/dna-details.html');
var FactoryResetTriggerView = require('components/factoryReset/FactoryResetTriggerView');
var FactoryResetTriggerModel = require('components/factoryReset/FactoryResetTriggerModel');
var RebootTriggerView = require('components/reboot/RebootTriggerView');
var i18n = require('i18next');

/**
 * Renders the DNA details (e.g. firmware version, etc.).
 */
module.exports = Marionette.View.extend({
  /**
   * @member {Backbone.Model} model
   * @property {String} deviceMac
   * @property {lib/models/DeviceConfiguration} attributes.deviceConfig
   * @property {lib/models/DeviceStatus} attributes.deviceStatus
   * @property {Backbone.Model} attributes.statusCard
   */

  template: twig({data: tpl}),

  regions: {
    reset: '.rg-factory-reset',
    reboot: '.rg-reboot',
  },

  ui: {
    version: '.value-version',
    dnsSection: '.section-dns',
    dnsName: '.value-dns',
  },

  onRender: function() {
    this.addResetTrigger();
    this.addRebootTrigger();
  },

  /**
   * Adds the "Factory Reset" button.
   */
  addResetTrigger: function() {
    var resetModel = new FactoryResetTriggerModel(this.model.pick(['deviceMac']));
    this.showChildView('reset', new FactoryResetTriggerView({model: resetModel}));
  },

  /**
   * Adds the "Reboot" button.
   */
  addRebootTrigger: function() {
    var attrs = ['deviceMac', 'deviceStatus', 'statusCard', 'jobs'];
    var model = new Backbone.Model(this.model.pick(attrs));
    this.showChildView('reboot', new RebootTriggerView({model: model}));
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.listenTo(this.model.get('deviceConfig').get('router'), 'change:dnsNameAuto', this.updateDnsName);
    this.listenTo(this.model.get('deviceStatus').get('router'), 'change:version', this.updateVersion);
    // serial number will never change for a given device
  },

  /**
   * @return {Object}
   */
  serializeData: function() {
    var authorizedCountryName = this.model.get('deviceConfig').get('router').get('authorizedCountryName');
    if (!authorizedCountryName) {
      authorizedCountryName = i18n.t('dnaDetails.authorizedCountryNotSet');
    }
    return {
      serialNumber: this.model.get('deviceConfig').get('mac'),
      countryName: authorizedCountryName,
      routerConfig: this.model.get('deviceConfig').get('router').toJSON(),
      routerStatus: this.model.get('deviceStatus').get('router').toJSON(),
    };
  },

  updateVersion: function(model, value) {
    this.ui.version.text(value);
  },

  updateDnsName: function(model, value) {
    this.ui.dnsName.text(value);
    this.ui.dnsSection.toggleClass('hidden', !value);
  },
});

