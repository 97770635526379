'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var ItemView = require('manage/edit/config/group/groupAlerts/GroupAlertView');
var NetworkDisruptionAlert = require('actionGroups/shared/NetworkDisruptionView');

/**
 * Displays a list of inline alert/warnings related to a config card. An example
 * of a warning is "Changes cannot be applied while your DNA is offline!".
 *
 * To interact with this view:
 * - instanceOfThisView.triggerMethod('add:alert', 'some-id', 'Some message to display!');
 * - instanceOfThisView.triggerMethod('remove:alert', 'some-id');
 *
 * To add an item using a custom GroupAlertView (@see manage/edit/config/group/groupAlerts/GroupAlertView):
 * instanceOfThisView.triggerMethod('add:custom:alert', myCustomGroupViewAlertInstace);
 */
module.exports = Marionette.CollectionView.extend({
  childView: ItemView,

  /**
   * @listens add:alert
   * @param {String} id
   * @param {String} msg
   *   The message to display. It can be HTML. Please be sure to sanitize it.
   */
  onAddAlert: function(id, msg) {
    this.collection.add(new Backbone.Model({
      id: id,
      msg: msg,
    }));
  },

  /**
   * Adds a child view directly to the list. This is useful when the alert has
   * behavior. For example, a message with a button to focus another config card.
   *
   * @listens add:custom:alert
   * @param {manage/edit/config/group/groupAlerts/GroupAlertView} childView
   */
  onAddCustomAlert: function(childView) {
    this.addChildView(childView);

    // addChildView does not add the View's model to the collection, we need to
    // manually attach it. Passing silent:true prevents duplicate events.
    this.collection.add(childView.model, {silent: true});
  },

  /**
   * @listens remove:alert
   * @param {String} id
   */
  onRemoveAlert: function(id) {
    this.collection.remove(id);
  },

  /**
   * Removes all alerts that extend NetworkDisruptionAlert
   */
  removeAllNetworkDisruptionAlerts: function() {
    var self = this;
    var toBeRemoved = this.collection.filter(function(alert) {
      var instance = false;

      var view = self.children.findByModel(alert);
      if (view) {
        instance = view instanceof NetworkDisruptionAlert;
      }

      return instance;
    });

    this.collection.remove(toBeRemoved);
  },
});
