'use strict';
var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');

/**
 * View-Model for global "LAN segregation" option.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} #attributes
   * @property {Boolean} mode
   * @property {Object} otherLan
   */

  _snapshotAttributes: ['mode', 'otherLan'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    // lan isolation config always exists
    return false;
  },

  // Needed to correctly determine whether the rules changed
  toJSON: function(options) {
    return _.cloneDeep(this.attributes);
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig) {
      var customRules = this.deviceConfig.get('firewall').get('vlanRouting').get('rules');
      var mode = this.deviceConfig.get('firewall').get('vlanRouting').get('mode');
      var otherLan = {};
      // If mode is not initially custom, select Internet for each vlan to automatically be selected
      // if mode is changed to custom.
      if (mode !== 'custom') {
        var networks = this.deviceConfig.getVlans({external: false});
        customRules = networks.map(function(lan) {
          return [lan.get('id'), 'wan'];
        });
      }
      for (var i in customRules) {
        if (customRules.hasOwnProperty(i)) {
          var rule = customRules[i];
          var src = rule[0];
          var dest = rule[1];
          if (otherLan.hasOwnProperty(src)) {
            otherLan[src].push(dest);
          } else {
            otherLan[src] = [dest];
          }
        }
      }

      return {
        mode: mode,
        otherLan: otherLan,
      };
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('firewall').get('vlanRouting');
      },
      events: 'change',
    },
  ],

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    var routingMode = this.get('mode');
    var customRules = [];
    if (routingMode === 'custom') {
      var selectedRules = this.get('otherLan');
      for (var src in selectedRules) {
        if (selectedRules.hasOwnProperty(src)) {
          var srcRules = selectedRules[src];
          for (var i in srcRules) {
            if (srcRules.hasOwnProperty(i)) {
              customRules.push([src, srcRules[i]]);
            }
          }
        }
      }
    }

    return {
      name: 'firewall.updateVlanRouting',
      data: {
        mode: routingMode,
        rules: customRules,
      },
    };
  },
});
