'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/speedDuplexSettings/static.html');
var twig = require('twig').twig;
var PortsLib = require('lib/ports');

/**
 * Renders a text-only version of the speed and duplex settings static view
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/speedDuplexSettings/StaticView#model
   * @type {actions/speedDuplexSettings/EditSpeedDuplexSettings}
   */

  template: twig({data: tpl}),

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var items = this.model.get('items').toJSON();
    var splitItems = PortsLib.splitPortsByPortNumber(items);

    return {
      items: splitItems,
    };
  },
});
