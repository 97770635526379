'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var scHelpers = require('lib/tplHelpers/statusCardHelpers');
var networkHelpers = require('lib/tplHelpers/networkHelpers');
var tplStats = require('components/deviceStats/device-stats.html');
var svg = require('components/deviceStats/health-widget.svg');

/**
 * Renders a DNA's health widget/stats.
 */
module.exports = Marionette.View.extend({
  /**
   * View-Model. Expects the model to have the attributes "statusCard" and
   * "page" ("device" or "fleetOverview").
   * @member {Backbone.Model} #model
   */

  template: twig({data: tplStats, allowInlineIncludes: true}),

  ui: {
    dna: '.dna',
    healthWidget: '.health-widget',
    wanNetwork: '.wan1',
    wan2Network: '.wan2',
    lteNetwork: '.wan3',
    wifiNetwork: '.wifi',
    lanNetwork: '.lan',
    internetIcon: '.internet',
    devicesIcon: '.devices',
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.xmlns = 'http://www.w3.org/2000/svg';

    /**
     * TODO
     * Better listeners, only change the parts we need to change
     * With the Health-Widget is such a high state of flux, re-rendering
     * will have to do for now
     */
    var debounced = _.debounce(this.render, 1);
    this.listenTo(this.model.get('statusCard'), {
      'change:connectedDevicesWifi': this.updateConnectedWifiText,
      'change:connectedDevices': this.updateConnectedDevices,
      'change:signalQuality': debounced,
      'change:connected': debounced,
      'change:activeWan': debounced,
      'change:primaryWan': debounced,
      'change:secondaryWan': debounced,
      'change:failedOver': debounced,
    });
  },

  onRender: function() {
    var self = this;
    var healthWidget = this.ui.healthWidget.append(svg);

    this.bindUIElements();

    if (healthWidget.length) {
      self.initSvg();
    }
  },

  /**
   * All our SVG magic
   */
  initSvg: function() {
    var statusCard = this.model.get('statusCard');
    var isConnected = this.model.get('statusCard').get('connected') === true;

    // SVG Icons
    this.addText('DNA', 26, 85).to(this.ui.dna);
    this.addText('Internet', -5, 60).to(this.ui.internetIcon);
    this.addText('Devices', 0, 70).to(this.ui.devicesIcon);

    this.initWifi(statusCard, isConnected);
    this.initLan(statusCard, isConnected);
    this.initWans(statusCard, isConnected);
    this.initLte(statusCard, isConnected);
  },

  /**
   * @param {Backbone.Model} statusCard
   * @param {Boolean} isDnaConnected
   */
  initWans: function(statusCard, isDnaConnected) {
    if (!isDnaConnected) {
      // Hardcoding port name is deliberate. If DNA is not connected, we are
      // just displaying grey bars.
      this.addText(networkHelpers.formatWanName('eth0'), 0, 27).to(this.ui.wanNetwork).addClass('bold');
      this.addText(networkHelpers.formatWanName('eth1'), 0, 27).to(this.ui.wan2Network).addClass('bold');

      return;
    }

    var wans = _.compact([statusCard.get('primaryWan'), statusCard.get('secondaryWan')]);
    _.each(wans, this._addWan.bind(this, statusCard));

    if (wans.length === 1) {
      // whoopsie, no secondary WAN
      this.addText('No Secondary WAN', 0, 27).to(this.ui.wan2Network).addClass('bold');
      this.ui.wanNetwork.addClass('no-wan2');
    }
  },

  /**
   * @param {Backbone.Model} statusCard
   * @param {Boolean} isDnaConnected
   */
  initLte: function(statusCard, isDnaConnected) {
    var modemState = scHelpers.networkInterfaceStatus(statusCard, 'lte');
    var lteStatus = this.getLTEStatusGivenModemStatus(modemState);
    var cellularQuality = 'sig-q0';

    if (lteStatus !== 'error') {
      cellularQuality = 'sig-q' + scHelpers.cellularQuality(statusCard);
    }

    this.addText('4G/LTE', 0, 27).to(this.ui.lteNetwork).addClass('bold');

    if (isDnaConnected) {
      this.addText(scHelpers.getWANText(modemState, true), 210, 27, 'end').to(this.ui.lteNetwork);
      this.ui.lteNetwork.addClass([lteStatus, cellularQuality].join(' '));
    }
  },

  /**
   * @param {Backbone.Model} statusCard
   * @param {Boolean} isDnaConnected
   */
  initLan: function(statusCard, isDnaConnected) {
    var vlanConflict = false;
    if (!_.isUndefined(this.model.get('deviceConfig'))) {
      vlanConflict = this.model.get('deviceConfig').getConflictingVlans().length > 0;
    }

    var lanStatus = scHelpers.getLanStatusClass(statusCard, vlanConflict);
    var connectedDevices = scHelpers.getLanText(statusCard, vlanConflict);

    this.addText('LAN', 45, 27).to(this.ui.lanNetwork).addClass('bold');

    if (isDnaConnected) {
      this.svgConnectedDevice = this.addText(connectedDevices, 256, 27, 'end').to(this.ui.lanNetwork);
      this.ui.lanNetwork.addClass(lanStatus);
    }
  },

  /**
   * @param {Backbone.Model} statusCard
   * @param {Boolean} isDnaConnected
   */
  initWifi: function(statusCard, isDnaConnected) {
    var wifiStatus = scHelpers.getWifiStatusClass(statusCard);
    var connectedWifiText = scHelpers.getWifiText(statusCard);

    this.addText('WIFI', 45, 27).to(this.ui.wifiNetwork).addClass('bold');

    if (isDnaConnected) {
      this.svgConnectedWifi = this.addText(connectedWifiText, 256, 27, 'end').to(this.ui.wifiNetwork);
      this.ui.wifiNetwork.addClass(wifiStatus);
    }
  },

  /**
   * Gets the lte state given the reported modem state.
   * @param {String} lteStatus
   * @return {String}
   */
  getLTEStatusGivenModemStatus: function(lteStatus) {
    var errorStates = [
      '',
      'incompatible_carriers',
      'no_carriers',
      'airplane_mode',
      'in_error',
      'unavailable',
      'persistent_error',
      'modem_unresponsive',
    ];

    if (_.isUndefined(lteStatus) || _.contains(errorStates, lteStatus)) {
      return 'error';
    }

    return lteStatus;
  },

  /**
   * Updates the number of connected wifi devices
   */
  updateConnectedWifiText: function() {
    var statusCard = this.model.get('statusCard');
    var connectedWifiText = scHelpers.getWifiText(statusCard);

    if (this.svgConnectedWifi) {
      this.svgConnectedWifi.remove();
    }
    this.svgConnectedWifi = this.addText(connectedWifiText, 256, 27, 'end').to(this.ui.wifiNetwork);
  },

  /**
   * Updates the number of connected lan devices
   */
  updateConnectedDevices: function() {
    var statusCard = this.model.get('statusCard');
    var connectedDevices = scHelpers.getLanText(statusCard);

    if (this.svgConnectedDevice) {
      this.svgConnectedDevice.remove();
    }
    this.svgConnectedDevice = this.addText(connectedDevices, 256, 27, 'end').to(this.ui.lanNetwork);
  },

  /**
   * Add text elements to the SVG
   *
   * @param {String} text
   * @param {Integer} left
   * @param {Integer} top
   * @param {String} anchor
   *   Either "middle" or "end". The value "start" is also valid, but it is
   *   the default, so there is no need to manually specify it.
   * @return {Element}
   */
  addText: function(text, left, top, anchor) {
    var newText = document.createElementNS(this.xmlns, 'text');
    newText.setAttributeNS(null, 'x', left);
    newText.setAttributeNS(null, 'y', top);

    if (anchor) {
      newText.setAttributeNS(null, 'text-anchor', anchor);
    }

    var textNode = document.createTextNode(text);
    newText.appendChild(textNode);
    newText.to = this.to;

    return newText;
  },

  /**
   * Appends an element to the SVG
   *
   * @param {Element} el
   * @return {jQuery}
   */
  to: function(el) {
    return $(el[0].appendChild(this));
  },

  /**
   * @return {Object}
   */
  serializeData: function() {
    var data = Marionette.View.prototype.serializeData.apply(this, arguments);
    data.statusCard = this.model.get('statusCard').toJSON();
    return data;
  },

  /**
   * _.each() callback to add a WAN's details.
   *
   * @param {Backbone.Model} statusCard
   * @param {Object} wan
   * @param {Number} i
   */
  _addWan: function(statusCard, wan, i) {
    var status = scHelpers.networkInterfaceStatus(statusCard, wan.id);
    var name = networkHelpers.formatWanName(wan.port);
    var uiHook = (i === 0 ? this.ui.wanNetwork : this.ui.wan2Network);

    this.addText(name, 0, 27).to(uiHook).addClass('bold');
    this.addText(scHelpers.getWANText(status, true), 210, 27, 'end').to(uiHook);
    uiHook.addClass(status);
  },
});
