'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/dhcpRelay/form.html');
var i18n = require('i18next');
var twig = require('twig').twig;
var RemoveConfig = require('manage/edit/config/action/removeConfig/RemoveConfig');
var RenderChanges = require('lib/behaviors/RenderChanges');
var tplHelpers = require('lib/tplHelpers');

/**
 * Renders the "dhcp relay" form action component.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/dhcpRelay/FormView#model
   * @type {actions/dhcpRelay/EditDhcpRelay}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: RemoveConfig,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    serverAddr: '[name="serverAddr"]',
    ipWarning: '.ip-warning',
  },

  events: {
    'blur @ui.serverAddr': 'saveForm',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  saveForm: function() {
    var serverAddr = this.ui.serverAddr.val();
    this.ui.serverAddr.bs3ui('clearFieldError');
    this.model.set('serverAddr', serverAddr, {commit: true});
  },

  templateContext: function() {
    return _.extend(tplHelpers.apply(this), {
      pendingDeleteMsg: this.model.getDeleteMsg.bind(this.model),
    });
  },

  onRender: function() {
    if (this.model.get('pendingDelete') === true) {
      return;
    }

    if (this.model.hasValidSubnet()) {
      this.ui.serverAddr.removeClass('hidden');
    } else {
      this.ui.serverAddr.addClass('hidden');
      var msg = i18n.t('actionDhcp.noSubnetDefinedRelay');
      this.ui.ipWarning.bs3ui('inlineStatus', this.ui.ipWarning, 'warning', msg);
    }
  },

  onError: function(model, error, options) {
    this.ui.serverAddr.bs3ui('showFieldError', error.serverAddr);
  },

});
