'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var detailTypes = require('manage/details/detailTypes');
var console2 = require('lib/Console');

var DnaView = require('manage/details/dna/DnaView');
var DnaViewModel = require('manage/details/dna/DnaViewModel');

var VlanView = require('manage/details/vlan/VlanView');
var VlanViewModel = require('manage/details/vlan/VlanViewModel');

var WifiView = require('manage/details/wifi/WifiView');
var WifiViewModel = require('manage/details/wifi/WifiViewModel');

var VpnStatusView = require('manage/details/vpn/VpnStatusView');
var VpnStatusModel = require('manage/details/vpn/VpnStatusModel');

var ConnectedDeviceView = require('manage/details/connectedDevice/ConnectedDeviceView');
var ConnectedDeviceModel = require('manage/details/connectedDevice/ConnectedDeviceModel');

var PortsDetailsView = require('manage/details/ports/PortsDetailsView');
var PortsDetailsModel = require('manage/details/ports/PortsDetailsModel');

/**
 * Registry of available details Views.
 */
var types = [
  // DNA
  {
    slugMatcher: /^$/,
    getViewClass: function() {
      return DnaView;
    },

    getModelClass: function() {
      return DnaViewModel;
    },

    getAttrs: function() {
      return {};
    },
  },
  // Network
  {
    slugMatcher: /^networks\/([^/]+)\/?$/,

    getViewClass: function(deviceConfig, deviceStatus, urlAttrs) {
      var network = deviceConfig.get('networks').get(urlAttrs.id);
      if (!network) {
        return null;
      }
      var type = network.get('type');

      switch (type) {
        case 'vlan':
          return VlanView;
        case 'wifi':
          return WifiView;
        case 'vpn':
          return VpnStatusView;
        default:
          return null;
      }
    },

    getModelClass: function(deviceConfig, deviceStatus, urlAttrs) {
      var network = deviceConfig.get('networks').get(urlAttrs.id);
      if (!network) {
        return null;
      }
      var type = network.get('type');

      switch (type) {
        case 'vlan':
          return VlanViewModel;
        case 'wifi':
          return WifiViewModel;
        case 'vpn':
          // disabled VPN networks shouldn't be navigable
          return network.get('enabled') ? VpnStatusModel : null;
        default:
          return null;
      }
    },

    getAttrs: function(matches) {
      return {id: matches[0]};
    },
  },
  // Connected device
  {
    slugMatcher: /^networks\/([^/]+)\/clients\/([^/]+)\/?$/,

    getViewClass: function() {
      return ConnectedDeviceView;
    },

    getModelClass: function(deviceConfig, deviceStatus, urlAttrs) {
      var networkClientInfo = deviceStatus.get('connectedDevices').get(urlAttrs.vlanId);
      if (!networkClientInfo) {
        return null;
      }
      var device = _.find(networkClientInfo.get('devices'), function(deviceObj) {
        return deviceObj.id === urlAttrs.id;
      });
      if (!device) {
        return null;
      }

      return ConnectedDeviceModel;
    },

    getAttrs: function(matches) {
      return {id: matches[1], vlanId: matches[0]};
    },
  },
  // Ports Details page
  {
    slugMatcher: /^ports\/?$/,

    getViewClass: function() {
      return PortsDetailsView;
    },

    getModelClass: function(deviceConfig, deviceStatus, urlAttrs) {
      return PortsDetailsModel;
    },

    getAttrs: function(matches) {
      return {};
    },
  },
];

/**
 * When viewing a DNA, there are a number of status pages the user can browse.
 * This object is meant to encapsulate the logic for building a status page
 * View instance, as well as building the URL for a particular status page.
 */
module.exports = Marionette.Object.extend({
  channelName: 'deviceConfigChannel',

  radioRequests: {
    'get:url': 'getUrl',
    'trim:url': 'trimUrl',
  },

  /**
   * Gets a details View.
   *
   * @param {String} slug
   *   The portion of the url w/o the DNA's mac address.
   * @param {Object} attrs
   *   Attributes to pass to the View-Model.
   * @param {lib/models/DeviceConfiguration} deviceConfig
   *   Device configuration for lookup and validation
   * @param {lib/models/DeviceStatus} deviceStatus
   *   Device status for lookup and validation
   * @return {Marionette.View}
   */
  factory: function(slug, attrs, deviceConfig, deviceStatus) {
    var matches;
    slug = slug || '';

    var typeObj = _.find(types, function(typeModel, i) {
      matches = slug.match(typeModel.slugMatcher);
      return matches !== null;
    });

    if (!typeObj) {
      console2.log('error', 'Unable to match details type', slug, attrs);
      throw new Error('Unable to determine details type from ' + slug);
    }

    var urlAttrs = typeObj.getAttrs(matches.splice(1));

    var View = typeObj.getViewClass(deviceConfig, deviceStatus, urlAttrs);
    var Model = typeObj.getModelClass(deviceConfig, deviceStatus, urlAttrs);

    if (!View || !Model) {
      console2.log('error', 'Unable to resolve view/model for', slug, attrs);
      throw new Error('Unable to resolve view/model from ' + slug);
    }

    attrs = _.extend({}, attrs, urlAttrs);

    return new View({model: new Model(attrs)});
  },

  /**
   * Gets the URL for a status page (e.g. /<mac>/vlan2).
   *
   * @param {String} type
   *   The type of status page. See {@link manage/details/detailTypes}.
   * @param {String} deviceMac
   * @param {String} typeId
   *   The Id of the entity (e.g. vlan2)
   * @param {Object} options
   *   Extra options needed to build the URL.
   * @property {String} options.parentVlan
   *   Only for connected devices, include the vlan they are associated with.
   * @return {String}
   */
  getUrl: function(type, deviceMac, typeId, options) {
    var baseUrl = '/' + encodeURIComponent(deviceMac);

    switch (type) {
      case detailTypes.DNA:
        return baseUrl;

      case detailTypes.CONNECTED_DEVICE:
        // <parentVlan>/device<typeId> (id is the mac)
        return baseUrl + '/networks/' + encodeURIComponent(options.parentVlan) +
          '/clients/' + encodeURIComponent(typeId);

      case detailTypes.PORTS:
        return baseUrl + '/ports';

      default:
        // <typeId> (e.g. vlan1, cfg033579, etc)
        return baseUrl + '/networks/' + encodeURIComponent(typeId);
    }
  },

  /**
   * Strips the device mac from a status page URL.
   *
   * @see DetailsViewFactory#getUrl()
   * @param {String} url
   *   Expects a URL structured like "/deviceMac/rest/of/url".
   * @return {String}
   */
  trimUrl: function(url) {
    // //FIXME Assumes a URL that always starts with "/<deviceMac>/"
    return url.substring(14);
  },
});
