'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;

/**
 * Individual config card alert/warning.
 *
 * @see manage/edit/config/group/groupAlerts/GroupAlertsView
 */
module.exports = Marionette.View.extend({
  /**
   * @member {Backbone.Model} #model
   * @property {String} attributes.id
   * @property {String} attributes.msg
   *   The alert message. Can be HTML.
   */

  // Note, the extra SPAN is to help the CSS. Flexbox is used to position the
  // icon and the message. The SPAN provides a safety for alert messages that
  // may contain additional HTML (e.g. a button).
  template: twig({data: '<span>{{msg}}</span>'}),

  tagName: 'span',
  className: 'help-block has-icon',

  onRender: function() {
    this.$el.prepend('<i class="fas fa-exclamation-circle"/> ');
  },
});
