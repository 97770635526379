'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var MultiActionItem = require('actions/shared/MultiActionItem');
var EditPolicy = require('actions/failoverPolicyControl/policies/Policy');

/**
 * Edit model for Failover Policy Control
 */
module.exports = MultiActionItem.extend({

  collectionModel: EditPolicy,

  _snapshotAttributes: ['mode'],

  /**
   * Overridden to always return false
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * Validates custom policy models if custom is enabled
   *
   * @see actions/shared/MultiActionItem#validate
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    if (this.get('mode') === 'custom') {
      return MultiActionItem.prototype.validate.apply(this, arguments);
    }
  },

  /**
   * Gets the task for changing the mode if need be,
   * If the new mode is custom, then get the task for each
   * model in the wrapped collection
   *
   * @see actions/shared/MultiActionItem#getTask
   * @return {Array|undefined}
   */
  getTask: function() {
    var initialTask = [];
    if (this.getSnapshotDiff().mode !== undefined) {
      initialTask = [{
        name: 'failoverPolicyControl.editMode',
        data: {
          mode: this.get('mode'),
        },
      }];
    }

    if (this.get('mode') === 'custom') {
      var tasks = this.get('items').reduce(function(memo, item) {
        var task = item.getTask();
        if (task) {
          memo = memo.concat(task);
        }
        return memo;
      }, initialTask);

      if (tasks.length > 0) {
        return tasks;
      }
    } else if (initialTask.length > 0) {
      return initialTask;
    }
  },

  /**
   * Overridden to include the diff of the wrapped collection
   *
   * @see actions/shared/MultiActionItem#getSnapshotDiff
   * @return {Boolean|Object}
   */
  getSnapshotDiff: function() {
    var diff = ActionItem.prototype.getSnapshotDiff.apply(this, arguments);
    if (this.get('mode') === 'custom') {
      if (diff === false) {
        return this.get('items').getSnapshotDiff();
      }
      return _.extend(diff, this.get('items').getSnapshotDiff());
    }
    return diff;
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      return {
        mode: this.deviceConfig.get('failoverPolicyControl').get('mode'),
        items: this.deviceConfig.get('failoverPolicyControl').get('policies'),
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('failoverPolicyControl');
      },
      events: 'change:mode change:policies',
    },
  ],

});
