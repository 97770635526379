'use strict';

var i18n = require('i18next');
var UpdateSchedule = require('actions/router/updateSchedule/EditUpdateSchedule');
var FormView = require('actions/router/updateSchedule/FormView');
var StaticView = require('actions/router/updateSchedule/StaticView');

/**
 * Action configuration for the update window components
 */
module.exports = {
  title: i18n.t('actionUpdateSchedule.title'),
  Model: UpdateSchedule,
  FormView: FormView,
  TextView: StaticView,
};
