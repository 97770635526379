'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/domainFilters/filter.html');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');

module.exports = Marionette.View.extend({
  /**
   * @name actions/domainFilters/FormView#model
   * @type {actions/domainFilters/EditDomainFilters}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    'domain': '[name="domain"]',
    'type': '.filterType',
    'delete': '.btn-remove',
    'messages': '.help-block',
  },

  events: {
    'blur @ui.domain': 'updateDomain',
    'change @ui.type': 'updateType',
    'click @ui.delete': 'delete',
  },

  modelEvents: {
    'invalid': 'onError',
    'change:remove': 'render',
    'noConflicts': 'onNoConflicts',
  },

  templateContext: function() {
    return {
      index: this.options.index,
    };
  },

  updateDomain: function(ev) {
    this.ui.domain.bs3ui('clearGroupedFieldError', 0, this.ui.messages);
    this.model.set({domain: ev.currentTarget.value}, {validateAll: true});
  },

  updateType: function(ev) {
    this.model.set({type: ev.currentTarget.value}, {commit: true});
  },

  delete: function() {
    if (this.model.isNew()) {
      this.model.collection.remove(this.model);
    } else {
      var shouldRemove = !this.model.get('remove');

      if (shouldRemove) {
        this.model.applySnapshot();
      }

      this.model.set({remove: shouldRemove});
    }
  },

  onError: function(model, error, options) {
    if (error.domain) {
      this.ui.domain.bs3ui('showGroupedFieldError', error.domain, 0, this.ui.messages);
    }
  },

  /**
   * Clear any "special" errors related to domain conflicts
   */
  onNoConflicts: function() {
    this.ui.domain.bs3ui('clearGroupedFieldError', 0, this.ui.messages);
  },
});
