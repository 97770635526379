'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var ActionItem = require('actions/shared/ActionItem');
var networkUtils = require('lib/network');

/**
 * Edit model for Global DNS
 */
module.exports = ActionItem.extend({

  _snapshotAttributes: ['searchDomain'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      resp = {};
      var searchDomain = this.deviceConfig.get('globalDns').get('searchDomain');
      if (_.isUndefined(searchDomain) || _.isNull(searchDomain)) {
        return {
          searchDomain: '',
        };
      }
      return {
        searchDomain: searchDomain,
      };
    }

    return resp;
  },

  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'searchDomain') && attrs.searchDomain !== '') {
      if (!networkUtils.validHostname(attrs.searchDomain)) {
        errors.searchDomain = i18n.t('actionGlobalDns.badHostname');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('globalDns');
      },
      events: 'change',
    },
  ],

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    return {
      name: 'searchDomain.setSearchDomain',
      data: {
        search: this.get('searchDomain'),
      },
    };
  },
});
