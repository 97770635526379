'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var poolTpl = require('manage/details/vlan/configs/dhcpPool.html');
var Ip = require('lib/Ip');

var classProps = {
  /**
   * @param {lib/models/Network} network
   * @return {Boolean}
   */
  isApplicable: function(network) {
    return network.get('role') !== 'external';
  },
};

/**
 * Renders the dhcp pool info section for the selected network
 */
module.exports = Marionette.View.extend({
  /**
   * Reference to a {@link lib/models/DeviceConfiguration} "networks" object.
   * @member {Backbone.Model} manage/details/vlan/configs/AddressView#model
   */

  /**
   * A reference to deviceConfig.
   * @member {lib/models/DeviceConfiguration} manage/details/vlan/configs/DhcpPoolView#deviceConfig
   */

  /**
   * A reference to deviceStatus.
   * @member {lib/models/DeviceStatus} manage/details/vlan/configs/DhcpPoolView#deviceStatus
   */

  template: twig({data: poolTpl}),

  /**
   * @param {Object} options
   * @property {lib/models/DeviceConfiguration} options.deviceConfig
   */
  initialize: function(options) {
    this.deviceConfig = options.deviceConfig;
  },

  /**
   * @return {Object}
   */
  serializeData: function() {
    return this.getNetworkPools();
  },

  /**
   * Determines if a network has an associated dhcp pool, and loads the data
   * for it
   *
   * @returns {object}
   */
  getNetworkPools: function() {
    var poolObj = this.deviceConfig.getDhcpPool(this.model.id);

    if (!_.isUndefined(poolObj)) {
      poolObj = poolObj.toJSON();
      poolObj.poolSize = Ip.ip2long(poolObj.endIp) - Ip.ip2long(poolObj.startIp);
    }

    return poolObj || {};
  },
}, classProps);
