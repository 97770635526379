'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var twig = require('twig').twig;
var tpl = require('actions/wanPriority/static.html');
var i18n = require('i18next');

module.exports = BaseStaticView.extend({
  template: twig({data: tpl}),

  getTemplate: function() {
    /**
     * Again, assuming we will only have 2 WANs
     * Will need to be revisited if we support > 2 WANS
     */
    if (Object.keys(this.model.getWans()).length !== 2) {
      return twig({data: ''});
    }

    return twig({data: tpl});
  },

  templateContext: function() {
    var primary = this.model.get('isPrimary');

    return {
      firstOrSecond: primary ? i18n.t('actionWanPriority.primary') : i18n.t('actionWanPriority.secondary'),
    };
  },
});
