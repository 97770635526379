'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/dhcpAdvOptions/DHCPOption/DHCPOptionForm.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the editable form elements for a single DHCP option
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/dhcpAdvOptions/FormView#model
   * @type {actions/dhcpAdvOptions/DHCPOption/DHCPOption}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    optionsDropdown: '[name="optionsDropdown"]',
    dhcpOptionValues: '[name="dhcpOptionValues"]',
    removeBtn: '.btn-remove',
    messages: '.help-block',
  },

  events: {
    'blur @ui.dhcpOptionValues': 'updateModel',
    'change @ui.optionsDropdown': 'updateOptionBasedOnSelect',
    'click @ui.removeBtn': 'handleRemoveButton',
  },

  modelEvents: {
    'change:remove': 'render',
    'change:availableOptions': 'render',
    'change:optionId': 'render',
    'invalid': 'onError',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var context = {};
    return _.extend(context, tplHelpers.apply(this));
  },

  onBeforeRender: function() {
    if (_.isEmpty(this.model.get('values')) && !_.isNull(this.model.get('optionId'))) {
      this.model.updateInfoFromOptionId(this.model.get('optionId'));
    }
  },

  onRender: function() {
    this.onError(this.model, this.model.get('errors'), {});
  },

  /**
   * Sync changes in UI input elements to the model and trigger validation
   *
   * @param {Object} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;
    var dataId = event.currentTarget.getAttribute('data-id');
    var value = event.currentTarget.value;

    this.clearError(name, dataId);

    switch (name) {
      case 'dhcpOptionValues':
        this.model.set('values', [{name: dataId, value: value}], {commit: true});
        break;
      default:
        break;
    }
  },

  updateOptionBasedOnSelect: function(event) {
    var number = event.target.value;
    this.model.updateInfoFromOptionId(number);

    this.render();
  },

  /**
   * Remove this option
   */
  handleRemoveButton: function() {
    var shouldRemove = true;
    if (!this.model.isNew()) {
      shouldRemove = !this.model.get('remove');
      if (shouldRemove) {
        // if you ask to remove an existing option, roll back any local changes
        this.model.applySnapshot();
      }
    }
    this.model.set({remove: shouldRemove});
  },

  /**
   * Decorates the view to indicate error.
   *
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      if (key === 'values') {
        _.forEach(value, function(val) {
          var elm = this.ui.dhcpOptionValues.filter('input[data-id="' + val.name + '"]');
          var errorMsg = val.errorMsg;

          elm.bs3ui('showFieldError', errorMsg);
        }.bind(this));
      }
    }, this);
  },

  /**
   * Remove an error message from the view (if present)
   *
   * @param {String} key - Identifier for the error message
   * @param {String} dataId - Identifier for the input field
   */
  clearError: function(key, dataId) {
    var elm = key === 'dhcpOptionValues' ?
              this.ui.dhcpOptionValues.filter('input[data-id="' + dataId + '"]') :
              this.ui[key];
    elm.bs3ui('clearFieldError');
  },
});
