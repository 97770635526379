'use strict';

var _ = require('lodash');
var appConfig = require('./../appConfig.prod.js');

/**
 * Logging utility (singleton) for logging messages to the console.
 *
 * Note, this object is not for every-day development purposes. For that, you
 * can still use the browser's "console.*" methods (just remember that eslint
 * will yell when building the production build). This object provides a
 * wrapper so that if the "debug" flag in your appConfig.js is true, the log
 * message will appear. Therefore, use this object for debug messages that
 * are useful to developers and want them to be a part of the codebase.
 *
 * @example
 * var console = require('lib/Console');
 * console.log('log', 'Message for console');
 *
 * @class
 * @name lib/Logger
 */
function Console() {
  this.debug = (appConfig.debug === true);
}

Console.prototype = {
  /**
   * Wrapper for console.* methods.
   *
   * @param {String} method
   *   debug, error, info, log, warn
   * @param {String} msg
   */
  log: function(method, msg) {
    var args = Array.prototype.slice.call(arguments, 1);
    if (this.debug) {
      args = _.map(args, function(arg) {
        return _.isObject(arg) ? _.cloneDeep(arg) : arg;
      });

      console[method].apply(console, args); // eslint-disable-line no-console
    }
  },
};

module.exports = new Console();
