'use strict';

var AbstractActionManager = require('config/AbstractActionManager');

/**
 * Swaps in the correct action when the user clicks on add custom DNS settings
 */
module.exports = AbstractActionManager.extend({

  initialize: function() {
    this.listenOnActions('actionModel:addCustomDns', this._addCustomDnsForm, 'wanDnsPlaceholder');

    this.listenOnActions('remove', this._onRemoveCustomDns, 'wanDns');
    this.listenOnActions('add', this._onAddCustomDns, 'wanDns');

    this.listenOnActions('remove', this._onRemoveCustomDns, 'lteDns');
    this.listenOnActions('add', this._onAddCustomDns, 'lteDns');
  },

  _addCustomDnsForm: function() {
    // dont allow user to add custom dns when web filters dns is configured.
    if (this.getAction('wanWebFilterDns')) {
      return;
    }

    var actionName = 'wanDns';
    if (this.getGroupType() === 'editLte') {
      actionName = 'lteDns';
    }

    this.addAction(actionName);
  },

  _onAddCustomDns: function(model, collection, options) {
    // If the change came from the server it will remove the place holder for us.
    if (options && options.parse) {
      return;
    }

    this.removeAction('wanDnsPlaceholder');
  },

  _onRemoveCustomDns: function(model, collection, options) {
    // If the change came from the server it will add the place holder for us.
    // Additionally dont allow a user to add custom dns when web filters dns is
    // configured via the dns place holder button. This becomes a problem because when we
    // enable web filters a remove is triggered on both lteDns and wanDns.
    if ((options && options.parse) || this.getAction('wanWebFilterDns')) {
      return;
    }

    this.addAction('wanDnsPlaceholder');
  },
});
