'use strict';

var Marionette = require('backbone.marionette');

/**
 * Behavior for automatically disabling/enabling all other
 * form fields as a config item is turned off or on.
 *
 * On the most basic level, this behavior is activated
 * by setting the view's "isItemOff" property to an
 * appropriate true/false value and calling
 * `triggerMethod('onoff:status:change')` on the affected
 * view
 *
 * This will ensure that the "off" state is re-applied
 * after any re-render of the host view
 *
 * For actions that declare an on/off state, these triggers
 * are automated; see ActionItem#getOnOffState, etc.
 *
 * If the host view uses child views, ensure that render
 * events from children are forwarded to the host view as
 * "childview:item:render" events; if they are wrapped in
 * a CollectionView, you can do this simply by adding the
 * following to the CollectionView:
 *
 * childViewTriggers: {
 *   'render': 'item:render',
 * },
 *
 * As an optimization for actions that have child views and
 * can be disabled as a whole, also set "isAddingChildViewsOnRender"
 * to true on the host view while child views are being added
 * a result of the main view rendering (i.e. in the onRender
 * method, before making any calls to this.showChildView). This
 * prevents duplicate work updating the state of each child
 * view when the entire form is about to be updated anyway.
 *
 * If child view items themselves can be disabled individually,
 * add the following to wire them up to this behavior:
 *
 * - Ensure their `isItemOff` property is set appropriately
 *  prior to rendering (e.g. onBeforeRender)
 *
 * - Whenever their model's on/off state changes after this,
 * update isItemOff and call `triggerMethod('onoff:status:change')`
 * (i.e. via a modelEvents handler)
 *
 * - Ensure this event is forwarded from any wrapping
 * CollectionView, e.g. in the CollectionView:
 *
 * childViewTriggers: {
 *   'render': 'item:render',
 *   'onoff:status:change': 'item:onoff:status:change',
 * },
 *
 * Note that child view on/off state can be used with or
 * without an on/off state on the host view
 *
 * Options:
 * @param {Function} enableCallback
 *   Function to be called when the item is turned on and fields have been enabled
 *   (Provides a chance for the view to re-calculate which fields should remain
 *   disabled for other reasons)
 *   - `this` will be set to the host view
 *   - one argument is passed: the view at the top of the hierarchy in
 *     which elements have been enabled (the host view or a child view)
 */
module.exports = Marionette.Behavior.extend({

  onOnoffStatusChange: function() {
    this.updateInputState(this.view);
  },

  onRender: function() {
    // this fires after the host view's `onRender` method,
    // so all child views should be present by this point

    // we only need to update things if the feature is in an
    // OFF state, because fields render enabled by default
    if (this.view.isItemOff) {
      this.updateInputState(this.view);
    }
  },

  onChildviewItemOnoffStatusChange: function(childView) {
    // if the host view is in an OFF state, that trumps any
    // on/off state for child views
    if (!this.view.isItemOff) {
      this.updateInputState(childView);
    }
  },

  onChildviewItemRender: function(childView) {
    // if neither the host view nor child view are in an OFF state,
    // there is nothing to do (fields render enabled by default)
    if (!this.view.isItemOff && !childView.isItemOff) {
      return;
    }

    // if the child view rendered because the host view is rendering,
    // and the host view is in an off state, this will be handled in
    // a single pass at the end for the entire form via onRender()
    if (this.view.isAddingChildViewsOnRender && this.view.isItemOff) {
      return;
    }

    this.updateInputState(childView);
  },

  updateInputState: function(view) {
    var isOff = !!this.view.isItemOff;
    var onOffContainer = '.onoff';

    // if the host view is in an ON state, allow
    // child-level on/off state to control
    if (!isOff && view !== this.view) {
      isOff = view.isItemOff;
      onOffContainer = '.onoff-item';
    }

    view.$el.find('input, select, button')
      .not(onOffContainer + ' input')
      .not(onOffContainer + ' button')
      .prop('disabled', isOff);

    view.$el.find('.radio, .checkbox')
      .not(onOffContainer)
      .toggleClass('disabled', isOff);

    // disable links by moving their "href" to a temporary attribute
    if (isOff) {
      view.$el.find('a[href]')
        .attr('data-disabled-href', function() {
          return this.getAttribute('href');
        })
        .removeAttr('href');
    } else {
      view.$el.find('a[data-disabled-href]')
        .attr('href', function() {
          return this.getAttribute('data-disabled-href');
        })
        .removeAttr('data-disabled-href');
    }

    if (!isOff && this.options.enableCallback) {
      this.options.enableCallback.call(this.view, view);
    }
  },

});
