'use strict';

var _ = require('lodash');
var AbstractEditWanAddress = require('actions/staticAddress/wan/AbstractEditWanAddress');
var i18n = require('i18next');
var networkUtils = require('lib/network');
var ipAddr = require('ipaddr.js');

/**
 * View-Model for creating/editing a a static WAN address.
 */
module.exports = AbstractEditWanAddress.extend({
  _snapshotAttributes: ['af', 'method', 'address', 'size', 'gateway', 'pendingDelete', 'secondaryIps'],

  defaults: {
    usage: 'client',
    af: 'ipv6',
    role: 'external',
    method: 'static',
    pendingDelete: false,
    size: 64,
  },

  /**
   * @param {String} address
   * @param {String} type
   * @param {Object} errors
   * @return {Object}
   */
  addressInSubnet: function(address, type, errors) {
    var primaryIp = this.get('address');
    var mask = parseInt(this.get('size'));

    if (_.isEmpty(address) || mask <= 0 || isNaN(mask)) {
      return errors;
    }

    if (!networkUtils.validIP(primaryIp, 'ipv6')) {
      errors.address = i18n.t('actionStaticAddress.badIP');
      return errors;
    }

    if (!networkUtils.validIP(address, 'ipv6')) {
      errors[type] = {subnet: i18n.t('actionStaticAddress.badIP')};
      return errors;
    }

    var cidr = ipAddr.parseCIDR(primaryIp + '/' + mask);
    var parsedAddress = ipAddr.parse(address);
    if (!parsedAddress.match(cidr)) {
      errors[type] = {subnet: i18n.t('actionStaticAddress.addressNotInRangeIpv6')};
    }

    return errors;
  },

  /**
   * @return {Object}
   */
  getTask: function() {
    if (this.get('pendingDelete') === true) {
      return {
        name: 'vlan.create',
        data: {
          id: this.id,
          addressIpv6: {
            method: 'dhcp',
          },
        },
      };
    }

    var data = {
      addressIpv6: this.pick(_.without(this._snapshotAttributes, 'pendingDelete')),
    };

    data.addressIpv6.method = 'static';

    if (!this.isNew()) {
      data.id = this.id;
    }

    return {
      name: 'vlan.create',
      data: data,
    };
  },

  /**
   * Ipv6 is interfaces are reported separately from their ipv4 counterparts, but the UI displays them as one network.
   * The config blob will report an interface for vlan3 and vlan3-v6 but if the user was to edit or view vlan3 they
   * would see info for both networks. The getInterfaceId method was created to help with this.
   *
   * @return {string}
   */
  getInterfaceId: function() {
    return this.id + networkUtils.ipv6PostFix;
  },
});
