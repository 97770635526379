'use strict';

var i18n = require('i18next');
var EditDhcpRelay = require('actions/dhcpRelay/EditDhcpRelay');
var FormView = require('actions/dhcpRelay/FormView');
var StaticView = require('actions/dhcpRelay/StaticView');

/**
 * Action configuration for the DHCP Relay action component.
 */
module.exports = {
  title: i18n.t('actionDhcp.dhcpRelay'),
  Model: EditDhcpRelay,
  FormView: FormView,
  TextView: StaticView,
  dependsOn: ['lanAddress'],
};
