'use strict';

var _ = require('lodash');
var rpcMixin = require('lib/mixins/rpc2');
var ReadOnly = require('backbone.readonly');
var filterSubnets = require('lib/mixins/filterSubnets');
var nestedModel = require('lib/mixins/nestedModel');
var capabilities = require('lib/mixins/capabilities');

var Model = ReadOnly.Model
  .extend(rpcMixin)
  .extend(nestedModel)
  .extend(filterSubnets)
  .extend(capabilities);

/**
 * Loader for the device status call. Creates attributes to be used and share
 * in the UI
 */
module.exports = Model.extend({
  /**
   * @member {Object} lib/models/DeviceStatus#attributes
   * @property {String} deviceMac
   * @property {Number} timestamp
   *   When the status snapshot was taken.
   * @property {ReadOnly.Collection} networks
   *  The WAN/LAN/LTE networks associated with the device and if they are up
   * @property {ReadOnly.Collection} ports
   *  Available ports on the device and if anything is connected to them
   * @property {ReadOnly.Collection} addresses
   *  List of addresses
   * @property {ReadOnly.model} router
   *  Router specific status
   * @property {ReadOnly.Model} wifi
   *  Router specific wifi statuses
   * @property {ReadOnly.Model} failover
   *  Failover details
   */

  rpc: {
    read: [
      {
        method: 'DNA.Portal.Device.getStatus',
        params: function() {
          return {
            mac: this.get('deviceMac'),
          };
        },
      },
    ],
  },

  /**
   * Registry of attribute types.
   *
   * @private
   * @type {Object}
   */
  attributeTypes: {
    networks: ReadOnly.Collection,
    ports: ReadOnly.Collection,
    subnets: ReadOnly.Collection,
    dns: ReadOnly.Collection,
    router: ReadOnly.Model,
    connectedDevices: ReadOnly.Collection,
    wifi: ReadOnly.Model,
    failover: ReadOnly.Model,
    remoteSites: ReadOnly.Collection,
    nicBonds: ReadOnly.Collection,
  },

  /**
   * Take the returned status call and break it into collections
   * and models to be consumed by the UI.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   *   The modified response.
   */
  parse: function(resp, options) {
    var self = this;
    var respClone;

    if (!_.isUndefined(resp.error)) {
      // TODO error handling
      return {};
    }

    // clone to prevent mutating original parameter
    respClone = _.cloneDeep(resp);

    // data location depends on whether it is coming from an API call or eventsource message
    respClone = respClone.result || respClone;

    respClone.mac = respClone.mac.toUpperCase();

    _.each(this.attributeTypes, function(AttributeType, attrName) {
      if (self.has(attrName)) {
        self.get(attrName).set(respClone[attrName], options);
        delete respClone[attrName];
      } else {
        respClone[attrName] = new AttributeType(respClone[attrName], options);
      }
    });

    return respClone;
  },

});
