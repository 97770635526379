'use strict';

var i18n = require('i18next');
var EditNat = require('actions/outboundNat/EditNat');
var FormView = require('actions/outboundNat/FormView');
var StaticView = require('actions/outboundNat/StaticView');

/**
 * Action configuration for Outbound NAT
 */
module.exports = {
  title: i18n.t('groups.outboundNat'),
  Model: EditNat,
  FormView: FormView,
  TextView: StaticView,
};
