'use strict';

var EditSiteVpn = require('actionGroups/editSiteVpn/EditSiteVpn');
var i18n = require('i18next');
var configPage = require('lib/configPage');

var staticProps = {
  isCreational: true,
  creationalPage: configPage.NETWORKS,
};

// maximum site to site topologies
var MAX_VPN_TOPOLOGIES = 2040;

/**
 * "Create SiteVpn" configuration group.
 */
module.exports = EditSiteVpn.extend({
  defaults: {
    isDirty: true,
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return true;
  },

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.editSiteVpn');
  },

  /**
   * Indicates if the user can create a new S2S topology network.
   *
   * @param {config/ConfigModel} configOutline
   * @return {Boolean}
   */
  allowNew: function(configOutline) {
    var topologies = configOutline.filterGroups(function(group) {
      var type = group.get('type');
      return type === 'editSiteVpn' || type === 'createSiteVpnGroup';
    });
    this.numTopologies = topologies.length;
    return topologies.length < MAX_VPN_TOPOLOGIES;
  },

  /**
   * Provides a user message indicating why a new VPN topology
   * cannot be created.
   *
   * @return {String}
   */
  disallowedNewReason: function() {
    if (this.numTopologies >= MAX_VPN_TOPOLOGIES) {
      return i18n.t('groups.addS2STopologyLimit', {limit: MAX_VPN_TOPOLOGIES});
    }
    return i18n.t('configEdit.capabilityMissing');
  },
}, staticProps);
