'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tplHelpers = require('lib/tplHelpers');

/**
 * Renders the top items view.
 */
module.exports = Marionette.View.extend({

  className: 'top-apps placeholder-container',

  ui: {
    content: '.top-items-content',
    status: '.top-items-status',
  },

  regions: {
    chart: '.rg-chart',
    key: '.rg-key',
  },

  childViewEvents: {
    'hover:chart': 'onChartHover',
    'hover:key': 'onKeyHover',
  },

  templateContext: function() {
    var baseHelpers = tplHelpers.apply(this);

    return _.extend({}, baseHelpers, {
      validData: this.hasValidData,
    });
  },

  /**
   * Determines whether we actually have data to display
   *
   * @return {Boolean}
   */
  hasValidData: function() {
    return !this.loading && !this.error && !!this.items && this.items.length > 0;
  },

  /**
   * Displays a given status message in place of the data output
   *
   * @param {String} msg
   */
  showStatus: function(msg) {
    this.ui.content.addClass('hidden');
    this.ui.status.removeClass('hidden').text(msg);
  },

  /**
   * Handles changes in hover state of the key
   *
   * @listens hover:key
   * @param {Number} index
   */
  onKeyHover: function(index) {
    var chartView = this.getChildView('chart');
    if (chartView) {
      chartView.highlightItem(index);
    }
  },

  /**
   * Handles changes in hover state of the chart
   *
   * @listens hover:chart
   * @param {Number} index
   */
  onChartHover: function(index) {
    var keyView = this.getChildView('key');
    if (keyView) {
      keyView.highlightItem(index);
    }
  },
});
