'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var layoutTpl = require('manage/details/vpn/vpn-status.html');
var NetworksNav = require('manage/details/NetworksDropdown');
var VpnDetailsView = require('manage/details/vpn/VpnDetailsView');
var ConnectedUsersListView = require('manage/details/ConnectedUsersListView');

/**
 * Renders the selected VPN View. Items displayed includes
 * list of connected users and configuration.
 */
module.exports = Marionette.View.extend({
  // extends base template defined in manage/details/dna/DnaView
  template: twig({allowInlineIncludes: true, data: layoutTpl}),

  className: 'row',

  regions: {
    details: '.rg-config-details',
    usersList: '.rg-users-list',
    networksNav: '.rg-networks-nav',
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.model.fetch();
  },

  onRender: function() {
    this.addVpnDetails();
    this.addUsersList();

    this.showChildView('networksNav', new NetworksNav({
      deviceMac: this.model.get('deviceMac'),
      deviceConfig: this.model.get('deviceConfig'),
      current: this.model.id,
    }));
  },

  onAttach: function() {
    var router = this.model.get('deviceStatus').get('router');
    this.listenTo(router, 'change:capabilities', this.render);
  },

  /**
   * Adds the "vpn details" section, which lists config.
   */
  addVpnDetails: function() {
    var detailsView = new VpnDetailsView({
      model: this.model,
    });

    this.showChildView('details', detailsView);
  },

  /**
   * Adds the list of users connected to the current VPN network.
   */
  addUsersList: function() {
    var deviceStatus = this.model.get('deviceStatus');

    var options = {
      deviceStatus: deviceStatus,
      vlanId: this.model.id,
    };

    if (!deviceStatus.hasCapability('supportsClientVpnStatus')) {
      options.emptyMessage = i18n.t('vpnDetails.userStatusNotSupported');
    }

    this.showChildView('usersList', new ConnectedUsersListView(options));
  },
});
