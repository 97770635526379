'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/staticRoutes/formRoute.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the editable form elements for a single static route
 */
module.exports = Marionette.View.extend({

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    destination: '[name="destination"]',
    gateway: '[name="gateway"]',
    interface: '[name="interface"]',
    metric: '[name="metric"]',
    enabled: '[name="enabled"]',
    zeroHint: '.zero-hint',
    remove: '.btn-remove',
    messages: '.help-block',
  },

  events: {
    'change @ui.enabled': 'updateModel',
    'blur @ui.name': 'updateModel',
    'blur @ui.destination': 'updateModel',
    'blur @ui.gateway': 'updateModel',
    'blur @ui.interface': 'updateModel',
    'blur @ui.metric': 'updateModel',
    'click @ui.remove': 'handleRemoveButton',
    'focus @ui.destination': 'addWarning',
  },

  modelEvents: {
    'change:remove': 'render',
    'change:enabled': 'onEnabledChange',
    'invalid': 'onError',
    'noConflicts': 'onNoConflicts',
    'zeroDestination': 'onZeroDestination',
  },

  onBeforeRender: function() {
    this.isItemOff = !this.model.get('enabled');
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    return _.extend(tplHelpers.apply(this), {
      eligibleNetworks: _.bind(this._getEligibleNetworks, this),
      interface: this.model.get('interface'),
    });
  },

  /**
   * Sync changes in UI input elements to the model and trigger validation
   *
   * @param {Object} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'destination':
        this.clearError('destination');
        this.clearWarning();
        this.model.set({destination: value}, {commit: true});
        break;
      case 'enabled':
        this.model.set({enabled: event.currentTarget.checked}, {commit: true});
        break;
      case 'gateway':
        this.clearError('gateway');
        this.model.set({gateway: value}, {commit: true});
        break;
      case 'interface':
        this.clearError('interface');
        this.model.set({interface: value}, {commit: true});
        this.model.setInterfaceName();
        break;
      case 'metric':
        this.clearError('metric');
        this.model.set({metric: value}, {commit: true});
        break;
      default:
        break;
    }
  },

  /**
   * Remove this reservation
   */
  handleRemoveButton: function() {
    if (this.model.isNew()) {
      // remove never-saved rules immediately
      this.model.collection.remove(this.model);
    } else {
      var shouldRemove = !this.model.get('remove');
      if (shouldRemove) {
        // if you ask to remove an existing reservation, roll back any local changes
        this.model.applySnapshot();
      }
      this.model.set({remove: shouldRemove});
    }
  },

  _getEligibleNetworks: function() {
    return this.model.get('vlans');
  },

  /**
   * Decorates the view to indicate error.
   *
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

  onZeroDestination: function(model, error, options) {
    this.ui.destination[0].value = this.model.get('destination');
  },

  /**
   * Remove an error message from the view (if present)
   *
   * @param {String} key
   *   Identifier for the error message
   */
  clearError: function(key) {
    this.ui[key].bs3ui('clearGroupedFieldError', key, this.ui.messages);
  },

  clearWarning: function(event) {
    this.ui.zeroHint.addClass('hidden');
  },

  addWarning: function(event) {
    this.ui.zeroHint.removeClass('hidden');
  },

  /**
   * Clear any "special" errors related to route conflicts
   */
  onNoConflicts: function() {
    this.clearError('destination');
  },

  onEnabledChange: function(model, value) {
    this.isItemOff = !value;
    this.triggerMethod('onoff:status:change', this);
  },
});
