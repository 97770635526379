'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var tpl = require('actions/outboundNat/form.html');
var NatView = require('actions/outboundNat/FormNatView');
var Navbar = require('components/navbar/NavbarView');
var Nat = require('actions/outboundNat/Nat');
var twig = require('twig').twig;
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');

var NatsListView = Marionette.CollectionView.extend({
  childView: NatView.extend({

    /**
     * This keeps validation errors preserved
     * when changing an Ingress IP
     */
    onAttach: function() {
      if (!this.model.isNew() || this.model.hasChanged()) {
        this.model.isValid();
      }
    },
  }),

  // wire child views to ToggleFormOff
  childViewTriggers: {
    'render': 'item:render',
    'onoff:status:change': 'item:onoff:status:change',
  },
});

module.exports = Marionette.View.extend({
  behaviors: [
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  regions: {
    navbar: '.rg-navbar',
    rules: '.rg-rules',
  },

  ui: {
    add: '.btn-add',
    rules: '.help-block',
  },

  events: {
    'click @ui.add': 'addRule',
  },

  modelEvents: {
    'invalid': 'onError',
    'change:secondaryIps': 'render',
  },

  initialize: function() {
    this.listenTo(this.model.get('items'), {
      'change': this.allItemsValid,
      'invalid': this.onError,
    });
  },

  allItemsValid: function() {
    var items = this.model.get('items');
    var invalidItems = items.models.filter(function(model) {
      return model.validationError !== null;
    });
    var invalidEgresses = invalidItems.map(function(item) {
      return item.get('egressIp');
    });
    var navbarView = this.getRegion('navbar').currentView;

    navbarView.children.each(function(navItem) {
      if (!invalidEgresses.includes(navItem.model.get('label'))) {
        navItem.unsetInvalid();
      }
    });
  },

  childViewOptions: function(model, index) {
    return {
      ips: this.options.ips,
    };
  },

  onRender: function() {
    this.showNavbarView();
    this.initChildViews();
  },

  showNavbarView: function() {
    var collection = new Backbone.Collection(this._getSecondaryIpsNavCollection());
    var navbar = new Navbar({
      collection: collection,
      large: true,
      grid: true,
    });

    this.showChildView('navbar', navbar);

    this.stopListening(null, 'navbarItem:selected');

    this.listenTo(navbar, {
      'navbarItem:selected': this.onEgressIpChange.bind(this),
    });
  },

  onEgressIpChange: function(model) {
    this.selectedIp = model.get('label');
    this.getRegion('rules').currentView.setFilter(this._getRulesFilter(this.selectedIp));
  },

  onError: function(model, error, options) {
    var navbarView = this.getRegion('navbar').currentView;

    var navItem = navbarView.children.find(function(child) {
      return child.model.get('label') === model.get('egressIp');
    });

    if (model.validationError && model.validationError !== null) {
      navItem.setInvalid();
    }
  },

  _getRulesFilter: function(ip) {
    return function(child) {
      return child.get('egressIp') === ip;
    };
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.selectedIp = this.selectedIp || this.model.get('secondaryIps')[0];

    var view = new NatsListView({
      collection: this.model.get('items'),
      ips: this.model.get('secondaryIps'),
    });
    view.setFilter(this._getRulesFilter(this.selectedIp));

    this.showChildView('rules', view);
  },

  addRule: function() {
    var rule = new Nat({
      'egressIp': this.selectedIp,
      'type': 'host',
    });

    this.model.get('items').add(rule);
  },

  onItemChange: function() {
    this.ui.rules.bs3ui('clearFieldError');
  },

  _getSecondaryIpsNavCollection: function() {
    var secondaryIps = this.model.get('secondaryIps');
    var items = this.model.get('items');
    var ips = _.union(secondaryIps, _.pluck(items.toJSON(), 'egressIp'));

    var collection = _.map(ips, function(ip, key) {
      var item = {
        id: 'ip-' + key,
        label: ip,
      };

      return item;
    });

    if (collection.length) {
      collection[0].isActive = true;
    }

    return collection;
  },

});
