'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var StaticRouteView = require('actions/staticRoutes/StaticRouteView');
var tpl = require('actions/staticRoutes/static.html');
var twig = require('twig').twig;
var viewFilters = require('lib/viewFilters');

var StaticRoutesListView = Marionette.CollectionView.extend({
  childView: StaticRouteView,
  filter: viewFilters.getStaticViewItemsCollectionViewFilter(),
});

/**
 * Renders a text-only version of the static routes component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/staticRoutes/StaticView#model
   * @type {actions/staticRoutes/StaticRoutes}
   */

  template: twig({data: tpl}),

  regions: {
    rules: '.rg-staticRoutes',
  },

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      getItemCountHelp: viewFilters.getItemCountHelpFn({
        // i18n.t('actionStaticRoutes.noStaticRoutes')
        emptyMsgKey: 'actionStaticRoutes.noStaticRoutes',
        // i18n.t('actionStaticRoutes.allDisabled', {count: count})
        allDisabledMsgKey: 'actionStaticRoutes.allDisabled',
        // i18n.t('actionStaticRoutes.viewAll', {count: count})
        moreMsgKey: 'actionStaticRoutes.viewAll',
      }),
    });
  },

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.showChildView('rules', new StaticRoutesListView({
      collection: this.model.get('items'),
    }));
  },
});
