'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var wrapTpl = require('manage/edit/config/action/action.html');
var twig = require('twig').twig;

/**
 * Functions provided on initialization to handle on/off toggle behavior
 */
var onOffCallbacks = [
  'calculateOnOffState',
  'listenForOnOffStateChange',
  'stopListeningForOnOffStateChange',
];

/**
 * Renders a single configuration item View (e.g. dhcp pool, subnet).
 *
 * Contains the regions for displaying an action component.
 *
 * Additionally, this View provides the wrapper HTML around every action
 * component and handles shared features (e.g. removing an action from the
 * config).
 */
module.exports = Marionette.View.extend({
  /**
   * @name manage/edit/config/action/ActionView#model
   * @type {config/Action}
   */

  /**
   * The "form" representation of the action component.
   * @name manage/edit/config/action/ActionView#formView
   * @type {Marionette.View}
   */

  /**
   * The "text-only" representation of the action component.
   * @name manage/edit/config/action/ActionView#textView
   * @type {Marionette.View}
   */

  /**
   * @name manage/edit/config/action/ActionView#calculateOnOffState
   * @type {Function}
   */

  /**
   * @name manage/edit/config/action/ActionView#listenForOnOffStateChange
   * @type {Function}
   */

  /**
   * @name manage/edit/config/action/ActionView#stopListeningForOnOffStateChange
   * @type {Function}
   */

  template: twig({data: wrapTpl}),
  className: 'action-item',

  regions: {
    form: '.rg-action-form',
    text: '.rg-action-text',
  },

  ui: {
    actionTitle: '.action-title',
    textRegion: '.rg-action-text',
    removeBtn: '.action-remove',
  },

  events: {
    'click @ui.removeBtn': 'onRemoveAction',
  },

  modelEvents: {
    'actionModel:change:pendingDelete': 'onPendingDeleteChange',
  },

  /**
   * Initializes the child Views.
   *
   * @throws {Error}
   *   Thrown if either child Views are not View objects.
   * @param {Object} options
   *   The hash (minus the standard "model" property) will be passed to the
   *   child View objects when they are instantiated.
   */
  initialize: function(options) {
    var actionConfig;

    _.bindAll(this, 'fixFormPlugins');

    actionConfig = this.model.get('actionConfig');

    if (typeof actionConfig.FormView != 'function') {
      throw new Error('Property "FormView" is not a constructor.');
    }

    if (typeof actionConfig.TextView != 'function') {
      throw new Error('Property "TextView" is not a constructor.');
    }

    this.mergeOptions(options, onOffCallbacks);

    this.childViewOptions = _.omit(options, onOffCallbacks.concat('Model'));
  },

  templateContext: function() {
    return {
      actionTitle: this.model.get('actionModel').get('actionTitle'),
      showTitle: this.model.get('actionConfig').showTitle,
    };
  },

  onBeforeRender: function() {
    // Hook for automation testing. Note, the data-tag value will not be
    // unique, so it must be scoped to the parent group.
    this.el.setAttribute('data-tag', 'config-option-' + this.model.id);
  },

  onRender: function() {
    if (this.model.get('isLoading') === true) {
      // Defer rendering child views until they are fully loaded.
      this.listenToOnce(this.model, 'change:isLoading', this.onRender);
    } else {
      this.initChildViews();
    }
  },

  /**
   * "Remove" button handler.
   *
   * @param {Event} ev
   */
  onRemoveAction: function(ev) {
    ev.preventDefault();
    this.model.remove();
  },

  onPendingDeleteChange: function(model, actionModel, pendingDelete) {
    var deletingClass = this.getDeletingClass();

    if (pendingDelete) {
      this.$el.addClass(deletingClass);
      this.ui.removeBtn.addClass('hidden');
      if (_.isFunction(this.options.removeCallback)) {
        this.options.removeCallback();
      }
    } else {
      this.$el.removeClass(deletingClass);
      this.ui.removeBtn.removeClass('hidden');
    }
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    var actionConfig = this.model.get('actionConfig');
    var childViewOptions = _.clone(this.childViewOptions);
    var childViewModel = this.model.get('actionModel');

    childViewOptions.model = childViewModel;

    var formView = new actionConfig.FormView(childViewOptions);

    if (this.model.actionSupportsOnOff()) {
      // in case this is a re-render, avoid duplicate listeners
      this.stopListeningForOnOffStateChange(this);
      this.applyOnOffState(formView);
      this.listenForOnOffStateChange(this, this.applyOnOffState);
    }

    this.showChildView('form', formView);

    if (this.isVisibleInTextView()) {
      this.showChildView('text', new actionConfig.TextView(childViewOptions));
    }
  },

  /**
   * Force the View's regions to be re-rendered. The primary use-case is for
   * when an action's attributes are rolled back.
   */
  refresh: function() {
    this.$el.removeClass(this.getDeletingClass());
    this.ui.removeBtn.removeClass('hidden');

    this.getChildView('form').render();

    if (this.isVisibleInTextView()) {
      this.getChildView('text').render();
    }
  },

  /**
   * Some actions use plugins that, in order to display properly, need to be
   * visible. If the action was initially hidden while being rendered, this
   * method can be used to call the form View's `onAttach` method again to
   * fix any problem plugins.
   *
   * Note, `onAttach` is a Marionette View lifecycle method. Using it for
   * familiarity.
   */
  fixFormPlugins: function() {
    var formView = this.getChildView('form');

    // //FIXME revisit why this method is being called before the View is
    // rendered. Doesn't seem to be breaking anything.
    if (formView && _.isFunction(formView.onAttach)) {
      formView.onAttach();
    }
  },

  /**
   * Should the action be visible in the "text" View.
   *
   * @return {Boolean}
   */
  isVisibleInTextView: function() {
    return this.model.get('actionConfig').showInStaticView === true;
  },

  /**
   * Class that should be used when action is pending deletion
   *
   * @return {String}
   */
  getDeletingClass: function() {
    return this.model.get('isManaged') ?
      'action-deleting--managed' :
      'action-deleting';
  },

  /**
   * Determine the current on/off status for this action
   * and trigger view updates as needed
   *
   * @param {Marionette.View} [formView] reference to the form view, if not yet attached
   */
  applyOnOffState: function(formView) {
    var actionModel = this.model.get('actionModel');
    var isOff = !this.calculateOnOffState(actionModel);
    formView = formView || this.getChildView('form');

    // provide form views a way to disable all their inputs
    formView.isItemOff = isOff;
    if (formView.isRendered()) {
      formView.triggerMethod('onoff:status:change');
    }

    // set a class for anything that's purely visual
    var offClass = actionModel.toggledOnOffBy ?
      'action-item--off-externally' :
      'action-item--off';
    this.$el.toggleClass(offClass, isOff);
  },
});
