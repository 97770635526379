'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/webFiltersTitanHq/settings/static.html');
var twig = require('twig').twig;
var _ = require('lodash');

/**
 * Renders a text-only version of the settings setting component of titan hq.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name #model
   * @type {actions/webFiltersTitanhq/settings/EditTitanHqSettings}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      zone: this.model.get('zone') || 'unknown',
      safesearch: this.model.get('safesearch'),
      allowUnclassified: this.model.get('allowUnclassified'),
    });
  },
});
