'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var formTpl = require('actions/wanWebFilterDns/form.html');

/**
 * Renders a view that just displays that the current DNS provider is your web filtering engine.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/wanWebFilterDns/FormView#model
   * @type {actions/shared/ActionItemPlaceholderModel}
   */

  template: twig({data: formTpl}),
});
