'use strict';

var Backbone = require('backbone');
var Radio = require('backbone.radio');
var apiChannel = Radio.channel('apiChannel');

/**
 * Data handler for the Factory Reset button
 */
module.exports = Backbone.Model.extend({
  /**
   * @member {Object} #attributes
   * @property {String} deviceMac
   */

  /**
   * Makes the api call to trigger a device factory reset
   *
   * @returns {jQuery.Deferred}
   */
  triggerFactoryReset: function() {
    return apiChannel.request('send', 'DNA.Portal.Device.factoryReset', {mac: this.get('deviceMac')});
  },
});
