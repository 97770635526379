'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var Marionette = require('backbone.marionette');
var tpl = require('actions/webFiltersTitanHq/category/form.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var selectedCategory = require('actions/webFiltersTitanHq/category/selectedCategory.html');
var RenderChanges = require('lib/behaviors/RenderChanges');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

/**
 * Renders the category form field for titan Hq web filters.
 */
module.exports = Marionette.View.extend({
  /**
   * @name #model
   * @type {actions/webFiltersTitanHq/category/EditCategoryTitanHq}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  selectedCategoryTemplate: twig({data: selectedCategory}),

  ui: {
    categories: '.categories',
    selectAll: '.btn-select-all',
    removeAll: '.btn-remove-all',
  },

  events: {
    'change @ui.categories': 'saveForm',
    'click @ui.selectAll': 'selectAll',
    'click @ui.removeAll': 'removeAll',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  onRender: function() {
    var categorySelect = this.ui.categories.select2({
      placeholder: i18n.t('actionWebFiltersTitanHq.selectPlaceHolder'),
      width: '100%',
      theme: 'bootstrap',
      closeOnSelect: false,
      data: this._getDisplayCategoriesForDropdown(),
      multiple: true,
      allowClear: true,
      templateResult: this.formatToolTipForDropdownCategory.bind(this),
      templateSelection: this.formatToolTipForSelectedCategory.bind(this),
      escapeMarkup: _.identity, // Return the value that passed - no escape or change in value - essentially a no-op.
    });

    categorySelect.data('select2').$dropdown.addClass('multicontent');
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    return _.extend(tplHelpers.apply(this), {
      categories: this.model.get('enabled'),
    });
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/webFiltering/EditWebFiltering} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    this.ui.categories.bs3ui('showFieldError', i18n.t('actionWebFilters.noCategoriesEnabled'));
  },

  /**
   * @param {Object} event
   */
  saveForm: function(event) {
    this.ui.categories.bs3ui('clearFieldError');
    this.model.setCategoryState(this.ui.categories.val());
  },

  selectAll: function() {
    this.model.setAllCategories();
    this.render();
  },

  removeAll: function() {
    this.model.unsetAllCategories();
    this.render();
  },

  formatToolTipForDropdownCategory: function(category, container) {
    var categoryDescription = this._getDescriptionForCategory(category.id);
    container.setAttribute('title', categoryDescription);

    return category.text;
  },

  formatToolTipForSelectedCategory: function(category, container) {
    var categoryDescription = this._getDescriptionForCategory(category.id);
    var data = {
      'description': categoryDescription,
      'categoryTitle': category.text,
    };

    return this.selectedCategoryTemplate.render(data);
  },

  _getDisplayCategoriesForDropdown: function() {
    var dataArray = [];
    this.model.get('categories').forEach(function(category) {
      var text = i18n.t('actionWebFiltersTitanHq.category.' + category.id);
      dataArray.push({id: category.id, text: text, selected: category.enabled});
    });

    return _.sortBy(dataArray, 'text');
  },

  _getDescriptionForCategory: function(categoryID) {
    return i18n.t('actionWebFiltersTitanHq.description.' + categoryID);
  },
});
