'use strict';

/**
 * {@link lib/models/DeviceStatus} mixin for capabilities-related methods.
 *
 * @mixin lib/mixins/capabilities
 */
module.exports = {
  /**
   * Determines whether the device supports a given capability
   *
   * @param {String} name
   * @return {Boolean}
   */
  hasCapability: function(name) {
    var capabilities = this.get('router').get('capabilities') || {};
    return !!capabilities[name];
  },

  /**
   * Registers a function to be called when a given capability is added
   * (i.e. as a result of installing a firmware update)
   *
   * @param {Backbone.Events} listener
   * @param {String} name
   *   Name of the capability to watch for
   * @param {Function} callback
   *   Function to call when capability appears. Will be called with
   *   `this` set to `listener`. Only called once at most.
   */
  onCapabilityAdded: function(listener, name, callback) {
    var router = this.get('router');
    listener.listenTo(router, 'change:capabilities', function handler(model, capabilities) {
      if (capabilities && capabilities[name]) {
        callback.apply(listener);
        listener.stopListening(router, 'change:capabilities', handler);
      }
    });
  },
};
