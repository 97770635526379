'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var Backbone = require('backbone');
var twig = require('twig').twig;
var tpl = require('components/remoteSites/sites.html');
var GroupsCollectionView = require('components/remoteSites/GroupsCollectionView');

var REQUIRED_CAPABILITY = 'supportsSiteToSiteVpnStatus';

module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  regions: {
    'groups': '.rg-groups',
  },

  initialize: function() {
    var sites = this.options.deviceConfig.get('remoteSites');
    var status = this.options.deviceStatus.get('remoteSites');

    this.listenTo(sites, {
      'add': this.onAddSite,
      'remove': this.onRemoveSite,
      'change:group': this.onChangeGroup,
    });

    this.listenTo(status, {
      'update': this.updateTunnelStatus,
    });

    if (!this.options.deviceStatus.hasCapability(REQUIRED_CAPABILITY)) {
      this.options.deviceStatus.onCapabilityAdded(
        this,
        REQUIRED_CAPABILITY,
        this._updateRemoteSitesVisibility
      );
    }
  },

  onRender: function() {
    this.addGroupsCollection();
    this._updateRemoteSitesVisibility();
  },

  onAddSite: function(model) {
    this.addGroupsCollection();
    this._updateRemoteSitesVisibility();
  },

  onRemoveSite: function(model) {
    this.addGroupsCollection();
    this._updateRemoteSitesVisibility();
  },

//  onChangeGroup: function(model) {
//    var previousGroupModel = this.groups.find({group: model.previous('group')});
//    var currentGroupModel = this.groups.find({group: model.get('group')});
//
//    if (previousGroupModel) {
//      this._removeTunnelFromGroup(previousGroupModel, model);
//    }
//
//    this._addTunnelToGroup(currentGroupModel, model);
//  },

  addGroupsCollection: function() {
    this.groups = this.getRemoteSiteGroups();

    this.showChildView('groups', new GroupsCollectionView({
      collection: this.groups,
    }));
  },

  /**
   * Just as a note:
   * We're not using the 'group' parameter from our iteratee in _.each because
   * _.groupBy is casting our keys, in this case, 0, 1, etc to Strings
   * This is inconsistent with our response from the server; groups are Integers
   * By using the the models attribute 'group' we are ensuring that Type of group
   * remains intact
   *
   * @return {Backbone.Collection}
   */
  getRemoteSiteGroups: function() {
    var collection = new Backbone.Collection();
    var sites = this.options.deviceConfig.get('remoteSites').invoke('clone');

    _.each(sites, function(topologyModel) {
      var tunnels = new Backbone.Collection(topologyModel.get('tunnels'));
      this._addStatusToTunnels(tunnels);

      collection.add({
        group: topologyModel.get('description'),
        tunnels: tunnels,
      });
    }, this);

    return collection;
  },

  updateTunnelStatus: function(collection, options) {
    collection.each(function(tunnel) {
      if (!_.isEmpty(tunnel.changed)) {
        this.groups.each(function(group) {
          var tunnelModel = group.get('tunnels').get(tunnel.id);

          if (tunnelModel) {
            tunnelModel.set(tunnel.attributes);
          }
        }, this);
      }
    }, this);
  },

  _addStatusToTunnels: function(tunnels) {
    var status = this.options.deviceStatus.get('remoteSites');

    if (status.length > 0) {
      tunnels.each(function(tunnel) {
        var tunnelStatus = status.find({id: tunnel.id});

        if (!_.isUndefined(tunnelStatus)) {
          tunnel.set(tunnelStatus.pick('established', 'peerIp', 'status'));
        }
      });
    }
  },

  _updateRemoteSitesVisibility: function() {
    var shouldHide =
      this.options.deviceConfig.get('remoteSites').length === 0 ||
      !this.options.deviceStatus.hasCapability(REQUIRED_CAPABILITY);

    this.options.parentRegion.toggleClass('hidden', shouldHide);
  },
});
