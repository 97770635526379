'use strict';

var _ = require('lodash');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var networkUtils = require('lib/network');
var snapshotMixin = require('lib/mixins/snapshot');
var i18n = require('i18next');

var specificationType = {
  ADDRESS: 'address',
  RANGE: 'range',
  SUBNET: 'subnet',
};

/**
 * Represents an individual address specification entry
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin, specificationType).extend({
  /**
   * @member {Object} actions/networkObject/EditNat#attributes
   * @property {string} addrSpec
   * @property {string} specType
   */

  _snapshotAttributes: ['addrSpec', 'specType'],

  defaults: {
    specType: 'address',
    addrSpec: '',
  },

  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'addrSpec') && _.has(attrs, 'specType')) {
      // save clicked
      this.validateAddressSpec(
        attrs.addrSpec,
        attrs.specType,
        errors
      );
    } else if (attrs.addrSpec) {
      var specType = _.has(attrs, 'specType') ? attrs.specType : this.get('specType');
      if (!_.isUndefined(specType)) {
        this.validateAddressSpec(
          attrs.addrSpec,
          specType,
          errors
        );
      }
    } else if (_.has(attrs, 'specType') && this.get('addrSpec').length > 0) {
      this.validateAddressSpec(
        this.get('addrSpec'),
        attrs.specType,
        errors
      );
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @param {String} specification
   * @param {String} specType
   * @param {Object} errors
   */
  validateAddressSpec: function(specification, specType, errors) {
    switch (specType) {
      default:
      case specificationType.ADDRESS:
        // validate address
        if (_.isUndefined(specification) || !networkUtils.validIP(specification.trim())) {
          errors.addrSpec = i18n.t('networkObject.invalidIpAddressSpecification');
        }
        break;
      case specificationType.RANGE:
        // validate range
        if (_.isUndefined(specification)) {
          errors.addrSpec = i18n.t('networkObject.invalidIpAddressRangeSpecification');
        } else {
          var range = specification.split('-');
          if (range.length !== 2) {
            errors.addrSpec = i18n.t('networkObject.invalidIpAddressRangeSpecification');
          } else if (!networkUtils.isIPv4InRange(range[0].trim(), range[0].trim(), range[1].trim())) {
            errors.addrSpec = i18n.t('networkObject.invalidIpAddressRangeSpecification');
          }
        }
        break;
      case specificationType.SUBNET:
        // validate subnet
        if (_.isUndefined(specification) || !networkUtils.validSubnet(specification)) {
          errors.addrSpec = i18n.t('networkObject.invalidSubnetSpecification');
        }
        break;
    }
  },
});
