'use strict';

var Marionette = require('backbone.marionette');
var RuleView = require('actions/trafficPolicyRules/RuleView');
var RuleModel = require('actions/trafficPolicyRules/Rule');
var tpl = require('actions/trafficPolicyRules/form.html');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');

var RulesListView = Marionette.CollectionView.extend({
  childView: RuleView,

  childViewTriggers: {
    'render': 'item:render',
  },
});

/**
 * Renders the editable form for the collection of traffic policy rules
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/trafficPolicyRules/FormView#model
   * @type {actions/trafficPolicyRules/EditTrafficPolicyRules}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
  ],

  template: twig({data: tpl}),

  regions: {
    rules: '.rg-rules',
  },

  ui: {
    add: '.btn-add',
  },

  events: {
    'click @ui.add': 'addRule',
  },

  initialize: function(attributes, options) {
    this.listenTo(this.model.get('items'), 'change:remove', this.initChildViews);
  },

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.showChildView('rules', new RulesListView({
      collection: this.model.get('items'),
    }));
  },

  /**
   * Add a new blank rule to the list of traffic policy rules
   */
  addRule: function() {
    var rule = new RuleModel({
    });
    var newRuleOrder = this.model.get('items').length + 1;
    rule.set('order', newRuleOrder.toString(), {silent: true});
    this.model.get('items').add(rule);
    this.initChildViews();
  },

});
