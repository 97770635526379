'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/outboundNat/static.html');
var twig = require('twig').twig;
var viewFilters = require('lib/viewFilters');

module.exports = BaseStaticView.extend({
  template: twig({data: tpl}),

  /**
   * @returns {Object}
   */
  serializeData: function() {
    return {
      items: this.model.get('items').toJSON(),
    };
  },

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      getItemCountHelp: viewFilters.getItemCountHelpFn({
        // i18n.t('actionOutboundNat.noRules')
        emptyMsgKey: 'actionOutboundNat.noRules',
        // i18n.t('actionOutboundNat.allDisabled', {count: count})
        allDisabledMsgKey: 'actionOutboundNat.allDisabled',
        // i18n.t('actionOutboundNat.viewAll', {count: count})
        moreMsgKey: 'actionOutboundNat.viewAll',
      }),
    });
  },
});
