'use strict';

var Backbone = require('backbone');
var Radio = require('backbone.radio');
var dnaStatusChannel = Radio.channel('dnaStatusChannel');

var classProps = {
  UPDATE: {
    INPROGRESS: 10,
    SUCCESS: 11,
    ERROR: 12,
  },
};

/**
 * View-Model for the {components/updateDna/UpdateDnaView} View.
 */
module.exports = Backbone.Model.extend({
  /**
   * @member {Backbone.Model} components/updateDna/UpdateDnaModel#attributes
   * @property {String|Boolean} version
   * @property {String} mac
   * @property {String} updateState
   * @property {lib/models/DeviceStatus} deviceStatus
   * @property {Backbone.Model} deviceHealth
   */

  /**
   * @param {Object} attributes
   * @param {Object} options
   */
  initialize: function(attributes, options) {
    this.set({
      alertType: 'wide',
      severity: 'info',
    });

    this.initUpdateState();

    dnaStatusChannel.reply('is:dna:updating', this.isDnaUpdating.bind(this));
  },

  /**
   * Sets the model's "updateState" and "version" attributes based on the the
   * current status blob.
   *
   * Note, when setting the "updateState" attribute, we do not care about
   * success or errors states, which may hang around until the DNA is rebooted
   * or another update is kicked off.
   */
  initUpdateState: function() {
    var deviceStatus = this.get('deviceStatus');
    var updateState = deviceStatus.get('router').get('upgradeState');

    this.set('version', deviceStatus.get('router').get('upgradeAvailable'));

    if ([1, 2, 4].indexOf(updateState) !== -1) {
      this.set('updateState', classProps.UPDATE.INPROGRESS);
    }
  },

  /**
   * Whether or not the DNA is in a state where it can be upgraded.
   *
   * @return {Boolean}
   */
  isUpdateable: function() {
    var deviceHealth = this.get('deviceHealth');
    var canUpdate = (deviceHealth.get('connected') === true && deviceHealth.get('failedOver') !== true);
    return canUpdate;
  },

  /**
   * Returns whether or not the DNA is updating.
   *
   * @listens dnaStatusChannel.reply~is:dna:updating
   * @return {Boolean}
   */
  isDnaUpdating: function() {
    return this.get('updateState') === classProps.UPDATE.INPROGRESS;
  },
}, classProps);
