'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var itemTpl = require('components/networksList/network.html');
var detailTypes = require('manage/details/detailTypes');
var wanIcon = require('network_wan.svg');
var lanIcon = require('network_lan.svg');
var wifiIcon = require('network_wifi.svg');
var vpnIcon = require('network_vpn.svg');
var Entities = require('html-entities').XmlEntities;
var entities = new Entities();

var networkTypes = {
  wan: {label: i18n.t('detailsCommon.wan'), icon: wanIcon},
  lan: {label: i18n.t('detailsCommon.lan'), icon: lanIcon},
  wifi: {label: i18n.t('detailsCommon.wifi'), icon: wifiIcon},
  vpn: {label: i18n.t('detailsCommon.vpn'), icon: vpnIcon},
};

/**
 * Displays a network's status.
 */
module.exports = Marionette.View.extend({
  /**
   * @name components/networksList/NetworkView#model
   * @type Backbone.Model
   * @property {String} id
   *   The network's id (e.g. vlan#, cfg#, etc.)
   * @property {String} url
   *   The URL to use for the link's href.
   * @property {Backbone.Model} network
   *   The object from deviceConfiguration's "networks" collection for passed id.
   * @property {Number} numDevices
   *   The number of devices actively connected to the network.
   */

  template: twig({data: itemTpl}),

  ui: {
    icon: 'svg',
  },

  className: function() {
    var classes = 'items-list__row';
    if (this.model.get('network').get('type') === detailTypes.WIFI) {
      classes += ' items-list__child-row';
    }
    return classes;
  },

  triggers: {
    'click a': 'network:selected',
  },

  modelEvents: {
    // TODO Right now, the View is small and rerenders are cheap. Rethink as
    // more data gets added to the View.
    'change': 'render',
  },

  /**
   * @return {Object}
   */
  attributes: function() {
    return {
      'data-type': this.getNetworkType(this.model.get('network')),
    };
  },

  onAttach: function() {
    if (this.model && this.model.has('network')) {
      this.listenTo(this.model.get('network'), 'change', this.render);
    }
  },

  onRender: function() {
    // add a class to the inlined SVG icon for cleaner styling
    this.ui.icon.addClass('items-list__icon');

    // add some accessibility info to the SVG icon
    // see https://css-tricks.com/accessible-svgs/#article-header-id-6
    var type = this.getNetworkType(this.model.get('network'));
    var typeInfo = networkTypes[type];
    if (typeInfo) {
      var id = _.uniqueId('svg_');
      this.ui.icon.prepend('<title id="' + id + '">' + typeInfo.label + '</title>');
      this.ui.icon.attr('aria-labelledby', id);
    }
    this.ui.icon.attr('role', 'img');
  },

  templateContext: function() {
    return {
      name: this.tplNetworkName,
      showNumDevices: this.tplShowNumDevices,
      showNumUsers: this.tplShowNumUsers,
      testId: this.tplTestId,
      icon: this.getIcon(),
    };
  },

  /**
   * Get the network's name/description.
   *
   * @method templateContext.name
   * @return {String}
   */
  tplNetworkName: function() {
    return entities.decode(this.network.getName());
  },

  /**
   * Whether or not the number of devices should be displayed.
   *
   * @method templateContext.showNumDevices
   * @return {String}
   */
  tplShowNumDevices: function() {
    var type = this.network.get('type');
    return (type === detailTypes.VLAN || type === detailTypes.WIFI) &&
      this.network.get('role') === 'internal';
  },

  /**
   * Whether or not the number of users should be displayed.
   *
   * @method templateContext.showNumUsers
   * @return {String}
   */
  tplShowNumUsers: function() {
    var type = this.network.get('type');
    return type === detailTypes.VPN;
  },

  /**
   * @method templateContext.testId
   * @return {String}
   */
  tplTestId: function() {
    return 'go-device-status-' + this.id;
  },

  getIcon: function() {
    var type = this.getNetworkType(this.model.get('network'));
    var typeInfo = networkTypes[type];
    return typeInfo ? typeInfo.icon : '';
  },

  /**
   * Gets the network's type.
   *
   * @param {Backbone.Model} network
   * @return {String}
   */
  getNetworkType: function(network) {
    switch (network.get('type')) {
      case detailTypes.VLAN:
        if (network.get('role') === 'external') {
          return 'wan';
        }
        return 'lan';

      case detailTypes.WIFI:
        return 'wifi';

      case detailTypes.VPN:
        return 'vpn';

      default:
        return 'unknown';
    }
  },
});
