'use strict';

var Marionette = require('backbone.marionette');
var moment = require('moment');
var twig = require('twig').twig;
var itemTpl = require('components/connectedUsersList/connected-user.html');

/**
 * Displays a connected user's status.
 */
module.exports = Marionette.View.extend({
  /**
   * @name #model
   * @type Backbone.Model
   * @property {String} username
   * @property {Number} connected
   * @property {String} ip
   */

  template: twig({data: itemTpl}),

  className: 'items-list__row items-list__row--flush',

  ui: {
    'duration': '.duration',
  },

  modelEvents: {
    'change': 'render',
  },

  templateContext: function() {
    return {
      duration: this.calculateDuration(),
    };
  },

  /**
   * Compute a human-readable display string representing
   * how long this user has been connected
   *
   * @returns {String}
   */
  calculateDuration: function() {
    var duration = this.model.get('connected'); // ISO 8601 duration
    return moment.duration(duration).humanize();
  },

});
