'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/siteVpn/DnaSite/form.html');
var tplHelpers = require('lib/tplHelpers');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');
var $ = require('jquery');

/**
 * Renders editable DNA to DNA site to site tunnel form
 *
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/siteVpn/DnaSite/DnaSiteFormView#model
   * @type {actions/siteVpn/DnaSite/DnaSiteVpn}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    return tplHelpers.apply(this);
  },

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  ui: {
    enabled: '[name="enable-site-vpn"]',
    remoteDevice: '[name="site-vpn-remote-device"]',
    initiator: '.site-initiator',
    remove: '.btn-remove',
  },

  events: {
    'change @ui.enabled': 'updateModel',
    'change @ui.initiator': 'updateModel',
    'change @ui.remoteDevice': 'updateModel',
    'click @ui.remove': 'handleRemoveButton',
  },

  modelEvents: {
    'change:remove': 'render',
    'change:enabled': 'onEnabledChange',
    'invalid': 'onError',
  },

  onBeforeRender: function() {
    this.isItemOff = !this.model.get('enabled');
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/siteVpn/DnaSite/DnaSiteVpn} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.mac) {
      this.ui.remoteDevice.bs3ui('showFieldError', error.mac);
    }
  },

  /**
   * Checks if values have changed and saves.
   *
   * @param {Object} ev
   */
  updateModel: function(ev) {
    var self = this;
    var control = ev.currentTarget;
    var name = control.name;
    var value = control.value;

    switch (name) {
      case 'enable-site-vpn':
        this.model.set({enabled: control.checked}, {commit: true});
        break;
      case 'site-vpn-initiator-' + self.cid:
        this.model.set({initiator: value === 'true' && $(control).is(':checked')}, {commit: true});
        break;
      case 'site-vpn-remote-device':
        this.ui.remoteDevice.bs3ui('clearFieldError');
        this.model.set({remoteMac: value}, {commit: true});
        break;
      default:
        break;
    }
  },

  /**
   * Handle the remove trigger
   */
  handleRemoveButton: function() {
    if (this.model.isNew()) {
      this.model.collection.remove(this.model);
    } else {
      var shouldRemove = !this.model.get('remove');
      if (shouldRemove) {
        this.model.applySnapshot();
      }
      this.model.set({remove: shouldRemove});
    }
  },

  onEnabledChange: function(model, value) {
    this.isItemOff = !value;
    this.triggerMethod('onoff:status:change', this);
  },
});
