'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/domainFilters/form.html');
var FilterView = require('actions/domainFilters/FilterView');
var Filter = require('actions/domainFilters/Filter');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
var twig = require('twig').twig;

var Filters = Marionette.CollectionView.extend({
  childView: FilterView,

  childViewOptions: function(child, index) {
    return {
      index: index,
    };
  },

  childViewTriggers: {
    'render': 'item:render', // see ToggleFormOff.js
  },
});

module.exports = Marionette.View.extend({

  behaviors: [
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  /**
   * @name actions/domainFilters/FormView#model
   * @type {actions/domainFilters/EditDomainFilters}
   */
  ui: {
    'add': '.btn-add',
  },

  events: {
    'click @ui.add': 'addFilter',
  },

  regions: {
    filters: '.rg-filters',
  },

  template: twig({data: tpl}),

  onRender: function() {
    this.isAddingChildViewsOnRender = true; // see ToggleFormOff.js
    this.initChildViews();
    this.isAddingChildViewsOnRender = false;
  },

  /**
   * Initializes the child views.
   */
  initChildViews: function() {
    this.showChildView('filters', new Filters({
      collection: this.model.get('items'),
    }));
  },

  /**
   * Add a new Filter
   */
  addFilter: function() {
    this.model.get('items').add(new Filter());
  },
});
