'use strict';

var _ = require('lodash');

/**
 * Defines keys that should be available as computed properties on this module
 * This is a map of key => config
 * Config format:
 *   - path: path within the context object to look for the value (e.g. 'foo' or 'foo.bar')
 *   - defaultValue: optional default value to return if path is not found in context
 *
 * @type {{PROPERTY_KEY: {path: string, defaultValue: *}}}
 */
var keys = {
  ASSET_URL_PREFIX: {
    path: 'legacy.assetUrlPrefix',
    defaultValue: '',
  },
};

var lookUp = _.memoize(getValue); // cache lookup results
var api = {};

_.forEach(keys, function(data, key) {
  Object.defineProperty(api, key, {get: lookUp.bind(api, data.path, data.defaultValue)});
});

/**
 * Look up a value in the context by name
 *
 * @param {String} subPath
 * @param {*} defaultValue
 * @return {*}
 */
function getValue(subPath, defaultValue) {
  return _.get(window, 'datto.dna.' + subPath, defaultValue);
}

/**
 * Convenience module that exposes known data paths from the page context
 * (datto.dna.*) as "constants" which can have default values
 *
 * Usage:
 * var urlPrefix = runtimeContext.ASSET_URL_PREFIX;
 */
module.exports = api;
