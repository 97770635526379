'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/dhcpPool/static.html');
var twig = require('twig').twig;
var RemoveConfig = require('manage/edit/config/action/removeConfig/RemoveConfig');
var i18n = require('i18next');

/**
 * Renders a text-only version of the "dhcp pool" form action component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/dhcpPool/StaticView#model
   * @type {actions/dhcpPool/EditDhcpPool}
   */

  behaviors: [{
    behaviorClass: RemoveConfig,
  }],

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      range: this.tplDhcpPoolRange(),
    });
  },

  /**
   * @method templateContext.range
   * @return {String}
   */
  tplDhcpPoolRange: function() {
    // The case we are checking for here is when: startIP is .x and end IP is 0.0.0.x
    if (!this.model.hasValidSubnet()) {
      return i18n.t('actionDhcp.noSubnetDefined');
    }

    return this.model.get('startIp') + ' - ' + this.model.get('endIp');
  },
});
