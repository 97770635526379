'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var noSnapshotMixin = require('lib/mixins/noSnapshot');
var networkUtils = require('lib/network');
var i18n = require('i18next');

var staticServers = {
  google: '8.8.8.8',
  opendns: '208.67.222.222',
  custom: null,
};

/**
 * Model for DNS Lookup.
 */
module.exports = ActionItem.extend(noSnapshotMixin).extend({
  /**
   * @member {Object} actions/dnsLookup/DnsLookup#attributes
   * @property {string} targetHostname
   * @property {string} server
   * @property {string} serverType
   * @property {object} servers
   * @property {string} result
   */

  defaults: {
    targetHostname: null,
    serverType: 'google',
    server: staticServers.google,
    servers: staticServers,
    result: i18n.t('dnsLookup.defaultResult'),
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * Parse available interfaces.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var parsedLans = this.deviceConfig.getLanAddresses();
      var servers = _.reduce(parsedLans, function(result, lan) {
        result[lan.id] = lan.id;
        return result;
      }, staticServers);

      return {
        lans: parsedLans,
        servers: servers,
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks');
      },
      events: 'add remove change:addressMap change:description',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'targetHostname')) {
      if (!networkUtils.validHostname(attrs.targetHostname) && !networkUtils.validIP(attrs.targetHostname)) {
        errors.targetHostname = i18n.t('dnsLookup.invalidHostname');
      }
    }

    if (_.has(attrs, 'server') && (attrs.server || this.get('serverType') === 'custom')) {
      if (!networkUtils.validIP(attrs.server)) {
        errors.customServer = i18n.t('dnsLookup.invalidServer');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    return {
      name: 'operatorTools.dnsLookup',
      data: {
        targetHostname: this.get('targetHostname'),
        server: this.get('server'),
      },
    };
  },
});
