'use strict';

var _ = require('lodash');
var TopItemsModel = require('components/usageShared/TopItemsModel');

/**
 * Model/loader for top client usage data
 */
module.exports = TopItemsModel.extend({

  rpc: {
    read: [
      {
        method: 'DNA.Portal.Bandwidth.getLastSeen',
        params: function() {
          return {
            mac: this.get('deviceMac'),
          };
        },
      },
    ],
  },

  /**
   * Take the returned result and transform it so that
   * the data ends up in the right place on this model
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   *   The modified response
   */
  parse: function(resp, options) {
    if (!_.isUndefined(resp)) {
      var sortedItems = resp.result;
      return {
        items: sortedItems,
      };
    }

    return resp;
  },
});
