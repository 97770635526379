'use strict';

var BaseGroup = require('config/Group');
var RenderPositions = require('config/RenderPositions');
var i18n = require('i18next');
var timeouts = require('lib/saveTimeouts');
var ConfigMessageTypes = require('manage/edit/ConfigMessageTypes');
var $ = require('jquery');
var _ = require('lodash');

/**
 * "Web Filters Titan HQ" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'webFiltersTitanHqEnable',
      position: RenderPositions.first,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'titanHqSettings',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'titanHqCategories',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'domainFiltersTitanHq',
      position: RenderPositions.full,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  shouldMergeTasks: ['webFiltersTitanHq.setOptions'],

  getConfigCardTimeout: function() {
    return timeouts.MAX_REMOTE_CALL_TIME;
  },

  /**
   * Overridden to ensure if static IP exists that custom DNS is set
   *
   * @return {Object}
   */
  _validateActions: function() {
    return BaseGroup.prototype._validateActions.apply(this, arguments).then(function(result) {
      // do the usual validation check and additionally check if were enabled that we must have categories set.
      // If we arent enabled ensure that you can still save without any categories set.
      if (!this.validEnableAndCategories(this.get('actions'))) {
        this.get('actions').get('titanHqCategories').get('actionModel').trigger('invalid');
        return $.Deferred().reject({type: ConfigMessageTypes.FAILED_VALIDATION});
      }
      return result;
    }.bind(this));
  },

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.webFilters');
  },

  validEnableAndCategories: function(actions) {
    var titanHqEnabled = actions.get('webFiltersTitanHqEnable').get('actionModel').get('enabled');
    var hasEnabledCategory = _.any(
      actions.get('titanHqCategories').get('actionModel').get('categories'), function(category) {
        return category.enabled === true;
      });

    var domainFiltersItems = actions.get('domainFiltersTitanHq').get('actionModel').get('items');

    var hasBlacklistedDomains = domainFiltersItems.filter(function(domainItem) {
      return domainItem.get('type') === 'blacklist' && !domainItem.get('remove');
    }).length > 0;

    if (titanHqEnabled) {
      return !!(hasBlacklistedDomains || hasEnabledCategory);
    }
    return true;
  },
});
