'use strict';

var Backbone = require('backbone');
var i18n = require('i18next');
var GroupAlertView = require('manage/edit/config/group/groupAlerts/GroupAlertView');

var staticProps = {
  dnaIsSpokeAlertId: 'dnaIsSpoke',
};

module.exports = GroupAlertView.extend({
  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.model = new Backbone.Model({
      id: staticProps.dnaIsSpokeAlertId,
      msg: i18n.t('actionSiteVpn.dnaIsSpokeWarning', {hubMac: options.hubMac}),
    });
  },
}, staticProps);
