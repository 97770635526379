'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');

var GLOBAL_TZ_CAPABILITY = 'supportsGlobalTimezones';

var timezones = [
  {name: 'Etc/UTC', label: i18n.t('actionTimezone.tz.Etc/UTC'), global: false},

  // these should be ordered by longitude, east to west
  {name: 'Pacific/Chatham', label: i18n.t('actionTimezone.tz.Pacific/Chatham'), global: true},
  {name: 'Pacific/Auckland', label: i18n.t('actionTimezone.tz.Pacific/Auckland'), global: true},
  {name: 'Australia/Lord_Howe', label: i18n.t('actionTimezone.tz.Australia/Lord_Howe'), global: true},
  {name: 'Australia/Brisbane', label: i18n.t('actionTimezone.tz.Australia/Brisbane'), global: true},
  {name: 'Australia/Sydney', label: i18n.t('actionTimezone.tz.Australia/Sydney'), global: true},
  {name: 'Australia/Adelaide', label: i18n.t('actionTimezone.tz.Australia/Adelaide'), global: true},
  {name: 'Australia/Darwin', label: i18n.t('actionTimezone.tz.Australia/Darwin'), global: true},
  {name: 'Australia/Eucla', label: i18n.t('actionTimezone.tz.Australia/Eucla'), global: true},
  {name: 'Australia/Perth', label: i18n.t('actionTimezone.tz.Australia/Perth'), global: true},
  {name: 'Asia/Famagusta', label: i18n.t('actionTimezone.tz.Asia/Famagusta'), global: true},
  {name: 'Europe/Nicosia', label: i18n.t('actionTimezone.tz.Europe/Nicosia'), global: true},
  {name: 'Europe/Bucharest', label: i18n.t('actionTimezone.tz.Europe/Bucharest'), global: true},
  {name: 'Europe/Vilnius', label: i18n.t('actionTimezone.tz.Europe/Vilnius'), global: true},
  {name: 'Europe/Helsinki', label: i18n.t('actionTimezone.tz.Europe/Helsinki'), global: true},
  {name: 'Europe/Tallinn', label: i18n.t('actionTimezone.tz.Europe/Tallinn'), global: true},
  {name: 'Europe/Riga', label: i18n.t('actionTimezone.tz.Europe/Riga'), global: true},
  {name: 'Europe/Athens', label: i18n.t('actionTimezone.tz.Europe/Athens'), global: true},
  {name: 'Europe/Sofia', label: i18n.t('actionTimezone.tz.Europe/Sofia'), global: true},
  {name: 'Europe/Warsaw', label: i18n.t('actionTimezone.tz.Europe/Warsaw'), global: true},
  {name: 'Europe/Tirane', label: i18n.t('actionTimezone.tz.Europe/Tirane'), global: true},
  {name: 'Europe/Podgorica', label: i18n.t('actionTimezone.tz.Europe/Podgorica'), global: true},
  {name: 'Europe/Budapest', label: i18n.t('actionTimezone.tz.Europe/Budapest'), global: true},
  {name: 'Europe/Stockholm', label: i18n.t('actionTimezone.tz.Europe/Stockholm'), global: true},
  {name: 'Europe/Bratislava', label: i18n.t('actionTimezone.tz.Europe/Bratislava'), global: true},
  {name: 'Europe/Vienna', label: i18n.t('actionTimezone.tz.Europe/Vienna'), global: true},
  {name: 'Europe/Zagreb', label: i18n.t('actionTimezone.tz.Europe/Zagreb'), global: true},
  {name: 'Europe/Prague', label: i18n.t('actionTimezone.tz.Europe/Prague'), global: true},
  {name: 'Europe/Ljubljana', label: i18n.t('actionTimezone.tz.Europe/Ljubljana'), global: true},
  {name: 'Europe/Berlin', label: i18n.t('actionTimezone.tz.Europe/Berlin'), global: true},
  {name: 'Europe/Malta', label: i18n.t('actionTimezone.tz.Europe/Malta'), global: true},
  {name: 'Europe/Copenhagen', label: i18n.t('actionTimezone.tz.Europe/Copenhagen'), global: true},
  {name: 'Europe/Rome', label: i18n.t('actionTimezone.tz.Europe/Rome'), global: true},
  {name: 'Europe/Busingen', label: i18n.t('actionTimezone.tz.Europe/Busingen'), global: true},
  {name: 'Europe/Luxembourg', label: i18n.t('actionTimezone.tz.Europe/Luxembourg'), global: true},
  {name: 'Europe/Amsterdam', label: i18n.t('actionTimezone.tz.Europe/Amsterdam'), global: true},
  {name: 'Europe/Brussels', label: i18n.t('actionTimezone.tz.Europe/Brussels'), global: true},
  {name: 'Europe/Paris', label: i18n.t('actionTimezone.tz.Europe/Paris'), global: true},
  {name: 'Europe/London', label: i18n.t('actionTimezone.tz.Europe/London'), global: true},
  {name: 'Europe/Madrid', label: i18n.t('actionTimezone.tz.Europe/Madrid'), global: true},
    // real location of Ceuta
  {name: 'Europe/Dublin', label: i18n.t('actionTimezone.tz.Europe/Dublin'), global: true},
  {name: 'Europe/Lisbon', label: i18n.t('actionTimezone.tz.Europe/Lisbon'), global: true},
  {name: 'Africa/Ceuta', label: i18n.t('actionTimezone.tz.Africa/Ceuta'), global: true},
  {name: 'Atlantic/Madeira', label: i18n.t('actionTimezone.tz.Atlantic/Madeira'), global: true},
  {name: 'Atlantic/Canary', label: i18n.t('actionTimezone.tz.Atlantic/Canary'), global: true},
  {name: 'Atlantic/Azores', label: i18n.t('actionTimezone.tz.Atlantic/Azores'), global: true},
  {name: 'GMT+2', label: i18n.t('actionTimezone.tz.GMT+2'), global: true},
  {name: 'America/Noronha', label: i18n.t('actionTimezone.tz.America/Noronha'), global: true},
  {name: 'America/St_Johns', label: i18n.t('actionTimezone.tz.America/St_Johns'), global: false},
  {name: 'America/Halifax', label: i18n.t('actionTimezone.tz.America/Halifax'), global: false},
  {name: 'America/Puerto_Rico', label: i18n.t('actionTimezone.tz.America/Puerto_Rico'), global: false},
  {name: 'America/New_York', label: i18n.t('actionTimezone.tz.America/New_York'), global: false},
  {name: 'America/Toronto', label: i18n.t('actionTimezone.tz.America/Toronto'), global: false},
  {name: 'America/Detroit', label: i18n.t('actionTimezone.tz.America/Detroit'), global: false},
  {name: 'America/Chicago', label: i18n.t('actionTimezone.tz.America/Chicago'), global: false},
  {name: 'America/Winnipeg', label: i18n.t('actionTimezone.tz.America/Winnipeg'), global: false},
  {name: 'America/Regina', label: i18n.t('actionTimezone.tz.America/Regina'), global: false},
  {name: 'America/Denver', label: i18n.t('actionTimezone.tz.America/Denver'), global: false},
  {name: 'America/Phoenix', label: i18n.t('actionTimezone.tz.America/Phoenix'), global: false},
  {name: 'America/Edmonton', label: i18n.t('actionTimezone.tz.America/Edmonton'), global: false},
  {name: 'America/Boise', label: i18n.t('actionTimezone.tz.America/Boise'), global: false},
  {name: 'America/Los_Angeles', label: i18n.t('actionTimezone.tz.America/Los_Angeles'), global: false},
  {name: 'America/Vancouver', label: i18n.t('actionTimezone.tz.America/Vancouver'), global: false},
  {name: 'America/Anchorage', label: i18n.t('actionTimezone.tz.America/Anchorage'), global: false},
  {name: 'America/Adak', label: i18n.t('actionTimezone.tz.America/Adak'), global: true},
  {name: 'Pacific/Honolulu', label: i18n.t('actionTimezone.tz.Pacific/Honolulu'), global: true},
  {name: 'GMT+12', label: i18n.t('actionTimezone.tz.GMT+12'), global: true},
];

/**
 * Model for Router's 'userTimezone' setting.
 *
 * @fires change:userTimezones
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/router/userTimezone/EditUserTimezone#attributes
   * @property {String} userTimezone
   *   Router's user set timezone
   */

  _snapshotAttributes: ['userTimezone'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    // userTimezone always exists
    return false;
  },

  initialize: function(attrs, options) {
    this.addDeviceStatus(options);

    if (!this.deviceStatus.hasCapability(GLOBAL_TZ_CAPABILITY)) {
      this.deviceStatus.onCapabilityAdded(this, GLOBAL_TZ_CAPABILITY, function() {
        this.trigger('change:userTimezones');
      });
    }
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('router');
      },
      events: 'change:userTimezone',
    },
  ],

  /**
   * Get the userTimezone from the rpc response and return a proper object.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      resp = {
        userTimezone: this.deviceConfig.get('router').get('userTimezone'),
      };
    }

    return resp;
  },

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Array|undefined}
   */
  validate: function(attrs, options) {
    var validDynamicTimezone = this.getUserTimezones().hasOwnProperty(attrs.userTimezone);

    if (!validDynamicTimezone) {
      return {
        userTimezone: i18n.t('actionTimezone.badTimezone'),
      };
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    return {
      name: 'router.setUserTimezone',
      data: {
        userTimezone: this.get('userTimezone'),
      },
    };
  },

  getUserTimezones: function() {
    var list = timezones;

    if (!this.deviceStatus.hasCapability(GLOBAL_TZ_CAPABILITY)) {
      list = _.filter(list, {global: false});
    }

    return _.zipObject(_.pluck(list, 'name'), _.pluck(list, 'label'));
  },
});
