'use strict';

var BaseGroup = require('config/Group');
var i18n = require('i18next');
var RenderPositions = require('config/RenderPositions');

/**
 * 'Client VPN' configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'vpnEnable',
      position: RenderPositions.first,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'vpnEnableOpenVpn',
      position: RenderPositions.middle,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'vpnAddress',
      position: RenderPositions.middle,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'vpnRoutes',
      position: RenderPositions.last,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'vpnAuth',
      position: RenderPositions.first,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'vpnUsers',
      position: RenderPositions.full,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  // do not merge user add/update/delete
  shouldMergeTasks: ['vpn.setOptions'],

  // don't re-send the core VPN config if operator is just editing users
  skipTasksWithNoChange: ['vpn.setOptions'],

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.vpn');
  },
});
