'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('manage/details/ports/ports-drillable.html');
var PortsView = require('manage/details/ports/PortsView');
var Radio = require('backbone.radio');
var layoutChannel = Radio.channel('layoutChannel');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');
var detailTypes = require('manage/details/detailTypes');
var Backbone = require('backbone');

var SUPPORTS_EXTENDED_PORT_STATUS = 'supportsExtendedPortStatus';
var SUPPORTS_LACP_NIC_BONDING_STATUS = 'supportsLacpNicBondingStatus';

module.exports = Marionette.View.extend({
  template: twig({allowInlineIncludes: true, data: tpl}),

  ui: {
    button: '.btn',
  },

  regions: {
    portsOverview: '.rg-ports-overview',
  },

  events: {
    'click @ui.button': 'changeToPortsDetailsPage',
  },

  initialize: function(options) {
    this.deviceConfig = options.deviceConfig;
    this.deviceStatus = options.deviceStatus;
    this.deviceMac = options.deviceMac;
    this.isVlanView = options.isVlanView;

    // model here will contain the VLAN id that is currently selected in the vlan view.
    if (options.model) {
      this.model = options.model;
    }

    var hasCapability = this.deviceStatus.hasCapability(SUPPORTS_EXTENDED_PORT_STATUS) ||
      this.deviceStatus.hasCapability(SUPPORTS_LACP_NIC_BONDING_STATUS);

    // These two capabilities should be in the same firmware as they were packaged together under the same task.
    if (!hasCapability) {
      this.deviceStatus.onCapabilityAdded(this, SUPPORTS_EXTENDED_PORT_STATUS, this._toggleMoreInfoButton);
      this.deviceStatus.onCapabilityAdded(this, SUPPORTS_LACP_NIC_BONDING_STATUS, this._toggleMoreInfoButton);
    }
  },

  onRender: function() {
    this._toggleMoreInfoButton();
    this._addPortsOverView();
  },

  templateContext: function() {
    return {
      portsDetailsUrl: deviceConfigChannel.request('get:url', detailTypes.PORTS, this.deviceMac),
    };
  },

  _addPortsOverView: function() {
    var viewOptions = {
      isVlanView: this.isVlanView,
      deviceStatus: this.deviceStatus,
      deviceConfig: this.deviceConfig,
    };

    if (this.model) {
      viewOptions.model = this.model;
    }

    var view = new PortsView(viewOptions);

    this.showChildView('portsOverview', view);
  },

  _toggleMoreInfoButton: function() {
    var hasCapability = this.deviceStatus.hasCapability(SUPPORTS_EXTENDED_PORT_STATUS) ||
      this.deviceStatus.hasCapability(SUPPORTS_LACP_NIC_BONDING_STATUS);

    this.ui.button.toggleClass('hidden', !hasCapability);
  },

  changeToPortsDetailsPage: function(event) {
    event.preventDefault();
    var href = event.target.pathname;

    Backbone.history.navigate(href);

    layoutChannel.request('change:page', {
      deviceMac: this.deviceMac,
      page: deviceConfigChannel.request('trim:url', href),
    });
  },
});
