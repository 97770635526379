'use strict';

var i18n = require('i18next');
var OperatorPing = require('actions/operatorPing/OperatorPing');
var FormView = require('actions/operatorPing/FormView');
var StaticView = require('actions/operatorPing/StaticView');

/**
 * Action configuration for operatorPing
 */
module.exports = {
  title: i18n.t('operatorPing.title'),
  Model: OperatorPing,
  FormView: FormView,
  TextView: StaticView,
};
