'use strict';

var _ = require('lodash');
var MultiActionItem = require('actions/shared/MultiActionItem');
var EditRule = require('actions/shared/portForwarding/Rule');

/**
 * Edit model for Port Forwarding Rules
 */
module.exports = MultiActionItem.extend({

  collectionModel: EditRule,

  initialize: function(attributes, options) {
    this.addDeviceStatus(options);
    this.set({supportsNetObj: this.deviceStatus.hasCapability('supportsNetworkObject')}, {fromConfig: true});
    var self = this;
    this.deviceStatus.onCapabilityAdded(this, 'supportsNetworkObject', function() {
      self.set({supportsNetObj: true}, {fromConfig: true});
    });
  },

  /**
   * Overridden to always return false (port forwarding is a fixed group)
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var redirects = this.deviceConfig.get('firewall').get('redirect').toJSON();
      var netObjs = _.map(this.deviceConfig.get('firewall').get('networkObjects').toJSON(), function(obj) {
        return _.pick(obj, 'id', 'description');
      });

      var parsedItems = _.map(_.filter(redirects, 'direction', 'inbound'), function(item) {
        if (item.netObjId) {
          item.srcIp = _.findWhere(netObjs, {id: item.netObjId})
            ? _.findWhere(netObjs, {id: item.netObjId}).description
            : item.netObjId;
        }
        return _.extend(item, {netObjs: netObjs});
      });

      return {
        items: parsedItems,
        netObjs: netObjs,
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('firewall').get('redirect');
      },
      events: 'add remove change',
    },
    {
      getDispatcher: function(config) {
        return config.get('firewall').get('networkObjects');
      },
      events: 'add remove change',
    },
  ],

});
