'use strict';

var i18n = require('i18next');
var EditMacFilter = require('actions/macFilter/EditMacFilter');
var FormView = require('actions/macFilter/FormView');
var StaticView = require('actions/macFilter/StaticView');

/**
 * Action configuration for the "Mac Filters" action component.
 */
module.exports = {
  title: i18n.t('actionMacFilter.title'),
  Model: EditMacFilter,
  FormView: FormView,
  TextView: StaticView,
};
