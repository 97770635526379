'use strict';

var i18n = require('i18next');
var EditEnableTitanHq = require('actions/webFiltersTitanHq/enable/EditEnableTitanHq');
var FormView = require('actions/webFiltersTitanHq/enable/FormView');
var StaticView = require('actions/webFiltersTitanHq/enable/StaticView');

/**
 * Action configuration for Web Filters TitanHQ
 */
module.exports = {
  title: i18n.t('actionWebFilters.title'),
  Model: EditEnableTitanHq,
  FormView: FormView,
  TextView: StaticView,
};
