'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('components/remoteSites/tunnel.html');
var Modal = require('components/modal/ModalView');
var ModalChildView = require('components/modal/GenericChildView');
var InfoView = require('components/remoteSites/InfoView');
var i18n = require('i18next');
var moment = require('moment');

/**
 * Localized tunnel statuses:
 * i18n.t('remoteSites.tunnelStatus.active'),
 * i18n.t('remoteSites.tunnelStatus.inactive'),
 * i18n.t('remoteSites.tunnelStatus.error'),
 * i18n.t('remoteSites.tunnelStatus.connecting')
 * i18n.t('remoteSites.tunnelStatus.partial')
 * i18n.t('remoteSites.tunnelStatus.unknown')
 *
 * Localized tunnel types:
 * i18n.t('siteVpn.tunnelType.peer'),
 * i18n.t('siteVpn.tunnelType.hub'),
 * i18n.t('siteVpn.tunnelType.spoke'),
 */
module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  ui: {
    'info': '.info',
  },

  events: {
    'click @ui.info': 'showModal',
  },

  modelEvents: {
    'change:remoteMac change:remoteHost change:role change:status change:established change:peerIp': 'render',
  },

  templateContext: function() {
    return {
      role: i18n.t('siteVpn.tunnelType.' + this.model.get('role')),
      established: this.calculateDuration(),
      status: i18n.t('remoteSites.tunnelStatus.' + (this.model.get('status') || 'unknown')),
    };
  },

  showModal: function() {
    var view = new InfoView({
      type: 'tunnel',
      remoteSubnets: this.model.get('remoteSubnets'),
      localSubnets: this.model.get('localSubnets'),
    }).render();

    var modalView = new ModalChildView({
      content: view.$el.html(),
    });
    var model = Backbone.Model.new({
      childView: modalView,
      actionButtonText: '',
      closeText: i18n.t('common.close'),
    });

    var modal = new Modal({model: model});

    modal.render();
  },

  /**
   * Compute a human-readable display string representing
   * how long this tunnel has been up
   *
   * @returns {String}
   */
  calculateDuration: function() {
    var duration = this.model.get('established'); // ISO 8601 duration

    if (!duration) {
      return;
    }

    return moment.duration(duration).humanize();
  },
});
