'use strict';

var i18n = require('i18next');
var SpeedDuplexSettings = require('actions/speedDuplexSettings/EditSpeedDuplexSettings');
var FormView = require('actions/speedDuplexSettings/FormView');
var StaticView = require('actions/speedDuplexSettings/StaticView');

/**
 * Action configuration for the speed and duplex settings.
 */
module.exports = {
  title: i18n.t('actionSpeedDuplex.title'),
  Model: SpeedDuplexSettings,
  FormView: FormView,
  TextView: StaticView,
};
