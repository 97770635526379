'use strict';

var _ = require('lodash');
var tplHelpers = require('lib/tplHelpers');
var Marionette = require('backbone.marionette');
var tpl = require('actions/alertConfiguration/subscriber/subscriber.html');
var twig = require('twig').twig;
var PriorityView = require('actions/alertConfiguration/priority/PriorityView');
var RenderChanges = require('lib/behaviors/RenderChanges');

var PriorityCollectionView = Marionette.CollectionView.extend({
  childView: PriorityView,
});

module.exports = Marionette.View.extend({
  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    email: '[name="email"]',
    remove: '.btn-remove',
    undo: '.btn-undo',
    messages: '.help-block',
  },

  events: {
    'blur @ui.email': 'onEmailChange',
    'click @ui.remove': 'onSubscriberRemove',
    'click @ui.undo': 'onSubscriberUndoRemove',
  },

  modelEvents: {
    'change:remove': 'render',
    'invalid': 'onError',
  },

  regions: {
    'priorities': '.rg-priorities',
  },

  templateContext: function() {
    var context = {};
    return _.extend(context, tplHelpers.apply(this));
  },

  onRender: function() {
    this.initChildViews();
  },

  initChildViews: function() {
    if (this.model.get('remove') === false) {
      this.showChildView('priorities', new PriorityCollectionView({
        collection: this.model.get('priorities'),
      }));
    }
  },

  onEmailChange: function(ev) {
    var el = ev.currentTarget;

    this.clearError(el.name);
    this.model.set({email: el.value}, {commit: true});
  },

  onSubscriberRemove: function() {
    if (!this.model.isNew()) {
      this.model.applySnapshot();
      this.model.set({remove: true});
    } else {
      this.model.collection.remove(this.model);
    }
  },

  onSubscriberUndoRemove: function() {
    this.model.set({remove: false});
  },

  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

  clearError: function(key) {
    this.ui[key].bs3ui('clearGroupedFieldError', key, this.ui.messages);
  },
});
