'use strict';

var _ = require('lodash');
var AbstractEditAddress = require('actions/staticAddress/AbstractEditAddress');

/**
 * View-Model for editing a VPN subnet.
 */
module.exports = AbstractEditAddress.extend({
  actionId: 'vpnAddress',

  _snapshotAttributes: ['af', 'address', 'size'],

  defaults: {
    usage: 'network',
    af: 'ipv4',
    role: 'internal',
    vpn: true,
  },

  /**
   * Overridden to always return false (VPN is a fixed group)
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  toggledOnOffBy: 'vpnEnable',

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig) {
      var attrs = {};
      var vpn = this.deviceConfig.getVpn();
      var subnet;
      if (vpn) {
        subnet = this.deviceConfig.getSubnet(vpn.id);
      }
      if (subnet) {
        attrs = subnet.toJSON();
        attrs.id = vpn.id;
        attrs.role = vpn.get('role');
        attrs.af = attrs.type;
        attrs.gateway = attrs.defaultGateway;
        attrs = _.omit(attrs, ['type', 'defaultGateway']);
      }
      return attrs;
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks');
      },
      // improvement: add support for event param filters,
      // so we only react if network type==='vpn' is added
      events: 'add',
    },
    {
      getDispatcher: function(config) {
        return config.getVpn();
      },
      events: 'change:addressMap',
    },
    {
      getDispatcher: function(config) {
        var vpn = config.getVpn();
        if (vpn) {
          return config.getSubnet(vpn.id);
        }
      },
      events: 'change',
    },
  ],

  /**
   * @return {Object}
   */
  getTask: function() {
    return {
      name: 'vpn.setOptions',
      data: {
        id: this.id,
        subnetAddress: this.get('address'),
        subnetSize: this.get('size'),
      },
    };
  },
});
