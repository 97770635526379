'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var vlanIdTpl = require('manage/details/vlan/configs/vlanId.html');

var classProps = {
  /**
   * @param {lib/models/Network} network
   * @return {Boolean}
   */
  isApplicable: function(network) {
    return !!network.get('tagId');
  },
};

/**
 * Renders the vlan section for the selected network
 */
module.exports = Marionette.View.extend({
  /**
   * Reference to a {@link lib/models/DeviceConfiguration} "networks" object.
   * @member {Backbone.Model} manage/details/vlan/configs/AddressView#model
   */

  /**
   * A reference to deviceConfig.
   * @member {lib/models/DeviceConfiguration} manage/details/vlan/configs/DhcpPoolView#deviceConfig
   */

  /**
   * A reference to deviceStatus.
   * @member {lib/models/DeviceStatus} manage/details/vlan/configs/DhcpPoolView#deviceStatus
   */

  template: twig({data: vlanIdTpl}),
}, classProps);
