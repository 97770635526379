var moment = require('moment');
var i18n = require('i18next');

var formatTime = function(moment) {
  if (moment.hour() === 0 && moment.minute() === 0) {
    // midnight
    return i18n.t('dateFormat.time.midnight');
  }
  if (moment.hour() === 12 && moment.minute() === 0) {
    // noon
    return i18n.t('dateFormat.time.noon');
  }
  return moment.format('LT');
};

module.exports = {
  /**
   * Provides a localized label for a given weekday value
   *
   * @param {String} value
   *   Weekday like '0', '1', ... '6'
   * @returns {String}
   */
  labelForWeekday: function(value) {
    var day = parseInt(value);
    return moment().day(day).format('dddd');
  },

  /**
   * Provides a localized label for a given time range
   *
   * @param {String} start
   *   Start time as 'HHMM'
   * @param {Number} interval
   *   Duration in seconds
   * @returns {String}
   */
  labelForTimeWindow: function(start, interval) {
    var hours = start.substr(0, 2);
    var minutes = start.substr(2, 2);
    var startTime = moment([1970, 0, 1, hours, minutes]);
    var endTime = startTime.clone().add(interval, 'seconds');
    var startLabel = formatTime(startTime);
    var endLabel = formatTime(endTime);
    return i18n.t('dateFormat.time.range', {start: startLabel, end: endLabel});
  },

  /**
   * Provides a localized label for a given time range
   *
   * @param {String} start
   *   Start time as 'HH'
   * @returns {String}
   */
  labelForHours: function(start) {
    var hours = start;
    var minutes = 0;
    var startTime = moment([1970, 0, 1, hours, minutes]);
    var startLabel = formatTime(startTime);
    return i18n.t('dateFormat.time.hours', {start: startLabel});
  },
};
