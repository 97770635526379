'use strict';

var Marionette = require('backbone.marionette');
var RuleView = require('actions/shared/portForwarding/RuleView');
var RuleModel = require('actions/shared/portForwarding/Rule');
var tpl = require('actions/portForwardingRules/form.html');
var twig = require('twig').twig;
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

var RulesListView = Marionette.CollectionView.extend({
  childView: RuleView,

  // wire child views to ToggleFormOff
  childViewTriggers: {
    'render': 'item:render',
    'onoff:status:change': 'item:onoff:status:change',
  },
});

/**
 * Renders the editable form for the collection of port forwarding rules
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/portForwardingRules/FormView#model
   * @type {actions/portForwardingRules/EditPortForwardingRules}
   */

  behaviors: [
    {
      behaviorClass: ToggleFormOff,
      enableCallback: function(ruleView) {
        ruleView.toggleDestPort();
      },
    },
  ],

  template: twig({data: tpl}),

  regions: {
    rules: '.rg-rules',
  },

  ui: {
    add: '.btn-add',
  },

  events: {
    'click @ui.add': 'addRule',
  },

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.showChildView('rules', new RulesListView({
      collection: this.model.get('items'),
    }));
  },

  /**
   * Add a new blank rule to the list of port forwarding rules
   */
  addRule: function() {
    var rule = new RuleModel({
      role: 'PORT_FORWARD',
      netObjs: this.model.get('netObjs'),
    });
    this.model.get('items').add(rule);
  },

});
