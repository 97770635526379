'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var networkUtils = require('lib/network');
var Radio = require('backbone.radio');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');

/**
 * Model for DNA to Non DNA VPN tunnel
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({

  /**
   * @member {Object} #attributes
   * @property {Boolean} remove
   *   Whether the reservation should be removed on save
   * @property {String} id
   *   ID of the tunnel config
   * @property {String} remoteId
   *   Id for the remote device. This can be whatever string the user set on the peer device
   * @property {String} localId
   *   Id for the DNA. The user sets this on the DNA and peer device
   * @property {String} keyExchange
   *   Which ike version to use
   * @property {String} deviceType
   * a  The type/vendor of the peer
   * @property {String} psk
   *   The shared secret to be set on both devices
   * @property {object} remoteSubnets
   *   subnets on the peer device that this tunnel should use
   * @property {string} remoteHost
   *   the DNS hostname or ip address of the peer device
   * @property {Boolean} enabled
   *   If the tunnel should be enabled or not
   * @property {Boolean} initiator
   *   Inbound or outbound
   * @property {Boolean} isCurrentDeviceSpoke
   *   If the DNA being viewed is considered a spoke or not
   */

  _snapshotAttributes: [
    'remoteHost',
    'enabled',
    'initiator',
    'remoteSubnets',
    'remoteId',
    'localId',
    'keyExchange',
    'deviceType',
    'psk',
    'remove',
    'isCurrentDeviceSpoke',
    'phase1Enc',
    'phase1Hash',
    'phase1DH',
    'phase1Life',
    'phase2Enc',
    'phase2Hash',
    'phase2DH',
    'phase2Life',
    'dpdEnabled',
    'dpdTimeout',
    'standbyEnabled',
  ],

  defaults: {
    remove: false,
    enabled: true,
    remoteMac: null,
    initiator: false,
    keyExchange: 'ikev2',
    deviceType: 'other',
    remoteSubnets: null,
    remoteHost: '',
    remoteId: '',
    localId: '',
    psk: '',
    isCurrentDeviceSpoke: false,
    phase1Enc: 'aes128',
    phase1Hash: 'sha1',
    phase1DH: 'modp2048',
    phase1Life: 86400,
    phase2Enc: 'aes128',
    phase2Hash: 'sha1',
    phase2DH: 'modp2048',
    phase2Life: 86400,
    dpdEnabled: true,
    dpdTimeout: 30,
    standbyEnabled: true,
  },

  initialize: function(attrs, options) {
    this.deviceStatus = deviceConfigChannel.request('get:status');
    this.attributes.newSite = _.isUndefined(attrs);
  },

  validate: function(attrs, options) {
    var errors = {};
    if (this.get('remove') === true) {
      return;
    }

    if (_.has(attrs, 'psk')) {
      if (!attrs.psk || attrs.psk.length < 16) {
        errors.psk = i18n.t('actionSiteVpn.badPsk');
      }
    }

    if (_.has(attrs, 'remoteSubnets')) {
      if (_.isEmpty(attrs.remoteSubnets)) {
        errors.remoteSubnets = i18n.t('actionSiteVpn.badRemoteSubnet');
      } else {
        attrs.remoteSubnets.forEach(function(remoteSubnet) {
          if (!networkUtils.validSubnet(remoteSubnet) || _.startsWith(remoteSubnet, '0.0.0.0')) {
            errors.remoteSubnets = i18n.t('actionSiteVpn.badRemoteSubnet');
          }
        });
      }
    }

    if (_.has(attrs, 'remoteHost')) {
      if (_.isEmpty(attrs.remoteHost) ||
        (!networkUtils.validHostname(attrs.remoteHost) && !networkUtils.validIP(attrs.remoteHost))) {
        errors.remoteHost = i18n.t('actionSiteVpn.remoteHostRequired');
      }
    }

    if (_.has(attrs, 'remoteId')) {
      if (_.isEmpty(attrs.remoteId)) {
        errors.remoteId = i18n.t('actionSiteVpn.remoteIdRequired');
      }
    }

    if (_.has(attrs, 'localId')) {
      if (_.isEmpty(attrs.localId)) {
        errors.localIdRequired = i18n.t('actionSiteVpn.localIdRequired');
      }
    }

    if (_.has(attrs, 'phase1Life')) {
      if (!networkUtils.validInt(attrs.phase1Life)) {
        errors.phase1LifeRequired = i18n.t('actionSiteVpn.keyLifetimeError');
      }
    }

    if (_.has(attrs, 'phase2Life')) {
      if (!networkUtils.validInt(attrs.phase2Life)) {
        errors.phase2LifeRequired = i18n.t('actionSiteVpn.keyLifetimeError');
      }
    }

    if (_.has(attrs, 'dpdTimeout')) {
      if (!networkUtils.validInt(attrs.dpdTimeout)) {
        errors.dpdTimeoutRequired = i18n.t('actionSiteVpn.dpdTimeoutError');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  getModelVals: function() {
    /**
     * i18n.t('actionSiteVpn.phase1Heading')
     * i18n.t('actionSiteVpn.phase2Heading')
     */

    return {
      'encryption': [this.get('phase1Enc'), this.get('phase2Enc')],
      'hashing': [this.get('phase1Hash'), this.get('phase2Hash')],
      'dh': [this.get('phase1DH'), this.get('phase2DH')],
      'life': [this.get('phase1Life'), this.get('phase2Life')],
    };
  },

  /**
   * @return {{data: {enabled, initiator, remoteHost, localId, keyExchange, remoteSubnets, psk, remoteId}}}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return;
    }

    var container = {data: {
      enabled: this.get('enabled'),
      initiator: this.get('initiator'),
      remoteHost: this.get('remoteHost'),
      localId: this.get('localId'),
      keyExchange: this.get('keyExchange'),
      deviceType: this.get('deviceType'),
      remoteSubnets: this.get('remoteSubnets'),
      psk: this.get('psk'),
      remoteId: this.get('remoteId'),
      isSpoke: this.get('isCurrentDeviceSpoke'),
    }};

    if (this.get('deviceType').toLowerCase() === 'other') {
      container.data.phase1Enc = this.get('phase1Enc');
      container.data.phase1Hash = this.get('phase1Hash');
      container.data.phase1DH = this.get('phase1DH');
      container.data.p1timer = this.get('phase1Life');
      container.data.phase2Enc = this.get('phase2Enc');
      container.data.phase2Hash = this.get('phase2Hash');
      container.data.phase2DH = this.get('phase2DH');
      container.data.p2timer = this.get('phase2Life');
      container.data.dpdenabled = this.get('dpdEnabled');
      container.data.dpdtimer = this.get('dpdTimeout');
      container.data.standbyEnabled = this.get('standbyEnabled');
    }

    return container;
  },
});
