'use strict';

var i18n = require('i18next');
var DnsLookup = require('actions/dnsLookup/DnsLookup');
var FormView = require('actions/dnsLookup/FormView');
var StaticView = require('actions/dnsLookup/StaticView');

/**
 * Action configuration for DNS lookup
 */
module.exports = {
  title: i18n.t('dnsLookup.title'),
  Model: DnsLookup,
  FormView: FormView,
  TextView: StaticView,
};
