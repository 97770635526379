'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');

/**
 * Edit model for VPN's "Authentication" settings.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} #attributes
   * @property {string} secret
   *   Shared secret for VPN
   */

  _snapshotAttributes: ['secret'],

  defaults: {
    secret: '',
  },

  /**
   * Overridden to always return false (VPN is a fixed group)
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  toggledOnOffBy: 'vpnEnable',

  initialize: function(attrs, options) {
    this.addDeviceStatus(options);
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var attrs = {};
      var vpn = this.deviceConfig.getVpn();
      if (vpn) {
        attrs.id = vpn.id;
        attrs.secret = vpn.get('secret');
      }
      return attrs;
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      // gateway certificate availability/identity
      getDispatcher: function() {
        return this.deviceStatus.get('router');
      },
      events: 'change:dynHostname',
    },
    {
      getDispatcher: function(config) {
        return config.get('networks');
      },
      // improvement: add support for event param filters,
      // so we only react if network type==='vpn' is added
      events: 'add change:secret',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'secret')) {
      if (!attrs.secret || attrs.secret.length < 16) {
        errors.secret = i18n.t('actionVpn.badSecret');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    return {
      name: 'vpn.setOptions',
      data: {
        id: this.get('id'),
        secret: this.get('secret'),
      },
    };
  },

});
