'use strict';

module.exports = {

  /**
   * Creates a delimited string
   *
   * @param {String} string
   * @param {Integer} size
   * @param {String} delimit
   * @return {String}
   */
  stringDelimit: function(string, size, delimit) {
    var regex = '[\\w]{1,' + size + '}';
    var exp = new RegExp(regex, 'g');
    var parts = string.match(exp);

    if (!delimit) {
      delimit = ':';
    }

    return parts.join(delimit);
  },

  /**
   * Prepend 0s to a value to achieve a minimum length
   *
   * @param {Number|String} value
   * @param {Number} minLength
   *   Default: 2
   * @returns {String}
   */
  zeroPad: function(value, minLength) {
    if (!minLength || isNaN(minLength)) {
      minLength = 2;
    }

    var strValue = value + '';
    var prefixed;

    if (strValue.length >= minLength) {
      return strValue;
    }

    prefixed = new Array(minLength + 1).join('0') + strValue;
    return prefixed.slice(strValue.length);
  },
};
