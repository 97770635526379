'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var RenderChanges = require('lib/behaviors/RenderChanges');
var tplHelpers = require('lib/tplHelpers');
var tpl = require('actions/webFiltersTitanHq/settings/form.html');
var twig = require('twig').twig;
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

/**
 * Renders the settings setting dropdown for titan Hq.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/webFiltersTitanhq/settings/FormView#model
   * @type {actions/webFiltersTitanhq/settings/EditTitanHqSettings}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    zone: '[name="zone"]',
    safesearch: '[name="safesearch"]',
    allowUnclassified: '[name="allowUnclassified"]',
  },

  events: {
    'change @ui.zone': 'saveForm',
    'change @ui.safesearch': 'saveForm',
    'change @ui.allowUnclassified': 'saveForm',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  templateContext: function() {
    var context = {zoneList: this.model.get('zoneList'), zone: this.model.get('zone')};
    return _.extend(context, tplHelpers.apply(this));
  },

  /**
   * Pushes updates from the DOM into the model and validate
   *
   * @param {Event} ev
   */
  saveForm: function(ev) {
    var name = ev.currentTarget.name;
    var value = ev.currentTarget.value;

    switch (name) {
      case 'zone':
        this.ui.zone.bs3ui('clearFieldError');
        this.model.set({zone: value}, {commit: true});
        break;
      case 'safesearch':
        this.ui.safesearch.bs3ui('clearFieldError');
        this.model.set({safesearch: value === 'on'}, {commit: true});
        break;
      case 'allowUnclassified':
        this.ui.allowUnclassified.bs3ui('clearFieldError');
        this.model.set({allowUnclassified: value === 'on'}, {commit: true});
        break;
      default:
        break;
    }
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/actions/webFiltersTitanhq/EditZoneTitanHq} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.zone) {
      this.ui.zone.bs3ui('showFieldError', error.zone);
    }
  },
});
