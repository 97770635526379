'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Marionette = require('backbone.marionette');
var tpl = require('actions/webFiltersTitanHq/enable/form.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

/**
 * Renders the "Web Filters" form fields.
 */
module.exports = Marionette.View.extend({
  /**
   * @name #model
   * @type {actions/webFiltersTitanHq/enable/EditEnableTitanHq}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    enable: '.enable',
  },

  events: {
    'change @ui.enable': 'saveForm',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    return _.extend(tplHelpers.apply(this), {
      enabled: this.model.get('enabled'),
    });
  },

  /**
   * Checks if values have changed and saves.
   *
   * @param {Object} event
   */
  saveForm: function(event) {
    var control = event.target;
    var value = control.value;
    this.model.set({enabled: value === 'on' && $(control).is(':checked')});
  },
});
