'use strict';

var _ = require('lodash');
var MultiActionItem = require('actions/shared/MultiActionItem');
var Filter = require('actions/domainFilters/Filter');

/**
 * Edit model for Domain Filters.
 */
module.exports = MultiActionItem.extend({
  /**
   * @member {Object} #attributes
   * @property {Collection} items
   */
  collectionModel: Filter,

  /**
   * Overridden to always return false
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  toggledOnOffBy: 'webFilters',

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var attrs;
      var webFilters = this.deviceConfig.get('webFilters');

      if (webFilters) {
        attrs = {
          items: this._getDomainFilters(webFilters),
        };
      }

      return attrs || {};
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('webFilters');
      },
      events: 'change:whitelistBlacklist',
    },
  ],

  getTask: function() {
    return {
      name: 'webFilters.buildWebFilterLists',
      data: {
        domains: this.getItems(),
      },
    };
  },

  getItems: function() {
    var filtered = [];
    var items = this.get('items').toJSON();

    items.forEach(function(obj) {
      if (!obj.remove) {
        filtered.push({domain: obj.domain, type: obj.type});
      }
    });

    return filtered;
  },

  /**
   * Adds an ID to the domain filters
   *
   * @param {Array} webFilters
   * @return {Array} filters
   */
  _getDomainFilters: function(webFilters) {
    var domainFilters = webFilters.get('whitelistBlacklist') || [];

    var filters = domainFilters.map(function(filter) {
      return _.extend({}, filter, {id: filter.domain});
    });

    return filters;
  },
});
