'use strict';

var _ = require('lodash');

/**
 * mixin factory for enabling Backbone models to handle child models and
 * collections
 *
 * this module provides a function that must be called to produce
 * the actual mixin with the appropriate configuration
 *
 * Warning: Backbone's .hasChanged() and .getChangedAttributes() methods
 * will not currently reflect changes to child models or collections
 * managed by this mixin.
 *
 * @mixin lib/mixins/subModels
 *
 * @param {Object} childTypes
 *   map of attribute names to constructors for sub-models/collections
 *   e.g. {foo: FooModel, bar: BarCollection}
 * @param {Function} ParentType
 *   parent class for delegating other normal functionality
 * @returns {Function}
 */
module.exports = function(childTypes, ParentType) {
  return {
    /**
     * Creates and attaches the specified sub-models upon model creation
     *
     * @param {Object} attributes
     * @param {Object|undefined} options
     */
    constructor: function(attributes, options) {
      var attrs = attributes || {};

      _.forEach(childTypes, function(ChildType, attrName) {
        var child = new ChildType(attrs[attrName], options);
        this.listenTo(child, 'change add remove', this._forwardEvents);
        attrs[attrName] = child;
      }, this);

      ParentType.call(this, attrs, options);
    },

    /**
     * Forwards values to sub-models/collections as needed
     *
     * @param {String|Object} key
     * @param {*} val
     * @param {Object|undefined} options
     * @return {Object} self
     */
    set: function(key, val, options) {
      // normalize key, value vs. {key: value} style - from Backbone source
      var attrs;
      if (typeof key === 'object') {
        attrs = key;
        options = val;
      } else {
        (attrs = {})[key] = val;
      }
      // end normalize

      Object.keys(childTypes).forEach(function(attrName) {
        if (this.has(attrName) && attrName in attrs) {
          this.get(attrName).set(attrs[attrName], options);
          attrs = _.omit(attrs, attrName);
        }
      }, this);

      return ParentType.prototype.set.call(this, attrs, options);
    },

    /**
     * Serializes sub models and collections
     *
     * @param {Object} options
     * @return {Object}
     */
    toJSON: function(options) {
      var shallow = ParentType.prototype.toJSON.apply(this, arguments);

      var children = _.mapValues(childTypes, function(ChildType, attrName) {
        if (this.has(attrName)) {
          return this.get(attrName).toJSON();
        }
        return this.get(attrName);
      }, this);

      return _.extend({}, shallow, children);
    },

    /**
     * Clones sub models and collections
     *
     * @return {Object}
     */
    clone: function() {
      return new this.constructor(this.toJSON());
    },

    /**
     * Forwards events from child models/collections to
     * a "child:modify" event on the parent model
     *
     * @private
     */
    _forwardEvents: function() {
      this.trigger('child:modify');
    },
  };
};
