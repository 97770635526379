'use strict';

var Marionette = require('backbone.marionette');
var i18n = require('i18next');
var twig = require('twig').twig;
var formTpl = require('actions/wanCustomMac/form.html');
var RenderChanges = require('lib/behaviors/RenderChanges');
var RemoveConfig = require('manage/edit/config/action/removeConfig/RemoveConfig');
var networkUtils = require('lib/network');

/**
 * Renders the mac field available to an external VLAN.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/wanCustomMac/FormView#model
   * @type {actions/wanCustomMac/EditWanCustomMac}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: RemoveConfig,
    },
  ],

  template: twig({data: formTpl}),

  ui: {
    'customMac': '.wan-customMac',
  },

  events: {
    'blur @ui.customMac': 'formatMacAndUpdateModel',
    'change @ui.customMac': 'handleFormChange',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  /**
   * Decorates field on error.
   *
   * @param {actions/vlan/wanCustomMac} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.customMac) {
      this.ui.customMac.bs3ui('showFieldError', error.customMac);
    }
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    return {
      'formLabel': i18n.t('actionWanCustomMac.title'),
      'helpBlock': i18n.t('actionWanCustomMac.help'),
      'pendingDeleteMsg': this.model.getDeleteMsg.bind(this.model),
    };
  },

  /**
   * @param {Event} ev
   */
  handleFormChange: function(ev) {
    if (ev.currentTarget.name === 'customMac') {
      this.formatMacAndUpdateModel(ev);
    }
  },

  formatMacAndUpdateModel: function(event) {
    var value = event.currentTarget.value;
    var formattedValue = networkUtils.formatMacAddress(value);
    if (formattedValue) {
      event.currentTarget.value = formattedValue;
    }

    this.updateModel(event);
  },

  /**
   * Sync changes in UI input elements to the model and trigger validation
   *
   * @param {Object} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'customMac':
        this.clearError('customMac');
        this.model.set({customMac: value}, {commit: true});
        break;
      default:
        break;
    }
  },

  /**
   * Remove an error message from the view (if present)
   *
   * @param {String} key
   *   Identifier for the error message
   */
  clearError: function(key) {
    this.ui[key].bs3ui('clearFieldError', key, this.ui.messages);
  },

});
