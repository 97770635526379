'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var i18n = require('i18next');
var tpl = require('actions/vpn/users/formUser.html');
var tplHelpers = require('lib/tplHelpers');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the "Users" form fields.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/vpn/users/FormView#model
   * @type {actions/vpn/users/EditVpnUsers
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    enabled: '[name="enabled"]',
    description: '[name="description"]',
    username: '[name="username"]',
    newPassword: '[name="newPassword"]',
    passwordMode: '.btn-password-mode',
    passwordInput: '.password-input',
    remove: '.btn-remove',
    messages: '.help-block',
  },

  events: {
    'change @ui.enabled': 'updateModel',
    'blur @ui.description': 'updateModel',
    'blur @ui.username': 'updateModel',
    'blur @ui.newPassword': 'updateModel',
    'click @ui.passwordMode': 'onPasswordModeChange',
    'click @ui.remove': 'handleRemoveButton',
  },

  modelEvents: {
    'change': 'onModelChange',
    'change:remove': 'render',
    'change:enabled': 'onEnabledChange',
    'invalid': 'onError',
    'noConflicts': 'onNoConflicts',
  },

  onBeforeRender: function() {
    this.isItemOff = !this.model.get('enabled');
  },

  onModelChange: function(model, options) {
    if (options.fromConfig) {
      this.render();
    }
  },

  onEnabledChange: function(model, value) {
    this.isItemOff = !value;
    this.triggerMethod('onoff:status:change', this);
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var context = {
      isNew: _.bind(this.model.isNew, this.model),
    };
    return _.extend({}, context, tplHelpers.apply(this));
  },

  /**
   * Sync changes in UI input elements to the model and trigger validation
   *
   * @param {Object} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'enabled':
        this.model.set({enabled: event.currentTarget.checked});
        break;

      case 'description':
        this.clearError('description');
        this.model.set({description: value}, {commit: true});
        break;

      case 'username':
        this.clearError('username');
        this.model.set({username: value}, {commit: true});
        break;

      case 'newPassword':
        this.clearError('newPassword');
        this.model.set({newPassword: value}, {commit: true});
        break;

      default:
        break;
    }
  },

  /**
   * Remove this rule
   */
  handleRemoveButton: function() {
    if (this.model.isNew()) {
      // remove never-saved users immediately
      this.model.collection.remove(this.model);
    } else {
      // flag existing users for deletion upon save
      var shouldRemove = !this.model.get('remove');
      if (shouldRemove) {
        // if you ask to remove an existing user, roll back any local changes
        // (otherwise it gets really confusing what you're actually removing)
        this.model.applySnapshot();
      }
      this.model.set({remove: shouldRemove});
    }
  },

  /**
   * Handles the user toggling "change password" option
   *
   * @param {Object} model
   * @param {Boolean} change
   */
  onPasswordModeChange: function() {
    var newMode = !this.model.get('setPassword');
    this.model.set('setPassword', newMode);

    if (newMode === true) {
      this.ui.passwordInput.removeClass('hidden');
      this.ui.passwordMode.addClass('change');
      this.ui.passwordMode.text(i18n.t('actionVpn.users.disableSetPassword'));
      this.ui.newPassword.focus();
    } else {
      this.clearError('newPassword');
      this.ui.passwordInput.addClass('hidden');
      this.ui.passwordMode.removeClass('change');
      this.ui.passwordMode.text(i18n.t('actionVpn.users.enableSetPassword'));
      this.ui.newPassword.val('');
      this.model.unset('newPassword');
    }
  },

  /**
   * Decorates the view to indicate error.
   *
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

  /**
   * Remove an error message from the view (if present)
   *
   * @param {String} key
   *   Identifier for the error message
   */
  clearError: function(key) {
    this.ui[key].bs3ui('clearGroupedFieldError', key, this.ui.messages);
  },

  /**
   * Clear any "special" errors related to user conflicts
   */
  onNoConflicts: function() {
    this.clearError('username');
  },

});
