'use strict';

var _ = require('lodash');
var GroupView = require('manage/edit/config/group/GroupView');
var i18n = require('i18next');
var ConfirmDelete = require('lib/behaviors/confirmDelete/ConfirmDelete');
var ConfirmSave = require('manage/edit/config/group/confirmSave/ConfirmSave');
var RunTest = require('manage/edit/config/group/runTest/RunTest');
var ScrollTo = require('lib/behaviors/ScrollTo');
var RemoveGroup = require('manage/edit/config/group/removeGroup/RemoveGroup');
var SiteToSiteVPNAlert = require('actionGroups/shared/SiteToSiteVPNPendingDisableAlertView');
var TitanHQAlert = require('actionGroups/shared/TitanHQPendingDisableAlertView');
var StaticWANAlert = require('actionGroups/shared/StaticWANPendingRemovalAlertView');

module.exports = GroupView.extend({
  behaviors: [
    {
      behaviorClass: ConfirmDelete,
      message: function() {
        if (this.isRemovable()) {
          return i18n.t('configEdit.deleteGroup', {name: this.model.description()});
        }
        return i18n.t('configEdit.cannotDeleteGroup', {name: this.model.description()});
      },
      subMessage: function() {
        return this.getRemovalSubMessage();
      },
      defaultIsYes: function() {
        return this.getRemovalDefaultIsYes();
      },
      showDangerMessage: function() {
        return this.getRemovalIsDangerous();
      },
      isRemovable: function() {
        return this.isRemovable();
      },
    },
    {
      behaviorClass: ConfirmSave,
    },
    {
      behaviorClass: RunTest,
    },
    {
      behaviorClass: ScrollTo,
    },
    {
      behaviorClass: RemoveGroup,
    },
  ],

  initialize: function(options) {
    GroupView.prototype.initialize.call(this, options);

    this.listenTo(
      this.model.deviceConfig.get('networks'), 'change:addressMap', this.showTransferWarnings.bind(this)
    );

    this.listenTo(
      this.model.deviceConfig.get('webFiltersTitanHq'), 'change:enabled', this.showTransferWarnings.bind(this)
    );

    this.listenTo(
      this.model.deviceConfig.get('remoteSites'), 'change add remove', this.showTransferWarnings.bind(this)
    );
  },

  /**
   * If the DNA is performing a task where we should not allow saving config
   * changes, then disable the Save and Remove buttons.
   *
   * @param {config/Group} model
   * @param {Boolean} isSaveable
   * @param {Object|undefined} options
   *   Expects {dnaUpdating: true}, {dnaConnected: false} or undefined.
   */
  onIsSaveableChange: function(model, isSaveable, options) {
    GroupView.prototype.onIsSaveableChange.call(this, model, !this.model.get('isSaving'), options);
    this.model.set('isSaveable', !this.model.get('isSaving'), {silent: true});
  },

  showTransferWarnings: function() {
    var warningsView = this.getChildView('configWarnings');

    warningsView.triggerMethod('remove:alert', TitanHQAlert.alertId);
    if (this.model.deviceConfig.get('webFiltersTitanHq').get('enabled')) {
      warningsView.triggerMethod('add:custom:alert', new TitanHQAlert());
    }

    warningsView.triggerMethod('remove:alert', StaticWANAlert.alertId);
    if (this.staticWANEnabled()) {
      warningsView.triggerMethod('add:custom:alert', new StaticWANAlert());
    }

    warningsView.triggerMethod('remove:alert', SiteToSiteVPNAlert.alertId);
    if (this.siteToSiteVPNEnabled()) {
      warningsView.triggerMethod('add:custom:alert', new SiteToSiteVPNAlert());
    }
  },

  onRender: function() {
    GroupView.prototype.onRender.call(this);
    this.showTransferWarnings();
  },

  /**
   * Triggers event when configuration group is to be focused.
   *
   * @fires manage/edit/config/group/GroupView~config:group:selected
   * @param {Event} ev
   */
  onClickToFocus: function(ev) {
    if (!this.model.emptyCard()) {
      GroupView.prototype.onClickToFocus.call(this);
    }
  },

  staticWANEnabled: function() {
    var staticWANFound = false;
    _.forEach(this.model.deviceConfig.get('networks').models, function(networkInterface) {
      var addressMap = networkInterface.get('addressMap');
      if (!_.isUndefined(addressMap)) {
        _.forEach(addressMap, function(address) {
          if (networkInterface.get('role') === 'external' && address.includes('staticAddress')) {
            staticWANFound = true;
          }
        });
      }
    });

    return staticWANFound;
  },

  siteToSiteVPNEnabled: function() {
    var siteToSiteVPNEnabled = false;
    _.forEach(this.model.deviceConfig.get('remoteSites').models, function(remoteSite) {
      _.forEach(remoteSite.get('tunnels'), function(tunnel) {
        siteToSiteVPNEnabled = tunnel.enabled;
      });
    });

    return siteToSiteVPNEnabled;
  },
});
