'use strict';

var $ = require('jquery');
var Backbone = require('backbone');
var rpcMixin = require('lib/mixins/rpc2');

/**
 * Model/loader for event log data
 */
module.exports = Backbone.Model.extend(rpcMixin).extend({
  /**
   * @member {Object} #attributes
   * @property {String} deviceMac
   * @property {String} eventType
   * @property {Array} data
   *  The loaded event data, as objects having "timestamp" and "message" properties
   * @property {Boolean} loading
   * @property {Boolean} error
   *  Whether an error occurred in the last fetch
   */

  rpc: {
    read: [
      {
        method: 'DNA.Portal.Events.getEvents',
        params: function() {
          return {
            mac: this.get('deviceMac'),
            type: this.get('eventType'),
          };
        },
      },
    ],
  },

  defaults: function() {
    return {
      loading: false,
      error: false,
      data: [],
    };
  },

  fetch: function(options) {
    var self = this;

    if (!self.get('deviceMac') || !self.get('eventType')) {
      throw new Error('EventLogModel: deviceMac and eventType must be set before fetching');
    }

    self.set({error: false, loading: true});

    return Backbone.Model.prototype.fetch.call(this, options)
      .then(function(result) {
        self.set({error: false, loading: false});
        return result;
      }, function(error) {
        self.set({error: true, loading: false});
        return ($.Deferred()).reject(error);
      });
  },

  /**
   * Take the returned list of events and transform it so that
   * the data ends up in the right place on this model
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   *   The modified response
   */
  parse: function(resp, options) {
    return {
      data: resp.result,
    };
  },

});
