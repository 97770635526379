'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var ActionItem = require('actions/shared/ActionItem');

var PPPOE_CAPABILITY = 'supportsPppoe';

/**
 * View-model representing protocol options for WANs.
 */
module.exports = ActionItem.extend({
  /**
   * @name actions/wanProtocol/EditWanProtocol#attributes
   * @type {Object}
   * @property {String} id
   *   The interface name (e.g. vlan40)
   * @property {String} method
   *   Primary configuration method (dhcp|static|pppoe)
   */

  defaults: {
    method: 'dhcp',
  },

  _snapshotAttributes: ['method'],

  initialize: function(attrs, options) {
    this.addDeviceStatus(options);

    if (!this.deviceStatus.hasCapability(PPPOE_CAPABILITY)) {
      this.deviceStatus.onCapabilityAdded(this, PPPOE_CAPABILITY, function() {
        this.trigger('availableMethodsChanged');
      });
    }
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    var vlan = this.deviceConfig.get('networks').get(this.id);
    return _.isUndefined(vlan) || vlan.isNew();
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   *   The modified response.
   */
  parse: function(resp, options) {
    if (options && options.fromConfig && !this.isNew()) {
      var subnet = this.deviceConfig.getSubnet(this.id);
      return {
        method: subnet.get('method'),
      };
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks').get(this.id);
      },
      events: 'change:addressMap',
    },
    {
      getDispatcher: function(config) {
        return config.getSubnet(this.id);
      },
      events: 'change',
    },
  ],

  /**
   * @return {Object}
   */
  getTask: function() {
    var data = {};

    if (!this.isNew()) {
      data.id = this.id;
    }

    data.address = {
      method: this.get('method'),
    };

    return {
      name: 'vlan.create',
      data: data,
    };
  },

  /**
   * @return {Array}
   */
  getAvailableMethods: function() {
    var methods = [
      {value: 'dhcp', label: i18n.t('actionWanProtocol.methods.dhcp')},
      {value: 'static', label: i18n.t('actionWanProtocol.methods.static')},
    ];

    if (this.deviceStatus.hasCapability(PPPOE_CAPABILITY)) {
      methods.push({value: 'pppoe', label: i18n.t('actionWanProtocol.methods.pppoe')});
    }

    return methods;
  },
});
