'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var twig = require('twig').twig;
var tpl = require('actions/macFilter/static.html');

/**
 * Renders a text-only version of the MAC Filter action.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/macFilter/StaticView#model
   * @type {actions/macFilter/EditMacFilter}
   */

  template: twig({data: tpl}),

  serializeData: function() {
    return {
      maclist: this.model.get('maclist').toJSON().slice(0, 5),
    };
  },

  onAttach: function() {
    // TODO: Switch model over to use MultiActionItem, then custom logic will not be needed
    if (this.model) {
      this.listenTo(this.model.get('maclist'), 'add remove change', this.render);
    }
  },
});
