'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var i18n = require('i18next');

/**
 * {@link lib/models/DeviceConfiguration} mixin for filtering/querying the list of networks.
 *
 * Using a mixin to keep DeviceConfiguration from getting too large.
 *
 * @mixin lib/mixins/filterNetworks
 */
module.exports = {
  /**
   * Gets the list of VLANs.
   *
   * @param {Object} options
   * @property {Boolean} options.internal
   *   If false, only "external" VLANs will be returned.
   * @property {Boolean} options.external
   *   If false, only "internal" VLANs will be returned.
   * @return {Backbone.Collection}
   */
  getVlans: function(options) {
    var vlans;
    var filtered = new Backbone.Collection();
    var attrsToMatch = {type: 'vlan'};

    options = options || {};

    vlans = this.get('networks');

    if (options.external === false) {
      attrsToMatch.role = 'internal';
    } else if (options.internal === false) {
      attrsToMatch.role = 'external';
    }

    vlans.each(function(vlan) {
      if (vlan.matches(attrsToMatch)) {
        filtered.add(vlan);
      }
    });

    return filtered;
  },

  /**
   * @param {String} bond name e.g. bond1
   * @return {Backbone.Model} vlan(s) that the bond is associated to.
   */
  getVlansAssociatedToBond: function(bond) {
    var associatedVlans = [];

    this.getVlans().each(function(vlan) {
      _.each(vlan.get('portsMap'), function(port) {
        var portParts = port.split('.');
        if (portParts[0] === bond) {
          associatedVlans.push(vlan);
        }
      });
    });

    return associatedVlans;
  },

  /**
   * Gets the LTE network
   *
   * @return {Object}
   */
  getLte: function() {
    var lte = this.get('networks').filter({type: 'lte'});

    return _.first(lte);
  },

  /**
   * Gets the client VPN network, if one has been created
   *
   * @return {Object|undefined}
   */
  getVpn: function() {
    var vpn = this.get('networks').filter({type: 'vpn'});

    return (vpn && vpn.length > 0) ? vpn[0] : undefined;
  },

  /**
   * @return {Backbone.Collection}
   */
  getInternalVlans: function() {
    return this.getVlans({external: false});
  },

  /**
   * @return {Backbone.Collection}
   */
  getExternalVlans: function() {
    return this.getVlans({internal: false});
  },

  /**
   * Returns WAN networks sorted by failover priority
   * (primary WAN first, then secondary)
   *
   * @return {Backbone.Collection}
   */
  getWansByPriority: function() {
    var sorted = this.getExternalVlans().sortBy('wanPriority');
    return new Backbone.Collection(sorted);
  },

  /**
   * @return {Object}
   */
  getPrimaryWan: function() {
    var wans = this.getWansByPriority();
    return wans.at(0);
  },

  /**
   * @return {Object|undefined}
   */
  getSecondaryWan: function() {
    var wans = this.getWansByPriority();
    if (wans.length > 1) {
      return wans.at(1);
    }
    return undefined;
  },

  /**
   * @return {Backbone.Collection}
   */
  getWifis: function() {
    return new Backbone.Collection(this.get('networks').where({type: 'wifi'}));
  },

  /**
   * Return all wifi networks that are using the provided LAN
   * as their parent LAN
   *
   * @param {String} vlanId
   * @return {Backbone.Collection}
   */
  getWifisForVlan: function(vlanId) {
    return this.getWifis().filter({parentVlan: vlanId});
  },

  getStaticRoutesForVlan: function(vlanId) {
    return this.get('staticRoutes').filter({interface: vlanId});
  },

  getConflictingVlans: function() {
    return this.get('networks').filter({type: 'vlan', ipconflict: true});
  },

  /**
   * Return all WAN (including LTE) ports with their description
   * Descriptions are user input and should be used for display only
   * This is why the LTE description is i18n
   *
   * @return {Array}
   */
  getWanPorts: function() {
    var wanList = this.getWansByPriority().map(function(wan) {
      return {
        id: wan.get('portsMap')[0],
        name: wan.get('description'),
      };
    });

    wanList.push({
      id: this.getLte().get('portsMap')[0],
      name: i18n.t('filterNetworks.lte'),
    });
    return wanList;
  },

  /**
   * Return all LAN addresses with their description
   * Descriptions are user input and should be used for display only
   *
   * @return {Array}
   */
  getLanAddresses: function() {
    return this.getInternalVlans().reduce(function(result, lan) {
      var subnet = this.getSubnet(lan.get('id'));
      if (subnet !== undefined) {
        result.push({
          id: subnet.get('address'),
          name: lan.get('description'),
        });
      }
      return result;
    }, [], this);
  },
};
