'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var addressTpl = require('manage/details/vlan/configs/address.html');
var i18n = require('i18next');
var networkHelpers = require('lib/tplHelpers/networkHelpers');
var Ip = require('lib/Ip');
var networkUtils = require('lib/network');

/**
 * Renders the address info section for the selected network
 */
module.exports = Marionette.View.extend({
  /**
   * Reference to a {@link lib/models/DeviceConfiguration} "networks" object.
   * @member {Backbone.Model} manage/details/vlan/configs/AddressView#model
   */

  /**
   * A reference to deviceConfig.
   * @member {lib/models/DeviceConfiguration} manage/details/vlan/configs/AddressView#deviceConfig
   */

  /**
   * A reference to deviceStatus.
   * @member {lib/models/DeviceStatus} manage/details/vlan/configs/AddressView#deviceStatus
   */

  template: twig({data: addressTpl}),

  /**
   * @param {Object} options
   * @property {lib/models/DeviceStatus} options.deviceStatus
   * @property {lib/models/DeviceConfiguration} options.deviceConfig
   */
  initialize: function(options) {
    this.deviceConfig = options.deviceConfig;
    this.deviceStatus = options.deviceStatus;
  },

  /**
   * @return {Object}
   */
  serializeData: function() {
    return this.getAddressDetails();
  },

  /**
   * Gathers the address details for a selected network
   *
   * @returns {Object}
   */
  getAddressDetails: function() {
    var self = this;
    var role = this.model.get('role');
    var confSubnets = this.deviceConfig.get('subnets');
    var statusSubnets = this.deviceStatus.get('subnets');
    var data = {};
    var addressArray = [];

    if (_.isUndefined(statusSubnets) ||
        _.isUndefined(confSubnets)) {
      return {};
    }

    _.each(this.model.get('addressMap'), function(addressId, key) {
      var statusSubnet = statusSubnets.get(addressId);
      var confSubnet = confSubnets.get(addressId);
      var subnetDetails = {};

      if (!_.isUndefined(statusSubnet)) {
        if (role === 'internal') {
          // prefer the device config; add extra subnet info from status
          subnetDetails =
            _.extend(confSubnet.toJSON(), statusSubnet.pick('defaultGateway', 'networkAddress', 'networkMask'));
        } else {
          // prefer the status; add extra subnet info from device config
          subnetDetails =
            _.extend(statusSubnet.toJSON(), confSubnet.pick('method', 'type'));
        }
      } else {
        // vlan is down, getStatus will not have address details
        subnetDetails = confSubnet.toJSON();
      }

      if (role === 'internal') {
        addressArray.push(
            self.getLanDetails(subnetDetails)
        );
      } else if (subnetDetails.method === 'static') {
        addressArray.push(
            self.getStaticWanDetails(subnetDetails)
        );
      } else {
        addressArray.push(
            self.getDhcpWanDetails(subnetDetails)
        );
      }
    });
    data.addresses = addressArray;
    data.role = role;
    return data;
  },

  /**
   * Gets and formats a LAN's address details.
   *
   * @param {Object} subnetDetails
   * @return {Object}
   */
  getLanDetails: function(subnetDetails) {
    var ip = subnetDetails.address + '/' + subnetDetails.size;
    var ipObj = new Ip(ip, {type: subnetDetails.type});

    var data = {
      ip: subnetDetails.address,
    };

    // netmask
    data.netmask = networkHelpers.formatNetmask(subnetDetails.type, ipObj.cidr());

    // subnet range
    data.range = i18n.t('actionStaticAddress.range', {
      start: ipObj.network(),
      end: ipObj.broadcast(),
    });

    return data;
  },

  /**
   * Gets and formats a static WAN's address details.
   *
   * @param {Object} subnetDetails
   * @return {Object}
   */
  getStaticWanDetails: function(subnetDetails) {
    var data = {
      ip: subnetDetails.address,
      mask: Ip.cidrToIp(subnetDetails.size),
      gateway: subnetDetails.defaultGateway,
      dns: this.deviceConfig.get('dns').get(this.model.id),
    };

    if (!_.isEmpty(data.dns)) {
      data.dns = data.dns.toJSON();
    }

    _.extend(data, this.getIpv6WanDetails());

    return data;
  },

  /**
   * Gets and formats a DHCP WAN's address details.
   *
   * @param {Object} subnetDetails
   * @return {Object}
   */
  getDhcpWanDetails: function(subnetDetails) {
    var data = {
      ip: subnetDetails.address,
      mask: subnetDetails.networkMask,
      gateway: subnetDetails.defaultGateway,
      dns: this.deviceStatus.get('dns').get(this.model.id),
    };

    if (!_.isEmpty(data.dns)) {
      data.dns = data.dns.toJSON();
    }

    data.methodText = networkHelpers.getMethodText(subnetDetails.method);

    _.extend(data, this.getIpv6WanDetails());

    return data;
  },

  /**
   * Checks if the interface has an ipv6 counterpart
   *
   * @return {{ipv6: {}}}
   */
  getIpv6WanDetails: function() {
    var ipv6Data = {};
    var ipv6InterfaceId = this.model.id + networkUtils.ipv6PostFix;
    var ipv6SubnetStatus = this.deviceStatus.getSubnet(ipv6InterfaceId);
    var ipv6SubnetConfig = this.deviceConfig.getSubnet(ipv6InterfaceId);
    if (!_.isUndefined(ipv6SubnetStatus) && !_.isUndefined(ipv6SubnetConfig)) {
      ipv6Data = {
        ip: ipv6SubnetStatus.get('address'),
        gateway: ipv6SubnetStatus.get('defaultGateway'),
        prefix: ipv6SubnetStatus.get('size'),
      };

      if (ipv6SubnetConfig.get('method') === 'dhcp') {
        ipv6Data.methodText = networkHelpers.getMethodText(ipv6SubnetConfig.get('method'));
      }
    }

    return {ipv6: ipv6Data};
  },

});
