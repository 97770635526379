'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var i18n = require('i18next');
var buttonSpinner = require('lib/buttonSpinner');
var Modal = require('components/modal/ModalView');
var ModalChildView = require('components/modal/GenericChildView');
var adjacentError = require('lib/mixins/adjacentStatusMessage');
var TrackJob = require('lib/behaviors/TrackJob');
var Jobs = require('lib/collections/Jobs');

/**
 * Renders the "Reboot" button
 */
module.exports = Marionette.View.extend(adjacentError).extend({
  /**
   * @member {Backbone.Model} model
   * @property {String} attributes.deviceMac
   * @property {lib/models/DeviceStatus} attributes.deviceStatus
   * @property {Backbone.Model} attributes.statusCard
   *   See Portal.Fleet.getOverview API call
   */

  template: false,
  tagName: 'button',
  className: 'btn btn-default btn-xs',

  events: {
    'click': 'showModal',
  },

  behaviors: [
    {
      behaviorClass: TrackJob,
      jobType: Jobs.TRIGGER_REBOOT,
    },
  ],

  onAttach: function() {
    var debounced = _.debounce(this.toggleSpinner, 1);

    // react to changes in connectivity that affect the ability
    // to initiate a reboot if one isn't already running
    this.listenTo(this.model.get('statusCard'), {
      'change:connected': debounced,
    });
  },

  onBeforeRender: function() {
    this.el.setAttribute('data-tag', 'do-reboot');
  },

  onRender: function() {
    this.$el.text(i18n.t('dnaDetails.reboot'));
    this.toggleSpinner();
  },

  showModal: function() {
    var modal = this.buildModal();
    modal.render();
  },

  buildModal: function() {
    var model = Backbone.Model.new({
      callback: this.triggerReboot.bind(this),
      title: i18n.t('rebootDna.modalTitle'),
      childView: new ModalChildView({
        content: '<p>' + i18n.t('rebootDna.modalBody') + '</p>',
      }),
      actionButtonText: i18n.t('rebootDna.rebootButton'),
      closeText: i18n.t('configEdit.cancel'),
    });
    return new Modal({model: model});
  },

  triggerReboot: function() {
    this.hideError();
    this.model.get('jobs').startJob(Jobs.TRIGGER_REBOOT);
  },

  /**
   * Shows or hides the button spinner and sets appropriate state
   */
  toggleSpinner: function() {
    var pendingJob = this.model.get('jobs').getJobOfType(Jobs.TRIGGER_REBOOT, true);

    if (pendingJob) {
      buttonSpinner.showButtonSpinner(this.$el);
      // no toggleButtonDisabled() here -- spinner should own the button state
    } else {
      buttonSpinner.hideButtonSpinner(this.$el);
      this.toggleButtonDisabled();
    }
  },

  /**
   * Sets whether the button is disabled or not
   */
  toggleButtonDisabled: function() {
    var statusCard = this.model.get('statusCard');
    this.$el.prop('disabled', statusCard.get('connected') === false);
  },

  onFailure: function() {
    this.showError(i18n.t('dnaDetails.rebootFailed'));
    this.toggleSpinner();
  },

});
