'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Radio = require('backbone.radio');
var BaseDevicesListView = require('components/connectedDevicesList/ConnectedDevicesListView');
var LastSeenModel = require('components/connectedDevicesList/LastSeenModel');
var i18n = require('i18next');
var layoutChannel = Radio.channel('layoutChannel');
var detailTypes = require('manage/details/detailTypes');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');

/**
 * Displays the list of connected-devices associated with with a DNA (and
 * optionally filtered by a specific LAN).
 */
module.exports = BaseDevicesListView.extend({
  /**
   * @param {Object} options
   * @property {String} options.deviceMac
   *   The DNA mac to look up the list of networks.
   * @property {lib/models/DeviceStatus} options.deviceStatus
   * @property {lib/models/DeviceStatus} options.deviceConfig
   * @property {String} vlanId
   *   The VLAN id to filter the list of connected devices by.
   */
  initialize: function(options) {
    this.deviceMac = options.deviceMac;
    this.deviceStatus = options.deviceStatus;
    this.deviceConfig = options.deviceConfig;
    var lastseen = new LastSeenModel({
      deviceMac: this.deviceMac,
    });
    this.last = lastseen;
    this.last.fetch();
    if (options.vlanId) {
      this.vlanId = options.vlanId;
      this.initCollectionByNetwork();
    }
    this.listenTo(this.last, 'change:loading', this.onLastSeenChange);
    this.listenTo(this.deviceStatus.get('connectedDevices'), 'add', this.onNetworkAdd);
    this.listenTo(this.deviceStatus.get('connectedDevices'), 'remove', this.onNetworkRemove);
    this.listenTo(this.deviceStatus.get('connectedDevices'), 'change:devices', this.onNetworkDeviceListChange);
    this.listenTo(this.deviceStatus.get('connectedDevices'), 'add remove change:devices', this.onDeviceChange);
    this.listenTo(this.last, 'change:loading', this.onLastSeenChange);
    if (this._isWan()) {
      this.listenTo(this.deviceStatus.get('failover'), 'change:activeWan', this.onActiveWanChange);
    }
  },

  onDeviceChange: function() {
    this.last.fetch();
  },

  onLastSeenChange: function() {
    if (_.isUndefined(this.last.get('items').models[0])) {
      return;
    }
    var lastSeenInfo = this.last.get('items').models[0].attributes[this.vlanId];
    for (var devMac in lastSeenInfo) {
      if (!lastSeenInfo.hasOwnProperty(devMac)) {
        continue;
      }
      var found = false;
      for (var i = 0; i < this.collection.models.length; i++) {
        if (this.collection.models[i].attributes.mac === devMac) {
          found = true;
          if (this.collection.models[i].attributes.hostname) {
            this.collection.models[i].set('lastSeen', i18n.t('lastSeen.fewSeconds'));
            break;
          }
        }
      }
      if (!found) {
        this.collection.add({
          'lastSeen': this.stringTime(lastSeenInfo[devMac]),
          'mac': devMac,
          'disconnected': true,
        });
      }
    }
    this.render();
  },
  stringTime: function(diff) {
    var min = 60;
    var threeminutes = 180;
    var hour = 3600;
    var threehours = 10800;
    var day = 86400;

    if (diff < min) {
      return i18n.t('lastSeen.lessThanMinute', {seconds: diff});
    } else if (diff < threeminutes) {
      return i18n.t('lastSeen.lessThanThreeMins', {mins: Math.floor(diff / min), seconds: diff % min});
    } else if (diff < hour) {
      return i18n.t('lastSeen.lessThanHour', {mins: Math.floor(diff / min)});
    } else if (diff < threehours) {
      return i18n.t('lastSeen.lessThanThreeHrs', {hours: Math.floor(diff / hour), mins: Math.floor(diff / min) % min});
    } else if (diff < day) {
      return i18n.t('lastSeen.lessThanDay', {hours: Math.floor(diff / hour)});
    }
    return i18n.t('lastSeen.moreThanDay', {days: Math.floor(diff / day)});
  },

  onNetworkAdd: function(network) {
    if (network.id === this.vlanId || this._isActiveWan()) {
      this.collection.add(this.getDevicesForNetwork(network.id));
    }
  },

  onNetworkRemove: function(network) {
    if (network.id === this.vlanId || this._isActiveWan()) {
      var cachedDevicesForRemoval = this._getCachedDevicesForNetwork(network.id);
      this.collection.remove(cachedDevicesForRemoval);
    }
  },

  onNetworkDeviceListChange: function(network) {
    if (network.id === this.vlanId || this._isActiveWan()) {
      var devices = this.getDevicesForNetwork(network.id);

      if (this._isActiveWan()) {
        var unaffectedDevices = this.collection.filter(function(networkObj) {
          return networkObj.get('parentNetworkId') !== network.id;
        }).map(function(device) {
          return device.attributes;
        });

        var newDeviceList = unaffectedDevices.concat(devices);
        this.collection.set(newDeviceList);
      } else if (this._isInternalNetwork()) {
        this.collection.set(devices);
      }
    }
  },

  onActiveWanChange: function(wan) {
    var currentWanBeingViewed = this.vlanId;
    var activeWan = wan.get('activeWan').id;

    if (currentWanBeingViewed !== activeWan) {
      this.collection.reset();
    } else if (currentWanBeingViewed === activeWan) {
      var devices = this.getDevicesForAllInternalNetworks();
      this.collection.set(devices);
    }
  },

  getDevicesForAllInternalNetworks: function() {
    var self = this;
    var networks = _.union(this.deviceConfig.getInternalVlans().models, this.deviceConfig.getWifis().models);
    var devices = [];

    _.each(networks, function(network) {
      devices.push.apply(devices, self.getDevicesForNetwork(network.id));
    });

    return devices;
  },

  getDevicesForNetwork: function(networkId) {
    var vlanDevices = this._getDevices(networkId);
    var self = this;
    return _.map(vlanDevices, function(device) {
      var tmpAttrs = _.clone(device);
      tmpAttrs.mac = tmpAttrs.id;
      tmpAttrs.id = networkId + '-' + tmpAttrs.mac;
      tmpAttrs.parentNetworkId = networkId;
      tmpAttrs.url = deviceConfigChannel.request('get:url',
        detailTypes.CONNECTED_DEVICE,
        self.deviceMac,
        tmpAttrs.mac,
        {parentVlan: networkId}
      );

      if (networkId !== self.vlanId) {
        var type;
        var requiresParentInfo = false;

        if (self._isWifi(networkId)) {
          type = detailTypes.WIFI;
          requiresParentInfo = true;
        } else if (self._isLan(networkId)) {
          type = detailTypes.LAN;
          requiresParentInfo = true;
        } else {
          tmpAttrs.parentNetworkType = detailTypes.UNKNOWN;
        }

        if (requiresParentInfo) {
          tmpAttrs.parentNetworkType = type;
          tmpAttrs.networkDescription = self.deviceConfig.get('networks').get(networkId).getName();
          tmpAttrs.parentNetworkUrl = deviceConfigChannel.request('get:url', type, self.deviceMac, networkId);
        }
      }
      return tmpAttrs;
    });
  },

  /**
   * Defines the View's collection property, which will be the list of connected-
   * devices for the passed LAN.
   */
  initCollectionByNetwork: function() {
    var devices = [];

    if (!this._isActiveWan() && !this._isInternalNetwork()) {
      devices = [];
    } else if (this._isActiveWan()) {
      devices = this.getDevicesForAllInternalNetworks();
    } else if (this._isInternalNetwork()) {
      devices = this.getDevicesForNetwork(this.vlanId);
    }

    this.collection = new Backbone.Collection(devices);
  },

  _isWan: function() {
    if (this.deviceConfig.get('networks').get(this.vlanId)) {
      return this.deviceConfig.get('networks').get(this.vlanId).isWan();
    }

    return false;
  },

  _isWifi: function(networkId) {
    if (this.deviceConfig.get('networks').get(this.vlanId)) {
      return this.deviceConfig.get('networks').get(networkId).isWifi();
    }

    return false;
  },

  _isLan: function(networkId) {
    if (this.deviceConfig.get('networks').get(this.vlanId)) {
      return this.deviceConfig.get('networks').get(networkId).isLan();
    }

    return false;
  },

  _isInternalNetwork: function() {
    if (this.deviceConfig.get('networks').get(this.vlanId)) {
      return this.deviceConfig.get('networks').get(this.vlanId).isInternalNetwork();
    }

    return false;
  },

  _isActiveWan: function() {
    if (this.deviceConfig.get('networks').get(this.vlanId)) {
      return this.deviceConfig.get('networks').get(this.vlanId).isActiveWan(this.deviceStatus);
    }

    return false;
  },

  _getDevices: function(networkId) {
    if (this.deviceStatus.get('connectedDevices').get(networkId)) {
      return this.deviceStatus.get('connectedDevices').get(networkId).get('devices');
    }

    return [];
  },

  _getCachedDevicesForNetwork: function(networkToRemove) {
    return this.collection.where({parentNetworkId: networkToRemove});
  },

  onChildviewConnectedDeviceSelected: function(childView) {
    var url = childView.model.get('url');

    Backbone.history.navigate(url);

    layoutChannel.request('change:page', {
      deviceMac: this.deviceMac,
      page: deviceConfigChannel.request('trim:url', url),
    });
  },

  onChildviewNetworkViewSelected: function(childView) {
    var url = childView.model.get('parentNetworkUrl');
    if (url) {
      Backbone.history.navigate(url);

      layoutChannel.request('change:page', {
        deviceMac: this.deviceMac,
        page: deviceConfigChannel.request('trim:url', url),
      });
    }
  },
});
