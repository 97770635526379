'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Backbone = require('backbone');
var Devices = require('lib/collections/Devices');
var rpc2 = require('lib/mixins/rpc2');

/**
 * Represents a user.
 */
module.exports = Backbone.Model.extend(rpc2).extend({
  /**
   * @member {Object} app/UserModel#attributes
   * @property {String} email
   * @property {String} firstName
   * @property {String} lastName
   * @property {Number} userId
   * @property {String} username
   * @property {lib/collections/Devices} devices
   *   The list of devices the user has access to.
   */

  defaults: {
    firstName: '',
    lastName: '',
    email: '',
    displayName: '',
    ssoUser: '',
    devices: {},
  },

  rpc: {
    read: {
      method: 'DNA.Portal.User.getUser',
      params: {},
    },
  },

  /**
   * @param {Object} attributes
   * @param {Object} options
   */
  initialize: function() {
    this.set('devices', new Devices());
    this.on('change:displayName', this.onUserChange.bind(this));
  },

  /**
   * Re-fetch the devices that belong to this User on change.
   */
  onUserChange: function() {
    this.get('devices').fetch();
  },

  /**
   * Construct the user given the server response.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (_.isUndefined(resp.error) && !_.isUndefined(resp.result)) {
      resp = _.clone(resp.result);
      resp.displayName = resp.email;

      delete resp.username;
      return resp;
    }

    return {};
  },

  /**
   * Contact the DNA middle layer to get user info.
   *
   * @param {Object} options
   * @return {jQuery.Promise}
   */
  fetch: function(options) {
    var deferred;
    var devicesList = this.get('devices');

    deferred = $.when(
      Backbone.Model.prototype.fetch.apply(this, arguments),
      devicesList.fetch()
    ).then(function(userResponse, devicesResponse) {
      // we only care about the response from getUser being passed to parse()
      return userResponse;
    });

    return deferred.promise();
  },
});
