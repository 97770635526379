'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var i18n = require('i18next');
var twig = require('twig').twig;
var tpl = require('manage/details/dna/securityDetails/security-details.html');
var EventLogModel = require('components/eventLog/EventLogModel');
var SystemEventLogModel = require('components/eventLog/SystemEventLogModel');
var EventLogView = require('components/eventLog/EventLogView');
var IDPEventLogView = require('components/eventLog/IDPEventLogView');
var configPage = require('lib/configPage');

/**
 * Renders the security details (e.g. intrusion and web filter log views).
 */
module.exports = Marionette.View.extend({
  /**
   * @member {Backbone.Model} model
   * @property {String} deviceMac
   * @property {lib/models/DeviceConfiguration} deviceConfig
   * @property {config/ConfigModel} configState
   */

  eventTypes: [
    {
      id: 'System',
      model: SystemEventLogModel,
      region: 'rg-system',
      label: i18n.t('groups.systemEvents'),
      isVisible: function(deviceConfig) {
        return true;
      },
      isAvailable: function() {
        return true;
      },
      isEnabled: function() {
        return true;
      },
      getEnabledChangeEvent: function() {
        return null;
      },
    },
    {
      id: 'IDP',
      model: EventLogModel,
      region: 'rg-snort',
      label: i18n.t('groups.intrusionDetectionShort'),
      isVisible: function(deviceConfig) {
        return true;
      },
      isAvailable: function(configState) {
        return !!configState.get(configPage.APPLICATIONS).get('intrusionDetection');
      },
      isEnabled: function(deviceConfig) {
        return deviceConfig.get('intrusionDetection').get('enabled');
      },
      getEnabledChangeEvent: function(deviceConfig) {
        return {site: deviceConfig.get('intrusionDetection'), name: 'change:enabled'};
      },
    },
    {
      id: 'WebFilter',
      model: EventLogModel,
      region: 'rg-web-filter',
      label: i18n.t('groups.webFilters'),
      isVisible: function(deviceConfig) {
        // TODO::RIX -> When we support TitanHQ Logs in the UI we must change this value to simply return true.
        // When the user finally sees titanHQ in their config the original web
        // filters will cease to work. So that is why we aren't checking for titanHQ
        // being enabled explicitly.
        return !deviceConfig.has('webFiltersTitanHq');
      },
      isAvailable: function(configState) {
        return !!configState.get(configPage.APPLICATIONS).get('webFilters') ||
          !!configState.get(configPage.APPLICATIONS).get('webFiltersTitanHq');
      },
      isEnabled: function(deviceConfig) {
        return deviceConfig.get('webFilters').get('enabled') || deviceConfig.get('webFiltersTitanHq').get('enabled');
      },
      getEnabledChangeEvent: function(deviceConfig) {
        return {site: deviceConfig.get('webFilters'), name: 'change:enabled'} ||
          {site: deviceConfig.get('webFiltersTitanHq'), name: 'change:enabled'};
      },
    },
  ],

  selectedEventType: 'System',

  template: twig({data: tpl}),

  ui: {
    tabs: '.dui-tabs__item',
  },

  events: {
    'click @ui.tabs': 'handleTabClick',
  },

  regions: function() {
    return _.chain(this.eventTypes)
      .indexBy('id')
      .mapValues(function(type) {
        return '.' + type.region;
      })
      .value();
  },

  templateContext: function() {
    return {
      eventTypes: this.eventTypes,
      shouldDisplayLog: this.shouldDisplayLog.bind(this),
    };
  },

  onAttach: function() {
    var model = this.model;
    var callback = _.debounce(this.updateConfigMessages.bind(this), 100);
    var listenTo = _.bind(this.listenTo, this, _, _, callback);

    // re-sync config hints if the available cards in the security page change...
    listenTo(model.get('configState').get(configPage.APPLICATIONS), 'add remove');

    // ... or if any event logging feature becomes enabled or disabled
    this.eventTypes.forEach(function(type) {
      var info = type.getEnabledChangeEvent(model.get('deviceConfig'));

      if (!_.isNull(info)) {
        listenTo(info.site, info.name);
      }
    });
  },

  onRender: function() {
    this.addEventViews();
    this.selectEventType(this.selectedEventType);
  },

  handleTabClick: function(event) {
    this.selectEventType(event.currentTarget.dataset.type);
  },

  /**
   * Creates the child event log views
   */
  addEventViews: function() {
    var mac = this.model.get('deviceMac');
    var deviceConfig = this.model.get('deviceConfig');
    // For now, just create multiple views and toggle visibility
    // Might be possible to optimize this better
    this.eventTypes.forEach(function(type) {
      if (type.isVisible(deviceConfig)) {
        var message = this.getConfigMessage(type);
        var Model = type.model; // b/c of eslint, Model instead of model

        Model = new Model({
          'deviceMac': mac,
          'eventType': type.id,
        });

        var eventView = new EventLogView({
          model: Model,
          message: message,
        });

        if (type.id === 'IDP') {
          eventView = new IDPEventLogView({
            model: Model,
            message: message,
          });
        }

        this.showChildView(type.id, eventView);
      }
    }.bind(this));
  },

  /**
   * Updates config messages in each event log view
   * to reflect latest configuration state
   */
  updateConfigMessages: function() {
    var self = this;

    this.eventTypes.forEach(function(type) {
      var eventView = self.getChildView(type.id);
      if (eventView) {
        eventView.setMessage(self.getConfigMessage(type));
      }
    });
  },

  /**
   * Makes a given event type active in the view
   *
   * @param {String} type
   *  id of the type to select
   */
  selectEventType: function(type) {
    var info = _.find(this.eventTypes, 'id', type);
    var tabs = this.ui.tabs;

    tabs.removeClass('dui-tabs__item--active');
    tabs.filter('[data-type="' + type + '"]').addClass('dui-tabs__item--active');

    // show/hide the correct region
    _.forEach(this.getRegions(), function(region) {
      region.$el.addClass('hidden');
    });
    this.getRegion(info.id).$el.removeClass('hidden');

    this.selectedEventType = type;
  },

  /**
   * Retrieves a localized message to display (if any) about
   * the configuration status for the given event type
   *
   * @param {Object} type
   *   Event type definition from #eventTypes list
   * @return {String}
   */
  getConfigMessage: function(type) {
    if (!type.isAvailable(this.model.get('configState'))) {
      return i18n.t('securityDetails.featureUnavailable', {name: type.label});
    }
    if (!type.isEnabled(this.model.get('deviceConfig'))) {
      return i18n.t('securityDetails.featureDisabled', {name: type.label});
    }
    return '';
  },

  shouldDisplayLog: function(eventId) {
    return _.find(this.eventTypes, 'id', eventId).isVisible(this.model.get('deviceConfig'));
  },
});
