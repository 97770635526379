'use strict';

var BaseGroup = require('config/Group');
var i18n = require('i18next');
var RenderPositions = require('config/RenderPositions');
var timeouts = require('lib/saveTimeouts.js');

/**
 * "Operator Ping" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'operatorPing',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  getConfigCardTimeout: function() {
    return timeouts.OPERATOR_TOOLS_MAX_TIME;
  },

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  isOperatorTool: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.operatorPing');
  },
});
