'use strict';

var i18n = require('i18next');
var FormView = require('actions/wanWebFilterDns/FormView');
var StaticView = require('actions/wanWebFilterDns/StaticView');
var EditWanWebFilterDns = require('actions/wanWebFilterDns/EditWanWebFilterDns');

/**
 * Action configuration for Web Filters DNS component to the router WAN cards.
 */
module.exports = {
  title: i18n.t('actionWanDns.title'),
  Model: EditWanWebFilterDns,
  FormView: FormView,
  TextView: StaticView,
};
