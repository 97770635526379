'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('manage/details/ports/ports-details-layout-view.html');
var AggregatedPortsListView = require('manage/details/ports/aggregated/AggregatedPortsDetailsListView.js');
var NativePortsListView = require('manage/details/ports/native/NativePortsDetailsListView');
var PortsView = require('manage/details/ports/PortsView');

module.exports = Marionette.View.extend({
  template: twig({allowInlineIncludes: true, data: tpl}),

  regions: {
    aggregatedPorts: '.rg-aggregated-ports',
    nativePorts: '.rg-native-ports',
    portsOverview: '.rg-ports-overview',
  },

  className: 'row',

  onRender: function() {
    this._addAggregatedPortsChildView();
    this._addNativePortsChildView();
    this._addPortsView();
  },

  _addPortsView: function() {
    var attrs = ['deviceMac', 'deviceStatus', 'deviceConfig'];
    var viewOptions = this.model.pick(attrs);
    viewOptions.isVlanView = false;
    var view = new PortsView(viewOptions);

    this.showChildView('portsOverview', view);
  },

  _addAggregatedPortsChildView: function() {
    var attrs = ['deviceStatus', 'deviceConfig'];
    var viewOptions = this.model.pick(attrs);
    var view = new AggregatedPortsListView(viewOptions);

    this.showChildView('aggregatedPorts', view);
  },

  _addNativePortsChildView: function() {
    var attrs = ['deviceStatus', 'deviceConfig'];
    var viewOptions = this.model.pick(attrs);
    var view = new NativePortsListView(viewOptions);

    this.showChildView('nativePorts', view);
  },
});
