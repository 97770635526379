'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/shared/portForwarding/staticRule.html');
var i18n = require('i18next');
var twig = require('twig').twig;

/**
 * Blank out destination port value when the rule is for a range of ports
 *
 * @param {Boolean} isRange
 * @param {String} value
 * @returns {String}
 */
var formatDestPort = function(isRange, value) {
  if (isRange) {
    return '';
  }
  return value;
};

/**
 * Renders a text-only version of an individual port forwarding rule.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/shared/portForwarding/StaticRuleView#model
   * @type {actions/shared/portForwarding/EditPortForwardingRule}
   */

  template: twig({data: tpl}),

  ui: {
    pfOnly: '[name="pfOnly"]',
  },

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      protocolText: this._protocolText,
      destPortText: this._destPortText,
    });
  },

  onRender: function() {
    if (this.model.get('role') === 'PORT_FORWARD') {
      this.ui.pfOnly.removeClass('hidden');
    }
  },

  /**
   * Format model's protocol value for display
   *
   * @returns {String}
   * @private
   */
  _protocolText: function() {
    var valueMap = {
      tcp: i18n.t('portForwarding.protocolType.tcp'),
      udp: i18n.t('portForwarding.protocolType.udp'),
      tcpudp: i18n.t('portForwarding.protocolType.both'),
    };

    return valueMap[this.proto];
  },

  /**
   * Format model's destination port value for display
   *
   * @returns {String}
   * @private
   */
  _destPortText: function() {
    if (this.srcPortRange) {
      return formatDestPort(this.srcPortRange.indexOf('-') > 0, this.blankVal(this.destPort));
    }
  },

});
