'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/webFiltersTitanHq/category/static.html');
var viewFilters = require('lib/viewFilters');
var twig = require('twig').twig;
var i18n = require('i18next');

/**
 * Renders a text-only version of the category component for web filters titan hq.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name #model
   * @type {actions/webFilters/EditWebFilters}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      formatCategories: this._formatCategories,
      getEnabledCategories: this._getEnabledCategories,
      getItemCountHelp: viewFilters.getItemCountHelpFn({
        // i18n.t('actionWebFiltersTitanHq.noCategories')
        emptyMsgKey: 'actionWebFiltersTitanHq.noCategories',
        // The disabled message is not applicable to web filters but is required by getItemCountHelpFn
        allDisabledMsgKey: '',
        // i18n.t('actionWebFiltersTitanHq.viewAll', {count: count})
        moreMsgKey: 'actionWebFiltersTitanHq.viewAll',
      }, true),

    });
  },

  /**
   * Formats list of first five enabled filter categories into a text string
   *
   * @param {Array} categories
   *  Categories list from #model
   * @return {String}
   * @private
   */
  _formatCategories: function(categories) {
    var formatted =
      categories.map(function(category) {
        return i18n.t('actionWebFiltersTitanHq.category.' + category.id);
      })
      .sort().slice(0, 5).join(i18n.t('general.listSeparator'));

    return formatted || '';
  },

  /**
   * Returns only the categories that are being blocked
   *
   * @param {Array} categories
   *  Categories list from #model
   * @return {Array}
   * @private
   */
  _getEnabledCategories: function(categories) {
    return categories.filter(function(category) {
      return category.enabled === true;
    });
  },
});
