'use strict';

var ActionItem = require('actions/shared/ActionItem');
var networkUtils = require('lib/network');
var i18n = require('i18next');
var _ = require('lodash');

/**
 * Edit model for DMZ.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/dmz/EditDmz#attributes
   * @property {string} ip
   * @property {boolean} enabled
   *  enabled or disabled
   * @property {string} srcIp
   */

  _snapshotAttributes: ['ip', 'enabled', 'srcIp'],

  initialize: function(attributes, options) {
    this.addDeviceStatus(options);
    this.set({supportsNetObj: this.deviceStatus.hasCapability('supportsNetworkObject')}, {fromConfig: true});
    this.deviceStatus.onCapabilityAdded(this, 'supportsNetworkObject', function() {
      this.set({supportsNetObj: true}, {fromConfig: true});
    }.bind(this));
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  getOnOffState: function() {
    return !!this.get('enabled');
  },

  /**
   * Parse response for IP.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var dmz = this.deviceConfig.get('firewall').get('dmz');
      var netObjs = _.map(this.deviceConfig.get('firewall').get('networkObjects').toJSON(), function(obj) {
        return _.pick(obj, 'id', 'description');
      });

      return {
        enabled: dmz.get('enabled'),
        ip: dmz.get('ip'),
        srcIp: dmz.get('src_ip'),
        netObjs: netObjs,
      };
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('firewall').get('dmz');
      },
      events: 'change',
    },
    {
      getDispatcher: function(config) {
        return config.get('firewall').get('networkObjects');
      },
      events: 'add remove change',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};
    if (_.has(attrs, 'ip')) {
      if (this.get('enabled') && !networkUtils.validInternalIPv4(attrs.ip)) {
        errors.ip = i18n.t('actionDmz.invalidIp');
      }
    }
    if (_.has(attrs, 'srcIp')) {
      if (!(_.isEmpty(attrs.srcIp) || networkUtils.validIP(attrs.srcIp) ||
        networkUtils.validSubnet(attrs.srcIp) ||
        _.contains(_.pluck(this.get('netObjs'), 'description'), attrs.srcIp))) {
        errors.srcIp = i18n.t('actionDmz.invalidIpOrNetobj');
      }
    }
    if (!_.isEmpty(errors)) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    var srcNetObj = _.findWhere(this.get('netObjs'), {description: this.get('srcIp')});

    return {
      name: 'firewall.dmz',
      data: {
        enabled: this.get('enabled'),
        srcIp: this.get('srcIp'),
        ip: this.get('ip'),
        srcNetObjId: srcNetObj ? srcNetObj.id : null,
      },
    };
  },
});
