'use strict';

var BaseEditDomainFilters = require('actions/domainFilters/EditDomainFilters');
var _ = require('lodash');

/**
 * Edit model for Domain Filters.
 */
module.exports = BaseEditDomainFilters.extend({
  /**
   * Overridden to always return false
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  toggledOnOffBy: 'webFiltersTitanHqEnable',

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var attrs;
      var webFilters = this.deviceConfig.get('webFiltersTitanHq');

      if (webFilters) {
        attrs = {
          items: this._getDomains(webFilters),
        };
      }

      return attrs || {};
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('webFiltersTitanHq');
      },
      events: 'add remove change',
    },
  ],

  getTask: function() {
    return {
      name: 'webFiltersTitanHq.buildWebFilterLists',
      data: {
        domains: this.getItems(),
        removedDomains: this.getRemovedItems(),
      },
    };
  },

  getItems: function() {
    var filtered = [];
    var items = this.get('items').toJSON();

    items.forEach(function(obj) {
      if (!obj.remove) {
        var includeSubdomains = (obj.subdomains === 'true' || obj.subdomains === '1') ? 1 : 0;
        filtered.push({id: obj.id, domain: obj.domain, type: obj.type, subdomains: includeSubdomains});
      }
    });

    return filtered;
  },

  getRemovedItems: function() {
    var filtered = [];
    var items = this.get('items').toJSON();

    items.forEach(function(obj) {
      if (obj.remove) {
        filtered.push({id: obj.id, domain: obj.domain, type: obj.type, subdomains: obj.subdomains});
      }
    });

    return filtered;
  },

  /**
   * Returns domain attributes
   *
   * @param {Array} webFilters
   * @return {Array} domains
   */
  _getDomains: function(webFilters) {
    var domainFilters = webFilters.get('domainList') || [];

    var parsedItems = domainFilters.map(function(domainElement) {
      return _.extend({}, domainElement);
    });

    return parsedItems;
  },
});
