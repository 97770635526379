'use strict';

var MultiActionItem = require('actions/shared/MultiActionItem');
var EditRule = require('actions/conditionalDns/rule/Rule');

/**
 * Edit model for Conditional DNS Forwarding Rules
 */
module.exports = MultiActionItem.extend({

  collectionModel: EditRule,

  /**
   * Overridden to always return false (this is a fixed group)
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var forwards = this.deviceConfig.get('conditionalDNS');
      return {
        items: forwards.map(function(forward) {
          return {
            id: forward.get('domain') + forward.get('ip'),
            host: forward.get('domain'),
            destination: forward.get('ip'),
            remove: false,
          };
        }),
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('conditionalDNS');
      },
      events: 'add remove change',
    },
  ],

});
