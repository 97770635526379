'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Backbone = require('backbone');
var Radio = require('backbone.radio');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');

/**
 * Represents a model without syncing capabilities.
 *
 * - It does use fetch(), but fetch() is expected to get its data from the
 *   shared {@link lib/models/DeviceConfiguration} object.
 * - Has snapshot capabilities. This makes it easier to track model changes
 *   over many calls to set().
 *
 * @mixes lib/mixins/snapshot
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({
  /**
   * @member {Object} lib/models/ConfigItem#attributes
   * @property {Boolean} pendingDelete
   *   Indicates the model is to be deleted. Note, this attribute is not
   *   actually added to the model because it doesn't make sense for all
   *   actions. It is documented here to indicate a standard attribute name
   *   to use when a ConfigItem has a "to be deleted" state.
   */

  /**
   * The deviceMac associated with this action.
   * @member {String} lib/models/ConfigItem#deviceMac
   */

  /**
   * @throws Error
   *   Thrown if "deviceMac" not passed in.
   * @param {Object} attributes
   * @param {Object} options
   * @property {String} options.deviceMac
   */
  constructor: function(attributes, options) {
    attributes = attributes || {};

    if (_.isUndefined(options.deviceMac)) {
      throw new Error('Object must be associated with a device');
    }

    this.deviceMac = options.deviceMac;

    // passing options.deviceConfig is for unit testing/cloning, which is why
    // it is not documented as a possible property of the "options" parameter.
    this.deviceConfig = options.deviceConfig;

    if (_.isUndefined(this.deviceConfig)) {
      this.deviceConfig = deviceConfigChannel.request('get:config');
    }

    Backbone.Model.call(this, attributes, options);

    if (this._snapshotAttributes !== false) {
      // set snapshot base by capturing default and initial attributes
      this.takeSnapshot();
    }
  },

  /**
   * @return {lib/models/ConfigItem}
   */
  clone: function() {
    var options = {
      deviceMac: this.deviceMac,
      deviceConfig: this.deviceConfig,
    };

    if (!_.isUndefined(this.deviceStatus)) {
      options.deviceStatus = this.deviceStatus;
    }

    return new this.constructor(this.attributes, options);
  },

  /**
   * The parse() method is the primary hook for building an ActionItem's
   * attributes.
   *
   * 1) options.fromConfig = true
   * The model is being fetched and its attributes should be loaded from
   * {@link lib/models/DeviceConfiguration}.
   *
   * 2) options.fromCache = true
   * The model is being restored to a previous state. The passed response is a
   * JSON-formatted representation of the Model. For simple models, nothing
   * needs to be done. For models with nested attributes Models, use parse()
   * to create those nested objects.
   *
   * @method lib/models/ConfigItem#parse
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */

  /**
   * A ConfigItem object does not fetch its data from an API.
   *
   * fetch() handles 2 scenarios:
   *
   * 1) Getting its data from shared configuration.
   *   - This is the default behavior.
   *   - After a model has retrieved its initial values from shared configuration,
   *     a snapshot is taken. The snapshot is used to compare changes between
   *     the base snapshot and whatever state the model is in after any number
   *     of attribute changes.
   *   - Use parse() to retrieve and return the initial values.
   *
   * 2) Processing a previously stored version of itself.
   *   - This scenario occurs when "fromCache: true" is passed in the options
   *     hash.
   *   - In most cases, the cache object can simply be applied over the model
   *     without issue (it is, after-all, a serialized version of the Model).
   *
   * @see lib/models/ConfigItem#parse
   * @param {Object} options
   * @return {jQuery.Promise}
   */
  fetch: function(options) {
    if (options && options.fromCache) {
      this.set(this.parse(options.cache, options));
    } else {
      options = _.extend({}, options, {fromConfig: true});
      this.set(this.parse(null, options), options);
      if (this._snapshotAttributes !== false) {
        this.takeSnapshot();
      }
    }

    return ($.Deferred().resolve()).promise();
  },

  /**
   * Overridden to throw an error. Anything extending ConfigItem is not syncing
   * with an API.
   */
  save: function() {
    throw new Error('Do not use save() with Models extending ConfigItem');
  },

  /**
   * Overridden to throw an error. Anything extending ConfigItem is not syncing
   * with an API.
   */
  destroy: function() {
    throw new Error('Do not use destroy() with Models extending ConfigItem');
  },

  /**
   * Adds an instance of {@link lib/models/DeviceStatus} to this model.
   * WARNING: Use this sparingly and cautiously. Bear in mind that device
   * status is not guaranteed to be always up to date with configuration,
   * since config is managed by the server and status by the device.
   *
   * @param {Object} options
   */
  addDeviceStatus: function(options) {
    // passing options.deviceConfig is for unit testing, which is why it is
    // not documented as a possible property of the "options" para
    this.deviceStatus = options.deviceStatus;
    if (_.isUndefined(this.deviceStatus)) {
      this.deviceStatus = deviceConfigChannel.request('get:status');
    }
  },
});
