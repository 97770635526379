'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var BaseAlertView = require('components/alert/AlertView');
var twig = require('twig').twig;
var tplUpdate = require('components/updateDna/update.html');
var Radio = require('backbone.radio');
var apiChannel = Radio.channel('apiChannel');
var dnaStatusChannel = Radio.channel('dnaStatusChannel');
var UpdateDnaModel = require('components/updateDna/UpdateDnaModel');
var Modal = require('components/modal/ModalView');
var LogMessage = require('lib/models/LogMessage');
var i18n = require('i18next');
var UpdateModalView = require('components/updateDna/modal/UpdateModalView');

/**
 * View for displaying a banner indicating that an update is available.
 *
 * Note, regarding the backend, if the update fails, it'll continue to report
 * the error code until the next time the DNA is rebooted, a successful
 * upgrade, or someone deletes the status file.
 */
module.exports = BaseAlertView.extend({
  /**
   * @member {components/updateDna/UpdateDnaModel} #model
   */

  template: twig({data: tplUpdate}),

  ui: {
    updateBtn: 'button',
  },

  events: {
    'click @ui.updateBtn': 'showModal',
  },

  modelEvents: {
    'change:version': 'render',
    'change:updateState': 'render',
  },

  moreInfoUrl: 'https://networkinghelp.datto.com/help/Content/5_ReleaseNotes/Routers/releaseNotes-DNA.html',

  onAttach: function() {
    _.bindAll(this, 'updateDNA', 'apiCallSent', 'apiCallFailed', 'tplGetInstalledVersion');

    this.listenTo(this.model.get('deviceHealth'), {
      'change:connected change:failedOver': _.debounce(this.render, 1),
    });

    this.listenTo(this.model.get('deviceStatus').get('router'), {
      'change:upgradeAvailable': this.onUpdateAvailable,
      'change:upgradeState': this.onUpdateStateChange,
    });
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    var baseHelpers = BaseAlertView.prototype.templateContext.apply(this);

    return _.extend(baseHelpers, {
      updateStates: UpdateDnaModel.UPDATE,
      installedVersion: this.tplGetInstalledVersion,
      moreInfoUrl: this.moreInfoUrl,
    });
  },

  /**
   * Determine the banner's "severity" and whether it should be visible.
   */
  onBeforeRender: function() {
    var updateState = this.model.get('updateState');
    var autoClose = false;

    if (_.isUndefined(updateState) || updateState === UpdateDnaModel.UPDATE.INPROGRESS) {
      this.model.set('severity', 'info');
    } else if (updateState === UpdateDnaModel.UPDATE.SUCCESS) {
      this.model.set('severity', 'success');
      autoClose = true;
    } else if (updateState === UpdateDnaModel.UPDATE.ERROR) {
      this.model.set('severity', 'error');
      autoClose = true;
    }

    BaseAlertView.prototype.onBeforeRender.apply(this, arguments);

    this.$el.addClass('mb-0');
    this.toggleBanner();

    if (autoClose) {
      this.closeBannerAfterTimeout();
    }
  },

  /**
   * @listens lib/models/DeviceStatus.attributes.router~change:upgradeAvailable
   * @param {lib/models/DeviceStatus} model
   * @param {String|Boolean} version
   * @param {Object} options
   */
  onUpdateAvailable: function(model, version, options) {
    var updateState = this.model.get('updateState');

    // if updateState is undefined, then an update is not in progress
    if (!_.isUndefined(updateState) && version !== this.model.get('version')) {
      this.model.set('updateState', UpdateDnaModel.UPDATE.SUCCESS);
      dnaStatusChannel.trigger('dna:updating', {dnaUpdating: false});
    }

    this.model.set('version', version);
  },

  /**
   * @listens lib/models/DeviceStatus.attributes.router~change:upgradeState
   * @param {lib/models/DeviceStatus} model
   * @param {String} updateState
   * @param {Object} options
   */
  onUpdateStateChange: function(model, updateState, options) {
    if ([1, 2, 4].indexOf(updateState) !== -1) {
      this.model.set('updateState', UpdateDnaModel.UPDATE.INPROGRESS);
    } else if ([3, 5].indexOf(updateState) !== -1) {
      this.model.set('updateState', UpdateDnaModel.UPDATE.ERROR);
      dnaStatusChannel.trigger('dna:updating', {dnaUpdating: false});
    }
  },

  /**
   * If the update is successful or there was an error, autoclose the banner
   * after a short delay.
   */
  closeBannerAfterTimeout: function() {
    var self = this;

    setTimeout(function() {
      self.model.unset('updateState');
      // TODO:: Maybe after the upgrade banner has disappeared make it so the page reloads?
    }, 10000);
  },

  /**
   * Show/hide the banner.
   */
  toggleBanner: function() {
    if ((!this.model.isUpdateable() || this.model.get('version') === false) && !this.model.has('updateState')) {
      this.$el.addClass('hidden');
    } else {
      this.$el.removeClass('hidden');
    }
  },

  showModal: function() {
    var modal = this.buildModal();
    modal.render();
  },

  buildModal: function() {
    var modalView = new UpdateModalView({
      moreInfoUrl: this.moreInfoUrl,
      version: this.model.get('version'),
    });

    return new Modal({
      model: new Backbone.Model(
        {
          callback: this.updateDNA,
          title: i18n.t('updateDna.modalTitle'),
          childView: modalView,
          actionButtonText: i18n.t('updateDna.updateButton'),
          closeText: i18n.t('configEdit.cancel'),
        }
      ),
    });
  },

  /**
   * Fire API call to kick off the upgrade.
   */
  updateDNA: function() {
    this.model.set('updateState', UpdateDnaModel.UPDATE.INPROGRESS);

    dnaStatusChannel.trigger('dna:updating', {dnaUpdating: true});

    apiChannel.request('send', 'DNA.Portal.Update.updateDevice', {
      mac: this.model.get('mac'),
    })
    .done(this.apiCallSent)
    .fail(this.apiCallFailed);
  },

  /**
   * Success callback for the API call to initiate the update.
   *
   * @see updateDna()
   * @param {Object} resp
   */
  apiCallSent: function(resp) {
    var self = this;

    if (resp.error) {
      this.apiCallFailed(resp);
      return;
    }

    // In most cases, the following listener is not needed because, by the
    // time "queue-processed" arrives, a new status has arrived and updated
    // the model's "updateState" accordingly. The listener IS needed for
    // cases where an installation failed, the user tries again, and the
    // installation fails again. In that case, the "change" event on
    // deviceStatus would never fire because the update state did not
    // actually change.
    Radio.once('socket', 'queue-processed', function() {
      var deviceStatus = self.model.get('deviceStatus');
      self.onUpdateStateChange(deviceStatus, deviceStatus.get('router').get('upgradeState'));
    });
  },

  /**
   * Failure callback for the API call to initiate the update.
   *
   * @see updateDna()
   * @param {Object} resp
   */
  apiCallFailed: function(resp) {
    (new LogMessage({
      message: resp.error.message,
      file: 'UpdateDnaView.js',
    })).save();

    this.model.set('updateState', UpdateDnaModel.UPDATE.ERROR);
  },

  /**
   * Gets the version installed.
   *
   * @return {String}
   */
  tplGetInstalledVersion: function() {
    return this.model.get('deviceStatus').get('router').get('version');
  },
});
