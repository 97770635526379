'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var BaseGroupView = require('manage/edit/config/group/GroupView');
var dnaStatusChannel = Radio.channel('dnaStatusChannel');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');
var configTypes = require('lib/configTypes');

/**
 * Renders the list of configuration groups (e.g. Primary LAN, Router settings).
 */
module.exports = Marionette.CollectionView.extend({
  /**
   * Details about the current configuration.
   * @member {config/ConfigModel} manage/edit/group/GroupsView#model
   */

  /**
   * List of configuration groups.
   * @member {config/Groups} manage/edit/group/GroupsView#collection
   */

  template: false,

  className: 'config-list',

  collectionEvents: {
    remove: 'onCollectionRemove',
    update: 'onCollectionUpdate',
  },

  /**
   * @param {Object} options
   * @property {Radio.Channel} options.navChannel
   */
  initialize: function(options) {
    _.bindAll(this, 'focusGroup', 'addGroup', 'addAction', 'onDnaStateChange', 'saveStart', 'saveEnd');

    this.mergeOptions(options, ['navChannel']);

    this.navChannel.reply({
      'focus:group': this.focusGroup, // requested by the toolbox via private navChannel
      'focus:action': this.focusGroup,
      'add:group': this.addGroup,
      'add:action': this.addAction,
    });

    this.listenTo(deviceConfigChannel, {
      'focus:group': this.focusGroup, // stuff on other pages can also ask to focus a group
      'save:start': this.saveStart,
      'save:end': this.saveEnd,
    });

    // Note, defining these listeners here and not on the individual config
    // cards to cut down on the number of event listeners.
    this.listenTo(dnaStatusChannel, {
      'dna:updating': this.onDnaStateChange,
      'dna:connected': this.onDnaStateChange,
    });
  },

  /**
   * @param {Backbone.Model} model
   * @return {manage/edit/config/group/GroupView}
   */
  childView: function(model) {
    var groupType = model.get('type');
    var View = configTypes.groups[groupType].GroupView;

    if (!_.isUndefined(View)) {
      return View;
    }

    return BaseGroupView;
  },

  /**
   * Each child View is itself a composite view because it renders each config
   * group's list of actions. To properly build the compositeView, set its
   * collection to the list of actions.
   *
   * @param {config/Group} model
   * @param {Number} index
   * @return {Object}
   */
  childViewOptions: function(model, index) {
    return {
      collection: model.get('actions'),
      navChannel: this.navChannel,
    };
  },

  /**
   * By default, config groups are unfocused (meaning they show text and not
   * the forms to edit the config). When a user clicks one, focus it.
   *
   * @listens manage/edit/config/group/GroupView~config:group:selected
   * @param {manage/edit/config/group/GroupView} childView
   */
  onChildviewConfigGroupSelected: function(childView) {
    if (childView.model.get('isFocused') === true) {
      return;
    }

    this._focusGroup(childView.model, {scroll: false});
  },

  /**
   * When adding a config card, we need to check whether the DNA is offline,
   * updating, etc. and prepare the child view's model accordingly.
   *
   * @param {edit/config/group/GroupsView} collectionView
   * @param {edit/config/group/GroupView} childView
   */
  onAddChild: function(collectionView, childView) {
    if (dnaStatusChannel.request('is:dna:updating')) {
      childView.model.set('isSaveable', false, {dnaUpdating: true});
    } else if (!dnaStatusChannel.request('is:dna:connected')) {
      childView.model.set('isSaveable', false, {dnaConnected: false});
    } else if (this.model.get('isSaving') === true) {
      childView.model.set('isSaveable', false);
    }
  },

  /**
   * When a group is removed from the config outline, add some info about
   * its replacement status and provide an event to hook
   *
   * @see this.onCollectionUpdate
   * @param {config/Group} model
   */
  onCollectionRemove: function(model) {
    // mark the group as anticipating a replacement - this is a best-
    // effort heuristic as we currently have no way of definitively
    // matching cards with their incoming replacement
    if (model.get('isApplying') && !model.get('pendingDelete')) {
      model.set('isBeingReplaced', true);
    }

    // give the group's view a chance to perform any final cleanup
    model.trigger('group:removed');
  },

  /**
   * When a card is added, check to see whether it's replacing a
   * card that's being removed at the same time, and if so, notify
   * it so that it can take over display of the "success" message.
   * This is needed for "create" cards that get replaced with "edit"
   * cards on save, and for cards that are destroyed and re-created
   * in the config with a different id upon an edit.
   *
   * @listens this.collection~update
   * @param {config/Groups} collection
   * @param {Object} options
   */
  onCollectionUpdate: function(collection, options) {
    var removedModel;
    var addedModel;

    if (options.changes.added.length === 1 && options.changes.removed.length === 1) {
      // check for card save resulting in replacement
      removedModel = options.changes.removed[0];
      if (removedModel.get('isBeingReplaced') === true) {
        addedModel = options.changes.added[0];
        addedModel.trigger('group:saved:created');
      }
    }
  },

  /**
   * If the DNA is not accessible, the firmware is being updated, etc., prevent
   * any config changes from being made.
   *
   * @listens dnaStatusChannel.on~dna:updating
   * @listens dnaStatusChannel.on~dna:connected
   * @param {Object} data
   */
  onDnaStateChange: function(data) {
    var isSaveable = !(data.dnaUpdating === true || data.dnaConnected === false);
    this.collection.invoke('set', 'isSaveable', isSaveable, data);
  },

  /**
   * Focus the configuration card that matches the passed parameters.
   *
   * @listens deviceConfigChannel.trigger~focus:group
   * @listens navChannel.request~focus:group
   * @listens navChannel.request~focus:action
   * @param {String} type
   * @param {String} typeId
   * @param {String} actionId
   */
  focusGroup: function(type, typeId, actionId) {
    var groupView = this.children.find(function(view) {
      if (view.model.get('type') === type) {
        return (_.isUndefined(typeId) || view.model.get('typeId') === typeId);
      }

      return false;
    });

    if (!_.isUndefined(groupView)) {
      this._focusGroup(groupView.model);
    }
  },

  /**
   * Sets all configuration cards to not saveable
   *
   * @listens deviceConfigChannel.request~save:start
   * @param {String} type
   * @param {String} typeId
   * @param {String} actionId
   */
  saveStart: function(type, typeId, actionId) {
    this.model.set('isSaving', true);
    this.collection.invoke('set', 'isSaveable', false);
  },

  /**
   * Sets all configuration cards to saveable
   *
   * @listens deviceConfigChannel.request~save:end
   * @param {String} type
   * @param {String} typeId
   * @param {String} actionId
   */
  saveEnd: function(type, typeId, actionId) {
    this.model.set('isSaving', false);
    this.collection.invoke('set', 'isSaveable', true);
  },

  /**
   * Adds a new group to the workbench.
   *
   * @listens navChannel.request~add:group
   * @param {String} type
   */
  addGroup: function(type) {
    var attrs = {
      type: type,
      deviceMac: this.model.get('deviceMac'),
    };

    var newGroup = this.collection.add(attrs, {parse: true, create: true});
    var childView = this.children.findByModel(newGroup);
    this.listenToOnce(childView, 'actions:loaded', this._focusGroup.bind(this, newGroup));
  },

  /**
   * Handler for adding an action to the passed configuration group.
   *
   * @listens navChannel.reply~add:action
   * @param {String} type
   * @param {String} typeId
   * @param {String} actionId
   */
  addAction: function(type, typeId, actionId) {
    var group = this.collection.getGroup(type, typeId);
    group.addAction(actionId);
    this._focusGroup(group);
  },

  /**
   * Helper method to change which configuration group is focused.
   *
   * @param {config/Group} model
   * @param {Object} options
   * @property {Boolean} scroll
   *   When focusing the model, if "scroll" is false, smooth-scrolling will not
   *   be used. Smooth-scrolling is not always appropriate.
   */
  _focusGroup: function(model, options) {
    this.collection.invoke('set', 'isFocused', false);
    model.set('isFocused', true, options);
  },
});
