'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var itemTpl = require('components/connectedDevicesList/connected-device.html');

/**
 * Displays a connected-device's status.
 */
module.exports = Marionette.View.extend({
  /**
   * @name components/connectedDevicesList/ConnectedDeviceView#model
   * @type Backbone.Model
   * @property {String} id
   *   The mac address of the connected device.
   * @property {String} hostname
   * @property {String} ip
   * @property {String} url
   *   The URL to use for the link's href.
   * @property {String} parentNetworkType
   *   The type of badge that should be shown - possible values:
   *    i18n.t('networkTypes.wifi') = Wifi
   *    i18n.t('networkTypes.lan') = Lan
   *    i18n.t('networkTypes.unknown') = Unknown
   * @property {String} parentNetworkUrl
   *   (Optional) The URL to use for the badge to jump to the network the device is connected to
   * @property {String} networkDescription
   *   (Optional) The description of the network that the tooltip will show when hovered over it
   */

  template: twig({data: itemTpl}),

  className: 'rs-table-row',

  ui: {
    badge: '.dui-badge',
    moreInfo: '.rs-table-cell-action a',
  },

  triggers: {
    'click @ui.moreInfo': 'connectedDevice:selected',
    'click @ui.badge': 'networkView:selected',
  },

  modelEvents: {
    // TODO Right now, the View is small and rerenders are cheap. Rethink as
    // more data gets added to the View.
    'change': 'render',
  },

  onRender: function() {
    this.ui.badge.tooltip({html: true, trigger: 'hover'});
  },
});
