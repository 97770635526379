'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

/**
 * List of Views making up a VLANs configuration, which is broken up to prevent
 * one gigantic View.
 *
 * Note, the order below is the order the Views will be rendered in.
 */
var configViews = [
  {type: 'vlan', View: require('manage/details/vlan/configs/VlanIdView')},
  {type: 'address', View: require('manage/details/vlan/configs/AddressView')},
  {type: 'dhcpPool', View: require('manage/details/vlan/configs/DhcpPoolView')},
  {type: 'ports', View: require('manage/details/ports/DrillablePortsOverview')},
];

/**
 * Collection View for displaying a VLAN's configuration.
 */
module.exports = Marionette.CollectionView.extend({
  /**
   * This View's collection is the list of "configViews" above.
   * @member {Backbone.Collection} manage/details/vlan/ConfigListView#collection
   */

  /**
   * Reference to the DeviceConfiguration "networks" object associated with the
   * current VLAN.
   * @member {Object} manage/details/vlan/ConfigListView#model
   */

  /**
   * A reference to deviceConfig is passed to each child View.
   * @member {lib/models/DeviceConfiguration} manage/details/vlan/ConfigListView#deviceConfig
   */

  /**
   * A reference to deviceStatus is passed to each child View.
   * @member {lib/models/DeviceStatus} manage/details/vlan/ConfigListView#deviceStatus
   */

  /**
   * @param {Object} options
   * @property {String} deviceMac
   * @property {lib/models/DeviceConfiguration} deviceConfig
   * @property {lib/models/DeviceStatus} deviceStatus
   */
  initialize: function(options) {
    var listItems = configViews;

    this.deviceConfig = options.deviceConfig;
    this.deviceStatus = options.deviceStatus;
    this.deviceMac = options.deviceMac;

    var network = this.model = this.deviceConfig.get('networks').get(options.networkId);

    listItems = _.filter(listItems, function(item) {
      if (_.isFunction(item.View.isApplicable)) {
        return item.View.isApplicable(network);
      }
      return true;
    });

    this.collection = new Backbone.Collection(listItems);
  },

  /**
   * Child views are dynamic based on the type of configuration the child view
   * represents. See the "configViews" variable.
   *
   * @param {Backbone.Model} item
   * @return {Marionette.View}
   */
  childView: function(item) {
    return item.get('View');
  },

  /**
   * Pass along the VLAN config, as well deviceConfig and deviceStatus to each
   * child View.
   *
   * @param {Backbone.Model} child
   *   A model within this.collection.
   * @param {Marionette.View} ChildViewClass
   * @param {Object} childViewOptions
   * @return {Marionette.View}
   */
  buildChildView: function(child, ChildViewClass, childViewOptions) {
    var options = _.extend({
      model: this.model,
      deviceStatus: this.deviceStatus,
      deviceConfig: this.deviceConfig,
      deviceMac: this.deviceMac,
      isVlanView: true,
    }, childViewOptions);

    return new ChildViewClass(options);
  },
});
