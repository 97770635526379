'use strict';

var _ = require('lodash');
var i18n = require('i18next');

/**
 * Collection of common template helpers.
 *
 * There are a couple of ways to use this in your View.
 *
 * @example
 * var tplHelpers = require(...);
 * var myView = Marionette.View.extend({
 *   templateContext: tplHelpers
 * });
 * // -- or --
 * var myView = Marionette.View.extend({
 *   templateContext: function() {
 *     var helpers = {};
 *     return _.extend(helpers, tplHelpers.apply(this));
 *   }
 * });
 *
 * @return {Object}
 */
module.exports = function() {
  var self = this;

  return {
    cid: function() {
      return self.cid;
    },

    /**
     * Returns the "checked" attribute for use with checkboxes and radios.
     *
     * @param {*} modelVal
     *   The value to compare.
     * @param {*} valueToMatch
     *   The value to compare against.
     * @return {string}
     */
    checked: function(modelVal, valueToMatch) {
      if (arguments.length === 1 && modelVal === true) {
        return ' checked';
      }

      if (modelVal === valueToMatch) {
        return ' checked';
      }
      if (_.isEqual(modelVal, valueToMatch)) {
        return ' checked';
      }

      return '';
    },

    /**
     * Returns the "selected" attribute for use with select elements.
     *
     * @param {string} modelVal
     *   The value to compare.
     * @param {string} valueToMatch
     *   The value to compare against.
     * @return {string}
     */
    selected: function(modelVal, valueToMatch) {
      if (modelVal === valueToMatch) {
        return ' selected';
      }

      return '';
    },

    /**
     * Returns the "disabled" attribute for use with various interactive controls
     *
     * @param {string} modelVal
     *   The value to compare.
     * @param {string} valueToMatch
     *   The value to compare against.
     * @return {string}
     */
    attrDisabled: function(modelVal, valueToMatch) {
      if (arguments.length === 1 && modelVal === true) {
        return ' disabled';
      }

      if (modelVal === valueToMatch) {
        return ' disabled';
      }

      return '';
    },

    /**
     * If the passed value is blank, return "(blank)".
     *
     * @param {mixed} val
     * @return {String}
     */
    blankVal: function(val) {
      return (_.isEmpty(val) ? i18n.t('configEdit.fieldBlank') : val);
    },

    /**
     * If the passed value is empty, return "(none assigned)".
     *
     * @param {mixed} val
     * @return {String}
     */
    emptyVal: function(val) {
      return (_.isEmpty(val) ? i18n.t('configEdit.fieldNoValues') : val);
    },

    /**
     * Returns "Yes" or "No".
     *
     * @param {Boolean} val
     * @return {String}
     */
    yesNoVal: function(val) {
      if (val === true) {
        return i18n.t('configEdit.yes');
      }

      if (_.isUndefined(val)) {
        return i18n.t('configEdit.fieldNoValues');
      }

      return i18n.t('configEdit.no');
    },
  };
};
