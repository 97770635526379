'use strict';

var i18n = require('i18next');
var EditWanCustomMac = require('actions/wanCustomMac/EditWanCustomMac');
var FormView = require('actions/wanCustomMac/FormView');
var StaticView = require('actions/wanCustomMac/StaticView');

/**
 * Action configuration for the VLAN custom mac action component.
 */
module.exports = {
  title: i18n.t('actionWanCustomMac.title'),
  Model: EditWanCustomMac,
  FormView: FormView,
  TextView: StaticView,
};
