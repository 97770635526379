'use strict';

var _ = require('lodash');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var networkUtils = require('lib/network');
var snapshotMixin = require('lib/mixins/snapshot');
var i18n = require('i18next');

/**
 * Represents an individual Outbound NAT rule
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({
  /**
   * @member {Object} actions/outboundNat/EditNat#attributes
   * @property {Boolean} remove
   *   Whether the rule should be removed on save
   * @property {string} egressIp
   * @property {string} srcIp
   * @property {boolean} enabled
   *  enabled or disabled
   */

  _snapshotAttributes: ['name', 'type', 'egressIp', 'srcIp', 'enabled', 'remove'],

  defaults: {
    remove: false,
    enabled: true,
  },

  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true) {
      // rules pending removal are never considered invalid
      return;
    }

    if (_.has(attrs, 'name')) {
      attrs.name = attrs.name.trim();
      if (attrs.name === '') {
        errors.name = i18n.t('actionOutboundNat.missingName');
      }
    }

    if (_.has(attrs, 'srcIp') || (_.has(attrs, 'type') && this.get('srcIp'))) {
      var type;
      var src;
      if (_.has(attrs, 'srcIp')) {
        type = this.get('type');
        src = attrs.srcIp;
      } else {
        type = attrs.type;
        src = this.get('srcIp');
      }
      if (type === 'network') {
        if (!networkUtils.validInternalSubnet(src)) {
          errors.srcIp = i18n.t('actionOutboundNat.badSubnet');
        }
      } else if (!networkUtils.validInternalIPv4(src)) {
        errors.srcIp = i18n.t('actionOutboundNat.badAddress');
      }
    }

    if (_.has(attrs, 'srcIp')) {
      if (attrs.srcIp === this.get('egressIp')) {
        errors.srcIp = i18n.t('actionOutboundNat.sourceMatchesEgress');
      } else {
        var cid = this.cid;
        var duplicates = this.collection.filter(function(rule) {
          return rule.cid !== cid && rule.get('srcIp') === attrs.srcIp;
        });
        if (duplicates.length > 0) {
          errors.srcIp = i18n.t('actionOutboundNat.duplicateSource');
        }
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return {
        name: 'nat.removeOutboundNat',
        data: {
          id: this.id,
        },
      };
    }

    if (this.isNew() || this.getSnapshotDiff()) {
      var task = {
        name: 'nat.addOutboundNat',
        data: {
          egressIp: this.get('egressIp'),
          name: this.get('name'),
          srcType: this.get('type'),
          srcIp: this.get('srcIp'),
          enabled: this.get('enabled'),
        },
      };

      if (!this.isNew()) {
        task.data.id = this.id;
      }

      return task;
    }
  },
});
