'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var configTypes = require('lib/configTypes');
var console2 = require('lib/Console');
var LogMessage = require('lib/models/LogMessage');

/**
 * Stand-in model for unknown group types. Reports
 * itself as invalid immediately upon instantiation,
 * so Backbone will skip adding it to the collection.
 */
var UnknownModel = Backbone.Model.extend({
  constructor: function(attrs, options) {
    var opts = _.extend({}, options, {validate: true});
    Backbone.Model.apply(this, [attrs, opts]);
  },
  validate: function() {
    return 'Unknown config group type: ' + this.get('type');
  },
});

/**
 * Collection of {@link config/Group} models. Represents the entire
 * active configuration of a DNA.
 */
module.exports = Backbone.Collection.extend({
  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {config/Group}
   */
  model: function(attrs, options) {
    var config = configTypes.groups[attrs.type];

    if (_.isUndefined(config) || !config.Model) {
      console2.log('error', 'Unknown config group type: "' + attrs.type + '". Check configTypes.js.');
      (new LogMessage({
        file: 'config/Groups.js',
        message: 'Unknown config group type. ' + JSON.stringify(attrs),
      })).save();

      return new UnknownModel({type: attrs.type}, options);
    }

    return new config.Model(attrs, _.extend({}, options, {actionTypes: configTypes.actions}));
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (!_.isArray(resp) || this.length === 0) {
      return resp;
    }

    return this._sortResponse(resp);
  },

  /**
   * Sorts the passed array of serialized groups so that it is in the same
   * order as the workbench.
   *
   * @see Groups.parse()
   * @param {Array} resp
   * @return {Array}
   */
  _sortResponse: function(resp) {
    var currentOrder = this.pluck('id');

    // split the list into [ [groups found in current workbench], [new groups] ]
    var oldAndNew = _.partition(resp, function(item) {
      return currentOrder.indexOf(item.id) !== -1;
    });

    // sort existing groups list to match the same order as the workbench
    oldAndNew[0].sort(function(a, b) {
      var indexA = currentOrder.indexOf(a.id);
      var indexB = currentOrder.indexOf(b.id);

      if (indexA < indexB) {
        return -1;
      } else if (indexA > indexB) {
        return 1;
      }

      return 0;
    });

    return oldAndNew[0].concat(oldAndNew[1]);
  },

  /**
   * Helper method to find a model by type and Id or just type.
   *
   * @param {String} type
   *   The configuration group type.
   * @param {String} typeId
   *   (Optional) The Id of the configuration group.
   * @return {config/Group|undefined}
   */
  getGroup: function(type, typeId) {
    return this.find(function(model) {
      if (model.get('type') === type) {
        return (_.isUndefined(typeId) || model.get('typeId') === typeId);
      }

      return false;
    });
  },
});
