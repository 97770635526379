'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Backbone = require('backbone');

/**
 * View-Model for {@link manage/details/connectedDevice/ConnectedDeviceView}.
 */
module.exports = Backbone.Model.extend({
  /**
   * In addition to the documented properties, this Model will also take the
   * properties found in a "device" object found in a "devices" array within
   * the DeviceStatus connectedDevices collection.
   *
   * @member {Object} manage/details/connectedDevice/ConnectedDeviceModel#attributes
   * @property {String} id
   *   The device's mac address
   * @property {String} vlanId
   *   The VLAN the device is associated with.
   * @property {String} deviceMac
   * @property {lib/models/DeviceConfig} deviceConfig
   * @property {lib/models/DeviceStatus} deviceStatus
   */

  /**
   * Overridden to always get the data from DeviceStatus.
   *
   * @param {Object} options
   * @return {jQuery.Deferred}
   */
  fetch: function(options) {
    var self = this;
    var vlanDevicesLists = this.get('deviceStatus').get('connectedDevices');
    var device;
    var vlan;

    vlan = vlanDevicesLists.get(this.get('vlanId'));
    device = _.find(vlan.get('devices'), function(deviceObj) {
      return deviceObj.id === self.id;
    });

    this.set(device);

    return ($.Deferred().resolve()).promise();
  },
});
