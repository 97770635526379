'use strict';

var BaseWebFiltersStaticView = require('actions/webFilters/StaticView');
var tpl = require('actions/webFiltersTitanHq/enable/static.html');
var twig = require('twig').twig;

/**
 * Renders a text-only version of the enable component of titan hq.
 */
module.exports = BaseWebFiltersStaticView.extend({
  template: twig({data: tpl}),
});
