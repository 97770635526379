'use strict';

var i18n = require('i18next');
var EditPorts = require('actions/ports/EditPorts');
var WanPortsFormView = require('actions/ports/wan/WanPortsFormView');
var WanPortsStaticView = require('actions/ports/wan/WanPortsStaticView');

/**
 * Action configuration for the VLAN ports action component.
 */
module.exports = {
  title: i18n.t('actionPorts.title'),
  Model: EditPorts,
  FormView: WanPortsFormView,
  TextView: WanPortsStaticView,
};
