'use strict';

var EditLan = require('actionGroups/editLan/EditLan');
var i18n = require('i18next');
var TrafficManagementDisruptionAlert = require('actionGroups/shared/TrafficManagementDisruptionView');
var configPage = require('lib/configPage');

var staticProps = {
  isCreational: true,
  creationalPage: configPage.NETWORKS,
};

// https://jira.datto.net/browse/DNET-1573
// The DNA has some udshape problems if we have more than 9 LANs
var MAX_LANS = 9;

/**
 * "Create LAN" configuration group.
 */
module.exports = EditLan.extend({
  defaults: {
    isDirty: true,
  },

  isConnectivityDisrupted: function(type, target) {
    return TrafficManagementDisruptionAlert; // network topology change
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return true;
  },

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return false;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.addLan');
  },

  /**
   * Indicates if the user can create a new LAN.
   *
   * @param {config/ConfigModel} configOutline
   * @return {Boolean}
   */
  allowNew: function(configOutline) {
    var lans = configOutline.filterGroups(function(group) {
      var type = group.get('type');
      return type === 'editLan' || type === 'createLan';
    });
    return lans.length < MAX_LANS;
  },

  /**
   * Provides a user message indicating why a new LAN
   * cannot be created.
   *
   * @return {String}
   */
  disallowedNewReason: function() {
    return i18n.t('groups.addLanLimit', {limit: MAX_LANS});
  },
}, staticProps);
