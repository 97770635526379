'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/domainFilters/static.html');
var twig = require('twig').twig;
var i18n = require('i18next');

module.exports = BaseStaticView.extend({
  /**
   * @name actions/domainFilters/FormView#model
   * @type {actions/domainFilters/EditDomainFilters
   */

  template: twig({data: tpl}),

  serializeData: function() {
    var whitelist = [];
    var blacklist = [];
    var model = this.model.toJSON();

    model.items.forEach(function(filter) {
      if (filter.type === 'whitelist') {
        whitelist.push(filter.domain);
      } else if (filter.type === 'blacklist') {
        blacklist.push(filter.domain);
      }
    });

    return {
      whitelist: whitelist.join(i18n.t('general.listSeparator')),
      blacklist: blacklist.join(i18n.t('general.listSeparator')),
    };
  },
});
