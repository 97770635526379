'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/router/siteToSiteRekeyTime/static.html');
var twig = require('twig').twig;
var dateFormatters = require('lib/tplHelpers/dateFormatters');
var i18n = require('i18next');

/**
 * Renders a text-only version of the site-to-site rekey time component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name #model
   * @type {actions/router/siteToSiteRekeyTime/EditSiteToSiteRekeyTime}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      formatHours: this._formatHours,
    });
  },
  /**
   * Formats list of selected days into a text string
   *
   * @param {String} hours
   *   Hours from #model
   *   Interval from #model
   * @return {String}
   * @private
   */
  _formatHours: function(hours) {
    if (hours) {
      return dateFormatters.labelForHours(hours);
    }
    return i18n.t('configEdit.fieldNoValues');
  },
});
