'use strict';

var Marionette = require('backbone.marionette');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var StaticHostMappingView = require('actions/staticHostMapping/StaticHostMappingView');
var tpl = require('actions/staticHostMapping/static.html');
var twig = require('twig').twig;

var StaticHostMappingsListView = Marionette.CollectionView.extend({
  childView: StaticHostMappingView,
  filter: function(child, index) {
    // only show first 5 children max in static view
    return index >= 0 && index < 5;
  },
});

/**
 * Renders a text-only version of the static host mappings component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/staticHostMapping/StaticView#model
   * @type {actions/staticHostMapping/StaticHostMappings}
   */

  template: twig({data: tpl}),

  regions: {
    rules: '.rg-hostMappings',
  },

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.showChildView('rules', new StaticHostMappingsListView({
      collection: this.model.get('items'),
    }));
  },
});
