'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/vpn/users/static.html');
var twig = require('twig').twig;
var viewFilters = require('lib/viewFilters');

/**
 * Renders a text-only version of the "VPN Users" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/vpn/users/StaticView#model
   * @type {actions/vpn/users/EditVpnUsers
   */

  template: twig({data: tpl}),

  serializeData: function() {
    // make this work right with wrapped model collection
    return this.model.toJSON();
  },

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      getItemCountHelp: viewFilters.getItemCountHelpFn({
        // i18n.t('actionVpn.users.noUsers')
        emptyMsgKey: 'actionVpn.users.noUsers',
        // i18n.t('actionVpn.users.allDisabled', {count: count})
        allDisabledMsgKey: 'actionVpn.users.allDisabled',
        // i18n.t('actionVpn.users.viewAll', {count: count})
        moreMsgKey: 'actionVpn.users.viewAll',
      }),
    });
  },

});
