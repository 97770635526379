'use strict';

var _ = require('lodash');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var i18n = require('i18next');
var PortSettingsLib = require('lib/portSettings');

/**
 * Represents an individual port
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({
  /**
   * @member {Object} actions/speedDuplexSetting/EditSpeedDuplexSettings#attributes
   * @property {String} speed
   * @property {String} duplex
   */

  _snapshotAttributes: ['configuredSpeed', 'configuredDuplex'],

  validate: function(attrs, options) {
    var errors = {};
    var hasDuplex = _.has(attrs, 'configuredDuplex');
    var hasSpeed = _.has(attrs, 'configuredSpeed');

    if (hasSpeed &&
      _.indexOf(PortSettingsLib.getSupportedSpeed(), attrs.configuredSpeed) === -1
    ) {
      errors.speedMissing = i18n.t('actionSpeedDuplex.errors.invalidSpeed');
    }

    if (hasDuplex &&
      _.indexOf(PortSettingsLib.getSupportedDuplex(), attrs.configuredDuplex) === -1
    ) {
      errors.duplexMissing = i18n.t('actionSpeedDuplex.errors.invalidDuplex');
    }

    var hasBeenConfigured = (hasSpeed && hasDuplex);

    if (hasBeenConfigured) {
      if (attrs.configuredSpeed === '1000' && attrs.configuredDuplex === 'half') {
        errors.incorrectSpeedDuplex = i18n.t('actionSpeedDuplex.errors.incorrectSpeedDuplex');
      }

      // Auto-neg is all or nothing therefore this checks that a user isn't trying to set up one setting
      // to be auto and the other to not be... doesn't work like that.
      // The onSelected methods should also prevent this
      if ((attrs.configuredSpeed === 'auto' && attrs.configuredDuplex !== 'auto') ||
        (attrs.configuredSpeed !== 'auto' && attrs.configuredDuplex === 'auto')
      ) {
        errors.incorrectAutoConfiguration = i18n.t('actionSpeedDuplex.errors.incorrectAutoConfiguration');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    return {
      portId: this.id,
      data: {speed: this.get('configuredSpeed'), duplex: this.get('configuredDuplex')},
    };
  },
});
