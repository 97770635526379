'use strict';

var _ = require('lodash');
var $ = require('jquery');
var BaseGroup = require('config/Group');
var i18n = require('i18next');
var RenderPositions = require('config/RenderPositions');
var ConfigMessageTypes = require('manage/edit/ConfigMessageTypes');

/**
 * "Configuration Clone" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'configClone',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.configClone.title');
  },

  /**
   * @return {String}
   */
  titleOverwrite: function() {
    return i18n.t('groups.configClone.titleOverwrite');
  },

  applyLabel: function() {
    return i18n.t('groups.configClone.applyLabel');
  },

  emptyCard: function() {
    var emptyCount = _.reduce(this.get('actions').models, function(memo, action) {
      memo += action.get('actionModel').isEmptyModel() ? 1 : 0;
      return memo;
    }, 0);

    return emptyCount === this.get('actions').length;
  },

  groupEnabled: function() {
    return !this.emptyCard();
  },

  /**
   * Provides a user message indicating why the tool can't be used.
   *
   * @return {String}
   */
  disallowedNewReason: function() {
    return i18n.t('configEdit.configClone.disabledReason');
  },

  /**
   * Override to handle the custom no changes error message for this action group.
   * @param {Object} options
   * @returns {JQueryDeferred<any>}
   * @private
   */
  _validateActions: function(options) {
    // Find all actions with changes
    var hasChanges = this.get('actions').filter(function(action) {
      return action.actionHasChanges();
    });

    if (hasChanges.length > 0) {
      return BaseGroup.prototype._validateActions.call(this, options);
    }
    return $.Deferred().reject({type: ConfigMessageTypes.CLONE_CONFIG_NO_CHANGES});
  },
});
