'use strict';

var Backbone = require('backbone');
var $ = require('jquery');

/**
 * View-Model for {@link manage/details/dna/DnaView}.
 */
module.exports = Backbone.Model.extend({
  /**
   * @member {Object} manage/details/dna/DnaViewModel#attributes
   * @property {String} deviceMac
   * @property {lib/models/DeviceConfiguration} deviceConfig
   * @property {lib/models/DeviceStatus} deviceStatus
   * @property {Backbone.Model} statusCard
   *   The "statusCard" property found in "fleet-overview" eventsource message.
   * @property {lib/models/Bandwidth} bandwidth
   * @property {config/ConfigModel} configState
   *   Config outline indicating which features are applicable for this device
   */

  /**
   * Overridden to return a promise immediately. All data has already been
   * fetched.
   *
   * @param {Object} options
   * @return {jQuery.Deferred}
   */
  fetch: function(options) {
    // Note, the main template does not need any special data. The details
    // region will be passed an instance of this Model and use DeviceConfig
    // and DeviceStatus as needed.

    return ($.Deferred().resolve()).promise();
  },
});
