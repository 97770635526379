'use strict';

var EditPriority = require('actions/wanPriority/EditPriority');
var i18n = require('i18next');
var FormView = require('actions/wanPriority/FormView');
var StaticView = require('actions/wanPriority/StaticView');

/**
 * Action configuration for the "WAN Priority" action component.
 */
module.exports = {
  title: i18n.t('actionWanPriority.title'),
  Model: EditPriority,
  FormView: FormView,
  TextView: StaticView,
};
