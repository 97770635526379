'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/router/ntp/form.html');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the NTP servers component.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/router/ntp/FormView#model
   * @type {actions/router/ntp/EditNtp}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    ntp1: '[name="ntp1"]',
    ntp2: '[name="ntp2"]',
  },

  events: {
    'blur @ui.ntp1': 'saveNtp1',
    'blur @ui.ntp2': 'saveNtp2',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  /**
   * Save primary NTP server.
   *
   * @param {Event} ev
   */
  saveNtp1: function(ev) {
    var value = ev.currentTarget.value;
    ev.preventDefault();
    this.ui.ntp1.bs3ui('clearFieldError');
    this.model.set({primaryNtp: value}, {commit: true});
  },

  /**
   * Save secondary NTP server.
   *
   * @param {Event} ev
   */
  saveNtp2: function(ev) {
    var value = ev.currentTarget.value;
    ev.preventDefault();
    this.ui.ntp2.bs3ui('clearFieldError');
    this.model.set({secondaryNtp: value}, {commit: true});
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/router/ntp/EditNtp} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.ntp1) {
      this.ui.ntp1.bs3ui('showFieldError', error.ntp1);
    }

    if (error.ntp2) {
      this.ui.ntp2.bs3ui('showFieldError', error.ntp2);
    }
  },

});
