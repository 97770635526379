'use strict';

var BaseGroup = require('config/Group');
var i18n = require('i18next');
var RenderPositions = require('config/RenderPositions');
var EditWifi = require('actions/router/wifi/EditWifi');

/**
 * "Manage Global WiFi Settings" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'wifiGlobal',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.wifiGlobal');
  },

  isConnectivityDisrupted: function(type, target) {
    // Checks whether wifi is globally disabled or not. If its already globally disabled
    // then there can not possibly be any network disruption.
    if (this.deviceConfig.get('wifi').get('disabled')) {
      return false;
    }

    if (type === 'input') {
      return (
        target.name === 'wifi-frequency' ||
        target.name === 'wifi-f2-channel' ||
        target.name === 'wifi-f5-channel' ||
        target.id === 'wifi-enable-off'
      );
    }

    return false;
  },

  isMisconfigured: function() {
    var country = this.deviceConfig.get('router').get('authorizedCountryCode');
    return !EditWifi.isSupportedCountry(country);
  },
});
