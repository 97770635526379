'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var RenderChanges = require('lib/behaviors/RenderChanges');
var tplHelpers = require('lib/tplHelpers');
var tpl = require('actions/router/userTimezone/form.html');
var twig = require('twig').twig;
require('lib/jquery/bootstrapUI');
require('select2');

/**
 * Renders the "user timezone" form field.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/router/userTimezone/FormView#model
   * @type {actions/router/userTimezone/EditUserTimezone}
   */

  template: twig({data: tpl}),

  ui: {
    'userTimezone': '[name="userTimezone"]',
  },

  events: {
    'change @ui.userTimezone': 'saveForm',
  },

  modelEvents: {
    'invalid': 'onError',
    'change:userTimezones': 'render',
  },

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  templateContext: function() {
    return _.extend(
      {
        timezoneList: this.model.getUserTimezones(),
      },
      tplHelpers.apply(this)
    );
  },

  /**
   * Initiate select2 plugin.
   */
  onDomRefresh: function() {
    this.ui.userTimezone.select2({width: '100%', theme: 'bootstrap'});
  },

  /**
   * Clean up select2 plugin.
   */
  onDomRemove: function() {
    this.ui.userTimezone.select2('destroy');
  },

  /**
   * Checks if timezone value has changed, validates and saves.
   *
   * @param {Event} ev
   */
  saveForm: function(ev) {
    this.ui.userTimezone.bs3ui('clearFieldError');
    this.model.set({userTimezone: ev.target.value}, {commit: true});
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/router/timezone/EditTimezone} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    this.ui.userTimezone.bs3ui('showFieldError', error.userTimezone);
  },
});
