'use strict';

var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');

module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({
  _snapshotAttributes: [
    'enabled',
  ],

  defaults: {
    enabled: true,
  },
});
