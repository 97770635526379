'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/shared/portForwarding/rule.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');
require('devbridge-autocomplete');

/**
 * Renders the editable form elements for a single port forwarding rule.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/shared/portForwarding/FormView#model
   * @type {actions/shared/portForwarding/Rule}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    enabled: '[name="enabled"]',
    name: '[name="name"]',
    srcPortRange: '[name="srcPortRange"]',
    protocol: '[name="protocol"]',
    destIp: '[name="destIp"]',
    destPort: '[name="destPort"]',
    srcIp: '[name="srcIp"]',
    pfOnly: '[name="pfOnly"]',
    pfExcluded: '[name="pfExcluded"]',
    remove: '.btn-remove',
    messages: '.help-block',
  },

  events: {
    'change @ui.enabled': 'updateModel',
    'blur @ui.name': 'updateModel',
    'blur @ui.srcPortRange': 'updateModel',
    'change @ui.protocol': 'updateModel',
    'blur @ui.destIp': 'updateModel',
    'blur @ui.destPort': 'updateModel',
    'focus @ui.srcIp': 'getAutocomplete',
    'blur @ui.srcIp': 'updateModel',
    'click @ui.remove': 'handleRemoveButton',
  },

  modelEvents: {
    'change:srcPortRange': 'onChangeSrcPortRange',
    'change:remove': 'render',
    'change:enabled': 'onEnabledChange',
    'invalid': 'onError',
    'noConflicts': 'onNoConflicts',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var context = {};
    return _.extend(context, tplHelpers.apply(this));
  },

  /**
   * Sync changes in UI input elements to the model and trigger validation
   *
   * @param {Object} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'enabled':
        this.model.set({enabled: event.currentTarget.checked});
        break;

      case 'name':
        this.clearError('name');
        this.model.set({name: value}, {commit: true});
        break;

      case 'srcPortRange':
        this.clearError('srcPortRange');
        this.clearError('destPort');
        this.model.set({srcPortRange: value}, {commit: true});
        break;

      case 'protocol':
        this.model.set({proto: value});
        break;

      case 'destIp':
        this.clearError('destIp');
        this.model.set({destIp: value}, {commit: true});
        break;

      case 'destPort':
        this.clearError('destPort');
        this.model.set({destPort: value}, {commit: true});
        break;

      case 'srcIp':
        this.clearError('srcIp');
        this.model.set({srcIp: value}, {commit: true});
        break;

      default:
        break;
    }
  },

  /**
   * Remove this rule
   */
  handleRemoveButton: function() {
    if (this.model.isNew()) {
      // remove never-saved rules immediately
      this.model.collection.remove(this.model);
    } else {
      // flag existing rules for deletion upon save
      var shouldRemove = !this.model.get('remove');
      if (shouldRemove) {
        // if you ask to remove an existing rule, roll back any local changes
        // (otherwise it gets really confusing what you're actually removing)
        this.model.applySnapshot();
      }
      this.model.set({remove: shouldRemove});
    }
  },

  getAutocomplete: function() {
    var thisView = this;
    this.ui.srcIp.autocomplete({
      lookup: _.pluck(thisView.model.get('netObjs'), 'description'),
      width: 'flex',
      maxHeight: 200,
      onSelect: function(suggestion) {
        thisView.updateModel({
          currentTarget: {
            name: 'srcIp',
            value: suggestion.value,
          },
        });
      },
    });
  },

  onBeforeRender: function() {
    this.isItemOff = !this.model.get('enabled');
  },

  onRender: function() {
    if (this.model.get('role') === 'PORT_FORWARD') {
      this.ui.pfOnly.removeClass('hidden');
      this.ui.pfExcluded.addClass('hidden');
    }
    this.toggleDestPort();
  },

  toggleDestPort: function(srcPort) {
    srcPort = srcPort || this.model.get('srcPortRange') || '';
    var isRange = srcPort.indexOf('-') > 0;
    this.ui.destPort.prop('disabled', isRange);
  },

  /**
   * Handles the user switching between "single port" and "range of ports" modes
   *
   * @param {Object} model
   * @param {String} value
   */
  onChangeSrcPortRange: function(model, value) {
    if (value.indexOf('-') > 0) {
      this.ui.destPort.val('');
    } else {
      this.model.set('destPort', value);
      this.ui.destPort.val(this.model.get('destPort'));
    }
    this.toggleDestPort(value);
  },

  /**
   * Decorates the view to indicate error.
   *
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

  /**
   * Remove an error message from the view (if present)
   *
   * @param {String} key
   *   Identifier for the error message
   */
  clearError: function(key) {
    this.ui[key].bs3ui('clearGroupedFieldError', key, this.ui.messages);
  },

  /**
   * Clear any "special" errors related to port conflicts
   */
  onNoConflicts: function() {
    this.clearError('srcPortRange');
  },

  onEnabledChange: function(model, value) {
    this.isItemOff = !value;
    this.triggerMethod('onoff:status:change', this);
  },

});
