'use strict';

var _ = require('lodash');
var MultiActionItem = require('actions/shared/MultiActionItem');
var NetworkAddressSpecification = require('actions/networkObject/NetworkAddressSpecification');

/**
 * Edit model for Network Object.
 */
module.exports = MultiActionItem.extend({

  collectionModel: NetworkAddressSpecification,

/**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        if (!_.isUndefined(this.id)) {
          return config.get('firewall').get('networkObjects').get(this.id);
        }
      },
      events: 'change:specifications',
    },
  ],

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var items = [];
      var networkObjects = this.deviceConfig.get('firewall').get('networkObjects');
      if (!_.isUndefined(this.id) && !_.isUndefined(networkObjects.get(this.id))) {
        var specifications = networkObjects.get(this.id).get('specifications');
        if (specifications) {
          for (var i = 0; i < specifications.length; i++) {
            var item = {};
            item.addrSpec = specifications[i];
            if (item.addrSpec.indexOf('/') > 0) {
              item.specType = NetworkAddressSpecification.SUBNET;
            } else if (item.addrSpec.indexOf('-') > 0) {
              item.specType = NetworkAddressSpecification.RANGE;
            } else {
              item.specType = NetworkAddressSpecification.ADDRESS;
            }
            items.push(item);
          }
        }
      }

      return {
        items: items,
      };
    }

    return resp;
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.isNew() || this.getSnapshotDiff()) {
      var specifications = [];

      this.get('items').forEach(function(entry) {
        specifications.push(entry.get('addrSpec'));
      });

      return {
        name: 'networkObject.createOrEditNetworkObject',
        data: {
          id: this.id,
          specs: specifications,
        },
      };
    }
  },

});
