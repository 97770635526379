'use strict';

var $ = require('jquery');
var BaseGroup = require('config/Group');
var RenderPositions = require('config/RenderPositions');
var i18n = require('i18next');
var Radio = require('backbone.radio');
var apiChannel = Radio.channel('apiChannel');
var socketChannel = Radio.channel('socket');

var ACTION_NAME = 'alertConfiguration';

module.exports = BaseGroup.extend({
  actionList: [
    {
      name: ACTION_NAME,
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  isRequired: function() {
    return true;
  },

  title: function() {
    return i18n.t('groups.alertConfiguration');
  },

  /**
   * Saves email addresses, and alert configuration
   *
   * When the API call has either failed or succeeded the
   * appropriate status for `jobResults` is set in order
   * for the SaveChanges behavior to trigger the correct methods
   *
   * Additionally, we need a new, random `id` each time so that the
   * SaveChange behavior listener on `jobResults` fires
   *
   * @param {Object} options
   * @returns {jQuery.Promise}
   */
  save: function(options) {
    var self = this;
    var opts = options || {};
    var randomId = Date.now();

    return this._validateActions(opts).then(function() {
      return $.when(self.saveEmails(), self.saveAlerts())
      .done(function() {
        self.listenToOnce(socketChannel, {
          'device-configuration': function() {
            this.set('jobResults', [{
              id: randomId,
              status: 'applied',
            }]);
          },
        });
      }).fail(function() {
        self.set('jobResults', [{
          id: randomId,
          status: 'failed',
        }]);
      });
    });
  },

  saveAlerts: function() {
    var actionModel = this.get('actions').get(ACTION_NAME).get('actionModel');

    return apiChannel.request('send', 'DNA.Portal.Alerts.setOptOuts', {
      mac: actionModel.deviceMac,
      optOutIds: actionModel.get('alerts').where({subscribed: false}).map(function(item) {
        return item.get('id');
      }),
    });
  },

  saveEmails: function() {
    var actionModel = this.get('actions').get(ACTION_NAME).get('actionModel');
    var subs = actionModel.get('subscribers');

    return apiChannel.request('send', 'DNA.Portal.Alerts.setEmailsWithPriority', {
      mac: actionModel.deviceMac,
      subs: subs,
    });
  },
});
