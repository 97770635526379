'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/configClone/static.html');
var twig = require('twig').twig;
var DNARecordView = require('actions/configClone/DNARecord/StaticDNARecordView');

var DNARecordListView = Marionette.CollectionView.extend({
  childView: DNARecordView,
});

/**
 * Renders a text-only version of the "configClone" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/configClone/StaticView#model
   * @type {actions/configClone/ConfigClone}
   */

  template: twig({data: tpl}),

  regions: {
    devices: '.rg-dna-record-static',
  },

  /**
   * Provide necessary data to render template.
   * @returns {Object}
   */
  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({
      deviceCount: this.model.get('items').length,
    }, baseHelpers);
  },

  /**
   * Initialize the child views on render.
   */
  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.showChildView('devices', new DNARecordListView({
      collection: this.model.get('items'),
    }));
  },
});
