'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var ActionItem = require('actions/shared/ActionItem');
var collectionSnapshotMixin = require('lib/mixins/snapshotCollection');

/**
 * Base View-Model for action items that wrap a collection of lightweight
 * configuration models that the user can add and remove.
 *
 * This class is meant to be extended and must not be used directly. To
 * extend, supply a collectionModel property and a parse() function
 * that produces an object with an "items" array, populated with raw
 * objects derived from an appropriate location in the deviceConfig
 * (when options.fromConfig === true)
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} #attributes
   * @property {Backbone.Collection} items
   *   Collection containing configuration models
   */

  _snapshotAttributes: ['items'],

  /**
   * The model type (constructor) to be used within the collection
   * (required). Must implement the methods provided in
   * {lib/mixins/snapshotMixin} as well as getTask().
   *
   * @type {Function}
   */
  collectionModel: null,

  /**
   * Creates the wrapped collection upon instantiation
   *
   * * @throws Error
   *   Thrown if "collectionModel" property not set.
   * @param {Object} attributes
   * @param {Object} options
   */
  constructor: function(attributes, options) {
    var CollectionType;
    var attrs = attributes || {};

    if (!this.collectionModel) {
      throw new Error('Extending MultiActionItem requires setting a collectionModel');
    }

    CollectionType = Backbone.Collection.extend(collectionSnapshotMixin).extend({
      model: this.collectionModel,
    });

    attrs.items = new CollectionType(attrs.items, _.extend({}, options, {parse: true}));

    ActionItem.call(this, attrs, options);
  },

  /**
   * Overridden to push collection contents down to the wrapped collection
   *
   * @param {String|Object} key
   * @param {mixed} val
   * @param {Object|undefined} options
   * @return {Object} self
   */
  set: function(key, val, options) {
    // normalize key, value vs. {key: value} style - from Backbone source
    var attrs;
    if (typeof key === 'object') {
      attrs = key;
      options = val;
    } else {
      (attrs = {})[key] = val;
    }
    // end normalize

    if (this.has('items') && 'items' in attrs) {
      var collectionOptions = _.extend({parse: true}, options);
      this.get('items').set(attrs.items, collectionOptions);
      attrs = _.omit(attrs, 'items');
    }

    return ActionItem.prototype.set.call(this, attrs, options);
  },

  /**
   * Overridden to serialize wrapped collection correctly.
   *
   * @param {Object} options
   * @return {Object}
   */
  toJSON: function(options) {
    var data = ActionItem.prototype.toJSON.apply(this, arguments);

    data.items = this.get('items').toJSON();

    return data;
  },

  /**
   * Returns the result of validating all contained models. If any
   * are invalid, this wrapping model will be invalid.
   *
   * As a convenience for models that need to coordinate amongst
   * themselves to check for conflicts, passes options.allItems
   * to child model validate() calls, which is set to an array of
   * all models in the wrapped collection.
   *
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};
    var collection = this.get('items');

    // provide each item with the full list so they can check for conflicts
    var validationOpts = _.extend({}, options, {allItems: collection.models});

    var itemsStatus = collection.countBy(function(item) {
      return item.isValid(validationOpts) ? 'valid' : 'invalid';
    });

    if (itemsStatus.invalid > 0) {
      errors.invalidChildCount = itemsStatus.invalid;
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Get task for each model in the wrapped collection, if applicable
   *
   * @see config/Actions#getTasks
   * @return {Array|undefined}
   */
  getTask: function() {
    var tasks = this.get('items').reduce(function(memo, item) {
      var task = item.getTask();
      if (task) {
        memo = memo.concat(task);
      }
      return memo;
    }, []);

    if (tasks.length > 0) {
      return tasks;
    }
  },

  /**
   * Overridden to get the diff of the wrapped collection. Note,
   * all other model attributes are ignored.
   *
   * @return {Boolean|Object}
   */
  getSnapshotDiff: function() {
    return this.get('items').getSnapshotDiff();
  },

  /**
   * Overridden to call takeSnapshot() on the wrapped collection and individual models.
   */
  takeSnapshot: function() {
    var collection = this.get('items');
    collection.invoke('takeSnapshot');
    collection.takeSnapshot();
    ActionItem.prototype.takeSnapshot.apply(this, arguments);
  },

  /**
   * Overridden to call applySnapshot() on the wrapped collection and individual models.
   */
  applySnapshot: function() {
    var collection = this.get('items');
    collection.invoke('applySnapshot');
    collection.applySnapshot();
    ActionItem.prototype.applySnapshot.apply(this, arguments);
  },
});
