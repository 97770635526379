'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/configClone/DNARecord/DNARecordForm.html');
var twig = require('twig').twig;
var i18n = require('i18next');
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
var tooltipTpl = require('manage/edit/toolbox/tooltip.html');
require('lib/jquery/bootstrapUI');

/**
 * Renders the editable form elements for a single DNA record
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/configClone/FormView#model
   * @type {actions/configClone/DNARecord/DNARecord}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),
  tooltipTemplate: twig({data: tooltipTpl}),

  ui: {
    dnaSelectorContainer: '[name="dnaSelectorContainer"]',
    dnaSelector: '[name="dnaSelector"]',
  },

  events: {
    'click @ui.dnaSelectorContainer': 'updateModel',
    'click @ui.dnaSelector': 'updateModel',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    return _.extend({
      tooltip: this.getToolTip.bind(this),
    }, tplHelpers.apply(this));
  },

  onRender: function() {
    this.ui.dnaSelectorContainer.tooltip({trigger: 'hover'});
  },

  /**
   * Sync changes in UI input elements to the model and trigger validation
   *
   * @param {Object} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;

    switch (name) {
      case 'dnaSelector':
        this.model.set({'selected': event.currentTarget.checked});
        break;
      default:
        break;
    }
  },

  getToolTip: function() {
    if (!this.model.get('allowed')) {
      return this.tooltipTemplate.render({reason: i18n.t('configClone.dnaDisabledReason')});
    }
  },
});
