'use strict';

var i18n = require('i18next');

module.exports = {
  getAppLabel: function(name) {
    switch (name) {
      // from https://github.com/cloudtrax/localization/blob/8658887dcf544c7d08ac650fd745448fe8ef664e/en.json#L287
      case 'hbogo': return i18n.t('layer7.apps.hbogo');
      case 'showtime': return i18n.t('layer7.apps.showtime');
      case 'amazonvideo': return i18n.t('layer7.apps.amazonvideo');
      case 'bittorrent': return i18n.t('layer7.apps.bittorrent');
      case 'kazaa': return i18n.t('layer7.apps.kazaa');
      case 'gnutella': return i18n.t('layer7.apps.gnutella');
      case 'edonkey': return i18n.t('layer7.apps.edonkey');
      case 'dcpp': return i18n.t('layer7.apps.dcpp');
      case 'dropbox': return i18n.t('layer7.apps.dropbox');
      case 'dropbox_lansync': return i18n.t('layer7.apps.dropbox_lansync');
      case 'ibackup': return i18n.t('layer7.apps.ibackup');
      case 'backblaze': return i18n.t('layer7.apps.backblaze');
      case 'mozy': return i18n.t('layer7.apps.mozy');
      case 'rapidshare': return i18n.t('layer7.apps.rapidshare');
      case 'megashare': return i18n.t('layer7.apps.megashare');
      case 'ultrashare': return i18n.t('layer7.apps.ultrashare');
      case 'filefactory': return i18n.t('layer7.apps.filefactory');
      case 'gamefront': return i18n.t('layer7.apps.gamefront');
      case 'massmirror': return i18n.t('layer7.apps.massmirror');
      case 'uploadcom': return i18n.t('layer7.apps.uploadcom');
      case 'downloadcom': return i18n.t('layer7.apps.downloadcom');
      case 'thepiratebay': return i18n.t('layer7.apps.thepiratebay');
      case 'torrentzeu': return i18n.t('layer7.apps.torrentzeu');
      case 'filestube': return i18n.t('layer7.apps.filestube');
      case '4shared': return i18n.t('layer7.apps.4shared');
      case 'easynews': return i18n.t('layer7.apps.easynews');
      case 'hotfile': return i18n.t('layer7.apps.hotfile');
      case 'zshare': return i18n.t('layer7.apps.zshare');
      case 'mediafire': return i18n.t('layer7.apps.mediafire');
      case 'googlesocial': return i18n.t('layer7.apps.googlesocial');
      case 'flickr': return i18n.t('layer7.apps.flickr');
      case 'smugmug': return i18n.t('layer7.apps.smugmug');
      case 'imgurcom': return i18n.t('layer7.apps.imgurcom');
      case 'photobucketcom': return i18n.t('layer7.apps.photobucketcom');
      case 'imageshack': return i18n.t('layer7.apps.imageshack');
      case 'imgly': return i18n.t('layer7.apps.imgly');
      case 'instagram': return i18n.t('layer7.apps.instagram');
      case 'yfrog': return i18n.t('layer7.apps.yfrog');
      case 'twitpic': return i18n.t('layer7.apps.twitpic');
      case 'yelp': return i18n.t('layer7.apps.yelp');
      case 'myspace': return i18n.t('layer7.apps.myspace');
      case 'twitter': return i18n.t('layer7.apps.twitter');
      case 'facebook': return i18n.t('layer7.apps.facebook');
      case 'studivz': return i18n.t('layer7.apps.studivz');
      case 'wikipedia': return i18n.t('layer7.apps.wikipedia');
      case 'google': return i18n.t('layer7.apps.google');
      case 'aboutcom': return i18n.t('layer7.apps.aboutcom');
      case 'askcom': return i18n.t('layer7.apps.askcom');
      case 'wikileaks': return i18n.t('layer7.apps.wikileaks');
      case 'ebay': return i18n.t('layer7.apps.ebay');
      case 'linkedin': return i18n.t('layer7.apps.linkedin');
      case 'wordpresscom': return i18n.t('layer7.apps.wordpresscom');
      case 'wordpressorg': return i18n.t('layer7.apps.wordpressorg');
      case 'amazon': return i18n.t('layer7.apps.amazon');
      case 'bing': return i18n.t('layer7.apps.bing');
      case 'yahoo': return i18n.t('layer7.apps.yahoo');
      case 'msn': return i18n.t('layer7.apps.msn');
      case 'blogger': return i18n.t('layer7.apps.blogger');
      case 'skype': return i18n.t('layer7.apps.skype');
      case 'teamspeak': return i18n.t('layer7.apps.teamspeak');
      case 'ventrilo': return i18n.t('layer7.apps.ventrilo');
      case 'sip': return i18n.t('layer7.apps.sip');
      case 'yahoomail': return i18n.t('layer7.apps.yahoomail');
      case 'googlemail': return i18n.t('layer7.apps.googlemail');
      case 'hotmail': return i18n.t('layer7.apps.hotmail');
      case 'hostbasedemail': return i18n.t('layer7.apps.hostbasedemail');
      case 'ign': return i18n.t('layer7.apps.ign');
      case 'miniclip': return i18n.t('layer7.apps.miniclip');
      case 'gamespot': return i18n.t('layer7.apps.gamespot');
      case 'bigfishgames': return i18n.t('layer7.apps.bigfishgames');
      case 'zynga': return i18n.t('layer7.apps.zynga');
      case 'pogo': return i18n.t('layer7.apps.pogo');
      case 'worldofwarcraft': return i18n.t('layer7.apps.worldofwarcraft');
      case 'battlenet': return i18n.t('layer7.apps.battlenet');
      case 'playstation': return i18n.t('layer7.apps.playstation');
      case 'speedtestnet': return i18n.t('layer7.apps.speedtestnet');
      case 'youtube': return i18n.t('layer7.apps.youtube');
      case 'ustream': return i18n.t('layer7.apps.ustream');
      case 'rtmp_ustream': return i18n.t('layer7.apps.rtmp_ustream');
      case 'rhapsody': return i18n.t('layer7.apps.rhapsody');
      case 'rtmp_rhapsody': return i18n.t('layer7.apps.rtmp_rhapsody');
      case 'xfinity': return i18n.t('layer7.apps.xfinity');
      case 'rtmp_xfinity': return i18n.t('layer7.apps.rtmp_xfinity');
      case 'livestream': return i18n.t('layer7.apps.livestream');
      case 'rtmp_livestream': return i18n.t('layer7.apps.rtmp_livestream');
      case 'shoutcast': return i18n.t('layer7.apps.shoutcast');
      case 'spotify': return i18n.t('layer7.apps.spotify');
      case 'itunes': return i18n.t('layer7.apps.itunes');
      case 'bliptv': return i18n.t('layer7.apps.bliptv');
      case 'dailymotion': return i18n.t('layer7.apps.dailymotion');
      case 'grooveshark': return i18n.t('layer7.apps.grooveshark');
      case 'hulu': return i18n.t('layer7.apps.hulu');
      case 'rtmp_hulu': return i18n.t('layer7.apps.rtmp_hulu');
      case 'lastfm': return i18n.t('layer7.apps.lastfm');
      case 'pandora': return i18n.t('layer7.apps.pandora');
      case 'rdio': return i18n.t('layer7.apps.rdio');
      case 'soundcloud': return i18n.t('layer7.apps.soundcloud');
      case 'vimeo': return i18n.t('layer7.apps.vimeo');
      case 'netflix': return i18n.t('layer7.apps.netflix');
      case 'webcam': return i18n.t('layer7.apps.webcam');
      case 'twitchtv': return i18n.t('layer7.apps.twitchtv');
      case 'justintv': return i18n.t('layer7.apps.justintv');
      case 'facetime': return i18n.t('layer7.apps.facetime');
      case 'microsoftds': return i18n.t('layer7.apps.microsoftds');
      case 'netbios': return i18n.t('layer7.apps.netbios');
      case 'dhcp': return i18n.t('layer7.apps.dhcp');
      case 'gre': return i18n.t('layer7.apps.gre');
      case 'snmp': return i18n.t('layer7.apps.snmp');
      case 'upnp': return i18n.t('layer7.apps.upnp');
      case 'dns': return i18n.t('layer7.apps.dns');
      case 'afp': return i18n.t('layer7.apps.afp');
      case 'ssl': return i18n.t('layer7.apps.ssl');
      case 'http': return i18n.t('layer7.apps.http');
      case 'tcp': return i18n.t('layer7.apps.tcp');
      case 'udp': return i18n.t('layer7.apps.udp');
      case 'igmp': return i18n.t('layer7.apps.igmp');
      case 'ftp': return i18n.t('layer7.apps.ftp');
      case 'icmp': return i18n.t('layer7.apps.icmp');
      case 'http_video': return i18n.t('layer7.apps.http_video');
      case 'http_audio': return i18n.t('layer7.apps.http_audio');
      case 'unclassified': return i18n.t('layer7.apps.unclassified');
      case 'nytimes': return i18n.t('layer7.apps.nytimes');
      case 'techcrunch': return i18n.t('layer7.apps.techcrunch');
      case 'gizmodo': return i18n.t('layer7.apps.gizmodo');
      case 'sinacomcn': return i18n.t('layer7.apps.sinacomcn');
      case 'foxnews': return i18n.t('layer7.apps.foxnews');
      case 'cnn': return i18n.t('layer7.apps.cnn');
      case 'turner': return i18n.t('layer7.apps.turner');
      case 'tumblr': return i18n.t('layer7.apps.tumblr');
      case 'ah': return i18n.t('layer7.apps.ah');
      case 'amazonmusic': return i18n.t('layer7.apps.amazonmusic');
      case 'esp': return i18n.t('layer7.apps.esp');
      case 'ipip': return i18n.t('layer7.apps.ipip');
      case 'l2tp': return i18n.t('layer7.apps.l2tp');
      case 'rtmp_aboutcom': return i18n.t('layer7.apps.rtmp_aboutcom');
      case 'sctp': return i18n.t('layer7.apps.sctp');
      case 'udplite': return i18n.t('layer7.apps.udplite');
      case 'vocera': return i18n.t('layer7.apps.vocera');
      case 'xboxlive': return i18n.t('layer7.apps.xboxlive');

      // additional values from muciri
      case 'adserver': return i18n.t('layer7.apps.adserver');
      case 'brightcove': return i18n.t('layer7.apps.brightcove');
      case 'icloud': return i18n.t('layer7.apps.icloud');
      case 'onedrive': return i18n.t('layer7.apps.onedrive');
      case 'steam': return i18n.t('layer7.apps.steam');

      default: return undefined;
    }
  },
  getTypeLabel: function(type) {
    switch (type) {
      // from https://github.com/cloudtrax/localization/blob/8658887dcf544c7d08ac650fd745448fe8ef664e/en.json#L262
      case 1: return i18n.t('layer7.types.1');
      case 2: return i18n.t('layer7.types.2');
      case 3: return i18n.t('layer7.types.3');
      case 4: return i18n.t('layer7.types.4');
      case 5: return i18n.t('layer7.types.5');
      case 6: return i18n.t('layer7.types.6');
      case 7: return i18n.t('layer7.types.7');
      case 8: return i18n.t('layer7.types.8');
      case 9: return i18n.t('layer7.types.9');
      case 10: return i18n.t('layer7.types.10');
      case 11: return i18n.t('layer7.types.11');
      case 12: return i18n.t('layer7.types.12');
      case 13: return i18n.t('layer7.types.13');
      case 14: return i18n.t('layer7.types.14');
      case 15: return i18n.t('layer7.types.15');
      case 16: return i18n.t('layer7.types.16');
      case 17: return i18n.t('layer7.types.17');
      case 18: return i18n.t('layer7.types.18');
      case 19: return i18n.t('layer7.types.19');
      case 20: return i18n.t('layer7.types.20');
      case 21: return i18n.t('layer7.types.21');
      case 22: return i18n.t('layer7.types.22');
      case 23: return i18n.t('layer7.types.23');
      case 24: return i18n.t('layer7.types.24');
      case 25: return i18n.t('layer7.types.25');

      default: return undefined;
    }
  },
};
