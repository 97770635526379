'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/outboundNat/rule.html');
var RenderChanges = require('lib/behaviors/RenderChanges');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');

module.exports = Marionette.View.extend({
  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    name: '[name="name"]',
    srcType: '[name="src-type"]',
    srcIp: '[name="src-ip"]',
    enabled: '[name="enabled"]',
    messages: '.help-block',
    remove: '.btn-remove',
  },

  events: {
    'change @ui.name': 'updateName',
    'change @ui.srcIp': 'updateSrcIp',
    'change @ui.enabled': 'updateEnabled',
    'change @ui.srcType': 'updateType',
    'click @ui.remove': 'removeNat',
  },

  modelEvents: {
    'invalid': 'onError',
    'change:remove': 'render',
    'change:enabled': 'onEnabledChange',
  },

  onBeforeRender: function() {
    this.isItemOff = !this.model.get('enabled');
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var context = {
      ips: this.options.ips,
    };

    return _.extend(context, tplHelpers.apply(this));
  },

  updateName: function(ev) {
    var value = ev.currentTarget.value;
    this.ui.name.bs3ui('clearGroupedFieldError', 'name', this.ui.messages);
    this.model.set({'name': value}, {commit: true});
  },

  updateType: function(ev) {
    var value = ev.currentTarget.value;
    this.ui.srcIp.bs3ui('clearGroupedFieldError', 'srcIp', this.ui.messages);
    this.model.set({'type': value}, {commit: true});
  },

  updateSrcIp: function(ev) {
    var value = ev.currentTarget.value;
    this.ui.srcIp.bs3ui('clearGroupedFieldError', 'srcIp', this.ui.messages);
    this.model.set({'srcIp': value}, {commit: true});
  },

  updateEnabled: function(ev) {
    this.model.set({enabled: ev.currentTarget.checked});
  },

  /**
   * Decorates the view to indicate error.
   *
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

  removeNat: function() {
    if (this.model.isNew()) {
      this.model.collection.remove(this.model);
    } else {
      var shouldRemove = !this.model.get('remove');

      if (shouldRemove) {
        this.model.applySnapshot();
      }

      this.model.set({remove: shouldRemove});
    }
  },

  onEnabledChange: function(model, value) {
    this.isItemOff = !value;
    this.triggerMethod('onoff:status:change', this);
  },
});
