'use strict';

var _ = require('lodash');
var Memento = require('backbone.memento');

/**
 * Properties and methods for allowing a Model to take a snapshot of itself.
 *
 * Backbone provides methods for checking what attributes have changed, but
 * only since the last change event (e.g. set()). Taking a snapshot allows
 * checking what attributes have changed over multiple change events.
 *
 * @example
 * var Person = Backbone.Model.extend(snapshotMixin).extend({
 *   _snapshotAttributes: ['name', 'age'],
 * });
 *
 * var jane = new Person({name: 'Jane'});
 * jane.takeSnapshot();
 * jane.set('name', 'Janice');
 * jane.getSnapshotDiff(); //{name: 'Janice'}
 * jane.getFromSnapshot('name');  //'Jane'
 *
 * @mixin lib/mixins/snapshot
 */

module.exports = {
  /**
   * Stack structure for model attributes. Primarily used for tracking model
   * changes against an initial snapshot.
   *
   * @member {Backbone.Memento} lib/mixins/snapshot#snapshot
   */

  /**
   * List of attributes indicating the Model's core user-data.
   *
   * @private
   * @see lib/mixins/snapshot#getSnapshotDiff
   * @member {Array} lib/mixins/snapshot#_snapshotAttributes
   */

  /**
   * Takes a snapshot of the current model's attributes.
   *
   * Note, this method bastardizes Backbone.Memento a little. That plugin
   * allows you to store any number of state changes on a stack. In this
   * context, taking a snapshot resets the stack.
   */
  takeSnapshot: function() {
    if (_.isEmpty(this._snapshotAttributes)) {
      throw new Error('Model requires list of attributes to snapshot');
    }

    // Snapshot object is always reinitialized when a snapshot is taken. This
    // is by design.
    this.snapshot = new Memento(this, {parse: true});

    this.snapshot.store();
  },

  /**
   * Replaces the model's state with that of the Model's snapshot. If a snapshot
   * has not been taken, the model is unchanged.
   */
  applySnapshot: function() {
    if (_.isUndefined(this.snapshot)) {
      return;
    }

    this.snapshot.restart();
    this.takeSnapshot();
  },

  /**
   * Returns a diff of attributes that have changed since the last-taken
   * snapshot, or False if the attributes have not been changed.
   *
   * The diff will contain the current values. If an attribute existed in the
   * snapshot, but no longer exists in the Model, it will be included in the
   * diff with a value of undefined.
   *
   * @return {Object|false}
   */
  getSnapshotDiff: function() {
    var changes;
    var attrsWhitelist = this._snapshotAttributes;

    if (_.has(this, 'snapshot')) {
      changes = this.snapshot.changes();
    }

    if (!_.isUndefined(changes)) {
      changes = _.pick(changes, attrsWhitelist);

      if (!_.isEmpty(changes)) {
        return changes;
      }
    }

    return false;
  },

  /**
   * Gets a single attribute value from the last-taken snapshot.
   *
   * @param {String} attribute
   * @return {mixed}
   */
  getFromSnapshot: function(attribute) {
    var previous;

    if (_.indexOf(this._snapshotAttributes, attribute) === -1) {
      throw new Error('Attribute "' + attribute + '" is not white-listed');
    }

    if (_.has(this, 'snapshot')) {
      previous = this.snapshot.previousState();
      if (!_.isUndefined(previous)) {
        return previous[attribute];
      }
    }
  },

  /**
   * Gets the last-taken snapshot.
   *
   * @return {Object}
   */
  getLastSnapshot: function() {
    var previous;

    if (_.isUndefined(this.snapshot)) {
      return {};
    }

    previous = this.snapshot.previousState();

    if (_.isUndefined(previous)) {
      return {};
    }

    return previous;
  },
};
