'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('lib/behaviors/pagination/pagination.html');
var twig = require('twig').twig;

module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  templateContext: function() {
    var params = this.options.params;

    var page = params.page;
    var count = params.maxVisiblePages;
    var itemsPerPage = params.itemsPerPage;
    var shift = params.shift;

    var total = params.getItems().length;
    var pages = params.getMaxPage();
    var offset = page - Math.ceil(count / 2);
    var maxOffset = Math.max(1, (pages - count));
    var lastPage = Math.min(pages, (Math.max(1, offset) + count));

    return {
      firstPage: function() {
        if (offset > maxOffset) {
          return maxOffset;
        }

        return Math.max(1, offset);
      },
      lastPage: lastPage,
      currentPage: page,
      showing: function() {
        return {
          start: ((page - 1) * itemsPerPage) + 1,
          end: Math.min(((page - 1) * itemsPerPage) + itemsPerPage, total - shift),
          total: total - shift,
        };
      },
    };
  },
});
