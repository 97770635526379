'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var ConnectedDeviceView = require('components/connectedDevicesList/ConnectedDeviceView');
var tpl = require('components/connectedDevicesList/connected-device-layout.html');

var EmptyView = Marionette.View.extend({
  className: 'rs-table-body',

  initialize: function(options) {
    this.vlanId = options.vlanId;
    this.deviceConfig = options.deviceConfig;
    this.deviceStatus = options.deviceStatus;
    this.listenTo(this.deviceStatus.get('failover'), 'change:activeWan', this.getTemplate);
  },

  getTemplate: function() {
    if (!this._isWan()) {
      return twig({data: i18n.t('itemsList.noConnectedDevicesLan')});
    }

    if (this._isActiveWan()) {
      return twig({data: i18n.t('itemsList.wanActiveNoDevicesConnected')});
    }

    return twig({data: i18n.t('itemsList.inactiveWanNoDevices')});
  },

  _isWan: function() {
    if (this.deviceConfig.get('networks').get(this.vlanId)) {
      return this.deviceConfig.get('networks').get(this.vlanId).isWan();
    }

    return false;
  },

  _isActiveWan: function() {
    if (this.deviceConfig.get('networks').get(this.vlanId)) {
      return this.deviceConfig.get('networks').get(this.vlanId).isActiveWan(this.deviceStatus);
    }

    return false;
  },
});

/**
 * Simple View for displaying the list of devices connected to one or more of
 * a DNA's LANs.
 */
module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  ui: {
    'tableHeaders': '.rs-table-row-header',
    'tableBadgeHeader': '.rs-table-badge-header',
  },

  className: 'rs-table',

  regions: {
    devicesList: {
      el: '.rg-devices-detail-list',
      replaceElement: true,
    },
  },

  /**
   * @type {Marionette.View}
   */

  onRender: function() {
    /**
     * @type {components/connectedDevicesList/ConnectedDeviceView}
     */
    this.collection.models = this.collection.sortBy('hostname', function(hostname) {
      return hostname.toLowerCase();
    });

    var DeviceListView = Marionette.CollectionView.extend({
      collection: this.collection,
      childView: ConnectedDeviceView,
      emptyView: EmptyView,
      emptyViewOptions: {
        vlanId: this.options.vlanId,
        deviceConfig: this.options.deviceConfig,
        deviceStatus: this.options.deviceStatus,
      },
      className: 'rs-table-body',
      onChildviewConnectedDeviceSelected: function(childView) {
        this.triggerMethod('connectedDevice:selected', childView);
      },
      onChildviewNetworkViewSelected: function(childView) {
        this.triggerMethod('networkView:selected', childView);
      },
    });

    this.toggleHeaders();
    this.toggleBadgeHeader();
    this.listenTo(this.collection, 'reset add remove', this.toggleHeaders);
    this.showChildView('devicesList', new DeviceListView(this.options));
  },

  toggleBadgeHeader: function() {
    if (this.options.deviceConfig.get('networks').get(this.options.vlanId).isWan()) {
      this.ui.tableBadgeHeader.removeClass('hidden');
    } else {
      this.ui.tableBadgeHeader.addClass('hidden');
    }
  },

  toggleHeaders: function() {
    this.ui.tableHeaders.toggleClass('hidden', this.collection.length === 0);
  },
});
