'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var networkUtils = require('lib/network');

/**
 * Represents an individual static host mapping
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({

  /**
   * @member {Object} #attributes
   * @property {Boolean} remove
   *   Whether the hostMapping should be removed on save
   * @property {String} id
   *   ID of the hostMapping
   * @property {String} hostname
   *   Name of the host
   * @property {String} ip
   *   IP address to map
   */

  _snapshotAttributes: [
    'remove',
    'hostname',
    'ip',
  ],

  defaults: {
    remove: false,
    hostname: null,
    ip: null,
  },

  /**
   * @fires noConflicts
   *   Validation has determined this rule is free of conflicts with other rules
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true) {
      return;
    }

    if (_.has(attrs, 'hostname')) {
      if (!attrs.hostname || !attrs.hostname.trim()) {
        errors.hostname = i18n.t('staticHostMapping.missingHostName');
      } else if (!networkUtils.validHostname(attrs.hostname)) {
        errors.hostname = i18n.t('staticHostMapping.invalidHostName');
      }
    }

    if (_.has(attrs, 'ip')) {
      if (!attrs.ip || !attrs.ip.trim()) {
        errors.ip = i18n.t('staticHostMapping.missingIpAddress');
      } else if (!networkUtils.validIP(attrs.ip)) {
        errors.ip = i18n.t('staticHostMapping.invalidIpAddress');
      }
    }

    if (_.has(attrs, 'hostname') && _.has(attrs, 'ip')) {
      var cid = this.cid;
      var duplicates = this.collection.filter(function(mapping) {
        return mapping.cid !== cid && mapping.get('ip') === attrs.ip && mapping.get('hostname') === attrs.hostname;
      });
      if (duplicates.length > 0) {
        errors.hostname = i18n.t('staticHostMapping.duplicateMapping');
      } else {
        this.trigger('noConflicts');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return {
        name: 'dns.deleteStaticHost',
        data: {
          id: this.id,
        },
      };
    }

    if (this.isNew() || this.getSnapshotDiff()) {
      var task = {
        name: 'dns.createOrEditStaticHost',
        data: {
          hostname: this.get('hostname'),
          ip: this.get('ip'),
        },
      };
      if (!this.isNew()) {
        task.data.id = this.id;
      }
      return task;
    }
  },
});
