'use strict';

var BaseGroup = require('config/Group');
var RenderPositions = require('config/RenderPositions');
var i18n = require('i18next');

/**
 * NAT configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'onemanyNat',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.onemanyNat');
  },

  shouldMergeTasks: false,
});
