'use strict';

var _ = require('lodash');
var rpcMixin = require('lib/mixins/rpc2');
var ReadOnly = require('backbone.readonly');
var filterNetworks = require('lib/mixins/filterNetworks');
var filterSubnets = require('lib/mixins/filterSubnets');
var filterDhcpPools = require('lib/mixins/filterDhcpPools');
var filterDhcpRelays = require('lib/mixins/filterDhcpRelays');
var nestedModel = require('lib/mixins/nestedModel');
var NetworkModel = require('lib/models/Network');
var VpnModel = require('lib/models/Vpn');
var FirewallModel = require('lib/models/Firewall');

var NetworksList = ReadOnly.Collection.extend({
  model: NetworkModel,
});
var VpnsList = ReadOnly.Collection.extend({
  model: VpnModel,
});

var SchedulesList = ReadOnly.Collection.extend({
  // right now we only support 1 schedule of a given type,
  // so 'type' is the id. add a real 'id' if this changes
  modelId: function(attrs) {
    return attrs.type;
  },
});

var Model = ReadOnly.Model
  .extend(rpcMixin)
  .extend(nestedModel)
  .extend(filterNetworks)
  .extend(filterSubnets)
  .extend(filterDhcpPools)
  .extend(filterDhcpRelays);

/**
 */
module.exports = Model.extend({
  /**
   * @member {Object} lib/models/DeviceConfiguration#attributes
   * @property {String} deviceMac
   * @property {Number} timestamp
   *   When the configuration was made.
   * @property {ReadOnly.Collection} networks
   *  The WAN/LAN/LTE/WiFi networks associated with the device. Note, each
   *  Model is an instance of {@link lib/models/Network}.
   * @property {ReadOnly.Collection} subnets
   *  The subnets setup for a device
   * @property {ReadOnly.Collection} dhcpPools
   *  DhcpPools setup on the device
   * @property {ReadOnly.Collection} dhcpRelays
   *  DhcpRelays setup on the device
   * @property {ReadOnly.Collection} dns
   *  DNS setting setup per network on the device
   * @property {ReadOnly.Model} router
   *  General router settings for the device
   * @property {ReadOnly.Model} wifi
   *  Global wifi settings for the device
   * @property {ReadOnly.Model} firewall
   *  Firewall settings for the device
   * @property {ReadOnly.Model} nicBonds
   *  Port Aggregation settings for the device
   */

  rpc: {
    read: [
      {
        method: 'DNA.Portal.Device.getConfiguration',
        params: function() {
          return {
            mac: this.get('deviceMac'),
          };
        },
      },
    ],
  },

  /**
   * Registry of attribute types.
   *
   * @private
   * @type {Object}
   */
  attributeTypes: {
    networks: NetworksList,
    subnets: ReadOnly.Collection,
    dhcpPools: ReadOnly.Collection,
    dhcpRelays: ReadOnly.Collection,
    dns: ReadOnly.Collection,
    router: ReadOnly.Model,
    wifi: ReadOnly.Model,
    vpnUsers: ReadOnly.Collection,
    remoteSites: VpnsList,
    openVpn: ReadOnly.Model,
    firewall: FirewallModel,
    outboundNat: ReadOnly.Collection,
    intrusionDetection: ReadOnly.Model,
    webFilters: ReadOnly.Model,
    webFiltersTitanHq: ReadOnly.Model,
    dhcpAdvOptions: ReadOnly.Collection,
    dhcpReservations: ReadOnly.Collection,
    lanAllowVpn: ReadOnly.Model,
    schedule: SchedulesList,
    networkQos: ReadOnly.Collection,
    conditionalDNS: ReadOnly.Collection,
    alertSubscriptions: ReadOnly.Collection,
    alerts: ReadOnly.Collection,
    dnsStaticHosts: ReadOnly.Collection,
    staticRoutes: ReadOnly.Collection,
    loadBalance: ReadOnly.Model,
    loadBalancePolicies: ReadOnly.Model,
    nicBonds: ReadOnly.Collection,
    ports: ReadOnly.Collection,
    failoverPolicyControl: ReadOnly.Model,
    operatorPing: ReadOnly.Model,
    globalDns: ReadOnly.Model,
  },

  /**
   * Take the returned configuration call and break it into collections
   * and models to be consumed by the UI.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    var self = this;
    var respClone;

    if (!_.isUndefined(resp.error)) {
      return {};
    }

    // clone to prevent mutating original parameter
    respClone = _.cloneDeep(resp);

    // data location depends on whether it is coming from an API call or eventsource message
    respClone = respClone.result || respClone;

    respClone.mac = respClone.mac.toUpperCase();

    _.each(this.attributeTypes, function(AttributeType, attrName) {
      if (self.has(attrName)) {
        self.get(attrName).set(respClone[attrName], options);
        delete respClone[attrName];
      } else {
        respClone[attrName] = new AttributeType(respClone[attrName], options);
      }
    });

    return respClone;
  },
});
