'use strict';

var TopItemsModel = require('components/usageShared/TopItemsModel');

/**
 * Model/loader for top app usage data
 */
module.exports = TopItemsModel.extend({

  rpc: {
    read: [
      {
        method: 'DNA.Portal.Bandwidth.getTopApps',
        params: function() {
          return {
            mac: this.get('deviceMac'),
          };
        },
      },
    ],
  },

  getMacError: function() {
    return 'TopAppsModel: deviceMac must be set before fetching';
  },
});
