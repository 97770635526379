'use strict';

// Note: If our needs become more sophisticated, we may want to replace
// these with a true formatting library like numeral.js
module.exports = {
  /**
   * Formats a given numerical bytes value into a nice
   * human-readable string like "22.4 MB".
   *
   * Note that this currently uses SI/base-10 multiples,
   * so 1 KB = 1000 B (not 1024 B)
   *
   * @param {Number} value
   * @returns {String}
   */
  formatBytes: function(value) {
    if (value === 0) {
      return value.toString();
    }

    var sign = value < 0 ? -1 : 1;
    var val = Math.abs(value);

    if (val >= 1000 && val < 1000000) {
      return sign * Math.floor(val / 100) / 10 + ' KB';
    }
    if (val >= 1000000 && val < 1000000000) {
      return sign * Math.floor(val / 100000) / 10 + ' MB';
    }
    if (val >= 1000000000) {
      return sign * Math.floor(val / 100000000) / 10 + ' GB';
    }

    return sign * val + ' B';
  },

  /**
   * Formats a fractional value like 0.1 into a human-
   * readable percentage like "10%"
   *
   * @param {Number} value
   * @returns {String}
   */
  formatPercent: function(value) {
    var sign = value < 0 ? -1 : 1;
    var val = Math.abs(value);

    return sign * Math.floor(val * 1000) / 10 + '%';
  },

  /**
   * Group values must be a positive integer
   * Any non-number or value less than 0 is set to 0
   * Any non-integer is set to a whole number
   *
   * @see {actions/siteVpn/DnaSite/DnaSiteFormView}
   * @param {string} val
   * @return {integer}
   */
  formatGroupValue: function(val) {
    if (isNaN(val) || val < 0) {
      return 0;
    }

    return parseInt(val) || 0;
  },
};
