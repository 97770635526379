'use strict';

var Radio = require('backbone.radio');
var console2 = require('lib/Console');

/**
 * Socket factory
 *
 * @param {Object} options
 * @return {Object}
 */
exports.factory = function(options) {
  return new Socket(options);
};

/**
 * Connect to real-time data EventSource provider.
 *
 * @class
 * @alias lib/Socket
 *
 * @param {Object} options
 * @property {String} options.url
 *   The EventSource URL
 */
function Socket(options) {
  this.url = options.url;
  this.mac = options.mac;
  this.debug = options.debug || false;
  this.withCredentials = (options.withCredentials === true);
}

Socket.prototype = {
  /**
   * Connects to EventSource
   */
  connect: function() {
    var self = this;
    var esOpts = {};

    var url = this.url + '?method=DNA.Portal.LiveEvents.connect';
    if (this.mac) {
      url += '&mac=' + this.mac;
    }

    if (this.withCredentials === true) {
      esOpts = {withCredentials: true};
    }

    this.es = new EventSource(url, esOpts);

    this.es.onopen = function(ev) {
      console2.log('log', 'EventSource opened for', self.mac || '<fleet only>');
      Radio.trigger('socket', 'connected', true);
    };

    this.es.onerror = function(ev) {
      if (self.es.readyState === EventSource.CLOSED) {
        Radio.trigger('socket', 'connected', false);
        console2.log('warn', 'EventSource closed due to error');
        return;
      }
      if (self.es.readyState === EventSource.CONNECTING) {
        Radio.trigger('socket', 'connected', false);
        console2.log('warn', 'EventSource is attempting to reconnect');
        return;
      }

      console2.log('warn', 'EventSource error! Connection still open', ev);
    };

    this.es.onmessage = this.onMessage.bind(this);
  },

  /**
   * Close the EventSource
   */
  close: function() {
    this.es.close();
    Radio.trigger('socket', 'connected', false);
  },

  /**
   * Handler for incoming messages.
   *
   * @param {Event} ev
   */
  onMessage: function(ev) {
    var resp;
    var eventName;

    try {
      resp = JSON.parse(ev.data); // {eventName: "", data: {}}
    } catch (e) {
      console2.log('error', 'Invalid JSON data from EventSource');
      // TODO log to server too
      return;
    }

    eventName = resp.eventName || 'message';
    console2.log('info', 'EventSource message: id', ev.lastEventId, 'name:', eventName, 'data:', resp.data);
    Radio.trigger('socket', eventName, resp.data);
  },
};
