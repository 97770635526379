'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('manage/details/dna/wanDetails/wan-details.html');
var WansWiredView = require('manage/details/dna/wanDetails/WansWiredView');
var WansLteView = require('manage/details/dna/wanDetails/WansLteView');
var FailoverTriggerView = require('components/failover/FailoverTriggerView');

/**
 * Renders the WAN details (primary, secondary and LTE).
 */
module.exports = Marionette.View.extend({
  /**
   * @member {Backbone.Model} model
   * @property {String} deviceMac
   * @property {lib/models/DeviceConfiguration} attributes.deviceConfig
   * @property {lib/models/DeviceStatus} attributes.deviceStatus
   * @property {Backbone.Model} attributes.statusCard
   */

  template: twig({data: tpl}),

  regions: {
    wired: '.rg-details-wired',
    lte: '.rg-details-lte',
    failover: '.rg-test-failover',
  },

  onRender: function() {
    this.addWiredDetails();
    this.addLteDetails();
    this.addFailoverTrigger();
  },

  /**
   * Adds the wired WAN details.
   */
  addWiredDetails: function() {
    var attrs = ['deviceConfig', 'deviceStatus', 'statusCard'];
    var model = new Backbone.Model(this.model.pick(attrs));
    this.showChildView('wired', new WansWiredView({model: model}));
  },

  /**
   * Adds the LTE details.
   */
  addLteDetails: function() {
    var attrs = ['statusCard'];
    var model = new Backbone.Model(this.model.pick(attrs));
    this.showChildView('lte', new WansLteView({model: model}));
  },

  /**
   * Adds the "Test Failover" button.
   */
  addFailoverTrigger: function() {
    var attrs = ['deviceMac', 'deviceStatus', 'statusCard', 'jobs'];
    var model = new Backbone.Model(this.model.pick(attrs));

    this.showChildView('failover', new FailoverTriggerView({model: model}));
  },

});
