'use strict';

var i18n = require('i18next');
var EditUserTimezone = require('actions/router/userTimezone/EditUserTimezone');
var FormView = require('actions/router/userTimezone/FormView');
var StaticView = require('actions/router/userTimezone/StaticView');

/**
 * Action configuration for the Timezone action component.
 */
module.exports = {
  title: i18n.t('actionTimezone.title'),
  Model: EditUserTimezone,
  FormView: FormView,
  TextView: StaticView,
};
