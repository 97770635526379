'use strict';

var BasePortsFormView = require('actions/ports/FormView');

/**
 * Renders the list of ports (part of the VLAN ports action component).
 */
module.exports = BasePortsFormView.extend({
  /**
   * Overrides portFilter to only include the reserved WAN ports
   *
   * @param {actions/ports/Port} port
   * @return {Boolean}
   */
  portFilter: function(port) {
    return port.id === 'eth0' || port.id === 'eth1';
  },

  /**
   * Overrides the supportsTagging property in the template context
   * as WANs do not currently support 802.1q VLAN tagging
   *
   * @return {Object}
   */
  templateContext: function() {
    var context = BasePortsFormView.prototype.templateContext.apply(this);
    context.supportsTagging = false;
    context.supportsAggregation = false;
    return context;
  },

});
