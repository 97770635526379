'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var ActionItem = require('actions/shared/ActionItem');
var childModels = require('lib/mixins/childModels');
var SubscriberModel = require('actions/alertConfiguration/subscriber/SubscriberModel');
var EditAlert = require('actions/alertConfiguration/modal/Alert');

var Subscribers = Backbone.Collection.extend({
  model: SubscriberModel,

  isValid: function() {
    var valid = true;

    _.each(this.models, function(model) {
      valid = model.isValid() && valid;
    });

    return valid;
  },
});

var Alerts = Backbone.Collection.extend({
  model: EditAlert,
});

var nesting = childModels({
  subscribers: Subscribers,
  alerts: Alerts,
}, ActionItem);

module.exports = ActionItem.extend(nesting).extend({

  _snapshotAttributes: ['subscribers', 'alerts'],

  isNew: function() {
    return false;
  },

  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var subscribers = this.deviceConfig.get('alertSubscriptions').toJSON();
      var alerts = this.deviceConfig.get('alerts').toJSON();

      return {
        subscribers: subscribers,
        alerts: alerts,
      };
    }

    return resp;
  },

  takeSnapshot: function() {
    var self = this;

    _.each(this._snapshotAttributes, function(attr) {
      var collection = self.get(attr);

      _.each(collection.models, function(model) {
        model.takeSnapshot();
      });
    });

    ActionItem.prototype.takeSnapshot.apply(this, arguments);
  },

  validate: function() {
    if (!this.get('subscribers').isValid()) {
      return {};
    }
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('alertSubscriptions');
      },
      events: 'add remove change',
    },
    {
      getDispatcher: function(config) {
        return config.get('alerts');
      },
      events: 'add remove change',
    },
  ],
});
