'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var networkUtils = require('lib/network');

/**
 * Edit Model for creating/managing a DHCP pool.
 *
 * Note, DHCP is unimplemented for IPv6 at this time.
 *
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/dhcpPool/EditDhcpPool#attributes
   * @property {String} id
   *   The VLAN will be used to get the subnet the DHCP will exist in.
   * @property {String} startIp
   *   The DHCP pool start IP.
   * @property {String} endIp
   *   The DHCP pool end IP.
   *
   * @property {Backbone.ReadOnlyModel} subnet
   *   A reference to the (deviceConfig) subnet Model this DHCP is associated with.
   */

  defaults: {
    pendingDelete: false,
  },

  _snapshotAttributes: ['id', 'startIp', 'endIp', 'pendingDelete'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    var dhcpPool = this.deviceConfig.getDhcpPool(this.id);
    return _.isUndefined(dhcpPool);
  },

  /**
   * @param {Object} options
   * @return {Object}
   */
  toJSON: function(options) {
    var data = ActionItem.prototype.toJSON.apply(this, arguments);

    // No need to serialize this since it is a reference to deviceConfig
    delete data.subnet;

    return data;
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig) {
      resp = this.getAttributesByVlanId(this.id);
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks').get(this.id);
      },
      events: 'change:dhcpPools',
    },
    {
      getDispatcher: function(config) {
        return config.getDhcpPool(this.id);
      },
      events: 'change',
    },
    {
      getDispatcher: function() {
        return this.getDependency('lanAddress');
      },
      events: 'change:af, change:address change:size',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (!_.isUndefined(attrs.id)) {
      if (attrs.id === '' || !this.hasValidSubnet()) {
        errors.id = i18n.t('actionDhcp.invalidVlan');
      }
    }

    if (!_.isUndefined(attrs.startIp) && !_.isUndefined(attrs.endIp)) {
      if (!this._validRange(networkUtils.Ip.ip2long(attrs.startIp), networkUtils.Ip.ip2long(attrs.endIp))) {
        errors.range = i18n.t('actionDhcp.invalidPool');
      }
    }
    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    var data = {};

    if (!this.isNew() || this.get('pendingDelete') === true) {
      data.id = this.id;
    }

    if (this.get('pendingDelete') === true) {
      // no task
      return;
    }

    var params = this.get('subnet').pick('address', 'size', 'type');
    var network = new networkUtils.Ip(params.address + '/' + params.size, {type: params.type});
    data.networkIp = network.string().network();

    return {
      name: 'dhcpPool.create',
      data: _.extend(data, this.pick('startIp', 'endIp')),
    };
  },

  /**
   * Gathers the passed vlan's data for dhcp pools and returns it.
   *
   * Note, only supports one dhcp pool/subnet for now.
   *
   * @param {String} vlanId
   * @returns {Object}
   */
  getAttributesByVlanId: function(vlanId) {
    var dhcpPool = this.deviceConfig.getDhcpPool(vlanId);
    var lanAddressModel = this.getDependency('lanAddress');

    var data = {
      subnet: new Backbone.Model({
        type: lanAddressModel.get('af'),
        address: lanAddressModel.get('address'),
        size: lanAddressModel.get('size'),
      }),
    };

    if (!_.isUndefined(dhcpPool)) {
      _.extend(data, dhcpPool.pick('startIp', 'endIp'));
    }

    return data;
  },

  /**
   * Does the associated VLAN have an address.
   *
   * @return {Boolean}
   */
  hasValidSubnet: function() {
    var subnet = this.get('subnet');

    return subnet &&
      (!_.isUndefined(subnet.get('address')) || !_.isEmpty(subnet.get('address'))) &&
      !_.isUndefined(subnet.get('size')) &&
      networkUtils.validIP(subnet.get('address'), subnet.get('type'));
  },

  /**
   * Validates the start and end IPs.
   *
   * @private
   * @param {Number} startIp
   * @param {Number} endIp
   * @param {Array} range
   * @return {Boolean}
   */
  _validRange: function(startIp, endIp) {
    var range = this.getCurrentBoundary();

    if (range.length === 0) {
      return false;
    }

    if (startIp < range[0] || startIp > range[1]) {
      return false;
    }

    if (endIp < range[0] || endIp > range[1]) {
      return false;
    }

    if (startIp > endIp) {
      return false;
    }

    return true;
  },

  /**
   * Gets the current range of IP's the DHCP pool can exist within.
   *
   * @param {lib/Ip} network
   * @return {Array}
   *   Returns the start and end IPs as integers.
   */
  getCurrentBoundary: function() {
    var params;

    if (!this.hasValidSubnet()) {
      return [];
    }

    params = _.toArray(this.get('subnet').pick('address', 'size', 'type'));
    return this.getBoundary.apply(this, params);
  },

  /**
   * Utility method to get the range of IP's a DHCP pool can exist within.
   *
   * Important! The range does not take into account where the router IP is.
   *
   * @param {String} address
   *   Any IP within the subnet.
   * @param {Number} size
   *   The size of the subnet in CIDR notation (e.g. 24).
   * @param {String} af
   * @return {Array}
   *   Returns the start and end IPs as integers.
   */
  getBoundary: function(address, size, af) {
    var network = new networkUtils.Ip(address + '/' + size, {type: af});
    return network.integer().hosts();
  },

  /**
   * Returns the message to use when a DHCP pool is being removed.
   *
   * @see actions/shared/removeConfig/RemoveConfig
   * @see manage/edit/config/action/ActionView
   * @return {String}
   */
  getDeleteMsg: function() {
    var vlan = this.deviceConfig.get('networks').get(this.id);
    var name = vlan.getName();

    return i18n.t('actionDhcp.taskDeletePool', {
      start: this.getFromSnapshot('startIp'),
      end: this.getFromSnapshot('endIp'),
      name: name,
    });
  },

  /**
   * Utility method to transpose the DHCP pool to a different subnet.
   */
  transposePool: function() {
    if (!this.has('startIp') || !this.has('endIp') || !this.hasValidSubnet()) {
      // nothing to transpose;
      return;
    }

    var boundary = this.getCurrentBoundary();

    // Try to leave one at the beginning of the range, for the router
    // We need to check both the start and the end of the boundary to handle
    // changes to the router's IP (e.g., from 192.168.0.1 to 10.10.0.1)
    var changes = false;
    var startIp = networkUtils.Ip.ip2long(this.get('startIp'));
    if (startIp < boundary[0] + 1 || startIp > boundary[1] - 1) {
      startIp = boundary[0] + 1;
      changes = true;
    }

    var endIp = networkUtils.Ip.ip2long(this.get('endIp'));
    if (endIp > boundary[1] || endIp < boundary[0] + 2) {
      endIp = boundary[1];
      changes = true;
    }

    if (changes) {
      this.set({
        startIp: networkUtils.Ip.long2ip(startIp),
        endIp: networkUtils.Ip.long2ip(endIp),
      }, {commit: true});
    }
  },
});
