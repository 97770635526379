'use strict';

/**
 * Custom additions to Twig.js functionality
 */

var twig = require('twig');
var viewFilters = require('lib/viewFilters');
var runtimeContext = require('lib/runtimeContext');

module.exports = {
  install: function() {
    /**
     * Add an "first5Enabled" filter to twig so static view lists can be easily
     * filtered/truncated in a consistent fashion
     * Add an "asset()" function to twig that can be used like the
     * server-side version to resolve an asset path using runtime
     * configuration data
     */
    twig.extendFilter('first5Enabled', viewFilters.staticViewItemsTwigFilter);
    twig.extendFunction('asset', function(relativePath) {
      return runtimeContext.ASSET_URL_PREFIX + '/' + relativePath;
    });
  },
};
