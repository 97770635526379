'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var formTpl = require('actions/lanAllowVpn/form.html');
var RenderChanges = require('lib/behaviors/RenderChanges');

var tplHelpers = require('lib/tplHelpers');

/**
 * Renders the checkbox to allow lan to be used by site vpn
 */
module.exports = Marionette.View.extend({

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  ui: {
    'allowVpn': '.allow-vpn',
  },

  events: {
    'change @ui.allowVpn': 'updateModel',
  },

  getTemplate: function() {
    return twig({data: formTpl});
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    var context = {};
    return _.extend(context, tplHelpers.apply(this));
  },

  updateModel: function(ev) {
    var $target = $(ev.target);
    var checked = $target.is(':checked');

    this.model.set('allowVpn', checked);
  },
});
