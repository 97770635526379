'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var userChannel = Radio.channel('userChannel');

/**
 * Router handlers for the whole application.
 */
module.exports = Marionette.Object.extend({
  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.session = options.session;
  },

  fleet: function() {
    Radio.request('layoutChannel', 'change:page', {});
  },

  /**
   * @param {null|String} deviceMac
   * @param {String} page
   *   Deep links to a specific details view (e.g. vlan, connected-device).
   *     - /<deviceMac>/edit
   *     - /<deviceMac>/security
   *     - /<deviceMac>/vlan<id>
   *     - /<deviceMac>/cfg<id>
   *     - /<deviceMac>/vlan<id>/device<mac>
   */
  manage: function(deviceMac, page) {
    var options = {};
    var devicesList = userChannel.request('get:user').get('devices');

    if (!_.isNull(deviceMac)) {
      deviceMac = deviceMac.toUpperCase();

      if (_.isUndefined(devicesList.get(deviceMac))) {
        deviceMac = null;
        Backbone.history.navigate('');
      }
    }

    if (!_.isNull(deviceMac)) {
      options.deviceMac = deviceMac;

      if (!_.isNull(page)) {
        options.page = page;
      }
    }

    Radio.request('layoutChannel', 'change:page', options);
  },

});
