'use strict';

var _ = require('lodash');
var $ = require('jquery');
var i18n = require('i18next');
var Marionette = require('backbone.marionette');
var tpl = require('actions/webFilters/form.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

/**
 * Renders the "Web Filters" form fields.
 */
module.exports = Marionette.View.extend({
  /**
   * @name #model
   * @type {actions/webFilters/EditWebFilters}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    enable: '.enable',
    categories: '.categories', // container for category checkboxes
  },

  events: {
    'change @ui.enable': 'saveForm',
    'change @ui.categories': 'saveForm', // catch bubbling checkbox events
  },

  modelEvents: {
    'invalid': 'onError',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    return _.extend(tplHelpers.apply(this), {
      displayCategories: this._getDisplayCategories(),
    });
  },

  /**
   * Checks if values have changed and saves.
   *
   * @param {Object} event
   */
  saveForm: function(event) {
    var control = event.target;
    var name = control.name;
    var value = control.value;

    switch (name) {
      case 'web-filters-enable':
        this.model.set({enabled: value === 'on' && $(control).is(':checked')});
        break;

      case 'web-filters-category':
        this.ui.categories.bs3ui('clearFieldError');
        this.model.setCategoryState(value, $(control).is(':checked'));
        break;

      default:
        break;
    }
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/webFiltering/EditWebFiltering} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.categories) {
      this.ui.categories.bs3ui('showFieldError', error.categories);
    }
  },

  /**
   * Provides categories as they should be displayed, with
   * localized name added and sorted on that name
   *
   * @return {Array}
   * @private
   */
  _getDisplayCategories: function() {
    var named = this.model.get('categories').map(function(category) {
      var name = i18n.t('actionWebFilters.category.' + category.id);
      return _.extend({}, category, {name: name});
    });
    return _.sortBy(named, 'name');
  },
});
