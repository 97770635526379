'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Marionette = require('backbone.marionette');
var RenderChanges = require('lib/behaviors/RenderChanges');
var tplHelpers = require('lib/tplHelpers');
var tpl = require('actions/router/siteToSiteRekeyTime/form.html');
var twig = require('twig').twig;
var dateFormatters = require('lib/tplHelpers/dateFormatters');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

/**
 * Renders the site-to-site rekey time form
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/router/siteToSiteRekeyTime/FormView#model
   * @type {actions/router/siteToSiteRekeyTime/EditUserTimezone}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    'enabled': '[name="site-to-site-rekey-time-enable"]',
    'hours': '[name="hours"]',
  },

  events: {
    'change @ui.enabled': 'saveForm',
    'change @ui.hours': 'saveForm',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  templateContext: function() {
    return _.extend(
      {
        timesList: this.getTimeOptions(),
      },
      tplHelpers.apply(this)
    );
  },

    /**
   * Pushes updates from the DOM into the model and validate
   *
   * @param {Event} ev
   */
  saveForm: function(ev) {
    var name = ev.currentTarget.name;
    var value = ev.currentTarget.value;

    switch (name) {
      case 'site-to-site-rekey-time-enable':
        this.model.set({enabled: value === 'on' && $(ev.target).is(':checked')});
        break;

      case 'hours':
        this.ui.hours.bs3ui('clearFieldError');
        this.model.set({hours: value}, {commit: true});
        if (value) {
          this.ui.hours.find('.no-value').remove();
        }
        break;

      default:
        break;
    }
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/router/siteToSiteRekeyTime/EditSiteToSiteRekeyTime} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.hours) {
      this.ui.hours.bs3ui('showFieldError', error.hours);
    }
  },

  /**
   * Creates a list of available time ranges with labels
   *
   * @returns {Array}
   */
  getTimeOptions: function() {
    return this.model.getTimesList().map(function(value) {
      return {
        value: value,
        label: dateFormatters.labelForHours(value),
      };
    });
  },
});
