'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var ActionItem = require('actions/shared/ActionItem');

/**
 * Edit model for Web Filters.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} #attributes
   * @property {Boolean} enabled
   * @property {Array} categories
   */

  _snapshotAttributes: ['enabled', 'categories'],

  defaults: function() {
    return {
      enabled: false,
      categories: [],
    };
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  getOnOffState: function() {
    return !!this.get('enabled');
  },

  /**
   * Parse response for configuration.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var attrs;
      var webFilters = this.deviceConfig.get('webFilters');

      if (webFilters) {
        attrs = {
          enabled: webFilters.get('enabled'),
          // make sure we are working on copies of the category objects
          categories: _.cloneDeep(webFilters.get('categories')),
        };
      }

      return attrs || this.defaults();
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('webFilters');
      },
      events: 'change:enabled change:categories',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'categories')) {
      if (this.get('enabled') && !_.any(attrs.categories, 'enabled', true)) {
        errors.categories = i18n.t('actionWebFilters.noCategoriesEnabled');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    return {
      name: 'webFilters.setOptions',
      data: {
        enabled: this.get('enabled'),
        categories: this.get('categories'),
      },
    };
  },

  /**
   * Updates the enabled state of a specified category
   *
   * @param {String} categoryId
   * @param {Boolean} enabled
   */
  setCategoryState: function(categoryId, enabled) {
    var updated = this.get('categories').map(function(category) {
      if (category.id === categoryId) {
        return _.extend({}, category, {enabled: enabled});
      }
      return category;
    });
    this.set('categories', updated);
  },

});
