'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var formTpl = require('actions/wanPriority/form.html');
var RenderChanges = require('lib/behaviors/RenderChanges');

/**
 * Renders the WAN Priority checkbox
 */
module.exports = Marionette.View.extend({

  behaviors: [{
    behaviorClass: RenderChanges,
    debounce: true, // need to wait for all WANs to update
  }],

  ui: {
    'priorityPrimary': '.wan-priority-primary',
    'prioritySecondary': '.wan-priority-secondary',
    'checkboxContainer': '.checkbox',
    'helpBlock': '.help-block',
  },

  events: {
    'change @ui.priorityPrimary': 'setPrimary',
    'change @ui.prioritySecondary': 'setSecondary',
  },

  initialize: function() {
    _.bindAll(this, 'getOtherWan');
  },

  getTemplate: function() {
    /**
     * Again, assuming we will only have 2 WANs
     * Will need to be revisited if we support > 2 WANS
     */
    if (Object.keys(this.model.getWans()).length !== 2) {
      return twig({data: ''});
    }

    return twig({data: formTpl});
  },

  templateContext: function() {
    return {
      helpBlock: this.getHelpBlockMessage(),
    };
  },

  onRender: function() {
    this.toggleHelpMessage();
  },

  /**
   * Flips the priority and shows a message informing the user
   * that they have also changed the priority on their other WAN
   *
   * @param {Event} ev
   */
  setPrimary: function(ev) {
    var $target = $(ev.target);
    var checked = $target.is(':checked');

    this.model.set('isPrimary', checked);
    this.render();
  },

  /**
   * Flips the priority and shows a message informing the user
   * that they have also changed the priority on their other WAN
   *
   * @param {Event} ev
   */
  setSecondary: function(ev) {
    var $target = $(ev.target);
    var checked = $target.is(':checked');

    this.model.set('isPrimary', !checked);
    this.render();
  },

  /**
   * Shows or hides the help message as needed
   */
  toggleHelpMessage: function() {
    var hasOriginalValue = this.model.getSnapshotDiff() === false;
    this.ui.helpBlock.toggleClass('hidden', hasOriginalValue);
  },

  /**
   * Gets the description of the other WAN
   *
   * @return {String} WAN Description
   */
  getOtherWan: function() {
    var wans = this.model.getWans();

    if (!_.isUndefined(wans.otherWan)) {
      return this.model.getWans().otherWan.get('description');
    }
  },

  /**
   * Creates a translated help block message
   *
   * @return {String}
   */
  getHelpBlockMessage: function() {
    var primary = this.model.get('isPrimary'); // this is the NEW set value of isPrimary after un/checking

    return i18n.t('actionWanPriority.helpBlock', {
      otherWan: this.getOtherWan(),
      thisPriority: primary ? i18n.t('actionWanPriority.primary') : i18n.t('actionWanPriority.secondary'),
      otherPriority: primary ? i18n.t('actionWanPriority.secondary') : i18n.t('actionWanPriority.primary'),
    });
  },
});
