'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('manage/edit/config/action/removeConfig/pendingDelete.html');

/**
 * When an action is removable, this behavior replaces the form
 * View with a message indicating the action is to be deleted.
 *
 * This behavior works by replacing the View's template on render.
 *
 * For this behavior to work properly with an Action, the following are
 * required:
 *
 * 1) Add the behavior to the Action's form View.
 * ```
 * var RemoveConfig = require('manage/edit/config/action/removeConfig/RemoveConfig');
 * return Marionette.View({
 *   behaviors: [{behaviorClass: RemoveConfig}],
 * });
 * ```
 *
 * 2) The Action's model must have a "getDeleteMsg" method.
 * ```
 * getDeleteMsg: function() {
 *   return i18n.t('deleteMessage', {someprop: this.get('someprop')});
 * },
 * ```
 *
 * 3) Add "pendingDeleteMsg" to the Action form View template helpers.
 * ````
 * templateContext: function() {
 *   return {
 *     pendingDeleteMsg: this.model.getDeleteMsg.bind(this.model),
 *   };
 * }
 * ```
 *
 * 4) If the form View uses lifecycle methods like `onRender`, be
 * sure to short-circuit any unnecessary work if the action is being removed.
 * ```
 * onRender: function() {
 *   if (this.model.get('pendingDelete') === true) {
 *     return;
 *   }
 *
 *   //rest of function
 * }
 * ```
 */
module.exports = Marionette.Behavior.extend({
  _template: twig({data: tpl}),

  modelEvents: {
    'change:pendingDelete': 'onChange',
  },

  initialize: function(options) {
    this.origTemplate = this.view.template;
  },

  /**
   * Prior to rendering the View, check if we need to override the View's
   * template property.
   */
  onBeforeRender: function() {
    if (this.view.model.get('pendingDelete') === true) {
      this.view.template = this._template;
    } else {
      this.view.template = this.origTemplate;
    }
  },

  /**
   * Rerender the View when the action's "pendingDelete" attribute changes.
   *
   * @param {actions/shared/ActionItem} model
   * @param {Boolean} pendingDelete
   * @param {Object} options
   */
  onChange: function(model, pendingDelete, options) {
    this.view.render();
  },
});
