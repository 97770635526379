'use strict';

var _ = require('lodash');
var userChannel = require('backbone.radio').channel('userChannel');
var DNARecord = require('actions/configClone/DNARecord/DNARecord');
var MultiActionItem = require('actions/shared/MultiActionItem');

/**
 * Model for Configuration Clone.
 */
module.exports = MultiActionItem.extend({
  /**
   * @member {Object} actions/configClone/ConfigClone#attributes
   */
  collectionModel: DNARecord,

  defaults: {
    items: {},
  },

  _snapshotAttributes: false,

  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      return {
        items: this._getAvailableDevices(),
      };
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function() {
        return userChannel.request('get:user').get('devices');
      },
      events: 'change add remove',
    },
  ],

  /**
   * Override to handle not snapshotting.
   * @returns {boolean}
   */
  getSnapshotDiff: function() {
    return !_.isUndefined(this.get('items').models) && _.filter(this.get('items').models, function(device) {
      return device.hasChanges();
    }).length > 0;
  },

  /**
   * Override to handle reset of models on save.
   */
  takeSnapshot: function() {
    _.forEach(this.get('items').models, function(dnaRecord) {
      dnaRecord.resetState();
    });
  },

  getTask: function() {
    return {
      name: 'fleetManagement.cloneConfig',
      data: {
        'deviceList': _.reduce(this.get('items').models, function(memo, dnaRecord) {
          var childTask = dnaRecord.getTask();
          if (!_.isUndefined(childTask)) {
            return memo.concat(dnaRecord.getTask());
          }

          return memo;
        }, []),
      },
    };
  },

  /**
   * Get all devices for this reseller.
   * @returns {Array}
   * @private
   */
  _getAvailableDevices: function() {
    var user = userChannel.request('get:user');
    var thisMac = this.deviceConfig.get('deviceMac').toUpperCase();
    var devices = user.get('devices').map(function(device) {
      return device.pick('deviceMac', 'hostname', 'description', 'fwVersion', 'country');
    });
    var myDevice = _.findWhere(devices, {deviceMac: thisMac});

    var availableDevices = [];
    devices.forEach(function(device) {
      if (device.deviceMac !== thisMac) {
        availableDevices.push(_.extend(device, {allowed: this.configCloneAllowedForDevice(myDevice, device)}));
      }
    }.bind(this));

    return availableDevices;
  },

  /**
   * Determine if a device can receive the config from this DNA.
   * @param {Object} currDevice
   * @param {Object} device
   * @returns {boolean}
   */
  configCloneAllowedForDevice: function(currDevice, device) {
    return device.fwVersion === currDevice.fwVersion &&
           device.country === currDevice.country;
  },

  /**
   * Determine if model is empty.
   * @returns {boolean}
   */
  isEmptyModel: function() {
    return this.get('items').length === 0;
  },
});
