'use strict';

var Backbone = require('backbone');
var rpcMixin = require('lib/mixins/rpc2');
var ConfigGroups = require('config/Groups');
var childModels = require('lib/mixins/childModels');

var categories = {
  system: ConfigGroups,
  networks: ConfigGroups,
  firewall: ConfigGroups,
  applications: ConfigGroups,
  fleetManagement: ConfigGroups,
  diagnostics: ConfigGroups,
};
var nesting = childModels(categories, Backbone.Model);

/**
 * Current configuration state model (aka ConfigOutline).
 *
 * How is the configuration structured?
 * * Map of category => {config/Group} models
 * * A "group" represents a group of configuration items (e.g. Manage LAN)
 * * A "group" contains a list of {config/Action} models
 * * An "action" is a configuration item (e.g. DHCP Pool) that requires a
 *   parent (e.g. a DHCP Pool always belongs to a VLAN)
 *
 * @mixes lib/mixins/rpc2
 */
module.exports = Backbone.Model.extend(rpcMixin).extend(nesting).extend({
  /**
   * @member {Object} config/ConfigModel#attributes
   * @property {String} deviceMac
   *   The mac address of the device to load its current configuration.
   * @property {Boolean} isSaving
   *   Whether a configuration save is in progress
   * @property {config/Groups} manage
   *   The list of config groups related to the "manage" page.
   * @property {config/Groups} security
   *   The list of config groups related to the "security" page.
   */

  idAttribute: 'deviceMac',

  defaults: {
    isSaving: false,
  },

  rpc: {
    read: {
      method: 'DNA.Portal.Changeset.Device.getConfigOutline',
      params: function() {
        return {
          mac: this.id,
        };
      },
    },
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (resp.error) {
      // TODO handle this
      return {};
    }

    if (resp.result) {
      resp = resp.result;
    }

    return resp;
  },

  /**
   * Look up the category within which a given config group resides
   * Returns null if the group is not found
   *
   * @param {String} type e.g. editLan
   * @param {String} [typeId] e.g. vlan2
   * @return {String}
   */
  getCategoryForConfigGroup: function(type, typeId) {
    var categoryKeys = Object.keys(categories);
    var criteria = {type: type};

    if (typeId) {
      criteria.typeId = typeId;
    }

    for (var i = 0; i < categoryKeys.length; i++) {
      var categoryKey = categoryKeys[i];
      var match = this.get(categoryKey).findWhere(criteria);
      if (match) {
        return categoryKey;
      }
    }
    return null;
  },

  /**
   * Execute a filter function against all groups in the
   * config outline, across all categories, and return the result
   *
   * @param {Function} filter
   * @return {Array}
   */
  filterGroups: function(filter) {
    var self = this;
    var result = [];
    Object.keys(categories).forEach(function(categoryKey) {
      result = result.concat(self.get(categoryKey).filter(filter));
    });
    return result;
  },

});
