'use strict';

var _ = require('lodash');

/**
 * {@link lib/models/DeviceConfiguration} mixin for DHCP Pool related methods.
 *
 * Using a mixin to keep DeviceConfiguration from getting too large.
 *
 * @mixin lib/mixins/filterDhcpPools
 */
module.exports = {
  /**
   * Gets the DHCP Pool for a VLAN.
   *
   * @param {String} vlanId
   * @param {Object} options
   * @return {Backbone.Model|undefined}
   */
  getDhcpPool: function(vlanId, options) {
    var pools = [];
    var vlan = this.get('networks').get(vlanId);
    var poolsList = this.get('dhcpPools');
    var poolsMap;

    if (_.isUndefined(vlan)) {
      return;
    }

    poolsMap = vlan.get('dhcpPools');

    if (poolsMap.length === 0) {
      return;
    }

    pools = _.map(poolsMap, function(dhcpPoolId) {
      return poolsList.get(dhcpPoolId);
    });

    return _.first(pools);
  },
};
