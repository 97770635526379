'use strict';

var $ = require('jquery');
var Marionette = require('backbone.marionette');
var FilterView = require('actions/macFilter/FilterView');
var Filter = require('actions/macFilter/Filter');
var i18n = require('i18next');
var tpl = require('actions/macFilter/form.html');
var twig = require('twig').twig;
var strMod = require('lib/mixins/stringModifiers');
require('lib/jquery/bootstrapUI');

var FiltersListView = Marionette.CollectionView.extend({
  childView: FilterView,

  onChildviewDeleteFilter: function() {
    this.render();
  },
});

/**
 * Renders the editable form for the collection of mac filter rules
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/macFilter/FormView#model
   * @type {actions/macFilter/EditMacFilter}
   */

  template: twig({data: tpl}),

  regions: {
    maclist: '.rg-maclist',
  },

  ui: {
    edit: '.edit-mac',
    error: '.mac-filter-error',
    add: '.btn-add',
  },

  events: {
    'input @ui.edit': 'colonFill',
    'keydown @ui.edit': 'validInput',
    'click @ui.add': 'addFilter',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  backspacing: false,

  onRender: function() {
    if (this.model.get('pendingDelete') === true) {
      return;
    }

    this.showChildView('maclist', new FiltersListView({
      collection: this.model.get('maclist'),
    }));
  },

  addFilter: function() {
    var filter = new Filter(null, {deviceMac: this.model.deviceMac});
    this.model.get('maclist').add(filter);
  },

  /**
   * @param {Object} ev
   * @return {Boolean}
   */
  validInput: function(ev) {
    var code = ev.keyCode;
    var valid = ev.key.match(/[a-fA-F0-9]/);

    this.ui.error.bs3ui('clearFieldError');
    this.backspacing = false;

    // If the input is invalid, and they are not trying to backspace or perform hotkey
    if (!valid && code !== 8 && (!ev.ctrlKey && !ev.metaKey)) {
      return false;
    }

    if (code === 8) {
      this.backspacing = true;
    }
  },

  /**
   * @param {Object} ev
   */
  colonFill: function(ev) {
    var $target = $(ev.currentTarget);
    var value = $target[0].value;

    if (value.length > 0 && !this.backspacing) {
      $target[0].value = strMod.stringDelimit(value, 2).toUpperCase();
    }
  },

  onError: function(model, error, options) {
    if (error.duplicate) {
      this.ui.error.bs3ui('showFieldError', i18n.t('actionMacFilter.duplicateMac'));
    }

    // invalid will take priority over duplicates and be rendered last
    if (error.invalid) {
      this.ui.error.bs3ui('showFieldError', i18n.t('actionMacFilter.invalidMac'));
    }
  },
});
