'use strict';

var AbstractStaticView = require('actions/shared/AbstractStaticView');
var twig = require('twig').twig;
var tpl = require('actions/description/static.html');

/**
 * Renders a text-only version of the "Description" component.
 */
module.exports = AbstractStaticView.extend({
  /**
   * @name actions/description/StaticView#model
   * @type {
   *    actions/description/wifi/EditDescription|
   *    actions/description/vlan/EditDescription
   *  }
   */

  template: twig({data: tpl}),
});
