'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/router/updateSchedule/static.html');
var twig = require('twig').twig;
var dateFormatters = require('lib/tplHelpers/dateFormatters');

/**
 * Renders a text-only version of the automatic updates component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name #model
   * @type {actions/router/updateSchedule/EditUpdateSchedule}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      formatSelectedDays: this._formatSelectedDays,
      formatTimeRange: this._formatTimeRange,
    });
  },

  /**
   * Formats list of selected days into a text string
   *
   * @param {Array} weekdays
   *  Weekdays list from #model
   * @return {String}
   * @private
   */
  _formatSelectedDays: function(weekdays) {
    var formatted = weekdays
      .map(function(value) {
        return dateFormatters.labelForWeekday(value);
      })
      .join(i18n.t('general.listSeparator'));

    return formatted || i18n.t('configEdit.fieldNoValues');
  },

  /**
   * Formats list of selected days into a text string
   *
   * @param {String} start
   *   Start time from #model
   * @param {Number} interval
   *   Interval from #model
   * @return {String}
   * @private
   */
  _formatTimeRange: function(start, interval) {
    if (start) {
      return dateFormatters.labelForTimeWindow(start, interval);
    }
    return i18n.t('configEdit.fieldNoValues');
  },
});
