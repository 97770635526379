'use strict';

var ActionItem = require('actions/shared/ActionItem');
var _ = require('lodash');
var i18n = require('i18next');

/**
 * Model for TitanHq settings setting
 */
module.exports = ActionItem.extend({

  _snapshotAttributes: ['zone', 'safesearch', 'allowUnclassified'],

  toggledOnOffBy: 'webFiltersTitanHqEnable',

  defaults: function() {
    return {
      safesearch: false,
      zone: '',
      zoneList: this.getZoneList,
      allowUnclassified: true,
    };
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * Get the settings from the web filters config or default if it doesn't exist.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var webFilters = this.deviceConfig.get('webFiltersTitanHq');
      var settings = this.defaults();

      if (webFilters) {
        if (webFilters.has('safesearch')) {
          settings.safesearch = webFilters.get('safesearch');
        }
        if (webFilters.has('allow_unclassified')) {
          settings.allowUnclassified = webFilters.get('allow_unclassified');
        }
        settings.zone = webFilters.get('zone');
        settings.zoneList = this.getZoneList();
      }

      return settings;
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('webFiltersTitanHq');
      },
      events: 'change:zone change:safesearch change:allowUnclassified',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    var approvedZones = this.getZoneList();
    var validEntry = _.indexOf(approvedZones, attrs.zone) >= 0;
    if (_.has(attrs, 'zone') && !validEntry) {
      errors.zone = i18n.t('actionWebFiltersTitanHq.invalidZoneDetected');
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    return {
      name: 'webFiltersTitanHq.setOptions',
      data: {
        safesearch: this.get('safesearch'),
        zone: this.get('zone'),
        allowUnclassified: this.get('allowUnclassified'),
      },
    };
  },

  getZoneList: function() {
    /**
     * i18n.t('actionWebFiltersTitanHq.zone.auto')
     * i18n.t('actionWebFiltersTitanHq.zone.us-west-1')
     * i18n.t('actionWebFiltersTitanHq.zone.us-east-2')
     * i18n.t('actionWebFiltersTitanHq.zone.eu-west-2')
     * i18n.t('actionWebFiltersTitanHq.zone.ap-southeast-2')
     */

    return ['auto', 'us-east-2', 'us-west-1', 'eu-west-2', 'ap-southeast-2'];
  },
});
