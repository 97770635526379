'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var tplHelpers = require('lib/tplHelpers');
var otherLanDetailsTpl = require('actions/lanIsolationGlobal/otherlan-details.html');
var formTpl = require('actions/lanIsolationGlobal/form.html');
var RenderChanges = require('lib/behaviors/RenderChanges');

// i18n.t('actionLanIsolationGlobal.modeSelection.allow')
// i18n.t('actionLanIsolationGlobal.modeSelection.deny')
// i18n.t('actionLanIsolationGlobal.modeSelection.custom')

/**
 * Renders the global "LAN Segregation" option form.
 */
module.exports = Marionette.View.extend({
  behaviors: [{
    behaviorClass: RenderChanges,
    debounce: true, // combine with renders for network add/remove (see initialize)
  }],

  template: twig({data: formTpl}),
  otherLanDetailsTpl: twig({data: otherLanDetailsTpl}),

  ui: {
    mode: '[name="inter-vlan-option"]',
    otherLan: '[name="other-network"]',
    customRules: '.lan-isolation-custom',
  },

  events: {
    'change @ui.mode': 'checkConfig',
    'change @ui.otherLan': 'checkConfig',
  },

  initialize: function() {
    // when networks are added or removed, we need to update the rule dropdowns
    this.listenTo(this.model.deviceConfig.get('networks'), 'add remove', function() {
      this.triggerMethod('needsRender');
    });
  },

  onRender: function() {
    this.checkCustom();
    var select2 = this.ui.otherLan.select2({
      placeholder: i18n.t('actionLanIsolationGlobal.helpSelectAll'),
      width: '100%',
      templateResult: _.bind(this.formatDetails, this),
      theme: 'bootstrap',
      closeOnSelect: false,
    });

    select2.data('select2').$dropdown.addClass('multicontent');
  },

  checkCustom: function() {
    if (this.model.get('mode') === 'custom') {
      this.ui.customRules.removeClass('hidden');
    } else {
      this.ui.customRules.addClass('hidden');
    }
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    return _.extend(tplHelpers.apply(this), {
      eligibleNetworks: _.bind(this._getEligibleNetworks, this),
      peerNetworks: _.bind(this._getPeerNetworks, this),
    });
  },

  /**
   * @param {Event} ev
   */
  checkConfig: function(ev) {
    if (ev.currentTarget.name === 'inter-vlan-option') {
      this.model.set('mode', ev.currentTarget.value);
      this.checkCustom();
    } else if (ev.currentTarget.name === 'other-network') {
      var rules = _.clone(this.model.get('otherLan'));
      var list = [];
      _.each(ev.currentTarget.selectedOptions, function(opt) {
        list.push(opt.value);
      });

      rules[ev.currentTarget.id] = list;
      this.model.set('otherLan', rules);
    }
  },

  formatDetails: function(data, container) {
    var details = {};
    var html;

    details = {
      id: data.id,
      name: data.text,
    };

    html = this.otherLanDetailsTpl.render(_.extend(details, {t: _.bind(i18n.t, i18n)}));
    return $(html);
  },

  _getEligibleNetworks: function() {
    return this.model.deviceConfig.getVlans({external: false})
      .map(function(lan) {
        return lan.toJSON();
      });
  },

  // Get the other networks to populate the dropdown for vlan routing rules
  _getPeerNetworks: function(vlanId) {
    var allNetworks = this._getEligibleNetworks();
    var peers = allNetworks.filter(function(lan) {
      return lan.id !== vlanId;
    });
    var wan = {id: 'wan', description: i18n.t('actionLanIsolationGlobal.internet')};
    peers.unshift(wan);
    return peers;
  },

});
