'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/operatorPing/form.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the "operatorPing" form field.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/operatorPing/FormView#model
   * @type {actions/operatorPing/OperatorPing}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    destIp: '[name="destIp"]',
    iface: '[name="iface"]',
    count: '[name="count"]',
    size: '[name="size"]',
    ttl: '[name="ttl"]',
    wait: '[name="wait"]',
    messagesDestIp: '.error-destIp',
    messagesCount: '.error-count',
    messagesSize: '.error-size',
    messagesTtl: '.error-ttl',
    messagesWait: '.error-wait',
  },

  events: {
    'blur @ui.destIp': 'updateModel',
    'change @ui.iface': 'updateModel',
    'blur @ui.count': 'updateModel',
    'blur @ui.size': 'updateModel',
    'blur @ui.ttl': 'updateModel',
    'blur @ui.wait': 'updateModel',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var context = {
      wanList: this.model.get('wans'),
      lanList: this.model.get('lans'),
    };
    return _.extend(context, tplHelpers.apply(this));
  },

  /**
   * @param {Event} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'destIp':
        this.clearError('destIp');
        this.model.set({destIp: value}, {commit: true});
        break;

      case 'iface':
        this.model.set({iface: value});
        break;

      case 'count':
        this.clearError('count');
        this.model.set({count: value}, {commit: true});
        break;

      case 'size':
        this.clearError('size');
        this.model.set({size: value}, {commit: true});
        break;

      case 'ttl':
        this.clearError('ttl');
        this.model.set({ttl: value}, {commit: true});
        break;

      case 'wait':
        this.clearError('wait');
        this.model.set({wait: value}, {commit: true});
        break;

      default:
        break;
    }
  },

  /**
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    var errorMessages = {
      destIp: this.ui.messagesDestIp,
      count: this.ui.messagesCount,
      size: this.ui.messagesSize,
      ttl: this.ui.messagesTtl,
      wait: this.ui.messagesWait,
    };
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, errorMessages[key]);
    }, this);
  },

  /**
   * Remove an error message from the view (if present)
   *
   * @param {String} key
   *   Identifier for the error message
   */
  clearError: function(key) {
    var errorMessages = {
      destIp: this.ui.messagesDestIp,
      count: this.ui.messagesCount,
      size: this.ui.messagesSize,
      ttl: this.ui.messagesTtl,
      wait: this.ui.messagesWait,
    };
    this.ui[key].bs3ui('clearGroupedFieldError', key, errorMessages[key]);
  },

  /**
   * Because of the way the WANs and LANs are looped over in the HTML,
   * whenever the form loses focus the iface will display as automatic,
   * even when the model still retains its value.
   *
   * This will manually trigger the model to update on every render of the form
   * to auto so that the display and model match
   */
  onBeforeRender: function() {
    this.model.set('iface', 'auto');
  },
});
