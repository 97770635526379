'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var ConfigCardActionView = require('manage/edit/toolbox/ConfigCardActionView');
var configTypes = require('lib/configTypes');
var tpl = require('manage/edit/toolbox/config-card-item.html');
var tooltipTpl = require('manage/edit/toolbox/tooltip.html');

/**
 * Renders the list of actions associated with a config card.
 *
 * Note, if this View gets more complicated, break it out into its own file.
 */
var ConfigCardItemList = Marionette.CollectionView.extend({
  tagName: 'ul',
  className: 'config-action-list',
  childView: ConfigCardActionView,
});

/**
 * Renders config card groups and their actions for the toolbox
 */
module.exports = Marionette.View.extend({
  /**
   * @name manage/edit/toolbox/ConfigCardItemView#model
   * @type {config/Group}
   */

  /**
   * @name manage/edit/toolbox/ConfigCardItemView#collection
   * @type {Backbone.Collection}
   */

  /**
   * @name manage/edit/toolbox/ConfigCardItemView#cardGroupObj
   * @type {Object}
   */

  template: twig({data: tpl}),
  tooltipTemplate: twig({data: tooltipTpl}),

  tagName: 'li',

  regions: {
    list: '.config-action-container',
  },

  ui: {
    configLink: '.config-link',
    configLinkIcon: '.config-link__icon',
  },

  events: {
    'click @ui.configLink': 'selectGroup',
  },

  modelEvents: {
    'change:isFocused': 'onFocusChange',
  },

  /**
   * @param {Object} options
   * @property {Radio.Channel} options.channel
   *   A private Radio channel to communicate item selection events.
   * @property {boolean} options.showActions
   *   a boolean to determine whether the group should show child actions or not
   * @property {lib/models/DeviceStatus} options.deviceStatus
   * @property {config/ConfigModel} options.configOutline
   *   info about what currently exists to enforce resource limits
   *   required when options.showActions === false (i.e. "new" cards)
   */
  initialize: function(options) {
    // optional injection of config type registry for testing
    this.configTypes = options.configTypes || configTypes;

    this.mergeOptions(options, ['channel', 'showActions', 'deviceStatus', 'deviceConfig', 'configOutline']);

    this.cardGroupObj = this.configTypes.groups[this.model.get('type')];

    this.createCollection();

    // keep "configured" flags in action collection in sync as config outline changes
    if (this.model.has('actions')) {
      this.listenTo(this.model.get('actions'), 'add remove', this.updateCollection);
    }

    if (this.hasDescription()) {
      this.model.onDescriptionChanged(this, this.render);
    }

    if (this.isCreational()) {
      this.model.onAllowNewChanged(this, this.render);
    }

    if (this.model.deviceConfig) {
      this.listenTo(this.model.deviceConfig.get('networks'), 'change:ipconflict', this.renderAlert);
    }

    this.groupEnabled = !_.isFunction(this.model.groupEnabled) ||
      this.model.groupEnabled(this.configOutline, this.deviceStatus);

    this.allowNewConfig = !_.isFunction(this.model.allowNew) ||
      this.model.allowNew(this.configOutline, this.deviceStatus);
  },

  isCreational: function() {
    if (!_.isUndefined(this.cardGroupObj.Model)) {
      return this.cardGroupObj.Model.isCreational && !_.isUndefined(this.model.onAllowNewChanged);
    }

    return false;
  },

  hasDescription: function() {
    return _.isFunction(this.model.description);
  },

  /**
   * @return {Objects}
   */
  templateContext: function() {
    return {
      title: this.model.titleOverwrite() || this.model.title(),
      testId: this.tplTestId,
      hasDescription: this.hasDescription(),
      description: (this.hasDescription()) ? this.model.description() : '',
      tooltip: this.tooltipHelper.bind(this),
    };
  },

  optionEnabled: function() {
    if (this.showActions) {
      // normal cards are always enabled; this check is only applicable to "New X" stub cards
      return this.groupEnabled;
    }

    return this.allowNewConfig;
  },

  onRender: function() {
    if (this.optionEnabled()) {
      this.renderAlert();
      this.renderChildren();
    } else {
      this.disableOption();
    }
  },

  renderAlert: function() {
    if (this.model.isMisconfigured()) {
      this.ui.configLink.addClass('config-link--attention');
      this.ui.configLinkIcon.addClass('config-link--attention__icon');
      this.ui.configLinkIcon.addClass('fas');
      this.ui.configLinkIcon.addClass('fa-exclamation-circle');
    } else {
      this.ui.configLink.removeClass('config-link--attention');
      this.ui.configLinkIcon.removeClass('config-link--attention__icon');
      this.ui.configLinkIcon.removeClass('fas');
      this.ui.configLinkIcon.removeClass('fa-exclamation-circle');
    }
  },

  renderChildren: function() {
    if (this.showActions) {
      this.ui.configLink.addClass('config-link--header');
      this.showChildView('list', new ConfigCardItemList({
        collection: this.collection,
        childViewOptions: {
          channel: this.channel,
        },
      }));

      if (this.collection !== undefined && this.collection.length > 0) {
        this.ui.configLink.addClass('config-link--has-children');
      }
    } else {
      this.ui.configLink.addClass('config-link--create-new');
    }
  },

  disableOption: function() {
    this.ui.configLink.tooltip({trigger: 'hover'});
    this.ui.configLink.addClass('config-link--disabled');
  },

  tooltipHelper: function() {
    if (!this.allowNewConfig || !this.groupEnabled) {
      var reason = this.model.disallowedNewReason();
      return this.tooltipTemplate.render({reason: reason});
    }
  },

  /**
   * Sets whether the configuration card associated with this View is focused.
   *
   * @param {config/Group} model
   * @param {Boolean} isFocused
   * @param {Object} options
   */
  onFocusChange: function(model, isFocused, options) {
    this.ui.configLink.toggleClass('config-link--focused', isFocused === true);
  },

  createCollection: function() {
    var self = this;
    var result = this.getAvailableActions();
    this.collection = new Backbone.Collection(result.actions);

    result.unmetCapabilities.forEach(function(capability) {
      self.deviceStatus.onCapabilityAdded(self, capability, self.updateCollection);
    });
  },

  updateCollection: function() {
    var result = this.getAvailableActions();
    this.collection.set(result.actions);
  },

  /**
   * Finds all the actions we want to show to the user for a group, determines if it has been configured,
   * and creates a collection based on the results. Also returns info about which device capabilities
   * prevented additional actions from being made available, if any
   *
   * @returns {Object}
   *  {Array} actions - actions that can be shown
   *  {Array} unmetCapabilities - names of required capabilities that were not met for other actions
   */
  getAvailableActions: function() {
    var actionsToShow = [];
    var unmetCapabilities = [];
    var configNotSupported = [];
    var self = this;

    var optionalActions = this.model.actionList.filter(function(actionObj) {
      return !actionObj.required && !actionObj.managed;
    });

    _.each(optionalActions, function(actionObj) {
      var requirements = actionObj.requireCapabilities || [];
      var disabledOnEnabledConfig = actionObj.disabledOnEnabledConfig || [];

      var unmet = requirements.filter(function(capability) {
        return !self.deviceStatus.hasCapability(capability);
      });
      if (unmet.length > 0) {
        Array.prototype.push.apply(unmetCapabilities, unmet);
        return;
      }

      _.each(disabledOnEnabledConfig, function(config) {
        if (self.deviceConfig.get(config.id).get('enabled')) {
          var tmpObj = {actionId: actionObj.name, reason: config.reason};
          configNotSupported.push(tmpObj);
        }
      });

      var actionId = actionObj.name;
      var configured = false;
      var actionInfo = self.configTypes.actions[actionId];

      if (self.model.has('actions') && !_.isUndefined(self.model.get('actions').get(actionId))) {
        configured = true;
      }

      actionsToShow.push({
        title: actionInfo.title,
        configured: configured,
        actionId: actionId,
        type: self.model.get('type'),
        typeId: self.model.get('typeId'),
        disableConfiguration: configNotSupported.length > 0 ? configNotSupported : false,
      });
    });

    return {
      actions: actionsToShow,
      unmetCapabilities: _.uniq(unmetCapabilities),
    };
  },

  /**
   * Signals that a group has been selected
   *
   * @fires channel.request~add:group
   * @fires channel.request~focus:group
   */
  selectGroup: function() {
    var eventName = 'focus:group';

    if (!this.optionEnabled()) {
      return;
    }

    if (this.cardGroupObj.Model.isCreational === true) {
      eventName = 'add:group';
    }

    this.channel.request(eventName, this.model.get('type'), this.model.get('typeId'));
  },

  /**
   * Testing Id
   *
   * @method templateContext.testId
   * @return {String}
   */
  tplTestId: function() {
    return 'go-config-group-' + _.toArray(_.pick(this, 'type', 'typeId')).join('-');
  },
});
