'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tplNavbarItem = require('components/navbar/navbarItem.html');

/**
 * Renders a navbar item.
 */
module.exports = Marionette.View.extend({
  /**
   * View-Model.
   * @member {Backbone.Model} components/navbar/NavbarItemView#model
   * @property {String} id
   *   The ID of the navbar item.
   * @property {String} attributes.label
   * @property {string} attributes.url
   * @property {Boolean} attrbutes.isActive
   *   Indicates the active/selected navbar item.
   */

  tagName: 'a',
  className: function() {
    var name = 'dui-tabs__item dui-tabs__item--underline';

    if (this.options.grid) {
      name += ' grid-block';
    }

    return name;
  },
  template: twig({data: tplNavbarItem}),

  ui: {
    icon: 'svg',
  },

  modelEvents: {
    'change:isActive': 'toggleActive',
  },

  triggers: {
    'click': 'nav:item:selected',
  },

  /**
   * The class name added to the active nav item.
   * @type {String}
   */
  navItemSelectedClass: 'dui-tabs__item--active',

  initialize: function(options) {
    _.bindAll(this, 'toggleActive');
  },

  onBeforeRender: function() {
    // FIXME Prefix must be customizable
    this.el.setAttribute('data-tag', 'device-' + this.model.id);
  },

  onRender: function() {
    if (this.model.get('isActive') === true) {
      this.toggleActive();
    }

    if (this.model.has('icon')) {
      this.$el.addClass('dui-tabs__item--has-icon');
      // add a class to the inlined SVG icon for cleaner styling
      this.ui.icon.addClass('dui-tabs__icon');
    }

    this.$el.attr('href', this.model.get('url'));
  },

  /**
   * Toggles the "active" class for the navbar item.
   */
  toggleActive: function() {
    if (this.model.get('isActive') === true) {
      this.$el.addClass(this.navItemSelectedClass);
    } else {
      this.$el.removeClass(this.navItemSelectedClass);
      this.$el.blur();
    }
  },

  setInvalid: function() {
    if (!this.$el.children().length) {
      this.$el.prepend('<i class="fas fa-exclamation-circle"></i>');
    }
  },

  unsetInvalid: function() {
    this.$el.find('.fa-exclamation-circle').remove();
  },

});
