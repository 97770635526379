'use strict';

var _ = require('lodash');
var Ip = require('lib/Ip');
var i18n = require('i18next');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var networkUtils = require('lib/network');

var MAX_METRIC = 16384;

/**
 * Represents an individual static route
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({

  /**
   * @member {Object} #attributes
   * @property {Boolean} enabled
   *   Whether the route should be enabledd
   * @property {Boolean} remove
   *   Whether the route should be removed on save
   * @property {String} destination
   *   Destination of the route in CIDR form
   * @property {String} gateway
   *   IP address of the gateway
   * @property {String} interface
   *   internal name of the interface (e.g., 'vlan5')
   * @property {String} interfaceName
   *   user-facing name of the interface
   * @property {Integer} metric
   *   relative priority of the route
   */

  _snapshotAttributes: [
    'enabled',
    'remove',
    'destination',
    'gateway',
    'interface',
    'interfaceName',
    'metric',
  ],

  defaults: {
    enabled: true,
    remove: false,
    destination: '',
    gateway: '',
    metric: '',
    interface: '',
  },

  setInterfaceName: function() {
    var iface = this.get('interface');
    var vlans = this.get('vlans');
    for (var i = 0; i < vlans.length; i++) {
      var vlan = vlans[i];
      if (vlan.id === iface) {
        this.set('interfaceName', vlan.description);
        return;
      }
    }
  },

  initialize: function(options) {
    this.setInterfaceName();
  },

  /**
   * @fires noConflicts
   *   Validation has determined this rule is free of conflicts with other rules
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true) {
      return;
    }

    if (_.has(attrs, 'destination')) {
      if (!attrs.destination) {
        errors.destination = i18n.t('actionStaticRoutes.destinationRequired');
      } else {
        try {
          var ip = new Ip(attrs.destination);
          var network = ip.network(); // zeroed ip
          var netmask = ip.netmask(); // e.g. 255.240.0.0
          if (netmask !== false && network !== false) {
            this.set('target', network);
            this.set('netmask', netmask);
            this.set('destination', network + '/' + ip.cidr());
            this.trigger('zeroDestination');
          } else {
            errors.destination = i18n.t('actionStaticRoutes.invalidIpOrMask');
          }
        } catch (e) {
          errors.destination = i18n.t('actionStaticRoutes.badInternalSubnet');
        }
      }
    }

    if (_.has(attrs, 'gateway')) {
      if (!attrs.gateway) {
        errors.gateway = i18n.t('actionStaticRoutes.gatewayRequired');
      } else if (!networkUtils.validInternalIPv4(attrs.gateway)) {
        errors.gateway = i18n.t('actionStaticRoutes.badGateway');
      }
    }

    if (_.has(attrs, 'metric')) {
      if (!attrs.metric) {
        errors.metric = i18n.t('actionStaticRoutes.metricRequired');
      } else {
        var metricInt = parseInt(attrs.metric);
        if (metricInt.toString() !== attrs.metric) {
          errors.metric = i18n.t('actionStaticRoutes.metricNotNumeric');
        } else if (metricInt > MAX_METRIC) {
          errors.metric = i18n.t('actionStaticRoutes.metricLimitExceeded', {limit: MAX_METRIC});
        }
      }
    }

    if (_.has(attrs, 'interface')) {
      if (!attrs.interface) {
        errors.interface = i18n.t('actionStaticRoutes.interfaceRequired');
      }
    }

    if (_.has(attrs, 'destination') || _.has(attrs, 'metric')) {
      var cid = this.cid;
      var duplicates = this.collection.filter(function(route) {
        return route.cid !== cid && route.get('destination') === attrs.destination &&
          route.get('metric') === attrs.metric;
      });
      if (duplicates.length > 0) {
        errors.destination = i18n.t('actionStaticRoutes.duplicateRoute');
      } else {
        this.trigger('noConflicts');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return {
        name: 'staticRoute.deleteStaticRoute',
        data: {
          id: this.id,
        },
      };
    }

    if (this.isNew() || this.getSnapshotDiff()) {
      var task = {
        name: 'staticRoute.createOrEditStaticRoute',
        data: {
          enabled: this.get('enabled'),
          gateway: this.get('gateway'),
          target: this.get('target'),
          netmask: this.get('netmask'),
          interface: this.get('interface'),
          metric: this.get('metric'),
        },
      };
      if (!this.isNew()) {
        task.data.id = this.id;
      }
      return task;
    }
  },
});
