'use strict';

var i18n = require('i18next');
var EditWifi = require('actions/wifi/EditWifi');
var FormView = require('actions/wifi/FormView');
var StaticView = require('actions/wifi/StaticView');

/**
 * Action configuration for the "Manage Wi-Fi Network" action component.
 */
module.exports = {
  title: i18n.t('actionWifi.title'),
  Model: EditWifi,
  FormView: FormView,
  TextView: StaticView,
};
