'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tplAlert = require('components/alert/alert.html');
var uiIcons = require('lib/tplHelpers/uiIcons');

/**
 * Renders an alert box. Alerts are made up of an icon, message and optional
 * header.
 *
 * //TODO add support for right-aligned action button
 */
module.exports = Marionette.View.extend({
  /**
   * @member {Backbone.Model} components/alert/AlertView#model
   * @property {String} attributes.alertType
   *   (Optional) Accepts "default" or "wide".
   * @property {String} attributes.header
   * @property {String} attributes.message
   * @property {String} attributes.severity
   *   One of "warning", "error", "success" or "info".
   */

  template: twig({data: tplAlert}),

  ui: {
    button: 'button',
  },

  triggers: {
    'click @ui.button': 'button:clicked',
  },

  className: 'alert',

  alertClasses: {
    warning: 'alert-warning',
    error: 'alert-danger',
    success: 'alert-success',
    info: 'alert-info',
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    return {
      iconClass: this.tplAlertIcon,
    };
  },

  /**
   * Adjust the class name(s) attached to the View's wrapping element. The
   * attached classes decorate the alert with a specific severity.
   */
  onBeforeRender: function() {
    var alertClass = this.alertClasses[this.model.get('severity')];
    this.$el.removeClass(_.values(this.alertClasses).join(' '));
    this.$el.addClass('alert ' + alertClass);
  },

  /**
   * Adjust alert contents.
   */
  onRender: function() {
    if (this.model.get('alertType') === 'wide') {
      this.$el
        .addClass('alert--wide')
        .wrapInner('<div class="container"></div>');
    }
  },

  /**
   * Get the alert icon.
   * @memberOf! components/alert/AlertView#
   * @method templateContext.iconClass
   * @return {String}
   */
  tplAlertIcon: function() {
    return _.get(uiIcons.icons, this.severity, uiIcons.icons.warning);
  },
});
