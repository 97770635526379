'use strict';

var i18n = require('i18next');
var EditCategoryTitanHq = require('actions/webFiltersTitanHq/category/EditCategoryTitanHq');
var FormView = require('actions/webFiltersTitanHq/category/FormView');
var StaticView = require('actions/webFiltersTitanHq/category/StaticView');

/**
 * Action configuration for categories for Web Filters TitanHQ
 */
module.exports = {
  title: i18n.t('actionWebFilters.title'),
  Model: EditCategoryTitanHq,
  FormView: FormView,
  TextView: StaticView,
};
