'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var i18n = require('i18next');
var buttonSpinner = require('lib/buttonSpinner');
var adjacentStatusMessage = require('lib/mixins/adjacentStatusMessage');
var TrackJob = require('lib/behaviors/TrackJob');
var Jobs = require('lib/collections/Jobs');

/**
 * Renders the "Test Failover" button
 */
module.exports = Marionette.View.extend(adjacentStatusMessage).extend({
  /**
   * @member {Backbone.Model} model
   * @property {String} attributes.deviceMac
   * @property {lib/models/DeviceStatus} attributes.deviceStatus
   * @property {Backbone.Model} attributes.statusCard
   *   See Portal.Fleet.getOverview API call
   */

  template: false,
  tagName: 'button',
  className: 'btn btn-primary btn-xs',

  events: {
    'click': 'triggerFailover',
  },

  behaviors: [
    {
      behaviorClass: TrackJob,
      jobType: Jobs.FAILOVER_TEST,
    },
  ],

  onAttach: function() {
    var debounced = _.debounce(this.toggleSpinner, 1);

    // react to changes in connectivity that affect the ability
    // to start a failover test if one isn't already running
    this.listenTo(this.model.get('statusCard'), {
      'change:signalQuality': debounced,
      'change:connected': debounced,
      'change:failedOver': debounced,
    });

    this.listenTo(this.model.get('deviceStatus').get('failover'), {
      'change:active': debounced,
    });
  },

  onBeforeRender: function() {
    this.el.setAttribute('data-tag', 'do-test-failover');
  },

  onRender: function() {
    this.$el.text(i18n.t('failover.test'));
    this.toggleSpinner();
  },

  triggerFailover: function() {
    this.hideStatusMessage();
    this.model.get('jobs').startJob(Jobs.FAILOVER_TEST);
  },

  /**
   * Shows or hides the button spinner and sets appropriate state
   */
  toggleSpinner: function() {
    var pendingJob = this.model.get('jobs').getJobOfType(Jobs.FAILOVER_TEST, true);

    if (pendingJob) {
      buttonSpinner.showButtonSpinner(this.$el);
      // no toggleButtonDisabled() here -- spinner should own the button state
    } else {
      buttonSpinner.hideButtonSpinner(this.$el);
      this.toggleButtonDisabled();
    }
  },

  /**
   * Sets whether the button is disabled or not
   */
  toggleButtonDisabled: function() {
    var statusCard = this.model.get('statusCard');
    var deviceStatus = this.model.get('deviceStatus');
    var disabled = false;

    // multiple conditions are for readability
    if (_.isUndefined(statusCard.get('signalQuality')) || statusCard.get('signalQuality') <= 0) {
      disabled = true;
    } else if (statusCard.get('failedOver') === true || statusCard.get('connected') === false) {
      disabled = true;
    } else if (deviceStatus.get('failover').get('active') === true) {
      disabled = true;
    }

    this.$el.prop('disabled', disabled);
  },

  onFailure: function() {
    this.showError(i18n.t('failover.failed'));
    this.toggleSpinner();
  },

  onApplied: function() {
    this.showSuccess(i18n.t('failover.applied'));
  },

});
