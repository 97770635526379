'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/siteVpn/OtherSite/form.html');
var tplHelpers = require('lib/tplHelpers');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');
var TogglePasswordField = require('lib/behaviors/ToggleSinglePassword');
var _ = require('lodash');
var $ = require('jquery');

/**
 * Renders editable DNA to non DNA site to site tunnel form
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/SiteVpn/OtherSite/OtherSiteFormView#model
   * @type {actions/siteVpn/OtherSite/OtherSiteVpn}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    return _.extend({
      supportsVpnStandby: this.model.deviceStatus.hasCapability('supportsVpnStandby'),
      modelVals: this.model.getModelVals(),
      vpnParamsExpanded: this.vpnParamsExpanded,
    }, tplHelpers.apply(this));
  },

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: TogglePasswordField,
      showPasswordOnLoad: false,
    },
  ],

  ui: {
    enabled: '[name="enable-site-vpn"]',
    remoteHost: '[name="remoteHost"]',
    initiator: '.site-initiator',
    preSharedKey: '[name="site-vpn-secret"]',
    localId: '[name="local-ipsec"]',
    remoteId: '[name="remote-ipsec"]',
    keyExchange: '[name="ike-version"]',
    deviceType: '[name="device-type"]',
    remoteSubnets: '[name="subnets"]',
    group: '[name="site-group"]',
    remove: '.btn-remove',
    expandContract: '[name="expandContract"]',
    phase1EncAlg: '[name="phase1EncAlg"]',
    phase1HashAlg: '[name="phase1HashAlg"]',
    phase1DHGroup: '[name="phase1DHGroup"]',
    phase1Life: '[name="phase1Life"]',
    phase2EncAlg: '[name="phase2EncAlg"]',
    phase2HashAlg: '[name="phase2HashAlg"]',
    phase2DHGroup: '[name="phase2DHGroup"]',
    phase2Life: '[name="phase2Life"]',
    dpdEnabled: '[name="dpdEnabled"]',
    dpdTimeout: '[name="dpdTimeout"]',
    standbyEnabled: '[name="standbyEnabled"]',
    vpnParamsEditSheet: '[name="vpnParamsEditSheet"]',
  },

  events: {
    'change @ui.enabled': 'updateModel',
    'change @ui.keyExchange': 'updateModel',
    'change @ui.initiator': 'updateModel',
    'change @ui.deviceType': 'updateModel',
    'blur @ui.remoteHost': 'updateModel',
    'blur @ui.localId': 'updateModel',
    'blur @ui.remoteId': 'updateModel',
    'blur @ui.preSharedKey': 'updateModel',
    'blur @ui.remoteSubnets': 'updateModel',
    'click @ui.remove': 'handleRemoveButton',
    'change @ui.phase1EncAlg': 'updateModel',
    'change @ui.phase1HashAlg': 'updateModel',
    'change @ui.phase1DHGroup': 'updateModel',
    'blur @ui.phase1Life': 'updateModel',
    'change @ui.phase2EncAlg': 'updateModel',
    'change @ui.phase2HashAlg': 'updateModel',
    'change @ui.phase2DHGroup': 'updateModel',
    'blur @ui.phase2Life': 'updateModel',
    'change @ui.dpdEnabled': 'updateModel',
    'blur @ui.dpdTimeout': 'updateModel',
    'change @ui.standbyEnabled': 'updateModel',
    'click @ui.expandContract': 'handleExpandContract',
  },

  modelEvents: {
    'change:remove': 'render',
    'change:enabled': 'onEnabledChange',
    'change:deviceType': 'render',
    'invalid': 'onError',
  },

  initialize: function() {
    this.vpnParamsExpanded = this.model.get('newSite');
  },

  onBeforeRender: function() {
    this.isItemOff = !this.model.get('enabled');
  },

  onRender: function() {
    this.toggleDpdTimeoutInput();
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/siteVpn/DnaSite/OtherSiteVpn} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.psk) {
      this.ui.preSharedKey.bs3ui('showFieldError', error.psk);
    }

    if (error.remoteSubnets) {
      this.ui.remoteSubnets.bs3ui('showFieldError', error.remoteSubnets);
    }

    if (error.remoteHost) {
      this.ui.remoteHost.bs3ui('showFieldError', error.remoteHost);
    }

    if (error.remoteId) {
      this.ui.remoteId.bs3ui('showFieldError', error.remoteId);
    }

    if (error.localIdRequired) {
      this.ui.localId.bs3ui('showFieldError', error.localIdRequired);
    }

    if (error.phase1LifeRequired) {
      this.ui.phase1Life.bs3ui('showFieldError', error.phase1LifeRequired);
    }

    if (error.phase2LifeRequired) {
      this.ui.phase2Life.bs3ui('showFieldError', error.phase2LifeRequired);
    }

    if (error.dpdTimeoutRequired) {
      this.ui.dpdTimeout.bs3ui('showFieldError', error.dpdTimeoutRequired);
    }
  },

  /**
   * Handle the remove trigger
   */
  handleRemoveButton: function() {
    if (this.model.isNew()) {
      this.model.collection.remove(this.model);
    } else {
      var shouldRemove = !this.model.get('remove');
      if (shouldRemove) {
        this.model.applySnapshot();
      }
      this.model.set({remove: shouldRemove});
    }
  },

  /**
   * Handle the expansion and contraction of the VPN parameters sheet
   * for each site.
   */
  handleExpandContract: function() {
    // Perform validation on collapse
    if (this.vpnParamsExpanded) {
      var errors = this.model.validate(this.model.toJSON());
      if (!_.isUndefined(errors)) {
        this.onError(this.model, errors, null);
        return;
      }
    }

    this.vpnParamsExpanded = !this.vpnParamsExpanded;
    this.ui.vpnParamsEditSheet.animate({height: 'toggle'}, 275, (function() {
      this.render();
    }).bind(this));
  },

  /**
   * Update the model on form changes
   * @param {object} event
   */
  updateModel: function(event) {
    var self = this;
    var control = event.currentTarget;
    var name = control.name;
    var value = control.value;

    switch (name) {
      case 'enable-site-vpn':
        this.model.set({enabled: control.checked}, {commit: true});
        break;
      case 'remoteHost':
        this.clearError('remoteHost');
        this.model.set({remoteHost: value}, {commit: true});
        break;
      case 'site-vpn-initiator-' + self.cid:
        this.model.set({initiator: value === 'true' && $(control).is(':checked')}, {commit: true});
        break;
      case 'site-vpn-secret':
        this.clearError('preSharedKey');
        this.model.set({psk: value}, {commit: true});
        break;
      case 'local-ipsec':
        this.clearError('localId');
        this.model.set({localId: value}, {commit: true});
        break;
      case 'remote-ipsec':
        this.clearError('remoteId');
        this.model.set({remoteId: value}, {commit: true});
        break;
      case 'ike-version':
        this.model.set({keyExchange: value}, {commit: true});
        break;
      case 'subnets':
        this.clearError('remoteSubnets');
        var subnets = _.compact(_.map(value.split(','), _.trim));
        this.model.set({remoteSubnets: subnets}, {commit: true});
        break;
      case 'device-type':
        this.model.set({deviceType: value}, {commit: true});
        break;
      case 'phase1EncAlg':
        this.model.set({phase1Enc: value});
        break;
      case 'phase1HashAlg':
        this.model.set({phase1Hash: value});
        break;
      case 'phase1DHGroup':
        this.model.set({phase1DH: value});
        break;
      case 'phase1Life':
        this.clearError('phase1Life');
        this.model.set({phase1Life: value}, {commit: true});
        break;
      case 'phase2EncAlg':
        this.model.set({phase2Enc: value});
        break;
      case 'phase2HashAlg':
        this.model.set({phase2Hash: value});
        break;
      case 'phase2DHGroup':
        this.model.set({phase2DH: value});
        break;
      case 'phase2Life':
        this.clearError('phase2Life');
        this.model.set({phase2Life: value}, {commit: true});
        break;
      case 'dpdEnabled':
        this.model.set({dpdEnabled: control.checked});
        this.toggleDpdTimeoutInput();
        break;
      case 'dpdTimeout':
        this.clearError('dpdTimeout');
        this.model.set({dpdTimeout: value}, {commit: true});
        break;
      case 'standbyEnabled':
        this.model.set({standbyEnabled: control.checked});
        break;
      default:
        break;
    }
  },

  onEnabledChange: function(model, value) {
    this.isItemOff = !value;
    this.triggerMethod('onoff:status:change', this);
  },

  toggleDpdTimeoutInput: function() {
    if (!this.model.get('dpdEnabled')) {
      this.ui.dpdTimeout.attr('disabled', true);
    } else {
      this.ui.dpdTimeout.removeAttr('disabled');
    }
  },

  clearError: function(key) {
    this.ui[key].bs3ui('clearFieldError');
  },
});
