'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');

/**
 * View-model representing fields for PPPoE.
 */

module.exports = ActionItem.extend({
  /**
   * @name actions/wanPppoe/EditWanPppoe#attributes
   * @type {Object}
   * @property {String} id
   *   The interface name (e.g. vlan40)
   * @property {String} username
   *   PPPoE username
   * @property {String} password
   *   PPPoE password
   */

  defaults: {
    username: '',
    password: '',
    pendingDelete: false,
  },

  _snapshotAttributes: ['username', 'password', 'pendingDelete'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    var vlan = this.deviceConfig.get('networks').get(this.id);
    return _.isUndefined(vlan) || vlan.isNew();
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   *   The modified response.
   */
  parse: function(resp, options) {
    if (options && options.fromConfig && !this.isNew()) {
      var subnet = this.deviceConfig.getSubnet(this.id);
      return {
        username: subnet.get('username'),
        password: subnet.get('password'),
      };
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.getSubnet(this.id);
      },
      events: 'change:username change:password',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'username')) {
      if (!attrs.username) {
        errors.username = i18n.t('actionWanPppoe.missingUsername');
      }
    }

    if (_.has(attrs, 'password')) {
      if (!attrs.password) {
        errors.password = i18n.t('actionWanPppoe.missingPassword');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('pendingDelete') === true) {
      return;
    }

    var data = {};

    if (!this.isNew()) {
      data.id = this.id;
    }

    data.address = {
      username: this.get('username'),
      password: this.get('password'),
    };

    return {
      name: 'vlan.create',
      data: data,
    };
  },
});
