'use strict';

var ReadOnly = require('backbone.readonly');

/**
 * Represents an individual "vpn". It was written to be used by the
 * "VpnsList" collection in {@link lib/models/DeviceConfiguration}.
 *
 */
module.exports = ReadOnly.Model.extend({
  /**
   *
   * @param {String|Object} key
   * @param {*} val
   * @param {Object|undefined} options
   * @return {Object} self
   */
  set: function(key, val, options) {
    // normalize key, value vs. {key: value} style - from Backbone source
    var attrs;
    if (typeof key === 'object') {
      attrs = key;
      options = val;
    } else {
      (attrs = {})[key] = val;
    }
    // end normalize

    return ReadOnly.Model.prototype.set.call(this, attrs, options);
  },

  /**
   * Request a callback when the name of this vpn changes
   *
   * @param {Backbone.Events} listener
   * @param {Function} callback
   */
  onNameChanged: function(listener, callback) {
    var cb = callback.bind(listener);
    listener.listenTo(this, 'change:description', cb);
  },
});
