'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var formTpl = require('actions/wanDnsPlaceholder/form.html');

/**
 * Renders a button to allow DNS configuration on this card.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/wanDnsPlaceholder/FormView#model
   * @type {actions/shared/ActionItemPlaceholderModel}
   */

  template: twig({data: formTpl}),

  ui: {
    addCustomDns: '.btn',
  },

  events: {
    'click @ui.addCustomDns': '_addCustomDnsForm',
  },

  _addCustomDnsForm: function() {
    this.model.trigger('addCustomDns', this);
  },
});
