'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var rpcMixin = require('lib/mixins/rpc2');
var Radio = require('backbone.radio');
var graphChannel = Radio.channel('graphChannel');

/**
 * Loader for the bandwidth data call
 */
module.exports = Backbone.Model.extend(rpcMixin).extend({
  /**
   * @member {Object} lib/models/Bandwidth#attributes
   * @property {String} deviceMac
   * @property {Boolean} loading
   * @property {Boolean} error
   * @property {Backbone.Collection} throughput
   *  The bandwidth data for DNA/Interfaces/Devices
   */

  rpc: {
    read: [
      {
        method: 'DNA.Portal.Bandwidth.getUsage',
        params: function() {
          return {
            mac: this.get('deviceMac'),
            start: Math.round(new Date().getTime() / 1000) - (1800), // start time should be 30 minutes ago
          };
        },
      },
    ],
  },

  defaults: function() {
    return {
      loading: false,
      error: false,
      throughput: new Backbone.Collection(),
    };
  },

  fetch: function(options) {
    var self = this;

    self.set({error: false, loading: true});

    return Backbone.Model.prototype.fetch.call(this, options)
      .done(function(result) {
        self.set({error: false, loading: false});
      })
      .fail(function(error) {
        self.set({error: true, loading: false});
      });
  },

  /**
   * Take the returned status call and break it into collections
   * and models to be consumed by the UI.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   *   The modified response
   */
  parse: function(resp, options) {
    var self = this;

    // 360 translates to 30 minutes of data
    var maxDataPoints = 360;
    resp = resp.result || resp;

    if (!_.isUndefined(resp.error)) {
      // TODO error handling
      return {};
    }

    resp.mac = resp.id.toUpperCase();

    var throughput = this.get('throughput');

    if (throughput.length === 0) {
      // initial page load, create new throughput models
      _.each(resp.data, function(obj) {
        obj.data = _.slice(_.sortBy(obj.data, 'timestamp'), -maxDataPoints);
        throughput.add(obj);
      });
    } else {
      // new data from event-source, add to throughput models
      _.each(resp.data, function(obj) {
        if (throughput.has(obj.id)) {
          var group = throughput.get(obj.id);
          var data = group.get('data');

          // FIXME: consumers are currently responsible for normalizing and incorporating raw newData
          group.set('newData', obj.data);

          data = self.addDeDuped(data, obj.data);
          data = _.slice(_.sortBy(data, 'timestamp'), -maxDataPoints);
          group.set('data', data);
        } else {
          obj.data = _.sortBy(obj.data, 'timestamp');
          throughput.add(obj);
        }
      });

      graphChannel.trigger('throughput:data');
    }
    return {};
  },

  addDeDuped: function(oldData, newData) {
    var result = oldData.slice();

    for (var i = 0; i < newData.length; i++) {
      var newItem = newData[i];
      var dupIndex = _.findLastIndex(result, {timestamp: newItem.timestamp});
      if (dupIndex >= 0) {
        var oldItem = result[dupIndex];
        if (newItem.upTotal > oldItem.upTotal || newItem.downTotal > oldItem.downTotal) {
          result[dupIndex] = newItem;
        }
        // otherwise ignore the new value; it is identical or older
      } else {
        result.push(newItem);
      }
    }

    return result;
  },

});
