'use strict';

var _ = require('lodash');
var AbstractEditAddress = require('actions/staticAddress/AbstractEditAddress');
var i18n = require('i18next');
var networkUtils = require('lib/network');

/**
 * View-Model for creating/editing a a static WAN address.
 */
module.exports = AbstractEditAddress.extend({

  reparseConfigTriggers: AbstractEditAddress.prototype.reparseConfigTriggers.concat([
    {
      getDispatcher: function(config) {
        return config.get('networks').get(this.getInterfaceId());
      },
      events: 'change:secondaryIps',
    },
  ]),

  parse: function(resp, options) {
    if (options && options.fromConfig && !this.isNew()) {
      resp = AbstractEditAddress.prototype.parse.apply(this, arguments);

      var networks = this.deviceConfig.get('networks');
      var vlan = networks.get(this.getInterfaceId());

      resp.secondaryIps = vlan.get('secondaryIps') || [];
    }

    return resp;
  },

  /**
   *
   * @param {Object} attrs
   * @param {Object} options
   * @return {*|{}}
   */
  validate: function(attrs, options) {
    var self = this;
    var errors = AbstractEditAddress.prototype.validate.apply(this, arguments) || {};

    if (_.has(attrs, 'secondaryIps') && _.isArray(attrs.secondaryIps)) {
      if (attrs.secondaryIps.length > 16) {
        errors.secondaryIps = i18n.t('actionStaticAddress.max16Ips');
      } else {
        attrs.secondaryIps.forEach(function(addr) {
          if (!networkUtils.validIP(addr, self.get('af'))) {
            errors.secondaryIps = i18n.t('actionStaticAddress.badSecondaryIP', {addr: addr});
          } else {
            errors = self.addressInSubnet(addr, 'secondaryIps', errors);
          }
        });
      }
    }

    var allIps = _.has(attrs, 'secondaryIps') ? attrs.secondaryIps.slice(0) : this.attributes.secondaryIps.slice(0);
    allIps.push(_.has(attrs, 'address') ? attrs.address : this.attributes.address);
    if (allIps.length !== _.unique(allIps).length) {
      errors.secondaryIps = i18n.t('actionStaticAddress.duplicateIp');
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Returns the message to use when a WAN static address is being removed.
   *
   * @see actions/shared/removeConfig/RemoveConfig
   * @see manage/edit/config/action/ActionView
   * @return {String}
   */
  getDeleteMsg: function() {
    return i18n.t('actionStaticAddress.taskDeleteAddress');
  },
});
