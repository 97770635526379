'use strict';

var $ = require('jquery');
var Backbone = require('backbone');

/**
 * View-Model for {@link manage/details/vpn/VpnStatusView}.
 */
module.exports = Backbone.Model.extend({
  /**
   * @member {Object} attributes
   * @property {string} id
   *   The network ID (e.g. "vpn0")
   * @property {String} deviceMac
   * @property {lib/models/DeviceConfig} deviceConfig
   * @property {lib/models/DeviceStatus} deviceStatus
   */

  /**
   * Overridden to return a promise immediately. All data has already been
   * fetched.
   *
   * @param {Object} options
   * @return {jQuery.Deferred}
   */
  fetch: function(options) {
    // Note, the main template does not need any special data. The details
    // region will be passed an instance of this Model and use DeviceConfig
    // and DeviceStatus as needed.

    return ($.Deferred().resolve()).promise();
  },
});
