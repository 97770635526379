'use strict';

var _ = require('lodash');
var Backbone = require('backbone');

/**
 * Represents a single router.
 */
module.exports = Backbone.Model.extend({
  /**
   * @member {Object} lib/models/Device#attributes
   * @property {String} deviceMac
   *   The mac address of the router.
   * @property {String} hostname
   * @property {Backbone.Model} statusCard
   *   The contents of the "statusCard" item, which contains a number of attributes.
   *   See the Portal.Fleet.getOverview API call.
   */

  idAttribute: 'deviceMac',

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    resp = _.cloneDeep(resp);

    if (resp.statusCard) {
      if (!this.has('statusCard')) {
        this.set('statusCard', new Backbone.Model(resp.statusCard));
      } else {
        this.get('statusCard').set(resp.statusCard);
      }
      delete resp.statusCard;
    }

    return resp;
  },
});
