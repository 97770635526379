'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Marionette = require('backbone.marionette');
var RenderChanges = require('lib/behaviors/RenderChanges');
var tplHelpers = require('lib/tplHelpers');
var tpl = require('actions/router/updateSchedule/form.html');
var twig = require('twig').twig;
var dateFormatters = require('lib/tplHelpers/dateFormatters');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

/**
 * Renders the automatic updates form
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/router/userTimezone/FormView#model
   * @type {actions/router/userTimezone/EditUserTimezone}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
      enableCallback: function() {
        this.toggleWeekdayDropdowns();
      },
    },
  ],

  template: twig({data: tpl}),

  ui: {
    'start': '[name="start"]',
    'enabled': '[name="auto-update-enable"]',
    'weekday1': '[name="weekday1"]',
    'weekday2': '[name="weekday2"]',
  },

  events: {
    'change @ui.start': 'saveForm',
    'change @ui.enabled': 'saveForm',
    'change @ui.weekday1': 'saveForm',
    'change @ui.weekday2': 'saveForm',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  templateContext: function() {
    var weekdays = this.model.get('weekdays');
    var weekday1 = null;
    var weekday2 = null;

    if (weekdays) {
      weekday1 = _.get(weekdays, '0', null);
      weekday2 = _.get(weekdays, '1', null);
    }

    return _.extend(
      {
        timesList: this.getTimeOptions(),
        weekdaysList: this.getWeekdayOptions(),
        weekday1: weekday1,
        weekday2: weekday2,
      },
      tplHelpers.apply(this)
    );
  },

  onRender: function() {
    this.toggleWeekdayDropdowns();
  },

  /**
   * Pushes updates from the DOM into the model and validate
   *
   * @param {Event} ev
   */
  saveForm: function(ev) {
    var name = ev.currentTarget.name;
    var value = ev.currentTarget.value;

    switch (name) {
      case 'weekday1':
      case 'weekday2':
        this.ui.weekday2.bs3ui('clearFieldError');
        this.saveWeekday(name, value);
        this.toggleWeekdayDropdowns();
        break;

      case 'auto-update-enable':
        this.model.set({enabled: value === 'on' && $(ev.target).is(':checked')});
        break;

      case 'start':
        this.ui.start.bs3ui('clearFieldError');
        this.model.set({start: value}, {commit: true});
        if (value) {
          this.ui.start.find('.no-value').remove();
        }
        break;

      default:
        break;
    }
  },

  /**
   * Handles updates to selected weekday 1 or 2
   *
   * @param {String} name
   * @param {String} value
   */
  saveWeekday: function(name, value) {
    var currentDays = this.model.get('weekdays');
    var newDays = currentDays ? currentDays.slice() : [];

    if (name === 'weekday1') {
      newDays[0] = value;
    } else if (name === 'weekday2') {
      if (value === '-1' && newDays.length > 1) {
        newDays.pop();
      } else if (value !== '-1') {
        newDays[1] = value;
      }
    }

    this.model.set({weekdays: newDays}, {commit: true});
  },

  /**
   * Updates weekday dropdown options/state
   */
  toggleWeekdayDropdowns: function() {
    var weekdays = this.model.get('weekdays');
    var weekday1Val = null;

    if (weekdays) {
      weekday1Val = _.get(weekdays, '0', null);
    }

    // remove the "none" option from weekday 1 if needed
    if (weekday1Val) {
      this.ui.weekday1.find('.no-value').remove();
    }

    // disable weekday2 unless there's a weekday1 selected
    this.ui.weekday2.prop('disabled', !weekdays || weekdays.length === 0);

    // disable the option in weekday2 that's already selected in weekday1
    this.ui.weekday2.children().each(function(index, element) {
      $(element).prop('disabled', element.value === weekday1Val);
    });
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/router/updateSchedule/EditUpdateSchedule} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.start) {
      this.ui.start.bs3ui('showFieldError', error.start);
    }

    if (error.weekdays) {
      this.ui.weekday2.bs3ui('showFieldError', error.weekdays);
    }
  },

  /**
   * Creates a list of available time ranges with labels
   *
   * @returns {Array}
   */
  getTimeOptions: function() {
    var interval = this.model.get('interval');
    return this.model.getTimesList().map(function(value) {
      return {
        value: value,
        label: dateFormatters.labelForTimeWindow(value, interval),
      };
    });
  },

  /**
   * Creates a list of available weekdays with labels
   *
   * @returns {Array}
   */
  getWeekdayOptions: function() {
    return this.model.getWeekdaysList().map(function(value) {
      return {
        value: value,
        label: dateFormatters.labelForWeekday(value),
      };
    });
  },
});
