'use strict';

var $ = require('jquery');
var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/loadBalance/form.html');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

/**
 * Renders the "load balance" form field.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/loadBalance/FormView#model
   * @type {actions/loadBalance/EditLoadBalance}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    'enabled': '[name="load-balance-enable"]',
    'wan1Load': '[name="wan1Load"]',
    'wan2Load': '[name="wan2Load"]',
    'messages': '.help-block',
  },

  events: {
    'change @ui.enabled': 'updateModel',
    'blur @ui.wan1Load': 'updateModel',
    'blur @ui.wan2Load': 'updateModel',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  getSecondWanLoad: function(wanLoad) {
    var load = (100 - wanLoad).toString();
    if (load === 'NaN') {
      load = '';
    }
    return load;
  },

  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;
    this.clearError('wan1Load');

    switch (name) {
      case 'wan1Load':
        this.model.set({wan1Load: value, wan2Load: this.getSecondWanLoad(value)}, {commit: true});
        $(this.ui.wan2Load).val(this.model.get('wan2Load'));
        break;
      case 'wan2Load':
        this.model.set({wan2Load: value, wan1Load: this.getSecondWanLoad(value)}, {commit: true});
        $(this.ui.wan1Load).val(this.model.get('wan1Load'));
        break;
      case 'load-balance-enable':
        if (value === 'yes') {
          this.model.set({enabled: true, wan1Load: '50', wan2Load: '50'}, {commit: true});
          $(this.ui.wan1Load).val(this.model.get('wan1Load'));
          $(this.ui.wan2Load).val(this.model.get('wan2Load'));
        } else {
          this.model.set({enabled: false}, {commit: true});
          if (this.model.get('primary') === 'vlan1') {
            $(this.ui.wan1Load).val(100);
            $(this.ui.wan2Load).val(0);
          } else {
            $(this.ui.wan1Load).val(0);
            $(this.ui.wan2Load).val(100);
          }
        }
        break;
      default:
        break;
    }
  },

  clearError: function(key) {
    this.ui[key].bs3ui('clearGroupedFieldError', key, this.ui.messages);
  },

  /**
   * @param {actions/loadBalance/EditLoadBalance} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

});
