'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/siteVpn/staticSiteVpn.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var _ = require('lodash');
var i18n = require('i18next');
var DnaSiteModel = require('actions/siteVpn/DnaSite/DnaSiteVpn');

module.exports = Marionette.View.extend({
  template: twig({data: tpl}),
  templateContext: function() {
    var context = {
      initiatorText: this._initiatorText,
      remoteMacText: this._remoteMacText,
      roleText: this._roleText,
      isDNA: this.model instanceof DnaSiteModel,
    };

    return _.extend(context, tplHelpers.apply(this));
  },

  _remoteMacText: function() {
    var selectedMac = this.remoteMac;

    if (!selectedMac || this.remoteMac === null) {
      return i18n.t('configEdit.fieldNoValues');
    }

    var device = _.find(this.availableDevices, function(availDevice) {
      return availDevice.deviceMac === selectedMac;
    });

    if (device) {
      return device.hostname;
    }

    return i18n.t('actionSiteVpn.unknownRemoteMacLabel');
  },

  _initiatorText: function(isInitiator) {
    if (isInitiator) {
      return i18n.t('actionSiteVpn.initiatorTrue');
    }

    return i18n.t('actionSiteVpn.initiatorFalse');
  },

  _roleText: function(role) {
    var roleText;
    switch (role) {
      case ('peer'):
        roleText = i18n.t('siteVpn.tunnelType.peer');
        break;
      case ('spoke'):
        roleText = i18n.t('siteVpn.tunnelType.spoke');
        break;
      case ('hub'):
        roleText = i18n.t('siteVpn.tunnelType.hub');
        break;
      default:
        roleText = '';
    }
    return roleText;
  },
});
