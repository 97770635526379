'use strict';

var i18n = require('i18next');
var EditVpnAuth = require('actions/vpn/auth/EditVpnAuth');
var FormView = require('actions/vpn/auth/FormView');
var StaticView = require('actions/vpn/auth/StaticView');

/**
 * Action configuration for the VPN enable/disable action component.
 */
module.exports = {
  title: i18n.t('vpn.auth.title'),
  Model: EditVpnAuth,
  FormView: FormView,
  TextView: StaticView,
};
