'use strict';

var _ = require('lodash');
var $ = require('jquery');
var ActionItem = require('actions/shared/ActionItem');
var Filters = require('actions/macFilter/Filters');

/**
 * Edit model for MAC Filtering
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/macFilter/EditMacFilter#attributes
   * @property {actions/editMacFilter} maclist
   *   Whitliested MAC addresses
   */

  defaults: {
    pendingDelete: false,
  },

  _snapshotAttributes: ['maclist', 'pendingDelete'],

  /**
   * @param {Object} attributes
   * @param {Object} options
   */
  initialize: function(attributes, options) {
    if (!this.has('maclist')) {
      this.set('maclist', new Filters(null, _.extend({deviceMac: this.deviceMac, wifiId: this.id}, options)));
    }
  },

  /**
   * @param {Object} options
   * @returns {Promise}
   */
  fetch: function(options) {
    return $.when(
      this.get('maclist').fetch(),
      ActionItem.prototype.fetch.apply(this, arguments)
    );
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (resp && _.isArray(resp.maclist)) {
      this.get('maclist').set(resp.maclist);
      resp = _.omit(resp, 'maclist');
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks').get(this.id);
      },
      events: 'change:macWhitelist',
    },
  ],

  /**
   * @return {Object}
   */
  getTask: function() {
    /**
     * We re-create the whole the WiFi network
     * So to remove MAC Filters we just don't send any on create
     */
    if (this.get('pendingDelete') === true) {
      return;
    }

    var macs = [];

    _.each(this.get('maclist').toJSON(), function(obj, key) {
      if (!obj.remove) {
        macs.push(obj.mac);
      }
    });

    return {
      name: 'wifi.create',
      data: {
        macfilter: 'allow',
        maclist: macs,
      },
    };
  },

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Array|false}
   */
  validate: function(attrs, options) {
    var errors = {};
    var invalid = [];
    var maclist = this.get('maclist');
    var macArr = _.pluck(maclist.toJSON(), 'mac');

    _.each(maclist.models, function(mac) {
      if (!mac.isValid()) {
        invalid.push(mac);
      }
    });

    // checking for duplicate MACs
    if (_.uniq(macArr).length !== macArr.length) {
      errors.duplicate = true;
    }

    if (invalid.length) {
      errors.invalid = invalid;
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Overridden to get the diff of the MAC filter maclist collection. Note,
   * all other model attributes are ignored.
   *
   * @return {Boolean|Object}
   */
  getSnapshotDiff: function() {
    return this.get('maclist').getSnapshotDiff() || this.get('pendingDelete') === true;
  },

  /**
   * Overridden to call takeSnapshot() on the maclist collection and individual mac.
   */
  takeSnapshot: function() {
    this.get('maclist').invoke('takeSnapshot');
    this.get('maclist').takeSnapshot();
    ActionItem.prototype.takeSnapshot.apply(this, arguments);
  },

  /**
   * Overridden to call applySnapshot() on the maclist collection and individual mac.
   */
  applySnapshot: function() {
    this.get('maclist').invoke('applySnapshot');
    this.get('maclist').applySnapshot();
    ActionItem.prototype.applySnapshot.apply(this, arguments);
  },

});
