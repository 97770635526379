'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var layoutTpl = require('manage/details/vlan/vlan-details.html');
var DetailsListView = require('manage/details/vlan/ConfigListView');
var DevicesListView = require('manage/details/ConnectedDevicesListView');
var Graph = require('components/usage/GraphItem');
var NetworksNav = require('manage/details/NetworksDropdown');

/**
 * Renders the selected VLAN View. Items displayed includes total usage
 * for the VLAN, list of connected devices and configuration.
 */
module.exports = Marionette.View.extend({
  // extends base template defined in manage/details/dna/DnaView
  template: twig({allowInlineIncludes: true, data: layoutTpl}),

  className: 'row',

  regions: {
    configDetails: '.rg-config-details',
    usage: '.rg-graphs',
    networksNav: '.rg-networks-nav',
  },

  ui: {
    devicesListWrap: '.devices',
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.model.fetch();
  },

  onRender: function() {
    this.showChildView('networksNav', new NetworksNav({
      deviceMac: this.model.get('deviceMac'),
      deviceConfig: this.model.get('deviceConfig'),
      current: this.model.id,
    }));

    this.addConfigDetails();
    this.addRegion('devicesList', '.rg-devices-list');
    this.addDevicesList();

    var id = this.model.get('id').toLowerCase();
    var usage = this.model.get('bandwidth');
    var deviceStatus = this.model.get('deviceStatus');

    var graphType = 'internal-network';

    if (this.model.get('role') === 'external') {
      graphType = 'external-network';
    }

    var graph = new Graph({
      id: id,
      usage: usage,
      deviceStatus: deviceStatus,
      graphType: graphType,
    });

    this.showChildView('usage', graph);

    // nav items are dynamic, so we default the first active one here
    this.$('.dui-tabs__item').first().addClass('dui-tabs__item--active');
  },

  /**
   * @return {Object}
   */
  serializeData: function() {
    var data = Marionette.View.prototype.serializeData.apply(this, arguments);

    // clean up bandwidth and statusCard; they are not used by the details Views.
    delete data.bandwidth;
    delete data.statusCard;

    return data;
  },

  templateContext: function() {
    return {
      detailsTitle: this.tplDetailsTitle,
      graphTitle: this.tplGraphTitle,
    };
  },

  /**
   * Gets details title (LAN Details vs WAN Details).
   * @memberof! manage/details/vlan/VlanView#
   * @method templateContext.detailsTitle
   * @return {String}
   */
  tplDetailsTitle: function() {
    if (this.role === 'internal') {
      return i18n.t('vlanDetails.lanDetails');
    }

    return i18n.t('vlanDetails.wanDetails');
  },

  /**
   * Gets usage graph title (LAN Network Usage vs WAN Network Usage).
   * @memberof! manage/details/vlan/VlanView#
   * @method templateContext.graphTitle
   * @return {String}
   */
  tplGraphTitle: function() {
    if (this.role === 'internal') {
      return i18n.t('vlanDetails.lanUsage');
    }

    return i18n.t('vlanDetails.wanUsage');
  },

  /**
   * Adds the "config details" section, which lists address details, etc.
   */
  addConfigDetails: function() {
    var attrs = ['deviceMac', 'deviceConfig', 'deviceStatus'];
    var viewOptions = this.model.pick(attrs);
    viewOptions.networkId = this.model.id;
    var listView = new DetailsListView(viewOptions);

    this.showChildView('configDetails', listView);
  },

  /**
   * Adds the list of devices connected to the current VLAN.
   */
  addDevicesList: function() {
    var attrs = ['deviceMac', 'deviceStatus', 'deviceConfig'];
    var viewOptions = this.model.pick(attrs);
    viewOptions.vlanId = this.model.id;
    var listView = new DevicesListView(viewOptions);

    this.showChildView('devicesList', listView);
  },
});
