'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var networkUtils = require('lib/network');

/**
 * Represents an individual DHCP option
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({

  /**
   * @member {Object} #attributes
   * @property {Boolean} remove
   *   Whether the reservation should be removed on save
   * @property {String} description
   *   Description of the option
   * @property {String} code
   *   Identifier of the option in DHCP protocol
   * @property {array} values
   *   List of object storing information needed for this option
   * @property {array} errors
   *   Last found errors with attributes
   */

  _snapshotAttributes: [
    'remove',
    'description',
    'optionId',
    'values',
    'errors',
  ],

  defaults: {
    'remove': false,
    'description': null,
    'optionId': null,
    'values': [],
    'availableOptions': {},
    'errors': {},
  },

  initialize: function(args) {
    this.on('change:availableOptions', this.availableOptionsChanged);
  },

  set: function(key, val, options) {
    var attrs;
    if (typeof key === 'object') {
      attrs = key;
      options = val;
    } else {
      (attrs = {})[key] = val;
    }

    if (_.has(attrs, 'values')) {
      var newValue = _.isUndefined(this.get('values')) ? [] : _.cloneDeep(this.get('values'));

      _.forEach(attrs.values, function(value) {
        // Find existing option if it exists
        var foundElm = _.find(newValue, function(elm) {
          return value.name === elm.name;
        });

        if (_.isUndefined(foundElm)) {
          value.placeholderText = this.getPlaceholderTextForName(value.name);
          newValue.push(value);
        } else {
          foundElm.value = value.value;
        }
      }.bind(this));

      attrs.values = newValue;
    }

    AdvancedValidationModel.prototype.set.call(this, attrs, options);
  },

  availableOptionsChanged: function() {
    if (!_.isEqual(this.get('availableOptions'), this.previous('availableOptions'))) {
      var currSelectedCode = _.isNull(this.get('optionId')) ?
                             _.keys(this.get('availableOptions'))[0] :
                             this.get('optionId');
      this.set('optionId', currSelectedCode);
    }
  },

  validate: function(attrs, options) {
    var errors = {};

    /**
     * The format should be (Suboption Code),(Suboption Value)(; if followed by another suboption)
     * \d{{1,3} - 1 to 3 characters of 0-9
     * , - literal comma for ending suboption code
     * [^,;]+ - one or more of any character except for a comma or semicolon
     * ; - literal semicolon for ending suboption
     * \d{1,3},[^,;]+ suboption code and value separated by a comma
     *
     * (?:\d{1,3},[^,;]+;)* - zero or more of semicolon terminated suboptions
     * (?:\d{1,3},[^,;]+) - last suboption without semicolon
     *
     * Ex: 1,1.1.1.1 or 1,2;4,3.3.3 not 1,he;,o;3,3.3
     */
    var suboptionRegex = '\\d{1,3},[^,;]+';
    var opt43Check = new RegExp('^(?:' + suboptionRegex + ';)*(?:' + suboptionRegex + ')$', 'm');

    if (this.get('remove') === true) {
      return;
    }

    if (_.has(attrs, 'values')) {
      var values = attrs.values;

      _.forEach(_.keys(values), function(index) {
        var option = values[index];
        var error = {name: option.name};

        switch (option.name) {
          case 'primaryDNS':
          case '42':
          case 'gatewayIP':
          case 'destIP':
            if (_.isEmpty(option.value) || !networkUtils.validIP(option.value)) {
              error.errorMsg = i18n.t('actionDhcp.advOptions.invalidIP');
            }
            break;
          case 'secondaryDNS':
            if (!_.isEmpty(option.value) && !networkUtils.validIP(option.value)) {
              error.errorMsg = i18n.t('actionDhcp.advOptions.invalidIP');
            }
            break;
          case '43':
            if (!opt43Check.test(option.value)) {
              error.errorMsg = i18n.t('actionDhcp.advOptions.invalidValueOption43');
            }
            break;
          case '66':
            if (_.isEmpty(option.value)) {
              error.errorMsg = i18n.t('actionDhcp.advOptions.invalidTftpServerName');
            }
            break;
          default:
            error.errorMsg = i18n.t('actionDhcp.advOptions.unsupportedOption');
            break;
        }

        if (!_.isUndefined(error.errorMsg)) {
          if (_.isUndefined(errors.values)) {
            errors.values = [];
          }
          errors.values.push(error);
        }
      }, this);
    }

    this.set('errors', errors);
    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return;
    }
    var valstr = _.chain(this.get('values'))
      .map(function(elm) {
        return elm.value;
      })
      .filter(function(value) {
        return !_.isEmpty(value);
      })
      .value()
      .join(',');

    // Create task for writing option
    var task = {
      name: 'dhcpAdvOptions.create',
      data: {
        code: this.get('optionId'),
        value: valstr,
      },
    };

    return task;
  },

  /**
   * t('actionDhcp.advOptions.option42Help')
   * t('actionDhcp.advOptions.option43Help')
   * t('actionDhcp.advOptions.option66Help')
   *
   * @param {string} name
   * @return {string}
   */
  getPlaceholderTextForName: function(name) {
    switch (name) {
      case '42':
      case '43':
      case '66':
        return i18n.t('actionDhcp.advOptions.option' + name + 'Help');
      case 'primaryDNS':
        return i18n.t('actionDhcp.advOptions.primaryDNS');
      case 'secondaryDNS':
        return i18n.t('actionDhcp.advOptions.secondaryDNS');
      case 'destIP':
        return i18n.t('actionDhcp.advOptions.staticRouteDestIP');
      case 'gatewayIP':
        return i18n.t('actionDhcp.advOptions.staticRouteGatewayIP');
      default:
        return '';
    }
  },

  updateInfoFromOptionId: function(optionId) {
    if (this.get('optionId') === optionId && !_.isEmpty(this.get('values'))) {
      return;
    }

    this.set('description', this.get('availableOptions')[optionId]);
    this.set('optionId', optionId);
    this.set('errors', {});

    switch (optionId) {
      case '6':
        this.attributes.values = [
          {
            name: 'primaryDNS',
            placeholderText: this.getPlaceholderTextForName('primaryDNS'),
            value: '',
          },
          {
            name: 'secondaryDNS',
            placeholderText: this.getPlaceholderTextForName('secondaryDNS'),
            value: '',
          },
        ];
        break;
      case '33':
        this.attributes.values = [
          {
            name: 'destIP',
            placeholderText: this.getPlaceholderTextForName('destIP'),
            value: '',
          },
          {
            name: 'gatewayIP',
            placeholderText: this.getPlaceholderTextForName('gatewayIP'),
            value: '',
          },
        ];
        break;
      default:
        this.attributes.values = [{
          name: optionId,
          placeholderText: this.getPlaceholderTextForName(optionId),
          value: '',
        }];
        break;
    }
  },
});
