'use strict';

var EditNetworkObject = require('actionGroups/editNetworkObject/EditNetworkObject');
var i18n = require('i18next');
var configPage = require('lib/configPage');

var staticProps = {
  isCreational: true,
  creationalPage: configPage.FIREWALL,
};

// set max number of network objects at 2048
var MAX_NETWORK_OBJECTS = 2048;

/**
 * "Create Network Object" configuration group.
 */
module.exports = EditNetworkObject.extend({
  defaults: {
    isDirty: true,
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.addNetworkObject');
  },

  /**
   * Indicates if the user can create a new network object.
   *
   * @param {config/ConfigModel} configOutline
   * @return {Boolean}
   */
  allowNew: function(configOutline) {
    var allowed = false;
    if (this.deviceStatus.hasCapability('supportsNetworkObject')) {
      var networkObjects = configOutline.filterGroups(function(group) {
        var type = group.get('type');
        return type === 'editNetworkObject' || type === 'createNetworkObject';
      });
      allowed = networkObjects.length < MAX_NETWORK_OBJECTS;
    }
    return allowed;
  },

  /**
   * Provides a user message indicating why a new Network Object
   * cannot be created.
   *
   * @return {String}
   */
  disallowedNewReason: function() {
    if (this.deviceConfig.get('firewall').get('networkObjects').length >= MAX_NETWORK_OBJECTS) {
      return i18n.t('groups.addNetworkObjectLimit', {limit: MAX_NETWORK_OBJECTS});
    }
    return i18n.t('configEdit.capabilityMissing');
  },
}, staticProps);
