'use strict';

/**
 * "Constants" used to identify different linkhealth options
 */
module.exports = {
  DISCONNECTED: 'disconnected',
  HEALTHY: 'healthy',
  PROBLEM: 'problem',
};
