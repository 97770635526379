'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/conditionalDns/rule/rule.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the editable form elements for a single conditional DNS forwarding rule.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/conditionalDns/FormView#model
   * @type {actions/conditionalDns/rule/Rule}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    host: '[name="host"]',
    destination: '[name="destination"]',
    remove: '.btn-remove',
    messages: '.help-block',
  },

  events: {
    'blur @ui.host': 'handleInputChanged',
    'blur @ui.destination': 'handleInputChanged',
    'click @ui.remove': 'handleRemoveButton',
  },

  modelEvents: {
    'change:remove': 'render',
    'invalid': 'onError',
    'noConflicts': 'onNoConflicts',
  },

  templateContext: function() {
    var context = {};
    return _.extend(context, tplHelpers.apply(this));
  },

  handleInputChanged: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'host':
        this.clearError('host');
        this.model.set({host: value}, {commit: true});
        break;

      case 'destination':
        this.clearError('destination');
        this.model.set({destination: value}, {commit: true});
        break;

      default: // required by linter
        break;
    }
  },

  handleRemoveButton: function() {
    if (this.model.isNew()) {
      // remove never-saved rules immediately
      this.model.collection.remove(this.model);
    } else {
      // flag existing rules for deletion upon save
      var shouldRemove = !this.model.get('remove');
      if (shouldRemove) {
        // if you ask to remove an existing rule, roll back any local changes
        // (otherwise it gets really confusing what you're actually removing)
        this.model.applySnapshot();
      }
      this.model.set({remove: shouldRemove});
    }
  },

  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

  clearError: function(key) {
    this.ui[key].bs3ui('clearGroupedFieldError', key, this.ui.messages);
  },

  onNoConflicts: function() {
    this.clearError('host');
  },
});
