'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('components/remoteSites/info.html');

module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  templateContext: function() {
    if (this.options.type === 'tunnel') {
      return {
        'remoteSubnets': this.options.remoteSubnets,
        'localSubnets': this.options.localSubnets,
      };
    }

    if (this.options.type === 'group') {
      var subnets = [];

      _.each(this.options.tunnels.models, function(tunnel) {
        subnets = subnets.concat(tunnel.get('remoteSubnets'));
        subnets = subnets.concat(tunnel.get('localSubnets'));
      });

      return {
        'availableSubnets': _.uniq(subnets),
      };
    }
  },
});
