'use strict';

var Backbone = require('backbone');
var $ = require('jquery');
var _ = require('lodash');

/**
 * View-model representing a empty placeholder action
 */
module.exports = Backbone.Model.extend({
  providesTask: false,
  _snapshotAttributes: [],
  applySnapshot: _.noop,
  takeSnapshot: _.noop,

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  fetch: function() {
    return ($.Deferred().resolve()).promise();
  },

  getSnapshotDiff: function() {
    return false;
  },
});
