'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var networkUtils = require('lib/network');

var PROTOCOLS = {
  TCP: 'tcp',
  UDP: 'udp',
  ALL: 'tcpudp',
};

/**
 * Represents an individual port forwarding rule
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({

  /**
   * @member {Object} #attributes
   * @property {Boolean} remove
   *   Whether the rule should be removed on save
   * @property {String} id
   *   ID of the rule
   * @property {String} name
   *   Name of the rule
   * @property {Boolean} enabled
   *   Whether the rule is enabled
   * @property {Number} srcPortRange
   *   Incoming port (or port range) to forward
   * @property {String} proto
   *   protocol to forward; one of the values "tcp"|"udp"|"tcpudp"
   * @property {String} destIp
   *   IP address to forward to
   * @property {Number} destPort
   *   Port(s) to forward to
   * @property {String} srcIp
   *   IP address for source (used for port forwards only)
   */

  idAttribute: 'redirectId',

  _snapshotAttributes: [
    'remove',
    'name',
    'enabled',
    'srcPortRange',
    'proto',
    'destIp',
    'destPort',
    'srcIp',
  ],

  defaults: {
    remove: false,
    name: null,
    enabled: true,
    srcPortRange: null,
    proto: PROTOCOLS.ALL,
    destIp: null,
    destPort: null,
    srcIp: null,
  },

  /**
   * @fires noConflicts
   *   Validation has determined this rule is free of conflicts with other rules
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true
        || this.get('enabled') === false) {
      // rules pending removal or that are disabled are never considered invalid
      return;
    }

    if (_.has(attrs, 'name')) {
      if (!attrs.name || !attrs.name.trim()) {
        errors.name = i18n.t('portForwarding.badRuleName');
      }
    }

    if (_.has(attrs, 'srcPortRange')) {
      if (!networkUtils.validPort(attrs.srcPortRange)
          && !networkUtils.validPortRange(attrs.srcPortRange)) {
        errors.srcPortRange = i18n.t('portForwarding.invalidPortRange');
      }
    }

    if (options.allItems && !errors.srcPortRange) {
      if (_.any(options.allItems, this.conflictsWithRule, this)) {
        errors.srcPortRange = i18n.t('portForwarding.portConflict');
      } else {
        // since rule conflict errors can be eliminated by editing the _other_
        // rule(s) involved, and backbone has no "valid" event, provide a way
        // for views to know that conflict errors on this rule should be cleared
        this.trigger('noConflicts');
      }
    }

    if (_.has(attrs, 'destIp')) {
      if (!networkUtils.validInternalIPv4(attrs.destIp)) {
        errors.destIp = i18n.t('portForwarding.badDestIp');
      }
    }

    if (_.has(attrs, 'destPort') && !this.isRange(this.get('srcPortRange'))) {
      if (!networkUtils.validPort(attrs.destPort)) {
        errors.destPort = i18n.t('portForwarding.badDestinationPort');
      }
    }

    if (_.has(attrs, 'srcIp') && attrs.srcIp) {
      if (!_.contains(_.pluck(this.get('netObjs'), 'description'), attrs.srcIp)
          && !networkUtils.validIP(attrs.srcIp)
          && !networkUtils.validSubnet(attrs.srcIp)) {
        errors.srcIp = i18n.t('portForwarding.badSrcIp');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return {
        name: 'firewall.removeFirewallSetting',
        data: {
          id: this.id,
        },
      };
    }

    if (this.isNew() || this.getSnapshotDiff()) {
      var srcNetObj = _.findWhere(this.get('netObjs'), {description: this.get('srcIp')});
      var task = {
        name: 'firewall.addPortForward', // Used for update if an ID is sent...
        data: {
          name: this.get('name'),
          enabled: this.get('enabled'),
          direction: 'inbound',
          srcPortRange: this.get('srcPortRange'),
          proto: this.get('proto'),
          destIp: this.get('destIp'),
          destPortRange: this.formatDestPort(),
          srcIp: this.get('srcIp'),
          srcNetObjId: srcNetObj ? srcNetObj.id : null,
        },
      };
      if (!this.isNew()) {
        // ... so include the ID if it's an update
        task.data.id = this.id;
      }
      return task;
    }
  },

  /**
   * Checks whether this rule conflicts with the provided rule.
   * Assumes that this rule has a valid source port or source port range.
   *
   * @param {actions/shared/portForwarding/rule} rule
   *   An instance of this model to check for conflicts
   * @return {Boolean}
   */
  conflictsWithRule: function(rule) {
    // a rule never conflicts with itself
    // and don't consider rules that are about to be removed
    // and don't consider rules that are disabled
    if (rule === this
        || rule.get('remove') === true
        || rule.get('enabled') === false) {
      return false;
    }

    // rules can't conflict if they don't apply to the same protocol
    var thisProtocol = this.get('proto');
    var otherProtocol = rule.get('proto');
    if (thisProtocol !== otherProtocol &&
        thisProtocol !== PROTOCOLS.ALL &&
        otherProtocol !== PROTOCOLS.ALL) {
      return false;
    }

    var thisSrcIp = this.get('srcIp') || '';
    var otherSrcIp = rule.get('srcIp') || '';

    if (thisSrcIp !== otherSrcIp) {
      // prohibit ambiguous rule - if either src ip is empty, then ports need to be different
      if (thisSrcIp !== '' && otherSrcIp !== '') {
        return false;
      }
    }

    // If rule doesn't have valid port, then they do not overlap
    if (!networkUtils.validPort(rule.get('srcPortRange'))
        && !networkUtils.validPortRange(rule.get('srcPortRange'))) {
      return false;
    }

    var startOne = this.getPortStart(this.get('srcPortRange'));
    var endOne = this.getPortEnd(this.get('srcPortRange'));
    var startTwo = this.getPortStart(rule.get('srcPortRange'));
    var endTwo = this.getPortEnd(rule.get('srcPortRange'));

    return !(startOne > endTwo || startTwo > endOne);
  },

  getPortStart: function(portRange) {
    var index = portRange.indexOf('-');
    if (index === -1) {
      return parseInt(portRange);
    }
    return parseInt(portRange.slice(0, index));
  },

  getPortEnd: function(portRange) {
    return parseInt(portRange.slice(portRange.indexOf('-') + 1));
  },

  isRange: function(portRange) {
    return portRange && portRange.indexOf('-') !== -1;
  },

  /**
   * Forces the destination ports to match the incoming ports in range mode
   *
   * @returns {String}
   */
  formatDestPort: function() {
    return this.isRange(this.get('srcPortRange'))
      ? this.get('srcPortRange')
      : this.get('destPort').trim();
  },

});
