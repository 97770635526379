'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tplHelpers = require('lib/tplHelpers');
var tpl = require('actions/router/timezone/form.html');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');
require('select2');

/**
 * Renders the "timezone" form field.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/router/timezone/FormView#model
   * @type {actions/router/timezone/EditTimezone}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    'timezone': '[name="timezone"]',
  },

  events: {
    'change @ui.timezone': 'saveForm',
    'blur @ui.timezone': 'saveForm',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  templateContext: function() {
    return _.extend(
      {
        timezoneList: this.model.timezonesList,
      },
      tplHelpers.apply(this)
    );
  },

  /**
   * Initiate select2 plugin.
   */
  onAttach: function() {
    this.ui.timezone.select2({width: '100%', theme: 'bootstrap'});
  },

  /**
   * Checks if timezone value has changed, validates and saves.
   *
   * @param {Event} ev
   */
  saveForm: function(ev) {
    var newTimezone = this.ui.timezone.val();
    ev.preventDefault();
    this.ui.timezone.bs3ui('clearFieldError');
    this.model.set({timezone: newTimezone}, {commit: true});
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/router/timezone/EditTimezone} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    this.ui.timezone.bs3ui('showFieldError', error.timezone);
  },
});
