'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var RuleView = require('actions/trafficPolicyRules/StaticRuleView');
var tpl = require('actions/trafficPolicyRules/static.html');
var twig = require('twig').twig;
var viewFilters = require('lib/viewFilters');

var RulesListView = Marionette.CollectionView.extend({
  childView: RuleView,
});

/**
 * Renders a text-only version of the "traffic policy rules" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/shared/trafficPolicy/StaticView#model
   * @type {actions/shared/trafficPolicy/EditTrafficPolicyRules}
   */

  template: twig({data: tpl}),

  regions: {
    rules: '.rg-rules',
  },

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      getItemCountHelp: viewFilters.getItemCountHelpFn({
        // i18n.t('trafficPolicy.noRules')
        emptyMsgKey: 'trafficPolicy.noRules',
        // i18n.t('trafficPolicy.viewAll', {count: count})
        moreMsgKey: 'trafficPolicy.viewAll',
      }),
    });
  },

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.showChildView('rules', new RulesListView({
      collection: this.model.get('items'),
    }));
  },

});
