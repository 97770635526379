'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');

var REQUIRED_CAPABILITY = 'supportsHostAccess';
/**
 * Edit model for VPN's "Allow Access To" settings.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} #attributes
   * @property {String[]} allowedNetworks
   *   Local networks that VPN users should be able to reach
   * @property {String} hostAccessEnabled
   *   if remote host access is enabled on for client vpn
   */

  _snapshotAttributes: ['allowedNetworks', 'hostAccessEnabled'],

  initialize: function(attributes, options) {
    // set this here rather than via defaults to prevent
    // instances from sharing the same default array
    if (!this.has('allowedNetworks')) {
      this.set({allowedNetworks: []});
    }

    this.addDeviceStatus(options);
    if (!this.deviceStatus.hasCapability(REQUIRED_CAPABILITY)) {
      this.deviceStatus.onCapabilityAdded(
        this,
        REQUIRED_CAPABILITY,
        this.fetch
      );
    }
  },

  /**
   * Overridden to always return false (VPN is a fixed group)
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  toggledOnOffBy: 'vpnEnable',

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var attrs = {};
      var networks;
      var vpn = this.deviceConfig.getVpn();
      if (vpn) {
        networks = vpn.get('allowedNetworks');

        attrs.id = vpn.id;
        attrs.allowedNetworks = networks ? networks.slice() : [];

        if (this.deviceStatus.hasCapability(REQUIRED_CAPABILITY)) {
          attrs.hostAccessEnabled = vpn.get('hostAccessEnabled') ? vpn.get('hostAccessEnabled') : false;
        }
      }
      return attrs;
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks');
      },
      // add, remove, and description to pick up changes to *available* LANs
      events: 'add remove change:description change:allowedNetworks change:hostAccessEnabled',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};
    var noSelectedNetworks = false;

    if (_.has(attrs, 'allowedNetworks')) {
      if (!attrs.allowedNetworks || attrs.allowedNetworks.length === 0) {
        noSelectedNetworks = true;
      }
    }

    if (_.has(attrs, 'hostAccessEnabled') && _.has(attrs, 'allowedNetworks')) {
      if (attrs.hostAccessEnabled === true) {
        noSelectedNetworks = false;
      }
    }

    if (noSelectedNetworks) {
      errors.allowedNetworks = i18n.t('actionVpn.routableRequired');
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    var data = {
      id: this.id,
      allowedNetworks: this.get('allowedNetworks'),
    };

    if (this.deviceStatus.hasCapability(REQUIRED_CAPABILITY)) {
      data.hostAccessEnabled = this.get('hostAccessEnabled');
    }

    return {
      name: 'vpn.setOptions',
      data: data,
    };
  },

});
