'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var Marionette = require('backbone.marionette');
var tpl = require('actions/lanPolicy/form.html');
var RenderChanges = require('lib/behaviors/RenderChanges');
var twig = require('twig').twig;

/**
 * Renders the LAN policies form action component.
 */
module.exports = Marionette.View.extend({

  behaviors: [{
    behaviorClass: RenderChanges,
    debounce: true, // combine with renders for lan change
  }],

  ui: {
    vlan1Policy: '[name="vlan1-policy"]',
    vlan3Policy: '[name="vlan3-policy"]',
    messages: '.help-block',
  },

  events: {
    'change @ui.vlan1Policy': 'updateModel',
    'change @ui.vlan3Policy': 'updateModel',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  onRender: function() {
    var vlan1Select2 = this.ui.vlan1Policy.select2({
      placeholder: i18n.t('actionLanPolicy.helpSelect2'),
      width: '100%',
      theme: 'bootstrap',
      closeOnSelect: false,
    });
    vlan1Select2.data('select2').$dropdown.addClass('multicontent');

    var vlan3Select2 = this.ui.vlan3Policy.select2({
      placeholder: i18n.t('actionLanPolicy.helpSelect2'),
      width: '100%',
      theme: 'bootstrap',
      closeOnSelect: false,
    });
    vlan3Select2.data('select2').$dropdown.addClass('multicontent');
  },

  template: twig({data: tpl}),

  updateModel: function(event) {
    this.clearError('messages');
    var lanIds = [];
    _.each(event.currentTarget.selectedOptions, function(opt) {
      lanIds.push(opt.value);
    });

    var policies = _.clone(this.model.get('lanPolicies'));
    var name = event.currentTarget.name;
    if (name === 'vlan3-policy') {
      policies.vlan3 = lanIds;
    } else if (name === 'vlan1-policy') {
      policies.vlan1 = lanIds;
    }
    this.model.set('lanPolicies', policies, {commit: true});
  },

  clearError: function(key) {
    this.ui[key].bs3ui('clearGroupedFieldError', key, this.ui.messages);
  },

  /**
   * @param {actions/lanPolicy/EditLanPolicy} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

});
