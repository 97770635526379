'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/wanCustomMac/static.html');
var twig = require('twig').twig;

/**
 * Renders a text-only version of the "WAN custom mac" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/wanCustomMac/StaticView#model
   * @type {actions/wanCustomMac/EditWanCustomMac}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    return {
      customMac: this.model.get('customMac'),
    };
  },
});
