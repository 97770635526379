'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var tpl = require('layouts/appLayout/layout.html');
var Radio = require('backbone.radio');
var twig = require('twig').twig;
var layoutChannel = Radio.channel('layoutChannel');
var LoadingView = require('layouts/appLayout/LoadingView');
require('lib/jquery/onAnimationEnd');
var userChannel = Radio.channel('userChannel');
var DevicesList = require('manage/list/DevicesListView');
var DeviceDetailsLayout = require('manage/detailsLayout/DeviceDetailsLayout');
var DeviceDetailsLayoutModel = require('manage/detailsLayout/DeviceDetailsLayoutModel');
var macChannel = Radio.channel('mac');

/**
 * Base view for the application.
 */
module.exports = Marionette.View.extend({
  template: twig({allowInlineIncludes: true, data: tpl}),

  regions: {
    devicesList: '.rg-devices-list',
    deviceDetails: '.rg-device-details',
  },

  ui: {
    header: '.app-header',
    mainContent: '.app-main',
    devicesList: '.rg-devices-list',
    deviceDetails: '.rg-device-details',
  },

  events: {
    'click [data-tag="app-home"]': 'onHome',
    'click [data-tag="app-logout"]': 'onLogout',
  },

  /**
   * Indicates if the app is rendering for the first time. If it is, the whole
   * UI is faded in (instead of letting the Views appear at different times).
   *
   * @type {Boolean}
   */
  firstLoad: true,

  /**
   * Base path to use when changing the URL.
   *
   * @type {String}
   */
  basePageUrl: '',

  /**
   * @param {Object} options
   * @property {String} options.deviceMac
   * @property {String} options.page
   *   (optional) the portion of the url w/o the DNA's mac
   */
  initialize: function(options) {
    var user = Radio.request('userChannel', 'get:user');

    _.bindAll(this, 'showLoader', 'removeLoader', 'hideOverflow', 'changePage');

    this.mergeOptions(options, ['deviceMac', 'page']);
    this.devicesList = user.get('devices');

    // TODO refactor loading graphic logic to an object or behavior
    this.itemsLoading = [];

    layoutChannel.reply({
      'show:loader': this.showLoader,
      'destroy:loader': this.removeLoader,
      'confine:animation': this.hideOverflow,
      'change:page': this.changePage,
    });
  },

  onRender: function() {
    if (this.firstLoad) {
      // Initially hides the Views. On first load, the whole UI is faded in.
      this.ui.header.addClass('hidden');
      this.ui.mainContent.addClass('hidden');
    }

    if (!this.getRegion('devicesList').hasView()) {
      this.addDevicesListView();
    }

    if (!_.isUndefined(this.deviceMac)) {
      this.addDeviceDetailsView();
    }
  },

  onAttach: function() {
    var win = $(window);
    win.resize(_.debounce(function() {
      layoutChannel.trigger('window:resized', win.height());
    }, 500));
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    var user = userChannel.request('get:user');

    return {
      /**
       * @memberof! layouts/appLayout#
       * @type {String}
       */
      displayName: user.get('displayName'),
      // only supplied when user is swapped to another reseller
      ssoUser: user.get('ssoUser'),
    };
  },

  /**
   * Adds the "Fleet Overview" View.
   */
  addDevicesListView: function() {
    var devicesListView = new DevicesList({
      collection: this.devicesList,
    });

    this.showChildView('devicesList', devicesListView);
  },

  /**
   * Adds the DNA overview/manage View.
   */
  addDeviceDetailsView: function() {
    var viewModel;

    if (_.isUndefined(this.devicesList.get(this.deviceMac))) {
      // DNA doesn't exist, send user back to Fleet Overview
      Backbone.history.navigate(this.basePageUrl);
      this.toggleDevicesList();
      return;
    }

    document.getElementsByTagName('title')[0].textContent = 'DNA - ' + this.deviceMac;
    this.ui.devicesList.hide();
    this.ui.deviceDetails.show();

    viewModel = new DeviceDetailsLayoutModel({
      page: this.page,
      devicesList: this.devicesList,
    });

    viewModel.setDevice(this.deviceMac);

    this.showChildView('deviceDetails', new DeviceDetailsLayout({model: viewModel}));
  },

  /**
   * Drops a "loading..." element to the screen.
   *
   * @listens layoutChannel.reply~show:loader
   * @param {Object} id
   *   The object asking for the loader. This ensures that if multiple items
   *   are "loading", the first one to finish does not remove it.
   * @param {Object} options
   *   Options are passed to a {@link layouts/appLayout/LoadingView} View.
   */
  showLoader: function(id, options) {
    this.itemsLoading.push(id);

    if (options && options.text && this.firstLoad) {
      // On first load, we only want to show the default loading text since
      // there is no UI being displayed yet.
      delete options.text;
    }

    if (this.itemsLoading.length === 1) {
      this.loader = new LoadingView(options);
      this.loader.render();
      this.$el.append(this.loader.el);

      if (!this.firstLoad) {
        this.ui.mainContent.css('opacity', '0.4');
      }
    } else if (!_.isUndefined(options)) {
      // Override loader settings and rerender it
      this.loader.model.set(options);
      this.loader.render();
    }
  },

  /**
   * Removes the "loading..." element.
   *
   * @listens layoutChannel.reply~destroy:loader
   * @param {Object} id
   */
  removeLoader: function(id) {
    var animate = function(element) {
      var animateClasses = 'animated fadeIn quick';
      element
        .removeClass('hidden')
        .addClass(animateClasses)
        .onAnimationEnd(function() {
          element.removeClass(animateClasses);
        });
    };

    this.itemsLoading = _.filter(this.itemsLoading, function(item) {
      return item !== id;
    });

    if (this.itemsLoading.length === 0) {
      this.loader.destroy();

      if (!this.firstLoad) {
        this.ui.mainContent.css('opacity', '1');
      } else {
        animate(this.ui.header);
        animate(this.ui.mainContent);
        this.firstLoad = false;
      }
    }
  },

  /**
   * Responds to a nav change.
   *
   * @listens layoutChannel.request~change:page
   * @param {Object} options
   * @property {String} options.deviceMac (optional)
   * @property {String} options.page (optional)
   */
  changePage: function(options) {
    this.deviceMac = options.deviceMac;
    this.page = options.page;

    if (!_.isUndefined(options.deviceMac)) {
      var detailsView = this.getChildView('deviceDetails');

      if (!detailsView || detailsView.model.get('deviceMac') !== this.deviceMac) {
        // Navigating to another DNA
        this.addDeviceDetailsView();
      } else {
        // changing page
        detailsView.model.set('page', this.page);
      }
    } else {
      // show fleet
      this.toggleDevicesList();
    }

    // This is here for the case where the user clicks a link in the middle of
    // the page.
    window.scrollTo(0, 0);
  },

  /**
   * Callback for the Logout link.
   *
   * @fires userChannel.request#logout:user
   * @param {Event} ev
   */
  onLogout: function(ev) {
    ev.preventDefault();
    userChannel.request('logout:user');
  },

  /**
   * Callback for logo link (Home)
   *
   * @param {Event} ev
   */
  onHome: function(ev) {
    ev.preventDefault();
    Backbone.history.navigate('');
    this.changePage({});
  },

  /**
   * Adds "overflow:hidden" to the main content area and when the passed
   * element has finished animating, "overflow:hidden" is cleaned up.
   *
   * When using CSS animations (e.g. sliding an element in from the side)
   * there may be a need put overflow:hidden on the main content wrapper to
   * prevent the scrollbar from appearing during the animation.
   *
   * @listens layoutChannel.reply~confine:animation
   * @param {jQuery} elm
   *   The element that will be animated.
   */
  hideOverflow: function(elm) {
    var wrapper = this.ui.mainContent;
    wrapper.css('overflow', 'hidden');
    elm.onAnimationEnd(function() {
      wrapper.css('overflow', '');
    });
  },

  /**
   * Shows the devices list View.
   */
  toggleDevicesList: function() {
    document.getElementsByTagName('title')[0].textContent = 'DNA';
    this.ui.devicesList.show();
    this.ui.deviceDetails.hide();
    macChannel.trigger('mac:change');

    // Wipe out the deviceDetails region; it will be rerendered the next time
    // a device is selected.
    this.getRegion('deviceDetails').reset();
  },
});
