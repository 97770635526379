'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/vpn/enable/form.html');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

/**
 * Renders the "enabled" form field.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/vpn/enable/FormView#model
   * @type {actions/vpn/enable/EditVpnEnabled}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    'enable': '[name="vpn-enable"]',
  },

  events: {
    'change @ui.enable': 'saveForm',
  },

  /**
   * Sync changes in UI input elements to the model
   *
   * @param {Object} event
   */
  saveForm: function(event) {
    var name = event.currentTarget.name;
    switch (name) {
      case 'vpn-enable':
        var enabled = event.currentTarget.checked && event.currentTarget.value === 'true';
        this.model.set({enabled: enabled});
        break;

      default:
        break;
    }
  },

});
