'use strict';

var Backbone = require('backbone');
var rpcMixin = require('lib/mixins/rpc2');

module.exports = Backbone.Collection.extend(rpcMixin).extend({
  rpc: {
    read: {
      method: 'DNA.Portal.ConfigRestore.getSavedConfigurations',
      params: function() {
        return {
          mac: this.mac,
        };
      },
    },
  },

  initialize: function(models, options) {
    this.mac = options.deviceMac;
  },

  /**
   * @param {Object} resp
   * @return {Array}
   */
  parse: function(resp) {
    if (resp.result !== false) {
      return resp.result;
    }

    return resp;
  },

  /**
   * When a new configuration comes up from the event stream
   * we need to push it onto the top of our configurations stack
   *
   * @param {Object} config
   */
  addNewConfiguration: function(config) {
    var hasConfig = this.get(config.id);

    if (!hasConfig) {
      this.unshift({
        configId: config.id,
        timestamp: config.timestamp,
      });
    }
  },
});
