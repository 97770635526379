'use strict';

var $ = require('jquery');
var Marionette = require('backbone.marionette');
var tpl = require('actions/alertConfiguration/priority/priority.html');
var twig = require('twig').twig;

module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  ui: {
    checkbox: '[name="priority"]',
  },

  events: {
    'click @ui.checkbox': 'onEnableToggle',
  },

  onEnableToggle: function(event) {
    var $el = $(event.currentTarget);
    this.model.set({'enabled': $el.is(':checked')}, {commit: true});
  },
});
