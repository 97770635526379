'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var tplHelpers = require('lib/tplHelpers');
var formTpl = require('actions/wanDns/form.html');
var RemoveConfig = require('manage/edit/config/action/removeConfig/RemoveConfig');
var RenderChanges = require('lib/behaviors/RenderChanges');

// nameserver presets
var presets = {
  'google': ['8.8.8.8', '8.8.4.4'],
  'opendns': ['208.67.222.222', '208.67.220.220'],
};

/**
 * Renders the list of DNS fields available to an external VLAN.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/wanDns/FormView#model
   * @type {actions/wanDns/EditWanDns}
   */

  behaviors: [
    {
      behaviorClass: RemoveConfig,
    },
    {
      behaviorClass: RenderChanges,
    },
  ],

  template: twig({data: formTpl}),

  ui: {
    preset: '[name="ns-presets"]',
    nsFields: '.custom-ns-wrap',
    ns1: '[name="ns1"]',
    ns2: '[name="ns2"]',
  },

  events: {
    'change @ui.preset': 'checkNameserverPreset',
    'blur input[type="text"]': 'checkDns',
  },

  modelEvents: {
    'change:af': 'render',
    'invalid': 'onError',
  },

  onRender: function() {
    this.toggleNameserverPreset();
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    var self = this;
    return _.extend(tplHelpers.apply(this), {
      ipExample: function() {
        if (self.model.get('af') === 'ipv4') {
          return i18n.t('actionWanDns.dnsIpv4Example');
        }
        return i18n.t('actionWanDns.dnsIpv6Example');
      },
      pendingDeleteMsg: this.model.getDeleteMsg.bind(this.model),
    });
  },

  /**
   * Blur event handler to save (and validate) individual DNS fields.
   *
   * @param {Event} ev
   */
  checkDns: function(ev) {
    var name = ev.currentTarget.name;
    var value = ev.currentTarget.value;
    var data = {};

    if (name === 'ns1') {
      this.ui.ns1.bs3ui('clearFieldError');
      data.ns1 = value;
    } else if (name === 'ns2') {
      this.ui.ns2.bs3ui('clearFieldError');
      data.ns2 = value;
    }

    this.model.set(data, {commit: true});
  },

  /**
   * "Change" event handler for Nameservers select box.
   *
   * @param {Event} ev
   */
  checkNameserverPreset: function(ev) {
    var self = this;
    var presetName = ev.currentTarget.value;
    ev.preventDefault();

    if (presetName === 'google' || presetName === 'opendns') {
      this.ui.nsFields.slideUp({
        complete: function() {
          self._setFieldPresets(presets[presetName]);
        },
      });
    } else {
      self._setFieldPresets(['', '']);
      this.ui.nsFields.slideDown();
    }
  },

  /**
   * Toggles the visibility of the Primary and Secondary Nameserver fields.
   */
  toggleNameserverPreset: function() {
    var self = this;
    var nameservers = _.toArray(this.model.pick('ns1', 'ns2'));
    var selectPreset;

    this.ui.preset.find('option').prop('selected', false);

    selectPreset = function(preset) {
      self.ui.preset.find('[value="' + preset + '"]').prop('selected', true);
      self.ui.nsFields.hide();
    };

    if (_.isEqual(nameservers, presets.google)) {
      selectPreset('google');
    } else if (_.isEqual(nameservers, presets.opendns)) {
      selectPreset('opendns');
    } else {
      this.ui.nsFields.show();
    }
  },

  /**
   * Decorates field on error.
   *
   * @param {actions/vlan/WanDns} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.ns1) {
      this.ui.ns1.bs3ui('showFieldError', error.ns1);
    }

    if (error.ns2) {
      this.ui.ns2.bs3ui('showFieldError', error.ns2);
    }
  },

  /**
   * sets field values those for a preset (Either google or OpenDNS)
   *
   * @private
   * @param {Array} fieldValues
   */
  _setFieldPresets: function(fieldValues) {
    var self = this;

    _.each(['ns1', 'ns2'], function(field, index) {
      self.ui[field].val(fieldValues[index]);
      self.ui[field].trigger('blur');
    });
  },

});
