'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var linkHealthTypes = require('manage/details/ports/linkHealthTypes');

var ALLOWED_FAILURE_THRESHOLD = 3;

/**
 * Helper for getting current state of the ports on the DNA from configuration and status to display onto the
 * UI.
 */

module.exports = {
  /**
   * Returns only native ports (eth0 & 1 & 2 & 3 & 4 & 5)
   *
   * @param {lib/models/DeviceConfig} deviceConfig
   * @param {lib/models/DeviceStatus} deviceStatus
   *
   * @return {Object}
   * @see formatNativePort for a run down of the contents of the object.
   */
  getNativePorts: function(deviceConfig, deviceStatus) {
    return this._getPorts(deviceConfig, deviceStatus).filter(function(port) {
      return port.isBond === false;
    });
  },

  /**
   * Returns only free'd up native ports.
   * @param {lib/models/DeviceConfig} deviceConfig
   * @param {lib/models/DeviceStatus} deviceStatus
   *
   * @return {Object}
   * @see formatNativePort for a run down of the contents of the object.
   */
  getUnboundPorts: function(deviceConfig, deviceStatus) {
    return this._getPorts(deviceConfig, deviceStatus).filter(function(port) {
      return port.isBond === false && _.isUndefined(port.bondId);
    });
  },

  /**
   * Returns only bonds.
   * @param {lib/models/DeviceConfig} deviceConfig
   * @param {lib/models/DeviceStatus} deviceStatus
   *
   * @return {Object}
   * @see formatAggregatedPort for a run down of the contents of the object.
   */
  getBondPorts: function(deviceConfig, deviceStatus) {
    return this._getPorts(deviceConfig, deviceStatus).filter(function(port) {
      return port.isBond === true;
    });
  },

  /**
   * Returns all ports (bonds and native).
   *
   * @param {lib/models/DeviceConfig} deviceConfig
   * @param {lib/models/DeviceStatus} deviceStatus
   *
   * @return {Object}
   * @see formatNativePort and
   * @see formatAggregatedPort for a run down of the contents of this object.
   */
  getAllPorts: function(deviceConfig, deviceStatus) {
    return this._getPorts(deviceConfig, deviceStatus);
  },

  _getPorts: function(deviceConfig, deviceStatus) {
    var vlans = deviceConfig.getVlans();
    var bondConfig = deviceConfig.get('nicBonds');

    var portsMap = this._setUpBasicInfoForNativePorts(deviceConfig, deviceStatus);
    this._setBoundPortAssociation(portsMap, bondConfig);
    this._constructBondPortsMap(portsMap, bondConfig, deviceConfig, deviceStatus);
    this._setUpVlanInfo(portsMap, vlans, bondConfig);
    return _.toArray(portsMap);
  },

  _getPhysicalPorts: function() {
    return [
      {id: 'eth0', portNumber: 1, type: 'wan'},
      {id: 'eth1', portNumber: 2, type: 'wan'},
      {id: 'eth2', portNumber: 3, type: 'lan'},
      {id: 'eth3', portNumber: 4, type: 'lan'},
      {id: 'eth4', portNumber: 5, type: 'lan'},
      {id: 'eth5', portNumber: 6, type: 'lan'},
    ];
  },

  /**
   * Splits the ports list in half, this is as per the way we want our UI to look with 2 columns of ports.
   * We are not deliberately trying to match the port look on the back of the DNA however.
   * @param {Array} portsList
   * @return {Array }
   */
  splitPortsByLength: function(portsList) {
    return _.partition(portsList, function(port, i) {
      return i < (portsList.length / 2);
    });
  },

  splitPortsByPortNumber: function(portsList) {
    var self = this;
    return _.partition(portsList, function(port) {
      return self._getPhysicalPort(port.id).portNumber % 2 !== 0;
    });
  },

  addDefaultInfoToPortConfig: function(port) {
    var physicalPort = this._getPhysicalPort(port.id);
    port.portNumber = physicalPort.portNumber;
    port.type = physicalPort.type;
  },

  _setUpBasicInfoForNativePorts: function(deviceConfig, deviceStatus) {
    var newList = {};
    var self = this;
    _.each(this._getPhysicalPorts(), function(port) {
      newList[port.id] = self.formatNativePort(deviceConfig, deviceStatus, port.id);
    });

    return newList;
  },

  /**
   * Used to format data attributes for a given native port.
   *
   * @param {lib/models/DeviceConfiguration} deviceConfig
   * @param {lib/models/DeviceStatus} deviceStatus
   * @param {String} portId - ethX
   * @returns {Object}
   *    Contents of object:
   *    {
   *      id: 'ethX',
   *      isUp: false|true, (is the port connected)
   *      nativeTo: vlanX,
   *      taggedBy: [vlanX, vlanY],
   *      bondDescription: undefined|'bond description',
   *      bondId: undefined|'bondX',
   *      isBond: true|false,
   *      linkHealth: health|problem|disconnected,
   *      portNumber: X,
   *      mac: XX:XX:XX:XX:XX:XX,
   *      speed: 0|1000 (usually),
   *      duplex: full|half,
   *      type: wan|lan,
   *      configuredSpeed: 1000|100|10|auto,
   *      configuredSpeed: full|half|auto,
   *      autoNegotiated: true|false
   *    }
   */
  formatNativePort: function(deviceConfig, deviceStatus, portId) {
    var self = this;
    var portsWithStatusUp = self._getPortsWithStatusUp(deviceStatus);
    var tmpAttr = {};
    tmpAttr.id = portId;

    // This is used for ports action and basic plugged in or not.
    // Ports View uses this in action cards (vlan card)
    // also the green dot ports view in vlan details also uses this.
    var isUp = _.indexOf(portsWithStatusUp, portId) >= 0;
    tmpAttr.up = isUp;
    tmpAttr.nativeTo = undefined;
    tmpAttr.taggedBy = [];
    tmpAttr.bondDescription = undefined;
    tmpAttr.bondId = undefined;

    // specifies if port being looked at is a bond (bond1 bond2 ...)
    tmpAttr.isBond = false;

    var status = null;
    if (deviceStatus.get('ports').has(portId)) {
      status = deviceStatus.get('ports').get(portId);
    }

    /**
     * The value of linkHealth is used for the badge color as a css selector
     * and is used as a key for the translation
     */
    tmpAttr.linkHealth = self._getLinkHealth(isUp);
    self._getCommonPortStatusInformation(tmpAttr, deviceConfig, status, portId);

    return tmpAttr;
  },

  _getCommonPortStatusInformation: function(portObj, deviceConfig, status, portId) {
    var self = this;
    var physicalPort = self._getPhysicalPort(portObj.id);
    portObj.portNumber = physicalPort.portNumber;
    portObj.type = physicalPort.type;

    /**
     * i18n.t('portDetails.duplexSettings.full') = Full
     * i18n.t('portDetails.duplexSettings.half') = Half
     * i18n.t('portDetails.duplexSettings.unknown') = Unknown
     */
    portObj.duplex = status && status.has('duplex') ? status.get('duplex') : 'unknown';
    portObj.speed = status && status.has('speed') && status.get('speed') !== '-1' ? status.get('speed') : 0;
    portObj.mac = status && status.has('mac') ? status.get('mac') : null;

    var portConfig;
    if (!_.isUndefined(deviceConfig.get('ports')) && deviceConfig.get('ports').has(portId)) {
      portConfig = deviceConfig.get('ports').get(portId);
    }

    portObj.configuredSpeed = portConfig && portConfig.has('configuredSpeed') ?
      portConfig.get('configuredSpeed') : undefined;

    portObj.configuredDuplex = portConfig && portConfig.has('configuredDuplex') ?
      portConfig.get('configuredDuplex') : undefined;

    // Auto negotiation is all or nothing. If speed and duplex are not configured than it is auto negotiated.
    // The UI will not allow speed to be saved without a duplex and vice versa.
    portObj.autoNegotiated = !(portObj.configuredSpeed && portObj.configuredDuplex) ? true :
      (portObj.configuredSpeed === 'auto' && portObj.configuredDuplex === 'auto');
  },

  /**
   * Used to set a ports bond association. If enslaved these options will be set.
   *
   * @param {Object} portsMap
   * @param {Object} bondConfig
   * @private
   */
  _setBoundPortAssociation: function(portsMap, bondConfig) {
    bondConfig.models.forEach(function(bond) {
      _.each(bond.get('portsMap'), function(enslavedPortId) {
        portsMap[enslavedPortId].bondDescription = bond.get('description');
        portsMap[enslavedPortId].bondId = bond.id;
      });
    });
  },

  /**
   * used to retrieve info for all bonds configured on this system.
   *
   * @param {Object} portsMap
   * @param {Object} bonds
   * @param {lib/models/DeviceConfiguration} deviceConfig
   * @param {lib/models/DeviceStatus} deviceStatus
   * @private
   */
  _constructBondPortsMap: function(portsMap, bonds, deviceConfig, deviceStatus) {
    var self = this;
    bonds.each(function(nicBond) {
      portsMap[nicBond.id] = self.formatAggregatedPort(nicBond, deviceConfig, deviceStatus);
    });
  },

  /**
   * Used to format data attributes for a bond.
   *
   * @param {Object} nicBond
   * @param {lib/models/DeviceConfiguration} deviceConfig
   * @param {lib/models/DeviceStatus} deviceStatus
   * @returns {Object}
   *    Contents of object:
   *    {
   *      id: 'bondX',
   *      isUp: false|true, (is the port connected)
   *      nativeTo: vlanX,
   *      taggedBy: [vlanX, vlanY],
   *      description: 'bond description',
   *      isBond: true,
   *      linkHealth: health|problem|disconnected,
   *      portName: 'bond description (ethX + ethY ...),
   *      virtualMac: XX:XX:XX:XX:XX:XX,
   *      speed: 0|2000 (usually),
   *      duplex: full|half
   *      mode: 0|1 (passive|active),
   *      lacpRate: 'slow|fast',
   *      childrenPorts: [
   *        {
   *          id: 'ethX',
   *          partnerPort: 11|XX,
   *          linkFailures: 0|XX,
   *          partnerMac: XX:XX:XX:XX:XX:XX
   *          linkHealth: health|problem|disconnected,
   *          portNumber: X,
   *          mac: XX:XX:XX:XX:XX:XX,
   *          speed: 0|1000 (usually),
   *          duplex: full|half
   *          type: wan|lan,
   *          configuredSpeed: 1000|100|10|auto,
   *          configuredSpeed: full|half|auto,
   *          autoNegotiated: true|false
   *        },
   *
   *        {...}
   *      ]
   *    }
   */
  formatAggregatedPort: function(nicBond, deviceConfig, deviceStatus) {
    var self = this;
    var nicBondPortsMap = nicBond.get('portsMap');
    var portsWithStatusUp = self._getPortsWithStatusUp(deviceStatus);
    var bondId = nicBond.id;
    var bondStatus = null;
    if (deviceStatus.get('nicBonds').has(bondId)) {
      bondStatus = deviceStatus.get('nicBonds').get(bondId);
    }

    var linkHealth;
    var isUp = false;
    var connectedChildrenCount = _.intersection(nicBondPortsMap, portsWithStatusUp).length;
    // For the bond to be "simply up" we just require it to have 1 or more links up.
    if (connectedChildrenCount > 0) {
      isUp = true;
    }

    // if the intersected array length is the same as the nic bonds ports map and if the link failures
    // of a port in a bond is not above 3 failures since boot then it is healthy.
    if (connectedChildrenCount === nicBondPortsMap.length) {
      linkHealth = linkHealthTypes.HEALTHY; // used to show different badge colors

      // if the failure count is above 3 we have a problem.
      if (bondStatus && self._isLinkFailureForNicBondPortsMapAboveThreshold(bondStatus.get('slaves'))) {
        linkHealth = linkHealthTypes.PROBLEM;
      }
    } else if (_.inRange(connectedChildrenCount, 1, nicBondPortsMap.length)) {
      // There is at least 1 port down flag this as a problem
      linkHealth = linkHealthTypes.PROBLEM;
    } else {
      // 0 ports are up - this is bad.
      linkHealth = linkHealthTypes.DISCONNECTED;
    }

    var portsList = nicBondPortsMap.map(function(port) {
      return self._getPhysicalPort(port).portNumber;
    }).join(i18n.t('nicBond.portSeparator'));

    var childrenPorts = self._constructChildrenPortsForBond(nicBondPortsMap, bondId, deviceConfig, deviceStatus);
    var configuredSpeed = _.reduce(childrenPorts, function(memo, childPort) {
      if (childPort.configuredSpeed !== 'auto') {
        return parseInt(memo) + parseInt(childPort.configuredSpeed);
      }

      return 'auto';
    }, 0);

    // This is a lil janky - I get it but hear me out:
    //    if we do our jobs correctly on validating configured duplex and speed settings then we
    //    "SHOULD" be guaranteed that when configuredSpeed is auto so is the configured duplex.
    var configuredDuplex = configuredSpeed === 'auto' ? 'auto' : childrenPorts[0].configuredDuplex;
    var autoNegotiated = (configuredDuplex === 'auto' && configuredSpeed === 'auto');

    return {
      id: bondId,
      description: nicBond.get('description'),
      up: isUp,
      linkHealth: linkHealth,
      nativeTo: undefined,
      taggedBy: [],
      isBond: true,
      portName: i18n.t('nicBond.fullName', {description: nicBond.get('description'), ports: portsList}),
      virtualMac: nicBond.get('virtualMac'),
      duplex: bondStatus && bondStatus.has('duplex') ? bondStatus.get('duplex') : 'unknown',
      speed: bondStatus && bondStatus.has('speed') && bondStatus.get('speed') !== '-1' ? bondStatus.get('speed') : 0,
      /**
       * Not the bonding MODE! Mode here represents 0/1 - passive/active
       *    i18n.t('portDetails.bondMode.0') = Passive
       *    i18n.t('portDetails.bondMode.1') = Active
       *    i18n.t('portDetails.bondMode.-') = Unknown
       */
      mode: bondStatus && bondStatus.has('mode') ? bondStatus.get('mode') : '-',
      /**
       * i18n.t('portDetails.lacp.slow') = Slow
       * i18n.t('portDetails.lacp.fast') = Fast
       */
      lacpRate: nicBond.get('lacpRate'),
      childrenPorts: childrenPorts,
      configuredSpeed: String(configuredSpeed),
      configuredDuplex: configuredDuplex,
      autoNegotiated: autoNegotiated,
    };
  },

  /**
   *
   * @param {Array} nicBondPortsMap
   * @param {String} bondId
   * @param {lib/models/DeviceConfiguration} deviceConfig
   * @param {lib/models/DeviceStatus} deviceStatus
   * @returns {Array}
   * @private
   */
  _constructChildrenPortsForBond: function(nicBondPortsMap, bondId, deviceConfig, deviceStatus) {
    var self = this;
    var tmpArray = [];
    _.each(nicBondPortsMap, function(enslavedPort) {
      var isUp = _.indexOf(self._getPortsWithStatusUp(deviceStatus), enslavedPort) >= 0;
      var slaveStatus = null;
      if (deviceStatus.has('nicBonds') && deviceStatus.get('nicBonds').get(bondId)) {
        slaveStatus = _.find(deviceStatus.get('nicBonds').get(bondId).get('slaves'), function(slave) {
          return slave.id === enslavedPort;
        });
      }

      var portStatus = deviceStatus.get('ports').get(enslavedPort);

      var childPort = {
        id: enslavedPort,
        partnerPort: slaveStatus && slaveStatus.partnerPort ? slaveStatus.partnerPort : null,
        linkFailures: slaveStatus && slaveStatus.linkFailureCountSinceBoot ?
          slaveStatus.linkFailureCountSinceBoot : null,
        partnerMac: slaveStatus && slaveStatus.partnerMac ? slaveStatus.partnerMac : null,
        linkHealth: self._getLinkHealth(isUp, slaveStatus),
      };

      self._getCommonPortStatusInformation(childPort, deviceConfig, portStatus, enslavedPort);
      tmpArray.push(childPort);
    });

    return tmpArray;
  },

  /**
   * As the name suggest this function is used to set up vlan information for a port irrespective of it being
   * a bond or not.
   *
   * @param {Object} portsMap
   * @param {Object} vlans
   * @param {Object} bondConfig
   * @private
   */
  _setUpVlanInfo: function(portsMap, vlans, bondConfig) {
    var self = this;
    vlans.each(function(vlanModel) {
      _.each(vlanModel.get('portsMap'), function(portName) {
        var parts = portName.split('.');
        portName = parts[0];

        // the port being looped through is a bond, handle it slightly different.
        if (bondConfig.get(portName)) {
          var currentBond = bondConfig.get(portName);
          self._setBondPortsVlanInfo(portsMap, currentBond, parts, vlanModel.id);
          return;
        }

        // Check if the port that is associated to the vlan is actually present in the portsMap.
        // if we don't the UI will hang indefinitely.
        // check portName (parts[0]) because it could be a tagged port
        if (_.isUndefined(portsMap[portName])) {
          return;
        }

        if (parts.length === 1) {
          // native port (e.g. eth0, eth1)
          portsMap[portName].nativeTo = vlanModel.id;
        } else {
          // dot1Q link
          portsMap[portName].taggedBy.push(vlanModel.id);
        }
      });
    });
  },

  /**
   * Used to set vlan information for an aggregated port.
   *
   * @param {Object} portsMap
   * @param {Object} currentBond
   * @param {Array} parts
   * @param {String} vlanId
   * @private
   */
  _setBondPortsVlanInfo: function(portsMap, currentBond, parts, vlanId) {
    _.each(currentBond.get('portsMap'), function(enslavedPort) {
      if (parts[1]) {
        portsMap[enslavedPort].taggedBy.push(vlanId);
      } else {
        portsMap[enslavedPort].nativeTo = vlanId;
      }
    });

    if (parts[1]) {
      portsMap[currentBond.id].taggedBy.push(vlanId);
    } else {
      portsMap[currentBond.id].nativeTo = vlanId;
    }
  },

  /*
   * i18n.t('portDetails.linkStatus.healthy') = Connected
   * i18n.t('portDetails.linkStatus.problem') = Problematic
   * i18n.t('portDetails.linkStatus.disconnected') = Disconnected
   */
  _getLinkHealth: function(isUp, slaveStatus) {
    // If the port is just down then bail and mark as disconnected.
    if (!isUp) {
      return linkHealthTypes.DISCONNECTED;
    }

    // linkFailureCount for native ports isn't supplied as we don't collect that information for native ports
    // so we check if it exists then compare the values to the threshold.
    if (slaveStatus && slaveStatus.linkFailureCountSinceBoot > ALLOWED_FAILURE_THRESHOLD) {
      return linkHealthTypes.PROBLEM;
    }

    return linkHealthTypes.HEALTHY;
  },

  /**
   * Gets all ports that are connected.
   *
   * @param {lib/models/DeviceStatus} deviceStatus
   * @private
   *
   * @return {Array}
   */
  _getPortsWithStatusUp: function(deviceStatus) {
    return deviceStatus.get('ports').filter(function(port) {
      return port.get('up');
    }).map(function(port) {
      return port.id;
    });
  },

  /**
   * Gets a single port.
   *
   * @param {String} port
   * @returns {Object}
   * @private
   */
  _getPhysicalPort: function(port) {
    var self = this;
    return _.find(self._getPhysicalPorts(), function(staticPort) {
      return staticPort.id === port;
    });
  },

  /**
   * For any of the slaves if the link failure since boot is above a curtain threshold mark that as a problem.
   *
   * @param {Object} bondSlaves
   * @returns {boolean}
   * @private
   */
  _isLinkFailureForNicBondPortsMapAboveThreshold: function(bondSlaves) {
    return _.any(bondSlaves, function(slave) {
      return slave.linkFailureCountSinceBoot > ALLOWED_FAILURE_THRESHOLD;
    });
  },
};
