'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var twig = require('twig').twig;
var layoutChannel = Radio.channel('layoutChannel');
var DeviceView = require('manage/list/DeviceView');
var layoutTpl = require('manage/list/wrapper.html');
require('lib/jquery/onAnimationEnd');

var ListView = Marionette.CollectionView.extend({
  childView: DeviceView,
  /**
   * Handles device being selected.
   *
   * @fires layoutChannel.request~change:page
   * @param {String} deviceMac
   */
  onChildviewDeviceSelected: function(deviceMac) {
    Backbone.history.navigate('/' + deviceMac);
    layoutChannel.request('change:page', {deviceMac: deviceMac});
  },
});

/**
 * Renders the list of devices available for managing.
 */
module.exports = Marionette.View.extend({
  template: twig({data: layoutTpl}),

  tagName: 'section',
  className: 'animated fadeIn quick',

  regions: {
    devicesList: '.devices-list',
  },

  ui: {
    'devicesList': '.devices-list',
    'noDevices': '.page-no-content',
  },

  onRender: function() {
    this.collection.comparator = 'hostname';
    this.collection.sort();
    this.showChildView('devicesList', new ListView({
      collection: this.collection,
    }));

    if (this.collection.length > 0) {
      this.ui.noDevices.hide();
    } else {
      this.ui.devicesList.hide();
    }

    // This is a little hack. All pages should trigger the loader while the
    // page gathers all of the data it needs. In the case of this View, its
    // data was already loaded. We still need to trigger these events to
    // ensure the layout actually appears.
    layoutChannel.request('show:loader', this);
    layoutChannel.request('destroy:loader', this);
  },
});
