'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('components/remoteSites/group.html');
var TunnelCollectionView = require('components/remoteSites/TunnelCollectionView');
var Backbone = require('backbone');
var Modal = require('components/modal/ModalView');
var ModalChildView = require('components/modal/GenericChildView');
var InfoView = require('components/remoteSites/InfoView');
var i18n = require('i18next');

module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  regions: {
    'tunnels': '.rg-tunnels',
  },

  ui: {
    'info': '.group-info',
  },

  events: {
    'click @ui.info': 'showModal',
  },

  templateContext: function() {
    return {
      groupCount: this.model.collection.length,
    };
  },

  showModal: function() {
    var view = new InfoView({
      type: 'group',
      tunnels: this.model.get('tunnels'),
    }).render();

    var modalView = new ModalChildView({
      content: view.$el.html(),
    });

    var model = Backbone.Model.new({
      childView: modalView,
      actionButtonText: '',
      closeText: i18n.t('common.close'),
    });

    var modal = new Modal({model: model});

    modal.render();
  },

  onRender: function() {
    this.addTunnelsCollection();
  },

  addTunnelsCollection: function() {
    this.showChildView('tunnels', new TunnelCollectionView({
      collection: this.model.get('tunnels'),
    }));
  },
});
