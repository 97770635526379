'use strict';

var ActionItem = require('actions/shared/ActionItem');

/**
 * Edit model for LAN allow VPN
 */
module.exports = ActionItem.extend({
  _snapshotAttributes: ['allowVpn'],

  defaults: {
    allowVpn: true,
  },

  isNew: function() {
    return false;
  },

  /**
   * Parse response for LAN allow VPN
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var vlan = this.deviceConfig.get('networks').get(this.id);
      if (vlan) {
        return {
          allowVpn: vlan.get('allowVpn'),
        };
      }
    }

    return resp;
  },

  /**
   * @return {Object}
   */
  getTask: function() {
    var data = {id: this.id};
    data.allowVpn = this.get('allowVpn');

    return {
      name: 'vlan.create',
      data: data,
    };
  },
});
