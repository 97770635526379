'use strict';

var MultiActionItem = require('actions/shared/MultiActionItem');
var EditRule = require('actions/trafficPolicyRules/Rule');
var dataTypes = require('lib/dataTypes');

/**
 * Edit model for Traffic Policy Rules
 */
module.exports = MultiActionItem.extend({

  collectionModel: EditRule,

  defaults: {
    items: {},
  },

  initialize: function(attributes, options) {
    this.attributes.items.comparator = function(a) {
      return parseInt(a.get('order'));
    };
    this.listenTo(this.get('items'), 'change:order', this.resortPolicies);
  },

  /**
   * Overridden to always return false (traffic policy is a fixed group)
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * Pull out policy traffic rules from full set of rules from the config
   * @return {array}
   */
  getTrafficPolicies: function() {
    var rules = this.deviceConfig.get('firewall').get('rule').toJSON();

    var trafficPolicies = rules.filter(function(rule) {
      return rule.role === 'TRAFFIC_POLICY';
    }).map(function(policy) {
      var obj = {
        description: policy.description,
        dest: policy.dest,
        destIp: policy.dest_ip,
        destPort: policy.dest_port,
        name: policy.name,
        order: policy.order,
        proto: policy.proto,
        protocolNumber: null,
        role: policy.role,
        ruleId: policy.ruleId,
        src: policy.src,
        srcIp: policy.src_ip,
        srcPort: policy.src_port,
        action: policy.target,
      };

      // check if proto field isNumeric, set its value to 'other' and move the numeric value to protocolNumber
      if (dataTypes.isNumeric(policy.proto)) {
        obj.protocolNumber = policy.proto;
        obj.proto = 'other';
      }
      return obj;
    });

    return trafficPolicies;
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      return {
        items: this.getTrafficPolicies(),
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('firewall').get('rule');
      },
      events: 'update',
    },
  ],

  resortPolicies: function() {
    return this.get('items').sort();
  },

});
