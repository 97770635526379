'use strict';

var i18n = require('i18next');
var EditGlobalDns = require('actions/globalDns/EditGlobalDns');
var FormView = require('actions/globalDns/FormView');
var StaticView = require('actions/globalDns/StaticView');

/**
 * Action configuration for Global DNS settings
 */
module.exports = {
  title: i18n.t('globalDns.title'),
  Model: EditGlobalDns,
  FormView: FormView,
  TextView: StaticView,
};
