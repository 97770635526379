'use strict';

var Backbone = require('backbone');
var i18n = require('i18next');
var Marionette = require('backbone.marionette');
var tpl = require('actions/alertConfiguration/form.html');
var twig = require('twig').twig;
var SubscriberView = require('actions/alertConfiguration/subscriber/SubscriberView');
var Modal = require('components/modal/ModalView');
var AlertConfigurationModal = require('actions/alertConfiguration/modal/AlertConfigurationModalView');
var SubscriberModel = require('actions/alertConfiguration/subscriber/SubscriberModel');

var SubscriberCollectionView = Marionette.CollectionView.extend({
  childView: SubscriberView,
});

module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  regions: {
    'subscribers': '.rg-subscribers',
  },

  ui: {
    add: '.btn-add',
    configure: '.configure-modal',
  },

  events: {
    'click @ui.add': 'addEmail',
    'click @ui.configure': 'showConfigurationModal',
  },

  onRender: function() {
    this.initChildViews();
  },

  initChildViews: function() {
    this.showChildView('subscribers', new SubscriberCollectionView({
      collection: this.model.get('subscribers'),
    }));
  },

  showConfigurationModal: function() {
    var model = Backbone.Model.new({
      childView: new AlertConfigurationModal({
        collection: this.model.get('alerts'),
      }),
      closeText: i18n.t('alertConfiguration.done'),
    });

    new Modal({model: model}).render();
  },

  addEmail: function() {
    var email = new SubscriberModel();
    this.model.get('subscribers').add(email);
  },
});
