'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var i18n = require('i18next');
var NetworkView = require('components/networksList/NetworkView');
var detailTypes = require('manage/details/detailTypes');
var Navbar = require('components/navbar/NavbarView');

var wanIcon = require('network_wan.svg');
var lanIcon = require('network_lan.svg');
var wifiIcon = require('network_wifi.svg');
var vpnIcon = require('network_vpn.svg');

/**
 * Simple View for displaying a list of networks associated with a DNA.
 */
module.exports = Marionette.CollectionView.extend({
  /**
   * @type {components/networksList/NetworkView}
   */
  childView: NetworkView,

  className: 'items-list items-list--all',

  onRender: function() {
    // This is a cheat to get around complicating the View with a LayoutView.
    this.$el.prepend(this.getFilterNav().render().$el);
  },

  /**
   * Gets the View displaying the filtering options. Also, sets up listener
   * for when a nav item is clicked.
   *
   * @return {components/navbar/NavbarView}
   */
  getFilterNav: function() {
    var filters = new Backbone.Collection([
      {id: 'all-networks', label: i18n.t('itemsList.all'), isActive: true},
      {id: 'wan-networks', label: i18n.t('detailsCommon.wan'), icon: wanIcon},
      {id: 'lan-networks', label: i18n.t('detailsCommon.lan'), icon: lanIcon},
      {id: 'wifi-networks', label: i18n.t('detailsCommon.wifi'), icon: wifiIcon},
      {id: 'vpn-networks', label: i18n.t('detailsCommon.vpn'), icon: vpnIcon},
    ]);

    var navbar = new Navbar({collection: filters, padded: true, large: true});

    navbar.on('navbarItem:selected', this.filterList.bind(this));

    return navbar;
  },

  /**
   * Filter the list of networks by a specific type.
   *
   * Note, assuming the list of networks will never be very large, so filtering
   * the list means hiding and showing elements.
   *
   * @listens navbarItem:selected
   * @param {Backbone.Model} model
   *   The "selected" model from the navbar View.
   */
  filterList: function(model) {
    this.$el.toggleClass('items-list--all', model.id === 'all-networks');
    this.children.each(this.showHideNetwork.bind(this, model.id));
  },

  /**
   * Shows or hides the network.
   *
   * Does this violate the best practice that a View should only make changes
   * to itself? In this case, the parent View is toggling the visibility of
   * children. Then again, there is an implicit relationship between children
   * and its parent. Hmmm.
   *
   * @param {String} filter
   *   Which networks to show (e.g. all-networks, wan-networks, etc.)
   * @param {components/networksList/NetworkView} child
   * @param {Number} index
   * @param {Backbone.Collection} collection
   */
  showHideNetwork: function(filter, child, index, collection) {
    var network;
    var networkType;
    var toShow;

    var showHide = function(show) {
      if (show) {
        child.$el.removeClass('hidden');
      } else {
        child.$el.addClass('hidden');
      }
    };

    if (filter === 'all-networks') {
      showHide(true);
      return;
    }

    network = child.model.get('network');
    networkType = network.get('type');

    switch (filter) {
      case 'wan-networks':
        toShow = (networkType === detailTypes.VLAN && network.get('role') === 'external');
        break;

      case 'lan-networks':
        toShow = (networkType === detailTypes.VLAN && network.get('role') === 'internal');
        break;

      case 'wifi-networks':
        toShow = (networkType === detailTypes.WIFI);
        break;

      case 'vpn-networks':
        toShow = (networkType === detailTypes.VPN);
        break;

      default:
        toShow = true;
        break;
    }

    showHide(toShow);
  },
});
