'use strict';

var _ = require('lodash');
var MultiActionItem = require('actions/shared/MultiActionItem');
var EditNat = require('actions/oneoneNat/Nat');
var i18n = require('i18next');

/**
 * Edit model for One to One NAT.
 */
module.exports = MultiActionItem.extend({

  collectionModel: EditNat,

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('firewall').get('oneoneNat');
      },
      events: 'update',
    },
    {
      getDispatcher: function(config) {
        return config.get('networks').get('vlan1');
      },
      events: 'change',
    },
    {
      getDispatcher: function(config) {
        return config.get('networks').get('vlan3');
      },
      events: 'change',
    },
  ],

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var natRules = this.deviceConfig.get('firewall').get('oneoneNat').toJSON();
      var secondaryIps = this._getSecondaryIps();

      return {
        items: natRules,
        secondaryIps: secondaryIps,
      };
    }

    return resp;
  },

  _getSecondaryIps: function() {
    var secondaryIps = [];
    var vlans = this.deviceConfig.getExternalVlans();

    vlans = vlans.filter(function(network) {
      return network.get('secondaryIps') !== undefined;
    });

    vlans.forEach(function(network) {
      secondaryIps = secondaryIps.concat(network.get('secondaryIps'));
    });

    return secondaryIps;
  },

  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'items')) {
      var items = attrs.items.toJSON();
      var srcDips = _.pluck(items, 'src_dip');

      if (srcDips.length !== _.unique(srcDips).length) {
        errors.duplicateIp = i18n.t('actionOneOneNat.duplicateIp');
      }

      attrs.items.forEach(function(item) {
        if (item.validationError !== null) {
          item.trigger('invalid', this, item.validationError);

          errors = item.validationError;
        }
      });
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },
});
