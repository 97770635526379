'use strict';

var Marionette = require('backbone.marionette');

/**
 * Primary router for the whole application. Route handlers can be found in
 * app/Controller.js.
 */
module.exports = Marionette.AppRouter.extend({
  appRoutes: {
    '': 'fleet',
    ':mac(/*subView)': 'manage',
  },
});
