'use strict';

var _ = require('lodash');
var AbstractStaticView = require('actions/shared/AbstractStaticView');
var twig = require('twig').twig;
var tpl = require('actions/trafficShaping/static.html');
var i18n = require('i18next');
var Category = require('actions/trafficShaping/Category');

/**
 * Renders a text-only version of the Traffic Shaping (QoS) component.
 */
module.exports = AbstractStaticView.extend({
  /**
   * @name #model
   * @type {actions/trafficShaping/EditShaping}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = AbstractStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      formatThroughput: this._formatThroughput,
      formatShapedCategories: this._formatShapedCategories,
    });
  },

  _formatThroughput: function(value) {
    return i18n.t('actionTrafficShaping.throughputRate', {rate: value});
  },

  _formatShapedCategories: function(categories) {
    var matchesDefaults = _.matches(Category.prototype.defaults());

    var shaped = categories
      .filter(function(category) {
        return !matchesDefaults(category.attributes);
      })
      .map(function(category) {
        return category.getLabel();
      })
      .join(i18n.t('general.listSeparator'));

    return shaped || i18n.t('actionTrafficShaping.noShapedCategories');
  },
});
