'use strict';

var i18n = require('i18next');
var EditHostname = require('actions/router/hostname/EditHostname');
var FormView = require('actions/router/hostname/FormView');
var StaticView = require('actions/router/hostname/StaticView');

/**
 * Action configuration for the Hostname action component.
 */
module.exports = {
  title: i18n.t('actionHostname.title'),
  Model: EditHostname,
  FormView: FormView,
  TextView: StaticView,
};
