'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var networkUtils = require('lib/network');

/**
 * Represents an individual conditional forwarding rule
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({

  /**
   * @member {Object} #attributes
   * @property {Boolean} remove
   *   Whether the rule should be removed on save
   * @property {String} id
   *   ID of the rule
   * @property {String} host
   *   Host the rule matches
   * @property {String} destination
   *   The server to forward DNS requests to
   */

  _snapshotAttributes: [
    'remove',
    'host',
    'destination',
  ],

  defaults: {
    remove: false,
    host: null,
    destination: null,
  },

  /**
   * Validation has determined this rule is free of conflicts with other rules
   * @fires noConflicts
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true) {
      // rules pending removal are never considered invalid
      return;
    }

    if (_.has(attrs, 'host')) {
      if (!attrs.host || !attrs.host.trim()) {
        errors.host = i18n.t('conditionalDns.errors.emptyHost');
      } else if (!networkUtils.validDomain(attrs.host)) {
        errors.host = i18n.t('conditionalDns.errors.invalidHost');
      }
    }

    if (_.has(attrs, 'destination')) {
      if (!attrs.destination || !attrs.destination.trim()) {
        errors.destination = i18n.t('conditionalDns.errors.emptyIp');
      } else if (!networkUtils.validIP(attrs.destination)) {
        errors.destination = i18n.t('conditionalDns.errors.invalidIp');
      }
    }

    if (options.allItems && !errors.host && !errors.destination) {
      if (_.any(options.allItems, this.conflictsWithRule, this)) {
        errors.host = i18n.t('conditionalDns.errors.conflictingRules');
      } else {
        this.trigger('noConflicts');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Checks whether this rule conflicts with the provided rule.
   *
   * @param {actions/conditionalDns/rule/Rule} rule
   *   An instance of this model to check for conflicts
   * @return {Boolean}
   */
  conflictsWithRule: function(rule) {
    // a rule never conflicts with itself
    if (rule === this) {
      return false;
    }

    if (rule.get('remove') === true) {
      return false;
    }

    var theirHost = rule.get('host').toLowerCase();
    var theirDest = rule.get('destination');
    var ourHost = this.get('host').toLowerCase();
    var ourDest = this.get('destination');

    return theirHost && theirDest && theirHost === ourHost && theirDest === ourDest;
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return this.getRemoveTask();
    }

    if (this.isNew()) {
      return this.getCreateTask();
    }

    if (this.getSnapshotDiff()) {
      return [
        this.getRemoveTask(this.getFromSnapshot('host'), this.getFromSnapshot('destination')),
        this.getCreateTask(),
      ];
    }
  },

  getRemoveTask: function(domainToRemove, ipToRemove) {
    domainToRemove = domainToRemove || this.get('host');
    ipToRemove = ipToRemove || this.get('destination');
    return {
      name: 'dns.deleteConditionalDnsForward',
      data: {
        domain: domainToRemove,
        ip: ipToRemove,
      },
    };
  },

  getCreateTask: function() {
    return {
      name: 'dns.createConditionalDnsForward',
      data: {
        domain: this.get('host'),
        ip: this.get('destination'),
      },
    };
  },
});
