'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var numberFormatters = require('lib/tplHelpers/numberFormatters');
var DataColors = require('components/usageShared/DataColors');

/**
 * Renders the top apps or clients pie chart
 */
module.exports = Marionette.View.extend({

  className: 'table-responsive',

  ui: {
    rows: '.app-row',
  },

  events: {
    'mouseenter @ui.rows': 'onItemHover',
    'mouseleave tbody': 'onListLeave',
  },

  currentHoverIndex: -1,

  serializeData: function() {
    return {
      // unwrap the nested items collection
      items: this.model.get('items').map(function(model) {
        return _.clone(model.attributes);
      }),
      totalBytes: this.model.get('totalBytes'),
    };
  },

  templateContext: function() {
    return {
      colorForItem: this.getColorForItem,
      labelForName: this.getLabelForName,
      labelForType: this.getLabelForType,
      formatUsage: this.formatUsage,
      formatUsagePercent: this.formatUsagePercent,
    };
  },

  /**
   * Determines the color key for a given item
   *
   * @param {Number} index
   * @param {String} name
   * @returns {String}
   */
  getColorForItem: function(index, name) {
    if (name === 'other') {
      return DataColors.otherColor;
    }
    return DataColors.colorForIndex(index);
  },

  /**
   * Determines the display name for a given item
   * Intended to be overriden
   *
   * @param {String} name
   * @returns {String}
   */
  getLabelForName: function(name) {
    return '';
  },

  /**
   * Determines the display description for a given item
   * Intended to be overriden
   *
   * @param {String} name
   * @param {Number} type
   * @returns {String}
   */
  getLabelForType: function(name, type) {
    return '';
  },

  /**
   * Formats a usage byte value for display
   *
   * @param {Number} bytes
   * @returns {String}
   */
  formatUsage: function(bytes) {
    return numberFormatters.formatBytes(bytes);
  },

  /**
   * Formats a usage byte value as a percent of total
   *
   * @param {Number} bytes
   * @returns {String}
   */
  formatUsagePercent: function(bytes) {
    var proportion = bytes / this.totalBytes;
    return numberFormatters.formatPercent(proportion);
  },

  /**
   * Notifies interested parties when a new row is hovered
   *
   * @fires hover:key
   * @param {MouseEvent} event
   */
  onItemHover: function(event) {
    var hoverIndex = this.ui.rows.index(event.currentTarget);

    if (hoverIndex !== this.currentHoverIndex) {
      this.currentHoverIndex = hoverIndex;
      this.trigger('hover:key', hoverIndex);
    }
  },

  /**
   * Notifies interested parties when hover over key rows ends
   *
   * @fires hover:key
   */
  onListLeave: function() {
    if (this.currentHoverIndex > -1) {
      this.currentHoverIndex = -1;
      this.trigger('hover:key', -1);
    }
  },

  /**
   * Programmatically highlights a given data item in the key
   * (as if it had been hovered)
   *
   * @param {Number} index
   *   Item to highlight. Pass -1 to remove any existing highlight.
   */
  highlightItem: function(index) {
    this.ui.rows.removeClass('highlight');
    if (index >= 0) {
      this.ui.rows.eq(index).addClass('highlight');
    }
  },

});
