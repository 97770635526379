'use strict';

var EventLogModel = require('components/eventLog/EventLogModel');
var i18n = require('i18next');
var numberFormatters = require('lib/tplHelpers/numberFormatters');

/**
 * Model/loader for System event log data
 *
 * The set of events categorized under System are handled here
 * e.g. system, system.upgrade, network.interface
 *
 * System Events are purposefully lacking a meaningful message, delivered from the server
 * This model does the work of using other properties on the System Event
 * to dynamically create said message
 */
module.exports = EventLogModel.extend({
  parse: function(resp, options) {
    var results = this.prepareResults(resp.result);

    return {
      data: results,
    };
  },

  prepareResults: function(results) {
    var self = this;

    results.forEach(function(event) {
      switch (event.emitter) {
        case 'system.upgrade':
          self.systemUpgradeEvent(event);
          break;
        case 'network.interface':
          self.networkInterfaceEvent(event);
          break;
        case 'network.down':
          self.networkDownEvent(event);
          break;
        case 'network.up':
          self.networkUpEvent(event);
          break;
        case 'boot':
          self.bootEvent(event);
          break;
        case 'reboot':
          self.rebootEvent(event);
          break;
        case 'config-restore':
          self.configRestoreEvent(event);
          break;
        case 'wifi':
          self.wifiInterfaceEvent(event);
          break;
        case 'wifi-global':
          self.wifiGlobalEvent(event);
          break;
        case 'wifi.ssid':
          self.wifiSsidEvent(event);
          break;
        case 'lan-ip-conflict':
          self.lanIpConflict(event);
          break;
        case 'dirtyboot':
          self.dirtyboot(event);
          break;
        case 'dhcp.full':
          self.dhcpPoolEvent(event);
          break;
        case 'vlan.clients.new':
          self.newVlanClientEvent(event);
          break;
        case 'site-vpn':
          self.siteVpnEvent(event);
          break;
        case 'client-vpn':
          self.clientVpnEvent(event);
          break;
        case 'failover':
          self.failover(event);
          break;
        case 'offline-alert':
          self.offlineAlert(event);
          break;
        case 'high-lte-usage':
          self.highDataUsage(event);
          break;
        case 'device.update.available':
          self.deviceUpdateAvailable(event);
          break;
        case 'factory.reset':
          self.factoryReset(event);
          break;
        default:
          self.genericEvent(event);
          break;
      }
    });

    return results;
  },

  systemUpgradeEvent: function(event) {
    /**
     * i18n-tasks-use t('systemEvents.upgrade.STARTING')
     * i18n-tasks-use t('systemEvents.upgrade.DOING_DOWNLOAD')
     * i18n-tasks-use t('systemEvents.upgrade.FAILED_DOWNLOAD')
     * i18n-tasks-use t('systemEvents.upgrade.DOING_UPGRADE')
     * i18n-tasks-use t('systemEvents.upgrade.FAILED_UPGRADE')
     * i18n-tasks-use t('systemEvents.upgrade.SUCCESS_UPGRADE')
     */
    event.message = i18n.t('systemEvents.upgrade.' + event.fields.message, {
      version: event.fields.version,
      who: event.fields.who ? event.fields.who : 'system',
    });
  },

  deviceUpdateAvailable: function(event) {
    event.message = i18n.t('systemEvents.deviceUpgradeAvailable', {
      version: event.fields.version,
    });
  },

  networkInterfaceEvent: function(event) {
    /**
     * i18n-tasks-use t('systemEvents.ifup')
     * i18n-tasks-use t('systemEvents.ifdown')
     */
    event.message = i18n.t('systemEvents.' + event.fields.action, {
      int: event.fields.interface,
    });
  },

  networkDownEvent: function(event) {
    if (event.fields.port !== undefined) {
      event.message = i18n.t('systemEvents.linkdown', {
        int: event.fields.interface,
        port: event.fields.port,
      });
    } else {
      event.message = i18n.t('systemEvents.ifdown', {
        int: event.fields.interface,
      });
    }
  },

  networkUpEvent: function(event) {
    if (event.fields.port !== undefined) {
      event.message = i18n.t('systemEvents.linkup', {
        int: event.fields.interface,
        port: event.fields.port,
      });
    } else {
      event.message = i18n.t('systemEvents.ifup', {
        int: event.fields.interface,
      });
    }
  },

  bootEvent: function(event) {
    event.message = i18n.t('systemEvents.boot');
  },

  rebootEvent: function(event) {
    event.message = i18n.t('systemEvents.reboot', {
      who: event.fields.who ? event.fields.who : 'system',
    });
  },

  factoryReset: function(event) {
    event.message = i18n.t('systemEvents.factoryReset', {
      who: event.fields.who ? event.fields.who : 'system',
    });
  },

  configRestoreEvent: function(event) {
    event.message = i18n.t('systemEvents.configRestore', {
      who: event.fields.who ? event.fields.who : 'system',
    });
  },

  wifiInterfaceEvent: function(event) {
    event.message = i18n.t('systemEvents.wifi.interface.translation', {
      action: event.fields.action === 'came up' ?
          i18n.t('systemEvents.wifi.interface.up') : i18n.t('systemEvents.wifi.interface.down'),
      int: event.fields.int,
    });
  },

  wifiGlobalEvent: function(event) {
    event.message = i18n.t('systemEvents.wifi.global', {
      who: event.fields.who ? event.fields.who : 'system',
    });
  },

  wifiSsidEvent: function(event) {
    /**
     * i18n-tasks-use t('systemEvents.wifi.create')
     * i18n-tasks-use t('systemEvents.wifi.edit')
     * i18n-tasks-use t('systemEvents.wifi.delete')
     */
    var action = event.fields.action;
    event.message = i18n.t('systemEvents.wifi.' + action, {
      who: event.fields.who ? event.fields.who : 'system',
      ssid: event.fields.ssid,
    });
  },

  lanIpConflict: function(event) {
    event.message = i18n.t('systemEvents.ipConflict', {
      lan: event.fields.interface,
    });
  },

  dirtyboot: function(event) {
    event.message = i18n.t('systemEvents.dirtyboot');
  },

  dhcpPoolEvent: function(event) {
    event.message = i18n.t('systemEvents.dhcpPool', {
      int: event.fields.int,
    });
  },

  failover: function(event) {
    event.message = i18n.t('systemEvents.failover.translation', {
      int: event.fields.activeInterface,
      verb: event.fields.verb === 'over' ?
          i18n.t('systemEvents.failover.over') : i18n.t('systemEvents.failover.back'),
    });
  },

  newVlanClientEvent: function(event) {
    event.message = i18n.t('systemEvents.newDevice', {
      hostname: event.fields.hostname,
      mac: event.fields.mac,
      int: event.fields.interface,
    });
  },

  siteVpnEvent: function(event) {
    event.message = i18n.t('systemEvents.siteVpn.translation', {
      site: event.fields.siteName ? event.fields.siteName : '{site}',
      verb: event.fields.verb === 'up' ?
          i18n.t('systemEvents.siteVpn.up') : i18n.t('systemEvents.siteVpn.down'),
    });
  },

  clientVpnEvent: function(event) {
    event.message = i18n.t('systemEvents.clientVpn.translation', {
      action: event.fields.action === 'up' ?
          i18n.t('systemEvents.clientVpn.up') : i18n.t('systemEvents.clientVpn.down'),
    });
  },

  offlineAlert: function(event) {
    event.message = i18n.t('systemEvents.offlineAlert');
  },

  highDataUsage: function(event) {
    event.message = i18n.t('systemEvents.highDataUsage', {
      bytes: numberFormatters.formatBytes(event.fields.usage),
    });
  },

  genericEvent: function(event) {
    if (!event.message && event.fields.message) {
      event.message = event.fields.message;
    }
  },
});
