'use strict';

var GroupView = require('manage/edit/config/group/GroupView');
var WifiDisabledAlert = require('actionGroups/editWifi/WifiDisabledAlertView');
var NetworkDisruptionAlert = require('actionGroups/shared/NetworkDisruptionView');
var i18n = require('i18next');

/**
 * Custom Group View for the Create WiFi and Edit WiFi config cards.
 */
module.exports = GroupView.extend({
  onAttach: function() {
    this.listenTo(this.model.deviceConfig.get('wifi'), 'change:disabled', this.toggleWifiDisabled);
    GroupView.prototype.onAttach.apply(this, arguments);
  },

  getRemovalSubMessage: function() {
    return i18n.t('configEdit.networkDisruption');
  },

  getRemovalIsDangerous: function() {
    return true;
  },

  /**
   * Overridden to check if the wifi disabled message needs to be displayed.
   *
   * @param {actionGroups/editWifi/EditWifi|actionGroups/editWifi/CreateWifi} model
   * @param {Boolean} isFocused
   * @param {Object} options
   */
  onFocusChange: function(model, isFocused, options) {
    GroupView.prototype.onFocusChange.apply(this, arguments);
    this.toggleWifiDisabled();
  },

  /**
   * Displays or removes the "Wifi is disabled" message.
   */
  toggleWifiDisabled: function() {
    var isDisabled = this.model.deviceConfig.get('wifi').get('disabled');
    var isEditing = this.model.get('isFocused') || this.model.get('isDirty');
    var warningsView = this.getChildView('configWarnings');
    var isWifiNew = this.model.isNew();

    warningsView.triggerMethod('remove:alert', NetworkDisruptionAlert.networkDisruptionAlertId);
    warningsView.triggerMethod('remove:alert', WifiDisabledAlert.wifiDisabledAlertId);

    if (isDisabled && isEditing) {
      warningsView.triggerMethod('add:custom:alert', new WifiDisabledAlert({
        navChannel: this.navChannel,
      }));
    } else if (!isDisabled && isWifiNew) {
      warningsView.triggerMethod('add:custom:alert', new NetworkDisruptionAlert({navChannel: this.navChannel}));
    }
  },
});
