'use strict';

var BaseGroup = require('config/Group');
var i18n = require('i18next');
var RenderPositions = require('config/RenderPositions');
var WanSpeedDuplexAlertView = require('actionGroups/portConfigurationOverride/WanSpeedDuplexAlertView');

/**
 * "Ethernet Settings" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'speedDuplexSettings',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  isConnectivityDisrupted: function(type, target) {
    if (type === 'input') {
      if (target.name === 'speed' || target.name === 'duplex') {
        return WanSpeedDuplexAlertView;
      }
    }

    return false;
  },

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.portConfigurationOverride');
  },
});
