'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var networkUtils = require('lib/network');
var Ip = require('lib/Ip');

/**
 * Represents an individual DHCP reservation
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({

  /**
   * @member {Object} #attributes
   * @property {Boolean} remove
   *   Whether the reservation should be removed on save
   * @property {String} id
   *   ID of the reservation
   * @property {String} deviceMac
   *   Mac of the client device
   * @property {String} deviceName
   *   Name of the client device
   * @property {String} ip
   *   IP address to reserve
   */

  idAttribute: 'reservationId',

  _snapshotAttributes: [
    'remove',
    'deviceMac',
    'deviceName',
    'ip',
  ],

  defaults: {
    remove: false,
    deviceMac: null,
    deviceName: null,
    ip: null,
  },

  /**
   * @fires noConflicts
   *   Validation has determined this rule is free of conflicts with other rules
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true) {
      return;
    }

    if (_.has(attrs, 'deviceMac')) {
      if (!attrs.deviceMac || !attrs.deviceMac.trim()) {
        errors.deviceMac = i18n.t('actionDhcpReservations.missingDeviceMac');
      } else if (!networkUtils.validMacAddress(attrs.deviceMac)) {
        errors.deviceMac = i18n.t('actionDhcpReservations.invalidDeviceMac');
      } else if (options.allItems) {
        if (_.any(options.allItems, this.conflictsWithDeviceMac, this)) {
          errors.deviceMac = i18n.t('actionDhcpReservations.duplicateMac');
        } else {
          this.trigger('noConflicts');
        }
      }
    }

    if (_.has(attrs, 'deviceName')) {
      if (!attrs.deviceName || !attrs.deviceName.trim()) {
        errors.deviceName = i18n.t('actionDhcpReservations.missingDeviceName');
      } else if (!networkUtils.validHostname(attrs.deviceName)) {
        errors.deviceName = i18n.t('actionDhcpReservations.invalidDeviceName');
      }
    }

    if (_.has(attrs, 'ip')) {
      if (!attrs.ip || !attrs.ip.trim()) {
        errors.ip = i18n.t('actionDhcpReservations.missingIpAddress');
      } else if (!networkUtils.validIP(attrs.ip)) {
        errors.ip = i18n.t('actionDhcpReservations.invalidIpAddress');
      } else {
        var lanAddress = this.get('lanIp');
        var subnet = lanAddress.get('address') + '/' + lanAddress.get('size');
        var network = new Ip(subnet, {type: 'ipv4'});
        var startIp = network.network();
        var endIp = network.broadcast();

        if (!networkUtils.isIPv4InRange(attrs.ip, startIp, endIp)) {
          errors.ip = i18n.t('actionDhcpReservations.invalidIpRange');
        } else if (attrs.ip === lanAddress.get('address')) {
          errors.ip = i18n.t('actionDhcpReservations.IpCannotBeRouter');
        } else if (networkUtils.isNetworkOrBroadcast(attrs.ip)) {
          errors.ip = i18n.t('actionDhcpReservations.IpCannotBeNetworkOrBroadcast');
        } else if (options.allItems) {
          if (_.any(options.allItems, this.conflictsWithOtherIps, this)) {
            errors.ip = i18n.t('actionDhcpReservations.duplicateIp');
          }
        }
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return {
        name: 'dhcpReservation.delete',
        data: {
          reservationId: this.id,
        },
      };
    }

    if (this.isNew() || this.getSnapshotDiff()) {
      var task = {
        name: 'dhcpReservation.createOrEdit',
        data: {
          deviceName: this.get('deviceName'),
          deviceMac: this.get('deviceMac'),
          ip: this.get('ip'),
        },
      };
      if (!this.isNew()) {
        task.data.reservationId = this.id;
      }
      return task;
    }
  },

  /**
   * Checks whether this device mac conflicts with the provided reservation.
   *
   * @param {actions/dhcpReservation/reservation} reservation
   *   An instance of this model to check for conflicts
   * @return {Boolean}
   */
  conflictsWithDeviceMac: function(reservation) {
    if (reservation === this) {
      return false;
    }

    if (reservation.get('remove') === true) {
      return false;
    }

    var deviceMac = reservation.get('deviceMac');
    if (!deviceMac || !networkUtils.validMacAddress(deviceMac)) {
      return false;
    }

    return this.get('deviceMac') === deviceMac;
  },

  /**
   * Checks whether this IP conflicts with the provided reservation.
   *
   * @param {actions/dhcpReservation/reservation} reservation
   *   An instance of this model to check for conflicts
   * @return {Boolean}
   */
  conflictsWithOtherIps: function(reservation) {
    if (reservation === this) {
      return false;
    }

    if (reservation.get('remove') === true) {
      return false;
    }

    var IP = reservation.get('ip');
    if (!IP || !networkUtils.validIP(IP)) {
      return false;
    }

    return this.get('ip') === IP;
  },
});
