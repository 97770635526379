'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');

/**
 * Model for a DNA to DNA VPN tunnel
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({

  /**
   * @member {Object} #attributes
   * @property {Boolean} remove
   *   Whether the reservation should be removed on save
   * @property {String} id
   *   ID of the tunnel config
   * @property {Boolean} enabled
   *  If the tunnel should be enabled or not
   * @property {Boolean} initiator
   *  Inbound or outbound
   * @property {String} remoteMac
   *  the mac address of the peer DNA
   * @property {Boolean} isCurrentDeviceSpoke
   *  If the DNA being viewed is considered a spoke or not
   */

  _snapshotAttributes: [
    'enabled',
    'initiator',
    'remoteMac',
    'remove',
  ],

  defaults: {
    remove: false,
    enabled: true,
    remoteMac: null,
    initiator: false,
    isCurrentDeviceSpoke: false,
  },

  initialize: function() {
    if (!this.get('availableDevices')) {
      this.set({availableDevices: []});
    }
  },

  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true) {
      return;
    }

    if (_.has(attrs, 'remoteMac')) {
      if (!attrs.remoteMac) {
        errors.mac = i18n.t('actionSiteVpn.noRemoteMac');
      } else if (!this.validMac(attrs.remoteMac)) {
        errors.mac = i18n.t('actionSiteVpn.unknownRemoteMac');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {{data: {enabled, initiator, remoteMac}}}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return;
    }

    return {
      data: {
        enabled: this.get('enabled'),
        initiator: this.get('initiator'),
        remoteMac: this.get('remoteMac'),
        isSpoke: this.get('isCurrentDeviceSpoke'),
      },
    };
  },

  /**
   * Whether the provided mac address is in the list of available devices
   *
   * @param {String} mac
   * @return {Boolean}
   */
  validMac: function(mac) {
    var device = _.find(this.get('availableDevices'), {deviceMac: mac});

    return !!device;
  },
});
