'use strict';

var Marionette = require('backbone.marionette');
var ReservationView = require('actions/dhcpReservations/FormReservationView');
var tpl = require('actions/dhcpReservations/form.html');
var RemoveConfig = require('manage/edit/config/action/removeConfig/RemoveConfig');
var i18n = require('i18next');
var twig = require('twig').twig;

var ReservationsListView = Marionette.CollectionView.extend({
  childView: ReservationView,
});

/**
 * Renders a text-only version of the DHCP reservations form action component.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/dhcpReservations/FormView#model
   * @type {actions/dhcpReservations/DhcpReservations}
   */

  behaviors: [{
    behaviorClass: RemoveConfig,
  }],

  regions: {
    reservations: '.rg-reservations',
  },

  ui: {
    add: '.btn-add',
  },

  events: {
    'click @ui.add': 'addReservation',
  },

  template: twig({data: tpl}),

  onRender: function() {
    if (this.model.get('pendingDelete') === true) {
      return;
    }

    this.initChildViews();
  },

  templateContext: function() {
    var deleteMsg;
    var reservations = this.model.get('items');
    if (reservations && reservations.length > 0) {
      deleteMsg = i18n.t('actionDhcpReservations.pendingActionRemoval', {count: reservations.length});
    } else {
      deleteMsg = i18n.t('actionDhcpReservations.pendingEmptyActionRemoval');
    }

    return {
      pendingDeleteMsg: deleteMsg,
    };
  },

  /**
   * Initializes the child views.
   */
  initChildViews: function() {
    this.showChildView('reservations', new ReservationsListView({
      collection: this.model.get('items'),
    }));
  },

  /**
   * Add a new blank reservation to the list of static leases
   */
  addReservation: function() {
    this.model.addReservation();
  },

});
