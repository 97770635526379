'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/advancedNat/rule.html');
var RenderChanges = require('lib/behaviors/RenderChanges');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
require('lib/jquery/bootstrapUI');
require('devbridge-autocomplete');

module.exports = Marionette.View.extend({
  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    name: '[name="name"]',
    srcIp: '[name="srcIp"]',
    srcPort: '[name="srcPort"]',
    protocol: '[name="protocol"]',
    destIp: '[name="destIp"]',
    destPort: '[name="destPort"]',
    enabled: '[name="enabled"]',
    order: '[name="order"]',
    type: '[name="type"]',
    rewrittenIp: '[name="rewrittenIp"]',
    rewrittenPort: '[name="rewrittenPort"]',
    bypass: '[name="bypass"]',
    messages: '.help-block',
    remove: '.btn-remove',
  },

  events: {
    'focus @ui.srcIp': 'getAutocomplete',
    'focus @ui.destIp': 'getAutocomplete',
    'change @ui.name': 'updateModel',
    'change @ui.srcIp': 'updateModel',
    'change @ui.srcPort': 'updateModel',
    'change @ui.protocol': 'handleProtocolChange',
    'change @ui.destIp': 'updateModel',
    'change @ui.destPort': 'updateModel',
    'change @ui.enabled': 'updateModel',
    'change @ui.order': 'updateModel',
    'change @ui.type': 'updateModel',
    'change @ui.rewrittenIp': 'updateModel',
    'change @ui.rewrittenPort': 'updateModel',
    'change @ui.bypass': 'handleBypassChange',
    'click @ui.remove': 'removeNat',
  },

  modelEvents: {
    'invalid': 'onError',
    'change:remove': 'render',
    'change:enabled': 'onEnabledChange',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var context = {
      ruleCount: this.setCurrentRuleCount(this.model.collection.models),
      ruleOrder: this.model.get('order'),
    };
    return _.extend(context, tplHelpers.apply(this));
  },

  // autocompletes network object options for src ip and dest ip
  getAutocomplete: function(event) {
    var field = event.currentTarget.name;
    var thisView = this;
    this.ui[field].autocomplete({
      lookup: _.pluck(thisView.model.get('netObjs'), 'description'),
      width: 'flex',
      maxHeight: 200,
      onSelect: function(suggestion) {
        thisView.updateModel({
          currentTarget: {
            name: field,
            value: suggestion.value,
          },
        });
      },
    });
  },

  onBeforeRender: function() {
    if (this.model.get('srcNetObjId')) {
      var srcNetObj = _.findWhere(this.model.get('netObjs'), {id: this.model.get('srcNetObjId')});
      if (srcNetObj) {
        this.model.set({'srcIp': srcNetObj.description});
      }
      this.model.set({'srcNetObjId': null});
    }
    if (this.model.get('destNetObjId')) {
      var destNetObj = _.findWhere(this.model.get('netObjs'), {id: this.model.get('destNetObjId')});
      if (destNetObj) {
        this.model.set({'destIp': destNetObj.description});
      }
      this.model.set({'destNetObjId': null});
    }
    this.isItemOff = !this.model.get('enabled');
  },

  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;
    switch (name) {
      case 'name':
        this.model.set({'name': value}, {commit: true});
        break;
      case 'srcIp':
        this.clearError('srcIp');
        this.model.set({'srcIp': value}, {commit: true});
        break;
      case 'srcPort':
        this.clearError('srcPort');
        this.model.set({'srcPort': value}, {commit: true});
        break;
      case 'destIp':
        this.clearError('srcIp');
        this.clearError('destIp');
        this.model.set({'destIp': value}, {commit: true});
        break;
      case 'destPort':
        this.clearError('destPort');
        this.model.set({'destPort': value}, {commit: true});
        break;
      case 'enabled':
        this.model.set({enabled: event.currentTarget.checked});
        break;
      case 'order':
        if (value < parseInt(this.model.get('order'))) {
          value--;
        } else {
          value++;
        }
        this.model.set({order: value});
        break;
      case 'type':
        this.model.set({'type': value}, {commit: true});
        break;
      case 'rewrittenIp':
        this.clearError('rewrittenIp');
        this.model.set({'rewrittenIp': value}, {commit: true});
        break;
      case 'rewrittenPort':
        this.clearError('rewrittenPort');
        this.model.set({'rewrittenPort': value}, {commit: true});
        break;
      default:
        break;
    }
  },

  /**
   * handle 'All' protocol selection
   *
   * @param {Object} event
   */
  handleProtocolChange: function(event) {
    var value = event.currentTarget.value;

    // clear the values in all ports when All is selected
    if (value === 'all') {
      this.clearError('srcPort');
      this.model.set({'srcPort': ''}, {commit: true});

      this.clearError('destPort');
      this.model.set({'destPort': ''}, {commit: true});

      this.clearError('rewrittenPort');
      this.model.set({'rewrittenPort': ''}, {commit: true});
    }

    this.model.set({protocol: value}, {commit: true});
    this.triggerMethod('needsRender');
  },

  /**
   * handle bypass selection
   *
   * @param {Object} event
   */
  handleBypassChange: function(event) {
    var value = event.currentTarget.checked;

    // clear the values in rewrittenIp and rewrittenPort when bypass is selected
    if (value) {
      this.clearError('rewrittenIp');
      this.model.set({'rewrittenIp': ''}, {commit: true});

      this.clearError('rewrittenPort');
      this.model.set({'rewrittenPort': ''}, {commit: true});
    }

    this.model.set({'bypass': value}, {commit: true});
    this.triggerMethod('needsRender');
  },

  /**
   * Decorates the view to indicate error.
   *
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

  removeNat: function() {
    if (this.model.isNew()) {
      this.model.collection.remove(this.model);
    } else {
      var shouldRemove = !this.model.get('remove');

      if (shouldRemove) {
        this.model.applySnapshot();
      }

      this.model.set({remove: shouldRemove});
    }
  },

  onEnabledChange: function(model, value) {
    this.isItemOff = !value;
    this.triggerMethod('onoff:status:change', this);
  },

  setCurrentRuleCount: function(ruleModels) {
    var ruleCount = 0;
    _.forEach(ruleModels, function(rule) {
      if (rule.attributes.remove === false) {
        ruleCount++;
        rule.set('order', ruleCount.toString(), {silent: true});
      }
    });
    return ruleCount;
  },

  clearError: function(key) {
    this.ui[key].bs3ui('clearGroupedFieldError', key, this.ui.messages);
  },
});
