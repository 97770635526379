'use strict';

var _ = require('lodash');
var BaseGroup = require('config/Group');
var i18n = require('i18next');
var RenderPositions = require('config/RenderPositions');

/**
 * 'Site to Site VPN' configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'siteVpnDescription',
      position: RenderPositions.header,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'editSiteVpn',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.editSiteVpn');
  },

  /**
   * @return {String}
   */
  description: function() {
    if (!_.isUndefined(this.get('typeId'))) {
      var topology = this.deviceConfig.get('remoteSites').get(this.get('typeId'));
      return '(' + topology.get('description') + ')';
    }
    return '';
  },

  /**
   * Request a callback when the description of this group changes
   *
   * @param {Backbone.Events} listener
   * @param {Function} callback
   */
  onDescriptionChanged: function(listener, callback) {
    var topo = this.deviceConfig.get('remoteSites').get(this.get('typeId'));
    if (topo) {
      topo.onNameChanged(listener, callback);
    }
  },

  /**
   * Overridden to include the "id".
   *
   * @return {Object}
   */
  _getTasks: function() {
    var tasks = BaseGroup.prototype._getTasks.apply(this, arguments);
    var task = _.find(tasks, {name: 'vpn.setSiteVpnConfigs'});

    if (!_.isUndefined(task)) {
      task.data.id = this.get('typeId');
    }

    return tasks;
  },
});
