'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('components/updateDna/modal/updateModal.html');
var twig = require('twig').twig;
require('lib/jquery/bootstrapUI');

module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  moreInfoUrl: '',
  version: '',

  initialize: function(options) {
    this.moreInfoUrl = options.moreInfoUrl || '';
    this.version = options.version || '';
  },

  templateContext: function() {
    var context = {
      moreInfoUrl: this.moreInfoUrl,
      version: this.version,
    };
    return context;
  },
});
