'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var PrioritiesCollection = require('actions/alertConfiguration/priority/PrioritiesCollection');
var networkUtils = require('lib/network');
var childModels = require('lib/mixins/childModels');

var nesting = childModels({
  priorities: PrioritiesCollection,
}, AdvancedValidationModel);

module.exports = AdvancedValidationModel.extend(nesting).extend(snapshotMixin).extend({

  _snapshotAttributes: [
    'remove',
    'email',
    'priorities',
  ],

  defaults: {
    remove: false,
    email: null,
  },

  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true) {
      return;
    }

    if (_.has(attrs, 'email')) {
      if (!attrs.email || !networkUtils.validEmail(attrs.email)) {
        errors.email = i18n.t('alertConfiguration.badEmail');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },
});
