'use strict';

/**
 * "Constants" used to identify different manage/details/* Views.
 */
module.exports = {
  DNA: 'dna',
  CONNECTED_DEVICE: 'connectedDevice',
  LAN: 'lan',
  PORTS: 'portsDetails',
  UNKNOWN: 'unknown',
  VLAN: 'vlan',
  VPN: 'vpn',
  WIFI: 'wifi',
};
