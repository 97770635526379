'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/networkObject/spec.html');
var RenderChanges = require('lib/behaviors/RenderChanges');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');

module.exports = Marionette.View.extend({
  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    addrSpec: '[name="addr-spec"]',
    specType: '[name="spec-type"]',
    messages: '.help-block',
    remove: '.btn-remove',
  },

  events: {
    'change @ui.addrSpec': 'updateAddrSpecification',
    'change @ui.specType': 'updateAddrSpecificationType',
    'click @ui.remove': 'removeSpecification',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var context = {
    };

    return _.extend(context, tplHelpers.apply(this));
  },

  updateAddrSpecification: function(ev) {
    var value = ev.currentTarget.value;
    this.ui.addrSpec.bs3ui('clearGroupedFieldError', 'addrSpec', this.ui.messages);
    this.model.set({'addrSpec': value}, {commit: true});
  },

  updateAddrSpecificationType: function(ev) {
    var value = ev.currentTarget.value;
    this.ui.addrSpec.bs3ui('clearGroupedFieldError', 'addrSpec', this.ui.messages);
    this.model.set({'specType': value}, {commit: true});
  },

  /**
   * Decorates the view to indicate error.
   *
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

  removeSpecification: function() {
    this.model.collection.remove(this.model);
  },
});
