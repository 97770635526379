'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/ports/static.html');
var twig = require('twig').twig;

/**
 * Renders a text-only version of the "VLAN ports" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/ports/StaticView#model
   * @type {actions/ports/EditPorts}
   */

  template: twig({data: tpl}),

  /**
   * @return {Object}
   */
  serializeData: function() {
    var data = BaseStaticView.prototype.serializeData.apply(this, arguments);
    data.ports = data.ports.toJSON();
    return data;
  },

  /**
   * VLAN static view template helpers
   * @return {Object}
   */
  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      portsList: this.formatPorts,
      supportsTagging: true,
      networkTypeInfo: this.formatNetworkType,
    });
  },

  /**
   * Template helper to return a formatted list of ports assigned to the VLAN.
   *
   * @method templateContext.formatPorts
   * @return {String}
   */
  formatPorts: function() {
    var ports = _.reduce(this.ports, function(memo, port) {
      if (port.isSelected === true) {
        if (port.isBond) {
          memo.push(port.portName);
        } else if (!port.bondId) {
          memo.push(port.portNumber);
        }
      }
      return memo;
    }, []);

    if (ports.length > 0) {
      return ports.join(', ');
    }

    return this.emptyVal();
  },

  /**
   * Template helper to return a formatted description of the network type.
   *
   * @return {String}
   */
  formatNetworkType: function() {
    if (this.isTagged) {
      return i18n.t('actionPorts.taggedAs', {tagId: this.tagId});
    }
    return i18n.t('actionPorts.typeNative');
  },
});
