'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var noSnapshotMixin = require('lib/mixins/noSnapshot');
var networkUtils = require('lib/network');
var i18n = require('i18next');

/**
 * Model for Ping Test.
 */
module.exports = ActionItem.extend(noSnapshotMixin).extend({
  /**
   * @member {Object} actions/operatorPing/OperatorPing#attributes
   * @property {string} destIp
   * @property {string} iface
   * @property {string} count
   * @property {string} size
   * @property {string} ttl
   * @property {string} wait
   * @property {string} result
   */

  defaults: {
    destIp: null,
    iface: 'auto',
    count: null,
    size: null,
    ttl: null,
    wait: null,
    result: i18n.t('operatorPing.defaultResult'),
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * Parse available interfaces.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      return {
        wans: this.deviceConfig.getWanPorts(),
        lans: this.deviceConfig.getLanAddresses(),
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks');
      },
      events: 'add remove change:addressMap change:portsMap change:description',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'destIp')) {
      if (!networkUtils.validHostname(attrs.destIp)
          && !networkUtils.validIP(attrs.destIp)) {
        errors.destIp = i18n.t('operatorPing.invalidIp');
      }
    }

    if (_.has(attrs, 'count') && attrs.count) {
      // There is a timout of 30s, hence max count of 30 pings
      if (!this.isInt(attrs.count) || parseInt(attrs.count) > 30) {
        errors.count = i18n.t('operatorPing.invalidCount');
      }
    }

    if (_.has(attrs, 'size') && attrs.size) {
      // Highest value allowed by ping command
      if (!this.isInt(attrs.size) || parseInt(attrs.size) > 65535) {
        errors.size = i18n.t('operatorPing.invalidSize');
      }
    }

    if (_.has(attrs, 'ttl') && attrs.ttl) {
      // Highest value allowed by ping command
      if (!this.isInt(attrs.ttl) || parseInt(attrs.ttl) > 2147483647) {
        errors.ttl = i18n.t('operatorPing.invalidTtl');
      }
    }

    if (_.has(attrs, 'wait') && attrs.wait) {
      // There is a timeout of 30s, can't wait for first packet any longer than that
      if (!this.isInt(attrs.wait) || parseInt(attrs.wait) > 30) {
        errors.wait = i18n.t('operatorPing.invalidWait');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Checks whether string is an integer greater than 0 with less than 11 digits
   *
   * @param {String} string
   *
   * @return {Boolean}
   */
  isInt: function(string) {
    var re = new RegExp('^[1-9][0-9]{0,9}$');
    return re.test(string);
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    return {
      name: 'operatorTools.ping',
      data: {
        destIp: this.get('destIp'),
        iface: this.get('iface'),
        count: this.get('count'),
        size: this.get('size'),
        ttl: this.get('ttl'),
        wait: this.get('wait'),
      },
    };
  },
});
