'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/vpn/routes/form.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

/**
 * Renders the "Allow Access To" form fields.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/vpn/routes/FormView#model
   * @type {actions/vpn/routes/EditVpnRoutes
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    vpnRouteWrapper: '.vpn-route-wrapper',
    allowedNetwork: '.allowed-network',
    allowLan: '[name="vpn-allow-lan[]"]',
    hostAccessEnabled: '[name="vpn-host-access[]"]',
    messages: '.help-block',
  },

  events: {
    'change @ui.allowLan': 'saveForm',
    'change @ui.hostAccessEnabled': 'saveForm',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var baseHelpers = tplHelpers.apply(this);

    return _.extend({}, baseHelpers, {
      eligibleNetworks: _.bind(this._getEligibleNetworks, this.model),
      supportsHostAccess: this.model.has('hostAccessEnabled'),
    });
  },

  /**
   * Ensure that we stay in sync if the list of eligible networks for
   * VPN routing changes externally (e.g. user adds or modifies a LAN)
   */
  onAttach: function() {
    var config = this.model.deviceConfig;

    if (config) {
      this.listenTo(config.get('networks'), 'update', this.render);
    }
  },

  /**
   * Checks if values have changed and saves.
   *
   * @param {Object} event
   */
  saveForm: function(event) {
    var name = event.currentTarget.name;

    switch (name) {
      case 'vpn-allow-lan[]':
        this.clearError();
        // build array of the data-id values for all checked networks
        var allowed = this.ui.allowLan
          .filter(':checked')
          .map(function(index, element) {
            return element.dataset.id;
          })
          .get();
        this.model.set({allowedNetworks: allowed});
        break;
      case 'vpn-host-access[]':
        this.clearError();
        this.model.set({'hostAccessEnabled': this.ui.hostAccessEnabled.is(':checked')});
        break;
      default:
        break;
    }
  },

  /**
   * Clears the error message and removes the has-error class.
   */
  clearError: function() {
    this.ui.vpnRouteWrapper.bs3ui('clearGroupedFieldError', 'vpnRouteWrapper', this.ui.messages);
    this.ui.allowedNetwork.removeClass('has-error');
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/vpn/routes/EditVpnRoutes} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.allowedNetworks) {
      this.ui.vpnRouteWrapper.bs3ui(
        'showGroupedFieldError', error.allowedNetworks, 'vpnRouteWrapper', this.ui.messages
      );

      this.ui.allowedNetwork.addClass('has-error');
    }
  },

  /**
   * Get each network that VPN routes can be allowed to,
   * and whether they currently are allowed
   *
   * @returns {Array}
   */
  _getEligibleNetworks: function() {
    var allowed = this.get('allowedNetworks');
    return this.deviceConfig.getVlans({external: false})
      .map(function(lan) {
        return _.extend({}, lan.toJSON(), {
          allowed: _.includes(allowed, lan.id),
        });
      });
  },
});
