'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var formTpl = require('actions/wifi/form.html');
var tplHelpers = require('lib/tplHelpers');
var Ip = require('lib/Ip');
var vlanDetailsTpl = require('actions/wifi/vlan-details.html');
var EditWifi = require('actions/wifi/EditWifi');
var TogglePasswordField = require('lib/behaviors/ToggleSinglePassword');
var RenderChanges = require('lib/behaviors/RenderChanges');

/**
 * Renders the create/edit Wi-Fi network form.
 */
module.exports = Marionette.View.extend({
  template: twig({data: formTpl}),
  vlanDetailsTpl: twig({data: vlanDetailsTpl}),

  behaviors: function() {
    return [
      {
        behaviorClass: RenderChanges,
      },
      {
        behaviorClass: TogglePasswordField,
        showPasswordOnLoad: this.options.model.isNew(),
      },
    ];
  },

  ui: {
    ssid: '[name="wifi-ssid"]',
    parentVlan: '[name="wifi-parentVlan"]',
    encryptionType: '[name="wifi-encryption-type"]',
    encryptionOptions: '.encryption-options-wrap',
    encryptionKey: '[name="wifi-encryption-key"]',
    broadcast: '[name="wifi-broadcast"]',
    priHelpBlock: '.primary-help-block',
    wepHelpBlock: '.wep-help-block',
  },

  events: {
    'change @ui.encryptionType': 'checkEncryptionType',
    'blur input[type="text"]': 'checkConfig',
    'blur input[type="password"]': 'checkConfig',
    'click @ui.broadcast': 'checkConfig',
    'change @ui.parentVlan': 'checkConfig',
  },

  modelEvents: {
    'invalid': 'onError',
    'change:encryptionType': 'onChangeEncryptionType',
  },

  initialize: function(options) {
    this.setDefaults({silent: true});
  },

  onRender: function() {
    this.onChangeEncryptionType(this.model, this.model.get('encryptionType'));

    // initialize select2 plugin on VLANs dropdown
    var select2 = this.ui.parentVlan.select2({
      placeholder: '',
      width: '100%',
      templateResult: _.bind(this.formatVlanDetails, this),
      minimumResultsForSearch: 10,
      theme: 'bootstrap',
    });

    // HACK select2 doesn't support this, need to hack our way to add it
    select2.data('select2').$dropdown.addClass('multicontent');
  },

  onAttach: function() {
    // our list of available parent VLANs needs to be redone as networks come and go
    this.listenTo(this.model.deviceConfig.get('networks'), 'add remove', this.render);
  },

  templateContext: function() {
    return _.extend({
      /**
       * @memberof! actions/wifi/FormView#
       * @method templateContext.isNew
       * @type {Boolean}
       */
      isNew: _.bind(this.model.isNew, this.model),
      encryptionOptions: this.tplGetEncryptionOptions(),
      vlanList: _.partial(this.tplGetVlanList, this.model.deviceConfig),
      deletePendingMsg: this.tplGetDeleteMsg,
    }, tplHelpers.apply(this));
  },

  /**
   * Gets the list of LANs for populating the "assign to vlan" dropdown.
   *
   * @memberof! actions/wifi/FormView#
   * @method templateContext.vlanList
   * @param {lib/models/DeviceConfiguration} deviceConfig
   * @return {Array}
   */
  tplGetVlanList: function(deviceConfig) {
    return deviceConfig.getInternalVlans().map(function(vlan) {
      return {
        id: vlan.id,
        name: vlan.getName(),
      };
    });
  },

  tplGetEncryptionOptions: function() {
    return EditWifi.encryptionTypes.map(function(type) {
      if (type.isMostSecure) {
        return _.extend({}, type, {
          label: i18n.t('actionWifi.bestEncryption', {name: type.label}),
        });
      }
      return type;
    });
  },

  /**
   * Sets some smart-defaults.
   *
   * @param {Object} options
   */
  setDefaults: function(options) {
    var defaults = {};

    if (!this.model.has('encryptionType')) {
      defaults.encryptionType = 'psk-mixed+tkip+aes';
    }

    if (!this.model.has('broadcastSSID')) {
      defaults.broadcastSSID = true;
    }

    this.model.set(defaults, options);
  },

  /**
   * Select2 plugin to decorate the dropdown options. In this case, we are
   * using multiline content.
   *
   * @param {Object} data
   * @param {Object} container
   * @return {jQuery}
   */
  formatVlanDetails: function(data, container) {
    var deviceConfig = this.model.deviceConfig;
    var vlan;
    var details = {};
    var subnet;
    var dhcpPool;
    var ip;
    var html;

    if (!data.id) {
      return $('');
    }

    vlan = deviceConfig.get('networks').get(data.id);

    details = {
      id: vlan.id,
      name: vlan.getName(),
    };

    subnet = deviceConfig.getSubnet(vlan.id);
    dhcpPool = deviceConfig.getDhcpPool(vlan.id);
    ip = new Ip(subnet.get('address'));

    ip.cidr(subnet.get('size'));

    details.subnet = ip.network() + ' - ' + ip.broadcast();

    if (!_.isUndefined(dhcpPool)) {
      details.dhcpPool = dhcpPool.get('startIp') + ' - ' + dhcpPool.get('endIp');
    }

    html = this.vlanDetailsTpl.render(_.extend(details, {t: _.bind(i18n.t, i18n)}));
    return $(html);
  },

  /**
   * Event handler for when the encryption type dropdown has changed.
   *
   * @param {Event} ev
   */
  checkEncryptionType: function(ev) {
    var type = ev.currentTarget.value;
    var data = {};
    ev.preventDefault();

    data.encryptionType = type;

    if (type === 'none') {
      data.encryptionKey = '';
    }

    if (type === 'wep') {
      this.ui.priHelpBlock.addClass('hidden');
      this.ui.wepHelpBlock.removeClass('hidden');
    } else {
      this.ui.priHelpBlock.removeClass('hidden');
      this.ui.wepHelpBlock.addClass('hidden');
    }

    this.model.set(data);
  },

  /**
   * Blur and click handlers for model data in form.
   *
   * @param {Event} ev
   */
  checkConfig: function(ev) {
    var name = ev.currentTarget.name;
    var value = ev.currentTarget.value;
    var data = {};

    switch (name) {
      case 'wifi-ssid':
        this.ui.ssid.bs3ui('clearFieldError');
        data.ssid = value;
        break;

      case 'wifi-encryption-key':
        this.ui.encryptionKey.bs3ui('clearFieldError');
        data.encryptionKey = value;
        break;

      case 'wifi-parentVlan':
        this.ui.parentVlan.bs3ui('clearFieldError');
        data.parentVlan = value;
        break;

      case 'wifi-broadcast':
        data.broadcastSSID = !!ev.currentTarget.checked; // force to boolean
        break;

      default:
        break;
    }

    this.model.set(data, {commit: true});
  },

  /**
   * Shows/hides encryption options depending on the type selected.
   *
   * @param {actions/wifi/EditWifi} model
   * @param {String} encryptionType
   * @param {Object} options
   */
  onChangeEncryptionType: function(model, encryptionType, options) {
    if (encryptionType === 'none') {
      this.ui.encryptionOptions.addClass('hidden');
    } else {
      this.ui.encryptionOptions.removeClass('hidden');
    }
  },

  /**
   * Decorates the form fields to indicate error.
   *
   * @param {actions/wifi/EditWifi} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.ssid) {
      this.ui.ssid.bs3ui('showFieldError', error.ssid);
    }

    if (error.encryptionKey) {
      this.ui.encryptionKey.bs3ui('showFieldError', error.encryptionKey);
    }

    if (error.parentVlan) {
      this.ui.parentVlan.bs3ui('showFieldError', error.parentVlan);
    }
  },
});
