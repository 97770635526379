'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/staticHostMapping/staticHostMapping.html');
var twig = require('twig').twig;

/**
 * Renders a text-only version of an individual static host mapping
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/staticHostMapping/StaticHostMappingView#model
   * @type {actions/staticHostMapping/StaticHostMapping}
   */

  template: twig({data: tpl}),
});
