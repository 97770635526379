'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');

module.exports = ActionItem.extend({

  _snapshotAttributes: ['categories'],

  toggledOnOffBy: 'webFiltersTitanHqEnable',

  defaults: function() {
    return {
      categories: [],
    };
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('webFiltersTitanHq');
      },
      events: 'change:categories',
    },
  ],

  /**
   * Parse response for configuration.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var webFilters = this.deviceConfig.get('webFiltersTitanHq');

      if (webFilters) {
        return {
          categories: _.cloneDeep(webFilters.get('categories')),
        };
      }

      return this.defaults();
    }

    return resp;
  },

  setCategoryState: function(selectedCategoryValues) {
    var categories = _.cloneDeep(this.get('categories'));
    categories.forEach(function(category) {
      category.enabled = _.indexOf(selectedCategoryValues, category.id) >= 0;
    });

    this.set({'categories': categories});
  },

  setAllCategories: function() {
    var categories = _.cloneDeep(this.get('categories'));
    categories.forEach(function(category) {
      category.enabled = true;
    });

    this.set({'categories': categories});
  },

  unsetAllCategories: function() {
    var categories = _.cloneDeep(this.get('categories'));
    categories.forEach(function(category) {
      category.enabled = false;
    });

    this.set({'categories': categories});
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    return {
      name: 'webFiltersTitanHq.setOptions',
      data: {
        categories: this.get('categories'),
      },
    };
  },
});
