'use strict';

var ActionItem = require('actions/shared/ActionItem');

/**
 * Edit model for Intrusion Detection.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/intrusionDetection/EditIntrusionDetection#attributes
   * @property {boolean} enabled
   *  enabled or disabled
   * @property {boolean} prevention
   *  detect only or detect and prevent
   * @property {string} sensitivity
   *  low or high
   */

  _snapshotAttributes: ['enabled', 'prevention', 'sensitivity', 'inspectionLimit'],

  initialize: function(attributes, options) {
    this.addDeviceStatus(options);
    this.set({supportsSnortInspLmt: this.deviceStatus.hasCapability('supportsSnortInspectionLimit')},
      {fromConfig: true});
    this.deviceStatus.onCapabilityAdded(this, 'supportsSnortInspectionLimit', function() {
      this.set({supportsSnortInspLmt: true}, {fromConfig: true});
      this.takeSnapshot();
    }.bind(this));
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  getOnOffState: function() {
    return !!this.get('enabled');
  },

  /**
   * Parse response for enabled.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var intrusionDetection = this.deviceConfig.get('intrusionDetection');

      return {
        enabled: intrusionDetection.get('enabled'),
        prevention: intrusionDetection.get('prevention'),
        sensitivity: intrusionDetection.get('sensitivity'),
        inspectionLimit: intrusionDetection.get('inspectionLimit'),
      };
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('intrusionDetection');
      },
      events: 'change',
    },
  ],

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    return {
      name: 'intrusionDetection.setOptions',
      data: {
        enabled: this.get('enabled'),
        prevention: this.get('prevention'),
        sensitivity: this.get('sensitivity'),
        inspectionLimit: this.get('inspectionLimit'),
      },
    };
  },
});
