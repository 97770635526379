'use strict';

var _ = require('lodash');
var $ = require('jquery');
var i18n = require('i18next');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var Radio = require('backbone.radio');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');
var ConfigMessageTypes = require('manage/edit/ConfigMessageTypes');
var tplDeleting = require('manage/edit/config/group/removeGroup/overlay.html');
var tplSuccess = require('manage/edit/config/group/removeGroup/deleted.html');
var timeouts = require('lib/saveTimeouts');

/**
 * Displays a message that the group is being deleted.
 *
 * Although this is a Behavior it is very tightly coupled to the GroupView and
 * should not be reused as if it was a reusable behavior.
 */
module.exports = Marionette.Behavior.extend({
  deletingTemplate: twig({data: tplDeleting}),
  successTemplate: twig({data: tplSuccess}),

  message: i18n.t('configEdit.removingGroup'),
  longMessage: i18n.t('configEdit.stillRemovingGroup'),

  ui: {
    deleteBtn: '[name="confirm-delete-yes"]',
    dismissBtn: '.dismiss',
  },

  longTimer: undefined,
  maxTimer: undefined,

  /**
   * Start removing the group.
   *
   * @listens lib/behaviors/confirmDelete/ConfirmDelete~delete:item:confirmed
   * @param {Object} options
   */
  onDeleteItemConfirmed: function(options) {
    var self = this;
    // grab the group name now because it may not be available once removal completes
    var name = this.view.model.description();

    this.view.model.set('isApplying', true);
    this.view.model.set('pendingDelete', true);
    deviceConfigChannel.trigger('save:start');

    self.view.alertView.trigger('clear', {animate: false});
    this.showOverlay();

    // listen for job results (successful or failed)...
    this.listenTo(this.view.model, 'change:jobResults', function(model, jobResults, options) {
      self.cleanUp();
      self.processResults(jobResults, name);
    });
    // ... as well as for removal of the group, which can happen before job results
    this.listenTo(this.view.model, 'group:removed', function() {
      self.cleanUp();
      self.showSuccess(name);
    });

    this.longTimer = setTimeout(this.onLongTime.bind(this), timeouts.FEEDBACK_TIME);
    this.maxTimer = setTimeout(this.onTimeout.bind(this), this.view.model.getConfigCardTimeout());

    this.view.model.delete()
      .fail(function(errorObj) {
        // In a client-side failure, the error object will already have the proper
        // structure for showErrors, however when the error is a server side RPC
        // error, it won't. The ternary statement will catch that situation and
        // create the proper structure.
        var errors = errorObj && errorObj.type ?
          errorObj : {type: ConfigMessageTypes.JOB_ERRORS, errors: errorObj};
        self.cleanUp();
        self.showError(errors);
      });
  },

  /**
   * If the removal isn't processed in less than
   * FEEDBACK_TIME, this timeout fires and updates
   * the text in the overlay to say that the
   * processing is still happening
   */
  onLongTime: function() {
    var message = this.longMessage;
    if (this.$modal) {
      this.$modal.find('.overlay2__message').text(message);
    }
  },

  /**
   * If the removal isn't processed in less than
   * the MAX_TIME, this timeout fires
   * and lets the UI function again
   */
  onTimeout: function() {
    this.cleanUp();
    this.showError({type: ConfigMessageTypes.JOB_TIMEOUT});
  },

  /**
   * Gets the results of the last job applied
   * and displays the appropriate information
   * based on the jobs results status
   *
   * @param {Object} jobResults
   *   The list of results from running each job.
   * @param {String} groupName
   *   Name of the group prior to removal
   */
  processResults: function(jobResults, groupName) {
    var results = _.first(jobResults);

    if (results.status === 'applied') {
      this.showSuccess(groupName);
    } else if (results.status === 'invalid') {
      this.showError({type: ConfigMessageTypes.INVALID_UCI, errors: results.results});
    } else if (results.status === 'failed') {
      this.showError({type: ConfigMessageTypes.JOB_ERRORS, errors: results.results});
    }
  },

  /**
   * Displays the "deleting group" overlay.
   */
  showOverlay: function() {
    var message = this.message;

    this.$modal = $(this.deletingTemplate.render({message: message}));
    this.$el.append(this.$modal);
  },

  /**
   * On a successful deletion, insert a success message before the group card.
   * Note, we don't actually change the group card because it is expected to
   * be removed automatically when the newest configOutline is processed. The
   * CollectionView (manage/edit/config/group/GroupsView) will remove groups
   * that no longer exist in the configOutline.
   *
   * @param {String} name
   *   Name of the group to display
   */
  showSuccess: function(name) {
    var tplData = {name: name};
    var $deletedMsg = $(this.successTemplate.render(tplData));
    this.view.$el.before($deletedMsg);

    $deletedMsg.on('click', '.dismiss', function(ev) {
      ev.preventDefault();
      $deletedMsg.remove();
    });

    window.setTimeout(function() {
      $deletedMsg.fadeOut(function() {
        $deletedMsg.remove();
      });
    }, 5000);
  },

  /**
   * Displays the error message.
   *
   * @param {Object} errorObj
   */
  showError: function(errorObj) {
    this.view.alertView.trigger('show', errorObj);
    this.view.scrollView({scroll: false});
  },

  /**
   * Removes overlay and resets listeners, timers,
   * and model state after removal succeeds or fails
   */
  cleanUp: function() {
    clearTimeout(this.longTimer);
    clearTimeout(this.maxTimer);
    this.view.model.set({isApplying: false, pendingDelete: false});
    this.stopListening(this.view.model, 'change:jobResults');
    this.stopListening(this.view.model, 'group:removed');
    if (this.$modal) {
      this.$modal.remove();
      this.$modal = null;
    }
    deviceConfigChannel.trigger('save:end');
  },
});
