'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var layoutTpl = require('manage/details/wifi/wifi-layout.html');
var DevicesListView = require('manage/details/ConnectedDevicesListView');
var WifiDetailsView = require('manage/details/wifi/WifiDetailsView');
var Graph = require('components/usage/GraphItem');
var NetworksNav = require('manage/details/NetworksDropdown');

/**
 * Renders the selected Wi-Fi View. Items displayed includes total usage
 * for the network, list of connected devices and configuration/status.
 */
module.exports = Marionette.View.extend({
  // extends base template defined in manage/details/dna/DnaView
  template: twig({allowInlineIncludes: true, data: layoutTpl}),

  className: 'row',

  regions: {
    details: '.rg-config-details',
    devicesList: '.rg-devices-list',
    usage: '.rg-graphs',
    networksNav: '.rg-networks-nav',
  },

  ui: {
    devicesListWrap: '.devices',
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.model.fetch();
  },

  onRender: function() {
    this.addWifiDetails();
    this.addDevicesList();

    var id = this.model.get('id').toLowerCase();
    var usage = this.model.get('bandwidth');
    var deviceStatus = this.model.get('deviceStatus');

    var graph = new Graph({
      id: id,
      usage: usage,
      deviceStatus: deviceStatus,
      graphType: 'internal-network',
    });

    this.showChildView('networksNav', new NetworksNav({
      deviceMac: this.model.get('deviceMac'),
      deviceConfig: this.model.get('deviceConfig'),
      current: this.model.id,
    }));

    this.showChildView('usage', graph);
  },

  /**
   * Adds the "network details" section, which lists Wi-Fi config and status.
   */
  addWifiDetails: function() {
    var detailsView = new WifiDetailsView({
      model: this.model,
    });

    this.showChildView('details', detailsView);
  },

  /**
   * Adds the list of devices connected to the current Wi-Fi network.
   */
  addDevicesList: function() {
    var attrs = ['deviceMac', 'deviceConfig', 'deviceStatus', 'bandwidth'];
    var viewOptions = this.model.pick(attrs);
    var listView;

    viewOptions.vlanId = this.model.id;

    listView = new DevicesListView(viewOptions);

    this.showChildView('devicesList', listView);
  },
});
