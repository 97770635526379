'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/dhcpAdvOptions/DHCPOption/StaticDHCPOptionForm.html');
var twig = require('twig').twig;

/**
 * Renders a text-only version of an individual DHCP option
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/dhcpAdvOptions/StaticDHCPOptionView#model
   * @type {actions/dhcpAdvOptions/EditDhcpAdvOptions}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers);
  },
});
