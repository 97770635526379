'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var networkUtils = require('lib/network');

/**
 * Represents an individual failover policy
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({

  /**
   * @member {Object} #attributes
   * @property {Boolean} remove
   *   Whether the policy should be removed on save
   * @property {String} id
   *   ID of the policy
   * @property {String} name
   *   Name of the policy
   * @property {Boolean} enabled
   *   Whether the policy is enabled
   * @property {String} srcIp
   *   IP address for source
   * @property {String} proto
   *   protocol to interrupt; one of the values "tcp"|"udp"
   * @property {String} destIp
   *   Destination IP address to interrupt (optional)
   * @property {String} portRange
   *   Port or Port Range to interrupt
   * @property {String} method
   *   Method of interrupting traffic; see docs for possible values
   */

  idAttribute: 'redirectId',

  _snapshotAttributes: [
    'remove',
    'name',
    'enabled',
    'srcIp',
    'proto',
    'destIp',
    'portRange',
    'method',
  ],

  defaults: {
    remove: false,
    name: null,
    enabled: true,
    srcIp: null,
    proto: 'tcp',
    destIp: null,
    portRange: null,
    method: 'icmp-port-unreachable',
  },

  /**
   * @fires noConflicts
   *   Validation has determined this policy is free of conflicts with other policies
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true) {
      // policies pending removal are never considered invalid
      return;
    }

    if (_.has(attrs, 'name')) {
      if (!attrs.name || !attrs.name.trim()) {
        errors.name = i18n.t('failoverPolicyControl.badPolicyName');
      }
    }

    if (_.has(attrs, 'portRange') && attrs.portRange) {
      if (!networkUtils.validPort(attrs.portRange)
          && !networkUtils.validPortRange(attrs.portRange)) {
        errors.portRange = i18n.t('failoverPolicyControl.invalidPortRange');
      }
    }

    if (options.allItems && !errors.portRange) {
      if (_.any(options.allItems, this.conflictsWithPolicy, this)) {
        errors.portRange = i18n.t('failoverPolicyControl.portConflict');
      } else {
        // since policy conflict errors can be eliminated by editing the _other_
        // policy(s) involved, and backbone has no "valid" event, provide a way
        // for views to know that conflict errors on this policy should be cleared
        this.trigger('noConflicts');
      }
    }

    if (_.has(attrs, 'destIp') && attrs.destIp) {
      if (!networkUtils.validIP(attrs.destIp)
          && !networkUtils.validSubnet(attrs.destIp)) {
        errors.destIp = i18n.t('failoverPolicyControl.badDestIp');
      }
    }

    if (_.has(attrs, 'srcIp')) {
      if (!networkUtils.validInternalIPv4(attrs.srcIp)
          && !networkUtils.validInternalSubnet(attrs.srcIp)) {
        errors.srcIp = i18n.t('failoverPolicyControl.badSrcIp');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return {
        name: 'failoverPolicyControl.removeCustomPolicy',
        data: {
          id: this.id,
        },
      };
    }

    if (this.isNew() || this.getSnapshotDiff()) {
      var task = {
        name: 'failoverPolicyControl.addCustomPolicy', // Used for update if an ID is sent...
        data: {
          name: this.get('name'),
          enabled: this.get('enabled'),
          srcIp: this.get('srcIp'),
          proto: this.get('proto'),
          destIp: this.get('destIp'),
          portRange: this.get('portRange'),
          method: this.get('method'),
        },
      };
      if (!this.isNew()) {
        // ... so include the ID if it's an update
        task.data.id = this.id;
      }
      return task;
    }
  },

  /**
   * Checks whether this policy conflicts with the provided policy.
   * Assumes that this policy has a valid source port or source port range.
   *
   * @param {actions/failoverPolicyControl/policies/policy} policy
   *   An instance of this model to check for conflicts
   * @return {Boolean}
   */
  conflictsWithPolicy: function(policy) {
    // a policy never conflicts with itself
    // and don't consider policies that are about to be removed
    // and policies can't conflict if they don't apply to the same protocol
    // and policies can't conflict if they don't apply to the same source ip
    if (policy === this ||
        policy.get('remove') === true ||
        this.get('proto') !== policy.get('proto') ||
        this.get('srcIp') !== policy.get('srcIp')) {
      return false;
    }

    var thisDestIp = this.get('destIp') || '';
    var otherDestIp = policy.get('destIp') || '';

    if (thisDestIp !== otherDestIp) {
      // prohibit ambiguous policy - if either dest ip is empty, then ports need to be different
      if (thisDestIp !== '' && otherDestIp !== '') {
        return false;
      }
    }

    // If either range is empty, then they overlap
    if (!this.get('portRange') || !policy.get('portRange')) {
      return true;
    }

    var startOne = this.getPortStart(this.get('portRange'));
    var endOne = this.getPortEnd(this.get('portRange'));
    var startTwo = this.getPortStart(policy.get('portRange'));
    var endTwo = this.getPortEnd(policy.get('portRange'));

    return !(startOne > endTwo || startTwo > endOne);
  },

  /**
   * Utility function to return the start of a port range
   * If not a range, will return the port
   *
   * @param {String} portRange
   *   Port(s) to parse
   * @return {Int}
   */
  getPortStart: function(portRange) {
    var index = portRange.indexOf('-');
    if (index === -1) {
      return parseInt(portRange);
    }
    return parseInt(portRange.slice(0, index));
  },

  /**
   * Utility function to return the end of a port range
   * If not a range, will return the port
   *
   * @param {String} portRange
   *   Port(s) to parse
   * @return {Int}
   */
  getPortEnd: function(portRange) {
    return parseInt(portRange.slice(portRange.indexOf('-') + 1));
  },

  /**
   * Utility function to determine if a port range is a range or just a port
   *
   * @param {String} portRange
   *   Port(s) to parse
   * @return {Boolean}
   */
  isRange: function(portRange) {
    return portRange.indexOf('-') !== -1;
  },

});
