'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var RuleView = require('actions/shared/portForwarding/StaticRuleView');
var tpl = require('actions/onemanyNat/static.html');
var header = require('actions/onemanyNat/header.html');
var twig = require('twig').twig;
var viewFilters = require('lib/viewFilters');

var RulesListView = Marionette.CollectionView.extend({
  childView: function(item) {
    if (!item.has('header')) {
      return RuleView;
    }

    return Marionette.View.extend({
      className: 'list-section-header',
      template: twig({data: header}),
    });
  },
});

module.exports = BaseStaticView.extend({
  template: twig({data: tpl}),

  regions: {
    rules: '.rg-rules',
  },

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      getItemCountHelp: viewFilters.getItemCountHelpFn({
        // i18n.t('portForwarding.noRules')
        emptyMsgKey: 'portForwarding.noRules',
        // i18n.t('portForwarding.allDisabled', {count: count})
        allDisabledMsgKey: 'portForwarding.allDisabled',
        // i18n.t('portForwarding.viewAll', {count: count})
        moreMsgKey: 'portForwarding.viewAll',
      }),
    });
  },

  onRender: function() {
    this.initChildViews();
  },

  initChildViews: function() {
    var collection = new Backbone.Collection();
    var rulesAdded = 0;

    var enabled = this.model.get('items').where({enabled: true});
    var byIngressIp = _.groupBy(enabled, function(rule) {
      return rule.get('srcIp');
    });

    var ingressIps = Object.keys(byIngressIp);
    for (var i = 0; i < ingressIps.length && rulesAdded < viewFilters.MAX_STATICVIEW_ITEMS; i++) {
      var ingressIp = ingressIps[i];
      var rules = byIngressIp[ingressIp];
      // add rules for this ingress IP until we run out, or hit the max # to show in static view
      var count = Math.min(rules.length, viewFilters.MAX_STATICVIEW_ITEMS - rulesAdded);
      collection.add({header: ingressIp});
      collection.add(rules.slice(0, count));
      rulesAdded += count;
    }

    this.showChildView('rules', new RulesListView({
      collection: collection,
    }));
  },
});
