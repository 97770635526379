'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var networkUtils = require('lib/network');

/**
 * Edit Model for creating/managing a DHCP relay.
 *
 * Note, DHCP is unimplemented for IPv6 at this time.
 *
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/dhcpRelay/EditDhcpRelay#attributes
   * @property {String} id
   *   The VLAN will be used to get the subnet for the DHCP relay.
   * @property {String} serverAddr
   *   The IP address of the server to which DHCP requests will be relayed
   *
   * @property {Backbone.ReadOnlyModel} subnet
   *   A reference to the (deviceConfig) subnet Model this DHCP relay is associated with.
   */

  defaults: {
    pendingDelete: false,
  },

  _snapshotAttributes: ['id', 'serverAddr', 'pendingDelete'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    var dhcpRelay = this.deviceConfig.getDhcpRelay(this.id);
    return _.isUndefined(dhcpRelay);
  },

  /**
   * @param {Object} options
   * @return {Object}
   */
  toJSON: function(options) {
    var data = ActionItem.prototype.toJSON.apply(this, arguments);

    // No need to serialize this since it is a reference to deviceConfig
    delete data.subnet;

    return data;
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig) {
      resp = this.getAttributesByVlanId(this.id);
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks').get(this.id);
      },
      events: 'change:dhcpRelays',
    },
    {
      getDispatcher: function(config) {
        return config.getDhcpRelay(this.id);
      },
      events: 'change',
    },
    {
      getDispatcher: function() {
        return this.getDependency('lanAddress');
      },
      events: 'change:address',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (!_.isUndefined(attrs.id)) {
      if (attrs.id === '' || !this.hasValidSubnet()) {
        errors.id = i18n.t('actionDhcp.invalidVlan');
      }
    }

    if (!_.isUndefined(attrs.serverAddr) && !networkUtils.validIP(attrs.serverAddr, this.get('subnet').get('type'))) {
      errors.serverAddr = i18n.t('actionDhcp.invalidRelay');
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    var data = {};

    if (!this.isNew() || this.get('pendingDelete') === true) {
      data.id = this.id;
    }

    if (this.get('pendingDelete') === true) {
      return {
        name: 'dhcpRelay.delete',
        data: data,
      };
    }

    data.serverAddr = this.get('serverAddr');
    data.localAddr = this.get('subnet').get('address');

    return {
      name: 'dhcpRelay.create',
      data: data,
    };
  },

  /**
   * Gathers the passed vlan's data for dhcp relays and returns it.
   *
   * @param {String} vlanId
   * @returns {Object}
   */
  getAttributesByVlanId: function(vlanId) {
    var dhcpRelay = this.deviceConfig.getDhcpRelay(vlanId);
    var lanAddressModel = this.getDependency('lanAddress');
    var serverAddr = '';
    if (!_.isUndefined(dhcpRelay)) {
      serverAddr = dhcpRelay.get('serverAddr');
    }

    var data = {
      subnet: new Backbone.Model({
        type: lanAddressModel.get('af'),
        address: lanAddressModel.get('address'),
        size: lanAddressModel.get('size'),
      }),
      serverAddr: serverAddr,
    };

    return data;
  },

  /**
   * Does the associated VLAN have an address.
   *
   * @return {Boolean}
   */
  hasValidSubnet: function() {
    var subnet = this.get('subnet');

    return subnet &&
      (!_.isUndefined(subnet.get('address')) || !_.isEmpty(subnet.get('address'))) &&
      !_.isUndefined(subnet.get('size')) &&
      networkUtils.validIP(subnet.get('address'), subnet.get('type'));
  },

  /**
   * Returns the message to use when a DHCP relay is being removed.
   *
   * @see actions/shared/removeConfig/RemoveConfig
   * @see manage/edit/config/action/ActionView
   * @return {String}
   */
  getDeleteMsg: function() {
    return i18n.t('actionDhcp.taskDeleteRelay');
  },
});
