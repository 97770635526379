'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/description/form.html');
var twig = require('twig').twig;
require('lib/jquery/bootstrapUI');
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');

/**
 * Renders the "Description" action component.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/description/FormView#model
   * @type {
   *    actions/description/wifi/EditDescription|
   *    actions/description/vlan/EditDescription|
   *    actions/description/siteVpn/EditDescription
   *  }
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    'description': '[name="description"]',
  },

  events: {
    'blur @ui.description': 'checkDescription',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  templateContext: tplHelpers,

  /**
   * Callback for user changing the description text.
   *
   * @callback
   * @param {Event} ev
   */
  checkDescription: function(ev) {
    ev.preventDefault();

    var desc = ev.currentTarget.value;
    this.ui.description.bs3ui('clearFieldError');
    this.model.set({description: desc}, {commit: true});
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/description/vlan/EditDescription|
   * actions/description/wifi/EditDescription|
   * actions/description/siteVpn/EditDescription} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.description) {
      this.ui.description.bs3ui('showFieldError', error.description);
    }
  },
});
