'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var RuleView = require('actions/shared/portForwarding/StaticRuleView');
var tpl = require('actions/portForwardingRules/static.html');
var twig = require('twig').twig;
var viewFilters = require('lib/viewFilters');

var RulesListView = Marionette.CollectionView.extend({
  childView: RuleView,
  filter: viewFilters.getStaticViewItemsCollectionViewFilter(),
});

/**
 * Renders a text-only version of the "port forwarding rules" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/shared/portForwarding/StaticView#model
   * @type {actions/shared/portForwarding/EditPortForwardingRules}
   */

  template: twig({data: tpl}),

  regions: {
    rules: '.rg-rules',
  },

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      getItemCountHelp: viewFilters.getItemCountHelpFn({
        // i18n.t('portForwarding.noRules')
        emptyMsgKey: 'portForwarding.noRules',
        // i18n.t('portForwarding.allDisabled', {count: count})
        allDisabledMsgKey: 'portForwarding.allDisabled',
        // i18n.t('portForwarding.viewAll', {count: count})
        moreMsgKey: 'portForwarding.viewAll',
      }),
    });
  },

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.showChildView('rules', new RulesListView({
      collection: this.model.get('items'),
    }));
  },

});
