'use strict';

var i18n = require('i18next');
var EditDmz = require('actions/dmz/EditDmz');
var FormView = require('actions/dmz/FormView');
var StaticView = require('actions/dmz/StaticView');

/**
 * Action configuration for DMZ
 */
module.exports = {
  title: i18n.t('actionDmz.title'),
  Model: EditDmz,
  FormView: FormView,
  TextView: StaticView,
};
