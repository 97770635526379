'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var tplWrapper = require('manage/edit/config/group/group-wrapper.html');
var ConfigMessageView = require('manage/edit/ConfigMessage');
var ConfirmDelete = require('lib/behaviors/confirmDelete/ConfirmDelete');
var SaveChanges = require('manage/edit/config/group/saveChanges/SaveChanges');
var RunTest = require('manage/edit/config/group/runTest/RunTest');
var ScrollTo = require('lib/behaviors/ScrollTo');
var RemoveGroup = require('manage/edit/config/group/removeGroup/RemoveGroup');
var GroupAlertsView = require('manage/edit/config/group/groupAlerts/GroupAlertsView');
var ActionsListView = require('manage/edit/config/group/ActionsListView');
var ActionsListRegion = require('manage/edit/config/group/ActionsListRegion');
var NetworkDisruptionAlert = require('actionGroups/shared/NetworkDisruptionView');

/**
 * Renders a single {@link config/Group|configuration group}.
 */
module.exports = Marionette.View.extend({
  /**
   * Details about the configuration group.
   * @member {config/Group} manage/edit/group/GroupView#model
   */

  /**
   * List of actions associated with the configuration group.
   * @member {config/Actions} manage/edit/group/GroupView#collection
   */

  template: twig({data: tplWrapper}),

  tagName: 'section',

  className: 'dui-card dui-card--no-padding',

  regions: {
    actionsListFull: {regionClass: ActionsListRegion, el: '.rg-action-list-full'},
    actionsListFirstColumn: {regionClass: ActionsListRegion, el: '.rg-action-column-first'},
    actionsListMiddleColumn: {regionClass: ActionsListRegion, el: '.rg-action-column-middle'},
    actionsListLastColumn: {regionClass: ActionsListRegion, el: '.rg-action-column-last'},
    headerAction: '.rg-header-action',
    configWarnings: '.rg-config-warnings',
  },

  actionRegions: [
    'actionsListFull',
    'actionsListFirstColumn',
    'actionsListMiddleColumn',
    'actionsListLastColumn',
    'headerAction',
  ],

  ui: {
    btnsWrap: '.btns-wrap',
    cancelBtn: '[name="cancel"]',
    actions: '.rg-actions-list',
    inputs: 'input',
    selects: 'select',
    sliders: '.slider',
  },

  behaviors: [
    {
      behaviorClass: ConfirmDelete,
      message: function() {
        if (this.isRemovable()) {
          return i18n.t('configEdit.deleteGroup', {name: this.model.description()});
        }
        return i18n.t('configEdit.cannotDeleteGroup', {name: this.model.description()});
      },
      subMessage: function() {
        return this.getRemovalSubMessage();
      },
      defaultIsYes: function() {
        return this.getRemovalDefaultIsYes();
      },
      showDangerMessage: function() {
        return this.getRemovalIsDangerous();
      },
      isRemovable: function() {
        return this.isRemovable();
      },
    },
    {
      behaviorClass: SaveChanges,
    },
    {
      behaviorClass: RunTest,
    },
    {
      behaviorClass: ScrollTo,
    },
    {
      behaviorClass: RemoveGroup,
    },
  ],

  events: {
    'click': 'onClickToFocus',
    'click @ui.cancelBtn': 'onCancel',
    'change @ui.inputs': 'onDirty',
    'change @ui.selects': 'onDirty',
    'input @ui.selects': 'onChange',
    'input @ui.inputs': 'onChange',
    'change input[type=radio]': 'onChange',
    'change input[type=checkbox]': 'onChange',
    'select2:select @ui.selects': 'onChange',
    'slideStop @ui.sliders': 'onChange',
  },

  modelEvents: {
    'change:isFocused': 'onFocusChange',
    'change:isSaveable': 'onIsSaveableChange',
    'change:isDirty': 'onIsDirtyChange',
    'change:isApplying': 'onIsApplyingChange',
    'action:add': 'onActionAdd',
  },

  collectionEvents: {
    'change:isLoading': 'onLoadingChange',
    'action:remove': 'onActionRemove',
    'action:dependencies:ready': 'onActionWasAdded',
    'remove': 'onActionWasRemoved',
  },

  /**
   * @param {Object} options
   * @property {Backbone.Radio} options.navChannel
   *   Allows group Views to trigger focusing or adding config groups from
   *   within other config groups.
   */
  initialize: function(options) {
    _.bindAll(this, 'onDirty', 'registerConfigReparseTriggers', 'onConfigReparseNeeded');
    this.mergeOptions(options, ['navChannel']);
    this._ = options._ || _; // allow underscore/lodash injection for unit testing
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    return {
      isNew: this.model.isNew.bind(this.model),
      title: this.model.title.bind(this.model),
      titleOverwrite: this.model.titleOverwrite.bind(this.model),
      applyLabel: this.model.applyLabel.bind(this.model),
      isRequired: this.model.isRequired(this.model),
      isOperatorTool: this.model.isOperatorTool.bind(this.model),
    };
  },

  onBeforeRender: function() {
    this.el.setAttribute('data-tag', 'config-group-' + _.toArray(this.model.pick('type', 'typeId')).join('-'));
  },

  onRender: function() {
    this.initChangesetErrorView();

    this.onIsDirtyChange(this.model, this.model.get('isDirty'), null);

    this.showChildView('actionsListFull', new ActionsListView.Full({
      collection: this.collection,
      removeCallback: this.onDirty,
    }));
    this.showChildView('actionsListFirstColumn', new ActionsListView.First({
      collection: this.collection,
      removeCallback: this.onDirty,
    }));
    this.showChildView('actionsListMiddleColumn', new ActionsListView.Middle({
      collection: this.collection,
      removeCallback: this.onDirty,
    }));
    this.showChildView('actionsListLastColumn', new ActionsListView.Last({
      collection: this.collection,
      removeCallback: this.onDirty,
    }));
    this.showChildView('headerAction', new ActionsListView.Header({collection: this.collection}));
    this.showChildView('configWarnings', new GroupAlertsView({collection: new Backbone.Collection()}));

    // Most config groups will load up really fast and the "change:isLoading"
    // collection event will never be fired, so we manually call the handler
    // just in case.
    this.onLoadingChange();

    // If DNA is updating, offline, etc. be sure the apply buttons are disabled
    if (this.model.get('isSaveable') === false) {
      this.onIsSaveableChange(this.model, false);
    }
  },

  onAttach: function() {
    this.collection.forEach(this.registerConfigReparseTriggers);
  },

  /**
   * Hooks up listeners when an action is added to the group
   * and its dependencies (if any) are wired
   *
   * @param {config/Action} action
   */
  onActionWasAdded: function(action) {
    this.registerConfigReparseTriggers(action);
  },

  /**
   * Removes listeners added via registerConfigReparseTriggers
   * (or any other way) when an action is removed from the group
   *
   * @param {config/Action} action
   */
  onActionWasRemoved: function(action) {
    action.stopListening();
  },

  /**
   * Listens for external changes in the device config that need to be incorporated
   * into an action's view model
   *
   * @param {config/Action} action
   */
  registerConfigReparseTriggers: function(action) {
    var childViewModel = action.get('actionModel');
    var config = childViewModel.deviceConfig;
    var _ = this._;

    if (config && childViewModel.reparseConfigTriggers) {
      var unsubscribers = [];
      // using `debounce` so the handler only runs once, after the new
      // config has been fully processed (could fire multiple events)
      var handler = _.debounce(_.partial(this.onConfigReparseNeeded, action, unsubscribers));

      childViewModel.reparseConfigTriggers.forEach(function(trigger) {
        var dispatcher = trigger.getDispatcher.call(childViewModel, config);
        var events = trigger.events;

        action.listenTo(dispatcher, events, handler);
        unsubscribers.push(function() {
          action.stopListening(dispatcher, events, handler);
        });
      });
    }
  },

  /**
   * Handles a trigger indicating an action's view model is out of date with the
   * saved device configuration and needs to re-fetch/re-parse from the config
   *
   * @param {config/Action} action
   * @param {Array} unsubscribers
   */
  onConfigReparseNeeded: function(action, unsubscribers) {
    var childViewModel = action.get('actionModel');

    // Currently, external changes to the config are applied automatically to the
    // action, overwriting any unsaved edits. In the future, we may want to
    // be kinder if the user is still editing a card, showing a notice that the
    // config has changed, and asking if they want to load the new saved values
    childViewModel.fetch().done(function() {
      childViewModel.trigger('sync:fromConfig');
    });

    // reset all listeners for this action (in case dispatchers need to change)
    // Note: we can't just call action.stopListening() because that might kill
    // other listeners that we didn't add here
    _.invoke(unsubscribers, 'apply');
    this.registerConfigReparseTriggers(action);
  },

  /**
   * Triggers event when configuration group is to be focused.
   *
   * @fires manage/edit/config/group/GroupView~config:group:selected
   * @param {Event} ev
   */
  onClickToFocus: function(ev) {
    if (!this.model.get('isFocused')) {
      this.triggerMethod('config:group:selected', {model: this.model});
    }
  },

  /**
   * Triggers event when fields in the group have changed. This is a WIP.
   * We will be expanding this to make it more robust and better extracted
   * to each action.
   *
   * @param {Event} ev
   */
  onDirty: function() {
    this.model.set('isDirty', true);
  },

  /**
   * Focuses (or unfocuses) the configuration group.
   *
   * @listens config/Group~change:isFocused
   * @param {config/Group} model
   * @param {Boolean} isFocused
   * @param {Object} options
   */
  onFocusChange: function(model, isFocused, options) {
    if (isFocused === true) {
      this.$el.addClass('dui-card--focused');
      this.alertView.trigger('clear', {animate: false});
      this.fixActionFormPlugins();
    } else {
      this.$el.removeClass('dui-card--focused');
    }

    // note, we don't want to always scroll when isFocused is false because
    // that can happen when another group is being focused
    if (isFocused === true || options.undoChanges === true) {
      this.scrollView(options);
    }
  },

  onChange: function(event) {
    var alert = this.model.isConnectivityDisrupted('input', event.target);
    if (alert) {
      this.showConnectivityWarning(alert);
    }
  },

  onActionRemove: function(model) {
    var alert = this.model.isConnectivityDisrupted('removeAction', model);
    if (alert) {
      this.showConnectivityWarning(alert);
    }
  },

  onActionAdd: function(actionId) {
    var alert = this.model.isConnectivityDisrupted('addAction', actionId);
    if (alert) {
      this.showConnectivityWarning(alert);
    }
  },

  /**
   * Display a warning about service disruptions due to a config change
   *
   * @param {Function|Boolean} alert
   *  optional pre-defined alert class to show; if omitted, a generic connectivity alert will be used
   *  must define a static property 'alertId' for alert cleanup
   */
  showConnectivityWarning: function(alert) {
    var warningsView = this.getChildView('configWarnings');
    if (_.isFunction(alert)) {
      var AlertView = alert;
      warningsView.triggerMethod('remove:alert', AlertView.alertId);
      warningsView.triggerMethod('add:custom:alert', new AlertView());
    } else {
      warningsView.triggerMethod('remove:alert', NetworkDisruptionAlert.networkDisruptionAlertId);
      warningsView.triggerMethod('add:custom:alert', new NetworkDisruptionAlert());
    }
  },

  /**
   * Cancel all configuration changes. Rollback model changes and unfocus
   * the configuration group.
   *
   * @param {Event} ev
   */
  onCancel: function(ev) {
    var self = this;
    ev.stopPropagation(); // prevents View from being refocused by the default click handler

    if (this.model.isNew() !== true) {
      this.alertView.trigger('clear', {animate: false});
      this.model.set('isDirty', false);

      if (this.model.get('isFocused')) {
        this.model.set('isFocused', false, {undoChanges: true, scroll: false});
      } else {
        this.model.trigger('change:isFocused', this.model, false, {undoChanges: true, scroll: false});
      }

      var warningsView = this.getChildView('configWarnings');
      warningsView.removeAllNetworkDisruptionAlerts();

      // rerender the actions to wipe out any changes the user made
      _.each(this.actionRegions, function(region) {
        self.getChildView(region).children.invoke('refresh');
      });
    } else {
      this.model.collection.remove(this.model);
    }
  },

  /**
   * If all actions have been loaded, unhide the list (hidden by default via
   * the HTML markup).
   *
   * @listens config/Actions~change:isLoading
   * @fires actions:loaded
   * @param {manage/edit/config/ActionView} view
   * @param {Boolean} isLoading
   * @param {Object} options
   */
  onLoadingChange: function(view, isLoading, options) {
    var allActionsLoaded = this.collection.every(function(child, i, list) {
      return child.get('isLoading') === false;
    });

    if (allActionsLoaded && this.ui.actions.hasClass('hidden')) {
      this.ui.actions.removeClass('hidden');
      this.triggerMethod('actions:loaded');
    }
  },

  /**
   * If any input or select has changed, the isDirty flag is set to true
   * and the class is added to give the card an orange border to show that
   * it's not saved yet.
   *
   * This is a Work In Progress and will be expanded upon and made more robust.
   *
   * @listens config/Group~change:isDirty
   * @param {config/Group} model
   * @param {Boolean} isDirty
   * @param {Object} options
   */
  onIsDirtyChange: function(model, isDirty, options) {
    this.$el.toggleClass('dui-card--unsaved', !!isDirty);
  },

  /**
   * Tags the card while changes are being applied so that it can be styled
   * and laid out appropriately
   *
   * @param {config/Group} model
   * @param {Boolean} isApplying
   * @param {Object} options
   */
  onIsApplyingChange: function(model, isApplying, options) {
    this.$el.toggleClass('dui-card--applying', !!isApplying);
  },

  /**
   * Sets up the View that will be used to display action-related errors.
   */
  initChangesetErrorView: function() {
    var self = this;

    this.alertView = new ConfigMessageView({
      el: this.$('.config-error'),
    });

    this.on('before:destroy', function() {
      self.alertView.destroy();
    });
  },

  /**
   * When a config group is rendering, its actions list is initially hidden.
   * Even though it is hidden, the actions are being built and rendered. Some
   * actions use plugins that, in order to display properly, need to be
   * visible. They are still rendered, but need a nudge to ensure that when
   * the user focuses an action, that plugin is displaying properly.
   *
   * When the config group is focused, this calls a method on each of
   * its Actions to provide a chance to fix problem plugins.
   */
  fixActionFormPlugins: function() {
    var self = this;

    if (this.model.get('isFocused') === false) {
      return;
    }

    _.each(this.actionRegions, function(region) {
      self.getChildView(region).children.invoke('fixFormPlugins');
    });

    // We only need to do this once, so clear the event listener
    this.off(this.model, 'change:isFocused');
  },

  /**
   * Scrolls to the top of the configuration card.
   *
   * @param {Object} options
   * @property {Boolean} scroll
   *   If false, sets scroll duration to zero. This is done because smooth
   *   scrolling isn't always appropriate, like when a user cancels editing a
   *   card.
   */
  scrollView: function(options) {
    var scrollOpts = {};
    options = options || {};

    scrollOpts.offset = {top: -8}; // "8" is half of the margin between cards

    if (options.scroll === false) {
      scrollOpts.duration = 0;
    }

    this.triggerMethod('scroll:me', this.$el, scrollOpts);
  },

  /**
   * If the DNA is performing a task where we should not allow saving config
   * changes, then disable the Save and Remove buttons.
   *
   * @param {config/Group} model
   * @param {Boolean} isSaveable
   * @param {Object|undefined} options
   *   Expects {dnaUpdating: true}, {dnaConnected: false} or undefined.
   */
  onIsSaveableChange: function(model, isSaveable, options) {
    var btns = this.ui.btnsWrap.find('button').not('[name="cancel"]');
    var warningsView = this.getChildView('configWarnings');

    if (!isSaveable) {
      this._showMessage(options, 'isSaveable');
      btns.prop('disabled', true);
    } else {
      btns.removeAttr('disabled');
      warningsView.triggerMethod('remove:alert', 'isSaveable');
    }
  },

  /**
   * Provides additional message text for confirming removal of a group.
   * Subclasses can override to provide customized messaging.
   *
   * @returns {String}
   */
  getRemovalSubMessage: function() {
    return null;
  },

  /**
   * Provides additional functionality when removing a config card in that if doing a removal of the card
   * the subMessage will be colored red. This is useful for example when removing a wifi network. That will
   * cause a network disruption so that we want to able to notify a user in a way that the user knows if this
   * is a good idea or not.
   *
   * You can override this method in *View.js for actionGroups.
   *
   * @returns {boolean} bool
   *  whether the removalSubMessage text should be red or not (red to show error conditions)
   */
  getRemovalIsDangerous: function() {
    return false;
  },

  /**
   * Determines whether a group is able to be removed - default is true - to add more specific
   * functionality at the card level override this method in *View.js files in actionGroups.
   * @returns {boolean}
   */
  isRemovable: function() {
    return true;
  },

  /**
   * Determines whether removing the group should be presented as the
   * default option in the confirmation prompt. Subclasses can override
   * to change this depending on circumstances.
   *
   * @returns {Boolean}
   */
  getRemovalDefaultIsYes: function() {
    return true;
  },

  _showMessage: function(options, id) {
    options = options || {};
    var msgText;
    var warningsView = this.getChildView('configWarnings');

    if (this.model.isOperatorTool()) {
      if (options.dnaUpdating) {
        msgText = i18n.t('configEdit.notRunnable.updating');
      } else if (options.hasOwnProperty('dnaConnected') && !options.dnaConnected) {
        msgText = i18n.t('configEdit.notRunnable.offline');
      }
    } else if (options.dnaUpdating) {
      msgText = i18n.t('configEdit.notSaveable.updating');
    } else if (options.hasOwnProperty('dnaConnected') && !options.dnaConnected) {
      msgText = i18n.t('configEdit.notSaveable.offline');
    }

    if (msgText) {
      warningsView.triggerMethod('add:alert', id, msgText);
    }
  },
});
