'use strict';

/**
 * Takes an IP address and calculates its network address, broadcast address
 * and range of host addresses.
 *
 * @class
 * @alias lib/Ip
 *
 * @todo To simplify this object's API, the cidr() method should really be
 * merged into netmask(). That way, there is only a single method for setting
 * a mask.
 *
 * @example
 * var ip = new Ip('10.10.0.1');
 * ip.cidr(24);  // set size to calculate network, broadcast, etc.
 * ip.network(); // gets the network address (same as ip.string().network())
 * ip.integer().network(); // gets the network address as an integer value
 *
 * @example
 * //same as above, except this example uses an IP mask
 * var ip = new Ip('10.10.0.1');
 * ip.netmask('255.255.255.0');
 *
 * @example
 * var ip2 = new Ip(3232235520, {type: 'ipv4'}); // force parsing as IPv4
 *
 * @example
 * var ip3 = new Ip('10.10.0.1/24'); // immediately calculates network, etc.
 *
 * @throws Error
 *   Thrown if address family of the passed IP cannot be determined.
 * @throws Error
 *   Thrown if IPv4 IP address can not be parsed (i.e converted to number).
 * @param {String|Number} ip
 * @param {Object} options
 * @property {String} options.type
 *   Specify "ipv4" or "ipv6". This is useful when passing in a non-formatted
 *   IP address.
 * @property {String} options.output
 *   Specify "string" or "integer" to indicate how retrieval methods, like network(),
 *   format their return value. The default is a string (e.g. 192.168.1.1).
 */
function Ip(ip, options) {
  var parts;
  var size;

  options = options || {};
  this._ip = {};

  if (options.type && ['ipv4', 'ipv6'].indexOf(options.type) !== -1) {
    this.type = options.type;
  }

  if (options.output && ['string', 'integer'].indexOf(options.output) !== -1) {
    this.output = options.output;
  } else {
    this.output = 'string';
  }

  if (typeof ip == 'string' && ip.indexOf('/') !== -1) {
    parts = ip.split('/');
    ip = parts[0];
    size = parseInt(parts[1]);

    if (isNaN(size) ||
      (options.type === 'ipv4' && size > 32) ||
      (options.type === 'ipv6' && size > 128)
    ) {
      throw new Error('Unable to determine netmask.');
    }
  }

  if (typeof this.type == 'undefined') {
    // try to determine address family

    if (typeof ip == 'string' && ip.indexOf('.') !== -1) {
      this.type = 'ipv4';
    } else {
      throw new Error(
        'Unable to determine address family. ' +
        'Please specify via the "options" attribute.'
      );
    }
  }

  // finalize IP and mask
  if (this.type === 'ipv4') {
    this._ip.ip = Ip.ip2long(ip);

    if (this._ip.ip === false) {
      throw new Error('Unable to parse passed IPv4 IP address.');
    }

    if (typeof size != 'undefined') {
      this.cidr(size);
    }
  }
}

/**
 * Converts an IPv4 address (in integer format) into a string in the
 * quad-dotted decimal format.
 *
 * @static
 * @param {Number} ip
 * @return {String|Boolean}
 *   Returns false if IP is not valid.
 */
Ip.long2ip = function(ip) {
  if (typeof ip == 'string') {
    return ip;
  }
  if (!isFinite(ip) || ip < 1 || ip > 4294967295) {
    return false;
  }
  return [
    ip >>> 24,
    ip >>> 16 & 0xFF,
    ip >>> 8 & 0xFF,
    ip & 0xFF].join('.');
};

/**
 * Converts IPv4 address (in quad-dotted decimal format) into an integer.
 *
 * @static
 * @param {String} ip
 * @return {Number|String|Boolean}
 *   If the passed IP is already a string, it is returned.
 *   If the passed IP is invalid, false is returned.
 */
Ip.ip2long = function(ip) {
  var octets;
  var longIp;
  if (typeof ip !== 'string' && ip >= 0) {
    return ip;
  }
  if (typeof ip !== 'string') {
    return false;
  }
  octets = ip.split('.');
  if (octets.filter(Boolean).length !== 4) {
    return false;
  }
  longIp = octets[0] *
    Math.pow(256, 3) + octets[1] *
    Math.pow(256, 2) + octets[2] *
    Math.pow(256, 1) + octets[3] *
    Math.pow(256, 0);
  return longIp;
};

/**
 * CIDR network size to integer netmask (not dotted notation) address.
 *
 * @static
 * @param {Number} size
 * @return {Number|Boolean}
 *   Returns false if size is invalid (must be from 1 to 32).
 */
Ip.cidrToNetmask = function(size) {
  if (!isFinite(size) || size < 1 || size > 32) {
    return false;
  }
  return 0xFFFFFFFF << (32 - parseInt(size)) >>> 0;
};

/**
 * CIDR size to dotted notation address.
 *
 * @param {Number} cidr
 * @return {String|Boolean}
 *   Returns false if cidr is invalid.
 */
Ip.cidrToIp = function(cidr) {
  var mask = Ip.cidrToNetmask(cidr);
  return Ip.long2ip(mask);
};

/**
 * Netmask to CIDR network size.
 *
 * @static
 * @param {Number} netmask
 * @return {Number|Boolean}
 *   Returns false if netmask is invalid.
 */
Ip.netmaskToCidr = function(netmask) {
  var cidr;

  if (typeof netmask == 'string') {
    netmask = Ip.ip2long(netmask);
  }

  cidr = Math.round(32 - Math.log(~netmask + 1) / Math.log(2));

  if (typeof cidr !== 'number' || !isFinite(cidr) || isNaN(cidr)) {
    return false;
  }

  return cidr;
};

Ip.prototype = {
  /**
   * Forces the next retrieval method (e.g. network()) to return its value as
   * an integer.
   *
   * @return {lib/Ip}
   */
  integer: function() {
    this.formatOverride = 'integer';
    return this;
  },

  /**
   * Forces the next retrieval method (e.g. network()) to return its value as
   * a string (e.g. 192.168.1.1).
   *
   * @return {lib/Ip}
   */
  string: function() {
    this.formatOverride = 'string';
    return this;
  },

  /**
   * Formats the passed value as an integer or string (e.g. 192.168.1.1).
   * @private
   * @param {String} value
   *   The ip to format.
   * @return {String|Number}
   */
  _format: function(value) {
    var format = this.output;

    if (typeof this.formatOverride != 'undefined') {
      format = this.formatOverride;
      delete this.formatOverride;
    }

    if (format === 'string') {
      return Ip.long2ip(value);
    }

    return value;
  },

  /**
   * Formats the passed array of values as integers or strings.
   *
   * @private
   * @see lib/Ip#_format
   * @param {Array} value
   * @return {Array}
   */
  _formatArray: function(value) {
    var self = this;
    var formatOverride;
    var formatted = [];

    if (typeof this.formatOverride != 'undefined') {
      formatOverride = this.formatOverride;
    }

    value.forEach(function(val, index, array) {
      self.formatOverride = formatOverride;
      formatted.push(self._format(val));
    });

    return formatted;
  },

  /*
   * ipv4 functions ----------------------------------------------------------
   */

  /**
   * Gets the network address.
   * @return {Number|String}
   */
  network: function() {
    return this._format(this._ip.network);
  },

  /**
   * Gets the broadcast address.
   * @return {Number|String}
   */
  broadcast: function() {
    return this._format(this._ip.broadcast);
  },

  /**
   * Gets/sets the netmask.
   *
   * @example
   *   var ip = new Ip('10.10.0.0');
   *   ip.netmask('255.255.255.0'); // sets the netmask
   *   ip.netmask(); // returns the netmask
   *
   * @param {String|Number} netmask
   *   The netmask to use to calculate the network and broadcast addresses.
   * @return {Number|String|lib/Ip}
   */
  netmask: function(netmask) {
    var cidr;

    if (arguments.length === 0) {
      return this._format(this._ip.netmask);
    }

    if (netmask >= 8 && netmask <= 128) {
      // cidr size
      return this.cidr(netmask);
    }

    cidr = Ip.netmaskToCidr(netmask);
    this.cidr(cidr);
    return this;
  },

  /**
   * Gets/sets the netmask by CIDR size.
   *
   * @example
   *   var ip = new Ip('10.10.0.0');
   *   ip.cidr(24); // sets the netmask
   *   ip.cidr(); // returns the CIDR value
   *
   * @param {Number} size
   *   The CIDR value.
   * @return {Number|lib/Ip}
   */
  cidr: function(size) {
    if (arguments.length === 0) {
      if (typeof this.formatOverride != 'undefined') {
        delete this.formatOverride;
      }

      return this._ip.cidr;
    }

    this._ip.netmask = Ip.cidrToNetmask(size);
    this._ip.cidr = size;
    this._parseIpv4(this._ip.ip, size);
    return this;
  },

  /**
   * Gets the hosts range.
   *
   * @example
   *   var ip = new Ip('10.10.0.0/24');
   *   ip.hosts(); // returns [10.10.0.1, 10.10.0.254]
   *
   * @return {Array}
   */
  hosts: function() {
    return this._formatArray(this._ip.hosts);
  },

  /**
   * Gets the number of hosts in the hosts range.
   *
   * @return {Number}
   */
  numHosts: function() {
    // TODO initialIp ip address is included, should it not be?
    return this._ip.hosts[1] - this._ip.hosts[0];
  },

  /**
   * Gets the IP passed into this object when it was instantiated.
   *
   * @return {String|Number}
   */
  initialIp: function() {
    return this._format(this._ip.ip);
  },

  /**
   * Checks that the passed IP is within the current subnet.
   *
   * @param {String|Number} ip
   * @return {Boolean}
   */
  inSubnet: function(ip) {
    ip = Ip.ip2long(ip);

    // /31 subnets are a special case -- they contain only 2 IPs
    // (one for this host and one for some remote host e.g. gateway)
    // So reserving a network and broadcast IP doesn't really make sense
    if (this.cidr() === 31) {
      return (ip >= this._ip.network && ip <= this._ip.broadcast);
    }

    return (ip > this._ip.network && ip < this._ip.broadcast);
  },

  /**
   * Calculates the network, broadcast and first/last usable host IPs of an
   * IPv4 address space.
   *
   * @private
   * @param {String|Number} ip
   * @param {Number} size
   */
  _parseIpv4: function(ip, size) {
    var binaryMask;
    var binaryIp;

    binaryMask = Ip.cidrToNetmask(size).toString(2);
    binaryIp = ip.toString(2);

    // calculate network address
    this._ip.network =
      (parseInt(binaryIp, 2) & parseInt(binaryMask, 2)) >>> 0;

    // calculate broadcast
    this._ip.broadcast =
      (parseInt(binaryIp, 2) | ~parseInt(binaryMask, 2)) >>> 0;

    this._ip.hosts = [
      // add 1 to get the first available host address
      this._ip.network + 1,
      // subtract 1 from broadcast for last available host
      this._ip.broadcast - 1,
    ];
  },
};

module.exports = Ip;
