'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');

/**
 * Edit model for Wifi Description
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/description/wifi/EditDescription#attributes
   * @property {String} id
   *   The ID of the associated wifi network (e.g. bss0.1).
   * @property {string} description
   *   Network description
   */

  _snapshotAttributes: ['description'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return _.isUndefined(this.deviceConfig.get('networks').get(this.id));
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig && !this.isNew()) {
      return {description: this.getDescriptionFromConfig(this.id)};
    }

    return resp;
  },

  getDescriptionFromConfig: function(id) {
    var config = this.deviceConfig;
    var networks = config.get('networks');
    var network = networks.get(id);

    return network.get('description');
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks').get(this.id);
      },
      events: 'change:description',
    },
  ],

  /**
   * @return {Object}
   */
  getTask: function() {
    var data = {description: this.get('description')};

    if (!this.isNew()) {
      data.id = this.id;
    }

    return {
      name: 'wifi.create',
      data: data,
    };
  },

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};
    var validateAll = (options && options.validateAll !== false);

    if (!_.isUndefined(attrs.description) || validateAll) {
      if (_.isEmpty(attrs.description)) {
        errors.description = i18n.t('wifiDescription.badDescription');
      }

      if (!errors.description && !this._validDescription(attrs.description)) {
        errors.description = i18n.t('wifiDescription.duplicateDescription');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Verify that the entered description is not the same as other WiFi.
   *
   * @private
   * @param {String} description
   * @returns {Boolean}
   */
  _validDescription: function(description) {
    var self = this;
    // No duplicate descriptions
    var duplicates = this.deviceConfig.get('networks').filter(function(network) {
      return (network.get('description').toLowerCase().trim() === description.toLowerCase().trim() &&
      network.id !== self.id && network.id);
    });

    return _.isEmpty(duplicates);
  },

});
