'use strict';

var i18n = require('i18next');
var EditNicBond = require('actionGroups/editNicBond/EditNicBond');
var configPage = require('lib/configPage');

var staticProps = {
  isCreational: true,
  creationalPage: configPage.SYSTEM,
};

module.exports = EditNicBond.extend({
  defaults: {
    isDirty: true,
  },

  isConnectivityDisrupted: function(type, target) {
    return false;
  },

  /**
   * @returns {boolean}
   */
  isNew: function() {
    return true;
  },

  /**
   * Request a callback when the capabilities of the router changes
   *
   * @param {Backbone.Events} listener
   * @param {Function} callback
   */
  onAllowNewChanged: function(listener, callback) {
    var routerStatus = this.deviceStatus.get('router');
    if (routerStatus) {
      listener.listenTo(routerStatus, 'change:capabilities', callback.bind(listener));
    }
  },

  /**
   * @returns {boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @returns {*}
   */
  title: function() {
    return i18n.t('groups.addNicBond');
  },

  /**
   * Indicates if the user can create a new NIC Bond.
   *
   * @param {config/ConfigModel} configOutline
   * @param {lib/models/DeviceStatus} deviceStatus
   * @return {Boolean}
   */
  allowNew: function(configOutline, deviceStatus) {
    return deviceStatus.hasCapability(EditNicBond.capabilityRequired);
  },

  /**
   * Provides a user message indicating why a new bond
   * cannot be created.
   *
   * @return {String}
   */
  disallowedNewReason: function() {
    return i18n.t('configEdit.capabilityMissing', {feature: i18n.t('groups.editNicBond')});
  },
}, staticProps);
