'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var networkUtils = require('lib/network');

/**
 * Collection of common functions used to help format network data to be
 * displayed to the user
 */
module.exports = {
  /**
   * Helper to return the VLAN's address method (static, dhcp, etc).
   *
   * @param {string} method
   * @param {String} af
   * @return {String}
   */
  getMethodText: function(method, af) {
    if (method === 'static') {
      return i18n.t('actionStaticAddress.methodStatic', {
        af: (af === 'ipv4' ? 'IPv4' : 'IPv6'),
      });
    } else if (method === 'dhcp') {
      return i18n.t('actionStaticAddress.methodDhcp');
    } else if (method === 'stateless') {
      return i18n.t('actionStaticAddress.methodStateless');
    }
    return '';
  },

  /**
   * Checks if the passed description is empty and returns the vlan name if
   * it is, else the description is returned
   *
   * @param {String} vlanName
   * @param {String} desc
   * @return {String}
   */
  formatFullName: function(vlanName, desc) {
    if (_.isEmpty(desc)) {
      return vlanName;
    }
    return desc;
  },

  /**
   * Formats the WAN name like "WAN Port #" or "<description> (WAN Port #)".
   *
   * @param {String} port
   *   "eth0", etc.
   * @param {String} description
   * @return {String}
   */
  formatWanFullName: function(port, description) {
    var portName = this.formatWanName(port);

    if (_.isEmpty(description)) {
      return portName;
    }

    return i18n.t('vlanDetails.wanFullName', {
      description: description,
      port: portName,
    });
  },

  /**
   * Gets and formats a VLAN's netmask.
   *
   * @param {String} af
   *   The address family
   * @param {Number} cidr
   * @return {String}
   */
  formatNetmask: function(af, cidr) {
    var mask;

    if (_.isUndefined(cidr)) {
      return '';
    }

    mask = '/' + cidr;

    if (af === 'ipv4') {
      mask += ' (' + networkUtils.Ip.cidrToIp(cidr) + ')';
    }

    return mask;
  },

  /**
   * A WAN has a standard name: "WAN Port #".
   *
   * @param {String} port
   *   "eth0", etc.
   * @return {String}
   */
  formatWanName: function(port) {
    port = parseInt(port.substring(3)) + 1;
    return i18n.t('vlanDetails.wanPort', {portNum: port});
  },
};
