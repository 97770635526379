'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/staticAddress/static.html');
var twig = require('twig').twig;
var networkHelpers = require('lib/tplHelpers/networkHelpers');

/**
 * Renders a text-only version of the "VLAN subnet" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/staticAddress/StaticView#model
   * @type {actions/staticAddress/AbstractEditAddress}
   */

  template: twig({data: tpl}),

  /**
   * VLAN static view template helpers
   * @return {Object}
   */
  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);
    return _.extend({}, baseHelpers, {
      addressMethod: this.formatAddressMethod,
      netmask: this.formatNetmask,
    });
  },

  /**
   * Template helper to format the "address method".
   *
   * @memberof! actions/staticAddress/StaticView#
   * @method templateContext.formatAddressMethod
   * @return {String}
   */
  formatAddressMethod: function() {
    var format = networkHelpers.getMethodText; // save some typing
    var attrs = ['method', 'af'];

    return format.apply(this, _.toArray(_.pick(this, attrs)));
  },

  /**
   * Template helper to return an external VLAN's address mask as CIDR.
   *
   * @memberof! actions/staticAddress/StaticView#
   * @method templateContext.formatNetmask
   * @return {String}
   */
  formatNetmask: function() {
    return networkHelpers.formatNetmask(this.af, this.size);
  },
});
