'use strict';

var Marionette = require('backbone.marionette');
var Backbone = require('backbone');
var tpl = require('manage/configRestore/form.html');
var twig = require('twig').twig;
var ConfigurationView = require('manage/configRestore/ConfigurationView');
var Pagination = require('lib/behaviors/pagination/Pagination');
var Radio = require('backbone.radio');
var apiChannel = Radio.channel('apiChannel');

var ConfigurationsCollectionView = Marionette.CollectionView.extend({
  childView: ConfigurationView,

  onChildviewRemoveConfig: function(child) {
    this.collection.forEach(function(model) {
      if (child.model.get('configId') === model.get('configId')) {
        model.set('remove', child.model.get('remove'));
      }
    });
    this.trigger('toggle:button', this);
  },

  onChildviewStartRestore: function(child) {
    this.collection.forEach(function(model) {
      if (child.model.get('configId') !== model.get('configId')) {
        model.set('disabled', true);
      }
    });
  },

  onChildviewEndRestore: function(child) {
    this.collection.forEach(function(model) {
      model.unset('disabled');
    });
  },
});

module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  behaviors: [{
    behaviorClass: Pagination,
    shift: 1,
    items: function() {
      return this.configurations;
    },
  }],

  templateContext: function() {
    return {
      hasConfigs: this.configurations.length,
    };
  },

  className: 'config-list',

  regions: {
    'configurations': '.rg-configurations',
    'currentConfig': '.rg-current-config',
    'pages': '.rg-pages',
  },

  ui: {
    'delete': '.delete-configs',
  },

  events: {
    'click @ui.delete': 'deleteConfigs',
  },

  initialize: function(options) {
    this.deviceMac = options.deviceMac;
    this.configurations = options.configurations || new Backbone.Collection();

    this.listenTo(this.configurations, 'update', this.render);
  },

  onRender: function() {
    if (this.configurations.length === 0) {
      return;
    }

    var currentConfig = new ConfigurationView({
      model: this.configurations.at(0),
      canRestore: false,
      canRemove: false,
    });

    this.collectionView = new ConfigurationsCollectionView({
      deviceMac: this.deviceMac,
      collection: this.configurations,
      filter: this.pageFilter || null,
    });

    this.showChildView('configurations', this.collectionView);
    this.showChildView('currentConfig', currentConfig);
  },

  onChildviewToggleButton: function() {
    if (this.getHiddenConfigs().length) {
      this.ui.delete.removeClass('hidden');
    } else {
      this.ui.delete.addClass('hidden');
    }
  },

  /**
   * Sets the filter on a CollectionView to render the appropriate items
   * The filter method is determined by the Pagination behavior through a combination of provided
   * and defaulted options
   *
   * @param {function} filter
   */
  updateItemsFilter: function(filter) {
    if (this.collectionView) {
      this.collectionView.setFilter(filter);
    }
    this.pageFilter = filter;
  },

  deleteConfigs: function() {
    var hiddenConfigs = this.getHiddenConfigs();
    var configIds = hiddenConfigs.map(function(model) {
      return model.get('configId');
    });
    hiddenConfigs.forEach(function(model) {
      model.collection.remove(model);
    });
    return apiChannel.request('send', 'DNA.Portal.ConfigRestore.removeConfigurations', {
      configIds: configIds,
    });
  },

  getHiddenConfigs: function() {
    var hiddenConfigs = [];
    this.collectionView.collection.forEach(function(model) {
      if (model.get('remove')) {
        hiddenConfigs.push(model);
      }
    });
    return hiddenConfigs;
  },
});
