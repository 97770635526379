'use strict';

var Marionette = require('backbone.marionette');

/**
 * Simple show/hide password Behavior.
 *
 * Expects the following:
 * * The password field has the class "pw-field"
 * * The toggle element is a checkbox and has the class "pw-field-toggle"
 *
 * Note, this Behavior was written to only work on a View with a single
 * password field. Multiple password fields (i.e. password/confirm password
 * combo) is untested.
 */
module.exports = Marionette.Behavior.extend({
  defaults: {
    showPasswordOnLoad: true,
  },

  ui: {
    pwField: '.pw-field',
    pwFieldToggle: '.pw-field-toggle',
  },

  events: {
    'click @ui.pwFieldToggle': 'togglePassword',
  },

  onRender: function() {
    if (this.options.showPasswordOnLoad) {
      this.ui.pwField.prop('type', 'text');
      this.ui.pwFieldToggle.prop('checked', true);
    }
  },

  /**
   * Toggle the password and text fields.
   */
  togglePassword: function() {
    var type = (this.ui.pwField.prop('type') === 'password') ? 'text' : 'password';
    this.ui.pwField.prop('type', type);
  },
});
