'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var twig = require('twig').twig;
var i18n = require('i18next');
var tpl = require('actions/wifi/static.html');
var EditWifi = require('actions/wifi/EditWifi');

/**
 * Renders a text-only version of the "Create/Manage WiFi" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/wifi/StaticView#model
   * @type {actions/wifi/EditWifi}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      parentVlanName: _.partial(this.tplFormatParentVlan, this.model.deviceConfig),
      encryptionTypeName: _.partial(this.tplFormatEncryptionType, EditWifi.encryptionTypes),
      formatPassphrase: this.tplFormatEncryptionKey,
    });
  },

  /**
   * Gets the diff for the parentVlan attribute.
   *
   * @method templateContext.parentVlanName
   * @param {lib/models/DeviceConfig} deviceConfig
   * @return {String}
   */
  tplFormatParentVlan: function(deviceConfig) {
    if (_.isUndefined(this.parentVlan)) {
      return this.blankVal();
    }

    return deviceConfig.get('networks').get(this.parentVlan).getName();
  },

  /**
   * Gets the diff for the encryption type attribute.
   *
   * @method templateContext.encryptionTypeName
   * @param {Array} encryptionTypes
   * @return {String}
   */
  tplFormatEncryptionType: function(encryptionTypes) {
    var type;

    if (_.isEmpty(this.encryptionType)) {
      return this.blankVal();
    }

    type = _.findWhere(encryptionTypes, {value: this.encryptionType});
    return type.label;
  },

  /**
   * Gets the state of the passphrase attribute. Note, its actual value will
   * be hidden.
   *
   * @method templateContext.formatPassphrase
   * @return {String}
   */
  tplFormatEncryptionKey: function() {
    if (_.isEmpty(this.encryptionKey)) {
      return this.blankVal();
    }

    return i18n.t('configEdit.valHidden');
  },
});
