'use strict';

var BaseGroup = require('config/Group');
var i18n = require('i18next');
var RenderPositions = require('config/RenderPositions');

/**
 * "Manage Router Settings" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'dnaDescription',
      position: RenderPositions.header,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'userTimezone',
      position: RenderPositions.first,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'ntp',
      position: RenderPositions.last,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: true,
    },
    {
      name: 'hostname',
      position: RenderPositions.first,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'updateSchedule',
      position: RenderPositions.full,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'siteToSiteRekeyTime',
      position: RenderPositions.full,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  isConnectivityDisrupted: function(type, target) {
    if (type === 'input') {
      return (target.name === 'hostname');
    }

    return false;
  },

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.router');
  },

  /**
   * @return {String}
   */
  description: function() {
    return this.deviceConfig.get('router').get('description');
  },

  /**
   * Request a callback when the description of this group changes
   *
   * @param {Backbone.Events} listener
   * @param {Function} callback
   */
  onDescriptionChanged: function(listener, callback) {
    listener.listenTo(this.deviceConfig.get('router'), 'change:description', callback.bind(listener));
  },
});
