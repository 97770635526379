'use strict';

var _ = require('lodash');
var $ = require('jquery');
var i18n = require('i18next');
var Backbone = require('backbone');
var Radio = require('backbone.radio');
var ActionsList = require('config/Actions');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');
var apiChannel = Radio.channel('apiChannel');
var ConfigMessageTypes = require('manage/edit/ConfigMessageTypes');
var console2 = require('lib/Console');
var LogMessage = require('lib/models/LogMessage');
var timeouts = require('lib/saveTimeouts');

/**
 * A "config group" is the representation of an entity (e.g. Primary LAN, Router settings)
 * and its active configuration.
 */
module.exports = Backbone.Model.extend({
  /**
   * @member {Object} #attributes
   * @property {String} deviceMac
   * @property {String} type
   *   The config group type (See {@link lib/actions})
   * @property {String} typeId
   *   (optional) The config group's Id (e.g. vlan1, bss0.1, etc.). Global entities,
   *   like "Router settings" will not have an Id.
   * @property {Array} actions
   *   List of {@link config/Actions}.
   * @property {Boolean} isFocused
   *   UI property indicating whether or not the group's actions are displayed
   *   as a form or text-only.
   * @property {Array} jobResults
   *   Result of the last job that was applied
   * @property {Boolean} isApplying
   *   Indicates that the Group is applying changes.
   * @property {Boolean} isSaveable
   *   Indicates if the group's changes can be saved. A group may be prevented
   *   from saving if, for example, the DNA is not accessible.
   */

  /**
   * @member {lib/models/DeviceConfiguration} #deviceConfig
   *   The "pending" configuration shared by all Group objects within the
   *   Workbench. The pending config may be changed by actions as the user
   *   interacts with them.
   * @member {String} #deleteTaskName
   *   The "task" name used when the group is being removed (e.g. "vlan.delete").
   */

  /**
   * @param {Object} attributes
   * @param {Object} options
   */
  constructor: function(attributes, options) {
    options = options || {};

    if (_.isUndefined(options.actionTypes)) {
      throw new Error('Group constructor requires actionTypes');
    }

    _.bindAll(this, '_validateActions', '_applyActions', 'onQueueProcessed', '_getActionAttrs');

    // deviceConfig has to be set in the constructor to ensure it is available
    // to the parse() method.
    this.deviceConfig = options.deviceConfig || deviceConfigChannel.request('get:config');
    this.actionTypes = options.actionTypes;
    this.deviceStatus = options.deviceStatus || deviceConfigChannel.request('get:status');

    Backbone.Model.call(this, attributes, _.omit(options, ['deviceConfig', 'actionTypes']));

    this.on('change:isFocused', this.onFocusChange);
  },

  /**
   * List of objects defining actions recognized by the group, with properties:
   *
   * name {String} action name per configTypes.js
   * position {String} enum from RenderPositions indicating where this action should appear in the group
   * order {Number} optional; order relative to other items in the same position, to be used when adding locally
   *   in all other cases, order is controlled by the config outline from the server
   *   items with an order will appear before items without an order, if there are both in the same position
   * required {Boolean} whether the action must always be present or can be added/removed
   * managed {Boolean} optional; if true, manual user add/remove controls are hidden for optional action
   * renderTitle {Boolean} whether the action's title should be displayed
   * renderStatic {Boolean} whether the action should appear in the static (text only) view
   * doubleWide {Boolean} whether the action should span 2 columns
   * requireCapabilities {Array<String>} optional; device capabilities required for optional action to be offered
   *   if the config outline from the server says the action is already present,
   *   it will appear in the group regardless of this value
   */
  actionList: [],

  /**
   * Optional list of "managers" that should be initialized with this group
   *
   * Action Managers extend from AbstractActionManager and implement custom
   * action "presence" rules like automatically adding or removing actions
   * based on changes to other actions. (Note that simply sharing data between
   * actions does not require a manager; it should be handled by declaring
   * "dependsOn" relationships in an action's config.js)
   */
  actionManagers: [],

  /**
   * Indicates whether this group should attempt to merge tasks of the
   * same name that its actions produce prior to sending them. If set
   * to true, all tasks having equal names will be merged; if set to an
   * array of task names, tasks with equal names will be merged only if
   * their names appear in the list; if false, no tasks will be merged.
   */
  shouldMergeTasks: true,

  /**
   * Names of tasks that should be omitted if none of the actions
   * that produce tasks with that name had changes (according to
   * config/Action#actionHasChanges). Use this for actions that need
   * to atomically produce a combined task, but which coexist with
   * other independent actions in a group and may be un-touched for
   * a given Save operation.
   *
   * @see #shouldMergeTasks
   */
  skipTasksWithNoChange: [],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  applyLabel: function() {
    return i18n.t('configEdit.save');
  },

  /**
   * Used to overwrite title in ConfigClone (Fleet management)
   * @return {String}
   */
  titleOverwrite: function() {
    return '';
  },

  /**
   * @return {Boolean}
   */
  isOperatorTool: function() {
    return false;
  },

  /**
   * Provides a way to add additional timeout time to a configuration card that we know is
   * going to take a while to finish processing. The default is 25 seconds, but some config cards like
   * Web Filters TitanHQ requires 1 minute.
   *
   * @returns {Integer}
   */
  getConfigCardTimeout: function() {
    return timeouts.MAX_TIME;
  },

  /**
   * Save any changes made to the configuration group.
   *
   * @param {Object} options
   *   force {Boolean} - if true, perform the save even when no changes have been made
   * @return {jQuery.Promise}
   */
  save: function(options) {
    var self = this;
    var opts = options || {};

    return this._validateActions(opts)
      .then(this._applyActions)
      .done(function() {
        // Only listen when necessary
        Radio.once('socket', 'queue-processed', self.onQueueProcessed);
      });
  },

  /**
   * Deletes the entity associated with the configuration group.
   *
   * @return {jQuery.Promise}
   */
  delete: function() {
    var self = this;

    if (this.isRequired() === true) {
      return ($.Deferred().reject({type: ConfigMessageTypes.TASKS_ERROR})).promise();
    }

    return this._applyActions()
      .done(function() {
        // Only listen when necessary
        Radio.once('socket', 'queue-processed', self.onQueueProcessed);
      });
  },

  /**
   * @param {Object} options
   * @return {Object}
   */
  toJSON: function(options) {
    var data = Backbone.Model.prototype.toJSON.apply(this, arguments);
    data.actions = data.actions.toJSON(options);

    if (options && options.caching === true) {
      data = _.pick(data, 'actions', 'deviceMac', 'type');
    }

    return data;
  },

  /**
   * @param {Object} resp
   *   Expects `{deviceMac, type, typeId, actions[]}`
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    resp = _.cloneDeep(resp);

    if (options && options.create === true) {
      var actions = this.actionList
        .filter(function(action) {
          return action.required;
        }).map(function(actn) {
          return actn.name;
        }).map(_.partial(this._getActionAttrs, _, resp));
      resp.actions = actions;
    } else if (resp.mac) {
      // restoring groups from server response

      resp.deviceMac = resp.mac;
      delete resp.mac;

      resp.actions = _.map(resp.actions, _.partial(this._getActionAttrs, _, resp));
    }

    if (resp.actions) {
      var collection;
      if (this.has('actions')) {
        collection = this.get('actions');
        collection.set(resp.actions);
        delete resp.actions;
      } else {
        collection = resp.actions = new ActionsList(resp.actions);
        this._attachActionManagers(collection);
      }

      // populate any models that were just added
      this._populateActionModels(
        collection.where({isLoading: true}),
        collection.where({isLoading: false})
      );
    }

    return resp;
  },

  /**
   * Responds to a "queue-processed" event source message.
   *
   * @listens socket.on~queue-processed
   * @param {Object} results
   */
  onQueueProcessed: function(results) {
    this.set('jobResults', results);
  },

  /**
   * @listens config/Group~change:isFocused
   * @param {config/Group} model
   * @param {Boolean} isFocused
   * @param {Object} options
   */
  onFocusChange: function(model, isFocused, options) {
    var actions;

    if (isFocused === false && options && options.undoChanges === true) {
      actions = this.get('actions');

      // if group is losing focus, roll back any changes the user made to the action models
      actions.invoke('undoActionChanges');

      // remove any pending actions
      actions.remove(actions.filter({isPending: true}));
    }
  },

  /**
   * Adds the passed action to the configuration group.
   *
   * @param {String} actionId
   */
  addAction: function(actionId) {
    var options = {};
    var actionsList = this.get('actions');
    var existingActions = actionsList.slice();
    var action = actionsList.findWhere({id: actionId});
    var details;
    if (!_.isUndefined(action)) {
      // in case the action is present but pending delete, reverse that
      action.get('actionModel').set('pendingDelete', false);
      return;
    }

    this.trigger('action:add', actionId);

    details = this._getActionAttrs(actionId, this.pick('deviceMac', 'typeId'));
    details.isPending = true;

    if (!_.isUndefined(details.renderOrder)) {
      options.at = this._getActionAddIndex(details, actionsList);
    }

    this.set('isDirty', true);
    action = actionsList.add(details, options);
    this._populateActionModels([action], existingActions);
  },

  /**
   * Determine if this card is misconfigured, and should be flagged.
   *
   * @return {Boolean}
   */
  isMisconfigured: function() {
    return false;
  },

  /**
   * This method adds functionality to config cards at the group layer by showing a network disruption error for
   * config changes that will impact network connectivity.
   *
   * This method is intended to be overridden.
   *
   * @param {String} type
   *  this is where the event originated from. For example: an input field,
   *  from removing an action or adding an action.
   *
   *  Possible values for:
   *    type: input
   *          addAction
   *          removeAction
   *
   *    target:
   *          when type === 'input': the modified HTML input element
   *          when type === 'removeAction': the model of the action
   *          when type === 'addAction': the id of the action
   *
   * @param {*} target
   *  This is the actual component that caused the event to be triggered. Think: input field, the add button, and the
   *  remove button. The target itself can have different types of attributes associated with it so thats
   *  why we have to specify a type because an input field will have different attributes than a button that triggers
   *  a action:add event.
   *
   * @returns {boolean} bool
   *  whether we should should show the general connectivity disruption error or not.
   */
  isConnectivityDisrupted: function(type, target) {
    return false;
  },

  /**
   * Expand action details.
   *
   * @private
   * @see config/Group::parse
   * @param {String} actionKey
   * @param {Object} options
   *   Looks for deviceMac, requiredActions[], (optional) typeId and (optional) hideActionTitles[].
   * @return {Object}
   */
  _getActionAttrs: function(actionKey, options) {
    var actionConfig;
    var attrs;
    var extraConfig = {};
    var err;

    var renderAction = _.find(this.actionList, function(actn) {
      return actn.name === actionKey;
    });

    if (!renderAction) {
      // this group isn't set up to accept this action
      err = 'Config action type "' + actionKey +
        '" is not valid for group type "' + options.type + '". ' +
        'Check the actionList for this group.';
      return this._getInvalidActionAttrs(err);
    }

    extraConfig.showTitle = renderAction.renderTitle;
    extraConfig.showInStaticView = renderAction.renderStatic;
    extraConfig.hideAction = (renderAction.hidden === true);

    actionConfig = this.actionTypes[actionKey];

    if (!actionConfig) {
      // this group knows about this action type, but the master actions registry does not
      err = 'Unknown config action type "' + actionKey + '". Check configTypes.js.';
      return this._getInvalidActionAttrs(err);
    }

    attrs = {
      id: actionKey,
      deviceMac: options.deviceMac,
      actionConfig: _.extend({}, actionConfig, extraConfig),
      isRequired: renderAction.required,
      isManaged: renderAction.managed,
      renderPosition: renderAction.position,
      renderOrder: renderAction.order,
      doubleWide: renderAction.doubleWide,
    };

    if (options.typeId) {
      attrs.actionModelCache = {id: options.typeId};
    }

    if (!attrs.renderPosition) {
      console2.log('warn', 'Unknown render position detected attrs: ', attrs);
    }

    return attrs;
  },

  /**
   * Calculate the index at which to add the action
   *
   * @param {Object} actionAttrs
   * @param {config/Actions} list
   * @return {Number}
   * @private
   */
  _getActionAddIndex: function(actionAttrs, list) {
    // find the item in front of which it should be inserted
    // (to simplify this, we just put it front of the first item
    // in the same render position that has a higher order value)
    var peers = list.where({renderPosition: actionAttrs.renderPosition});
    var nextItem = _.find(peers, function(peer) {
      var peerOrder = peer.get('renderOrder');
      if (!_.isUndefined(peerOrder)) {
        return peerOrder > actionAttrs.renderOrder;
      }
      // treat peers without a specified order as always wanting to be last
      return true;
    });

    if (nextItem) {
      return list.indexOf(nextItem);
    }
    return list.length;
  },

  /**
   * Create a placeholder attributes object that signifies
   * an invalid action type.
   *
   * @param {String} errorMessage
   * @return {Object}
   * @private
   */
  _getInvalidActionAttrs: function(errorMessage) {
    console2.log('error', errorMessage);
    (new LogMessage({
      file: 'config/Group.js',
      message: errorMessage,
    })).save();
    return {badActionType: true};
  },

  /**
   * Wire up dependencies for the pending actions (by drawing
   * from the provided list of completed actions, if any)
   * and load them. Guarantees that dependencies will be
   * loaded before the dependent action.
   *
   * @param {Array} pending
   * @param {Array} completed
   * @private
   */
  _populateActionModels: function(pending, completed) {
    if (pending.length === 0) {
      // recursion base case
      return;
    }

    var self = this;
    var completedKeys = _.pluck(completed, 'id');

    var stillPending = [];
    var newlyCompleted = [];

    pending.forEach(function(action) {
      var dependencies = action.get('actionConfig').dependsOn || [];

      if (_.difference(dependencies, completedKeys).length === 0) {
        self._wireActionDependencies(action, dependencies, completed);
        action.loadActionModel();
        newlyCompleted.push(action);
      } else {
        stillPending.push(action);
      }
    });

    if (newlyCompleted.length === 0) {
      var msg = 'Deadlock resolving action dependencies! ' +
        'Check for circular dependencies among ' + _.pluck(pending, 'id').join(', ');
      console2.log('error', msg);
      (new LogMessage({
        file: 'config/Group.js',
        message: msg,
      })).save();
      return;
    }

    // recursively populate each "layer" of dependencies
    this._populateActionModels(stillPending, completed.concat(newlyCompleted));
  },

  /**
   * Provide the given action with references to other actions it depends on
   *
   * @param {config/Action} action
   * @param {Array} dependencyNames
   * @param {Array} available
   *   list of all actions that are ready to be used as dependencies
   * @private
   */
  _wireActionDependencies: function(action, dependencyNames, available) {
    var dependencies = _.chain(available)
      .indexBy('id')
      .pick(dependencyNames)
      .mapValues(function(action) {
        return action.get('actionModel');
      })
      .value();

    action.setActionModelDependencies(dependencies);
  },

  /**
   * Initialize any action managers defined for this group
   *
   * @param {config/Actions} actionsList
   * @private
   */
  _attachActionManagers: function(actionsList) {
    var self = this;

    this.actionManagers.forEach(function(Manager) {
      var manager = new Manager(self, actionsList);

      // mostly for testing/diagnostic purposes - we don't keep a reference
      // here to the manager; if it doesn't attach any callbacks to its own
      // methods then it should be eligible for garbage collection
      self.trigger('actionManager:attach', self, manager);
    });
  },

  /**
   * Wrapper for {@link config/Actions.validateActions}
   * to resolve its return value as a promise.
   *
   * @private
   * @see {changeset/ChangesetModel.processQueue}
   * @param {Object} options
   * @property {Boolean} options.force
   *   Proceed with validation even if there are no changes
   * @return {jQuery.Deferred}
   */
  _validateActions: function(options) {
    var deferred = $.Deferred();
    var actions = this.get('actions');
    var hasChanges;
    var failedValidation = [];

    // Find all actions with changes
    hasChanges = actions.filter(function(action) {
      return action.actionHasChanges() || options.force;
    });

    if (hasChanges.length > 0) {
      failedValidation = actions.reduce(function(memo, action) {
        if (!action.actionIsValid()) {
          memo.push(action.get('actionModel').get('actionTitle'));
        }
        return memo;
      }, []);

      if (failedValidation.length > 0) {
        deferred.reject({type: ConfigMessageTypes.FAILED_VALIDATION});
      } else {
        deferred.resolve();
      }
    } else {
      deferred.reject({type: ConfigMessageTypes.NO_CHANGES});
    }

    return deferred.promise();
  },

  /**
   * Gets the list of tasks and fires off the API call to process the changes.
   *
   * @private
   * @return {jQuery.Deferred}
   */
  _applyActions: function() {
    var params = {
      mac: this.get('deviceMac'),
      tasks: [],
    };

    try {
      params.tasks = this._getTasks();
    } catch (e) {
      return ($.Deferred().reject({type: ConfigMessageTypes.TASKS_ERROR, error: e})).promise();
    }

    console2.log('info', 'Sending tasks', params);

    return apiChannel.request('send',
      'DNA.Portal.Changeset.Device.process', params
    ).done(function(resp) {
      // updates status, userId, processTime, lastUpdated
      // self.set(self.parse(resp));
    });
  },

  /**
   * Get the list of tasks indicating the changes to be made to the entity.
   *
   * @private
   * @return {Array}
   */
  _getTasks: function() {
    var tasks;

    // check for deletion
    if (this.get('pendingDelete') === true) {
      return [{
        name: this.deleteTaskName,
        data: {
          id: this.get('typeId'),
        },
      }];
    }

    // create/edit
    tasks = this.get('actions').getTasks(this.skipTasksWithNoChange);
    return this._mergeTasks(tasks, this.shouldMergeTasks);
  },

  /**
   * For tasks that share the same task name, merge them if permitted.
   *
   * @private
   * @param {Array} tasks
   * @param {Array|Boolean} mergeable
   * @return {Array}
   */
  _mergeTasks: function(tasks, mergeable) {
    var taskGroups;

    if (tasks.length === 1 || mergeable === false) {
      return tasks;
    }

    taskGroups = _.groupBy(tasks, 'name');

    if (tasks.length === taskGroups.length) {
      return tasks;
    }

    return _.chain(taskGroups)
      .map(function(group, name) {
        if (mergeable === true || _.contains(mergeable, name)) {
          // note, need the empty object to prevent mutating the first object in
          // the group array
          var toMerge = [{}].concat(group);
          return _.merge.apply(_, toMerge);
        }
        return group;
      })
      .flatten() // un-nest groups that weren't eligible for merging
      .value();
  },
});
