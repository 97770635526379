'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var networkUtils = require('lib/network');

/**
 * View-model representing DNS fields for external VLANs.
 */
module.exports = ActionItem.extend({
  /**
   * @name actions/wanDns/EditWanDns#attributes
   * @type {Object}
   * @property {String} id
   *   The interface name (e.g. vlan40)
   * @property {String} af
   *   The address family (ipv4 or ipv6). Primarily used for validation.
   * @property {String} ns1
   *   Primary nameserver IP (quad-dotted notation)
   * @property {String} ns2
   *   Secondary nameserver IP (quad-dotted notation)
   */

  defaults: {
    ns1: '',
    ns2: '',
    af: 'ipv4',
    pendingDelete: false,
  },

  _snapshotAttributes: ['ns1', 'ns2', 'pendingDelete'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    var vlan = this.deviceConfig.get('networks').get(this.id);
    return _.isUndefined(vlan) || vlan.isNew();
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   *   The modified response.
   */
  parse: function(resp, options) {
    if (options && options.fromConfig && !this.isNew()) {
      var vlan = this.deviceConfig.get('networks').get(this.id);
      var dns = this.deviceConfig.get('dns').get(this.id);

      if (!_.isUndefined(dns)) {
        resp = dns.toJSON();
        delete resp.id;
        resp.ns1 = '';
        resp.ns2 = '';

        if (!_.isUndefined(resp.nameservers)) {
          if (!_.isUndefined(resp.nameservers[1])) {
            resp.ns2 = resp.nameservers[1];
          }

          if (!_.isUndefined(resp.nameservers[0])) {
            resp.ns1 = resp.nameservers[0];
          }

          delete resp.nameservers;
        }
        resp.af = this._parseAddressFamily(vlan);
      }
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('dns').get(this.id);
      },
      events: 'change',
    },
    {
      getDispatcher: function(config) {
        return config.get('networks').get(this.id);
      },
      events: 'change:addressMap',
    },
    {
      getDispatcher: function(config) {
        return config.getSubnet(this.id);
      },
      events: 'change:type',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};
    var af = this.get('af');

    if (_.has(attrs, 'ns1')) {
      if (attrs.ns1 !== '' && !networkUtils.validIP(attrs.ns1, af)) {
        errors.ns1 = i18n.t('actionWanDns.badIP');
      }
      if (attrs.ns1 === '') {
        errors.ns1 = i18n.t('actionWanDns.missingNameservers');
      }
    }

    if (_.has(attrs, 'ns2')) {
      if (attrs.ns2 !== '' && !networkUtils.validIP(attrs.ns2, af)) {
        errors.ns2 = i18n.t('actionWanDns.badIP');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object}
   */
  getTask: function() {
    var data = {};

    if (!this.isNew()) {
      data.id = this.id;
    }

    if (this.get('pendingDelete') === false) {
      data.dns = this.pick(_.without(this._snapshotAttributes, 'pendingDelete'));
      data.dns.source = 'static';
    } else {
      data.dns = {source: 'dynamic'};
    }

    return {
      name: 'vlan.create',
      data: data,
    };
  },

  /**
   * Gets the address family the current VLAN's subnet belongs to. This is
   * used to determine if the form should be validating ipv4 or ipv6 addresses.
   * Note, ipv6 is not implemented yet.
   *
   * @param {lib/models/Network} vlan
   * @return {String}
   */
  _parseAddressFamily: function(vlan) {
    var subnet = this.deviceConfig.getSubnet(this.id);
    return subnet.get('type') || 'ipv4';
  },

  /**
   * Returns the message to use when custom DNS is being removed.
   *
   * @see actions/shared/removeConfig/RemoveConfig
   * @see manage/edit/config/action/ActionView
   * @return {String}
   */
  getDeleteMsg: function() {
    return i18n.t('actionWanDns.taskDeleteDns');
  },
});
