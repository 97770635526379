'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var twig = require('twig').twig;
var tpl = require('actions/lanIsolationGlobal/static.html');

/**
 * Renders a text-only version of the global "LAN Segregation" action.
 */
module.exports = BaseStaticView.extend({
  template: twig({data: tpl}),
});
