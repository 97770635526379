'use strict';

var i18n = require('i18next');
var EditWanProtocol = require('actions/wanProtocol/EditWanProtocol');
var FormView = require('actions/wanProtocol/FormView');
var StaticView = require('actions/wanProtocol/StaticView');

/**
 * Action configuration for the VLAN dns action component.
 */
module.exports = {
  title: i18n.t('actionWanProtocol.title'),
  Model: EditWanProtocol,
  FormView: FormView,
  TextView: StaticView,
};
