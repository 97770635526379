'use strict';

// changes to these lists need to be mirrored in feature-dna-portal/src/Portal/ConfigRestore/ConfigRestorer.php
module.exports = {
  euCountries: ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR',
    'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB'],
  // For now, we support the same channels in US and CA, but this will change if we ever support outdoor devices.
  northAmerica: ['US', 'CA'],
  // For now, we support the same channels in AU and NZ, but this will change if we ever support DFS.
  australasia: ['AU', 'NZ'],
};
