'use strict';

/**
 * Small helper to replace a button's label with a spinner.
 */
module.exports = {
  /**
   * Replaces the button's label with the spinner graphic.
   *
   * @param {jQuery} btn
   * @param {String} additionalClasses
   */
  showButtonSpinner: function(btn, additionalClasses) {
    if (btn.find('.spinner').length) {
      return;
    }

    // wrap the original button text in a span that will hide it
    var wrapper = '<span class="spinner__replaced">' + btn.text() + '</span>';

    btn
      .addClass('spinner-container')
      .prop('disabled', true)
      .html(wrapper + '<span class="spinner ' + (additionalClasses ? additionalClasses : '') + '"></span>');
  },

  /**
   * Removes the spinner and restores the button's original label.
   *
   * @param {jQuery} btn
   */
  hideButtonSpinner: function(btn) {
    btn
      .text(btn.text()) // strip out all the HTML that was added
      .prop('disabled', false)
      .removeClass('spinner-container');
  },
};
