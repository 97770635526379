'use strict';

var Backbone = require('backbone');
var i18n = require('i18next');
var NetworkDisruptionAlert = require('actionGroups/shared/NetworkDisruptionView');

var staticProps = {
  alertId: 'trafficManagementDisruptionAlert',
};

/**
 * Custom GroupAlertView for the warning that changes will disrupt traffic monitoring.
 */
module.exports = NetworkDisruptionAlert.extend({

  initialize: function() {
    this.model = new Backbone.Model({
      id: staticProps.alertId,
      msg: i18n.t('configEdit.trafficManagementDisruption'),
    });
  },
}, staticProps);
