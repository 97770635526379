'use strict';

var _ = require('lodash');

/**
 * {@link lib/models/DeviceConfiguration} mixin for DHCP Relay related methods.
 *
 * Using a mixin to keep DeviceConfiguration from getting too large.
 *
 * @mixin lib/mixins/filterDhcpRelays
 */
module.exports = {
  /**
   * Gets the DHCP Relay for a VLAN.
   *
   * @param {String} vlanId
   * @param {Object} options
   * @return {Backbone.Model|undefined}
   */
  getDhcpRelay: function(vlanId, options) {
    var vlan = this.get('networks').get(vlanId);
    var relaysList = this.get('dhcpRelays');
    var relaysMap;

    if (_.isUndefined(vlan)) {
      return;
    }

    relaysMap = vlan.get('dhcpRelays');

    if (_.isUndefined(relaysMap) || relaysMap.length === 0) {
      return;
    }

    var relays = _.map(relaysMap, function(dhcpRelayId) {
      return relaysList.get(dhcpRelayId);
    });

    return _.first(relays);
  },
};
