'use strict';

window.jQuery = window.$ = require('jquery');

// force everyone to use the EventSource polyfill for consistent reconnect behavior
window.OriginalEventSource = window.EventSource;
window.EventSource = undefined;

require('bootstrap'); // TODO change to bootstrap3-datto once fixed
require('backbone.extras');
require('event-source-polyfill');
var i18n = require('i18next');
var i18nLanguageDetector = require('i18next-browser-languagedetector');
var i18nXhr = require('i18next-xhr-backend');
var LogMessage = require('lib/models/LogMessage');
var console2 = require('lib/Console');
var runtimeContext = require('lib/runtimeContext');
var twigExtensions = require('twig.extras');

window.onerror = function(errorMsg, url, lineNumber) {
  // Log to server
  (new LogMessage({
    'message': errorMsg,
    'url': url,
    'line': lineNumber,
    file: 'main.js',
  })).save();

  console2.log('error', 'Application Error: ', errorMsg, url, lineNumber);
};

twigExtensions.install();

i18n
  .use(i18nXhr)
  .use(i18nLanguageDetector)
  .init({
    // lng: 'es', // forces language (i18next picks up language from headers)
    // debug: true,
    whitelist: ['en', 'es'],
    fallbackLng: 'en',
    backend: {
      // //FIXME Appending the date/time is for cache-busting. It's not ideal
      // because it will cause the translations to be fetched every time.
      // We will need to find a better way.
      loadPath: runtimeContext.ASSET_URL_PREFIX + '/locales/{{lng}}/{{ns}}.json?t=' + new Date().getTime(),
    },
  }, function(err, t) {
    if (err) {
      console2.log('error', 'i18n Initialization Error: ', err);
    }
    var App = require('app/App');
    new App().start();
  });
