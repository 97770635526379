'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Radio = require('backbone.radio');
var BaseNetworksListView = require('components/networksList/NetworksListView');
var layoutChannel = Radio.channel('layoutChannel');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');
var detailTypes = require('manage/details/detailTypes');

/**
 * Displays the list of networks associated with a DNA.
 */
module.exports = BaseNetworksListView.extend({
  /**
   * @param {Object} options
   * @property {String} options.deviceMac
   *   The DNA mac to look up the list of networks.
   * @property {lib/models/DeviceConfiguration} options.deviceConfig
   * @property {lib/models/DeviceStatus} options.deviceStatus
   */
  initialize: function(options) {
    this.deviceMac = options.deviceMac;
    this.deviceConfig = options.deviceConfig;
    this.deviceStatus = options.deviceStatus;

    this.initCollection();
  },

  /**
   * @fires layoutChannel.request~change:page
   * @param {components/networksList/NetworkView} childView
   */
  onChildviewNetworkSelected: function(childView) {
    var url = childView.model.get('url');

    Backbone.history.navigate(url);

    layoutChannel.request('change:page', {
      deviceMac: this.deviceMac,
      page: deviceConfigChannel.request('trim:url', url),
    });
  },

  /**
   * Defines the View's collection property, which is a combination of data
   * from DeviceConfiguration and DeviceStatus. Event-listeners are used to
   * keep this View's collection up to date.
   */
  initCollection: function() {
    var srcNetworks = this.deviceConfig.get('networks');
    var devicesLists = this.deviceStatus.get('connectedDevices');

    // changes to the network list that affect which items this view needs to display or their order
    var reSortEvents = 'add remove change:wanPriority change:description change:parentVlan change:ssid change:enabled';

    // create the collection and fill with initial contents
    var list = this.getModels(srcNetworks, devicesLists);
    this.collection = new Backbone.Collection(list);

    // handle subsequent changes to networks
    this.listenTo(srcNetworks, reSortEvents, function() {
      this.collection.set(this.getModels(srcNetworks, devicesLists));
    });

    // handle subsequent changes to connected device status
    this.listenTo(devicesLists, 'add change:devices', function(vlanDevicesModel) {
      var devices = vlanDevicesModel.get('devices');
      var model = this.collection.get(vlanDevicesModel.id);
      if (model) {
        model.set('numDevices', devices ? devices.length : 0);
      }
    });

    this.listenTo(devicesLists, 'remove', function(vlanDevicesModel) {
      var model = this.collection.get(vlanDevicesModel.id);
      if (model) {
        model.set('numDevices', 0);
      }
    });
  },

  /**
   * Generate the list of networks (in proper order) for this view to display
   *
   * @param {Backbone.Collection} networks
   * @param {Backbone.Collection} devicesLists
   * @returns {Array}
   */
  getModels: function(networks, devicesLists) {
    var self = this;

    return this.getOrderedNetworks(networks)
      .map(function(network) {
        // create a model from each network with the properties this view needs
        var attrs = {
          network: network,
          id: network.id,
          numDevices: 0,
          url: deviceConfigChannel.request('get:url', network.get('type'), self.deviceMac, network.id),
        };

        if (!_.isUndefined(devicesLists.get(network.id))) {
          var devices = devicesLists.get(network.id).get('devices');
          attrs.numDevices = devices ? devices.length : 0;
        }

        return new Backbone.Model(attrs);
      });
  },

  /**
   * Group and order the provided networks for display
   *
   * @param {Backbone.Collection} networks
   * @returns {Array}
   */
  getOrderedNetworks: function(networks) {
    var list;
    var byName = function(network) {
      var name = network.getName() || '';
      return name.toLocaleLowerCase();
    };

    // group networks by type
    var types = networks.groupBy(function(network) {
      var type = network.get('type');

      if (type === detailTypes.VLAN && network.get('role') === 'external') {
        return 'wan';
      }
      if (type === detailTypes.VLAN && network.get('role') === 'internal') {
        return 'lan';
      }
      if (type === detailTypes.WIFI) {
        return 'wifi';
      }
      if (type === detailTypes.VPN && network.get('enabled')) {
        return 'vpn';
      }
    });

    // further group wifi networks by parent LAN
    types.wifi = _.groupBy(types.wifi || [], function(wifi) {
      return wifi.get('parentVlan');
    });

    // start with WANs sorted by priority
    list = _.sortBy(types.wan || [], function(wan) {
      return wan.get('wanPriority');
    });

    // sort the LANs by name
    _.sortBy(types.lan || [], byName)
    // and add each LAN and its child wifi networks, also sorted by name
    .forEach(function(lan) {
      var wifis = _.sortBy(types.wifi[lan.id] || [], byName);
      list = list.concat(lan, wifis);
    });

    // put VPNs at the end, sorted by name
    list = list.concat(_.sortBy(types.vpn || [], byName));

    return list;
  },
});
