'use strict';

var SUPPORTED_DUPLEX_SETTINGS = ['auto', 'full', 'half'];
var SUPPORTED_SPEED_SETTINGS = ['auto', '1000', '100', '10'];

/**
 * This is a helper for port setting related things.
 * I know this module is currently bare and I don't have a good reason for splitting these arrays from ports.js
 * as of yet.
 * With that being said this module will be getting used more as customers require more fine
 * grained port settings to be introduced.
 */

module.exports = {
  getSupportedDuplex: function() {
    return SUPPORTED_DUPLEX_SETTINGS;
  },

  getSupportedSpeed: function() {
    return SUPPORTED_SPEED_SETTINGS;
  },
};
