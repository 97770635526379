'use strict';

var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var _ = require('lodash');

/**
 * Model for Router's updateWindow which is a type of update schedule.
 */
module.exports = ActionItem.extend({
  /**
   * @member #attributes
   * @property {Boolean} enabled
   * @property {Array} weekdays
   *   Values '0' to '6'
   * @property {String} start
   *   Time of day in the format 'HHMM'
   * @property {Number} interval
   *   Duration of update window in seconds
   */

  _snapshotAttributes: ['enabled', 'weekdays', 'start', 'interval'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  getOnOffState: function() {
    return !!this.get('enabled');
  },

  /**
   * Get the updateSchedule from config, filter out all other types of
   * updates and return an updateWindow schedule.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var schedules = this.deviceConfig.get('schedule');
      // for now we support only a single update window (though
      // it can be effective on multiple days of the week)
      var updateWindow = schedules.find({type: 'updateWindow'});
      var config;
      var defaults = {
        enabled: false,
        weekdays: [],
        start: null,
        interval: 7200,
      };

      if (updateWindow) {
        config = {
          enabled: updateWindow.get('enabled'),
          weekdays: updateWindow.get('weekday'), // name is singular in config blob
          start: updateWindow.get('start'),
          interval: updateWindow.get('interval'),
        };
      }

      return _.defaults({}, config, defaults);
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        // TODO: there are multiple types of schedule, need to filter for specific type to
        // avoid generating extra events.  Using "config.get('schedule').filter({type: 'updateWindow'})"
        // doesn't work because it returns the wrong Backbone type.
        return config.get('schedule');
      },
      events: 'add remove change',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (!this.get('enabled')) {
      return;
    }

    if (_.has(attrs, 'start') && !this.isValidStartTime(attrs.start)) {
      errors.start = i18n.t('actionUpdateSchedule.errors.badStartTime');
    }

    if (_.has(attrs, 'weekdays')) {
      var weekdaysError = this.getWeekdaysError(attrs.weekdays);

      if (weekdaysError) {
        errors.weekdays = weekdaysError;
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @param {String} startTime
   * @returns {Boolean}
   */
  isValidStartTime: function(startTime) {
    return this.getTimesList().indexOf(startTime) >= 0;
  },

  /**
   *
   * @param {Array} weekdays
   * @returns {String|null}
   */
  getWeekdaysError: function(weekdays) {
    if (!weekdays || weekdays.length === 0) {
      return i18n.t('actionUpdateSchedule.errors.needWeekday');
    }

    var unknownDays = _.difference(weekdays, this.getWeekdaysList());
    if (unknownDays.length > 0) {
      return i18n.t('actionUpdateSchedule.errors.badWeekday');
    }

    if (weekdays.length !== _.uniq(weekdays).length) {
      return i18n.t('actionUpdateSchedule.errors.sameWeekdays');
    }

    // for now, interval is not user editable; if that changes, add validation

    return null;
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    return {
      name: 'schedule.setUpdateWindow',
      data: {
        enabled: this.get('enabled'),
        start: this.get('start'),
        weekdays: this.get('weekdays'),
        interval: this.get('interval'),
      },
    };
  },

  getTimesList: function() {
    return [
      '0000',
      '0200',
      '0400',
      '0600',
      '0800',
      '1000',
      '1200',
      '1400',
      '1600',
      '1800',
      '2000',
      '2200',
    ];
  },

  getWeekdaysList: function() {
    return ['0', '1', '2', '3', '4', '5', '6'];
  },
});
