'use strict';

var AddressView = require('actions/staticAddress/AbstractAddressView');
var twig = require('twig').twig;
var networkUtils = require('lib/network');
var i18n = require('i18next');
var ipv4Tpl = require('actions/staticAddress/lan/ipv4-address.html');
var ipv6Tpl = require('actions/staticAddress/lan/ipv6-address.html');

/**
 * LAN address form.
 */
module.exports = AddressView.extend({
  className: 'address-form type-lan',

  /**
   * @return {Object}
   */
  serializeData: function() {
    var data = AddressView.prototype.serializeData.apply(this, arguments);
    data.subnetMasks = networkUtils.getSubnetMasks(16, 30);
    return data;
  },

  /**
   * Returns the template based on the address family.
   *
   * @return {Function}
   */
  getTemplate: function() {
    var af = this.model.get('af');
    if (af === 'ipv4') {
      return twig({allowInlineIncludes: true, data: ipv4Tpl});
    } else if (af === 'ipv6') {
      return twig({allowInlineIncludes: true, data: ipv6Tpl});
    }
  },

  onRender: function() {
    this.onChangeMask(this.model, this.model.get('size'));
    this.updateRange();
    AddressView.prototype.onRender.apply(this, arguments);
  },

  /**
   * Overridden to display the info text next to the mask input field.
   *
   * @listens this.model.attributes.address~change:size
   * @param {actions/vlan/AbstractEditVlan} model
   * @param {String|Number} mask
   * @param {Object} options
   */
  onChangeMask: function(model, mask, options) {
    var maskElm;
    var text = '';

    AddressView.prototype.onChangeMask.apply(this, arguments);

    maskElm = this.$el.find('.mask-info');

    if (this.model.get('size') === '') {
      maskElm.addClass('hidden').text('');
      return;
    }

    // mask is expected to be in CIDR
    text = 'CIDR: /' + mask;

    if (this.model.get('af') === 'ipv4') {
      text += ' - ' + i18n.t('actionStaticAddress.maskText', {context: 'ipv4'}) + ': ' +
        networkUtils.Ip.cidrToIp(mask);
    }

    maskElm
      .text(text)
      .removeClass('hidden');
  },

  /**
   * Display a warning if the router address is not at the beginning or end
   * of the subnet. Best practices suggest that the router IP either be the
   * first or last hosts address (e.g. 192.168.0.1 or 192.168.0.254).
   */
  checkForNonStandardIp: function() {
    var suggested;
    var msg;

    this.ui.ipWarning.bs3ui('clearInlineStatus');

    if (this.model.hasNonStandardRouterIp() === true &&
        this.model.get('newAddressHasError') === false) {
      suggested = this.model.getSuggestedRouterIps();
      msg = i18n.t('actionStaticAddress.nonStandardRouterIp', {suggested: suggested});
      this.ui.address.bs3ui('inlineStatus', this.ui.ipWarning, 'warning', msg);
    }
  },

});
