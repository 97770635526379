'use strict';

var Marionette = require('backbone.marionette');
var i18n = require('i18next');
var _ = require('lodash');
var $ = require('jquery');
var tpl = require('actions/siteVpn/form.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var DnaSiteModel = require('actions/siteVpn/DnaSite/DnaSiteVpn');
var netObjsDetailsTpl = require('actions/siteVpn/net-objs-details.html');
var DnaSiteView = require('actions/siteVpn/DnaSite/DnaSiteFormView');
var OtherSiteView = require('actions/siteVpn/OtherSite/OtherSiteFormView');
var OtherSiteModel = require('actions/siteVpn/OtherSite/OtherSiteVpn');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
var RenderChanges = require('lib/behaviors/RenderChanges');
var networkUtils = require('lib/network');

var SiteToSiteListView = Marionette.CollectionView.extend({
  childView: function(item) {
    if (item instanceof DnaSiteModel) {
      return DnaSiteView;
    }

    return OtherSiteView;
  },

  // wire child views to ToggleFormOff
  childViewTriggers: {
    'render': 'item:render',
    'onoff:status:change': 'item:onoff:status:change',
  },
});

/**
 * Renders the "Site to Site VPN" form fields.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/siteVpn/FormView#model
   * @type {actions/siteVpn/EditSiteVpn}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
      enableCallback: function(childView) {
        this.reapplyDisabledFields(childView);
      },
    },
  ],

  template: twig({data: tpl}),
  netObjsDetailsTpl: twig({data: netObjsDetailsTpl}),

  templateContext: function() {
    var baseHelpers = tplHelpers.apply(this);
    return _.extend({}, baseHelpers, {
      eligibleNetworks: _.bind(this._getEligibleNetworks, this.model),
      staticRoutes: _.bind(this._getStaticRoutes, this.model),
    });
  },

  regions: {
    siteVpnConfigs: '.rg-site-vpn-configs',
  },

  ui: {
    addDna: '.add-dna',
    addNonDna: '.add-non-dna',
    cardError: '.site-vpn-config-error',
    resetSection: '.site-vpn-reset',
    reset: '[name="reset"]',
    newSite: '.new-site-to-site',
    rangeWarning: '.range-warning',
    routeDisabledWarning: '.route-disabled-warning',
    badAddressWarning: '.bad-address-warning',
    noLocalAddressWarning: '.no-local-addresses-warning',
    selectedNetObjs: '[name="selected-network-objs"]',
    allowLan: '[name="vpn-allow-lan[]"]',
    allowRoute: '[name="vpn-allow-static-routes[]"]',
  },

  events: {
    'click @ui.addDna': 'addDnaSiteToSite',
    'click @ui.addNonDna': 'addNonDnaSiteToSite',
    'click @ui.reset': 'handleResetClick',
    'change @ui.allowLan': 'updateAllowedRoutes',
    'change @ui.allowRoute': 'updateAllowedRoutes',
    'change @ui.selectedNetObjs': 'checkWarningsAndUpdateRoutes',
  },

  modelEvents: {
    'invalid': 'onError',
    'clearValidation': 'clearValidationErrors',
  },

  initialize: function() {
    this.listenTo(this.model.get('items'), 'add remove', this.checkResetAndAddAvailabilityFromConfig);
    this.listenTo(this.model.get('items'), 'change add remove', this.checkResetAvailabilityFromLocalChange);
  },

  /**
   * Triggered from the model when validation is called by the user clicking "save changes"
   */
  clearValidationErrors: function() {
    this.ui.cardError.bs3ui('clearFieldError');
  },

  checkNoLocalAddressWarning: function() {
    if (_.isEmpty(this.model.get('allowedLans'))
        && _.isEmpty(this.model.get('allowedStaticRoutes'))
        && _.isEmpty(this.model.get('allowedNetObjs'))) {
      this.ui.noLocalAddressWarning.removeClass('hidden');
    } else {
      this.ui.noLocalAddressWarning.addClass('hidden');
    }
  },

  checkWarningsAndUpdateRoutes: function(event) {
    this.updateAllowedRoutes(event);
    this.checkNetObjWarnings();
  },

  checkNetObjWarnings: function() {
    if (this.selectedNetObjHasBadAddress()) {
      this.ui.badAddressWarning.removeClass('hidden');
    } else {
      this.ui.badAddressWarning.addClass('hidden');
    }
    if (this.selectedNetObjHasRange()) {
      this.ui.rangeWarning.removeClass('hidden');
    } else {
      this.ui.rangeWarning.addClass('hidden');
    }
  },

  selectedNetObjHasBadAddress: function() {
    var selected = this.model.get('allowedNetObjs');

    return this.model.get('allNetObjs').some(function(obj) {
      if (selected.includes(obj.id) && obj.specifications.includes('0.0.0.0')) {
        return true;
      }
      // If it has a subnet that includes 0.0.0.0
      if (selected.includes(obj.id) && obj.specifications.some(function(spec) {
        return spec.includes('/') && networkUtils.isIPv4InSubnet('0.0.0.0', spec);
      })) {
        return true;
      }
      return false;
    });
  },

  selectedNetObjHasRange: function() {
    var selected = this.model.get('allowedNetObjs');
    return this.model.get('allNetObjs').some(function(obj) {
      if (selected.includes(obj.id)) {
        return obj.specifications.some(function(spec) {
          return spec.includes('-');
        });
      }
      return false;
    });
  },

  onRender: function() {
    this.initChildViews();
    if (!this.model.get('isCurrentDeviceSpoke')) {
      this.toggleReset();
    }
    var select2 = this.ui.selectedNetObjs.select2({
      placeholder: i18n.t('actionSiteVpn.helpSelectAll'),
      width: '100%',
      templateResult: _.bind(this.formatDetails, this),
      theme: 'bootstrap',
      closeOnSelect: false,
    });
    select2.data('select2').$dropdown.addClass('multicontent');
    var routeDisabledMsg = i18n.t('actionVpn.staticRouteDisabled');
    var netObjRangeMsg = i18n.t('actionSiteVpn.warnIgnoreNetObjRange');
    var ignoreZeroMsg = i18n.t('actionSiteVpn.warnIgnoreZeroAddress');
    var noLocalAddressesMsg = i18n.t('actionSiteVpn.noAllowedLansWarning');
    this.ui.noLocalAddressWarning.bs3ui('inlineStatus', this.ui.noLocalAddressWarning, 'warning', noLocalAddressesMsg);
    this.ui.routeDisabledWarning.bs3ui('inlineStatus', this.ui.routeDisabledWarning, 'warning', routeDisabledMsg);
    this.ui.rangeWarning.bs3ui('inlineStatus', this.ui.rangeWarning, 'warning', netObjRangeMsg);
    this.ui.badAddressWarning.bs3ui('inlineStatus', this.ui.badAddressWarning, 'warning', ignoreZeroMsg);
    this.checkNetObjWarnings();
    this.checkNoLocalAddressWarning();
  },

  onAttach: function() {
    // our list of available lans, routes, and netobjs need to be updated
    this.listenTo(this.model.deviceConfig.get('firewall').get('NetworkObjects'), 'add remove', this.render);
    this.listenTo(this.model.deviceConfig.get('networks'), 'add remove change', this.render);
    this.listenTo(this.model.deviceConfig.get('subnets'), 'add remove change', this.render);
    this.listenTo(this.model.deviceConfig.get('staticRoutes'), 'add remove change', this.render);
  },

  initChildViews: function() {
    var siteToSiteView = new SiteToSiteListView({
      collection: this.model.get('items'),
    });
    this.showChildView('siteVpnConfigs', siteToSiteView);
  },

  onError: function(model, error, options) {
    if (error.duplicateDna) {
      this.ui.cardError.bs3ui('showFieldError', error.duplicateDna);
    }

    if (error.noneSelected) {
      this.ui.cardError.bs3ui('showFieldError', error.noneSelected);
    }
  },

  /**
   * Adds a DNA site model
   * @param {Event} ev
   */
  addDnaSiteToSite: function(ev) {
    ev.preventDefault();
    this.model.get('items').add(new DnaSiteModel({
      availableDevices: this.model.get('availableDevices'),
    }));
  },

  /**
   * Adds a non dna site model
   * @param {Event} ev
   */
  addNonDnaSiteToSite: function(ev) {
    ev.preventDefault();
    this.model.get('items').add(new OtherSiteModel());
  },

  /**
   * Checks if values have changed and saves.
   *
   * @param {Object} event
   */
  updateAllowedRoutes: function(event) {
    var name = event.currentTarget.name;
    var allowed = null;

    switch (name) {
      case 'vpn-allow-lan[]':
        // build array of the data-id values for all checked networks
        allowed = this.ui.allowLan
          .filter(':checked')
          .map(function(index, element) {
            return element.dataset.id;
          })
          .get();
        this.model.set({allowedLans: allowed}, {commit: true});
        break;
      case 'vpn-allow-static-routes[]':
        // build array of the data-id values for all checked networks
        allowed = this.ui.allowRoute
          .filter(':checked')
          .map(function(index, element) {
            return element.dataset.id;
          })
          .get();
        this.model.set({allowedStaticRoutes: allowed}, {commit: true});
        break;
      case 'selected-network-objs':
        allowed = [];
        _.each(event.currentTarget.selectedOptions, function(opt) {
          allowed.push(opt.value);
        });
        this.model.set({allowedNetObjs: allowed}, {commit: true});
        break;
      default:
        break;
    }
    this.checkNoLocalAddressWarning();
  },

  /**
   * Initiates a forced save to reset the config
   * @see actionGroups/editSiteVpn/EditSiteVpnView
   */
  handleResetClick: function() {
    this.model.trigger('request:save', {force: true});
  },

  /**
   * Toggles the reset button
   */
  toggleReset: function() {
    var itemsEnabledValue = this.model.get('items').pluck('enabled');
    if (itemsEnabledValue.indexOf(true) !== -1 && !this.model.getSnapshotDiff()) {
      // if you've made changes, or need to make changes, the next step is save, not reset
      this.ui.reset.prop('disabled', false);
    } else {
      this.ui.reset.prop('disabled', true);
    }
  },

  formatDetails: function(data, container) {
    var deviceConfig = this.model.deviceConfig;
    var details = {};
    var html;
    if (!data.id) {
      return $('');
    }

    details = {
      id: data.id,
      name: data.text,
    };
    var obj = deviceConfig.get('firewall').get('networkObjects').get(data.id);
    details.specifications = obj.get('specifications');

    html = this.netObjsDetailsTpl.render(_.extend(details, {t: _.bind(i18n.t, i18n)}));
    return $(html);
  },

  /**
   * Fires when the collections items have changed due to a new configuration being sent up
   * @param {Backbone.model} model
   * @param {Backbone.collection} collection
   * @param {object} options
   */
  checkResetAndAddAvailabilityFromConfig: function(model, collection, options) {
    if (options && options.fromConfig) {
      var itemsEnabledValue = this.model.get('items').pluck('enabled');
      if (collection.models.length > 0) {
        this.ui.newSite.prop('disabled', model.get('isCurrentDeviceSpoke'));
      } else {
        this.ui.newSite.prop('disabled', false);
      }

      if (model.get('isCurrentDeviceSpoke') === true) {
        this.ui.reset.prop('disabled', true);
      } else if (itemsEnabledValue.indexOf(true) !== -1) {
        this.ui.reset.prop('disabled', false);
      } else {
        this.ui.reset.prop('disabled', true);
      }
    }
  },

  /**
   * This is fired by change, add, and remove events by the user to determine if we need to disable/enable the reset
   * button
   * @param {Backbone.model} model
   * @param {Object} data
   * @param {Object} options
   */
  checkResetAvailabilityFromLocalChange: function(model, data, options) {
    // Add and remove have different function args which is why this typeof check is used
    var functionOptions = options;
    if (typeof functionOptions === 'undefined') {
      functionOptions = data;
    }

    if (functionOptions && functionOptions.fromConfig) {
      // bail if this change was triggered from a config
      return;
    }
    if (model.get('isCurrentDeviceSpoke') === true) {
      this.ui.reset.prop('disabled', true);
    } else {
      this.toggleReset();
    }
  },

  /**
   * Get each network that VPN routes can be allowed to,
   * and whether they currently are allowed
   *
   * @returns {Array}
   */
  _getEligibleNetworks: function() {
    return this.deviceConfig.getVlans({external: false})
      .map(function(lan) {
        var subnet = this.deviceConfig.getSubnet(lan.id);
        var addr = '';
        if (!_.isUndefined(subnet)) {
          addr = subnet.get('address') + '/' + subnet.get('size');
        }
        return _.extend({}, lan.toJSON(), {
          subnet: addr,
        });
      }, this);
  },

  /**
   * Get each network that VPN routes can be allowed to,
   * and whether they currently are allowed
   *
   * @returns {Array}
   */
  _getStaticRoutes: function() {
    var routes = this.deviceConfig.get('staticRoutes');
    return routes.map(function(lan) {
      return _.extend({}, lan.toJSON(), {
      });
    });
  },

  reapplyDisabledFields: function(childView) {
    if (childView instanceof OtherSiteView) {
      childView.toggleKeyExchangeDropdown();
    }
  },
});
