'use strict';

var i18n = require('i18next');
var EditVpnAddress = require('actions/staticAddress/vpn/EditVpnAddress');
var FormView = require('actions/staticAddress/vpn/VpnAddressView');
var StaticView = require('actions/staticAddress/StaticView');

/**
 * Action configuration for the VPN subnet address action component.
 */
module.exports = {
  title: i18n.t('actionStaticAddress.title.vpn'),
  Model: EditVpnAddress,
  FormView: FormView,
  TextView: StaticView,
};
