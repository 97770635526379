'use strict';

var i18n = require('i18next');
var EditWanAddress = require('actions/staticAddress/wan/ipv6/EditWanAddressIpv6');
var FormView = require('actions/staticAddress/wan/WanAddressView');
var StaticView = require('actions/staticAddress/StaticView');

/**
 * Action configuration for the WAN static address action component.
 */
module.exports = {
  title: i18n.t('actionStaticAddress.title.wanIpv6'),
  Model: EditWanAddress,
  FormView: FormView,
  TextView: StaticView,
};
