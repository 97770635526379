'use strict';

var _ = require('lodash');
var Backbone = require('backbone');

/**
 * Settings, if you will, for the Usage graphs
 */
module.exports = Backbone.Model.extend({
  /**
   * @member {Object} components/usage/GraphModel#attributes
   * @property {Integer} height
   *  Height of Chart
   * @propery {boolean} liveUpdates
   *  Whether the graph should live update or not
   */

  initialize: function(options) {
    this.set(_.extend({
      height: 300,
      liveUpdates: true,
    }, options));

    if (options.type === 'small') {
      this.set('height', 100);
      this.set('liveUpdates', false);
    }
  },
});
