'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var formTpl = require('actions/globalDns/form.html');

require('lib/jquery/bootstrapUI');

module.exports = Marionette.View.extend({

  template: twig({data: formTpl}),

  ui: {
    messages: '.help-block',
    searchDomain: '[name="dns-search"]',
  },

  events: {
    'blur @ui.searchDomain': 'saveModel',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  saveModel: function() {
    var searchDomain = this.ui.searchDomain.val();
    this.ui.searchDomain.bs3ui('clearFieldError');
    this.model.set('searchDomain', searchDomain, {commit: true});
  },

  onError: function(model, error, options) {
    if (error.searchDomain) {
      this.ui.searchDomain.bs3ui('showFieldError', error.searchDomain);
    }
  },
});
