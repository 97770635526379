'use strict';

var AbstractConfigPageView = require('manage/edit/page/AbstractConfigPageView');
var i18n = require('i18next');
var configPage = require('lib/configPage');

var PAGE_KEY = configPage.FIREWALL;

module.exports = AbstractConfigPageView.extend({

  /**
   * @see AbstractConfigPageView#getActiveCardList
   * @return {Backbone.Collection}
   */
  getActiveCardList: function() {
    return this.model.get(PAGE_KEY);
  },

  /**
   * @see AbstractConfigPageView#getToolboxTitle
   * @return {String}
   */
  getToolboxTitle: function() {
    return i18n.t('toolbox.title.firewall');
  },

  /**
   * @see AbstractConfigPageView#getCreationalCardFilter
   * @return {Function}
   */
  getCreationalCardFilter: function() {
    return function(GroupModel) {
      return GroupModel.isCreational === true &&
        GroupModel.creationalPage === PAGE_KEY;
    };
  },

});
