'use strict';

var colors = [
  '#59C1C0',
  '#1CA45A',
  '#B1CF5E',
  '#FBB843',
  '#FD822A',
  '#CE1816',
  '#8358BE',
  '#1960A8',
  '#2180FB',
  '#57C7FC',
];

module.exports = {
  colorForIndex: function(index) {
    return colors[index % colors.length];
  },
  otherColor: '#878787',
};
