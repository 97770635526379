'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var i18n = require('i18next');
var twig = require('twig').twig;
var portsTpl = require('manage/details/ports/ports.html');
var tooltipTpl = require('actions/ports/tooltip.html');
var PortsLib = require('lib/ports');
var Entities = require('html-entities').XmlEntities;
var entities = new Entities();

var WAN_PORTS = ['eth0', 'eth1'];

var PORT_STATUS = {
  SELECTED: 'selected',
  UNAVAILABLE: 'unavailable',
  AVAILABLE: 'available',
  SELECTED_AND_ENSLAVED: 'selectedAndEnslaved',
};

/**
 * Renders the Ports info section
 */
module.exports = Marionette.View.extend({
  /**
   * Reference to a {@link lib/models/DeviceConfiguration} "networks" object.
   * @member {Backbone.Model} manage/details/vlan/configs/AddressView#model
   */

  /**
   * A reference to deviceConfig.
   * @member {lib/models/DeviceConfiguration} manage/details/vlan/configs/PortsView#deviceConfig
   */

  /**
   * A reference to deviceStatus.
   * @member {lib/models/DeviceStatus} manage/details/vlan/configs/PortsView#deviceStatus
   */

  template: twig({data: portsTpl}),
  tooltipTemplate: twig({data: tooltipTpl}),

  className: 'static-ports-wrap dui-def',

  ui: {
    ports: '.port-wrap',
  },

  /**
   * set attributes from the device configuration and status
   * @param {Object} options
   * @property {lib/models/DeviceStatus} options.deviceStatus
   * @property {lib/models/DeviceConfiguration} options.deviceConfig
   */
  initialize: function(options) {
    this.deviceConfig = options.deviceConfig;
    this.deviceStatus = options.deviceStatus;
    this.isVlanView = options.isVlanView;

    this.listenTo(this.deviceStatus.get('ports'), 'change add remove', this.render);
    this.listenTo(this.deviceConfig.get('networks'), 'change:portsMap add remove', this.render);
    this.listenTo(this.deviceConfig.get('ports'), 'change add remove', this.render);
  },

  /**
   * Gathers the port data and splits the list of ports into two groups. One
   * for odd-numbered ports and another for even-numbered ports.
   *
   * @return {Object}
   */
  serializeData: function() {
    var nativePorts = PortsLib.getNativePorts(this.deviceConfig, this.deviceStatus);
    var ports = PortsLib.splitPortsByPortNumber(nativePorts);

    return {
      portsList: ports,
    };
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    var self = this;
    return {
      tooltip: this.helperPortTooltip.bind(this),
      status: this.helperPortStatusLabel.bind(this),
      isNative: function(port) {
        switch (self.getPortStatus(port)) {
          case PORT_STATUS.SELECTED:
          case PORT_STATUS.SELECTED_AND_ENSLAVED:
            return 'is-native';
          case PORT_STATUS.UNAVAILABLE:
            return 'is-other-native';
          case PORT_STATUS.AVAILABLE:
          default:
            return '';
        }
      },
    };
  },

  onRender: function() {
    // bootstrap tooltip plugin
    this.ui.ports.tooltip({html: true});
  },

  /**
   * @param {Object} port
   *   Port details
   * @returns {String}
   */
  helperPortStatusLabel: function(port) {
    switch (this.getPortStatus(port)) {
      case PORT_STATUS.SELECTED:
        return i18n.t('actionPorts.portSelected');
      case PORT_STATUS.UNAVAILABLE:
        return i18n.t('actionPorts.portUnavailable');
      case PORT_STATUS.SELECTED_AND_ENSLAVED:
        return i18n.t('actionPorts.portGrouped');
      case PORT_STATUS.AVAILABLE:
      default:
        return i18n.t('actionPorts.portAvailable');
    }
  },

  /**
   * Builds the tooltip message to indicate the port is unavailable and to
   * which vlan it belongs to.
   *
   * @param {Object} port
   * @return {String}
   */
  helperPortTooltip: function(port) {
    var vlan;
    var data = {
      cable: i18n.t('actionPorts.portHasCable'),
    };

    var portStatus = this.getPortStatus(port);

    if (portStatus === PORT_STATUS.UNAVAILABLE) {
      // This checks if a port is native to a specific vlan.
      // However if a port is bonded and if the ports view is being viewed from the ports details view,
      // a bond that is not assigned to a vlan will not have ports with nativeTo assigned. So we also check if the port
      // is bonded and if so just show a bond description instead. It is only from the ports details view because
      // port status on that page is decided on if the port is nativeTo, taggedBy or bonded. So we need to make this
      // check here a little different.
      if (port.nativeTo) {
        vlan = this.deviceConfig.get('networks').get(port.nativeTo);
        data.nativeTo = entities.decode(vlan.getName());
      } else if (!_.isUndefined(port.bondId)) {
        data.bondDescription = port.bondDescription;
      }
    }

    if (portStatus === PORT_STATUS.SELECTED_AND_ENSLAVED) {
      data.bondDescription = port.bondDescription;
    }

    if (port.autoNegotiated) {
      data.autoNegotiated = true;
    } else {
      data.speed = port.configuredSpeed;
      data.duplex = port.configuredDuplex;
    }

    if (port.up === false) {
      data.cable = i18n.t('actionPorts.portNoCable');
    }

    return this.tooltipTemplate.render(_.extend(data, {t: _.bind(i18n.t, i18n)}));
  },

  /**
   * Helper method for consistently calculating port status
   *
   * @param {Object} port
   * @return {String} a value from PORT_STATUS
   */
  getPortStatus: function(port) {
    if (this.isVlanView) {
      var vlanId = this.model.id;
      var isWanPort = WAN_PORTS.indexOf(port.id) >= 0;

      // For ports part of a vlan that are mixed between bonds and non-bonded ports
      // if at any time _.isUndefined bondId is true that means the port is not part of a bond.
      if ((port.nativeTo === vlanId || port.taggedBy.indexOf(vlanId) >= 0) && _.isUndefined(port.bondId)) {
        return PORT_STATUS.SELECTED;
      }

      // if a port is part of a nic bond and the bond is being used for a tagged VLAN or natively we want to show
      // that this port is selected and grouped.
      if ((port.nativeTo === vlanId || port.taggedBy.indexOf(vlanId) >= 0) && !_.isUndefined(port.bondId)) {
        return PORT_STATUS.SELECTED_AND_ENSLAVED;
      }

      // only consider ports unavailable if they are reserved for a WAN or this is a native VLAN
      if ((isWanPort || !this.model.has('tagId')) &&
        port.nativeTo &&
        port.nativeTo !== vlanId
      ) {
        return PORT_STATUS.UNAVAILABLE;
      }

      return PORT_STATUS.AVAILABLE;
    } else if (!this.isVlanView) {
      if (!_.isUndefined(port.bondId) || port.taggedBy.length > 0 || port.nativeTo) {
        return PORT_STATUS.UNAVAILABLE;
      }

      return PORT_STATUS.AVAILABLE;
    }
  },
});
