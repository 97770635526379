'use strict';

var Marionette = require('backbone.marionette');
var RouteView = require('actions/staticRoutes/FormRouteView');
var tpl = require('actions/staticRoutes/form.html');
var twig = require('twig').twig;
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');

var RoutesListView = Marionette.CollectionView.extend({
  childView: RouteView,
  sortable: true,

  // wire child views to ToggleFormOff
  childViewTriggers: {
    'render': 'item:render',
    'onoff:status:change': 'item:onoff:status:change',
  },
});

/**
 * Renders a text-only version of the static routes form action component.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/staticRoutes/FormView#model
   * @type {actions/staticRoutes/StaticRoutes}
   */

  behaviors: [
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  regions: {
    routes: '.rg-staticRoutes',
  },

  ui: {
    add: '.btn-add',
  },

  events: {
    'click @ui.add': 'addRoute',
  },

  template: twig({data: tpl}),

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child views.
   */
  initChildViews: function() {
    this.showChildView('routes', new RoutesListView({
      collection: this.model.get('items'),
    }));
  },

  /**
   * Add a new blank route to the list of static routes
   */
  addRoute: function() {
    this.model.addRoute();
  },
});
