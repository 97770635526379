'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var i18n = require('i18next');
var formTpl = require('actions/wanPppoe/form.html');
var RemoveConfig = require('manage/edit/config/action/removeConfig/RemoveConfig');
var RenderChanges = require('lib/behaviors/RenderChanges');
var TogglePasswordField = require('lib/behaviors/ToggleSinglePassword');

/**
 * Renders the PPPoE options available to an external VLAN.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/wanPppoe/FormView#model
   * @type {actions/wanPppoe/EditWanPppoe}
   */

  behaviors: [
    {
      behaviorClass: RemoveConfig,
    },
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: TogglePasswordField,
      showPasswordOnLoad: false,
    },
  ],

  template: twig({data: formTpl}),

  ui: {
    username: '[name="username"]',
    password: '[name="password"]',
  },

  events: {
    'blur @ui.username': 'handleFormChange',
    'blur @ui.password': 'handleFormChange',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    return _.extend(tplHelpers.apply(this), {
      pendingDeleteMsg: i18n.t('actionWanPppoe.taskDeletePppoe'),
    });
  },

  /**
   * @param {Event} ev
   */
  handleFormChange: function(ev) {
    var name = ev.currentTarget.name;
    var value = ev.currentTarget.value;
    var data = {};

    if (name === 'username') {
      this.ui.username.bs3ui('clearFieldError');
      data.username = value;
    } else if (name === 'password') {
      this.ui.password.bs3ui('clearFieldError');
      data.password = value;
    }

    this.model.set(data, {commit: true});
  },

  /**
   * Decorates field on error.
   *
   * @param {actions/wanPppoe/EditWanPppoe} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.username) {
      this.ui.username.bs3ui('showFieldError', error.username);
    }

    if (error.password) {
      this.ui.password.bs3ui('showFieldError', error.password);
    }
  },

});
