'use strict';

var ConfigItem = require('lib/models/ConfigItem');
var networkUtils = require('lib/network');

/**
 * Represents an individual MAC filter
 */
module.exports = ConfigItem.extend({
  /**
   * @member {Object} #attributes
   * @property {String} mac
   *   MAC Address
   * @property {Boolean} remove
   *   Pending removal
   */

  _snapshotAttributes: ['mac', 'remove'],

  defaults: {
    mac: '',
    remove: false,
  },

  validate: function(attrs, options) {
    if (!networkUtils.validMacAddress(this.get('mac'))) {
      return true;
    }
  },
});
