'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var ActionItem = require('actions/shared/ActionItem');
var childModels = require('lib/mixins/childModels');
var Category = require('actions/trafficShaping/Category');
var i18n = require('i18next');

var Categories = Backbone.Collection.extend({
  model: Category,
  comparator: function(category) {
    var label = _.result(category, 'getLabel');
    return _.result(label, 'toLocaleLowerCase');
  },
});

// build a mixin to handle the nested collection of categories
var nesting = childModels({categories: Categories}, ActionItem);

/**
 * Edit model for Traffic Shaping (QoS).
 */
module.exports = ActionItem.extend(nesting).extend({
  /**
   * @member {Object} #attributes
   * @property {String} id
   * @property {Number} upload
   *   max per client upload rate in Mbps
   * @property {Number} download
   *   max per client download rate in Mbps
   * @property {Backbone.Collection} categories
   * @property {Boolean} pendingDelete
   */

  _snapshotAttributes: ['upload', 'download', 'categories', 'pendingDelete', 'enabled'],

  defaults: function() {
    return {
      upload: 1000,
      download: 1000,
      categories: [],
      enabled: false,
    };
  },

  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var qos = this.deviceConfig.get('networkQos').get(this.id);

      if (qos) {
        return {
          upload: qos.get('upload') / 1000, // Kbps to Mbps
          download: qos.get('download') / 1000,
          categories: qos.get('categories'),
          enabled: qos.get('enabled'),
        };
      }
      return this.getParseDefaults();
    }

    return resp;
  },

  getParseDefaults: function() {
    // if there are no settings to parse (i.e. for a new unsaved network),
    // cheat and try to use vlan2 as a template for available categories -
    // re-think this if categories become customizable instead of hard-coded
    var template = this.deviceConfig.get('networkQos').get('vlan2') || new Backbone.Model();
    var categories = _.map(template.get('categories'), function(category) {
      return _.pick(category, 'name');
    });
    return _.extend(this.defaults(), {categories: categories});
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networkQos').get(this.id);
      },
      events: 'change',
    },
  ],

  validate: function(attrs, options) {
    var errors = {};

    if (this.get('pendingDelete')) {
      return;
    }

    if (_.has(attrs, 'upload')) {
      if (!this.isValidRate(attrs.upload)) {
        errors.upload = i18n.t('actionTrafficShaping.invalidUpload');
      }
    }

    if (_.has(attrs, 'download')) {
      if (!this.isValidRate(attrs.download)) {
        errors.download = i18n.t('actionTrafficShaping.invalidDownload');
      }
    }

    if (_.has(attrs, 'categories')) {
      var categoriesValid = this.get('categories').invoke('isValid');
      if (categoriesValid.indexOf(false) >= 0) {
        errors.categories = i18n.t('actionTrafficShaping.invalidCategorySettings');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  getTask: function() {
    var task = {
      name: 'vlan.create',
      data: {
        // to remove (disable) QoS, we just don't send it when the LAN is re-created
        qos: this.get('pendingDelete') ? undefined : {
          upload: this.get('upload') * 1000, // Mbps to Kbps
          download: this.get('download') * 1000,
          categories: this.get('categories').toJSON(),
        },
      },
    };

    if (!this.isNew()) {
      task.data.id = this.id;
    }

    return task;
  },

  /**
   * Checks whether a value represents a valid data rate
   *
   * @private
   * @param {Number} value
   * @returns {Boolean}
   */
  isValidRate: function(value) {
    return _.isFinite(value) && value > 0;
  },

});
