'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var networkUtils = require('lib/network');

/**
 * Base View-Model for creating/editing a Wi-Fi network.
 *
 * As its name implies, this model should be treated as an "abstract" class
 * and not used directly.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/wifi/AbstractEditVlan#attributes
   * @property {String} id
   *   The wifi networks ID (eg bss0.1).
   * @property {String} ssid
   * @property {string} parentVlan
   *   The VLAN the wifi network will be a part of.
   * @property {String} encryptionType
   * @property {String} encryptionKey
   * @property {Boolean} broadcastSSID
   * @property {String} role
   *   Always set to "internal". It's really only here to provide consistency
   *   with VLANs.
   */

  _snapshotAttributes: [
    'ssid', 'parentVlan', 'encryptionType', 'encryptionKey', 'broadcastSSID',
  ],

  defaults: {
    role: 'internal',
    pendingDelete: false,
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return _.isUndefined(this.deviceConfig.get('networks').get(this.id));
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true && !this.isNew()) {
      resp = this.getAttributesByWifiId(this.id);
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks').get(this.id);
      },
      events: 'change',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};
    var validateAll;
    options = options || {};

    if (this.get('pendingDelete') === true) {
      return;
    }

    validateAll = (options.validateAll !== false);

    if (!_.isUndefined(attrs.ssid) || validateAll) {
      if (_.isEmpty(attrs.ssid)) {
        errors.ssid = i18n.t('actionWifi.missingSsid');
      } else if (!networkUtils.validSSID(attrs.ssid)) {
        errors.ssid = i18n.t('actionWifi.badSsid');
      } else if (!this._uniqueSsid(attrs.ssid)) {
        errors.ssid = i18n.t('actionWifi.duplicateSsid');
      }
    }

    if (!_.isUndefined(attrs.parentVlan) || validateAll) {
      if (_.isEmpty(attrs.parentVlan)) {
        errors.parentVlan = i18n.t('actionWifi.noParentVlan');
      } else if (!this._validParentVlan(attrs.parentVlan)) {
        errors.parentVlan = i18n.t('actionWifi.badParentVlan');
      }
    }

    if (attrs.encryptionType !== 'none') {
      if (!_.isUndefined(attrs.encryptionKey) || validateAll) {
        if (_.isEmpty(attrs.encryptionKey)) {
          errors.encryptionKey = i18n.t('actionWifi.noPassphrase');
        } else if (!this._validPassPhrase(attrs.encryptionKey, attrs.encryptionType)) {
          errors.encryptionKey = i18n.t('actionWifi.badPassphrase');
        }
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Boolean}
   */
  getSnapshotDiff: function() {
    if (this.get('pendingDelete') === true) {
      return true;
    }

    return ActionItem.prototype.getSnapshotDiff.apply(this);
  },

  /**
   * Loads a Wi-Fi networks details from configuration.
   *
   * @param {String} wifiId
   * @return {Object}
   */
  getAttributesByWifiId: function(wifiId) {
    var wifi = this.deviceConfig.get('networks').get(wifiId);
    return wifi.omit('type', 'macWhitelist', 'macBlacklist');
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    var data = {};

    if (!this.isNew() || this.get('pendingDelete') === true) {
      data.id = this.id;
    }

    if (this.get('pendingDelete') === true) {
      return {
        name: 'wifi.delete',
        data: data,
      };
    }

    _.extend(data, this.pick(_.without(this._snapshotAttributes, 'pendingDelete')));

    return {
      name: 'wifi.create',
      data: data,
    };
  },

  /**
   * Validates the WPA-PSK and WPA2-PSK passphrase.
   *
   * @param {String} key
   * @param {String} type
   * @return {Boolean}
   */
  _validPassPhrase: function(key, type) {
    var validCharsRegex = /[\x20-\x7E]+/;
    var validWepCharsRegex = /^[0-9a-fA-F]{10}$/;

    if (type !== 'wep') {
      return (key.length >= 8 && key.length <= 63 && validCharsRegex.test(key));
    }

    return (validWepCharsRegex.test(key));
  },

  /**
   * Verifies that the selected VLAN exists and is a LAN.
   *
   * @param {String} vlanId
   * @return {Boolean}
   */
  _validParentVlan: function(vlanId) {
    var vlan = this.deviceConfig.get('networks').get(vlanId);

    if (_.isUndefined(vlan)) {
      return false;
    }

    if (vlan.get('type') !== 'vlan' || vlan.get('role') !== 'internal') {
      return false;
    }

    return true;
  },

  _uniqueSsid: function(ssid) {
    var self = this;
    var duplicates = this.deviceConfig.get('networks').filter(function(network) {
      return network.get('type') === 'wifi' && network.get('ssid') === ssid && network.id !== self.id;
    });

    return _.isEmpty(duplicates);
  },

}, {

  /**
   * List of supported encryption types
   *
   * @static
   * @memberof! actions/wifi/EditWifi
   * @type {Array}
   */
  encryptionTypes: [
    {value: 'psk2', label: i18n.t('actionWifi.encryptionTypes.wpa2'), isMostSecure: true},
    {value: 'psk-mixed+tkip+aes', label: i18n.t('actionWifi.encryptionTypes.wpaMixed')},
    {value: 'psk', label: i18n.t('actionWifi.encryptionTypes.wpa')},
    {value: 'wep', label: i18n.t('actionWifi.encryptionTypes.wep')},
    {value: 'none', label: i18n.t('actionWifi.encryptionTypes.none')},
  ],

});
