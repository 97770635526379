'use strict';

var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var networkUtils = require('lib/network');

/**
 * Edit model for Router's "hostname" setting.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/router/hostname/EditHostname#attributes
   * @property {string} hostname
   *   The router's hostname
   */

  _snapshotAttributes: ['hostname'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    // hostname always exists
    return false;
  },

  /**
   * Parse response for Hostname attribute.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      return {
        hostname: this.deviceConfig.get('router').get('hostname'),
      };
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('router');
      },
      events: 'change:hostname',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Array|unsigned}
   */
  validate: function(attrs, options) {
    if (!networkUtils.validHostname(attrs.hostname)) {
      return {
        hostname: i18n.t('actionHostname.invalidHostname'),
      };
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    return {
      name: 'router.setHostname',
      data: {
        hostname: this.get('hostname'),
      },
    };
  },

});
