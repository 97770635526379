'use strict';

var MultiActionItem = require('actions/shared/MultiActionItem');
var EditRoute = require('actions/staticRoutes/Route');

/**
 * Model for creating/managing static routes
 */
module.exports = MultiActionItem.extend({

  collectionModel: EditRoute,

  /**
   * Overridden to always return false
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var routes = this.deviceConfig.get('staticRoutes').toJSON();
      var interfaces = this.deviceConfig.getVlans({external: false}).map(function(lan) {
        return lan.toJSON();
      });

      return {
        items: this.getRoutes(routes, interfaces),
        interfaces: interfaces,
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('staticRoutes');
      },
      events: 'add remove change',
    },
    {
      getDispatcher: function(config) {
        return config.get('networks');
      },
      events: 'add remove change:description',
    },
  ],

  orderRoutes: function(route1, route2) {
    var mask1 = route1.get('destination').split('/')[1];
    var mask2 = route2.get('destination').split('/')[1];
    var maskDiff = parseInt(mask2) - parseInt(mask1);
    if (maskDiff !== 0) {
      return maskDiff;
    }
    return parseInt(route1.get('metric')) - parseInt(route2.get('metric'));
  },

  /**
   * @param {Array} routes
   * @param {Array} vlans
   * @return {Array} routes
   */
  getRoutes: function(routes, vlans) {
    routes = routes.map(function(route) {
      route.metric = route.metric.toString();
      route.vlans = vlans;
      return new EditRoute(route);
    });
    return routes.sort(this.orderRoutes);
  },

  /**
   * Adds a new static route
   */
  addRoute: function() {
    var route = new EditRoute({vlans: this.get('interfaces')});

    this.get('items').add(route);
  },
});
