'use strict';

var ConfigItems = require('lib/collections/ConfigItems');
var EditPort = require('actions/ports/Port');
var LibPorts = require('lib/ports');

/**
 * Collection of {@link actions/ports/Port} models.
 */
module.exports = ConfigItems.extend({
  /**
   * @type {actions/ports/Port}
   */
  model: EditPort,

  initialize: function(models, options) {
    this.addDeviceStatus(options);
  },

  /**
   * @param {Array} resp
   * @param {Object} options
   * @return {Array}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      resp = LibPorts.getAllPorts(this.deviceConfig, this.deviceStatus);
    }

    return resp;
  },
});
