'use strict';

var i18n = require('i18next');
var EditLanAddress = require('actions/staticAddress/lan/EditLanAddress');
var FormView = require('actions/staticAddress/lan/LanAddressView');
var StaticView = require('actions/staticAddress/StaticView');

/**
 * Action configuration for the LAN static address action component.
 */
module.exports = {
  title: i18n.t('actionStaticAddress.title.lan'),
  Model: EditLanAddress,
  FormView: FormView,
  TextView: StaticView,
};
