'use strict';

var BasePortsFormView = require('actions/ports/FormView');

/**
 * Renders the list of ports (part of the Bond ports action component).
 */
module.exports = BasePortsFormView.extend({

  /**
   * Overrides portFilter to so that aggregated ports are not shown
   * on the port aggregation card.
   *
   * @param {actions/ports/Port} port
   * @return {Boolean}
   */
  portFilter: function(port) {
    return !port.isBond && port.id !== 'eth1' && port.id !== 'eth0';
  },

  /**
   * Overrides the supportsTagging property in the template context
   * as bonds are not networks, and tagged vlans would be set up in the vlan card.
   *
   * @return {Object}
   */
  templateContext: function() {
    var context = BasePortsFormView.prototype.templateContext.apply(this);
    context.supportsTagging = false;
    // We do not want to show the aggregated ports port group on port aggregation cards
    context.supportsAggregation = false;
    return context;
  },
});
