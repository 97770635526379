'use strict';

var i18n = require('i18next');
var StaticRoutes = require('actions/staticRoutes/StaticRoutes');
var FormView = require('actions/staticRoutes/FormView');
var StaticView = require('actions/staticRoutes/StaticView');

/**
 * Action configuration for the static routes action component.
 */
module.exports = {
  title: i18n.t('actionStaticRoutes.title'),
  Model: StaticRoutes,
  FormView: FormView,
  TextView: StaticView,
};
