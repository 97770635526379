'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var console2 = require('lib/Console');

/**
 * AbstractActionManager provides base functionality for custom action managers
 *
 * Normally you will not create instances directly; instead, register your
 * subclass with the desired config group and it will be automatically
 * created and initialized when the group is populated
 *
 * @see {config/Group} #actionManagers
 *
 * @param {config/Group} group
 * @param {config/Actions} actions
 * @constructor
 */
var AbstractActionManager = function(group, actions) {
  // these are private and subject to change; subclasses should not use them directly
  this._group = group;
  this._actions = actions;

  // subclasses should not depend on this happening here;
  // could change to deferred initialization in the future
  this.initialize();
};

_.extend(AbstractActionManager.prototype, Backbone.Events, {

  /**
   * Called when the manager can begin attaching event listeners
   *
   * Intended to be overridden by subclasses
   */
  initialize: function() {
    // does nothing by default
  },

  /**
   * Register a listener on the host group's action collection
   *
   * @param {String} event
   * @param {Function} callback
   * @param {String} [actionId]
   *   if provided, only listen to events from the specified action
   */
  listenOnActions: function(event, callback, actionId) {
    var cb;

    if (actionId) {
      cb = function(model) {
        if (model && model.id === actionId) {
          callback.apply(this, arguments);
        }
      };
    } else {
      cb = callback;
    }

    this.listenTo(this._actions, event, cb);
  },

  /**
   * Retrieve an action from the host group
   *
   * @param {String} actionId
   * @return {config/Action}
   */
  getAction: function(actionId) {
    return this._actions.get(actionId);
  },

  /**
   * Add an action to the host group
   *
   * @param {String} actionId
   */
  addAction: function(actionId) {
    this._group.addAction(actionId);
  },

  /**
   * Gets the group Id that a specific action is being added to/removed from.
   *
   * @returns {String} id of the group
   */
  getGroupType: function() {
    return this._group.get('type');
  },

  /**
   * Remove an action from the host group, if present
   *
   * Note that this follows the normal action removal flow -
   * specifically, if an action was already present in the
   * saved config, it will be set to a "pendingDelete" state
   * and given a chance to perform final cleanup on save.
   *
   * @param {String} actionId
   */
  removeAction: function(actionId) {
    var action = this._actions.get(actionId);
    if (action) {
      action.remove();
    } else {
      console2.log('warn', actionId + ' is not a configured action.');
    }
  },

});

// add the "extend" helper function that allows subclass creation in a
// "Backbone" style like `AbstractActionManager.extend({...})`. Note that
// this does not cause this class to have any inheritance relationship to
// Backbone.Model; we're just grabbing one of its "static" functions
AbstractActionManager.extend = Backbone.Model.extend;

module.exports = AbstractActionManager;
