'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/vpn/auth/form.html');
var twig = require('twig').twig;
var TogglePasswordField = require('lib/behaviors/ToggleSinglePassword');
var RenderChanges = require('lib/behaviors/RenderChanges');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

/**
 * Renders the "Authentication" form field.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/vpn/auth/FormView#model
   * @type {actions/vpn/auth/EditVpnAuth}
   */

  template: twig({data: tpl}),

  behaviors: function() {
    return [
      {
        behaviorClass: TogglePasswordField,
        showPasswordOnLoad: false,
      },
      {
        behaviorClass: RenderChanges,
      },
      {
        behaviorClass: ToggleFormOff,
      },
    ];
  },

  ui: {
    'secret': '[name="vpn-secret"]',
  },

  events: {
    'blur @ui.secret': 'saveForm',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  templateContext: function() {
    return {
      supportsIKEv2: !!this.model.deviceStatus.get('router').get('dynHostname'),
      mac: this.model.deviceMac,
    };
  },

  /**
   * Sync changes in UI input elements to the model and trigger validation
   *
   * @param {Object} event
   */
  saveForm: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'vpn-secret':
        this.ui.secret.bs3ui('clearFieldError');
        this.model.set({secret: value}, {commit: true});
        break;

      default:
        break;
    }
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/vpn/auth/EditVpnAuth} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.secret) {
      this.ui.secret.bs3ui('showFieldError', error.secret);
    }
  },

});
