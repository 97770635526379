'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var appConfig = require('./../appConfig.prod.js');
var Radio = require('backbone.radio');
var AppRouter = require('app/Router');
var AppController = require('app/Controller');
var SessionModel = require('app/SessionModel');
var AppLayout = require('layouts/appLayout/AppLayoutView');
var LoadingView = require('layouts/appLayout/LoadingView');
var AppError = require('layouts/appError/AppError');
var RpcClient = require('lib/RpcClient');
var Stream = require('app/Stream');
var AppTosView = require('layouts/tos/AppTos');
var twig = require('twig').twig;
var console2 = require('lib/Console');

/**
 * Initializes and kicks off our application.
 *
 * This app has two base layouts ("public" and "app"). The base layout is
 * rendered after checking if the current user is authenticated.
 */
module.exports = Marionette.Application.extend({
  region: '.app-container',

  initialize: function(options) {
    _.bindAll(this,
      'onAuthChange', 'initAppLayout', 'loadUserSession', 'onAppError'
    );

    if (appConfig.debug === true) {
      Radio.DEBUG = true;
      // Radio.tuneIn('userChannel');
      // Radio.tuneIn('deviceConfigChannel');
      // Radio.tuneIn('layoutChannel');
      // Radio.tuneIn('socket');
      // Radio.tuneIn('apiChannel');
      // Radio.tuneIn('graphChannel');
      // Radio.tuneIn('dnaStatusChannel');
    }

    // Initialize Ajax handler
    this.initRequestHandler();

    // Register header template
    // Unfortunately, this has to go here because it is shared by a few Views.
    twig({id: 'appHeader', data: require('layouts/header.html')});
  },

  /**
   * Start the app.
   *
   * Load the important bits (router, etc.) and check if the user is logged in.
   */
  onStart: function() {
    if (!this.isBrowserSupported()) {
      return;
    }

    this.userSession = new SessionModel({});

    this.appController = new AppController({
      session: this.userSession,
    });

    this.appRouter = new AppRouter({
      controller: this.appController,
      session: this.userSession,
    });

    this.listenTo(this.userSession, 'change:loggedIn', this.onAuthChange);

    // When the app is initially rendered, Backbone will check the route and
    // fire the appropriate method in {@link app/Controller}. That first page
    // change will be picked up here, which instantiates the base layout and
    // then the relevant app Views. After this initial render, page changes
    // will go through {@link layouts/appLayout/AppLayoutView}.
    Radio.replyOnce('layoutChannel', 'change:page', this.initAppLayout);

    this.showLoader();

    this.userSession.checkAuth().then(this.loadUserSession)
      .done(function() {
        var hasTos = this.userSession.get('user').has('acceptedTermsAndConditions');
        var hasAcceptedTos = hasTos && this.userSession.get('user').get('acceptedTermsAndConditions') !== '0';
        if (hasAcceptedTos) {
          this.initApp();
        } else {
          this.showTos();
        }
      }.bind(this))
      .fail(function() {
        // Show error and then logout user after sleeping for a bit
        this.onAppError(AppError.ALL_SYSTEMS_DOWN);
        setTimeout(function() {
          this.userSession.logout();
        }.bind(this), 2000);
      }.bind(this));
  },

  /**
   * Completely destroys the current layout when the current user's
   * authenticated state changes.
   *
   * @param {app/SessionModel} model
   * @param {Boolean} isLoggedIn
   * @param {Object} options
   */
  onAuthChange: function(model, isLoggedIn, options) {
    if (typeof this.baseLayout == 'object') {
      this.getRegion().empty();
      delete this.baseLayout;
    }
  },

  /**
   * Renders the app's main layout.
   *
   * @listens layoutChannel.replyOnce#change:page
   * @param {Object} options
   */
  initAppLayout: function(options) {
    this.baseLayout = new AppLayout(options);
    this.showView(this.baseLayout);
  },

  /**
   * While initializing the app, an error stopped it in its tracks.
   *
   * @param {Number} type
   *   Error code to pass through the error View.
   */
  onAppError: function(type) {
    this.showView(new AppError({errorCode: type}));
    // TODO error logging
  },

  /**
   * Shows the loading spinner.
   */
  showLoader: function() {
    var loader = new LoadingView({text: ''});
    this.showView(loader);
  },

  /**
   * Starts eventsource and kicks off the Backbone router (which will cause
   * the app layout to be rendered).
   */
  initApp: function() {
    this.stream = new Stream(this.userSession);

    if (!Backbone.history.start({pushState: true})) {
      // Handle 404 by redirecting to index (//TODO proper 404?)
      Backbone.history.navigate('', {trigger: true, replace: true});
    }
  },

  /**
   * Shows the terms of service interstitial View.
   */
  showTos: function() {
    var self = this;
    var tosView = new AppTosView();

    this.listenTo(tosView, 'tos:accepted', function() {
      self.showLoader();
      self.initApp();
    });

    tosView.loadTranslations().done(function() {
      self.showView(tosView);
    }).fail(function(err) {
      console2.log('error', 'Failed to load localized TOS text: ', err);
      self.onAppError(AppError.ALL_SYSTEMS_DOWN);
    });
  },

  /**
   * Initializes AJAX request handler. All dna-portal-api calls should run
   * through this.
   *
   * @example
   * var Radio = require('backbone.radio');
   * var apiChannel = Radio.channel('apiChannel');
   * var promise = apiChannel.request('send', Some.Method', {... data ...});
   */
  initRequestHandler: function() {
    var rpcClient = new RpcClient({
      url: appConfig.apiAjaxUrl,
      debug: appConfig.debug,
      withCredentials: appConfig.apiAjaxWithCredentials,
    });

    // Overridden to handle session timeouts
    rpcClient._resolve = function(rpcResponse, deferred) {
      var code;

      if (_.has(rpcResponse, 'error')) {
        code = rpcResponse.error.code;

        if (code === RpcClient.jsonErrors.JSON_USER_NOT_LOGGED_IN
          || code === RpcClient.jsonErrors.JSON_TOKEN_EXPIRED) {
          Radio.trigger('userChannel', 'user:timeout');
        }
        // TODO: Fix this and handle an unknown error correctly
        // I don't think this is the functionality that we are looking for
        // if (code === RpcClient.jsonErrors.JSON_INTERNAL_ERROR) {
        //   Radio.trigger('userChannel', 'user:timeout');
        // }
      }

      // call parent to properly resolve deferred object
      RpcClient.prototype._resolve.apply(this, arguments);
    };

    Radio.reply('apiChannel', 'send', function(method, params, options) {
      return rpcClient.request(method, params, options);
    });
  },

  /**
   * Loads the user session. Note, {@link app/SessionModel} will actually kick
   * off loading the session data when the user is authenticated. Therefore,
   * this method actually watches for when the session data is retrieved.
   *
   * @return {jQuery.Deferred}
   */
  loadUserSession: function() {
    var self = this;
    var deferred = $.Deferred();

    var listener = function(model, sessionLoading, options) {
      if (sessionLoading === false) {
        if (model.get('user').get('displayName') === '') {
          return deferred.reject();
        }
        deferred.resolve();
        self.stopListening(model, 'change:sessionLoading');
      }
    };

    this.listenTo(this.userSession, 'change:sessionLoading', listener);

    return deferred.promise();
  },

  /**
   * Checks that the app can run on the current browser.
   *
   * @return {Boolean}
   */
  isBrowserSupported: function() {
    var eventSource = (
      window.EventSource !== undefined &&
      ('withCredentials' in window.EventSource.prototype)
    );

    if (!eventSource) {
      this.onAppError(AppError.BROWSER_UNSUPPORTED);
      return false;
    }

    return true;
  },

});
