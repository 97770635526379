'use strict';

var i18n = require('i18next');
var EditPortForwardingRules = require('actions/portForwardingRules/EditPortForwardingRules');
var FormView = require('actions/portForwardingRules/FormView');
var StaticView = require('actions/portForwardingRules/StaticView');

/**
 * Action configuration for the Port Forwarding rules action component.
 */
module.exports = {
  title: i18n.t('portForwarding.rulesTitle'),
  Model: EditPortForwardingRules,
  FormView: FormView,
  TextView: StaticView,
};
