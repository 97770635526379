'use strict';

var MultiActionItem = require('actions/shared/MultiActionItem');
var EditHostMapping = require('actions/staticHostMapping/HostMapping');

/**
 * Model for creating/managing static host mappings
 *
 * Note, DHCP is unimplemented for IPv6 at this time.
 *
 */
module.exports = MultiActionItem.extend({

  collectionModel: EditHostMapping,

  /**
   * Overridden to always return false
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var hostMappings = this.deviceConfig.get('dnsStaticHosts').toJSON();

      return {
        items: this.getHostMappings(hostMappings),
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('dnsStaticHosts');
      },
      events: 'add remove change',
    },
  ],

  /**
   * Only returns the hostMappings for each VLAN, not all
   *
   * @param {Array} hostMappings
   * @return {Array} hostMappings
   */
  getHostMappings: function(hostMappings) {
    return hostMappings;
  },

  /**
   * Adds a new hostMapping
   */
  addHostMapping: function() {
    var hostMapping = new EditHostMapping();

    this.get('items').add(hostMapping);
  },
});
