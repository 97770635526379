'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/siteVpn/static.html');
var twig = require('twig').twig;
var StaticSiteVpnView = require('actions/siteVpn/StaticSiteVpnView');
var viewFilters = require('lib/viewFilters');

var SiteToSiteListView = Marionette.CollectionView.extend({
  childView: StaticSiteVpnView,
  filter: viewFilters.getStaticViewItemsCollectionViewFilter(),
});

/**
 * Renders a text-only version of the "Site to Site VPN" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name #model
   * @type {actions/siteVpn/EditSiteVpn}
   */

  template: twig({data: tpl}),

  regions: {
    siteVpns: '.rg-site-vpn-configs',
  },

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      getItemCountHelp: viewFilters.getItemCountHelpFn({
        // i18n.t('actionSiteVpn.noTunnels')
        emptyMsgKey: 'actionSiteVpn.noTunnels',
        // i18n.t('actionSiteVpn.allDisabled', {count: count})
        allDisabledMsgKey: 'actionSiteVpn.allDisabled',
        // i18n.t('actionSiteVpn.viewAll', {count: count})
        moreMsgKey: 'actionSiteVpn.viewAll',
      }),
      remoteSubnets: _.bind(this._getSubnets, this.model, 'remoteSubnets'),
      localSubnets: _.bind(this._getSubnets, this.model, 'localSubnets'),
    });
  },

  onRender: function() {
    this.initChildViews();
  },

  _getSubnets: function(subnets) {
    var subs = [];
    _.each(this.get('items').models, function(t) {
      if (t.get('enabled')) {
        _.each(t.get(subnets), function(s) {
          subs.push(s);
        });
      }
    });
    return _.uniq(subs);
  },

  initChildViews: function() {
    this.showChildView('siteVpns', new SiteToSiteListView({
      collection: this.model.get('items'),
    }));
  },
});
