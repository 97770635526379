'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('components/eventLog/event-log.html');
var tplHelpers = require('lib/tplHelpers');
var i18n = require('i18next');

/**
 * Renders the contents of an event log from the device.
 */
module.exports = Marionette.View.extend({

  template: twig({data: tpl}),

  ui: {
    refresh: '.btn-refresh',
    logContainer: '.event-log-container',
    status: '.event-log-status',
    message: '.help-block',
  },

  events: {
    'click @ui.refresh': 'loadData',
  },

  message: '',

  initialize: function(options) {
    this.mergeOptions(options, ['message']);
  },

  setMessage: function(value) {
    if (value === this.message) {
      return;
    }

    this.ui.message.find('.event-log-hint').text(value || '');
    this.ui.message.toggleClass('invisible', !value);

    this.message = value;
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var baseHelpers = tplHelpers.apply(this);

    return _.extend({}, baseHelpers, {
      validData: this.hasValidData,
      message: this.message,
    });
  },

  onAttach: function() {
    this.loadData();
  },

  /**
   * Tells the model to load event log data and updates the view
   */
  loadData: function() {
    var self = this;
    self.ui.refresh.attr('disabled', true);
    self.showStatus(i18n.t('eventLog.loading'));

    this.model.fetch()
      .then(function() {
        // success
        self.render();
      }, function() {
        // error
        self.ui.refresh.attr('disabled', false);
        self.showStatus(i18n.t('eventLog.error'));
      });
  },

  /**
   * Determines whether we actually have data to display
   *
   * @return {Boolean}
   */
  hasValidData: function() {
    return !this.loading && !this.error && this.data.length > 0;
  },

  /**
   * Displays a given status message in place of the data output
   *
   * @param {String} msg
   */
  showStatus: function(msg) {
    this.ui.logContainer.addClass('hidden');
    this.ui.status.removeClass('hidden').text(msg);
  },
});
