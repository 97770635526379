'use strict';

/**
 * Overrides and additions to Backbone and Marionette base classes.
 *
 * Note, this is probably terrible because we're adding methods directly to the
 * base classes. Need to research a better way.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var _ = require('lodash');
var i18n = require('i18next');
var console2 = require('lib/Console');

var debugMixins;

/**
 * Overrides Model._validate() to provide an option to only validate Model
 * properties that are currently being set or saved.
 *
 * See {@link https://github.com/gfranko/Backbone.validateAll}. Tried to simply
 * pull the plugin in via requirejs, but because of the way it is written, I
 * could not find a proper shim that worked.
 *
 * @param {Object} attrs
 * @param {Object} options
 * @return {Boolean}
 */
Backbone.Model.prototype._validate = function(attrs, options) {
  if (!options.validate || !this.validate) {
    return true;
  }
  if (options.validateAll !== false) {
    attrs = _.extend({}, this.attributes, attrs);
  }
  var error = this.validationError = this.validate(attrs, options) || null;
  if (!error) {
    return true;
  }
  this.trigger(
    'invalid', this, error, _.extend(options || {}, {validationError: error})
  );
  return false;
};

/**
 * Serves as a model factory.
 *
 * @param {Object} attrs
 * @return {Object}
 */
Backbone.Model.new = function(attrs) {
  return new Backbone.Model(attrs);
};

/**
 * Overrides Marionette's render() to make i18next's t() available to every
 * template.
 *
 * Note, this works great for a View's "template" property, but if you are
 * working with an ad-hoc template, you have to pass in t(),
 *   var myTpl = twig({data: '<p>{{t('prefix')}} {{name}}</p>'});
 *   var html = myTpl.render(_.extend(data, {t: _.bind(i18n.t, i18n)}));
 *
 * @param {String} template
 * @param {Object} data
 * @return {String}
 */
Marionette.Renderer.render = function(template, data) {
  data = _.extend(data, {t: _.bind(i18n.t, i18n)});
  return template.render(data);
};

/**
 * Development-related mixins
 * @type {Object}
 */
debugMixins = {
  /**
   * Simple method to log all events to the console.
   * @example
   *  this.debugEvents();
   *  model.debugEvents();
   *  //etc
   * @param {String} objectName
   *   The name to prepend each log message with.
   */
  debugEvents: function(objectName) {
    this.on('all', function(eventName) {
      var args = arguments;
      var msg = '';
      var extra = ' '; // space is deliberate

      if (args[1]) {
        if (args[1].attributes !== undefined) {
          extra += '(model, cid: ' + args[1].cid + ') ';
        } else if (args[1].models !== undefined) {
          extra += '(collection, length: ' + args[1].length + ') ';
        }
      }

      if (eventName !== undefined) {
        msg = objectName + ': ';
      }

      msg += '"' + eventName + '"' + extra + 'with ';
      console2.log('log', msg, Array.prototype.slice.call(arguments, 1));
    });
  },
};

_.each([
  Backbone.Model,
  Backbone.Collection,
  Backbone.View,
  Backbone.Router,
  Marionette.Object,
], function(obj) {
  _.extend(obj.prototype, debugMixins);
});
