'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/advancedNat/static.html');
var twig = require('twig').twig;
var viewFilters = require('lib/viewFilters');
var i18n = require('i18next');

module.exports = BaseStaticView.extend({
  template: twig({data: tpl}),

  /**
   * @returns {Object}
   */
  serializeData: function() {
    return {
      items: this.model.get('items').toJSON(),
      parsedItems: this.parseItems(),
      delimiter: '->',
      src: i18n.t('actionAdvancedNat.src'),
      dest: i18n.t('actionAdvancedNat.dest'),
    };
  },

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      getItemCountHelp: viewFilters.getItemCountHelpFn({
        // i18n.t('actionAdvancedNat.noRules')
        emptyMsgKey: 'actionAdvancedNat.noRules',
        // i18n.t('actionAdvancedNat.allDisabled', {count: count})
        allDisabledMsgKey: 'actionAdvancedNat.allDisabled',
        // i18n.t('actionAdvancedNat.viewAll', {count: count})
        moreMsgKey: 'actionAdvancedNat.viewAll',
      }),
    });
  },

  parseItems: function() {
    var items = this.model.get('items').toJSON();
    var parsedItems = _.reduce(items, function(list, item) {
      if (item.enabled === false || list.length === 5) {
        return list;
      }
      if (item.srcIp) {
        item.source = item.srcIp;
      } else {
        item.source = i18n.t('actionAdvancedNat.any').toUpperCase();
      }
      if (item.srcPort) {
        item.source += ':' + item.srcPort;
      }
      if (item.destIp) {
        item.dest = item.destIp;
      } else {
        item.dest = i18n.t('actionAdvancedNat.any').toUpperCase();
      }
      if (item.destPort) {
        item.dest += ':' + item.destPort;
      }
      if (item.bypass) {
        item.rewrite = i18n.t('actionAdvancedNat.bypass').toUpperCase();
      } else {
        item.rewrite = item.protocol.toUpperCase() + ' ' + item.type.toUpperCase() + ' ';
        if (item.rewrittenIp) {
          item.rewrite += item.rewrittenIp;
        } else {
          item.rewrite += i18n.t('actionAdvancedNat.any').toUpperCase();
        }
        if (item.rewrittenPort) {
          item.rewrite += ':' + item.rewrittenPort;
        }
      }
      list.push(item);
      return list;
    }, []);
    return parsedItems;
  },
});
