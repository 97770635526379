'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/failoverPolicyControl/policies/policy.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the editable form elements for a single failover policy.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/failoverPolicyControl/FormView#model
   * @type {actions/failoverPolicyControl/policies/policy}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    enabled: '[name="enabled"]',
    name: '[name="name"]',
    portRange: '[name="portRange"]',
    protocol: '[name="protocol"]',
    destIp: '[name="destIp"]',
    srcIp: '[name="srcIp"]',
    method: '[name="method"]',
    remove: '.btn-remove',
    messages: '.help-block',
    tcpEnabled: '.failover-tcp-custom',
  },

  events: {
    'change @ui.enabled': 'updateModel',
    'blur @ui.name': 'updateModel',
    'blur @ui.portRange': 'updateModel',
    'change @ui.protocol': 'updateModel',
    'change @ui.method': 'updateModel',
    'blur @ui.destIp': 'updateModel',
    'blur @ui.srcIp': 'updateModel',
    'click @ui.remove': 'handleRemoveButton',
  },

  modelEvents: {
    'change:remove': 'render',
    'change:enabled': 'onEnabledChange',
    'change:proto': 'render',
    'invalid': 'onError',
    'noConflicts': 'onNoConflicts',
  },

  onBeforeRender: function() {
    this.isItemOff = !this.model.get('enabled');
  },

  onEnabledChange: function(model, value) {
    this.isItemOff = !value;
    this.triggerMethod('onoff:status:change', this);
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var context = {};
    return _.extend(context, tplHelpers.apply(this));
  },

  /**
   * Sync changes in UI input elements to the model and trigger validation
   *
   * @param {Object} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'enabled':
        this.model.set({enabled: event.currentTarget.checked});
        break;

      case 'name':
        this.clearError('name');
        this.model.set({name: value}, {commit: true});
        break;

      case 'srcIp':
        this.clearError('srcIp');
        this.model.set({'srcIp': value}, {commit: true});
        break;

      case 'protocol':
        if (value === 'udp' && this.model.get('method') === 'tcp-reset') {
          this.model.set({method: 'icmp-port-unreachable'});
        }
        this.model.set({proto: value});
        break;

      case 'destIp':
        this.clearError('destIp');
        this.model.set({'destIp': value}, {commit: true});
        break;

      case 'portRange':
        this.clearError('portRange');
        this.model.set({portRange: value}, {validate: true, validateAll: false});
        break;

      case 'method':
        this.model.set({'method': value});
        break;

      default:
        break;
    }
  },

  /**
   * Remove this policy
   */
  handleRemoveButton: function() {
    if (this.model.isNew()) {
      // remove never-saved policies immediately
      this.model.collection.remove(this.model);
    } else {
      // flag existing policies for deletion upon save
      var shouldRemove = !this.model.get('remove');
      if (shouldRemove) {
        // if you ask to remove an existing policy, roll back any local changes
        // (otherwise it gets really confusing what you're actually removing)
        this.model.applySnapshot();
      }
      this.model.set({remove: shouldRemove});
    }
  },

  /**
   * Decorates the view to indicate error.
   *
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, this.ui.messages);
    }, this);
  },

  /**
   * Remove an error message from the view (if present)
   *
   * @param {String} key
   *   Identifier for the error message
   */
  clearError: function(key) {
    this.ui[key].bs3ui('clearGroupedFieldError', key, this.ui.messages);
  },

  /**
   * Clear any "special" errors related to port conflicts
   */
  onNoConflicts: function() {
    this.clearError('portRange');
  },

});
