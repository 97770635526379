'use strict';

var i18n = require('i18next');
var EditDescription = require('actions/description/siteVpn/EditDescription');
var FormView = require('actions/description/FormView');
var StaticView = require('actions/description/StaticView');

/**
 * Action configuration for the SiteVPN Description action component.
 */
module.exports = {
  title: i18n.t('actionDescription.description'),
  Model: EditDescription,
  FormView: FormView,
  TextView: StaticView,
};
