'use strict';

var _ = require('lodash');
var $ = require('jquery');
var console2 = require('lib/Console');

/**
 * AJAX wrapper for sending JSON-RPC formatted API calls.
 *
 * Each request is pushed into a queue and after a brief "cool down" (debounce), the queue
 * is sent to the server (rpc batching). This helps cut down on the number of
 * HTTP connections made to the server.
 *
 * @example
 * var RpcClient = require('lib/RpcClient');
 * var rpc = new RpcClient.Ajax({url: '/api'});
 *
 * @class
 * @alias RpcClient
 * @param {Object} options
 * @property {String} options.url
 *   The API's endpoint.
 */
var RpcClient = function(options) {
  this.url = options.url;
  this.debug = options.debug || false;
  this.withCredentials = (options.withCredentials === true);
  this._batchQueue = {}; // map of: group name => queue
  this._drainQueuesAfterCooldown = _.debounce(
    this._runAllBatchQueues.bind(this),
    100,
    {maxWait: 500} // failsafe in case of incessant stream of requests
  );
};

/**
 * Standardized JSON-RPC errors.
 * @see http://www.jsonrpc.org/specification#error_object
 * @static
 * @memberof RpcClient
 */
RpcClient.jsonErrors = {
  JSON_PARSE_ERROR: -32700,
  JSON_INVALID_STRUCTURE: -32600,
  JSON_NO_SUCH_METHOD: -32601,
  JSON_BAD_PARAMETERS: -32602,
  JSON_INTERNAL_ERROR: -32603,
  // custom
  JSON_USER_NOT_LOGGED_IN: -32001,
  JSON_SECURITY_ERROR: -32002,
  JSON_TOKEN_EXPIRED: -32004,
  JSON_TOKEN_SIGNATURE_INVALID: -32005,
  JSON_USER_ERROR: -32098,
  JSON_RESULT_UNDEFINED: -32099,
};

/**
 * Builds the RPC payload.
 *
 * @static
 * @memberof RpcClient
 * @param {Number} id
 * @param {String} method
 * @param {Object} params
 * @return {Object}
 */
RpcClient.getRpcBody = function(id, method, params) {
  return {
    jsonrpc: '2.0',
    id: id,
    method: method,
    params: params,
  };
};

RpcClient.prototype = {
  /**
   * Makes an API call.
   *
   * @param {string} method
   * @param {Object} params
   * @param {Object} options
   *   @property {String} options.group
   *     Requests may only be batched if they are in the same group
   *     Defaults to 'default'
   * @returns {jQuery.Promise}
   */
  request: function(method, params, options) {
    var deferred = $.Deferred();
    var opts = _.defaults({}, options, {group: 'default'});
    var group = opts.group;
    var queue = this._batchQueue[group] = this._batchQueue[group] || [];

    queue.push({
      method: method,
      params: params,
      deferred: deferred,
    });

    this._drainQueuesAfterCooldown();

    return deferred.promise();
  },

  /**
   * Determines how to resolve an RPC response.
   *
   * @private
   * @param {Object} rpcResponse
   * @param {jQuery.Deferred} deferred
   */
  _resolve: function(rpcResponse, deferred) {
    if (typeof rpcResponse.error == 'object') {
      console2.log('error', 'RPC error: %s', rpcResponse.error.message, rpcResponse);
      deferred.reject(rpcResponse);
    } else {
      deferred.resolve(rpcResponse);
    }
  },

  _runAllBatchQueues: function() {
    _.forEach(this._batchQueue, this._runBatchQueue, this);
  },

  /**
   * Sends API calls (as a batch) waiting in the specified queue.
   * @param {Array} queue
   * @param {String} name
   *   Name of the queue/group
   * @private
   */
  _runBatchQueue: function(queue, name) {
    var self = this;
    var requestData;
    var processing;
    var i;
    var ajaxOptions;

    if (queue.length < 1) {
      return;
    }

    // local queue is copied from global queue
    processing = queue.slice();

    // reset global queue
    this._batchQueue[name] = [];

    requestData = processing.map(function(request, index) {
      return RpcClient.getRpcBody(index, request.method, request.params);
    });

    ajaxOptions = {
      url: this.url,
      contentType: 'application/json-rpc',
      data: JSON.stringify(requestData),
      dataType: 'json',
      method: 'POST',
    };

    if (this.withCredentials === true) {
      ajaxOptions.xhrFields = {withCredentials: true};
    }

    $.ajax(ajaxOptions)
      .done(function(data, textStatus, xhr) {
        if (typeof data[0] === 'undefined') {
          data = [data];
        }

        for (var i = 0; i < data.length; i++) {
          self._resolve(data[i], processing[data[i].id].deferred);
        }
      })
      .fail(function(xhr, textStatus, error) {
        for (i = 0; i < processing.length; i++) {
          processing[i].deferred.reject();
        }
      });
  },
};

module.exports = RpcClient;
