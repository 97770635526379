'use strict';

var ActionItem = require('actions/shared/ActionItem');

/**
 * Edit model for VPN's "enabled" setting.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} #attributes
   * @property {Boolean} enabled
   *   Whether the Open VPN service is enabled
   */

  _snapshotAttributes: ['enabled'],

  defaults: {
    enabled: false,
  },

  /**
   * Overridden to always return false (VPN is a fixed group)
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  toggledOnOffBy: 'vpnEnable',

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var attrs = {};
      var vpn = this.deviceConfig.get('openVpn');
      if (vpn) {
        attrs.enabled = vpn.get('ui_enabled');
      }
      return attrs;
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('openVpn');
      },
      events: 'change:enabled',
    },
  ],

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    return {
      name: 'vpn.setOptions',
      data: {
        openVpnEnabled: this.get('enabled'),
      },
    };
  },

});
