'use strict';

var _ = require('lodash');
var Backbone = require('backbone');

var JOB_STATES = {
  PENDING: 'pending',
  APPLIED: 'applied',
  APPLYING: 'applying',
  FAILED: 'failed',
  SCHEDULED: 'scheduled',
};

module.exports = Backbone.Model
.extend({

  defaults: {
    status: 'pending',
  },

  start: function(mac) {
    throw new Error('Extending Job requires defining a custom start method');
  },

  isDone: function() {
    return _.contains([
      JOB_STATES.APPLIED,
      JOB_STATES.FAILED,
    ], this.get('status'));
  },
});

