'use strict';

var i18n = require('i18next');

var MAX_STATICVIEW_ITEMS = 5;

var wrapMessage = function(message) {
  return '<span class="message">' + message + '</span>';
};

var wrapMessageInline = function(message) {
  return '<span class="message-inline">' + message + '</span>';
};

var wrap = function(message, inline, empty) {
  if (inline) {
    if (!empty) {
      message = i18n.t('general.listSeparator') + ' ' + message;
    }
    return wrapMessageInline(message);
  }
  return wrapMessage(message);
};

/**
 * Helpers for common view filtering tasks
 */
module.exports = {
  MAX_STATICVIEW_ITEMS: MAX_STATICVIEW_ITEMS,

  /**
   * Create a filter function for use with Marionette CollectionViews
   * that are part of a config card StaticView. The filter will select
   * only the first MAX_STATICVIEW_ITEMS enabled items in the collection
   * for display
   *
   * Assumes a model attribute named "enabled" with a Boolean value
   *
   * @return {Function}
   */
  getStaticViewItemsCollectionViewFilter: function() {
    return function(child) {
      if (!child.get('enabled')) {
        return false;
      }

      var visible = 0;
      var len = this.collection.length;
      for (var i = 0; i < len && visible < MAX_STATICVIEW_ITEMS; i++) {
        var model = this.collection.at(i);
        if (model === child) {
          return true;
        }
        if (model.get('enabled')) {
          visible++;
        }
      }
      return false;
    };
  },

  /**
   * Custom filter function for use in config card static view
   * templates. Pass a list and it will return a filtered list
   * containing only the first MAX_STATICVIEW_ITEMS enabled items
   *
   * See twig.extras.js for the installed name of this filter.
   *
   * Assumes a serialized data structure with a property named
   * "enabled" that has a Boolean value
   *
   * @param {Array} value
   * @return {Array}
   */
  staticViewItemsTwigFilter: function(value) {
    if (!Array.isArray(value)) {
      throw new Error('filter for first 5 enabled items requires an array');
    }

    var result = [];
    var count = 0;
    var len = value.length;
    for (var i = 0; i < len && count < MAX_STATICVIEW_ITEMS; i++) {
      var item = value[i];
      if (item.enabled) {
        result.push(item);
        count++;
      }
    }

    return result;
  },

  /**
   * Higher-order function - creates a template helper function,
   * configured with a desired set of messages, that accepts a
   * list of items and generates "hint" HTML for display in cases
   * where some items in the list are hidden by one of the
   * filter functions above
   *
   * @param {Object} messages - object with i18n key names for:
   *   - emptyMsgKey: message when the list is empty
   *   - allDisabledMsgKey: message when all items in the list are disabled
   *   - moreMsgKey: message when some items are filtered out
   * @param {Boolean} inline - indicate whether the message should be on the same line
         as the preceeding text
   * @return {Function}
   *   this function takes 1 argument: the list of items to analyze
   *     this list may be an array of plain objects, or a collection of backbone models
   *     each item in the list must have a boolean "enabled" attribute
   */
  getItemCountHelpFn: function(messages, inline) {
    if (inline !== true) {
      inline = false;
    }
    return function(list) {
      var emptyMsgKey = messages.emptyMsgKey;
      var allDisabledMsgKey = messages.allDisabledMsgKey;
      var moreMsgKey = messages.moreMsgKey;

      if (typeof list.filter !== 'function') {
        throw new Error('getItemCountHelpElement requires an Array or Backbone.Collection');
      }

      if (list.length === 0) {
        return wrap(i18n.t(emptyMsgKey), inline, true);
      }

      var enabledCount = list.filter(function(item) {
        if (typeof item.get === 'function') {
          // Backbone model
          return item.get('enabled');
        }
        // plain object
        return item.enabled;
      }).length;

      if (enabledCount === 0) {
        return wrap(i18n.t(allDisabledMsgKey, {count: list.length}), inline, false);
      }

      var hiddenCount = list.length - Math.min(enabledCount, MAX_STATICVIEW_ITEMS);
      if (hiddenCount > 0) {
        return wrap(i18n.t(moreMsgKey, {count: hiddenCount}), inline, false);
      }

      return '';
    };
  },
};
