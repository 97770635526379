'use strict';

var BasePortsStaticView = require('actions/ports/StaticView');

/**
 * Renders a text-only version of the "VLAN ports" component.
 */
module.exports = BasePortsStaticView.extend({
  /**
   * Overrides the supportsTagging property in the template context
   * as WANs do not currently support 802.1q VLAN tagging
   *
   * @return {Object}
   */
  templateContext: function() {
    var context = BasePortsStaticView.prototype.templateContext.apply(this);
    context.supportsTagging = false;
    return context;
  },
});
