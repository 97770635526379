'use strict';

module.exports = {
  /**
   * List of common icon types.
   * @type {Array}
   */
  icons: {
    warning: 'fas fa-exclamation-circle',
    error: 'fas fa-exclamation-triangle',
    success: 'fas fa-check-circle',
  },
};
