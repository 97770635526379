'use strict';

var twig = require('twig').twig;
var tpl = require('components/usageTopApps/top-apps.html');
var TopAppsModel = require('components/usageTopApps/TopAppsModel');
var TopItemsView = require('components/usageShared/TopItemsView');
var ChartView = require('components/usageShared/ChartView');
var KeyView = require('components/usageTopApps/KeyView');
var i18n = require('i18next');

/**
 * Renders the top applications view.
 */
module.exports = TopItemsView.extend({

  template: twig({data: tpl}),

  initialize: function(options) {
    var model = new TopAppsModel({
      deviceMac: options.deviceMac,
    });
    model.deviceStatus = options.deviceStatus;
    this.model = model;
  },

  onRender: function() {
    if (!this.hasValidData.call(this.model.toJSON())) {
      return;
    }

    var chartView = new ChartView({
      collection: this.model.get('items'),
    });
    this.showChildView('chart', chartView);

    var keyView = new KeyView({
      model: this.model,
    });
    this.showChildView('key', keyView);
  },

  onAttach: function() {
    var deviceStatus = this.model.deviceStatus;
    var capability = this.model.requiredCapability;

    if (deviceStatus.hasCapability(capability)) {
      this.loadData();
    } else {
      this.showStatus(i18n.t('usageTopApps.updateRequired'));
      deviceStatus.onCapabilityAdded(this, capability, this.loadData);
    }
  },

  /**
   * Tells the model to load data and updates the view
   *
   * @returns {Promise}
   */
  loadData: function() {
    this.showStatus(i18n.t('usageTopApps.loading'));

    return this.model.fetch()
      .then(function() {
        // success
        this.render();
      }.bind(this), function() {
        // error
        this.showStatus(i18n.t('usageTopApps.error'));
      }.bind(this));
  },
});
