'use strict';

var Marionette = require('backbone.marionette');
var RenderChanges = require('lib/behaviors/RenderChanges');
var portItemTpl = require('actions/speedDuplexSettings/port-setting-item.html');
var twig = require('twig').twig;

var THOUSAND = '1000';
var HUNDRED = '100';
var HALF = 'half';
var FULL = 'full';
var AUTO = 'auto';

/**
 * Renders the speed and duplex form
 */
module.exports = Marionette.View.extend({
  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({allowInlineIncludes: true, data: portItemTpl}),

  className: 'col-md-4',

  ui: {
    speedDropDown: '[name="speed"]',
    duplexDropDown: '[name="duplex"]',
    portError: '.error',
  },

  events: {
    'change @ui.speedDropDown': 'onSpeedSelected',
    'change @ui.duplexDropDown': 'onDuplexSelected',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  initialize: function(options) {
    this.duplexList = options.duplexList;
    this.speedList = options.speedList;
  },

  templateContext: function() {
    return {
      duplexList: this.duplexList,
      speedList: this.speedList,
    };
  },

  onSpeedSelected: function(event) {
    this.ui.portError.bs3ui('clearFieldError');
    var speedValue = event.target.value;
    var currentDuplexValue = this.model.get('configuredDuplex');

    var newDuplexValue;
    if (speedValue === THOUSAND) {
      if (currentDuplexValue !== FULL) {
        newDuplexValue = FULL;
        this.ui.duplexDropDown.val(newDuplexValue);
      }
    } else if (speedValue === AUTO) {
      if (currentDuplexValue !== AUTO) {
        newDuplexValue = AUTO;
        this.ui.duplexDropDown.val(newDuplexValue);
      }
    } else if (speedValue !== AUTO && currentDuplexValue === AUTO) {
      newDuplexValue = FULL;
      this.ui.duplexDropDown.val(newDuplexValue);
    }

    this.model.set(
        {configuredSpeed: speedValue, configuredDuplex: newDuplexValue || currentDuplexValue},
        {commit: true}
      );
  },

  onDuplexSelected: function(event) {
    this.ui.portError.bs3ui('clearFieldError');
    var duplexValue = event.target.value;
    var currentSpeedValue = this.model.get('configuredSpeed');

    var newSpeedValue;
    if (duplexValue === HALF) {
      if (currentSpeedValue === THOUSAND || currentSpeedValue === AUTO) { // Cant have 1000/half
        newSpeedValue = HUNDRED;
        this.ui.speedDropDown.val(newSpeedValue);
      }
    } else if (duplexValue === FULL) {
      if (currentSpeedValue === AUTO) {
        newSpeedValue = THOUSAND;
        this.ui.speedDropDown.val(newSpeedValue);
      }
    } else if (duplexValue === AUTO) {
      if (currentSpeedValue !== AUTO) {
        newSpeedValue = AUTO;
        this.ui.speedDropDown.val(newSpeedValue);
      }
    }

    this.model.set(
        {configuredSpeed: newSpeedValue || currentSpeedValue, configuredDuplex: duplexValue},
        {commit: true}
      );
  },

  onError: function(model, error) {
    if (error.speedMissing) {
      this.ui.portError.bs3ui('showFieldError', error.speedMissing);
    }

    if (error.duplexMissing) {
      this.ui.portError.bs3ui('showFieldError', error.duplexMissing);
    }

    if (error.incorrectSpeedDuplex) {
      this.ui.portError.bs3ui('showFieldError', error.incorrectSpeedDuplex);
    }

    if (error.invalidBondChildPortConfiguration) {
      this.ui.portError.bs3ui('showFieldError', error.invalidBondChildPortConfiguration);
    }

    if (error.incorrectAutoConfiguration) {
      this.ui.portError.bs3ui('showFieldError', error.incorrectAutoConfiguration);
    }
  },
});
