'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('manage/edit/config/group/confirmSave/confirmationDialog.html');
var twig = require('twig').twig;

/**
 * Renders a confirmation dialog.
 */
module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  ui: {
    acceptChanges: '[name="acceptBtn"]',
    cancelChanges: '[name="cancelBtn"]',
  },

  events: {
    'click @ui.acceptChanges': 'onAccept',
    'click @ui.cancelChanges': 'onCancel',
  },

  onAccept: function() {
    this.options.parent.trigger('confirmationDecisionMade', {
      action: this.constructor.ACTIONS.ACCEPT,
      ctx: this,
    });
  },

  onCancel: function() {
    this.options.parent.trigger('confirmationDecisionMade', {
      action: this.constructor.ACTIONS.CANCEL,
      ctx: this,
    });
  },
}, {
  ACTIONS: {
    ACCEPT: 0,
    CANCEL: 1,
  },
});
