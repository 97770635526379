'use strict';

var _ = require('lodash');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var networkUtils = require('lib/network');
var snapshotMixin = require('lib/mixins/snapshot');
var i18n = require('i18next');

/**
 * Represents an individual NAT rule
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({
  /**
   * @member {Object} actions/advancedNat/EditNat#attributes
   * @property {Boolean} remove
   *   Whether the rule should be removed on save
   * @property {string} ip
   * @property {string} srcIp
   * @property {boolean} enabled
   *  enabled or disabled
   */

  _snapshotAttributes: [
    'name',
    'srcIp',
    'srcPort',
    'protocol',
    'destIp',
    'destPort',
    'enabled',
    'order',
    'type',
    'rewrittenIp',
    'rewrittenPort',
    'bypass',
    'remove',
  ],

  defaults: {
    order: 1,
    srcIp: '',
    srcPort: '',
    destIp: '',
    destPort: '',
    rewrittenIp: '',
    rewrittenPort: '',
    protocol: 'tcpudp',
    type: 'snat',
    remove: false,
    bypass: false,
    enabled: true,
  },

  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true) {
      // rules pending removal are never considered invalid
      return;
    }

    if (_.has(attrs, 'srcPort')) {
      if (attrs.srcPort !== '' && !networkUtils.validPort(attrs.srcPort)
            && !networkUtils.validPortRange(attrs.srcPort)) {
        errors.srcPort = i18n.t('actionAdvancedNat.invalidPortRange');
      }
    }

    // Src IP and Dest IP are validated together since there is a dependency between them.
    if (_.has(attrs, 'srcIp') || _.has(attrs, 'destIp')) {
      var srcIp = _.has(attrs, 'srcIp') ? attrs.srcIp : this.get('srcIp');
      var destIp = _.has(attrs, 'destIp') ? attrs.destIp : this.get('destIp');
      if (destIp === '' && srcIp === '') {
        errors.srcIp = i18n.t('actionAdvancedNat.missingSrcOrDestRequired');
      } else if (srcIp !== '' && !_.contains(_.pluck(this.get('netObjs'), 'description'), srcIp)
          && !networkUtils.validIP(srcIp)
          && !networkUtils.validSubnet(srcIp)) {
        errors.srcIp = i18n.t('actionAdvancedNat.badSrcIp');
      }
      if (destIp !== '' && !_.contains(_.pluck(this.get('netObjs'), 'description'), destIp)
            && !networkUtils.validIP(destIp)
            && !networkUtils.validSubnet(destIp)) {
        errors.destIp = i18n.t('actionAdvancedNat.badDestIp');
      }
    }

    if (_.has(attrs, 'destPort')) {
      if (attrs.destPort !== '' && !networkUtils.validPort(attrs.destPort)
          && !networkUtils.validPortRange(attrs.destPort)) {
        errors.destPort = i18n.t('actionAdvancedNat.invalidPortRange');
      }
    }

    // Rewritten IP , rewritten port, and bypass are validated together since they depend on each other.
    if (_.has(attrs, 'rewrittenIp') || _.has(attrs, 'bypass') || _.has(attrs, 'rewrittenPort')) {
      var rewrittenIp = _.has(attrs, 'rewrittenIp') ? attrs.rewrittenIp : this.get('rewrittenIp');
      var rewrittenPort = _.has(attrs, 'rewrittenPort') ? attrs.rewrittenPort : this.get('rewrittenPort');
      var bypass = _.has(attrs, 'bypass') ? attrs.bypass : this.get('bypass');
      if (bypass) {
        if (rewrittenIp !== '') {
          errors.rewrittenIp = i18n.t('actionAdvancedNat.denyBypassAndRewrittenIp');
        }
        if (rewrittenPort !== '') {
          errors.rewrittenPort = i18n.t('actionAdvancedNat.denyBypassAndRewrittenPort');
        }
      } else if (rewrittenIp === '') {
        errors.rewrittenIp = i18n.t('actionAdvancedNat.requireBypassOrRewrite');
      }
      if (rewrittenIp !== '' && !networkUtils.validIP(rewrittenIp) && !networkUtils.validSubnet(rewrittenIp)) {
        errors.rewrittenIp = i18n.t('actionAdvancedNat.badRewrittenIp');
      }
      if (rewrittenPort !== '' && !networkUtils.validPort(rewrittenPort)
          && !networkUtils.validPortRange(rewrittenPort)) {
        errors.rewrittenPort = i18n.t('actionAdvancedNat.invalidPortRange');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return {
        name: 'nat.removeAdvancedNat',
        data: {
          id: this.id,
        },
      };
    }

    // send all tasks, even if unchanged, to preserve order
    var srcNetObj = _.findWhere(this.get('netObjs'), {description: this.get('srcIp')});
    var destNetObj = _.findWhere(this.get('netObjs'), {description: this.get('destIp')});
    var task = {
      name: 'nat.addAdvancedNat',
      data: {
        name: this.get('name'),
        srcIp: srcNetObj ? null : this.get('srcIp'),
        srcSet: srcNetObj ? srcNetObj.id : null,
        srcNetObjDescription: srcNetObj ? srcNetObj.description : null,
        srcPort: this.get('srcPort'),
        protocol: this.get('protocol'),
        destIp: destNetObj ? null : this.get('destIp'),
        destSet: destNetObj ? destNetObj.id.replace(/^cfg_src/, 'cfg_dst') : null,
        destNetObjDescription: destNetObj ? destNetObj.description : null,
        destPort: this.get('destPort'),
        enabled: this.get('enabled'),
        type: this.get('type'),
        rewrittenIp: this.get('rewrittenIp'),
        rewrittenPort: this.get('rewrittenPort'),
        bypass: this.get('bypass'),
      },
    };

    if (!this.isNew()) {
      task.data.id = this.id;
    }

    return task;
  },
});
