'use strict';

var Backbone = require('backbone');

/**
 * Model for a DNA record
 */

module.exports = Backbone.Model.extend({
  defaults: {
    'deviceMac': '',
    'hostname': '',
    'description': '',
    'fwVersion': '',
    'country': '',
    'allowed': false,
    'selected': false,
  },

  _snapshotAttributes: false,

  hasChanges: function() {
    return this.get('selected');
  },

  resetState: function() {
    this.set('selected', false);
  },

  getTask: function() {
    if (this.get('selected')) {
      return {
        'mac': this.get('deviceMac'),
      };
    }
  },
});
