'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('components/modal/genericChildContent.html');
var twig = require('twig').twig;
require('lib/jquery/bootstrapUI');

module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  content: '',

  initialize: function(options) {
    this.content = options.content || '';
  },

  templateContext: function() {
    var context = {
      content: this.content,
    };
    return context;
  },
});
