'use strict';

var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var _ = require('lodash');

/**
 * Model for Router's siteToSiteRekeyTime which is a type of update schedule.
 */
module.exports = ActionItem.extend({
  /**
   * @member #attributes
   * @property {Boolean} enabled
   * @property {Array} hours
   *   site-to-site rekey hour - values 'Midnight' to '2300'
   */

  _snapshotAttributes: ['enabled', 'hours'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  getOnOffState: function() {
    return !!this.get('enabled');
  },

  /**
   * Get the siteToSiteRekeyTime from config, filter out all other types of
   * updates and return an siteToSiteRekeyTime schedule.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var schedules = this.deviceConfig.get('schedule');
      // for now we support only a single site to site rekeytime (though
      // it can be effective on multiple days of the week)
      var siteToSiteRekeyTime = schedules.find({type: 'siteToSiteRekeyTime'});
      var config;
      var defaults = {
        enabled: false,
        hours: '00',
      };

      if (siteToSiteRekeyTime) {
        config = {
          enabled: siteToSiteRekeyTime.get('enabled'),
          hours: siteToSiteRekeyTime.get('hours'),
        };
      }

      return _.defaults({}, config, defaults);
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        // TODO: there are multiple types of schedule, need to filter for specific type to
        // avoid generating extra events.  Using "config.get('schedule').filter({type: 'siteToSiteRekeyTime'})"
        // doesn't work because it returns the wrong Backbone type.
        return config.get('schedule');
      },
      events: 'add remove change',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (!this.get('enabled')) {
      return;
    }

    if (_.has(attrs, 'hours') && !this.isValidHours(attrs.hours)) {
      errors.hours = i18n.t('actionSiteToSiteRekeyTime.errors.badHours');
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @param {String} hours
   * @returns {Boolean}
   */
  isValidHours: function(hours) {
    return this.getTimesList().indexOf(hours) >= 0;
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    return {
      name: 'schedule.setSiteToSiteRekeyTime',
      data: {
        enabled: this.get('enabled'),
        hours: this.get('hours'),
      },
    };
  },

  getTimesList: function() {
    return [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ];
  },
});
