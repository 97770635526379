'use strict';

var AbstractEditAddress = require('actions/staticAddress/AbstractEditAddress');

/**
 * View-Model for creating/editing a LAN subnet (static address).
 */
module.exports = AbstractEditAddress.extend({
  _snapshotAttributes: ['af', 'method', 'address', 'size'],

  defaults: {
    usage: 'router',
    af: 'ipv4',
    role: 'internal',
    method: 'static',
  },
});
