'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/dmz/form.html');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');
require('devbridge-autocomplete');

/**
 * Renders the "dmz" form field.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/dmz/FormView#model
   * @type {actions/dmz/EditDmz}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    'enable': '[name="dmz-enable"]',
    'srcIp': '[name="srcIp"]',
    'ip': '[name="ip"]',
  },

  events: {
    'change @ui.enable': 'updateModel',
    'focus @ui.srcIp': 'getAutocomplete',
    'blur @ui.srcIp': 'updateModel',
    'blur @ui.ip': 'updateModel',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  /**
   * @param {Event} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;

    switch (name) {
      case 'dmz-enable':
        this.model.set({enabled: event.currentTarget.id === 'dmz-enable-yes'});
        break;
      case 'ip':
        this.clearError('ip');
        this.model.set('ip', this.ui.ip.val(), {commit: true});
        break;
      case 'srcIp':
        this.clearError('srcIp');
        this.model.set('srcIp', this.ui.srcIp.val(), {commit: true});
        break;
      default:
        break;
    }
  },

  getAutocomplete: function() {
    var thisView = this;
    this.ui.srcIp.autocomplete({
      lookup: _.pluck(thisView.model.get('netObjs'), 'description'),
      width: 'flex',
      maxHeight: 200,
      onSelect: function(suggestion) {
        thisView.updateModel({
          currentTarget: {
            name: 'srcIp',
            value: suggestion.value,
          },
        });
      },
    });
  },

  /**
   * @param {actions/dmz/EditDmz} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showFieldError', value);
    }, this);
  },

  clearError: function(key) {
    this.ui[key].bs3ui('clearFieldError');
  },
});
