'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('components/tabPanel/tab-panel.html');

/**
 * Renders multiple provided child views in a tabbed layout.
 */
module.exports = Marionette.View.extend({

  template: twig({data: tpl}),

  ui: {
    tabs: '.dui-tabs__item',
  },

  events: {
    'click @ui.tabs': 'handleTabClick',
  },

  regions: function() {
    return _.chain(this.tabs)
      .indexBy('id')
      .mapValues(function(tab) {
        return '.rg-content-' + tab.id;
      })
      .value();
  },

  /**
   * @param {Object} options
   * @property {Array} options.tabs
   *   List of tabs to display, each an object with 'id', 'label'
   * @property {Object} options.views
   *   A map of tab.id => content view
   * @return {Marionette.View}
   */
  constructor: function(options) {
    this.mergeOptions(options, ['tabs', 'views']);
    return Marionette.View.call(this, options);
  },

  templateContext: function() {
    return {
      tabs: this.tabs,
    };
  },

  onRender: function() {
    this.addContentViews();
    this.selectTab(this.tabs[0].id);
  },

  handleTabClick: function(event) {
    this.selectTab(event.currentTarget.dataset.id);
  },

  /**
   * Creates the child views to tab between
   */
  addContentViews: function() {
    var self = this;

    this.tabs.forEach(function(tab) {
      var view = self.views[tab.id];

      self.showChildView(tab.id, view);
    });
  },

  /**
   * Makes a given tab active
   *
   * @param {String} id
   *  id of the tab to select
   */
  selectTab: function(id) {
    var $tabs = this.ui.tabs;

    $tabs.removeClass('dui-tabs__item--active');
    $tabs.filter('[data-id="' + id + '"]').addClass('dui-tabs__item--active');

    // show/hide the correct content region
    _.forEach(this.getRegions(), function(region) {
      region.$el.addClass('hidden');
    });
    this.getRegion(id).$el.removeClass('hidden');
  },

});
