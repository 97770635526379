'use strict';

var $ = require('jquery');
var _ = require('lodash');
var Marionette = require('backbone.marionette');
var i18n = require('i18next');
var twig = require('twig').twig;
var formTpl = require('actions/ports/ports.html');
var tooltipTpl = require('actions/ports/tooltip.html');
var tplHelpers = require('lib/tplHelpers');
var networkHelpers = require('lib/tplHelpers/networkHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
var PortsLib = require('lib/ports');
var Entities = require('html-entities').XmlEntities;
var entities = new Entities();

/**
 * Renders the list of ports (part of the VLAN ports action component).
 *
 * Notes:
 * - In the UI, ports are listed starting at "1" to match the physical
 *   device's ports panel.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/ports/FormView#model
   * @type {actions/ports/EditPorts}
   */

  /**
   * List of ports.
   * @name actions/ports/FormView#collection
   * @type {actions/ports/Ports}
   */

  behaviors: [{
    // This view is unusual in that it maps a collection to a single form
    // view, which requires some extra wiring - see onPortChange/onPortAddRemove
    behaviorClass: RenderChanges,
    debounce: true, // combine changes across multiple ports into 1 render
  }],

  template: twig({data: formTpl}),

  tooltipTemplate: twig({data: tooltipTpl}),

  ui: {
    type: '.type-wrap',
    tagId: '[name="tag-id"]',
    tagIdSection: '.section-tag-id',
    ports: '.port-wrap',
    portContainers: '.port-option',
    portInputs: '.port-wrap > input',
  },

  events: {
    'change @ui.type': 'onTypeChanged',
    'blur @ui.tagId': 'onTagIdChanged',
    'change @ui.ports': 'checkSelectedPort',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  collectionEvents: {
    'change:isSelected': 'onPortToggled',
    'add remove': 'onPortAddRemove',
    'change': 'onPortChange',
  },

  /**
   * Only display ports not reserved for WANs
   *
   * @param {actions/ports/Port} port
   * @return {Boolean}
   */
  portFilter: function(port) {
    return port.id !== 'eth0' && port.id !== 'eth1';
  },

  /**
   * @param {Object} options
   * @return {Marionette.View}
   */
  constructor: function(options) {
    if (options && options.model && _.isUndefined(options.collection)) {
      options.collection = options.model.get('ports');
    }

    return Marionette.View.call(this, options);
  },

  /**
   * Format and filter the ports list
   *
   * @return {Object}
   */
  serializeData: function() {
    var data = Marionette.View.prototype.serializeData.apply(this);

    var ports = this.collection.toJSON();
    ports = ports.filter(this.portFilter);

    return _.extend(data, {
      portsList: PortsLib.splitPortsByLength(ports.filter(function(port) {
        return !port.isBond;
      })),
      bondsList: ports.filter(function(bond) {
        return bond.isBond;
      }),
    });
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    var context = {
      isNew: this.model.isNew,
      supportsTagging: true, // overridden for WANs
      supportsAggregation: true,
      tooltip: this.helperPortTooltip.bind(this),
    };

    return _.extend(context, tplHelpers.apply(this));
  },

  onRender: function() {
    // decorate ports (class names, etc.)
    this.collection.each(this.renderPort.bind(this));
    // bootstrap tooltip plugin
    this.ui.ports.tooltip({html: true, trigger: 'hover'});
  },

  /**
   * Notifies the RenderChanges behavior when ports in the collection come or go
   *
   * @param {actions/ports/Port} model
   * @param {actions/ports/Ports} collection
   * @param {Object} options
   */
  onPortAddRemove: function(model, collection, options) {
    this.triggerMethod('configChange', model, options);
  },

  /**
   * Notifies the RenderChanges behavior when ports in the collection change
   *
   * @param {actions/ports/Port} model
   * @param {Object} options
   */
  onPortChange: function(model, options) {
    this.triggerMethod('configChange', model, options);
  },

  /**
   * When the "isSelected" attribute changes, it means the port state has
   * been toggled. Update its state.
   *
   * @listens this.collection~change:isSelected
   * @param {actions/vlan/Port} portModel
   * @param {Boolean} isSelected
   * @param {Object} options
   */
  onPortToggled: function(portModel, isSelected, options) {
    this.renderPort(portModel);
  },

  /**
   * Handles changes to the network type made via the view
   *
   * @param {Event} ev
   */
  onTypeChanged: function(ev) {
    var isTagged = $(ev.target).is(':checked') && ev.target.value === 'tagged';

    if (isTagged === this.model.get('isTagged')) {
      return;
    }

    this.model.set('isTagged', isTagged);
    this.model.normalizePortSelection(this.portFilter);

    this.ui.tagIdSection.toggleClass('hidden', !isTagged);

    if (isTagged) {
      this.ui.portContainers.removeClass('checkbox').addClass('radio');
      this.ui.portInputs.attr('type', 'radio');
    } else {
      this.ui.portContainers.removeClass('radio').addClass('checkbox');
      this.ui.portInputs.attr('type', 'checkbox');
    }

    this.collection.each(this.renderPort.bind(this));
  },

  /**
   * Handles changes to the tagId made via the view
   *
   * @param {Event} ev
   */
  onTagIdChanged: function(ev) {
    var parsed = parseInt(ev.target.value);
    this.ui.tagId.bs3ui('clearFieldError');
    if (!isNaN(parsed)) {
      this.ui.tagId.val(parsed);
    }
    this.model.set({tagId: parsed}, {commit: true});
  },

  /**
   * Handles changes to port selection made via the view
   *
   * @param {Event} ev
   */
  checkSelectedPort: function(ev) {
    var $input = $(ev.currentTarget).find('input');
    var port;

    if ($input.prop('disabled') === true || $input.parent().hasClass('disabled-port')) {
      return;
    }

    if (ev.type === 'keyup' && !(ev.which === 13 || ev.which === 32)) {
      return;
    }

    this.ui.ports.bs3ui('clearFieldError');

    if (this.model.get('isTagged')) {
      this.collection.forEach(function(port) {
        port.set('isSelected', false);
      });
    }

    port = this.collection.get($input.val());
    port.set('isSelected', $input.prop('checked'));
  },

  /**
   * Builds the tooltip message to indicate the port is unavailable and to
   * which vlan it belongs to.
   *
   * @param {Object} port
   * @return {String}
   */
  helperPortTooltip: function(port) {
    var vlan;
    var data = {
      t: _.bind(i18n.t, i18n),
      cable: i18n.t('actionPorts.portHasCable'),
    };

    // See renderPort(), reservedNative var
    if (!this.model.get('isTagged') && port.nativeTo !== this.model.id) {
      if (!_.isUndefined(port.nativeTo) && _.isUndefined(port.bondId)) {
        vlan = this.model.deviceConfig.get('networks').get(port.nativeTo);
        data.nativeTo = networkHelpers.formatFullName(
          port.nativeTo, entities.decode(vlan.get('description'))
        );
      }
    }

    // See renderPort(), reservedEnslaved var
    if (!_.isUndefined(port.bondId) && port.bondId !== this.model.id) {
      var bond = this.model.deviceConfig.get('nicBonds').get(port.bondId);
      data.bondDescription = bond.get('description');
    }

    if (port.autoNegotiated) {
      data.autoNegotiated = true;
    } else {
      data.speed = port.configuredSpeed;
      data.duplex = port.configuredDuplex;
    }

    // See renderPort(), reservedTagged var
    if (this.model.forBond &&
      _.isArray(port.taggedBy) &&
      port.taggedBy.length > 0 &&
      _.isUndefined(port.bondId)
    ) {
      data.taggedCount = port.taggedBy.length;
    }

    if (port.up === false) {
      data.cable = i18n.t('actionPorts.portNoCable');
    }

    return this.tooltipTemplate.render(data);
  },

  /**
   * Decorates field on error.
   *
   * @param {actions/vlan/EditPorts} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.tagId) {
      this.ui.tagId.bs3ui('showFieldError', error.tagId);
    }

    if (error.notEnoughboundPorts) {
      this.ui.portInputs.bs3ui('showFieldError', error.notEnoughboundPorts);
    }

    if (error.portSpeedDuplexMismatch) {
      this.ui.portInputs.bs3ui('showFieldError', error.portSpeedDuplexMismatch);
    }
  },

  /**
   * Decorates the port to indicate whether its selected, native to another
   * VLAN, etc.
   *
   * @param {actions/ports/Port} portModel
   */
  renderPort: function(portModel) {
    var $port = this.ui.ports.filter('[data-port="' + portModel.id + '"]');
    var $input = $port.find('input');

    // is this port in use by another native LAN, blocking it from use by this native LAN (or bond)?
    var reservedNative = !this.model.get('isTagged') &&
      portModel.has('nativeTo') && portModel.get('nativeTo') !== this.model.id &&
      _.isUndefined(portModel.get('bondId'));

    // is this port enslaved to a bond (other than the bond currently being edited)?
    var reservedEnslaved = !_.isUndefined(portModel.get('bondId')) && portModel.get('bondId') !== this.model.id;

    // is this port in use by a tagged VLAN, blocking it from use in a bond?
    var reservedTagged = this.model.forBond &&
      _.isArray(portModel.get('taggedBy')) &&
      portModel.get('taggedBy').length > 0 &&
      _.isUndefined(portModel.get('bondId'));

    var isSelected = portModel.get('isSelected') === true;
    var isDisabled = reservedNative || reservedEnslaved || reservedTagged;

    $port
      .toggleClass('port-selected', isSelected)
      .toggleClass('disabled-port', isDisabled)
      .attr('data-original-title', this.helperPortTooltip(portModel.toJSON()));

    $input
      .prop('checked', isSelected)
      .prop('disabled', isDisabled);
  },
});
