'use strict';

var MultiActionItem = require('actions/shared/MultiActionItem');
var ActionItem = require('actions/shared/ActionItem');
var DnaSite = require('actions/siteVpn/DnaSite/DnaSiteVpn');
var OtherSite = require('actions/siteVpn/OtherSite/OtherSiteVpn');
var userChannel = require('backbone.radio').channel('userChannel');
var i18n = require('i18next');
var _ = require('lodash');

module.exports = MultiActionItem.extend({

  initialize: function(attributes, options) {
    this.addDeviceStatus(options);
    this.set({supportsNetObj: this.deviceStatus.hasCapability('supportsNetworkObject')}, {fromConfig: true});
    this.deviceStatus.onCapabilityAdded(this, 'supportsNetworkObject', function() {
      this.set({supportsNetObj: true}, {fromConfig: true});
    }.bind(this));
  },

  _snapshotAttributes: [
    'allowedLans', 'allowedStaticRoutes', 'allowedNetObjs',
  ],

  /**
   * Determine if the config being loaded is a DNA or not. Non DNA devices will have
   * an empty remoteMac attr
   *
   * @param {Object} attrs
   * @param {Object} options
   * @return {DnaSite|OtherSite}
   */
  collectionModel: function(attrs, options) {
    if (_.has(attrs, 'remoteMac') && !_.isEmpty(attrs.remoteMac)) {
      return new DnaSite(attrs, options);
    }

    return new OtherSite(attrs, options);
  },

  /**
   * Determine if the card is new.
   *
   * @return {Boolean}
   **/
  isNew: function() {
    return _.isUndefined(this.id);
  },

  /**
   * @param {object} resp
   * @param {object} options
   * @return {*}
   */
  parse: function(resp, options) {
    var isCurrentDeviceSpoke = false;
    var remoteSites = [];
    var allNetObjs = _.map(this.deviceConfig.get('firewall').get('networkObjects').toJSON(), function(obj) {
      return _.pick(obj, 'id', 'description', 'specifications');
    });
    var availableDevices = this._getAvailableDevices();
    if (options && options.fromConfig === true && !this.isNew()) {
      if (!_.isUndefined(this.deviceConfig.get('remoteSites')) &&
          !_.isUndefined(this.id) &&
          !_.isUndefined(this.deviceConfig.get('remoteSites').get(this.id))) {
        remoteSites = this.deviceConfig.get('remoteSites').get(this.id).get('tunnels');
      }

      remoteSites.forEach(function(remoteSite) {
        remoteSite.group = this.id;
        if (_.has(remoteSite, 'remoteMac') && !_.isEmpty(remoteSite.remoteMac)) {
          remoteSite.availableDevices = availableDevices;

          // if any remote sites are set as the hub, then the device we are viewing is a spoke
          if (remoteSite.role === 'hub') {
            isCurrentDeviceSpoke = true;
          }
        }
      }, this);

      remoteSites.forEach(function(remoteSite) {
        remoteSite.isCurrentDeviceSpoke = isCurrentDeviceSpoke;
      });

      return {
        items: remoteSites,
        availableDevices: availableDevices,
        isCurrentDeviceSpoke: isCurrentDeviceSpoke,
        allNetObjs: allNetObjs,
        allowedLans: this._getAllowedLans(),
        allowedStaticRoutes: this._getAllowedStaticRoutes(),
        allowedNetObjs: this._getAllowedNetObjs(),
      };
    }
    if (this.isNew() && resp === null) {
      return {
        items: remoteSites,
        availableDevices: availableDevices,
        isCurrentDeviceSpoke: isCurrentDeviceSpoke,
        allNetObjs: allNetObjs,
        allowedLans: this._getAllowedLans(),
        allowedStaticRoutes: this._getAllowedStaticRoutes(),
        allowedNetObjs: this._getAllowedNetObjs(),
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('remoteSites');
      },
      events: 'update',
    },
    {
      getDispatcher: function(config) {
        return config.get('networks');
      },
      events: 'add remove change',
    },
    {
      getDispatcher: function() {
        return userChannel.request('get:user').get('devices');
      },
      events: 'change:hostname add remove',
    },
  ],

  /**
   * Get the lans that this topology has access to
   * @private
   * @return {Array}
   */
  _getAllowedLans: function() {
    if (this.isNew()) {
      return this.deviceConfig.getVlans({external: false})
      .map(function(lan) {
        return lan.id;
      }, this);
    }
    var topology = this.deviceConfig.get('remoteSites').get(this.id);
    return _.isUndefined(topology) ? [] : topology.get('allowedLans');
  },

  /**
   * Get the static routes that this topology has access to
   * @private
   * @return {Array}
   */
  _getAllowedStaticRoutes: function() {
    if (this.isNew()) {
      return [];
    }
    var topology = this.deviceConfig.get('remoteSites').get(this.id);
    return _.isUndefined(topology) ? [] : topology.get('allowedStaticRoutes');
  },

  /**
   * Get the network objects that this topology has access to
   * @private
   * @return {Array}
   */
  _getAllowedNetObjs: function() {
    if (this.isNew()) {
      return [];
    }
    var topology = this.deviceConfig.get('remoteSites').get(this.id);
    return _.isUndefined(topology) ? [] : topology.get('allowedNetObjs');
  },

  /**
   * Get DNA devices the user can setup a tunnel to
   * @private
   * @return {Object}
   */
  _getAvailableDevices: function() {
    var user = userChannel.request('get:user');
    var thisMac = this.deviceConfig.get('deviceMac').toUpperCase();
    var disallowedMacs = [];
    this.deviceConfig.get('remoteSites').forEach(function(topology) {
      topology.get('tunnels').forEach(function(topology, site) {
        if (site.deviceType === 'dna' && this.id !== topology.id) {
          disallowedMacs.push(site.remoteMac);
        }
      }.bind(this, topology));
    }, this);

    return user.get('devices').map(function(device) {
      return device.pick('deviceMac', 'hostname');
    }).filter(function(device) {
      // can't VPN to yourself
      return device.deviceMac !== thisMac && !_.contains(disallowedMacs, device.deviceMac);
    });
  },

  validate: function(attrs, options) {
    this.trigger('clearValidation');
    var errors = MultiActionItem.prototype.validate.apply(this, arguments) || {};

    var selectedDnas = [];
    var hasDuplicateDna = false;
    if (!_.isUndefined(attrs.items)) {
      attrs.items.forEach(function(item) {
        if (item.has('remoteMac') && !_.isEmpty(item.get('remoteMac'))) {
          if ((selectedDnas.indexOf(item.get('remoteMac')) !== -1) && !item.get('remove')) {
            hasDuplicateDna = true;
          } else if (!item.get('remove')) {
            selectedDnas.push(item.get('remoteMac'));
          }
        }
      });

      if (hasDuplicateDna) {
        errors.duplicateDna = i18n.t('actionSiteVpn.duplicateDna');
      }
      if (attrs.items.length === 0) {
        errors.noneSelected = i18n.t('actionSiteVpn.noneSelected');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Overridden to include the diff of us and items
   *
   * @see actions/shared/MultiActionItem#getSnapshotDiff
   * @return {Boolean|Object}
   */
  getSnapshotDiff: function() {
    var diff = ActionItem.prototype.getSnapshotDiff.apply(this, arguments);
    if (diff === false) {
      return this.get('items').getSnapshotDiff();
    }
    return diff;
  },

  /**
   * Site to site VPN will always completely tear down existing tunnel in the same topology
   * and re-create them for any change, because of this we only need to send a single create job with
   * the peers that need to be created.
   *
   * @returns {{name: string, data: {peers: Array, allowedLans: Array}}}
   */
  getTask: function() {
    var itemTask = MultiActionItem.prototype.getTask.apply(this, arguments);
    var itemData = [];
    if (itemTask !== undefined) {
      itemTask.forEach(function(task) {
        task.data.mac = this.deviceConfig.get('deviceMac');
        task.data.group = this.id;
        itemData.push(task.data);
      }, this);
    }

    return {
      name: 'vpn.setSiteVpnConfigs',
      data: {
        peers: itemData,
        allowedLans: this.get('allowedLans'),
        allowedStaticRoutes: this.get('allowedStaticRoutes'),
        allowedNetObjs: this.get('allowedNetObjs'),
      },
    };
  },
});
