'use strict';

var $ = require('jquery');
var _ = require('lodash');
var Backbone = require('backbone');
var Radio = require('backbone.radio');
var DeviceConfig = require('lib/models/DeviceConfiguration');
var DeviceStatus = require('lib/models/DeviceStatus');
var Jobs = require('lib/collections/Jobs');
var Configurations = require('lib/collections/Configurations');
var ConfigStateModel = require('config/ConfigModel');
var Bandwidth = require('lib/models/Bandwidth');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');
var macChannel = Radio.channel('mac');

/**
 * View-Model for {@link manage/detailsLayout/DeviceDetailsLayout}.
 */
module.exports = Backbone.Model.extend({
  /**
   * @member {Object} manage/detailsLayout/DeviceDetailsLayoutModel#attributes
   * @property {lib/collections/Devices} devicesList
   * @property {lib/collections/Configurations} configurations
   * @property {lib/models/DeviceConfig} deviceConfig
   * @property {lib/models/DeviceStatus} deviceStatus
   * @property {config/ConfigModel} configState
   * @property {lib/models/Bandwidth} bandwidth
   *   Bandwidth data for DNA (contains bandwidth for all items; vlans, connected devices, etc.)
   * @property {Backbone.Model} statusCard
   *   The "statusCard" property found in "fleet-overview" eventsource message.
   * @property {String} deviceMac
   * @property {String} page
   *   The current URL (everything after /<deviceMac>/)
   * @property {Boolean} isLoading
   * @property {Boolean} loadingFailed
   */

  /**
   * @param {Object} deviceMac
   */
  setDevice: function(deviceMac) {
    var statusCard = this.get('devicesList').get(deviceMac).get('statusCard');
    var deviceConfig;
    var deviceStatus;
    var jobs;
    var configurations;
    var bandwidth;
    var attrs = {deviceMac: deviceMac};

    this.unset('deviceMac');

    deviceConfig = new DeviceConfig(attrs);
    deviceStatus = new DeviceStatus(attrs);
    jobs = new Jobs(null, attrs);
    configurations = new Configurations(null, attrs);
    bandwidth = new Bandwidth(attrs);

    this.set({
      deviceMac: deviceMac,
      deviceConfig: deviceConfig,
      deviceStatus: deviceStatus,
      jobs: jobs,
      configurations: configurations,
      bandwidth: bandwidth,
      statusCard: statusCard,
      configState: new ConfigStateModel({deviceMac: deviceMac}),
    });

    deviceConfigChannel.reply({
      'get:config': deviceConfig,
      'get:status': deviceStatus,
      'get:statusCard': statusCard,
    });

    macChannel.trigger('mac:change', deviceMac);
  },

  /**
   * Retrieves critical data required for the
   * device status/management UI to function
   *
   * @param {Object} options
   * @return {jQuery.Promise}
   */
  fetch: function(options) {
    var self = this;
    var promise;

    this.set('isLoading', true);
    this.set('loadingFailed', false);

    var configPromise = this.get('deviceConfig').fetch(options);
    var statusPromise = this.get('deviceStatus').fetch(options);
    // processing the config outline triggers things that assume config and status are already loaded
    var outlineOptions = _.extend({dependencies: [configPromise, statusPromise]}, options);
    var configOutlinePromise = this.get('configState').fetch(outlineOptions);
    var jobsPromise = this.get('jobs').fetch(options);

    promise = $.when(
      configPromise,
      statusPromise,
      configOutlinePromise,
      jobsPromise
    ).catch(function(err) {
      self.set('loadingFailed', true);
      // make sure the rejection gets passed along to anyone down the chain
      return $.Deferred().reject(err);
    });

    promise.always(function() {
      self.set('isLoading', false);
    });

    return promise;
  },

  /**
   * Retrieves bandwidth usage data (not critical
   * for functioning of other UI elements)
   *
   * @return {jQuery.Promise}
   */
  fetchBandwidth: function() {
    return this.get('bandwidth').fetch();
  },

  /**
   * Retrieves past configuration data (not critical
   * for functioning of other UI elements)
   *
   * @return {jQuery.Promise}
   */
  fetchConfigurations: function() {
    return this.get('configurations').fetch();
  },

  /**
   * Process incoming update to deviceConfig.
   *
   * @listens socket~device-configuration
   * @param {Object} newConfig
   */
  onDeviceConfigUpdate: function(newConfig) {
    var deviceConfigModel = this.get('deviceConfig');
    var deviceMac = this.get('deviceMac');
    var options = {overrideImmutability: true};

    if (newConfig.mac.toUpperCase() === deviceMac) {
      deviceConfigModel.set(deviceConfigModel.parse(newConfig, options), options);
    }
  },

  /**
   * Process incoming addition of new deviceConfig.
   *
   * This is separated from onDeviceConfigUpdate
   * so that we can listen at different times
   * based on the state of each model that needs
   * updated.
   *
   * @listens socket~device-configuration
   * @param {Object} newConfig
   */
  onNewDeviceConfig: function(newConfig) {
    var configurations = this.get('configurations');
    var deviceMac = this.get('deviceMac');

    if (newConfig.mac.toUpperCase() === deviceMac) {
      // update our configurations collection with new config
      var existing = _.find(configurations.models, function(config) {
        return config.get('configId') === newConfig.id;
      });

      // Only add non-duplicates
      if (!existing) {
        configurations.addNewConfiguration(newConfig);
      }
    }
  },

  /**
   * Process incoming update to deviceStatus.
   *
   * @listens socket~device-status
   * @param {Object} newStatus
   */
  onDeviceStatusUpdate: function(newStatus) {
    var model = this.get('deviceStatus');
    var deviceMac = this.get('deviceMac');
    var options = {overrideImmutability: true};

    if (newStatus.mac.toUpperCase() === deviceMac) {
      model.set(model.parse(newStatus, options), options);
    }
  },

  /**
   * Process incoming update to configState aka config outline
   *
   * @listens socket~config-outline
   * @param {Array} newOutline
   */
  onConfigOutlineUpdate: function(newOutline) {
    var model = this.get('configState');
    var deviceMac = this.get('deviceMac');
    var routerEntryOutline = _.find(newOutline.system, function(entry) {
      return entry.id === 'router';
    });

    if (_.isUndefined(routerEntryOutline) || routerEntryOutline.mac === deviceMac) {
      model.set(newOutline, {parse: true});
    }
  },

  onBandwidthDataUpdate: function(bandwidth) {
    var model = this.get('bandwidth');
    var deviceMac = this.get('deviceMac');
    var options = {overrideImmutability: true};

    if (bandwidth.id.toUpperCase() === deviceMac) {
      model.set(model.parse(bandwidth, options), options);
    }
  },

});
