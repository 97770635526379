'use strict';

var _ = require('lodash');
var EventLogView = require('components/eventLog/EventLogView');
var twig = require('twig').twig;
var tpl = require('components/eventLog/idp-event-log.html');
var tplHelpers = require('lib/tplHelpers');

/**
 * Renders the contents of an event log from the device.
 */
module.exports = EventLogView.extend({

  template: twig({data: tpl}),

  ruleType: '',
  ruleText: '',
  ruleUrl: '',
  classification: '',
  source: '',
  destination: '',

  setMessage: function(value) {
    if (value === this.message) {
      return;
    }

    this.ui.message.find('.event-log-hint').text(value || '');
    this.ui.message.toggleClass('invisible', !value);

    this.message.ruleType = value.ruleType;
    this.message.ruleText = value.ruleText;
    this.message.ruleUrl = value.ruleUrl;
    this.message.classification = value.classification;
    this.message.source = value.source;
    this.message.destination = value.destination;
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var baseHelpers = tplHelpers.apply(this);

    return _.extend({}, baseHelpers, {
      validData: this.hasValidData,
      ruleType: this.ruleType,
      ruleText: this.ruleText,
      ruleUrl: this.ruleUrl,
      classification: this.classification,
      source: this.source,
      destination: this.destination,
    });
  },

});
