'use strict';

var Marionette = require('backbone.marionette');
var UserView = require('actions/vpn/users/FormUserView');
var UserModel = require('actions/vpn/users/User');
var tpl = require('actions/vpn/users/form.html');
var twig = require('twig').twig;
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

var UsersListView = Marionette.CollectionView.extend({
  childView: UserView,

  // wire child views to ToggleFormOff
  childViewTriggers: {
    'render': 'item:render',
    'onoff:status:change': 'item:onoff:status:change',
  },
});

/**
 * Renders the "Users" form fields.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/vpn/users/FormView#model
   * @type {actions/vpn/users/EditVpnUsers
   */

  behaviors: [
    {
      behaviorClass: ToggleFormOff,
      enableCallback: function(view) {
        this.updateDisabledUsers(view);
      },
    },
  ],

  template: twig({data: tpl}),

  regions: {
    users: '.rg-users',
  },

  ui: {
    add: '.btn-add',
  },

  events: {
    'click @ui.add': 'addUser',
  },

  onRender: function() {
    this.isAddingChildViewsOnRender = true; // see ToggleFormOff.js
    this.initChildViews();
    this.isAddingChildViewsOnRender = false;
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.showChildView('users', new UsersListView({
      collection: this.model.get('items'),
    }));
  },

  /**
   * Add a new blank user to the list of users
   */
  addUser: function() {
    var user = new UserModel();
    this.model.get('items').add(user);
  },

  /**
   * Re-apply the disabled form state to any disabled
   * users after the action as whole becomes enabled
   *
   * @param {Marionette.View} view - view that just became enabled
   */
  updateDisabledUsers: function(view) {
    if (view !== this) {
      // a single user was enabled rather than the whole VPN feature,
      // so we don't need to do anything extra
      return;
    }

    var collectionView = this.getChildView('users');
    var self = this;

    if (collectionView) {
      collectionView.children.each(function(childView) {
        if (childView.isItemOff) {
          self.triggerMethod('childview:item:onoff:status:change', childView);
        }
      });
    }
  },

});
