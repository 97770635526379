'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tplHelpers = require('lib/tplHelpers');
var MultiActionItem = require('actions/shared/MultiActionItem');

/**
 * Base class for action "static" views.
 */
module.exports = Marionette.View.extend({
  className: 'data-view',

  constructor: function(options) {
    if (options && (options.model instanceof MultiActionItem) && _.isUndefined(options.collection)) {
      options.collection = options.model.get('items');
    }

    return Marionette.View.call(this, options);
  },

  onAttach: function() {
    if (this.model) {
      this.listenTo(this.model, 'change child:modify', this.render);
    }

    if (this.collection) {
      this.listenTo(this.collection, 'add remove change', this.render);
    }
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    var context = {};
    return _.extend(context, tplHelpers.apply(this));
  },
});
