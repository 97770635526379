'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var speedDuplexLayoutTpl = require('actions/speedDuplexSettings/speed-duplex-layout.html');
var SpeedDuplexView = require('actions/speedDuplexSettings/SpeedDuplexView');

var PortRowView = Marionette.CollectionView.extend({
  childView: SpeedDuplexView,

  initialize: function(options) {
    this.options = options || {};
  },

  childViewOptions: function(model, index) {
    return {
      duplexList: this.options.duplexList,
      speedList: this.options.speedList,
    };
  },
});

module.exports = Marionette.View.extend({
  template: twig({data: speedDuplexLayoutTpl}),

  regions: {
    topRowPorts: '.top-row-ports',
    bottomRowPorts: '.bottom-row-ports',
  },

  onRender: function() {
    var topRow = new PortRowView({
      collection: this.model.get('items'),
      duplexList: this.model.duplexList,
      speedList: this.model.speedList,
      filter: function(child) {
        // We only want to get all odd port numbers for this row
        return child.get('portNumber') % 2 !== 0;
      },
    });

    var bottomRow = new PortRowView({
      collection: this.model.get('items'),
      duplexList: this.model.duplexList,
      speedList: this.model.speedList,
      filter: function(child) {
        // We only want to get all even port numbers for this row
        return child.get('portNumber') % 2 === 0;
      },
    });

    this.showChildView('topRowPorts', topRow);
    this.showChildView('bottomRowPorts', bottomRow);
  },
});
