'use strict';

var $ = require('jquery');
var twig = require('twig').twig;
var markup = '<div><span class="help-block">{{message|e}}</span></div>';
var template = twig({data: markup});

/**
 * Provides logic for buttons or other simple views to display
 * an adjacent error message.
 */
module.exports = {

  /**
   * Shows the provided message after the view. The message
   * is removed with animation after 10 seconds.
   *
   * @param {String} message
   */
  showError: function(message) {
    this.showStatusMessage(message, true);
  },

  /**
   * Shows the provided message after the view. The message
   * is removed with animation after 10 seconds.
   *
   * @param {String} message
   */
  showSuccess: function(message) {
    this.showStatusMessage(message, false);
  },

  /**
   * Shows the provided message after the view. The message
   * is removed with animation after 10 seconds.
   *
   * @param {String} message
   * @param {Boolean} isError
   */
  showStatusMessage: function(message, isError) {
    this.$message = $(template.render({message: message}));
    this.$message.addClass(isError ? 'has-error' : 'has-success');
    this.$el.after(this.$message);
    this.messageTimeout = setTimeout(this.hideStatusMessage.bind(this, true), 10000);
  },

  /**
   * Hides the displayed status message (if any).
   *
   * @param {Boolean} animated
   *   Whether the removal should be animated (default: false)
   */
  hideError: function(animated) {
    this.hideStatusMessage(animated);
  },

  /**
   * Hides the displayed status message (if any).
   *
   * @param {Boolean} animated
   *   Whether the removal should be animated (default: false)
   */
  hideStatusMessage: function(animated) {
    clearTimeout(this.messageTimeout);
    this.messageTimeout = null;

    if (!this.$message) {
      return;
    }

    var self = this;

    var remove = function() {
      self.$message.remove();
      self.$message = null;
    };

    if (animated) {
      this.$message.slideUp(remove);
    } else {
      remove();
    }
  },

};
