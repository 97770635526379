'use strict';

var _ = require('lodash');
var $ = require('jquery');
var BaseGroup = require('config/Group');
var RenderPositions = require('config/RenderPositions');
var i18n = require('i18next');
var TrafficManagementDisruptionAlert = require('actionGroups/shared/TrafficManagementDisruptionView');
var ConfigMessageTypes = require('manage/edit/ConfigMessageTypes');

/**
 * "Edit LAN" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'vlanDescription',
      position: RenderPositions.header,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'lanPorts',
      position: RenderPositions.first,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'lanAddress',
      position: RenderPositions.middle,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'lanAllowVpn',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'dhcpPool',
      position: RenderPositions.last,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'dhcpRelay',
      position: RenderPositions.last,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
      requireCapabilities: ['supportsDhcpRelay'],
    },
    {
      name: 'dhcpReservations',
      position: RenderPositions.full,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'dhcpAdvOptions',
      position: RenderPositions.full,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'trafficShaping',
      position: RenderPositions.full,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
      requireCapabilities: ['supportsLayer7Shaping'],
    },
  ],
  deleteTaskName: 'vlan.delete',

  shouldMergeTasks: ['vlan.create'],

  defaults: {
    'dhcpOptionPendingDeletionCount': 0,
  },

  initialize: function() {
    var actions = this.get('actions');
    if (!_.isUndefined(actions)) {
      var dhcpOptions = this.get('actions').get('dhcpAdvOptions');
      if (this.actionPresent(dhcpOptions)) {
        this.listenTo(
          this.get('actions').get('dhcpAdvOptions').get('actionModel'),
          'change:pendingDeleteCount',
          this.dhcpOptionMarkedDeletion
        );
      }
    }
  },

  isConnectivityDisrupted: function(type, target) {
    if (type === 'removeAction') {
      if (target.id === 'trafficShaping') {
        return TrafficManagementDisruptionAlert;
      }
      return target.id === 'dhcpPool' || target.id === 'dhcpAdvOptions';
    }

    if (type === 'addAction') {
      if (target === 'trafficShaping') {
        return TrafficManagementDisruptionAlert;
      }
      return target === 'dhcpPool';
    }

    if (type === 'input') {
      // Things that restart udshape
      if (target.name === 'upload' ||
        target.name === 'download' ||
        target.name === 'percent' ||
        target.name === 'priority' ||
        target.name === 'vlan-port') {
        return TrafficManagementDisruptionAlert;
      } else if (target.name === 'dhcpOptionValues') {
        return true;
      }
      return (
        // subnet
        target.name === 'address' ||
        target.name === 'mask' ||
        // DHCP pool
        target.id === 'pool-slider'
      );
    }

    return false;
  },

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return _.indexOf(['vlan2'], this.get('typeId')) >= 0;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.editLan');
  },

  /**
   * @return {String}
   */
  description: function() {
    var lan = this.deviceConfig.get('networks').get(this.get('typeId'));
    return lan !== undefined ? lan.getName() : '';
  },

  /**
   * Request a callback when the description of this group changes
   *
   * @param {Backbone.Events} listener
   * @param {Function} callback
   */
  onDescriptionChanged: function(listener, callback) {
    var lan = this.deviceConfig.get('networks').get(this.get('typeId'));
    if (lan) {
      lan.onNameChanged(listener, callback);
    }
  },

  /**
   * Overridden to check for an empty LAN static address.
   *
   * @return {Boolean}
   */
  isMisconfigured: function() {
    // This check is needed to guard against subclasses inheriting this function
    if (this.get('type') === 'editLan') {
      var vlan = this.get('typeId');
      var ipconflict = this.deviceConfig.get('networks').get(vlan).get('ipconflict');
      if (ipconflict) {
        return true;
      }
    }
    return false;
  },

  /**
   * Overridden to include the "id" and "role".
   *
   * @return {Object}
   */
  _getTasks: function() {
    var tasks = BaseGroup.prototype._getTasks.apply(this, arguments);
    var vlanTask = _.find(tasks, {name: 'vlan.create'});

    var typeId = this.get('typeId');
    if (!_.isUndefined(vlanTask)) {
      vlanTask.data.id = typeId;
      vlanTask.data.role = 'internal';
    }

    if (this.get('pendingDelete') === true) {
      // remove associated wifi networks and static routes as well
      var wifis = this.deviceConfig.getWifisForVlan(this.get('typeId'));
      tasks = wifis.map(function(wifi) {
        return {
          name: 'wifi.delete',
          data: {
            id: wifi.id,
          },
        };
      }).concat(tasks);
      var staticRoutes = this.deviceConfig.getStaticRoutesForVlan(this.get('typeId'));
      tasks = staticRoutes.map(function(route) {
        return {
          name: 'staticRoute.deleteStaticRoute',
          data: {
            id: route.id,
          },
        };
      }).concat(tasks);
    }

    return tasks;
  },

  /**
   * Overridden to prevent DHCP relay from being configured together with a DHCP pool or reservation
   *
   * @return {Object}
   */
  _validateActions: function() {
    var self = this;
    return BaseGroup.prototype._validateActions.apply(this, arguments).then(function(result) {
      var errorCode = self.validateDhcpActions(self.get('actions'));
      if (errorCode > 0) {
        return $.Deferred().reject({type: ConfigMessageTypes.DHCP_ERROR, 'errorCode': errorCode});
      }
      return result;
    });
  },

  validateDhcpActions: function(actions) {
    var dhcpRelay = actions.get('dhcpRelay');
    var dhcpPool = actions.get('dhcpPool');
    var dhcpReservations = actions.get('dhcpReservations');
    var dhcpOptions = actions.get('dhcpAdvOptions');

    if (this.actionPresent(dhcpRelay)) {
      // dhcpPool, dhcpAdvOptions nor dhcpReservations may be configured.
      if (this.actionPresent(dhcpPool) || this.actionPresent(dhcpReservations || this.actionPresent(dhcpOptions))) {
        return 1;
      }
    } else if (
      dhcpOptions &&
      !(dhcpOptions.get('actionModel').get('pendingEmptying') || dhcpOptions.get('actionModel').get('pendingDelete')) &&
      !this.actionPresent(dhcpPool)) {
      // dhcpOptions need dhcpPool
      return 2;
    }

    return 0;
  },

  /**
   * Determines whether the given action is configured for the action group.
   *
   * @param {Object | null} object: An action belonging to this actionGroup
   * @return {Boolean}
   */
  actionPresent: function(object) {
    return object && !(object.get('actionModel').get('pendingDelete'));
  },

  dhcpOptionMarkedDeletion: function() {
    var dhcpOptions = this.get('actions').get('dhcpAdvOptions');
    if (this.actionPresent(dhcpOptions)) {
      this.set('dhcpOptionPendingDeletionCount', dhcpOptions.get('actionModel').get('pendingDeleteCount') > 0);
    }
  },
});
