'use strict';

var _ = require('lodash');
var ConfigItem = require('lib/models/ConfigItem');

/**
 * Vlan-specific View model representing a single port.
 */
module.exports = ConfigItem.extend({
  /**
   * @name actions/vlan/Port#attributes
   * @type {Object}
   * @property {String} id
   *   As it relates to VLANs, the interface is the physical port (e.g. eth0).
   * @property {String} nativeTo
   *   Indicates which vlan the port is native to.
   * @property {Array} taggedBy
   *   Indicates which vlans the port is tagged by.
   * @property {Number} portNumber
   *   The port number displayed on the router.
   * @property {Boolean} isSelected
   *   View-specific property to indicate which ports are selected for the
   *   associated VLAN.
   */

  _snapshotAttributes: ['isSelected'],

  defaults: {
    isSelected: false,
  },

  /**
   * @param {Object} options
   * @return {Object}
   */
  toJSON: function(options) {
    var data = ConfigItem.prototype.toJSON.apply(this, arguments);

    if (options && options.caching) {
      data = _.omit(data, 'portNumber', 'up');
    }

    return data;
  },
});
