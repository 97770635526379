'use strict';

var GroupView = require('manage/edit/config/group/GroupView');
var i18n = require('i18next');

/**
 * Custom Group View for the Editing / Creating of a new Port Aggregation config card.
 */
module.exports = GroupView.extend({
  getRemovalSubMessage: function() {
    if (!this.isRemovable()) {
      var vlans = this.model.deviceConfig.getVlansAssociatedToBond(this.model.get('typeId'));

      if (vlans.length === 1) {
        return i18n.t('nicBond.bondInUse', {vlanName: vlans[0].get('description')});
      }

      return i18n.t('nicBond.bondInUseMultipleTimes');
    }

    // by the time removal of a bond is allowed, it is guaranteed to have been disassociated
    // from all LANs, meaning there are essentially no additional effects from removing it
    return null;
  },

  /**
   * @returns {boolean}
   */
  getRemovalIsDangerous: function() {
    return true;
  },

  /**
   * Determine if this config card is removable based on whether a bond is associated to a vlan.
   * @returns {boolean}
   */
  isRemovable: function() {
    var associatedToVlans = this.model.deviceConfig.getVlansAssociatedToBond(this.model.get('typeId'));
    return associatedToVlans.length === 0;
  },
});
