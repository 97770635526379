'use strict';

var Marionette = require('backbone.marionette');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/dhcpAdvOptions/static.html');
var twig = require('twig').twig;
var DHCPOptionView = require('actions/dhcpAdvOptions/DHCPOption/StaticDHCPOptionView');

var DHCPOptionListView = Marionette.CollectionView.extend({
  childView: DHCPOptionView,
});

/**
 * Renders a text-only version of the dhcp options form action component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/dhcpAdvOptions/StaticView#model
   * @type {actions/dhcpAdvOptions/EditDhcpAdvOptions}
   */

  template: twig({data: tpl}),

  regions: {
    options: '.rg-dhcp-options-static',
  },

  onAttach: function() {
    BaseStaticView.prototype.onAttach.apply(this, arguments);
  },

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.showChildView('options', new DHCPOptionListView({
      collection: this.model.get('items'),
    }));
  },
});
