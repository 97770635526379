'use strict';

var _ = require('lodash');
var i18n = require('i18next');

/**
 * Common functions to help format Wi-Fi data.
 */
module.exports = {
  /**
   * Gets the WiFi network's description, which is a combination of its SSID
   * and, if provided, description.
   *
   * @param {String} ssid
   * @param {String} description
   * @return {String}
   */
  formatFullName: function(ssid, description) {
    if (_.isEmpty(description)) {
      return ssid;
    }

    return i18n.t('wifiDetails.wifiFullName', {
      ssid: ssid,
      description: description,
    });
  },
};
