'use strict';

var i18n = require('i18next');
var EditDhcpAdvOptions = require('actions/dhcpAdvOptions/EditDhcpAdvOptions');
var FormView = require('actions/dhcpAdvOptions/FormView');
var StaticView = require('actions/dhcpAdvOptions/StaticView');

/**
 * Action configuration for the DHCP Advanced Option action component.
 */
module.exports = {
  title: i18n.t('actionDhcp.dhcpAdvOptions'),
  Model: EditDhcpAdvOptions,
  FormView: FormView,
  TextView: StaticView,
};
