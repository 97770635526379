'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/trafficPolicyRules/rule.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');

/**
 * Renders the editable form elements for a single traffic policy rule.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/trafficPolicy/FormView#model
   * @type {actions/trafficPolicy/Rule}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    ruleId: '[name="ruleId"]',
    order: '[name="order"]',
    description: '[name="description"]',
    action: '[name="action"]',
    srcPort: '[name="srcPort"]',
    protocol: '[name="protocol"]',
    protocolNumber: '[name="protocolNumber"]',
    destIp: '[name="destIp"]',
    destPort: '[name="destPort"]',
    srcIp: '[name="srcIp"]',
    remove: '.btn-remove',
    messages: '.help-block',
  },

  events: {
    'change @ui.order': 'updateModel',
    'blur @ui.description': 'updateModel',
    'blur @ui.srcPort': 'updateModel',
    'change @ui.action': 'updateModel',
    'change @ui.protocol': 'handleProtocolChange',
    'blur @ui.protocolNumber': 'updateModel',
    'blur @ui.destIp': 'updateModel',
    'blur @ui.destPort': 'updateModel',
    'blur @ui.srcIp': 'updateModel',
    'click @ui.remove': 'handleRemoveButton',
  },

  modelEvents: {
    'change:remove': 'render',
    'invalid': 'onError',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var context = {
      ruleCount: this.setCurrentRuleCount(this.model.collection.models),
      ruleOrder: this.model.get('order'),
    };
    return _.extend(context, tplHelpers.apply(this));
  },

  /**
   * Sync changes in UI input elements to the model and trigger validation
   *
   * @param {Object} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'order':
        // a little manipulation so that sort handles entries with the same order value
        if (value < parseInt(this.model.get('order'))) {
          value--;
        } else {
          value++;
        }
        this.model.set({order: value});
        break;

      case 'description':
        this.clearError('description');
        this.model.set({description: value}, {commit: true});
        break;

      case 'action':
        this.clearError('action');
        this.model.set({action: value}, {commit: true});
        break;

      case 'protocolNumber':
        this.clearError('protocolNumber');
        this.model.set({protocolNumber: value}, {commit: true});
        break;

      case 'srcPort':
        this.clearError('srcPort');
        this.model.set({srcPort: value}, {commit: true});
        break;

      case 'destIp':
        this.clearError('destIp');
        this.model.set({destIp: value}, {commit: true});
        break;

      case 'destPort':
        this.clearError('destPort');
        this.model.set({destPort: value}, {commit: true});
        break;

      case 'srcIp':
        this.clearError('srcIp');
        this.model.set({srcIp: value}, {commit: true});
        break;

      default:
        break;
    }
  },

  /**
   * handle 'Other' protocol selection
   *
   * @param {Object} event
   */
  handleProtocolChange: function(event) {
    var value = event.currentTarget.value;
    this.clearError('protocolNumber');

    // clear the value in protocolNumber when Other is not selected
    if (value !== 'other') {
      this.model.set({protocolNumber: null}, {commit: true});
    }

    this.model.set({proto: value}, {commit: true});
    this.triggerMethod('needsRender');
  },

  /**
   * Remove this rule
   */
  handleRemoveButton: function() {
    if (this.model.isNew()) {
      // remove never-saved rules immediately
      this.model.collection.remove(this.model);
    } else {
      // flag existing rules for deletion upon save
      var shouldRemove = !this.model.get('remove');
      if (shouldRemove) {
        // if you ask to remove an existing rule, roll back any local changes
        // (otherwise it gets really confusing what you're actually removing)
        this.model.applySnapshot();
      }
      this.model.set({remove: shouldRemove});
      this.trigger('initialize');
    }
  },

  /**
   * Decorates the view to indicate error.
   *
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showFieldError', value);
    }, this);
  },

  /**
   * Remove an error message from the view (if present)
   *
   * @param {String} key
   *   Identifier for the error message
   */
  clearError: function(key) {
    this.ui[key].bs3ui('clearFieldError');
  },

  setCurrentRuleCount: function(ruleModels) {
    var ruleCount = 0;
    _.forEach(ruleModels, function(rule) {
      if (rule.attributes.remove === false) {
        ruleCount++;
        rule.set('order', ruleCount.toString(), {silent: true});
      }
    });
    return ruleCount;
  },
});
