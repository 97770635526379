'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/conditionalDns/rule/staticRule.html');
var twig = require('twig').twig;

/**
/**
 * Renders a text-only version of an individual conditional DNS forwarding rule.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/conditionalDns/Rule/StaticRuleView#model
   * @type {actions/conditionalDns/EditConditionalDns}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return baseHelpers;
  },
});
