'use strict';

var Backbone = require('backbone');
var i18n = require('i18next');
var GroupAlertView = require('manage/edit/config/group/groupAlerts/GroupAlertView');
var Radio = require('backbone.radio');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');

var staticProps = {
  wifiDisabledAlertId: 'wifiDisabled',
};

/**
 * Custom GroupAlertView for the "wifi is disabled" warning, which contains a
 * button to focus the Global Wifi Settings config card.
 */
module.exports = GroupAlertView.extend({
  events: {
    'click a': 'focusGlobalWifiSettings',
  },

  /**
   * @param {Object} options
   * @property {Backbone.Radio} options.navChannel
   *   Private radio channel used by the toolbox and config cards.
   *   It is used in this View to focus the Global WiFi Settings config card.
   */
  initialize: function(options) {
    this.mergeOptions(options, ['navChannel']);

    this.model = new Backbone.Model({
      id: staticProps.wifiDisabledAlertId,
      msg: this.getWifiDisabledMsg(),
    });
  },

  /**
   * @param {Event} ev
   */
  focusGlobalWifiSettings: function(ev) {
    // navigate within the app (using code below) instead of a full page load
    ev.preventDefault();

    deviceConfigChannel.request('goto:group', 'wifiGlobal');
  },

  /**
   * Gets the "wifi is disabled" message.
   *
   * @return {String}
   */
  getWifiDisabledMsg: function() {
    var href = deviceConfigChannel.request('get:group:url', 'wifiGlobal');
    var link = '<a href="' + href + '" class="link-inline">' + i18n.t('groups.wifiGlobal') + '</a>';
    return i18n.t('actionWifi.wifiDisabledWarning', {link: link});
  },
}, staticProps);
