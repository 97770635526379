'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var itemTpl = require('manage/details/ports/native/native-port.html');

/**
 * Displays a native ports status.
 */
module.exports = Marionette.View.extend({
  template: twig({data: itemTpl}),

  modelEvents: {
    'change': 'render',
  },
});
