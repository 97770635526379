'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/intrusionDetection/form.html');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

/**
 * Renders the "intrusionDetection" form field.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/intrusionDetection/FormView#model
   * @type {actions/intrusionDetection/EditIntrusionDetection}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    'enable': '#intrusion-detection-enable-yes',
    'disable': '#intrusion-detection-enable-no',
    'detect': '#intrusion-detection-prevention-detect',
    'prevent': '#intrusion-detection-prevention-prevent',
    'loglow': '#intrusion-detection-sensitivity-low',
    'loghigh': '#intrusion-detection-sensitivity-high',
    'packet': '#intrusion-detection-inspection-mode-packet',
    'flow': '#intrusion-detection-inspection-mode-flow',
  },

  events: {
    'change @ui.enable': 'setEnabled',
    'change @ui.disable': 'setEnabled',
    'change @ui.detect': 'setPrevention',
    'change @ui.prevent': 'setPrevention',
    'change @ui.loglow': 'setSensitivity',
    'change @ui.loghigh': 'setSensitivity',
    'change @ui.packet': 'setInspectionMode',
    'change @ui.flow': 'setInspectionMode',
  },

  /**
   * @param {Event} ev
   */
  setEnabled: function(ev) {
    if (this.ui.enable.is(':checked')) {
      this.model.set('enabled', true);
    } else {
      this.model.set('enabled', false);
    }
  },

  /**
   * @param {Event} ev
   */
  setPrevention: function(ev) {
    if (this.ui.detect.is(':checked')) {
      this.model.set('prevention', false);
    } else {
      this.model.set('prevention', true);
    }
  },

  /**
   * @param {Event} ev
   */
  setSensitivity: function(ev) {
    if (this.ui.loglow.is(':checked')) {
      this.model.set('sensitivity', 'low');
    } else {
      this.model.set('sensitivity', 'high');
    }
  },

  /**
   * @param {Event} ev
   */
  setInspectionMode: function(ev) {
    if (this.ui.packet.is(':checked')) {
      this.model.set('inspectionLimit', '0');
    } else {
      this.model.set('inspectionLimit', '2097152');
    }
  },
});
