'use strict';

var _ = require('lodash');
var $ = require('jquery');
var BaseGroup = require('config/Group');
var RenderPositions = require('config/RenderPositions');
var i18n = require('i18next');
var BondInterruptionAlertView = require('actionGroups/shared/BondInterruptionAlertView');
var ConfigMessageTypes = require('manage/edit/ConfigMessageTypes');

var staticProps = {
  capabilityRequired: 'supportsLacpNicBonding',
};

module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'bondDescription',
      position: RenderPositions.header,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'bondPorts',
      position: RenderPositions.first,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  deleteTaskName: 'nicBond.delete',

  shouldMergeTasks: ['nicBond.create'],

  /**
   * @returns {boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * @returns {boolean}
   */
  isRequired: function() {
    return false;
  },

  isConnectivityDisrupted: function(type, target) {
    if (type === 'removeAction') {
      return BondInterruptionAlertView;
    }

    // Any type of input may cause a bond interruption because the system mac address is always
    // randomly generated and when the device does a comparison between the value and whats currently there
    // it will rebuild the bond.
    if (type === 'input') {
      return BondInterruptionAlertView;
    }
  },

  /**
   * @returns {*}
   */
  title: function() {
    return i18n.t('groups.editNicBond');
  },

  /**
   * Request a callback when the description of this group changes
   *
   * @param {Backbone.Events} listener
   * @param {Function} callback
   */
  onDescriptionChanged: function(listener, callback) {
    var nicBond = this.deviceConfig.get('nicBonds').get(this.get('typeId'));
    if (nicBond) {
      listener.listenTo(nicBond, 'change:description', callback.bind(listener));
    }
  },

  /**
   * @returns {string}
   */
  description: function() {
    var nicBond = this.deviceConfig.get('nicBonds').get(this.get('typeId'));
    return !_.isUndefined(nicBond) ? nicBond.get('description') : '';
  },

  /**
   * Overridden to ensure that a user can not save a new configuration if the device downgrades for
   * whatever reason.
   *
   * @return {Object}
   */
  _validateActions: function() {
    if (!this._isSupported()) {
      return $.Deferred().reject({type: ConfigMessageTypes.CAPABILITY_UNSUPPORTED});
    }

    return BaseGroup.prototype._validateActions.apply(this, arguments).then(function(result) {
      return result;
    });
  },

  /**
   * Used to help in unit testing.
   *
   * @returns {Boolean}
   * @private
   */
  _isSupported: function() {
    return this.deviceStatus.hasCapability(staticProps.capabilityRequired);
  },
}, staticProps);
