'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/traceroute/form.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the "traceroute" form field.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/traceroute/FormView#model
   * @type {actions/traceroute/Traceroute}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    destIp: '[name="destIp"]',
    iface: '[name="iface"]',
    probes: '[name="probes"]',
    dontFragment: '[name="dontFragment"]',
    messagesDestIp: '.error-destIp',
    messagesProbes: '.error-probes',
  },

  events: {
    'blur @ui.destIp': 'updateModel',
    'change @ui.iface': 'updateModel',
    'blur @ui.probes': 'updateModel',
    'change @ui.dontFragment': 'updateModel',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var context = {
      wanList: this.model.get('wans'),
      lanList: this.model.get('lans'),
    };
    return _.extend(context, tplHelpers.apply(this));
  },

  /**
   * @param {Event} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'destIp':
        this.clearError('destIp');
        this.model.set({destIp: value}, {commit: true});
        break;

      case 'iface':
        this.model.set({iface: value});
        break;

      case 'probes':
        this.clearError('probes');
        this.model.set({probes: value}, {commit: true});
        break;

      case 'dontFragment':
        this.model.set({dontFragment: event.currentTarget.checked});
        break;

      default:
        break;
    }
  },

  /**
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    var errorMessages = {
      destIp: this.ui.messagesDestIp,
      probes: this.ui.messagesProbes,
    };
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, errorMessages[key]);
    }, this);
  },

  /**
   * Remove an error message from the view (if present)
   *
   * @param {String} key
   *   Identifier for the error message
   */
  clearError: function(key) {
    var errorMessages = {
      destIp: this.ui.messagesDestIp,
      probes: this.ui.messagesProbes,
    };
    this.ui[key].bs3ui('clearGroupedFieldError', key, errorMessages[key]);
  },

  /**
   * Because of the way the WANs and LANs are looped over in the HTML,
   * whenever the form loses focus the iface will display as automatic,
   * even when the model still retains its value.
   *
   * This will manually trigger the model to update on every render of the form
   * to auto so that the display and model match
   */
  onBeforeRender: function() {
    this.model.set('iface', 'auto');
  },
});
