'use strict';

var MultiActionItem = require('actions/shared/MultiActionItem');
var EditReservation = require('actions/dhcpReservations/Reservation');

/**
 * Model for creating/managing DHCP reservations
 *
 * Note, DHCP is unimplemented for IPv6 at this time.
 *
 */
module.exports = MultiActionItem.extend({

  collectionModel: EditReservation,

  defaults: {
    pendingDelete: false,
  },

  _snapshotAttributes: [
    'pendingDelete',
  ],

  /**
   * Overridden to always return false
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var reservations = this.deviceConfig.get('dhcpReservations').toJSON();

      return {
        items: this.getReservations(reservations),
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('dhcpReservations');
      },
      events: 'update',
    },
  ],

  /**
   * Only returns the reservations for each VLAN, not all
   *
   * @param {Array} reservations
   * @return {Array} reservations
   */
  getReservations: function(reservations) {
    var self = this;

    return reservations.filter(function(obj) {
      if (obj.vlanId === self.id) {
        obj.lanIp = self.getDependency('lanAddress');
        return true;
      }

      return false;
    });
  },

  getTask: function() {
    var tasks = [];

    if (this.get('pendingDelete') === true) {
      this.get('items').forEach(function(obj) {
        tasks.push({
          name: 'dhcpReservation.delete',
          data: {
            reservationId: obj.id,
          },
        });
      });

      return tasks;
    }

    return MultiActionItem.prototype.getTask.apply(this, arguments);
  },

  /**
   * Overridden to get the diff of the items collection
   *
   * @return {Boolean|Object}
   */
  getSnapshotDiff: function() {
    return this.get('items').getSnapshotDiff() || this.get('pendingDelete') === true;
  },

  /**
   * Adds a new reservation
   */
  addReservation: function() {
    var reservation = new EditReservation({
      lanIp: this.getDependency('lanAddress'),
    });

    this.get('items').add(reservation);
  },
});
