'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var noSnapshotMixin = require('lib/mixins/noSnapshot');
var networkUtils = require('lib/network');
var i18n = require('i18next');

/**
 * Model for Traceroute Test.
 */
module.exports = ActionItem.extend(noSnapshotMixin).extend({
  /**
   * @member {Object} actions/traceroute/Traceroute#attributes
   * @property {string} destIp
   * @property {string} iface
   * @property {string} probes
   * @property {boolean} dontFragment
   * @property {string} result
   */

  defaults: {
    destIp: null,
    iface: 'auto',
    probes: '2',
    dontFragment: false,
    result: i18n.t('traceroute.defaultResult'),
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * Parse available interfaces.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      return {
        wans: this.deviceConfig.getWanPorts(),
        lans: this.deviceConfig.getLanAddresses(),
      };
    }
    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks');
      },
      events: 'add remove change:addressMap change:portsMap change:description',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'destIp')) {
      if (!networkUtils.validHostname(attrs.destIp)
          && !networkUtils.validIP(attrs.destIp)) {
        errors.destIp = i18n.t('traceroute.invalidIp');
      }
    }

    if (_.has(attrs, 'probes')) {
      if (!/^[1-5]$/.test(attrs.probes)) {
        errors.probes = i18n.t('traceroute.invalidProbes');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    return {
      name: 'operatorTools.traceroute',
      data: {
        destIp: this.get('destIp'),
        iface: this.get('iface'),
        probes: this.get('probes'),
        dontFragment: this.get('dontFragment'),
      },
    };
  },
});
