'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/dhcpPool/form.html');
var i18n = require('i18next');
var twig = require('twig').twig;
require('lib/jquery/bootstrapUI');
var networkUtils = require('lib/network');
var Slider = require('bootstrap-slider');
var RemoveConfig = require('manage/edit/config/action/removeConfig/RemoveConfig');
var RenderChanges = require('lib/behaviors/RenderChanges');
var tplHelpers = require('lib/tplHelpers');

/**
 * Renders the "dhcp pool" form action component.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/dhcpPool/FormView#model
   * @type {actions/dhcpPool/EditDhcpPool}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
      debounce: true, // combine with renders for pool transposition (see onSubnetChanged)
    },
    {
      behaviorClass: RemoveConfig,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    startIp: '[name="startIp"]',
    endIp: '[name="endIp"]',
    dummySliderInput: '[name="pool"]',
    poolSlider: '.pool-slider',
    numIps: '.num-ips',
    ipWarning: '.ip-warning',
    dhcpPoolLabel: '.control-label',
  },

  events: {
    'blur @ui.startIp': 'onRangeInputChange',
    'blur @ui.endIp': 'onRangeInputChange',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  /**
   * @param {Event} ev
   */
  onRangeInputChange: function(ev) {
    this.parseInputs();
    this.sanitizeInputs();
    this.updateModel();

    var poolRange = [this.inputs.startLong, this.inputs.endLong];
    var sliderRange = [this.inputs.startLong, this.inputs.endLong + 1]; // see note in initSlider()
    this.poolSlider.setValue(sliderRange);
    this.updatePoolInfo(poolRange);
    this.checkForOverlappingRouterAddress(poolRange);
  },

  parseInputs: function() {
    var startString = this.ui.startIp.val();
    var endString = this.ui.endIp.val();

    var startLong = networkUtils.Ip.ip2long(startString);
    var endLong = networkUtils.Ip.ip2long(endString);

    this.inputs = {
      startString: startString,
      endString: endString,
      startLong: startLong,
      endLong: endLong,
    };
  },

  sanitizeInputs: function() {
    var range = this.model.getCurrentBoundary();

    if (this.inputs.startLong && this.inputs.endLong &&
        this.inputs.startLong > this.inputs.endLong) {
      this.swapStartAndEnd();
    }

    // If the user inputs say, '500' for an octet the long gets calculated correctly, but it looks weird
    // Re-calculating the string will fix any formatting issues.
    this.inputs.startString = networkUtils.Ip.long2ip(this.inputs.startLong);
    this.inputs.endString = networkUtils.Ip.long2ip(this.inputs.endLong);

    if (!this.inputs.startLong || this.inputs.startLong < range[0]) {
      this.inputs.startLong = range[0];
      this.inputs.startString = networkUtils.Ip.long2ip(range[0]);
    }

    if (!this.inputs.endLong || this.inputs.endLong > range[1]) {
      this.inputs.endLong = range[1];
      this.inputs.endString = networkUtils.Ip.long2ip(range[1]);
    }

    this.ui.startIp.val(this.inputs.startString);
    this.ui.endIp.val(this.inputs.endString);
  },

  swapStartAndEnd: function() {
    var tmp = this.inputs.startLong;
    this.inputs.startLong = this.inputs.endLong;
    this.inputs.endLong = tmp;

    tmp = this.inputs.startString;
    this.inputs.startString = this.inputs.endString;
    this.inputs.endString = tmp;
  },

  updateModel: function() {
    this.ui.endIp.bs3ui('clearFieldError');
    this.model.set({
      startIp: this.inputs.startString,
      endIp: this.inputs.endString,
    }, {commit: true});
  },

  /**
   * @param {actions/dhcpPool/EditDhcpPool} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    // this is a last-resort thing - normally this view tries
    // to force all input into a valid range, but just in case
    // something slips past, we should show a message
    if (error.range) {
      this.ui.endIp.bs3ui('showFieldError', error.range);
    }
    // we don't handle the error for invalid subnet here, because
    // that comes from another action that should be responsible
    // for showing that information
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.setDefaultPool();

    _.bindAll(this,
      'updatePoolInfo', 'checkForOverlappingRouterAddress'
    );

    this.listenTo(this.model, {
      'change:subnet': this.onSubnetReplaced,
    });
    this.listenTo(this.model.get('subnet'), {
      'change:address change:size': this.onSubnetChanged,
    });
  },

  setDefaultPool: function() {
    var usableRange = this.model.getCurrentBoundary();

    if (!(this.model.has('startIp') && this.model.has('endIp'))) {
      // Fill the pool (minus one address at start for router)
      this.model.set({
        startIp: networkUtils.Ip.long2ip(usableRange[0] + 1),
        endIp: networkUtils.Ip.long2ip(usableRange[1]),
      });
    }
  },

  templateContext: function() {
    return _.extend(tplHelpers.apply(this), {
      pendingDeleteMsg: this.model.getDeleteMsg.bind(this.model),
      usableRange: this.tplFormattedUsableRange.bind(this),
    });
  },

  onRender: function() {
    if (this.model.get('pendingDelete') === true) {
      return;
    }

    if (this.model.hasValidSubnet()) {
      this.ui.dhcpPoolLabel.removeClass('hidden');
      this.ui.startIp.removeClass('hidden');
      this.ui.endIp.removeClass('hidden');
      this.initSlider();
      this.checkForOverlappingRouterAddress(_.map(this.model.pick('startIp', 'endIp'), networkUtils.Ip.ip2long));
    } else {
      this.ui.dhcpPoolLabel.addClass('hidden');
      this.ui.startIp.addClass('hidden');
      this.ui.endIp.addClass('hidden');
      var msg = i18n.t('actionDhcp.noSubnetDefined');
      this.ui.ipWarning.bs3ui('inlineStatus', this.ui.ipWarning, 'warning', msg);
    }
  },

  /**
   * Handles subnet being swapped out for a different object
   *
   * @param {actions/dhcpPool/EditDhcpPool} model
   * @param {ReadOnly.Model} newSubnet
   */
  onSubnetReplaced: function(model, newSubnet) {
    var previousSubnet = model.previous('subnet');
    if (previousSubnet) {
      this.stopListening(previousSubnet);
    }

    this.onSubnetChanged();

    this.listenTo(newSubnet, {
      'change:address change:size': this.onSubnetChanged,
    });
  },

  /**
   * Updates slider when the address associated with the VLAN has changed.
   *
   * @listens subnetModel~change:size
   * @param {ReadOnly.Model} subnetModel
   * @param {String} value
   * @param {Object} options
   * @property {Boolean} configRolledBack
   *   Indicates the subnet change is related to it being rolled back.
   */
  onSubnetChanged: function(subnetModel, value, options) {
    if (options && options.configRolledBack === true) {
      // Actions are being rolled back, leave the dhcp pool alone
      return;
    }

    this.model.transposePool();
    this.triggerMethod('needsRender');
  },

  /**
   * Initialize the slider used for DHCP pool selection.
   */
  initSlider: function() {
    var sliderRange;
    var poolRange;
    var usableRange;

    if (typeof this.poolSlider != 'undefined') {
      this.poolSlider.destroy();
    }

    usableRange = this.model.getCurrentBoundary();
    poolRange = _.map(this.model.pick('startIp', 'endIp'), networkUtils.Ip.ip2long);

    // for visualization purposes only, we bump up the end of the range by 1 - this
    // prevents an issue where pools with a single address look like an empty bar
    // (because the hidden slider thumbs on are top of each other) - basically,
    // we want to represent the subnet as a space divided into discrete, even-width
    // segments for each address, rather than as a pure mathematical range
    sliderRange = [poolRange[0], poolRange[1] + 1];

    this.poolSlider = new Slider(this.ui.dummySliderInput[0], {
      min: usableRange[0],
      max: usableRange[1] + 1,
      value: sliderRange,
      id: 'pool-slider',
      tooltip: 'hide',
      handle: 'custom',
    });

    this.poolSlider.disable();

    this.updatePoolInfo(poolRange);
  },

  /**
   * Bootstrap-slider "slide" callback. Updates the total hosts label.
   *
   * @param {Array} value
   */
  updatePoolInfo: function(value) {
    var numIps = (value[1] - value[0] + 1);
    this.ui.numIps.text(i18n.t('actionDhcp.poolSize', {count: numIps}));
  },

  /**
   * Checks if the pool overlaps the associated subnet's router address.
   *
   * @param {Array} range
   *   The pool's start and end Ips.
   */
  checkForOverlappingRouterAddress: function(range) {
    var address = this.model.get('subnet').get('address');
    var addressAsLong = networkUtils.Ip.ip2long(address);

    this.ui.ipWarning.bs3ui('clearInlineStatus');

    if (addressAsLong >= range[0] && addressAsLong <= range[1]) {
      var msg = i18n.t('actionDhcp.overlapsIp', {ip: address});
      this.ui.dummySliderInput.bs3ui(
          'inlineStatus',
          this.ui.ipWarning,
          'warning',
          msg
      );
    }
  },

  /**
   * Template helper returning the usable range as dot-notation formatted IP
   * addresses.
   *
   * @return {Array}
   */
  tplFormattedUsableRange: function() {
    var usableRange = this.model.getCurrentBoundary();
    return _.map(usableRange, networkUtils.Ip.long2ip);
  },
});
