'use strict';

var _ = require('lodash');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var i18n = require('i18next');

/**
 * Model representing a single traffic category
 *
 * Localized category names:
 * i18n.t('actionTrafficShaping.categories.p2p'),
 * i18n.t('actionTrafficShaping.categories.backup'),
 * i18n.t('actionTrafficShaping.categories.webp2p'),
 * i18n.t('actionTrafficShaping.categories.web20'),
 * i18n.t('actionTrafficShaping.categories.webpopular'),
 * i18n.t('actionTrafficShaping.categories.voip'),
 * i18n.t('actionTrafficShaping.categories.email'),
 * i18n.t('actionTrafficShaping.categories.games'),
 * i18n.t('actionTrafficShaping.categories.speedtest'),
 * i18n.t('actionTrafficShaping.categories.media'),
 * i18n.t('actionTrafficShaping.categories.l4proto'),
 * i18n.t('actionTrafficShaping.categories.webnews'),
 *
 * DO NOT REMOVE THE COMMENT ABOVE - it's required for `grunt i18next`
 * to leave these names in the translation files!!
 */
module.exports = AdvancedValidationModel.extend({
  /**
   * @member {Object} #attributes
   * @property {String} name - this is like an id, not user editable
   * @property {Number} percent
   * @property {String} priority
   *   One of 'high'|'normal'|'low'
   */

  idAttribute: 'name',

  defaults: function() {
    return {
      percent: 100,
      priority: 'normal',
    };
  },

  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'percent')) {
      if (!this.isValidPercent(attrs.percent)) {
        errors.percent = i18n.t('actionTrafficShaping.invalidPercent');
      }
    }

    if (_.has(attrs, 'priority')) {
      if (['low', 'normal', 'high'].indexOf(attrs.priority) < 0) {
        errors.priority = i18n.t('actionTrafficShaping.invalidPriority');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Checks whether a value represents a valid percentage
   *
   * @param {Number} value
   * @returns {Boolean}
   */
  isValidPercent: function(value) {
    // check that it's numeric
    if (!_.isFinite(value)) {
      return false;
    }

    // check that it's a whole number
    if (Math.floor(value) % value !== 0) {
      return false;
    }

    // check that it's in a sane range
    return value > 0 && value <= 100;
  },

  /**
   * @returns {String}
   */
  getLabel: function() {
    var key = 'actionTrafficShaping.categories.' + this.id;
    var label = i18n.t(key);
    if (label === key) {
      return i18n.t('actionTrafficShaping.categories.unknown', {name: this.id});
    }
    return label;
  },
});
