'use strict';

var Marionette = require('backbone.marionette');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var RuleView = require('actions/conditionalDns/rule/StaticRuleView');
var tpl = require('actions/conditionalDns/static.html');
var twig = require('twig').twig;

var RulesListView = Marionette.CollectionView.extend({
  childView: RuleView,
  filter: function(child, index) {
    // only show first 5 children max in static view
    return index >= 0 && index < 5;
  },
});

module.exports = BaseStaticView.extend({
  template: twig({data: tpl}),

  regions: {
    rules: '.rg-rules',
  },

  onRender: function() {
    this.initChildViews();
  },

  initChildViews: function() {
    this.showChildView('rules', new RulesListView({
      collection: this.model.get('items'),
    }));
  },
});
