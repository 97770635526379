'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var VlanPorts = require('actions/ports/Ports');

module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/ports/EditPorts#attributes
   * @property {String} id
   *   The VLAN name (e.g. vlan40)
   * @property {actions/ports/Ports} ports
   *   The list of ports available on the DNA.
   * @property {Boolean} isTagged
   * @property {Number|null} tagId
   */

  _snapshotAttributes: ['ports', 'isTagged', 'tagId'],

  defaults: {
    isTagged: false,
    tagId: null,
  },

  /**
   * Whether this model represents ports for use in a bond
   *
   * @readonly
   */
  forBond: false,

  /**
   * @param {Object} attributes
   * @param {Object} options
   */
  initialize: function(attributes, options) {
    if (!this.has('ports')) {
      this.set('ports', new VlanPorts(null, _.extend({deviceMac: this.deviceMac}, options)));
    }
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    var vlan = this.deviceConfig.get('networks').get(this.id);
    return _.isUndefined(vlan) || vlan.isNew();
  },

  /**
   * @param {Object} options
   * @return {Object}
   */
  toJSON: function(options) {
    var data = ActionItem.prototype.toJSON.apply(this, arguments);
    data.ports = data.ports.toJSON();
    return data;
  },

  fetch: function(options) {
    var self = this;

    if (options && options.fromCache) {
      return ActionItem.prototype.fetch.apply(this, arguments);
    }

    return this.get('ports').fetch()
      .then(function() {
        return ActionItem.prototype.fetch.apply(self, arguments);
      });
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig && !this.isNew()) {
      var vlan = this.deviceConfig.get('networks').get(this.id);
      var tagId = vlan.get('tagId');
      // assumes ports collection has been fetched and parsed first; see fetch()
      this.get('ports').each(this._setIsSelected.bind(this));
      return {
        isTagged: !!tagId,
        tagId: tagId,
      };
    }

    if (resp && _.isArray(resp.ports)) {
      this.get('ports').set(resp.ports);
      resp = _.omit(resp, 'ports');
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function() {
        // Link status comes from the device status blob
        return this.get('ports').deviceStatus.get('ports');
      },
      events: 'add remove change',
    },
    {
      getDispatcher: function(config) {
        return config.get('ports');
      },
      events: 'add remove change',
    },
    {
      getDispatcher: function(config) {
        // who is using what port comes from device config
        return config.get('networks');
      },
      events: 'add remove change:portsMap', // tagId is derived from portsMap
    },
    {
      getDispatcher: function(config) {
        // who is using what port comes from device config
        return config.get('nicBonds');
      },
      events: 'add remove change:portsMap change:description',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'tagId') && this.get('isTagged')) {
      if (!this._isValidTagId(attrs.tagId)) {
        errors.tagId = i18n.t('actionPorts.badTagId');
      } else {
        var duplicateTagError = this._checkUniqueTagId(attrs.tagId, this.id);
        if (!_.isEmpty(duplicateTagError)) {
          errors.tagId = duplicateTagError;
        }
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Overridden to include the diff of the ports models.
   *
   * @return {Boolean|Object}
   */
  getSnapshotDiff: function() {
    var changed = ActionItem.prototype.getSnapshotDiff.apply(this) || {};
    var diff = this.get('ports').invoke('getSnapshotDiff');

    diff = _.filter(diff, _.identity);
    if (diff.length > 0) {
      changed.ports = diff;
    }

    return (_.size(changed) > 0 ? changed : false);
  },

  /**
   * Overridden to call takeSnapshot() on each ports model.
   */
  takeSnapshot: function() {
    this.get('ports').invoke('takeSnapshot');
    ActionItem.prototype.takeSnapshot.apply(this, arguments);
  },

  /**
   * @return {Object}
   */
  getTask: function() {
    var ports = this._getPortDetailsForTask();
    var data = {};

    if (!this.isNew()) {
      data.id = this.id;
    }

    return {
      name: 'vlan.create',
      data: _.extend(data, {ports: ports}),
    };
  },

  /**
   * Ensures the set of selected ports are appropriate
   * based on the current value of isTagged
   *
   * @param {Function} filter
   *   filter function for limiting the ports considered
   */
  normalizePortSelection: function(filter) {
    var ports = this.get('ports').filter(filter);

    if (this.get('isTagged')) {
      // tagged VLANs must select exactly 1 port
      var first = _.find(ports, _.property('attributes.isSelected')) || _.find(ports, function(port) {
        return !port.has('bondId');
      });
      ports.forEach(function(port) {
        port.set('isSelected', port === first);
      });
    } else {
      // native LANs cannot share ports with other native LANs
      ports.forEach(function(port) {
        if (port.get('isSelected')) {
          port.set('isSelected', false);
        }
      }, this);
    }
  },

  /**
   * Determines whether or not the port is assigned to the current VLAN. This
   * method expects a VLAN to be associated with this Model.
   *
   * @param {actions/ports/Port} port
   */
  _setIsSelected: function(port) {
    var isNativeTo = port.get('nativeTo') === this.id;
    var isTaggedBy = port.get('taggedBy').indexOf(this.id) >= 0;
    var isNotBonded = !port.get('bondId');
    var selected = (isNativeTo || isTaggedBy) && isNotBonded;

    port.set('isSelected', selected);
  },

  /**
   * Builds the list of ports to add or remove from the VLAN
   *
   * @private
   * @return {Object}
   */
  _getPortDetailsForTask: function() {
    var isTagged = this.get('isTagged');
    var tagId = this.get('tagId');

    var selectedPortNames = this.get('ports').filter(function(port) {
      return port.get('isSelected');
    }).map(function(selectedPort) {
      if (isTagged) {
        return selectedPort.id + '.' + tagId;
      }
      return selectedPort.id;
    });

    // convert list of port names to a map of portName => attributes
    return _.zipObject(selectedPortNames, _.map(selectedPortNames, function() {
      return {}; // port attributes object is currently empty
    }));
  },

  /**
   * Checks whether a value represents a valid VLAN ID
   *
   * @param {Number} value
   * @returns {Boolean}
   * @private
   */
  _isValidTagId: function(value) {
    // check that it's numeric
    if (!_.isFinite(value)) {
      return false;
    }

    // check that it's a whole number
    if (Math.floor(value) % value !== 0) {
      return false;
    }

    // 802.1q VID is a 12-bit value with 0 and 4095 (0xFFF) reserved
    return value > 0 && value < 4095;
  },

  /**
   * Checks whether a vlan tag ID is unique and provides an
   * error message if it isn't
   *
   * @param {Number} tagId
   * @param {String} networkId
   * @returns {String|null}
   * @private
   */
  _checkUniqueTagId: function(tagId, networkId) {
    var error = '';
    var vlans = this.deviceConfig.getVlans();
    var conflictingVlan = vlans.find(function(vlan) {
      return vlan.id !== networkId && vlan.get('tagId') === tagId;
    });

    if (conflictingVlan) {
      var desc = conflictingVlan.get('description');
      error = i18n.t('actionPorts.duplicateTagId', {
        network: desc || conflictingVlan.id,
      });
    }

    return error;
  },
});
