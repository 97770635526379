'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var RenderPositions = require('config/RenderPositions');

/**
 * Custom region for setting and adjusting a config card's action list columns.
 */
module.exports = Marionette.Region.extend({
  /**
   * @param {Marionette.Region} region
   * @param {manage/edit/config/group/ActionsListView} view
   */
  onBeforeShow: function(region, view) {
    this.formatColumn(view);
  },

  /**
   * @param {Marionette.Region} region
   * @param {manage/edit/config/group/ActionsListView} view
   */
  onShow: function(region, view) {
    this.toggleRegion(view);
    this.listenTo(view, 'add:child remove:child', this.toggleRegion.bind(this));
  },

  /**
   * Shows or hides the region depending on whether or not it has rendered children.
   *
   * @param {Marionette.CollectionView} collectionView
   * @param {Marionette.View} childView
   */
  toggleRegion: function(collectionView, childView) {
    if (collectionView.children.isEmpty()) {
      this.$el.addClass('hidden');
    } else {
      this.formatColumn(collectionView);
      this.$el.removeClass('hidden');
    }
  },

  /**
   * @param {Marionette.CollectionView} view
   */
  formatColumn: function(view) {
    var columnLists = _.pick(RenderPositions, 'first', 'middle', 'last');
    var extraWideLists = _.pick(RenderPositions, 'first', 'last');

    if (columnLists[view.listType]) {
      this.$el.addClass('col-xs-12 col-md-4');
    }

    if (extraWideLists[view.listType]) {
      var extraWide = view.collection.filter(function(actn) {
        return actn.get('doubleWide') && actn.get('renderPosition') === view.listType;
      });

      if (extraWide.length > 0) {
        this.$el.removeClass('col-md-4').addClass('col-md-8');
      }
    }
  },
});
