'use strict';

var $ = require('jquery');
var _ = require('lodash');
var Marionette = require('backbone.marionette');
var i18n = require('i18next');
var twig = require('twig').twig;
var tpl = require('actions/router/wifi/form.html');
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');

/**
 * Renders the "Manage global Wi-Fi settings" component form.
 */
module.exports = Marionette.View.extend({
  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    frequency: '[name="wifi-frequency"]',
    f2Wrap: '.wifi-f2-wrap', // 2.4ghz
    f5Wrap: '.wifi-f5-wrap', // 5ghz
    channel: '[name="wifi-f2-channel"], [name="wifi-f5-channel"]',
    wifiEnable: '[name="wifi-enable"]',
  },

  events: {
    'change @ui.frequency': 'checkConfig',
    'change @ui.channel': 'checkConfig',
    'change @ui.wifiEnable': 'checkConfig',
  },

  modelEvents: {
    'invalid': 'onError',
    'change:frequency': 'onChangeFrequency',
    'change:channel': 'onChangeChannel',
  },

  onRender: function() {
    this.toggleFrequencySpecificControls(this.model.get('frequency'));
    this.checkForSupportedCountry();
  },

  templateContext: function() {
    return _.extend({
      channels: this.model.getFrequencyChannels(),
      supportsAc: this.model.deviceStatus.hasCapability('supportsAc'),
    }, tplHelpers.apply(this));
  },

  /**
   * Blur and click handlers for model data in form.
   *
   * @param {Event} ev
   */
  checkConfig: function(ev) {
    var name = ev.currentTarget.name;
    var value = ev.currentTarget.value;
    var data = ev.currentTarget.dataset;

    switch (name) {
      case 'wifi-frequency':
        this.model.set({
          frequency: value,
          htmode: data.htmode,
        });
        break;

      case 'wifi-f2-channel':
      case 'wifi-f5-channel':
        this.ui.channel.bs3ui('clearFieldError');
        this.model.set({channel: value}, {validate: true});
        break;

      case 'wifi-enable':
        this.model.set({disabled: $(ev.currentTarget).is(':checked') && ev.currentTarget.value === 'off'});
        break;

      default:
        break;
    }
  },

  /**
   * Shows/hides the appropriate "channel" option.
   *
   * @param {String} frequency
   */
  toggleFrequencySpecificControls: function(frequency) {
    if (frequency === '2.4') {
      this.ui.f5Wrap.addClass('hidden');
      this.ui.f2Wrap.removeClass('hidden');
    } else {
      this.ui.f2Wrap.addClass('hidden');
      this.ui.f5Wrap.removeClass('hidden');
    }
  },

  checkForSupportedCountry: function() {
    if (this.model.get('countryError')) {
      this.ui.wifiEnable.prop('disabled', true);
      this.ui.channel.prop('disabled', true);
      this.ui.frequency.prop('disabled', true);
      this.ui.channel.bs3ui('showFieldError', i18n.t('actionGlobalWifi.unsupportedCountry'));
    }
  },

  /**
   * Ensures valid options are available and selected for the new frequency
   *
   * @listens actions/router/wifi/EditWifi~change:frequency
   * @param {actions/router/wifi/EditWifi} model
   * @param {String} frequency
   * @param {Object} options
   */
  onChangeFrequency: function(model, frequency, options) {
    if (options && options.fromConfig) {
      // if the frequency is changing because a new config came down,
      // don't munge anything around (RenderChanges will take care of it)
      return;
    }

    this.toggleFrequencySpecificControls(frequency);

    var orig = this.model.getLastSnapshot();

    var selectedChannel;
    if (frequency === orig.frequency) {
      selectedChannel = orig.channel;
    } else {
      selectedChannel = this.model.getFrequencyChannels()[frequency][0];
    }

    this.model.set({channel: selectedChannel});
  },

  /**
   * Changes the Channel value.
   *
   * Note, normally we don't manually update form field values, but with this
   * View, changing the frequency may adjust the channel value.
   *
   * @listens actions/router/wifi/EditWifi~change:channel
   * @param {actions/router/wifi/EditWifi} model
   * @param {String} channel
   * @param {Object} options
   */
  onChangeChannel: function(model, channel, options) {
    this.ui.channel.val(channel);
  },

  /**
   * Decorates the form fields to indicate error.
   *
   * @param {actions/router/wifi/EditWifi} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.channel) {
      this.ui.channel.bs3ui('showFieldError', error.channel);
    }
  },
});
