'use strict';

var i18n = require('i18next');
var EditTrafficPolicyRules = require('actions/trafficPolicyRules/EditTrafficPolicyRules');
var FormView = require('actions/trafficPolicyRules/FormView');
var StaticView = require('actions/trafficPolicyRules/StaticView');

/**
 * Action configuration for the Traffic Policy rules action component.
 */
module.exports = {
  title: i18n.t('trafficPolicy.rulesTitle'),
  Model: EditTrafficPolicyRules,
  FormView: FormView,
  TextView: StaticView,
};
