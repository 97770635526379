'use strict';

var _ = require('lodash');
var BaseAlertView = require('components/alert/AlertView');
var i18n = require('i18next');
var Radio = require('backbone.radio');
var uiIcons = require('lib/tplHelpers/uiIcons');
var dnaStatusChannel = Radio.channel('dnaStatusChannel');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');

/**
 * Displays a notification for a DNA in a non-optimal state. This can include
 * the DNA being in failover or a bad WAN connection.
 */
module.exports = BaseAlertView.extend({
  /**
   * View-Model (see components/alert/AlertView for more model attributes).
   * @member {Backbone.Model} manage/detailsLayout/globalDeviceMessage#model
   * @property {Object} attributes.statusCard
   *   The status of the DNA.
   * @property {String} attributes.page
   *   The page the message is being displayed on. Currently allowed values
   *   are: fleetOverview, dnaView.
   */

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    var statusCard = this.model.get('statusCard');
    var deviceConfig = this.model.get('deviceConfig');
    var deviceStatus = this.model.get('deviceStatus');

    if (this.model.get('page') === 'dnaView') {
      this.model.set('alertType', 'wide');
      dnaStatusChannel.reply('is:dna:connected', this.isDnaConnected.bind(this));
    }

    _.bindAll(this, 'getAlertIcon', 'getAlertHeader', 'getAlertMessage', 'getButtonText');

    // determine if we need to show the establishing connection flag
    if (statusCard.get('connected') === false) {
      this.model.set('establishingConnection', true);
    }

    this.listenTo(statusCard, {
      'change:activeWan change:failedOver change:loadBalancingDegraded change:connected': this.render,
      'change:connected': this.onConnectedChange,
    });

    this.listenTo(this.model, {
      'change:establishingConnection': this.render,
    });

    if (deviceConfig) {
      this.listenTo(deviceConfig.get('networks'), 'change:ipconflict', this.render);
      this.listenTo(deviceConfig.get('webFilters'), 'change:enabled', this.render);
      this.listenTo(deviceConfig.get('webFiltersTitanHq'), 'change:enabled', this.render);
    }

    if (deviceStatus) {
      this.listenTo(deviceStatus.get('router'), 'change:upgradeAvailable', this.render);
    }
  },

  onBeforeRender: function() {
    this.updateSeverity();
    BaseAlertView.prototype.onBeforeRender.apply(this, arguments);

    this.$el.addClass('status-alert mb-0');

    if (this.model.get('page') === 'fleetOverview') {
      this.$el.addClass('status-alert--compact');
    }

    this.toggleAlert();
  },

  onRender: function() {
    var self = this;

    BaseAlertView.prototype.onRender.apply(this, arguments);

    if (this.model.get('establishingConnection') === true && _.isUndefined(this.connectionTimeout)) {
      this.connectionTimeout = setTimeout(function() {
        self.model.set('establishingConnection', false);
      }, 10000);
    }
  },

  /**
   * This gets called from the parent object that this extends via a trigger when the button gets hit.
   */
  onButtonClicked: function() {
    this.navigateToVlan();
  },

  onBeforeDestroy: function() {
    window.clearTimeout(this.connectionTimeout);
  },

  templateContext: function() {
    var baseHelpers = BaseAlertView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      message: this.getAlertMessage,
      header: this.getAlertHeader,
      iconClass: this.getAlertIcon,
      buttonText: this.getButtonText,
    });
  },

  /**
   * "severity" change callback
   *
   * @param {Backbone.Model} model
   * @param {Boolean} connected
   * @param {Object} options
   */
  onConnectedChange: function(model, connected, options) {
    if (this.model.get('page') === 'dnaView') {
      // Fire off event for any component that cares if the dna is connected or not
      dnaStatusChannel.trigger('dna:connected', {dnaConnected: connected === true});
    }
  },

  /**
   *
   * @memberof! manage/detailsLayout/globalDeviceMessage/GlobalDeviceMessage!
   * @method templateContext.alertIcon
   * @returns {string}
   */
  getAlertIcon: function() {
    var statusHealth = this.model.get('statusCard');

    if (this.model.get('establishingConnection') === true) {
      return 'fas fa-sync-alt fa-spin';
    }

    if (statusHealth.get('connected') !== true || this.shouldShowIpConflictBanner()) {
      return uiIcons.icons.error;
    }

    if (statusHealth.get('failedOver') === true) {
      return uiIcons.icons.warning;
    }

    if (this.shouldShowTitanHqKBBanner()) {
      return uiIcons.icons.warning;
    }

    return uiIcons.icons.success;
  },

  /**
   * @memberof! manage/detailsLayout/globalDeviceMessage/GlobalDeviceMessage!
   * @method templateContext.alertHeader
   * @returns {string}
   */
  getAlertHeader: function() {
    var statusHealth = this.model.get('statusCard');
    var alertHeader = '';

    if (statusHealth.get('connected') === false) {
      alertHeader = i18n.t('globalMessage.attention');
      if (this.model.get('establishingConnection') === true) {
        alertHeader = i18n.t('globalMessage.connecting');
      }
    } else if (this.shouldShowIpConflictBanner()) {
      alertHeader = i18n.t('globalMessage.vlanConfigError');
    } else if (statusHealth.get('failedOver') === true) {
      if (this.getActiveWanId() && this.getActiveWanId().substring(0, 3) === 'lte') {
        alertHeader = i18n.t('globalMessage.degradedState');
      } else {
        alertHeader = i18n.t('globalMessage.failoverState');
      }
    } else if (this.model.get('page') === 'fleetOverview') {
      alertHeader = i18n.t('globalMessage.connectionEstablished');
    } else if (this.shouldShowTitanHqKBBanner()) {
      alertHeader = i18n.t('globalMessage.titanHqKBHeader');
    }

    return alertHeader;
  },

  getButtonText: function() {
    // This is here to prevent the button from showing on Fleet over view cards
    if (this.model.get('page') === 'fleetOverview') {
      return false;
    }

    if (this.shouldShowIpConflictBanner()) {
      return i18n.t('globalMessage.resolveConflict');
    }
  },

  /**
   * @memberof! manage/detailsLayout/globalDeviceMessage/GlobalDeviceMessage!
   * @method templateContext.alertMessage
   * @returns {{beforeClickableText: string, afterClickableText: string}}
   */
  getAlertMessage: function() {
    var statusHealth = this.model.get('statusCard');
    var alertMessage = {beforeClickableText: '', afterClickableText: '', clickableText: '', clickableURL: ''};

    if (statusHealth.get('connected') === false) {
      if (this.model.get('establishingConnection')) {
        alertMessage.beforeClickableText = i18n.t('globalMessage.establishingConnection');
      } else {
        alertMessage.beforeClickableText = i18n.t('globalMessage.unableToConnect');
      }

      if (this.model.get('page') === 'dnaView') {
        alertMessage.beforeClickableText += ' ' + i18n.t('globalMessage.lastKnownState');
      } else if (this.model.get('page') === 'workbench') {
        alertMessage.beforeClickableText += ' ' + i18n.t('globalMessage.noChanges');
      }
    } else if (this.shouldShowIpConflictBanner()) {
      if (statusHealth.get('failedOver') === true) {
        if (statusHealth.get('loadBalancingDegraded') === true) {
          alertMessage.beforeClickableText = i18n.t('globalMessage.ipConflictAndDegradedState');
        } else {
          alertMessage.beforeClickableText = i18n.t('globalMessage.ipConflictAndFailoverState');
        }
      } else {
        alertMessage.beforeClickableText = i18n.t('globalMessage.conflictingIPs');
      }
    } else if (statusHealth.get('failedOver') === true) {
      if (this.getActiveWanId() && this.getActiveWanId().substring(0, 3) === 'lte') {
        alertMessage.beforeClickableText = i18n.t('globalMessage.cellularFailover');
      } else if (statusHealth.get('loadBalancingDegraded') === true) {
        alertMessage.beforeClickableText = i18n.t('globalMessage.loadBalancingDegraded');
      } else {
        alertMessage.beforeClickableText = i18n.t('globalMessage.failover');
      }
    } else if (this.model.get('page') === 'fleetOverview') {
      alertMessage.beforeClickableText = i18n.t('globalMessage.connectedToDatto');
    } else if (this.shouldShowTitanHqKBBanner()) {
      var text = i18n.t('globalMessage.clickToLearnMore');
      var indexOfOpenBrace = text.indexOf('{');
      var indexOfCloseBrace = text.indexOf('}');

      if (indexOfOpenBrace !== -1 && indexOfCloseBrace !== -1) {
        alertMessage.beforeClickableText = text.substring(0, indexOfOpenBrace);
        alertMessage.clickableText = text.substring(indexOfOpenBrace + 1, indexOfCloseBrace);
        alertMessage.clickableURL = 'https://kb.datto.com/hc/en-us/articles/360000924183';
        alertMessage.afterClickableText = text.substring(indexOfCloseBrace + 1);
      }
    }

    return alertMessage;
  },

  /**
   * Parses the status health data to determine the alert's severity.
   */
  updateSeverity: function() {
    var statusHealth = this.model.get('statusCard');
    var severity = '';

    if (statusHealth.get('connected') === false) {
      if (this.model.get('establishingConnection') === true) {
        severity = 'warning';
      } else {
        severity = 'error';
      }
    } else if (this.shouldShowIpConflictBanner()) {
      severity = 'error';
    } else if (statusHealth.get('failedOver') === true) {
      severity = 'warning';
    } else if (this.model.get('page') === 'fleetOverview') {
      severity = 'success';
    } else if (this.shouldShowTitanHqKBBanner()) {
      severity = 'info';
    }

    this.model.set('severity', severity);
  },

  /**
   * Returns the active wan id, or an empty string if the device is not connected
   *
   * @return {String}
   */
  getActiveWanId: function() {
    var statusHealth = this.model.get('statusCard');

    if (statusHealth.get('connected') === false) {
      return '';
    }

    return statusHealth.get('activeWan');
  },

  /**
   * Shows or hides the alert.
   *
   * @fires dnaStatusChannel.request~is:dna:updating
   */
  toggleAlert: function() {
    if (this.model.get('page') === 'fleetOverview' ||
        (!this.isDnaUsingPrimaryWan() &&
          !dnaStatusChannel.request('is:dna:updating')) ||
        this.shouldShowIpConflictBanner() ||
        this.shouldShowTitanHqKBBanner()
    ) {
      this.$el.removeClass('hidden');
    } else {
      this.$el.addClass('hidden');
    }
  },

  /**
   * @listens dnaStatusChannel.reply~is:dna:connected
   * @return {Boolean}
   */
  isDnaConnected: function() {
    return this.model.get('statusCard').get('connected') === true;
  },

  /**
   * @return {Boolean}
   */
  isDnaUsingPrimaryWan: function() {
    var statusHealth = this.model.get('statusCard');
    return statusHealth.get('connected') && statusHealth.get('failedOver') === false;
  },

  /**
   * Determines whether the conflicts banner should be shown or not.
   *
   * @returns {boolean}
   */
  shouldShowIpConflictBanner: function() {
    if (!_.isUndefined(this.model.get('deviceConfig')) &&
        !_.isUndefined(this.model.get('statusCard'))
    ) {
      return this.model.get('deviceConfig').getConflictingVlans().length > 0 &&
        this.model.get('statusCard').get('connected') === true;
    }

    return false;
  },

  shouldShowTitanHqKBBanner: function() {
    var deviceConfig = this.model.get('deviceConfig');
    var deviceStatus = this.model.get('deviceStatus');

    return deviceConfig && deviceStatus &&
        deviceConfig.get('webFilters').get('enabled') &&
        deviceConfig.get('webFiltersTitanHq') &&
        !deviceConfig.get('webFiltersTitanHq').get('enabled') &&
        deviceStatus.get('router').get('upgradeAvailable') === false &&
        !dnaStatusChannel.request('is:dna:updating');
  },

  /**
   * changes your page to the /edit page and focuses on the problem vlan.
   */
  navigateToVlan: function() {
    // 0 because when deviceConfig get networks is called the networks are listed in order based on cid. 0
    // is the lowest cid so we choose the first one to jump to.
    var typeId = this.model.get('deviceConfig').getConflictingVlans()[0].id;
    deviceConfigChannel.request('goto:group', 'editLan', typeId);
  },
});
