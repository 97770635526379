'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Backbone = require('backbone');
var Radio = require('backbone.radio');
var ConfigItem = require('lib/models/ConfigItem');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');

/**
 * Represents a collection of {@link lib/models/ConfigItem}.
 */
module.exports = Backbone.Collection.extend({
  /**
   * The deviceMac associated with this collection of actions. When a new
   * Model is added, the Collection will pass it the deviceMac (via the
   * options parameter).
   *
   * @member {String} lib/models/ConfigItems#deviceMac
   */

  /**
   * @member {lib/models/ConfigItem} lib/collections/ConfigItems#model
   */
  model: ConfigItem,

  /**
   * @throws Error
   *   Thrown if "deviceMac" not passed in.
   * @param {Array} models
   * @param {Object} options
   * @property {String} options.deviceMac
   */
  constructor: function(models, options) {
    if (_.isUndefined(options.deviceMac)) {
      throw new Error('Object must be associated with a device');
    }

    this.deviceMac = options.deviceMac;

    // passing options.deviceConfig is for unit testing, which is why it is
    // not documented as a possible property of the "options" param.
    this.deviceConfig = options.deviceConfig;

    if (_.isUndefined(this.deviceConfig)) {
      this.deviceConfig = deviceConfigChannel.request('get:config');
    }

    Backbone.Collection.call(this, models, options);
  },

  /**
   * Overridden to pass deviceMac to every model added.
   *
   * Note, Backbone's add() method calls this method, so only set() is overridden.
   *
   * @param {Array} models
   * @param {Object} options
   * @return {Backbone.Collection}
   */
  set: function(models, options) {
    options = options || {};
    options.deviceMac = this.deviceMac;
    return Backbone.Collection.prototype.set.call(this, models, options);
  },

  /**
   * @return {lib/collections/ConfigItems}
   */
  clone: function() {
    var options = {
      deviceMac: this.deviceMac,
      deviceConfig: this.deviceConfig,
      model: this.model,
      comparator: this.comparator,
    };

    if (!_.isUndefined(this.deviceStatus)) {
      options.deviceStatus = this.deviceStatus;
    }

    // TODO should the models be cloned as well?
    return new this.constructor(this.models, options);
  },

  /**
   * @param {Object} options
   * @return {jQuery.Promise}
   */
  fetch: function(options) {
    var parsed;

    if (options && options.fromCache) {
      this.set(options.cache, _.extend({}, options, {parse: true}));
    } else {
      options = _.extend({}, options, {fromConfig: true});
      parsed = this.parse(null, options);
      this.set(parsed, _.extend({}, options, {parse: true}));
      this.invoke('takeSnapshot');
    }

    return ($.Deferred().resolve()).promise();
  },

  /**
   * Adds an instance of {@link lib/models/DeviceStatus} to this model.
   *
   * @param {Object} options
   */
  addDeviceStatus: function(options) {
    // passing options.deviceConfig is for unit testing, which is why it is
    // not documented as a possible property of the "options" para
    this.deviceStatus = options.deviceStatus;
    if (_.isUndefined(this.deviceStatus)) {
      this.deviceStatus = deviceConfigChannel.request('get:status');
    }
  },
});
