'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');

/**
 * Edit model for WAN Priority
 */
module.exports = ActionItem.extend({
  _snapshotAttributes: ['isPrimary'],

  isNew: function() {
    var vlan = this.deviceConfig.get('networks').get(this.id);
    return _.isUndefined(vlan) || vlan.isNew();
  },

  /**
   * Parse response for WAN Priority.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      if (!this.isNew()) {
        return {
          isPrimary: this.deviceConfig.getPrimaryWan().id === this.id,
        };
      }

      /**
       * We can only ever have 2 WANs, and if they are able to create another one
       * it means that the existing WAN is primary, and this one should therefore
       * default to secondary
       */
      return {
        isPrimary: false,
      };
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks');
      },
      events: 'change:wanPriority',
    },
  ],

  /**
   * Gets the WAN from deviceConfig associated with this model,
   * as well as the other WAN, if there is one
   *
   * @return {Object}
   */
  getWans: function() {
    var wans = {};
    var vlanId = this.id;
    var vlans = this.deviceConfig.getExternalVlans();
    var thisWan = vlans.get(this.id);

    var otherWan = vlans.find(function(network) {
      return network.id !== vlanId;
    });

    if (!_.isUndefined(thisWan)) {
      wans.thisWan = thisWan;
    }

    if (!_.isUndefined(otherWan)) {
      wans.otherWan = otherWan;
    }

    return wans;
  },

  /**
   * @return {Object}
   */
  getTask: function() {
    var metricValue = this.get('isPrimary') ? 1 : 2;
    var data = {id: this.id, metric: metricValue};

    return {
      name: 'vlan.create',
      data: data,
    };
  },
});
