'use strict';

var i18n = require('i18next');
var EditDomainFilters = require('actions/domainFilters/EditDomainFilters');
var FormView = require('actions/domainFilters/FormView');
var StaticView = require('actions/domainFilters/StaticView');

/**
 * Action configuration for Domain Filters
 */
module.exports = {
  title: i18n.t('actionDomainFilters.title'),
  Model: EditDomainFilters,
  FormView: FormView,
  TextView: StaticView,
};
