'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/staticRoutes/staticRoute.html');
var twig = require('twig').twig;

/**
 * Renders a text-only version of an individual static route
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/staticRoutes/StaticRouteView#model
   * @type {actions/staticRoutes/StaticRoute}
   */

  template: twig({data: tpl}),
});
