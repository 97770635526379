'use strict';

var $ = require('jquery');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var filterTpl = require('actions/macFilter/filter.html');
var removeTpl = require('actions/macFilter/remove.html');
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the editable form elements for a single mac filter rule
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/macFilter/FormView#model
   * @type {actions/macFilter/Filter}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  className: 'mac-filter',

  ui: {
    remove: '.btn-remove',
    edit: '.edit-mac',
  },

  events: {
    'click @ui.remove': 'deleteFilter',
    'focus @ui.edit': 'focusFilter',
    'blur @ui.edit': 'blurFilter',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  getTemplate: function() {
    if (this.model.get('remove') === true) {
      return twig({data: removeTpl});
    }

    return twig({data: filterTpl});
  },

  /**
   * @param {Object} ev
   */
  deleteFilter: function(ev) {
    if (this.model.isNew()) {
      this.model.collection.remove(this.model);
    } else {
      this.ui.edit.trigger('change'); // trigger dirty flag on group
      this.model.set('remove', true);
      this.trigger('delete:filter');
    }
  },

  focusFilter: function(ev) {
    this.originalValue = ev.currentTarget.value;
  },

  blurFilter: function(ev) {
    // we have to trigger a change event manually because the
    // colonFill function in form view re-sets the input value
    // programmatically, making the field think it hasn't changed
    if (this.originalValue !== ev.currentTarget.value) {
      $(ev.currentTarget).trigger('change');
    }
    var $target = $(ev.currentTarget);
    var value = $target[0].value.toUpperCase();

    this.ui.edit.bs3ui('clearFieldError');
    this.model.set({mac: value});
  },

  onError: function(model, error, options) {
    this.ui.edit.bs3ui('showFieldError', '');
  },
});
