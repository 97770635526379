'use strict';

var _ = require('lodash');
var AddressView = require('actions/staticAddress/AbstractAddressView');
var twig = require('twig').twig;
var ipv4Tpl = require('actions/staticAddress/wan/ipv4/ipv4-address.html');
var ipv6Tpl = require('actions/staticAddress/wan/ipv6/ipv6-address.html');
var RemoveConfig = require('manage/edit/config/action/removeConfig/RemoveConfig');
var networkUtils = require('lib/network');

/**
 * WAN static-address form.
 */
module.exports = AddressView.extend({
  behaviors: [{
    behaviorClass: RemoveConfig,
  }],

  className: 'address-form type-wan',

  ui: _.extend({}, AddressView.prototype.ui, {
    gateway: '[name="gateway"]',
    secondaryIps: '[name="secondaryIps"]',
  }),

  modelEvents: _.extend({}, AddressView.prototype.modelEvents, {
    'change:gateway': 'onChangeGateway',
  }),

  /**
   * @return {Object}
   */
  serializeData: function() {
    var data = AddressView.prototype.serializeData.apply(this, arguments);

    if (this.model.get('af') === 'ipv6') {
      data.subnetMasks = this.subnetMaskIpv6();
    } else {
      data.subnetMasks = networkUtils.getSubnetMasks(17, 31);
    }

    return data;
  },

  /**
   * Returns the template based on the address family.
   *
   * @return {Function}
   */
  getTemplate: function() {
    var af = this.model.get('af');

    if (this.model.get('pendingDelete') === true) {
      return this.template;
    }

    if (af === 'ipv4') {
      return twig({allowInlineIncludes: true, data: ipv4Tpl});
    } else if (af === 'ipv6') {
      return twig({allowInlineIncludes: true, data: ipv6Tpl});
    }
  },

  templateContext: function() {
    return _.extend({
      pendingDeleteMsg: this.model.getDeleteMsg.bind(this.model),
    }, AddressView.prototype.templateContext.apply(this));
  },

  onRender: function() {
    if (this.model.get('pendingDelete') === true) {
      return;
    }

    this.onChangeMask(this.model, this.model.get('size'));
    this.updateRange();
  },

  /**
   * Updates the "gateway" input field when it changes in the model.
   *
   * @listens this.model~change:gateway
   * @param {actions/staticAddress/AbstractEditAddress} model
   * @param {String} gateway
   * @param {Object} options
   */
  onChangeGateway: function(model, gateway, options) {
    this.ui.gateway.val(gateway);
  },

  /**
   * Overridden to add additional blur events for gateway, etc.
   *
   * @param {Event} ev
   */
  checkAddress: function(ev) {
    var name = ev.currentTarget.name;
    var fieldValue = ev.currentTarget.value;
    var data = {};

    if (name === 'address' || name === 'mask') {
      this.ui.secondaryIps.bs3ui('clearFieldError');
      this.ui.gateway.bs3ui('clearFieldError');

      AddressView.prototype.checkAddress.apply(this, arguments);

      if (!this.model.validationError) {
        this.checkGateway();
      }
    } else if (name === 'secondaryIps') {
      this.ui.secondaryIps.bs3ui('clearFieldError');
      data.secondaryIps = _.compact(_.map(fieldValue.split(','), _.trim));
      this.model.set(data, {commit: true});
    } else if (name === 'gateway') {
      this.ui.gateway.bs3ui('clearFieldError');
      data.gateway = fieldValue;
      this.model.set(data, {commit: true});
    }
  },

  /**
   * Overridden to also display invalid gateway.
   *
   * @param {actions/vlan/AbstractEditVlan} model
   * @param {Object} errors
   * @param {Object} options
   */
  onError: function(model, errors, options) {
    var err;

    if (!_.isUndefined(errors.address) || !_.isUndefined(errors.size)) {
      AddressView.prototype.onError.apply(this, arguments);
    }

    if (errors.secondaryIps) {
      this.ui.secondaryIps.bs3ui('showFieldError', errors.secondaryIps);
    }

    if (_.isObject(errors.gateway)) {
      err = errors.gateway.subnet;
      this.ui.gateway.bs3ui('showFieldError', err);
    }

    if (_.isObject(errors.secondaryIps)) {
      err = errors.secondaryIps.subnet;
      this.ui.secondaryIps.bs3ui('showFieldError', err);
    }
  },

  /**
   * When the user updates the address or mask
   * verify the gateway is still valid.
   */
  checkGateway: function() {
    var errors = {};

    errors = this.model.addressInSubnet(this.ui.gateway.val(), 'gateway', errors);

    if (_.isObject(errors.gateway)) {
      this.model.trigger('invalid', this.model, errors);
    }
  },
});
