'use strict';

var _ = require('lodash');
var $ = require('jquery');
var BaseGroup = require('config/Group');
var RenderPositions = require('config/RenderPositions');
var tplNetworkHelpers = require('lib/tplHelpers/networkHelpers');
var ConfigMessageTypes = require('manage/edit/ConfigMessageTypes');
var WanModeActionManager = require('actionGroups/editWan/WanModeActionManager');
var DnsPlaceholderActionManager = require('actionGroups/shared/DnsPlaceholderActionManager');
var i18n = require('i18next');
var Entities = require('html-entities').XmlEntities;
var entities = new Entities();

/**
 * "Manage WAN" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'vlanDescription',
      position: RenderPositions.header,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'wanPorts',
      required: true,
      hidden: true,
      position: RenderPositions.none,
    },
    {
      name: 'wanPriority',
      position: RenderPositions.first,
      order: 0,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'wanMTU',
      position: RenderPositions.first,
      order: 1,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'wanProtocol',
      position: RenderPositions.last,
      order: 0,
      required: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: true,
    },
    {
      name: 'wanAddress',
      position: RenderPositions.last,
      order: 1,
      required: false,
      managed: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: true,
    },
    {
      name: 'wanPppoe',
      position: RenderPositions.last,
      order: 1,
      required: false,
      managed: true,
      renderTitle: true,
      renderStatic: true,
      doubleWide: true,
    },
    {
      name: 'wanAddressIpv6',
      position: RenderPositions.last,
      order: 2,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: true,
      requireCapabilities: ['supportsIpv6'],
    },
    {
      name: 'wanCustomMac',
      position: RenderPositions.first,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: false,
      requireCapabilities: ['supportsWanCustomMac'],
    },
    {
      name: 'wanDns',
      order: 3,
      position: RenderPositions.last,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: true,
      disabledOnEnabledConfig: [
        {id: 'webFiltersTitanHq', reason: i18n.t('configEdit.disabledReason.dnsProvidedByWebFilters')},
      ],
    },
    {
      name: 'wanDnsPlaceholder',
      order: 4,
      position: RenderPositions.last,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: true,
      managed: true,
    },
    {
      name: 'wanWebFilterDns',
      order: 4,
      position: RenderPositions.last,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: true,
      requireCapabilities: ['supportsWebFilteringTitanHq'],
      managed: true,
    },
  ],

  actionManagers: [
    WanModeActionManager,
    DnsPlaceholderActionManager,
  ],

  deleteTaskName: 'vlan.delete',

  isConnectivityDisrupted: function(type, target) {
    if (type === 'removeAction') {
      return (target.id === 'wanAddress' || target.id === 'wanCustomMac');
    }

    if (type === 'addAction') {
      return (target === 'wanAddress' || target === 'wanCustomMac');
    }

    if (type === 'input') {
      return (
        target.name === 'wan-priority-vlan1' ||
        target.name === 'wan-priority-vlan3' ||
        target.name === 'address' ||
        target.name === 'gateway' ||
        target.name === 'mask' ||
        target.name === 'customMac'

      );
    }

    return false;
  },

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    var wan = this.deviceConfig.get('networks').get(this.get('typeId'));
    // note, WANs can only be assigned to a single port
    return tplNetworkHelpers.formatWanName(wan.get('portsMap')[0]);
  },

  /**
   * @return {String}
   */
  description: function() {
    var wan = this.deviceConfig.get('networks').get(this.get('typeId'));
    return wan !== undefined ? entities.decode(wan.get('description')) : '';
  },

  /**
   * Request a callback when the description of this group changes
   *
   * @param {Backbone.Events} listener
   * @param {Function} callback
   */
  onDescriptionChanged: function(listener, callback) {
    var wan = this.deviceConfig.get('networks').get(this.get('typeId'));
    if (wan) {
      listener.listenTo(wan, 'change:description', callback.bind(listener));
    }
  },

  /**
   * Overridden to include the "id" and "role".
   *
   * @return {Object}
   */
  _getTasks: function() {
    var tasks = BaseGroup.prototype._getTasks.apply(this, arguments);
    var vlanTask = _.find(tasks, {name: 'vlan.create'});

    if (!_.isUndefined(vlanTask)) {
      vlanTask.data.id = this.get('typeId');
      vlanTask.data.role = 'external';
    }

    return tasks;
  },

  /**
   * Overridden to ensure if static IP exists that custom DNS is set
   *
   * @return {Object}
   */
  _validateActions: function() {
    var self = this;
    return BaseGroup.prototype._validateActions.apply(this, arguments).then(function(result) {
      // do the usual validation check and additionally check if we also have web filters DNS.
      // If both fail then our static configuration will become invalid.
      if (!self.validateStaticAndDns(self.get('actions')) &&
        !self.validateStaticAndWebFilterDns(self.get('actions'))
      ) {
        return $.Deferred().reject({type: ConfigMessageTypes.REQUIRE_DNS});
      }
      return result;
    });
  },

  validateStaticAndDns: function(actions) {
    var valid = true;
    var wanAddress = actions.find(function(act) {
      return act.id === 'wanAddress';
    });

    var wanDns = actions.find(function(act) {
      return act.id === 'wanDns';
    });

    // tried to save static IP without custom DNS
    // or tried to remove custom DNS while still having a static IP
    if (wanAddress && !wanAddress.get('actionModel').get('pendingDelete') &&
      (!wanDns || (wanDns && wanDns.get('actionModel').get('pendingDelete')))) {
      valid = false;
    }
    return valid;
  },

  validateStaticAndWebFilterDns: function(actions) {
    var wanWebFilterDns = actions.find(function(act) {
      return act.id === 'wanWebFilterDns';
    });

    var wanAddress = actions.find(function(act) {
      return act.id === 'wanAddress';
    });

    return !(wanAddress && !wanAddress.get('actionModel').get('pendingDelete') && !wanWebFilterDns);
  },
});
