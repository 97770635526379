'use strict';

var _ = require('lodash');
var Ip = require('lib/Ip');
var ipAddr = require('ipaddr.js');
var strMod = require('lib/mixins/stringModifiers');

/**
 * Collection of functions for working with hostnames and IP addresses.
 */

var regex = {
  // eslint-disable-next-line max-len
  hostname: '^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)' +
    '*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])$',

  // eslint-disable-next-line max-len
  ipv4: '^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}' +
    '(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$',

  /**
   * This regex matches any valid CIDR subnet
   * First, it matches a valid IPv4 address (in dot notation)
   * Then, it looks for a '/' and any number between 1-32
   * The IPv4 part will match leading 0s up to 3 digits
   * The mask part will not match any leading zeros
   *
   * Sample Matches:
   *    192.168.1.1/24
   *    192.168.001.01/24
   *    10.0.0.1/1
   *
   * Sample MisMatches:
   *    192.168.1.1
   *    192.168.1.1/0
   *    192.168.1.1 /24
   *    192.168.1.1/ 24
   *    192.168.1.000001/24
   *    192.168.1.1/24/
   *    192.168.1.1/24/foo
   *    192.168.1.1/2.4
   */

  subnet: '^(([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.){3}([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])' +
      '\\/([1-2]?[1-9]|3[0-2]|[1-2]0)$',

  // Valid MAC Address
  mac: '^([0-9A-F]{2}[:]){5}([0-9A-F]{2})$',

  // Valid email address
  // this should be accurate for most cases; see http://www.regular-expressions.info/email.html
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,

  // Loose definition for a valid domain
  domain: /^[a-z0-9.-]+\.[a-z]+$/i,

  // For list of domains that are allowed / blocked please refer to:
  // datto-dna-cloud-ui/test/lib/NetworkSpec.js
  datto: /^(http(s)?:\/\/)?(.*\.)?(dattobackup|datto|cloudtrax)(\.com|\.net|\.co|\.uk)+$/i,

  positiveIntNoLeadingZeros: /^0$|^[1-9][0-9]*$/,

  /**
   * This regex matches any port or port range
   * It is NOT a full validation check
   * It will check for two numbers separated by a dash
   * Those numbers must have no leading zeros and be less than 6 digits
   *
   * Sample Matches:
   *    150-200
   *    99999-99999
   *    500-1
   *
   * Sample MisMatches:
   *    500
   *    -100
   *    100-
   *    100 -500
   *    100- 500
   *    0500-100
   *    100-0500
   *    50-655350
   */
  portRange: '^(([1-9][0-9]{0,4})-([1-9][0-9]{0,4}))$',
  singlePort: '^([1-9][0-9]{0,4})$',
};

var privateRanges = [
  ['10.0.0.0', '10.255.255.255', 8],
  ['172.16.0.0', '172.31.255.255', 12],
  ['192.168.0.0', '192.168.255.255', 16],
  ['100.64.0.0', '100.127.255.255', 10],
];

var subnetMasks = [
  {size: 16, mask: '255.255.0.0'},
  {size: 17, mask: '255.255.128.0'},
  {size: 18, mask: '255.255.192.0'},
  {size: 19, mask: '255.255.224.0'},
  {size: 20, mask: '255.255.240.0'},
  {size: 21, mask: '255.255.248.0'},
  {size: 22, mask: '255.255.252.0'},
  {size: 23, mask: '255.255.254.0'},
  {size: 24, mask: '255.255.255.0'},
  {size: 25, mask: '255.255.255.128'},
  {size: 26, mask: '255.255.255.192'},
  {size: 27, mask: '255.255.255.224'},
  {size: 28, mask: '255.255.255.240'},
  {size: 29, mask: '255.255.255.248'},
  {size: 30, mask: '255.255.255.252'},
  {size: 31, mask: '255.255.255.254'},
];

module.exports = {
  /**
   * For convenience, attaching {@link lib/Ip}, which provides some handy-dandy
   * static methods.
   *
   * @function
   * @type {lib/Ip}
   */
  Ip: Ip,

  ipv6PostFix: '_v6',

  validDomain: function(domain) {
    var re = new RegExp(regex.domain);

    return re.test(domain);
  },

  isDattoDomain: function(domain) {
    return new RegExp(regex.datto).test(domain);
  },

  validMacAddress: function(mac) {
    var re = new RegExp(regex.mac);

    return re.test(mac);
  },

  // relative distinguished names (RDNs) must not exceed 63 chars
  validRdns: function(hostname) {
    var rdns = hostname.split('.');
    if (rdns.length > 1) {
      for (var i = 0, len = rdns.length; i < len; i++) {
        if (rdns[i].length > 63) {
          return false;
        }
      }
    }
    return true;
  },

  /**
   * Validates hostname value.
   *
   * @param {string} hostname
   * @return {Boolean}
   *
   *  1) verify hostname non-empty
   *  2) verify less than max-length
   *  3) verify that there is at least one alphabetic character
   *  4) verify contains only valid chars, starts/ends with alphanumeric char
   */
  validHostname: function(hostname) {
    var re = new RegExp(regex.hostname);
    return (
      !_.isEmpty(hostname) &&
      hostname.length > 1 &&
      hostname.length < 256 &&
      this.validRdns(hostname) &&
      /[a-zA-Z]/.test(hostname) &&
      re.test(hostname)
    );
  },

  /**
   * Validates an SSID.
   *
   * @param {string} ssid
   * @return {Boolean}
   */
  validSSID: function(ssid) {
    if (_.isUndefined(ssid) || _.isEmpty(ssid)) {
      return false;
    }

    var length = encodeURI(ssid).split(/%..|./).length - 1;
    return (length > 0 && length <= 32);
  },

  /**
   * Validates IPv4 (dot-notation) or IPv6 IP address.
   *
   * @param {String} ip
   * @param {String} af
   *   Either "ipv4" or "ipv6". Defaults to ipv4.
   * @return {Boolean}
   */
  validIP: function(ip, af) {
    var re;
    af = af || 'ipv4';

    if (af === 'ipv4') {
      re = new RegExp(regex.ipv4, 'g');
      return (ip !== '' && re.test(ip));
    }
    return (ipAddr.IPv6.isValid(ip));
  },

  /**
   * Validates IPv4 Subnet (CIDR notation)
   *
   * Does not support IPv6.
   *
   * @param {String} subnet
   *
   * @return {Boolean}
   */
  validSubnet: function(subnet) {
    var re = new RegExp(regex.subnet);
    return re.test(subnet);
  },

  /**
   * Validates IPv4 Subnet (CIDR notation) for suitability as a private subnet.
   *
   * Does not support IPv6.
   *
   * @param {String} subnet
   *
   * @return {Boolean}
   */
  validInternalSubnet: function(subnet) {
    if (!this.validSubnet(subnet)) {
      return false;
    }
    var subnetInfo = _.compact(_.trim(subnet).split('/'));

    for (var i = 0; i < privateRanges.length; i++) {
      if (this.isIPv4InRange(subnetInfo[0], privateRanges[i][0], privateRanges[i][1]) &&
            subnetInfo[1] >= privateRanges[i][2]) {
        return true;
      }
    }
    return false;
  },

  /**
   * Checks if an IPv4 address (dot-notation) falls between two other IPv4 addresses, inclusively.
   *
   * @param {String} ipv4
   * @param {String} rangeStart
   * @param {String} rangeEnd
   * @return {Boolean}
   */
  isIPv4InRange: function(ipv4, rangeStart, rangeEnd) {
    if (!this.validIP(ipv4) || !this.validIP(rangeStart) || !this.validIP(rangeEnd)) {
      return false;
    }

    var address = Ip.ip2long(ipv4);
    var rangeStartLong = Ip.ip2long(rangeStart);
    var rangeEndLong = Ip.ip2long(rangeEnd);

    return (address >= rangeStartLong && address <= rangeEndLong);
  },
  isNetworkOrBroadcast: function(ip) {
    var regex = /\.(0|255)$/;
    return regex.test(ip);
  },
  isIPv4InSubnet: function(ipv4, subnet) {
    var ipv4Long = Ip.ip2long(ipv4);
    var splitSubnet = subnet.split('/');
    var subnetIp = splitSubnet[0];
    var subnetSize = splitSubnet[1];
    var subnetIpLong = Ip.ip2long(subnetIp);
    var mask = -1 << (32 - subnetSize);
    return (ipv4Long & mask) === (subnetIpLong & mask);
  },

  /**
   * Validates IPv4 (dot-notation) address for suitability as a private, static address.
   * Does not support IPv6.
   *
   * @param {String} ip
   * @return {Boolean}
   */
  validInternalIPv4: function(ip) {
    if (!this.validIP(ip)) {
      return false;
    }

    for (var i = 0; i < privateRanges.length; i++) {
      if (this.isIPv4InRange(ip, privateRanges[i][0], privateRanges[i][1])) {
        return true;
      }
    }

    return false;
  },

  /**
   * Verifies that a port is valid.
   *
   * @param {number} port
   * @return {boolean}
   */
  validPort: function(port) {
    if (port < 1 || port > 65535) {
      return false;
    }
    var re = new RegExp(regex.singlePort);
    return re.test(port);
  },

  /**
   * Verifies that a port range is valid
   *
   * @param {string} portRange
   * @return {boolean}
   */
  validPortRange: function(portRange) {
    var re = new RegExp(regex.portRange);
    if (re.test(portRange)) {
      var portOne = parseInt(portRange.slice(0, portRange.indexOf('-')));
      var portTwo = parseInt(portRange.slice(portRange.indexOf('-') + 1));
      return portOne < 65536 && portTwo < 65536 && portOne < portTwo;
    }
    return false;
  },

  /**
   * Verifies that an email address is valid.
   * TODO: Determine if we can use HTML5 email-type inputs and let the browser do the validation
   *
   * @param {string} email
   * @return {boolean}
   */
  validEmail: function(email) {
    return regex.email.test(email);
  },

  /**
   * Formats a string into a standard mac address format. Returns
   * false if the resulting string is not a valid mac.
   *
   * @param {string} mac
   * @return {boolean}|{string}
   */
  formatMacAddress: function(mac) {
    var formattedMac = strMod.stringDelimit(mac, 2).toUpperCase();
    if (this.validMacAddress(formattedMac)) {
      return formattedMac;
    }

    return false;
  },

  /**
   * Checks if a mac address is a multicast mac address. Returns boolean.
   *
   * @param {string} mac - can be unformatted
   * @return {boolean} - true if multicast, or false if not (or invalid)
   */
  isMulticastMacAddress: function(mac) {
    mac = this.formatMacAddress(mac);
    var notMulticast = ['0', '2', '4', '6', '8', 'A', 'C', 'E'];

    if (!mac) {
      // No. In fact, it's not a mac address at all!
      return false;
    }

    // Since we're getting it as a string, we'll check it as
    // as a string.
    var secondChar = mac.charAt(1);
    return (notMulticast.indexOf(secondChar) === -1);
  },

  /**
   * Checks that the following are true:
   * - val is an integer with no leading 0s
   * - val is greater than or equal to min (if given)
   * - val is less than or equal to max (if given, also needs min to be given)
   *
   * @param {string} val
   * @param {int} min - optional
   * @param {int} max - optional
   * @returns {boolean}
   */
  validInt: function(val, min, max) {
    var isInt = regex.positiveIntNoLeadingZeros.test(val);
    var moreThanMin = _.isUndefined(min) || (val >= min);
    var lessThanMax = _.isUndefined(max) || (val <= max);

    return isInt && moreThanMin && lessThanMax;
  },

  /**
   * Returns subnet size/mask descriptors for a given range of CIDR size values
   *
   * @param {int} minCidr
   * @param {int} maxCidr
   * @return {Array}
   */
  getSubnetMasks: function(minCidr, maxCidr) {
    return subnetMasks.filter(function(entry) {
      return entry.size >= minCidr && entry.size <= maxCidr;
    });
  },
};
