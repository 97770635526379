'use strict';

var AbstractActionManager = require('config/AbstractActionManager');

var methodActionMap = {
  // currently no action for DHCP
  'static': 'wanAddress',
  'pppoe': 'wanPppoe',
};

/**
 * Swaps in the correct action when the user changes the WAN mode
 * between DHCP, Static, and PPPoE
 */
module.exports = AbstractActionManager.extend({

  initialize: function() {
    this.listenOnActions('actionModel:change:method', this.onWanMethodChange, 'wanProtocol');
  },

  onWanMethodChange: function(action, actionModel, value, options) {
    // make sure this is a user-initiated change so we aren't fighting
    // other stuff that's trying to sync or roll back the config
    if (!options || !options.commit) {
      return;
    }

    var oldActionId = methodActionMap[actionModel.previous('method')];
    var newActionId = methodActionMap[value];

    if (oldActionId) {
      this.removeAction(oldActionId);
    }

    if (oldActionId === 'wanAddress') {
      // if it looks like we just added DNS for static IP, clean up after ourselves
      // (this is a heuristic; it's also possible the user just added it manually)
      var dnsAction = this.getAction('wanDns');
      if (dnsAction && dnsAction.get('isPending')) {
        this.removeAction('wanDns');
      }
    }

    if (newActionId) {
      this.addAction(newActionId);
    }

    if (newActionId === 'wanAddress' && !this.getAction('wanWebFilterDns')) {
      // static address requires configuring DNS as well, BUT only add it if web filter DNS is not configured.
      this.addAction('wanDns');
    }
  },
});
