'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');

/**
 * Behavior for automatically re-rendering a config view when its
 * ConfigItem-derived model is fetched and updated from the shared
 * DeviceConfiguration (e.g. due to reparseConfigTriggers firing)
 *
 * This is intended primarily for use with FormView classes for
 * config card actions. These views need to update themselves when
 * the model changes from external activity (new config from the
 * server), but not when it changes as a result of user interaction
 * inside them -- in that case, the changes are flowing the other
 * direction, from view to model.
 *
 * StaticViews typically inherit from AbstractStaticView, which
 * has its own logic for re-rendering when data changes, so they
 * don't require this Behavior.
 *
 * NOTE: For FormViews that render a collection, you should usually
 * attach this behavior to the "leaf" view that renders an individual
 * item from the collection, and let CollectionView handle adding
 * and removing leaf views as items come and go from the collection.
 *
 * The exception to this is a FormView that uses a Collection in the
 * model, but renders its contents in a single view rather than using
 * a CollectionView (such approach is generally not recommended for
 * performance reasons, unless the collection has a small, fixed
 * length). In that case, use this Behavior directly in the FormView,
 * with the 'debounce' option set to true, and add a handler for
 * 'change' (and, if applicable, 'add' and 'remove') events from the
 * collection. Your handler(s) should call:
 *   this.triggerMethod('configChange', model, options);
 *
 * Options:
 * @param {Boolean} debounce
 *   whether re-render should be debounced instead of immediate (defaults to false)
 *   use this to coalesce multiple closely-spaced changes into a single re-render
 *   when set to true, view code can also manually ask for a debounced render by calling
 *     this.triggerMethod('needsRender')
 */
module.exports = Marionette.Behavior.extend({

  defaults: {
    debounce: false,
  },

  modelEvents: {
    'change': 'onConfigChange',
  },

  collectionEvents: {
    'change': 'onConfigChange',
  },

  initialize: function(options) {
    var __ = options._ || _; // allow underscore injection for testing
    this.onNeedsRender = this.onNeedsRender.bind(this);

    if (options.debounce) {
      this.onNeedsRender = __.debounce(this.onNeedsRender);
    }
  },

  onConfigChange: function(model, options) {
    if (options && options.fromConfig) {
      this.onNeedsRender();
    }
  },

  onNeedsRender: function() {
    this.view.render();
  },

});
