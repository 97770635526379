'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var Chart = require('chart.js');

var EXPLODE_RADIUS = 10;
var ANIMATION_DURATION = 200;

/**
 * Renders the top usage pie chart
 */
module.exports = Marionette.View.extend({

  /**
   * @name #collection
   * @type {components/usageShared/List}}
   */

  template: false,

  tagName: 'canvas',

  currentHoverIndex: -1,

  initialize: function(options) {
    _.bindAll(this, 'onItemHover');
  },

  onDomRefresh: function() {
    this.chart = new Chart(this.el, {
      type: 'pie',
      data: this.collection.getChartData(),
      options: this.getChartOptions(),
    });
  },

  onDomRemove: function() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  },

  getChartOptions: function() {
    return {
      layout: {
        padding: EXPLODE_RADIUS,
      },
      tooltips: {
        enabled: false,
      },
      hover: {
        onHover: this.onItemHover,
      },
    };
  },

  /**
   * Handles a new pie segment being hovered
   *
   * @fires hover:chart
   * @param {Event} event
   * @param {Array} items
   *   Chart items (pie segments) under the cursor
   */
  onItemHover: function(event, items) {
    var hoverIndex = -1;

    if (items.length > 0) {
      hoverIndex = items[0]._index;
    }

    if (hoverIndex !== this.currentHoverIndex) {
      this.currentHoverIndex = hoverIndex;
      this.highlightItem(hoverIndex);
      this.trigger('hover:chart', hoverIndex);
    }
  },

  /**
   * Highlights a given pie segment by applying a
   * simulated "explode" effect
   *
   * @param {Number} index
   *   Index of the pie segment to highlight
   */
  highlightItem: function(index) {
    if (!this.chart) {
      return;
    }

    if (index >= 0) {
      // update() has to be called _before_ adjusting
      // the radius, or it will wipe out the change
      this.chart.update(ANIMATION_DURATION);
      this.chart.getDatasetMeta(0).data[index]._model.outerRadius += EXPLODE_RADIUS;
    } else {
      this.chart.update(ANIMATION_DURATION);
    }
  },

});
