'use strict';

var Job = require('lib/models/Job');
var Radio = require('backbone.radio');
var apiChannel = Radio.channel('apiChannel');
var LogMessage = require('lib/models/LogMessage');
var console2 = require('lib/Console');

// Reboots should finish within 3 minutes
var MAX_DURATION = 180000;

module.exports = Job.extend({

  defaults: {
    jobType: 'reboot',
    status: 'pending',
  },

  initialize: function() {
    this.startTimeout();
  },

  startTimeout: function() {
    var self = this;

    if (!this.isDone() && this.timeout === undefined) {
      this.timeout = setTimeout(function() {
        self._error(self.collection.mac, 'Job Timeout');
      }, MAX_DURATION);
    }
  },

  stopTimeout: function() {
    clearTimeout(this.timeout);
  },

  /**
   * Initiates a reboot job for a device
   *
   * @param {String} mac
   * @fires reboot:succeeded
   * @fires reboot:failed
   * @returns {jQuery.Deferred}
   */
  start: function(mac) {
    var self = this;

    this.startTimeout();

    return apiChannel
    .request('send', 'DNA.Portal.Device.reboot', {mac: mac})
    .done(function(resp) {
      if (resp.result) {
        self.set('id', resp.result);
      } else {
        self._error(mac, 'No assigned job queue ID from DNA.Portal.Device.reboot');
      }
    }).fail(function(err) {
      self._error(mac, err);
    });
  },

  _error: function(mac, error) {
    this.set('status', 'failed');

    var message = 'Reboot failed for ' + mac + ': ' + JSON.stringify(error);

    console2.log('warn', message);
    (new LogMessage({
      level: 'NOTICE',
      message: message,
      file: 'reboot.js',
    })).save();
  },
});
