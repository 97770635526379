'use strict';

var i18n = require('i18next');
var twig = require('twig').twig;
var tpl = require('components/usageTopClients/key.html');
var BaseKeyView = require('components/usageShared/BaseKeyView');

/**
 * Renders the top clients pie chart
 */
module.exports = BaseKeyView.extend({

  /**
   * @name #model
   * @type {components/usageTopclients/TopClientsModel}
   */

  template: twig({data: tpl}),

  /**
   * Determines the display name for a given client
   *
   * @param {String} name
   * @returns {String}
   */
  getLabelForName: function(name) {
    if (name === 'other') {
      return i18n.t('usageTopClients.other');
    }

    return name.toUpperCase();
  },

  /**
   * Determines the display description for a given client
   *
   * @param {String} name
   * @param {Number} type
   * @returns {String}
   */
  getLabelForType: function(name, type) {
    if (name === 'other') {
      return '';
    }
    if (type === 'unknown') {
      return i18n.t('usageTopClients.unknown');
    }

    return type;
  },
});
