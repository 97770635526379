'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');

/**
 * Edit model for SiteVPN Description
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/description/siteVpn/EditDescription#attributes
   * @property {String} id
   *   The ID of the associated siteVpn topology
   * @property {string} description
   *   Topology description
   */

  _snapshotAttributes: ['description'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return _.isUndefined(this.deviceConfig.get('remoteSites').get(this.id));
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig && !this.isNew()) {
      return {description: this.getDescriptionFromConfig(this.id)};
    }
    return '';
  },

  getDescriptionFromConfig: function(id) {
    return this.deviceConfig.get('remoteSites').get(id).get('description');
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('remoteSites').get(this.id);
      },
      events: 'change:description',
    },
  ],

  /**
   * @return {Object}
   */
  getTask: function() {
    var data = {description: this.get('description')};

    if (!this.isNew()) {
      data.id = this.id;
    }

    return {
      name: 'vpn.setSiteVpnConfigs',
      data: data,
    };
  },

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};
    var validateAll = (options && options.validateAll !== false);

    if (!_.isUndefined(attrs.description) || validateAll) {
      if (_.isEmpty(attrs.description)) {
        errors.description = i18n.t('siteVpnDescription.emptyDescription');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },
});
