'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var Marionette = require('backbone.marionette');
var tpl = require('actions/trafficShaping/form.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var CategoryFormView = require('actions/trafficShaping/FormCategoryView');
var RemoveConfig = require('manage/edit/config/action/removeConfig/RemoveConfig');
require('lib/jquery/bootstrapUI');

var CategoriesListView = Marionette.CollectionView.extend({
  childView: CategoryFormView,
});

/**
 * Renders the Traffic Shaping (QoS) form component.
 */
module.exports = Marionette.View.extend({
  /**
   * @name #model
   * @type {actions/trafficShaping/EditShaping}
   */

  behaviors: [{
    behaviorClass: RemoveConfig,
  }],

  template: twig({data: tpl}),

  regions: {
    categories: '.rg-categories',
  },

  ui: {
    upload: '[name="upload"]',
    download: '[name="download"]',
    messages: '.help-block',
  },

  events: {
    'blur @ui.upload': 'saveForm',
    'blur @ui.download': 'saveForm',
  },

  modelEvents: {
    'invalid': 'onError',

    // normally we'd use the RenderChanges behavior to automate this,
    // but for performance, we don't want to re-render the entire
    // category collection view just because upload or download changed
    'change:upload': 'onUploadChange',
    'change:download': 'onDownloadChange',
  },

  templateContext: function() {
    var context = {
      pendingDeleteMsg: i18n.t('actionTrafficShaping.pendingRemoval'),
    };

    return _.extend(tplHelpers.apply(this), context);
  },

  onRender: function() {
    if (this.model.get('pendingDelete') === true) {
      return;
    }

    this.showChildView('categories', new CategoriesListView({
      collection: this.model.get('categories'),
    }));
  },

  initialize: function() {
    if (!this.model.enabled) {
      this.model.set('enabled', true);
    }
  },

  /**
   * Saves modified values
   *
   * @param {Object} event
   */
  saveForm: function(event) {
    var control = event.target;
    var name = control.name;
    var value = parseFloat(control.value);

    switch (name) {
      case 'upload':
        this.ui.upload.bs3ui('clearGroupedFieldError', 'upload', this.ui.messages);
        if (!isNaN(value)) {
          this.ui.upload.val(value);
        }
        this.model.set({upload: value}, {commit: true});
        break;

      case 'download':
        this.ui.download.bs3ui('clearGroupedFieldError', 'download', this.ui.messages);
        if (!isNaN(value)) {
          this.ui.download.val(value);
        }
        this.model.set({download: value}, {commit: true});
        break;

      default:
        break;
    }
  },

  /**
   * Decorates the view to indicate error.
   *
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    _.forEach(error, function(value, key) {
      var input = this.ui[key];
      if (input) {
        input.bs3ui('showGroupedFieldError', value, key, this.ui.messages);
      }
    }, this);
  },

  /**
   * Updates the UI when upload value changes remotely
   *
   * @param {actions/trafficShaping/EditShaping} model
   * @param {Number} value
   * @param {Object} options
   */
  onUploadChange: function(model, value, options) {
    if (options && options.fromConfig) {
      this.ui.upload.val(value);
    }
  },

  /**
   * Updates the UI when download value changes remotely
   *
   * @param {actions/trafficShaping/EditShaping} model
   * @param {Number} value
   * @param {Object} options
   */
  onDownloadChange: function(model, value, options) {
    if (options && options.fromConfig) {
      this.ui.download.val(value);
    }
  },

});
