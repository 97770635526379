'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('manage/details/dna/wanDetails/wans-wired.html');
var scHelpers = require('lib/tplHelpers/statusCardHelpers');
var tplNetworkHelpers = require('lib/tplHelpers/networkHelpers');
var networkUtils = require('lib/network');
var i18n = require('i18next');

/**
 * Renders the wired WAN details (primary and secondary).
 */
module.exports = Marionette.View.extend({
  /**
   * @member {Backbone.Model} model
   * @property {lib/models/DeviceConfiguration} attributes.deviceConfig
   * @property {lib/models/DeviceStatus} attributes.deviceStatus
   * @property {Backbone.Model} attributes.statusCard
   */

  template: twig({data: tpl}),

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    var debounced = _.debounce(this.render, 1);
    this.listenTo(this.model.get('statusCard'), {
      'change:connected': debounced,
      'change:activeWan': debounced,
      'change:primaryWan': debounced,
      'change:secondaryWan': debounced,
      'change:failedOver': debounced,
      'change:loadBalancingDegraded': debounced,
    });

    this.listenTo(
      this.model.get('deviceConfig').get('networks'),
      'add remove change:description change:wanPriority',
      _.partial(this.modelIsWan, _, debounced));
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    return {
      isLoadBalancingDegraded: this.tplIsLoadBalancingDegraded.bind(this),
      isFailedOver: this.tplIsFailedOver.bind(this),
      wans: this.tplWanDetails.bind(this),
    };
  },

  /**
   * Gets whether or not the DNA is in failover.
   *
   * @method templateContext.isFailedOver
   * @return {Boolean}
   */
  tplIsFailedOver: function() {
    return this.model.get('statusCard').get('failedOver') === true;
  },

  tplIsLoadBalancingDegraded: function() {
    var balanceInfo = this.model.get('deviceStatus').get('failover').get('loadBalanceInfo');
    return _.isUndefined(balanceInfo) ? balanceInfo.degraded === true : false;
  },

  /**
   * Gets WAN details, ordering the WANs by priority.
   *
   * @method templateContext.wans
   * @see getWanDetails
   * @return {Array}
   */
  tplWanDetails: function() {
    var wansList = this.model.get('deviceConfig').getWansByPriority();
    return wansList.map(this.getWanDetails.bind(this));
  },

  /**
   * Gets the group of WAN details needed for the template.
   *
   * @param {Backbone.Model} wanModel
   * @param {Number} i
   * @return {Object}
   */
  getWanDetails: function(wanModel, i) {
    var statusCard = this.model.get('statusCard');
    var status = scHelpers.networkInterfaceStatus(statusCard, wanModel.id);
    var subnet;
    var ip;
    var ipv6;
    var ipv6Subnet;

    subnet = this.model.get('deviceStatus').getSubnet(wanModel.id);
    if (_.isUndefined(subnet)) {
      ip = i18n.t('vlanDetails.unassigned');
    } else {
      ip = subnet.get('address');
    }
    // Check for ipv6
    ipv6Subnet = this.model.get('deviceStatus').getSubnet(wanModel.id + networkUtils.ipv6PostFix);
    if (!_.isUndefined(ipv6Subnet)) {
      ipv6 = ipv6Subnet.get('address');
    }

    return {
      name: tplNetworkHelpers.formatWanName(wanModel.get('portsMap')[0]),
      description: wanModel.get('description'),
      status: status,
      ip: ip,
      ipv6: ipv6,
    };
  },

  /**
   * Determines if the model provided represents a WAN
   *
   * @param {Backbone.Model} model
   * @param {Function} callback
   *   Function to be called if model is a WAN
   */
  modelIsWan: function(model, callback) {
    if (model.get('role') === 'external') {
      callback();
    }
  },

});
