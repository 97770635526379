'use strict';

var _ = require('lodash');
var PortForwardingRule = require('actions/shared/portForwarding/Rule');

module.exports = PortForwardingRule.extend({

  idAttribute: 'id',

  getTask: function() {
    var task = PortForwardingRule.prototype.getTask.apply(this, arguments);

    if (!_.isUndefined(task) && this.get('remove') === false) {
      task.name = 'firewall.addOneToManyNat';
      _.extend(task.data, {'srcIp': this.get('srcIp')});
    }

    return task;
  },

  validate: function(attrs, options) {
    if (options.allItems && _.has(attrs, 'srcIp')) {
      var srcIp = attrs.srcIp;

      options.allItems = _.filter(options.allItems, function(item) {
        return item.get('srcIp') === srcIp;
      });
    }

    return PortForwardingRule.prototype.validate.apply(this, arguments);
  },

});
