'use strict';

var _ = require('lodash');
var ReadOnly = require('backbone.readonly');

/**
 * {@link lib/models/DeviceConfiguration} mixin for container-style models
 *
 * Common housekeeping code for models that contain other models (or collections)
 * as attributes. Requires models using this to have an "attributeTypes" dictionary
 * that maps attribute names to their types (constructors).
 *
 * @mixin lib/mixins/nestedModel
 */
module.exports = {
  /**
   * Registry of attribute types. Override in concrete implementations.
   *
   * @private
   * @type {Object}
   */
  attributeTypes: {
    /*
     name: Type,
     name2: Type2,
     */
  },

  /**
   * Start the model with "empty" sub-models
   *
   * @return {Object}
   */
  defaults: function() {
    return _.mapValues(this.attributeTypes, function(AttributeType, attrName) {
      return new AttributeType();
    });
  },

  /**
   * By default, Backbone does not serialize nested models and collections. We
   * need to do it manually.
   *
   * @param {Object} options
   * @return {Object}
   */
  toJSON: function(options) {
    var json = ReadOnly.Model.prototype.toJSON.apply(this, arguments);
    return _.mapValues(json, function(attr) {
      return attr && _.isFunction(attr.toJSON) ? attr.toJSON() : attr;
    });
  },

  /**
   * By default, Backbone does not perform a deep clone of nested models and
   * collections. We need to do it manually.
   *
   * @return {Object}
   */
  clone: function() {
    return new this.constructor(this.toJSON(), {parse: true, overrideImmutability: true});
  },

};
