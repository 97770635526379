'use strict';

var ConfigMessageTypes = require('manage/edit/ConfigMessageTypes');
var SaveChanges = require('manage/edit/config/group/saveChanges/SaveChanges');
var ConfirmationView = require('manage/edit/config/group/confirmSave/ConfirmationView');

/**
 * Displays a confirmation dialog before proceeding to save changes.
 *
 * Although this is a Behavior it is very tightly coupled
 * to the GroupView and should not be reused as if it was a behavior
 */
module.exports = SaveChanges.extend({
  initialize: function() {
    this.on('confirmationDecisionMade', this.onConfirmationDecision.bind(this));
  },

  /**
   * Save configuration changes.
   *
   * @param {Event|null} ev
   *   This param exists to allow use as an event listener, but is not required
   * @param {Object} options
   *   Options to be passed through to the model's save() method
   */
  onSave: function(ev, options) {
    if (this.view.model.get('isSaveable') === false) {
      return;
    }

    this.view.alertView.trigger('clear', {animate: false});
    this.options.ev = ev;
    this.options.saveOptions = options || {};
    this.view.model._validateActions(this.options.saveOptions).fail(function(errorObj) {
      var errors = errorObj && errorObj.type ?
        errorObj : {type: ConfigMessageTypes.JOB_ERRORS, errors: errorObj};
      this.cleanUp();
      this.showError(errors);
    }.bind(this)).done(function() {
      this.showConfirmationDialog();
    }.bind(this));
  },

  /**
   * Handle user decision on confirmation dialog.
   * @param {Object} data
   */
  onConfirmationDecision: function(data) {
    data.ctx.destroy();
    delete data.ctx;

    if (data.action === ConfirmationView.ACTIONS.ACCEPT) {
      SaveChanges.prototype.onSave.call(this, this.options.ev, this.options.saveOptions);
    }
  },

  /**
   * Displays the confirmation dialog overlay
   */
  showConfirmationDialog: function() {
    this.cleanUp();
    this.$el.append(new ConfirmationView({parent: this}).render().$el);
  },
});
