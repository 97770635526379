'use strict';

var Marionette = require('backbone.marionette');
var Backbone = require('backbone');
var twig = require('twig').twig;
var UsageView = require('components/usage/UsageView');
var tpl = require('components/usage/graphs.html');

/**
 * Renders the usage graphs up/down
 */
module.exports = Marionette.View.extend({
  /**
   * @name components/usage/GraphItem#model
   * @type lib/models/Bandwidth
   */

  template: twig({data: tpl}),

  className: 'usage-graphs placeholder-container',

  ui: {
    noDevices: '.no-devices-connected',
  },

  regions: {
    'graph': '.rg-graph',
  },

  modelEvents: {
    'change:loading': 'render',
  },

  /**
   * @param {Object} options
   * @property {String} options.id
   *  id of the dna, network, or connected device to show graph for
   * @property {String} options.graphType
   *  'dna|network|device'
   * @property {String} options.size
   *  'big|small' - default is big
   * @property {lib/models/Bandwidth} options.usage
   * @property {lib/models/DeviceStatus} options.deviceStatus
   */
  initialize: function(options) {
    this.mergeOptions(options, ['id', 'graphType', 'size', 'deviceStatus']);
    this.model = options.usage;

    if (this.graphType !== 'device') {
      this.listenTo(
        this.deviceStatus.get('connectedDevices'),
        'add remove change:devices',
        this.updateConnectedDevices
      );
    }
  },

  templateContext: function() {
    return {
      isLoaded: this.isLoaded,
    };
  },

  onRender: function() {
    if (!this.isLoaded.call(this.model.toJSON())) {
      return;
    }

    this.createGraph();
    this.updateConnectedDevices();
  },

  isLoaded: function() {
    return !this.loading && !this.error;
  },

  createGraph: function() {
    var usage = new UsageView({
      model: this.getBandwidthData(),
      settings: {
        type: this.size || 'big',
      },
    });

    this.showChildView('graph', usage);
  },

  /**
   * This view's model is the entire collection of bandwidth data.
   * This function finds the subset we want to actually show
   * (i.e. just the DNA overall, a given network, or a connected device)
   *
   * @return {Backbone.Model}
   */
  getBandwidthData: function() {
    var throughput = this.model.get('throughput');
    var dataSubset = throughput.get(this.id);

    if (dataSubset) {
      return dataSubset;
    }

    // if the thing we care about doesn't exist yet in the data set, wait
    // for it to appear and return an empty dummy model in the meantime
    if (!this.waitingForData) {
      this.listenTo(throughput, 'add', this.onThroughputModelAdd);
      this.waitingForData = true;
    }

    return new Backbone.Model({
      data: new Backbone.Collection(),
    });
  },

  onThroughputModelAdd: function(model) {
    if (model.id === this.id) {
      this.stopListening(this.model.get('throughput'), 'add');
      this.waitingForData = false;
      this.render();
    }
  },

  updateConnectedDevices: function() {
    if (this.graphType === 'dna') {
      this.toggleNoDevices(this.determineDnaConnectedDevices());
    } else if (this.graphType === 'internal-network') {
      this.toggleNoDevices(this.determineInterfaceConnectedDevices());
    }
  },

  determineDnaConnectedDevices: function() {
    var hasDevices = false;
    var data = this.deviceStatus.get('connectedDevices');
    data.each(function(network) {
      var devices = network.get('devices');
      if (devices && devices.length > 0) {
        hasDevices = true;
      }
    });
    return hasDevices;
  },

  determineInterfaceConnectedDevices: function() {
    var hasDevices = false;
    var data = this.deviceStatus.get('connectedDevices');
    var id = this.id;
    if (data) {
      var network = data.find(function(item) {
        return item.id === id;
      });
      if (network) {
        var devices = network.get('devices');
        hasDevices = devices && devices.length > 0;
      }
    }
    return !!hasDevices;
  },

  toggleNoDevices: function(hasConnectedDevices) {
    if (hasConnectedDevices) {
      this.ui.noDevices.addClass('hidden');
    } else {
      this.ui.noDevices.removeClass('hidden');
    }
  },
});
