'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/networkObject/form.html');
var NetworkObjectView = require('actions/networkObject/FormNetworkObjectView');
var NetworkAddressSpecification = require('actions/networkObject/NetworkAddressSpecification');
var twig = require('twig').twig;

var NetworkObjectsListView = Marionette.CollectionView.extend({
  childView: NetworkObjectView,

  initialize: function(options) {
    this.options = options || {};
  },
});

module.exports = Marionette.View.extend({
  template: twig({data: tpl}),

  regions: {
    specs: '.rg-specs',
  },

  ui: {
    add: '.btn-add',
    specs: '.help-block',
  },

  events: {
    'click @ui.add': 'addSpec',
  },

  initialize: function() {
    this.listenTo(this.model.get('items'), {
      'change': this.initChildViews(),
    });
  },

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    var view = new NetworkObjectsListView({
      collection: this.model.get('items'),
    });

    this.showChildView('specs', view);
  },

  addSpec: function() {
    var networkAddressSpec = new NetworkAddressSpecification();
    this.model.get('items').add(networkAddressSpec);
  },
});
