'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/router/userTimezone/static.html');
var twig = require('twig').twig;

/**
 * Renders a text-only version of the "user timezone" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/router/userTimezone/StaticView#model
   * @type {actions/router/userTimezone/EditUserTimezone}
   */

  template: twig({data: tpl}),
});
