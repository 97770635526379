'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var Radio = require('backbone.radio');
var ToolboxView = require('manage/edit/toolbox/ToolboxView');
var GroupsView = require('manage/edit/config/group/GroupsView');
var configTypes = require('lib/configTypes');
var tplLayout = require('manage/edit/page/configPage.html');

/**
 * Base view class for rendering a page of configuration options (cards)
 */
module.exports = Marionette.View.extend({
  /**
   * View-Model
   * @member {config/ConfigModel} #model
   */

  /**
   * Private Radio channel to pass along to nested Views. Since those Views
   * are shared by any number of "pages", we can't use a global Radio channel
   * because events will be overwritten by the last "page" rendered.
   * @member {Radio.Channel} #navChannel
   */

  // the id publishes this template for others to {% extend %}
  template: twig({id: 'baseConfigPage', data: tplLayout}),

  className: 'row',

  regions: {
    config: '.rg-config',
    toolboxEdit: '.rg-edit-toolbox',
    toolboxNew: '.rg-new-toolbox',
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.navChannel = new Radio.Channel();
  },

  /**
   * Get the list (in config outline format) of cards to show for this view
   * Intended to be overridden by subclasses
   *
   * @return {Backbone.Collection}
   */
  getActiveCardList: function() {
    return null;
  },

  /**
   * Get the title to be displayed for the toolbox
   * Intended to be overridden by subclasses
   *
   * @return {String}
   */
  getToolboxTitle: function() {
    return null;
  },

  /**
   * Get the filter function that should be used to select types of new cards
   * that can be created for this view (default matches nothing).
   *
   * Filter function will be called with one parameter, a class (constructor)
   * of a Group
   *
   * Intended to be overridden by subclasses, if needed
   *
   * @return {Function}
   */
  getCreationalCardFilter: function() {
    return _.constant(false);
  },

  onRender: function() {
    this.showChildView('toolboxEdit', this.getToolboxView());
    var newView = this.getToolBoxNewView();
    if (newView) {
      this.showChildView('toolboxNew', newView);
    }
    this.showChildView('config', this.getConfigView());
  },

  onBeforeDestroy: function() {
    // clean up our private radio channel
    this.navChannel.reset();
  },

  /**
   * @return {manage/edit/toolbox/ToolboxView}
   */
  getToolboxView: function() {
    return new ToolboxView({
      collection: this.getActiveCardList(),
      title: this.getToolboxTitle(),
      showActions: true,
      channel: this.navChannel,
      configOutline: this.model,
    });
  },

  /**
   * Gets the View representing the list of configuration groups that can be added
   *
   * Note, in getToolboxView(), we pass along a collection of instantiated config
   * group objects. For "creational" toolbox items, we don't have instantiated
   * objects, so we need to define the collection models manually.
   *
   * @return {manage/edit/toolbox/ToolboxView}
   */
  getToolBoxNewView: function() {
    var toolboxNewView;
    var filter = this.getCreationalCardFilter();

    var addGroups = _.reduce(configTypes.groups, function(memo, GroupObj, type) {
      if (filter(GroupObj.Model)) {
        var model = new GroupObj.Model(
          {type: type, actions: new Backbone.Collection()},
          {actionTypes: configTypes.actions}
        );
        memo.push(model);
      }

      return memo;
    }, []);

    if (addGroups.length === 0) {
      return null;
    }

    toolboxNewView = new ToolboxView({
      collection: new Backbone.Collection(addGroups),
      title: i18n.t('toolbox.newCards'),
      showActions: false,
      channel: this.navChannel,
      configOutline: this.model,
    });

    // If the config outline changes, rerender the View in case one or more
    // options need to be filtered.
    this.listenTo(this.getActiveCardList(), 'update', toolboxNewView.render);

    return toolboxNewView;
  },

  /**
   * @return {manage/edit/config/group/GroupsView}
   */
  getConfigView: function() {
    return new GroupsView({
      model: this.model,
      collection: this.getActiveCardList(),
      navChannel: this.navChannel,
    });
  },
});
