'use strict';

var i18n = require('i18next');
var Traceroute = require('actions/traceroute/Traceroute');
var FormView = require('actions/traceroute/FormView');
var StaticView = require('actions/traceroute/StaticView');

/**
 * Action configuration for traceroute
 */
module.exports = {
  title: i18n.t('traceroute.title'),
  Model: Traceroute,
  FormView: FormView,
  TextView: StaticView,
};
