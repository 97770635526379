'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var Radio = require('backbone.radio');
var console2 = require('lib/Console');
var i18n = require('i18next');
var layoutChannel = Radio.channel('layoutChannel');
var layoutTpl = require('manage/detailsLayout/layout.html');
var NavbarView = require('components/navbar/NavbarView');
var NavbarItemView = require('components/navbar/NavbarItemView');
var DetailsViewFactory = require('manage/details/DetailsViewFactory');
var socketChannel = Radio.channel('socket');
var GlobalDeviceMessageView = require('manage/detailsLayout/globalDeviceMessage/GlobalDeviceMessageView');
var GlobalAdminMessageView = require('manage/detailsLayout/globalDeviceMessage/GlobalAdminMessageView');
var UpdateDnaView = require('components/updateDna/UpdateDnaView');
var UpdateDnaViewModel = require('components/updateDna/UpdateDnaModel');
var SystemConfigPageView = require('manage/edit/page/SystemConfigPageView');
var NetworksConfigPageView = require('manage/edit/page/NetworksConfigPageView');
var FirewallConfigPageView = require('manage/edit/page/FirewallConfigPageView');
var ApplicationsConfigPageView = require('manage/edit/page/ApplicationsConfigPageView');
var FleetManagementConfigPageView = require('manage/edit/page/FleetManagementConfigPageView');
var DiagnosticsConfigPageView = require('manage/edit/page/DiagnosticsConfigPageView');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');
var configPage = require('lib/configPage');

/**
 * Part of the "Manage" page, this view renders a specific device's
 * configuration and workbench Views.
 */
module.exports = Marionette.View.extend({
  /**
   * View-Model.
   * @name manage/detailsLayout/DeviceDetailsLayout#model
   * @type {manage/detailsLayout/DeviceDetailsLayoutModel}
   */

  template: twig({data: layoutTpl}),

  // we start off with the View hidden because we don't want to show it until
  // all data dependencies are loaded
  className: 'hidden animated fadeIn quick',

  regions: {
    pageNavbar: '.rg-page-nav',
    statusView: '.rg-status-view',
    systemEdit: '.rg-system-edit',
    networksEdit: '.rg-networks-edit',
    firewallEdit: '.rg-firewall-edit',
    applicationsEdit: '.rg-applications-edit',
    fleetManagementEdit: '.rg-fleetManagement-edit',
    diagnosticsEdit: '.rg-diagnostics-edit',
    adminMessage: '.rg-admin-message',
    globalMessage: '.rg-global-device-message',
    updateMessage: '.rg-update-message',
  },

  ui: {
    description: 'h1 > a',
    fatalError: '.page-no-content',
  },

  events: {
    'click @ui.description': 'onOverview',
  },

  modelEvents: {
    'change:isLoading': 'render',
    'change:page': 'onChangePage',
  },

  /**
   * Base path to use when changing the URL.
   *
   * @type {String}
   */
  basePageUrl: '',

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.detailsViewFactory = new DetailsViewFactory();
    this.basePageUrl += '/' + encodeURIComponent(this.model.get('deviceMac'));
    this.loadCoreData();
    // fetch bandwidth and config history data separately because the UI can continue without it
    this.loadBandwidthData();
    this.loadConfigurationHistory();
  },

  loadCoreData: function() {
    var self = this;
    // fence off this fetch in its own group to ensure it doesn't mix with and wait for less critical requests
    this.model.fetch({group: 'critical'}).done(function() {
      // once initial fetch succeeds, add listeners for updates to model data
      self.listenTo(socketChannel, {
        'device-configuration': self.model.onDeviceConfigUpdate.bind(self.model),
        'device-status': self.model.onDeviceStatusUpdate.bind(self.model),
        'config-outline': self.model.onConfigOutlineUpdate.bind(self.model),
      });
      deviceConfigChannel.reply({
        'get:group:url': self.getGroupUrl.bind(self),
        'goto:group': self.gotoGroup.bind(self),
      });
    });
  },

  loadBandwidthData: function() {
    var self = this;
    this.model.fetchBandwidth().done(function() {
      // once initial fetch succeeds, add listener for updates to bandwidth data
      self.listenTo(socketChannel, {
        'bandwidth-data': self.model.onBandwidthDataUpdate.bind(self.model),
      });
    });
  },

  loadConfigurationHistory: function() {
    var self = this;
    this.model.fetchConfigurations().done(function() {
      // once initial fetch succeeds, add listener for updates to bandwidth data
      self.listenTo(socketChannel, {
        'device-configuration': self.model.onNewDeviceConfig.bind(self.model),
      });
    });
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    return {
      baseUrl: this.basePageUrl,
    };
  },

  onRender: function() {
    this.toggleLoader();

    if (this.model.get('isLoading') === true) {
      // only render child-views after device config/status has been fetched
      return;
    }

    if (this.model.get('loadingFailed') === true) {
      this.showFailsafeDescription();
      this.ui.fatalError.removeClass('hidden');
      return;
    }

    this.showDescription();

    // Note, the order in which the following regions are rendered matters.
    // The update banner registers a Radio event that the global banner uses
    // and both banners register Radio events that the "edit" Views use.
    this.addUpdateMessage();
    this.addAdminMessage();
    this.addGlobalMessage();
    this.addNavbarView();

    if (this.getPageType() === 'status') {
      this.addStatusView();
    }

    this.addEditViews();

    // hide the loader
    this.toggleLoader();

    // show the correct "page" (based on current URL)
    this.togglePage();
  },

  onAttach: function() {
    this.listenTo(this.model.get('deviceConfig').get('router'), 'change:description', this.showDescription);
  },

  onBeforeDestroy: function() {
    this.detailsViewFactory.destroy();
    deviceConfigChannel.reset();
  },

  /**
   * @listens model~change:page
   * @param {manage/detailsLayout/DeviceDetailsLayoutModel} model
   * @param {String} page
   * @param {Object} options
   */
  onChangePage: function(model, page, options) {
    var pageType = this.getPageType();

    if (pageType === 'status') {
      this.addStatusView();
    }

    this.navbarView.triggerMethod('set:active', pageType);

    this.togglePage();
  },

  /**
   * Shows the navbar View.
   */
  addNavbarView: function() {
    var self = this;

    var InverseNavbarView = NavbarView.extend({
      className: 'dui-tabs dui-tabs--inverse',
      childView: NavbarItemView.extend({
        className: 'dui-tabs__item dui-tabs__item--underline',
      }),
    });

    this.initPageList(this.getPageType());

    this.navbarView = new InverseNavbarView({
      collection: this.pageList,
    });

    this.listenTo(this.navbarView, 'navbarItem:selected', function(navItem) {
      var url = navItem.get('url');
      Backbone.history.navigate(url);
      self.model.set('page', this.detailsViewFactory.trimUrl(url));
    });

    this.showChildView('pageNavbar', this.navbarView);
  },

  /**
   * Shows the status View.
   * @listens model~change:page
   */
  addStatusView: function() {
    var statusView;
    var options = this.model.pick(
      'deviceMac', 'deviceConfig', 'deviceStatus', 'bandwidth', 'statusCard', 'configState', 'jobs'
    );

    try {
      statusView = this.detailsViewFactory.factory(
        this.model.get('page'),
        options,
        this.model.get('deviceConfig'),
        this.model.get('deviceStatus')
      );
    } catch (e) {
      // if we couldn't find a specific detail view, redirect to the main overview page
      Backbone.history.navigate(this.basePageUrl);
      this.model.set('page', '');
      return;
    }

    this.showChildView('statusView', statusView);
  },

  /**
   * Shows workbench Views.
   */
  addEditViews: function() {
    var model = this.model.get('configState');
    var configurations = this.model.get('configurations');

    this.showChildView('systemEdit', new SystemConfigPageView({model: model, configurations: configurations}));
    this.showChildView('networksEdit', new NetworksConfigPageView({model: model}));
    this.showChildView('firewallEdit', new FirewallConfigPageView({model: model}));
    this.showChildView('applicationsEdit', new ApplicationsConfigPageView({model: model}));
    this.showChildView('fleetManagementEdit', new FleetManagementConfigPageView({model: model}));
    this.showChildView('diagnosticsEdit', new DiagnosticsConfigPageView({model: model}));
  },

  /**
   * Adds admin message (if any)
   */
  addAdminMessage: function() {
    var statusCard = this.model.get('statusCard');

    var globalAdminMessageView = new GlobalAdminMessageView({
      model: new Backbone.Model({
        statusCard: statusCard,
        page: 'dnaView',
      }),
    });
    this.showChildView('adminMessage', globalAdminMessageView);
  },

  /**
   * Adds the global device message
   */
  addGlobalMessage: function() {
    var deviceStatus = this.model.get('deviceStatus');
    var deviceConfig = this.model.get('deviceConfig');
    var statusCard = this.model.get('statusCard');

    var globalDeviceMessageView = new GlobalDeviceMessageView({
      model: new Backbone.Model({
        mac: deviceStatus.get('mac'),
        deviceConfig: deviceConfig,
        deviceStatus: deviceStatus,
        statusCard: statusCard,
        page: 'dnaView',
      }),
    });
    this.showChildView('globalMessage', globalDeviceMessageView);
  },

  /**
   * Adds the update available message
   */
  addUpdateMessage: function() {
    var deviceStatus = this.model.get('deviceStatus');
    var deviceHealth = this.model.get('statusCard');

    var model = new UpdateDnaViewModel({
      mac: deviceStatus.get('mac'),
      deviceHealth: deviceHealth,
      deviceStatus: deviceStatus,
    });

    this.showChildView('updateMessage', new UpdateDnaView({model: model}));
  },

  /**
   * While there are many "status" pages, this layout technically shows three
   * pages: status, edit and security. The status page is variable.
   *
   * @return {String}
   */
  getPageType: function() {
    var page = this.model.get('page');
    var type = 'status';

    // //FIXME there must be a better way
    if (!_.isUndefined(page)) {
      if (page.search(/system\/?$/) !== -1) {
        type = configPage.SYSTEM;
      } else if (page.search(/networks\/?$/) !== -1) {
        type = configPage.NETWORKS;
      } else if (page.search(/firewall\/?$/) !== -1) {
        type = configPage.FIREWALL;
      } else if (page.search(/applications\/?$/) !== -1) {
        type = configPage.APPLICATIONS;
      } else if (page.search(/fleetManagement\/?$/) !== -1) {
        type = configPage.FLEETMANAGEMENT;
      } else if (page.search(/diagnostics\/?$/) !== -1) {
        type = configPage.DIAGNOSTICS;
      }
    }

    return type;
  },

  /**
   * Sets up the list of pages serviced by this View.
   *
   * @param {String} activePage
   */
  initPageList: function(activePage) {
    // important: the ids of config pages need to match the category keys in config outline
    this.pageList = new Backbone.Collection([
      {
        id: 'status',
        label: i18n.t('configEdit.tabs.status'),
        url: this.basePageUrl,
        isActive: (activePage === 'status'),
        region: 'statusView',
      },
      {
        id: configPage.SYSTEM,
        label: i18n.t('configEdit.tabs.system'),
        url: this.basePageUrl + '/system',
        isActive: (activePage === configPage.SYSTEM),
        region: 'systemEdit',
      },
      {
        id: configPage.NETWORKS,
        label: i18n.t('configEdit.tabs.networks'),
        url: this.basePageUrl + '/networks',
        isActive: (activePage === configPage.NETWORKS),
        region: 'networksEdit',
      },
      {
        id: configPage.FIREWALL,
        label: i18n.t('configEdit.tabs.firewall'),
        url: this.basePageUrl + '/firewall',
        isActive: (activePage === configPage.FIREWALL),
        region: 'firewallEdit',
      },
      {
        id: configPage.APPLICATIONS,
        label: i18n.t('configEdit.tabs.applications'),
        url: this.basePageUrl + '/applications',
        isActive: (activePage === configPage.APPLICATIONS),
        region: 'applicationsEdit',
      },
      {
        id: configPage.FLEETMANAGEMENT,
        label: i18n.t('configEdit.tabs.fleetManagement'),
        url: this.basePageUrl + '/fleetManagement',
        isActive: (activePage === configPage.FLEETMANAGEMENT),
        region: 'fleetManagementEdit',
      },
      {
        id: configPage.DIAGNOSTICS,
        label: i18n.t('configEdit.tabs.diagnostics'),
        url: this.basePageUrl + '/diagnostics',
        isActive: (activePage === configPage.DIAGNOSTICS),
        region: 'diagnosticsEdit',
      },
    ]);
  },

  /**
   * Show the active View, hide the others.
   */
  togglePage: function() {
    var self = this;
    this.pageList.each(function(pageObj) {
      var region = self.getRegion(pageObj.get('region'));
      if (_.isUndefined(region.$el)) {
        return;
      }
      if (pageObj.get('isActive')) {
        region.$el.removeClass('hidden');
      } else {
        region.$el.addClass('hidden');
      }
    });
  },

  /**
   * Shows or hides the app loader. If the device configuration page is the
   * selected page, include the description in the loading message.
   */
  toggleLoader: function() {
    var loaderOpts = {};
    var hostname;

    if (this.model.get('isLoading') === true) {
      if (this.model.has('deviceConfig')) {
        hostname = this.model.get('devicesList').get(this.model.get('deviceMac')).get('hostname');
        loaderOpts.text = 'Loading ' + hostname; // ML text
      }

      layoutChannel.request('show:loader', this, loaderOpts);
    } else {
      layoutChannel.request('destroy:loader', this);
      this.$el.removeClass('hidden');
    }
  },

  /**
   * Updates the description
   *
   * @listens deviceConfig.router~change:description
   */
  showDescription: function() {
    this.ui.description.text(this.model.get('deviceConfig').get('router').get('description'));
  },

  /**
   * Makes a best-effort attempt to display the description
   * when pieces of the model may be missing
   */
  showFailsafeDescription: function() {
    var name = i18n.t('configEdit.unknownHostname', {mac: this.model.get('deviceMac')});
    var device;

    if (this.model.has('deviceMac') && this.model.has('devicesList')) {
      device = this.model.get('devicesList').get(this.model.get('deviceMac'));
      if (device) {
        name = device.get('description');
      }
    }

    this.ui.description.text(name);
  },

  /**
   * @param {Event} ev
   */
  onOverview: function(ev) {
    // if we're in a fatal error state, don't try to navigate when user clicks description
    if (this.model.get('loadingFailed') === true) {
      return;
    }
    ev.preventDefault();
    Backbone.history.navigate(this.basePageUrl);
    this.navbarView.triggerMethod('set:active', 'status');
    this.model.set('page', '');
  },

  /**
   * Returns the URL for a particular group (config card)
   * (Currently these URLs are only granular to the page/view level;
   * in the future they may include the specific card as well via
   * a URL fragment)
   *
   * @param {String} type e.g. editLan
   * @param {String} [typeId] e.g. vlan2
   * @return {String}
   */
  getGroupUrl: function(type, typeId) {
    var categoryKey = this.model.get('configState').getCategoryForConfigGroup(type, typeId);
    if (!categoryKey) {
      console2.log('warn', 'Unable to find group', type, 'with id', typeId);
      return '#';
    }

    var pageObj = this.pageList.get(categoryKey);
    if (!pageObj) {
      console2.log('warn', 'Unable to find page for config outline category:', categoryKey);
      return '#';
    }

    return pageObj.get('url');
  },

  /**
   * Navigates to a particular config group (config card)
   * by Id. Encapsulates logic to find that card
   *
   * @param {String} type e.g. editLan
   * @param {String} [typeId] e.g. vlan2
   */
  gotoGroup: function(type, typeId) {
    var categoryKey = this.model.get('configState').getCategoryForConfigGroup(type, typeId);
    if (!categoryKey) {
      console2.log('warn', 'Unable to find group', type, 'with id', typeId);
      return;
    }

    if (categoryKey !== this.getPageType()) {
      var pageObj = this.pageList.get(categoryKey);
      if (!pageObj) {
        console2.log('warn', 'Unable to find page for config outline category:', categoryKey);
        return;
      }
      var href = pageObj.get('url');
      Backbone.history.navigate(href);
      layoutChannel.request('change:page', {
        deviceMac: this.model.get('deviceMac'),
        page: deviceConfigChannel.request('trim:url', href),
      });
    }

    _.defer(function() {
      deviceConfigChannel.trigger('focus:group', type, typeId);
    });
  },
});
