'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var twig = require('twig').twig;
var i18n = require('i18next');
var tpl = require('actions/router/wifi/static.html');

/**
 * Renders a text-only version of the "Manage global Wi-Fi settings" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/router/wifi/StaticView#model
   * @type {actions/router/wifi/EditWifi}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      frequencyDiff: this.tplFormatFrequency,
    });
  },

  /**
   * Appends "GHz" to frequency values.
   *
   * @method templateContext.frequencyDiff
   * @return {String}
   */
  tplFormatFrequency: function() {
    return this.frequency + 'GHz / ' + this.htmode + 'MHz';
  },
});
