'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/failoverPolicyControl/policies/staticPolicy.html');
var i18n = require('i18next');
var twig = require('twig').twig;

/**
 * Renders a text-only version of an individual custom failover policy.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/failoverPolicyControl/policies/StaticPolicyView#model
   * @type {actions/failoverPolicyControl/policies/Policy}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      protocolText: this._protocolText,
      methodText: this._methodText,
    });
  },

  /**
   * Format model's protocol value for display
   *
   * @returns {String}
   * @private
   */
  _protocolText: function() {
    var valueMap = {
      tcp: i18n.t('failoverPolicyControl.protocolType.tcp'),
      udp: i18n.t('failoverPolicyControl.protocolType.udp'),
    };

    return valueMap[this.proto];
  },

  /**
   * Format model's method value for display
   *
   * @returns {String}
   * @private
   */
  _methodText: function() {
    var valueMap = {
      'icmp-port-unreachable': i18n.t('failoverPolicyControl.methodType.portUnreachable'),
      'tcp-reset': i18n.t('failoverPolicyControl.methodType.reset'),
      'icmp-net-unreachable': i18n.t('failoverPolicyControl.methodType.netUnreachable'),
      'icmp-host-unreachable': i18n.t('failoverPolicyControl.methodType.hostUnreachable'),
      'icmp-proto-unreachable': i18n.t('failoverPolicyControl.methodType.protoUnreachable'),
      'icmp-net-prohibited': i18n.t('failoverPolicyControl.methodType.netProhibited'),
      'icmp-host-prohibited': i18n.t('failoverPolicyControl.methodType.hostProhibited'),
    };

    return valueMap[this.method];
  },

});
