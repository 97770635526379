'use strict';

var _ = require('lodash');
var Backbone = require('backbone');

/**
 * Enhanced functionality for the "set" method that allows more
 * control over validation behavior.
 */

module.exports = Backbone.Model.extend({
  /**
   * @param {String|Object} key
   * @param {mixed} val
   * @param {Object|undefined} options
   * @property {Boolean} options.commit
   *   When set to True, the passed attributes will be validated and, even if
   *   validation fails, they will be saved.
   * @return {Boolean|self}
   *   - Default behavior returns false if validation fails, otherwise it
   *     returns itself.
   *   - If options.forceSet is true, itself is always returned.
   *     Listen for "invalid" events to detect validation failures.
   */
  set: function(key, val, options) {
    var result;

    // the following is taken from Backbone.Model.set()
    // Handle both `"key", value` and `{key: value}` -style arguments.
    var attrs;
    if (typeof key === 'object') {
      attrs = key;
      options = val;
    } else {
      (attrs = {})[key] = val;
    }
    options = options || {};
    // end of code borrowed from Backbone.Model.set()

    if (options.commit === true) {
      options = _.extend(
        {}, options, {validate: true, validateAll: false, forceSet: true}
      );
      // see backbone.extras.js
    }

    result = Backbone.Model.prototype.set.call(this, attrs, options);

    if (result === false && options.forceSet === true) {
      result = this.set(attrs, _.omit(options, 'validate', 'commit'));
    }

    return result;
  },
});
