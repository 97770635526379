'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var i18n = require('i18next');
var tpl = require('layouts/appLayout/loading.html');

/**
 * Displays a loading screen while application initializes.
 */
module.exports = Marionette.View.extend({
  /**
   * @name layouts/appLayout/LoadingView#model
   * @type Backbone.Model
   * @property {String} text
   */

  template: twig({data: tpl}),
  className: 'loader',

  ui: {
    'message': '.text',
  },

  initialize: function(options) {
    this.model = new Backbone.Model();

    if (_.isString(options.text)) {
      // we want to allow passing in an empty string to not use default "Loading..." text
      this.model.set('text', options.text);
    } else {
      this.model.set('text', i18n.t('appLayout.loader.loading'));
    }
  },

  templateContext: function() {
    return {
      showText: function() {
        return this.text !== '';
      },
    };
  },

  onRender: function() {
    this.$el.addClass('loader-app');
  },
});
