'use strict';

var MultiActionItem = require('actions/shared/MultiActionItem');
var _ = require('lodash');
var SpeedDuplexSettings = require('actions/speedDuplexSettings/SpeedDuplexModel');
var PortsLib = require('lib/ports');
var PortSettingsLib = require('lib/portSettings');
var i18n = require('i18next');
var SETTING_ATTRS = ['configuredSpeed', 'configuredDuplex'];

/**
 * Edit model for SpeedDuplexSettings.
 */
module.exports = MultiActionItem.extend({
  /**
   * @member {Object} actions/speedDuplexSettings/EditSpeedDuplexSettings#attributes
   * @property {Collection} items
   *  Collection of objects that represent a port and its configuration settings.
   * @property {Array} speedList
   *  all possible speed values that a port contains.
   * @property {Array} duplexList
   *  the duplex values that a port can support
   */

  collectionModel: SpeedDuplexSettings,

  duplexList: PortSettingsLib.getSupportedDuplex(),
  speedList: PortSettingsLib.getSupportedSpeed(),

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('ports');
      },
      events: 'add remove change',
    },
  ],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  /**
   * Parse response
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var items = this.deviceConfig.get('ports').toJSON();
      _.each(items, function(port) {
        PortsLib.addDefaultInfoToPortConfig(port);
      });

      return {
        items: items,
      };
    }

    return resp;
  },

  validate: function(attrs, options) {
    var errors = MultiActionItem.prototype.validate.apply(this, arguments) || {};
    var self = this;

    // ensure that ports in the same bond aren't being given different settings
    this.deviceConfig.get('nicBonds').forEach(function(bond) {
      var bondPortIds = bond.get('portsMap');
      var bondPortModels = self.get('items').filter(function(port) {
        return _.includes(bondPortIds, port.id);
      });

      if (bondPortModels.length > 0) { // Verify that we have child ports before trying accessing them.
        var firstPortAttrs = bondPortModels[0].pick(SETTING_ATTRS);
        var allMatch = _.every(bondPortModels, function(port) {
          return _.isEqual(firstPortAttrs, port.pick(SETTING_ATTRS));
        });

        if (!allMatch) {
          var err = {
            invalidBondChildPortConfiguration: i18n.t('actionSpeedDuplex.errors.invalidBondChildPortConfiguration'),
          };

          bondPortModels.forEach(function(portModel) {
            portModel.trigger('invalid', portModel, err);
          });

          _.extend(errors, err);
        }
      }
    });

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Overridden to group all ports data into a ports attribute so that the middle layer endpoint
   * doesn't have to figure out which ports we sent to it via the sifter.
   *
   * @returns {Object}
   *  Contains a name for the endpoint to hit, and a ports object that has ethX and speed/duplex
   *  to it.
   */
  getTask: function() {
    var data = {};
    var taskData = MultiActionItem.prototype.getTask.apply(this);

    _.each(taskData, function(task) {
      data[task.portId] = task.data;
    });

    return {
      name: 'portConfigurationOverride.setSpeedDuplexSettings',
      data: {ports: data},
    };
  },
});
