'use strict';

var _ = require('lodash');
var EditPorts = require('actions/ports/EditPorts');
var i18n = require('i18next');

module.exports = EditPorts.extend({
  /**
   * @member {Object} actions/ports/EditPorts#attributes
   * @property {String} id
   *   The Bond name (bondX)
   * @property {actions/ports/Ports} ports
   *   The list of ports available on the DNA.
   */

  /**
   * Overridden to indicate that this model represents ports for use in a bond
   */
  forBond: true,

  /**
   * @return {Boolean}
   */
  isNew: function() {
    var bond = this.deviceConfig.get('nicBonds').get(this.id);
    return _.isUndefined(bond) || bond.isNew();
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig && !this.isNew()) {
      var bonds = this.get('ports').filter(function(port) {
        return port.get('isBond');
      });

      this.get('ports').remove(bonds);
      this.get('ports').each(this._setIsSelected.bind(this));
    }

    if (resp && _.isArray(resp.ports)) {
      this.get('ports').set(resp.ports);
      resp = _.omit(resp, 'ports');
    }

    return resp;
  },

  validate: function(attrs, options) {
    var errors = {};
    var selectedPorts = {};

    if (!_.isUndefined(attrs)) {
      selectedPorts = this.get('ports').filter(function(port) {
        return port.get('isSelected');
      });
    }

    if (selectedPorts.length < 2) {
      errors.notEnoughboundPorts = i18n.t('actionPorts.invalidSelection');
    } else {
      var selectedAutoNegotiatedPorts = selectedPorts.filter(function(port) {
        return port.get('autoNegotiated') === true;
      });

      if (_.inRange(selectedAutoNegotiatedPorts.length, 1, selectedPorts.length)) {
        // Some ports are configured some aren't. --- Why?... force them to fix that.
        errors.portSpeedDuplexMismatch = i18n.t('actionPorts.bondError.portSpeedDuplexMismatch');
      } else if (selectedAutoNegotiatedPorts.length === 0) {
        // All ports are configured
        var firstConfiguredPort = selectedPorts[0];
        var allPortsMatchConfiguredSetting = selectedPorts.every(function(port) {
          return port.get('configuredSpeed') === firstConfiguredPort.get('configuredSpeed') &&
            port.get('configuredDuplex') === firstConfiguredPort.get('configuredDuplex');
        });

        if (!allPortsMatchConfiguredSetting) {
          errors.portSpeedDuplexMismatch = i18n.t('actionPorts.bondError.portSpeedDuplexMismatch');
        }
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object}
   */
  getTask: function() {
    var ports = this._getPortDetailsForTask();
    var data = {};

    if (!this.isNew()) {
      data.id = this.id;
    }

    return {
      name: 'nicBond.create',
      data: _.extend(data, {ports: ports}),
    };
  },

  _setIsSelected: function(port) {
    var selected = !_.isUndefined(port.get('bondId')) && (port.get('bondId') === this.id);
    port.set('isSelected', selected);
  },

  /**
   * Builds the list of ports to add or remove from the Bond
   *
   * @private
   * @return {Object}
   */
  _getPortDetailsForTask: function() {
    return this.get('ports').filter(function(port) {
      return port.get('isSelected');
    }).map(function(port) {
      return port.id;
    });
  },
});
