'use strict';

var Marionette = require('backbone.marionette');
var _ = require('lodash');
var View = require('lib/behaviors/pagination/PaginationView');

/**
 * Behavior for rendering your typical pagination
 *
 * Views using this behavior are required to have a region called 'pages'
 * where they would like the pagination to be rendered. This behavior basically
 * glues in a child view representing the pagination view
 * @see {lib/behaviors/pagination/PaginationView}
 */
module.exports = Marionette.Behavior.extend({

  defaults: {
    itemsPerPage: 5,
    maxVisiblePages: 9,
    page: 1,
    shift: 0,
    updateItemsFilter: function(filter) {
      this.updateItemsFilter(filter);
    },
  },

  ui: {
    'page': '.page',
    'back': '.back',
    'forward': '.forward',
  },

  events: {
    'click @ui.page': 'updatePage',
    'click @ui.back': 'downPage',
    'click @ui.forward': 'upPage',
  },

  initialize: function(options) {
    if (_.isFunction(this.options.items)) {
      this.options.getItems = options.items.bind(this.view);
    }

    if (_.isFunction(this.options.updateItemsFilter)) {
      this.options.updateItemsFilter = this.options.updateItemsFilter.bind(this.view);
    }

    this.options.getMaxPage = this._getMaxPage;

    this.setPage(this.options.page);
  },

  onRender: function() {
    var paginatorView;
    if (this.options.getItems().length > this.options.itemsPerPage) {
      paginatorView = new View({params: this.options});
      this.view.showChildView('pages', paginatorView);
    }
    this.paginatorView = paginatorView;
  },

  updatePage: function(ev) {
    var el = ev.currentTarget;
    var page = parseInt(el.dataset.page);

    this.setPage(page);
  },

  upPage: function() {
    var maxPage = this.options.getMaxPage();

    if (this.options.page + 1 <= maxPage) {
      this.setPage(this.options.page + 1);
    }
  },

  downPage: function() {
    if (this.options.page - 1 > 0) {
      this.setPage(this.options.page - 1);
    }
  },

  setPage: function(page) {
    this.options.page = page;
    this.options.updateItemsFilter(this._getPageFilter(page));
    if (this.paginatorView) {
      this.paginatorView.render();
    }
  },

  _getPageFilter: function(page) {
    var self = this;

    return function(child, index, collection) {
      var lower = (page - 1) * self.options.itemsPerPage;
      var upper = (page * self.options.itemsPerPage) - 1;
      var absoluteIndex = collection.indexOf(child) - self.options.shift;

      return absoluteIndex >= lower && absoluteIndex <= upper;
    };
  },

  _getMaxPage: function() {
    return Math.ceil((this.getItems().length - this.shift) / this.itemsPerPage);
  },
});
