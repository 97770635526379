'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Backbone = require('backbone');
var rpcMixin = require('lib/mixins/rpc2');
var ItemsList = require('components/usageShared/List');

/**
 * Model/loader for top item usage data
 */
module.exports = Backbone.Model.extend(rpcMixin).extend({
  /**
   * @member {Object} #attributes
   * @property {String} deviceMac
   * @property {components/usageShared/ItemsList} items
   *  Loaded list of top items
   * @property {Number} totalBytes
   *  Loaded total throughput over the same time frame (for proportional calculations)
   * @property {Boolean} loading
   * @property {Boolean} error
   *  Whether an error occurred in the last fetch
   */

  /**
   * @member {lib/models/DeviceStatus} #deviceStatus
   */

  defaults: function() {
    return {
      loading: false,
      error: false,
      items: new ItemsList(),
      totalBytes: 0,
    };
  },

  requiredCapability: 'supportsLayer7Classification',

  fetch: function(options) {
    if (!this.get('deviceMac')) {
      throw new Error(this.getMacError());
    }

    this.set({error: false, loading: true});

    return Backbone.Model.prototype.fetch.call(this, options)
      .then(function(result) {
        this.set({error: false, loading: false});
        return result;
      }.bind(this), function(error) {
        this.set({error: true, loading: false});
        return ($.Deferred()).reject(error);
      }.bind(this));
  },

  /**
   * Take the returned result and transform it so that
   * the data ends up in the right place on this model
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   *   The modified response
   */
  parse: function(resp, options) {
    var sortedItems = _.sortBy(resp.result.categories || [], function(item) {
      if (item.name === 'other') {
        // we want 'other' at the end, so make it first before flipping everything to desc
        return -1;
      }
      return item.bytes;
    }).reverse();

    return {
      items: sortedItems,
      totalBytes: resp.result.totalBytes,
    };
  },

  /**
   * Overridden to push collection contents down to the wrapped collection
   *
   * @param {String|Object} key
   * @param {mixed} val
   * @param {Object|undefined} options
   * @return {Object} self
   */
  set: function(key, val, options) {
    // normalize key, value vs. {key: value} style - from Backbone source
    var attrs;
    if (typeof key === 'object') {
      attrs = key;
      options = val;
    } else {
      (attrs = {})[key] = val;
    }
    // end normalize

    if (this.has('items') && attrs.items) {
      this.get('items').set(attrs.items, options);
      attrs = _.omit(attrs, 'items');
    }

    return Backbone.Model.prototype.set.call(this, attrs, options);
  },

});
