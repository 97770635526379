'use strict';

var i18n = require('i18next');
var EditVpnEnabled = require('actions/vpn/enable/EditVpnEnabled');
var FormView = require('actions/vpn/enable/FormView');
var StaticView = require('actions/vpn/enable/StaticView');

/**
 * Action configuration for the VPN enable/disable action component.
 */
module.exports = {
  title: i18n.t('vpn.enable.title'),
  Model: EditVpnEnabled,
  FormView: FormView,
  TextView: StaticView,
};
