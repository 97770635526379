'use strict';

var Marionette = require('backbone.marionette');
var PolicyView = require('actions/failoverPolicyControl/policies/PolicyView');
var PolicyModel = require('actions/failoverPolicyControl/policies/Policy');
var tpl = require('actions/failoverPolicyControl/form.html');
var tplHelpers = require('lib/tplHelpers');
var twig = require('twig').twig;
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');
require('lib/jquery/bootstrapUI');

var PoliciesListView = Marionette.CollectionView.extend({
  childView: PolicyView,

  // wire child views to ToggleFormOff
  childViewTriggers: {
    'render': 'item:render',
    'onoff:status:change': 'item:onoff:status:change',
  },
});

/**
 * Renders the editable form for the collection of Failover Policies
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/failoverPolicyControl/FormView#model
   * @type {actions/failoverPolicycontrol/EditFailoverPolicyControl}
   */

  behaviors: [
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  regions: {
    policies: '.rg-policies',
  },

  ui: {
    mode: '[name="failover-policy-control-option"]',
    customEnabled: '.failover-policy-control-custom',
    add: '.btn-add',
  },

  events: {
    'change @ui.mode': 'checkConfig',
    'click @ui.add': 'addPolicy',
  },

  onRender: function() {
    this.checkCustom();
    this.initChildViews();
  },

  checkCustom: function() {
    if (this.model.get('mode') === 'custom') {
      this.ui.customEnabled.removeClass('hidden');
    } else {
      this.ui.customEnabled.addClass('hidden');
    }
  },

  checkConfig: function(ev) {
    if (ev.currentTarget.name === 'failover-policy-control-option') {
      this.model.set('mode', ev.currentTarget.value);
      this.checkCustom();
    }
  },

  templateContext: function() {
    return tplHelpers.apply(this);
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.showChildView('policies', new PoliciesListView({
      collection: this.model.get('items'),
    }));
  },

  /**
   * Add a new blank policy to the list of policies
   */
  addPolicy: function() {
    var policy = new PolicyModel();
    this.model.get('items').add(policy);
  },

});
