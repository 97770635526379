'use strict';

var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/networkObject/staticSpecification.html');
var twig = require('twig').twig;

/**
 * Renders a text-only version of an individual network object
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/networkObject/StaticNetworkObjectView#model
   * @type {actions/networkObject/StaticNetworkObject}
   */

  template: twig({data: tpl}),
});
