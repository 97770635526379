'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var ActionItem = require('actions/shared/ActionItem');
var networkUtils = require('lib/network');

var MIN_MTU = 68;
var MAX_MTU = 1500;

/**
 * View-model representing MTU for WANs.
 */
module.exports = ActionItem.extend({
  /**
   * @name actions/wanMTU/EditWanMTU#attributes
   * @type {Object}
   * @property {String} id
   *   The interface name (e.g. vlan40)
   */

  _snapshotAttributes: ['mtu'],

  defaults: {
    mtu: 1500,
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    var vlan = this.deviceConfig.getExternalVlans().get(this.id);
    return _.isUndefined(vlan) || vlan.isNew();
  },

  /**
   * Validate user input according to spec
   *
   * @param {Object} attrs
   * @param {Object} options
   * @returns {Object} errors
   */
  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'mtu')) {
      if (!networkUtils.validInt(attrs.mtu, MIN_MTU, MAX_MTU)) {
        errors.mtu = i18n.t('actionWanMTU.invalidInput', {min: MIN_MTU, max: MAX_MTU});
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   *   The modified response.
   */
  parse: function(resp, options) {
    if (options && options.fromConfig && !this.isNew()) {
      var network = this.deviceConfig.getExternalVlans().get(this.id);
      if (!_.isNull(network.get('mtu'))) {
        return {mtu: network.get('mtu')};
      }
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.getExternalVlans().get(this.id);
      },
      events: 'change:mtu',
    },
  ],

  /**
   * @return {Object}
   */
  getTask: function() {
    var data = {
      id: this.id,
      mtu: this.get('mtu'),
    };

    return {
      name: 'vlan.create',
      data: data,
    };
  },
});
