'use strict';

var Backbone = require('backbone');
var rpcMixin = require('lib/mixins/rpc2');

/**
 * Handles sending errors to the backend and logging them.
 *
 * This class should only be used to send critical frontend errors to the
 * backend. If you need to log something for development purposes, use the
 * browser's console.* methods or, for log messages that you want part of
 * the codebase, see {@link lib/Console.js}.
 *
 * @constructor
 * @augments {Backbone.Model}
 * @mixes {lib/mixins/rpc2}
 * @name lib/models/LogToServer
 */
module.exports = Backbone.Model.extend(rpcMixin).extend({
  /**
   * @name lib/models/LogMessage#attributes
   * @property {string} message
   *  Error message thrown
   * @property {string} level
   *  Level to log error as (corresponds to a const of the Datto\Logger\Logger class)
   * @property {string} url
   *  url the error occurred at
   * @property {file}
   *  File the error occurred at
   * @property {int} line
   *  lineNumber that caused the error
   */

  defaults: {
    message: '',
    level: 'CRITICAL',
    url: '',
    file: '',
    line: '',
  },

  /**
   * Only need a create method for now since entries will not be
   * updated or destroyed.
   */
  rpc: {
    create: {
      method: 'DNA.Portal.LogWriter.log',
      params: function() {
        return this.toJSON();
      },
    },
  },
});
