'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var Ip = require('lib/Ip');
var networkUtils = require('lib/network');
var LogMessage = require('lib/models/LogMessage');
var console2 = require('lib/Console');

/**
 * View-Model for creating/editing a VLAN subnet.
 */
module.exports = ActionItem.extend({
  /**
   * @name actions/staticAddress/AbstractEditAddress#attributes
   * @type {Object}
   * @property {lib/models/DeviceStatus} attributes.deviceStatus
   * @property {String} id
   *   The VLAN name (e.g. vlan40)
   * @property {String} af
   *   The address family; ipv4 or ipv6
   * @property {string} role
   *   Expects "internal" for a LAN subnet and "external" for a WAN subnet.
   * @property {String} method
   *   The address type: dhcp (ipv4 only), static (ipv4 and ipv6),
   *   Stateless (ipv6 only).
   * @property {String} address
   *   The Vlan's network address.
   * @property {Number} size
   *   The Vlan's subnet mask in CIDR notation.
   * @property {String} gateway (external vlans only)
   * @property {String} usage
   *   Intended usage for this address; potential values:
   *      - "network" : this address defines the start of a subnet
   *      - "router"  : this address belongs to a router for the given network
   *      - "client"  : this address belongs to a general networked device
   * @property {Boolean} newAddressHasError
   *  When an invalid ip is entered in the ui, the model's address is not
   *  changed. This flag allows us to determine if the currently entered
   *  ip is valid.
   */

  /**
   * @param {Object} attributes
   * @param {Object} options
   */
  initialize: function(attributes, options) {
    this.addDeviceStatus(options);
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    var vlan = this.deviceConfig.get('networks').get(this.id);
    return _.isUndefined(vlan) || vlan.isNew();
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig && !this.isNew()) {
      var interfaceId = this.getInterfaceId();
      // vlan id is already set
      var vlan = this.deviceConfig.get('networks').get(interfaceId);
      var address = this.deviceConfig.getSubnet(interfaceId);

      resp = address.toJSON();
      resp.role = vlan.get('role');
      if (resp.role === 'internal') {
        resp.ipconflict = vlan.get('ipconflict');
      }

      delete resp.id;

      resp.af = resp.type;
      delete resp.type;

      if (!_.isUndefined(resp.defaultGateway)) {
        resp.gateway = resp.defaultGateway;
        delete resp.defaultGateway;
      }
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks').get(this.getInterfaceId());
      },
      events: 'change:addressMap change:ipconflict',
    },
    {
      getDispatcher: function(config) {
        return config.getSubnet(this.id);
      },
      events: 'change',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};
    var afWhiteList = ['ipv4', 'ipv6'];
    var validateAll;
    var testIfAddressOverlaps = false;
    var overlappingRange;

    options = options || {};

    validateAll = (options.validateAll !== false);

    if (_.has(attrs, 'af')) {
      if (_.contains(afWhiteList, attrs.af) !== true) {
        errors.af = i18n.t('actionStaticAddress.badAddressType');
      }
    }

    if (_.has(attrs, 'address') || validateAll) {
      if (!networkUtils.validIP(attrs.address, this.get('af'))) {
        errors.address = i18n.t('actionStaticAddress.badIP');
      } else if (this.get('role') === 'internal' && !networkUtils.validInternalIPv4(attrs.address)) {
        errors.address = i18n.t('actionStaticAddress.notPrivateIP');
      } else {
        testIfAddressOverlaps = true;
      }
    }

    if (_.has(attrs, 'size') || validateAll) {
      if (_.isUndefined(attrs.size) ||
          isNaN(attrs.size) ||
          attrs.size === 0
      ) {
        errors.size = i18n.t('actionStaticAddress.badMask');
      } else {
        testIfAddressOverlaps = true;
      }
    }

    if (testIfAddressOverlaps || validateAll) {
      try {
        overlappingRange = this.determineOverlappingRange(attrs.address, attrs.size);
        if (!_.isEmpty(overlappingRange)) {
          errors.address = overlappingRange;
        }
      } catch (e) {
        errors.address = i18n.t('actionStaticAddress.unexpectedError');

        var msg = 'Subnet conflict validation error: ' + e.message;
        (new LogMessage({
          level: 'ERROR',
          'message': msg,
          'url': window.location.href,
          'file': 'AbstractEditAddress.js',
        })).save();
        console2.log('error', msg);
      }
    }

    if (this.get('role') === 'external') {
      if (_.has(attrs, 'gateway') || validateAll) {
        if (!networkUtils.validIP(attrs.gateway, this.get('af'))) {
          errors.gateway = {invalid: i18n.t('actionStaticAddress.badIP')};
        }

        errors = this.addressInSubnet(attrs.gateway, 'gateway', errors);
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object}
   */
  getTask: function() {
    var data = {
      address: this.pick(_.without(this._snapshotAttributes, 'pendingDelete')),
    };

    data.address.method = 'static';

    if (!this.isNew()) {
      data.id = this.id;
    }

    return {
      name: 'vlan.create',
      data: data,
    };
  },

  /**
   * Checks if the new subnet overlaps with any existing LANs.
   *
   * @param {String} ip
   * @param {Number} mask
   * @returns {string}
   *   An error message or no message
   */
  determineOverlappingRange: function(ip, mask) {
    var vlans = this.deviceConfig.getVlans();
    var vpn = this.deviceConfig.getVpn();
    var self = this;
    var network;
    var overlappingNetwork;
    var overlappedStart;
    var overlappedEnd;
    var error = '';

    // Though flagged as its own network type rather than 'vlan', the client
    // VPN can occupy a subnet so we must check for conflicts with it
    if (vpn && vpn.get('enabled') === true) {
      vlans.push(vpn);
    }

    if (_.isUndefined(ip)) {
      ip = this.get('address');
    }

    if (_.isUndefined(mask)) {
      mask = this.get('size');
    }

    if (_.isUndefined(ip) || _.isUndefined(mask)) {
      return '';
    }

    network = new Ip(ip + '/' + mask, {type: this.get('af'), output: 'integer'});

    overlappingNetwork = vlans.find(function(vlan) {
      if (vlan.id === self.id) {
        return false;
      }

      var vlanNetwork;

      // Prefer to look up subnets from the config, unless the vlan is
      // a WAN using a dynamic addressing method
      var vlanSubnet = self.deviceConfig.getSubnet(vlan.id);

      if (vlanSubnet.get('method') !== 'static') {
        vlanSubnet = self.deviceStatus.getSubnet(vlan.id);
      }

      if (_.isUndefined(vlanSubnet)) {
        return false;
      }

      var vlanIp = vlanSubnet.get('address');
      var vlanMask = vlanSubnet.get('size');

      if (!vlanIp || !vlanMask) {
        return false;
      }

      vlanNetwork = new Ip(vlanIp + '/' + vlanMask, {
        type: vlanSubnet.get('af'),
        output: 'integer',
      });

      if (self.overlapsSubnet(network, vlanNetwork)) {
        overlappedStart = vlanNetwork.string().network();
        overlappedEnd = vlanNetwork.string().broadcast();
        return vlan;
      }

      if (vlanMask === 32 && vlanSubnet.has('defaultGateway')) {
        // this is a point-to-point link so we need to check against the gateway also
        var gateway = new Ip(vlanSubnet.get('defaultGateway') + '/32', {
          type: vlanSubnet.get('af'),
          output: 'integer',
        });
        if (self.overlapsSubnet(network, gateway)) {
          overlappedStart = gateway.string().network();
          overlappedEnd = gateway.string().broadcast();
          return vlan;
        }
      }

      return false;
    });

    if (!_.isUndefined(overlappingNetwork)) {
      var overlapDesc = overlappingNetwork.get('description');
      error = i18n.t('actionStaticAddress.overlappingRange', {
        network: !_.isEmpty(overlapDesc) ? overlapDesc : overlappingNetwork.id,
        start: overlappedStart,
        end: overlappedEnd,
      });
    }

    return error;
  },

  /**
   * Compares two {lib/Ip} objects to see if their addresses overlap.
   *
   * Note, this method was created to make it easier to unit test.
   *
   * @todo should this method actually be part of {lib/Ip}
   *
   * @param {lib/Ip} subnet1
   * @param {lib/Ip} subnet2
   * @return {Boolean}
   *   Returns true if subnet1's address overlaps subnet2's address.
   */
  overlapsSubnet: function(subnet1, subnet2) {
    var range1 = {start: subnet1.network(), end: subnet1.broadcast()};
    var range2 = {start: subnet2.network(), end: subnet2.broadcast()};
    return (
      (range2.start >= range1.start && range2.start <= range1.end) ||
      (range2.end >= range1.start && range2.end <= range1.end) ||
      (range1.start >= range2.start && range1.start <= range2.end)
    );
  },

  /**
   * Checks that the address is within the subnet, based on the primary IP.
   *
   * @param {String} address
   * @param {String} type
   * @param {Object} errors
   * @return {Object}
   */
  addressInSubnet: function(address, type, errors) {
    var ip;
    var primaryIp = this.get('address');
    var mask = parseInt(this.get('size'));

    if (_.isEmpty(address) || mask <= 0) {
      return errors;
    }

    try {
      ip = new networkUtils.Ip(primaryIp + '/' + mask, {type: this.get('af')});
    } catch (e) {
      return errors;
    }

    if (!ip.inSubnet(address)) {
      var msg;
      var bounds = {
        ip1: ip.network(),
        ip2: ip.broadcast(),
      };
      if (mask === 31) {
        msg = i18n.t('actionStaticAddress.addressNotInRange31', bounds);
      } else {
        msg = i18n.t('actionStaticAddress.addressNotInRange', bounds);
      }
      errors[type] = {
        subnet: msg,
      };
    }

    return errors;
  },

  /**
   * Checks if the router address is not at the beginning or the end of the
   * subnet range.
   *
   * @return {Boolean}
   */
  hasNonStandardRouterIp: function() {
    var suggested = this.getSuggestedRouterIps();
    var address = this.get('address');

    if (suggested === false) {
      // problem parsing the address
      return;
    }

    return (address !== suggested.start && address !== suggested.end);
  },

  /**
   * Gets the suggested router addresses.
   *
   * @return {Array}
   */
  getSuggestedRouterIps: function() {
    var ip;

    try {
      ip = new networkUtils.Ip(this.get('address') + '/' + this.get('size'), {
        type: this.get('af'),
        output: 'integer',
      });
    } catch (e) {
      return false;
    }

    return {
      start: networkUtils.Ip.long2ip(ip.network() + 1),
      end: networkUtils.Ip.long2ip(ip.broadcast() - 1),
    };
  },

  /**
   * Gets the suggested network address.
   *
   * @return {Array}
   */
  getSuggestedNetworkIp: function() {
    var ip;
    if (!networkUtils.validIP(this.get('address'), this.get('af'))) {
      return false;
    }

    try {
      ip = new networkUtils.Ip(this.get('address') + '/' + this.get('size'), {
        type: this.get('af'),
        output: 'integer',
      });
    } catch (e) {
      return false;
    }

    return networkUtils.Ip.long2ip(ip.network());
  },

  /**
   *
   * @return {String}
   */
  getInterfaceId: function() {
    return this.id;
  },
});
