'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var twig = require('twig').twig;
var i18n = require('i18next');
var tplError = require('manage/edit/error.html');
var console2 = require('lib/Console');
var LogMessage = require('lib/models/LogMessage');
var BaseAlertView = require('components/alert/AlertView');
var MessageTypes = require('manage/edit/ConfigMessageTypes');

/**
 * Renders one or more pending-config-related error messages.
 *
 * A pending config has few error states:
 * 1. FAILED_VALIDATION
 *    One or more actions have validation issues and cannot be submitted.
 * 2. FAILED_APPLY
 *    An error occurred applying the pending config to a device.
 * 3. TASKS_ERROR
 *    This is bad. The "tasks" list could not be processed.
 */
module.exports = BaseAlertView.extend({
  template: twig({data: tplError}),

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    if (!options || !options.model) {
      this.model = new Backbone.Model();
    }

    this.on({
      'show': this.onShowMessage,
      'clear': this.onClearMessage,
    });
  },

  /**
   * Renders the error messages.
   *
   * @listens manage/edit/ConfigMessage~show
   * @param {Object} options
   */
  onShowMessage: function(options) {
    var data = {};
    var isSuccess = false;

    if (_.isUndefined(options)) {
      // Doode, why?
      data = this.getLogicErrorAlert({});
      options = {type: '', error: 'Something went really wrong, and there is no data to report what happened.'};
    }

    switch (options.type) {
      case MessageTypes.FAILED_VALIDATION:
        data = this.getValidationAlert();
        break;
      case MessageTypes.REQUIRE_DNS:
        data = this.getDnsAlert();
        break;
      case MessageTypes.TASKS_ERROR:
        data = this.getLogicErrorAlert(options.error);
        break;
      case MessageTypes.CLONE_CONFIG_NO_CHANGES:
      case MessageTypes.NO_CHANGES:
        data = this.getNoChangesAlert(options.type);
        break;
      case MessageTypes.QUEUE_APPLIED:
        data = this.getQueueApplied();
        isSuccess = true;
        break;
      case MessageTypes.JOB_ERRORS:
        data = this.getJobErrors(options.errors);
        break;
      case MessageTypes.INVALID_UCI:
        data = this.getInvalidUciErrors(options.errors);
        break;
      case MessageTypes.JOB_TIMEOUT:
        data = this.getTimeoutAlert();
        break;
      case MessageTypes.TEST_TIMEOUT:
        data = this.getTestTimeoutAlert();
        break;
      case MessageTypes.DHCP_ERROR:
        data = this.getDhcpAlert(options.errorCode);
        break;
      case MessageTypes.CAPABILITY_UNSUPPORTED:
        data = this.getCapabilityAlert();
        break;
      default:
        break;
    }

    this.model.set(data);
    this.model.set('severity', (isSuccess ? 'success' : 'error'));
    this.render().$el.show();

    if (isSuccess === true) {
      window.setTimeout(this.onClearMessage.bind(this), 5000);
    }
  },

  /**
   * Removes the error messages.
   *
   * @listens manage/edit/ConfigMessage~clear
   * @param {Object} options
   * @property {Boolean} animate
   *   Set to false to remove the alert without the default animation.
   */
  onClearMessage: function(options) {
    if (!this.$el.hasClass('alert')) {
      return;
    }

    var self = this;

    var clear = function() {
      self.$el.hide().empty();
    };

    if (options && options.animate === false) {
      clear();
    } else {
      this.$el.slideUp(clear);
    }
  },

  getNoChangesAlert: function(errorType) {
    var errorMsg = i18n.t('configEdit.queueNoChangesError');
    switch (errorType) {
      case MessageTypes.CLONE_CONFIG_NO_CHANGES:
        errorMsg = i18n.t('configEdit.cloneConfigNoChangesError');
        break;
      default:
        break;
    }

    return {
      msg: errorMsg,
    };
  },

  /**
   * Gets the data object to render a message indicating the configuration
   * group cannot be submitted due to validation issues.
   *
   * @return {Object}
   */
  getValidationAlert: function() {
    return {
      msg: i18n.t('configEdit.queueValidationError'),
    };
  },

  /**
   * Gets the data object to render a message indicating the configuration
   * group cannot be submitted due to requiring custom dns with static IPs.
   *
   * @return {Object}
   */
  getDnsAlert: function() {
    return {
      msg: i18n.t('configEdit.dnsStaticIpError'),
    };
  },

  /**
   * Gets the data object to render a message indicating the configuration
   * group cannot be submitted due to requiring custom dns with static IPs.
   *
   * @param {int} errorCode
   * @return {Object}
   */
  getDhcpAlert: function(errorCode) {
    var msg;
    switch (errorCode) {
      case 2:
        msg = i18n.t('configEdit.dhcpOptionsError');
        break;
      default:
        msg = i18n.t('configEdit.dhcpError');
        break;
    }
    return {
      'msg': msg,
    };
  },

  /**
   * Gets the data object to render a message indicating shit hit the fan.
   *
   * @param {Object} error
   * @return {Object}
   */
  getLogicErrorAlert: function(error) {
    console2.log('error', 'Applying changes failed: ', error);
    (new LogMessage({
      file: 'manage/edit/ConfigMessage.js',
      message: 'Error encountered before changes sent to DNA: ' + JSON.stringify(error),
    })).save();

    return {
      msg: i18n.t('configEdit.queueApplyError'),
    };
  },

  /**
   * Gets the data object to render a message indicating queue applied.
   *
   * @return {Object}
   */
  getQueueApplied: function() {
    return {
      msg: i18n.t('configEdit.queueApplied'),
    };
  },

  /**
   * Gets the data object to render a message indicating queue applied.
   *
   * @return {Object}
   */
  getCapabilityAlert: function() {
    return {
      msg: i18n.t('configEdit.capabilityMissing'),
    };
  },

  /**
   * Gets the data object to render a message indicating queue applied.
   *
   * @param {Object} errors
   * @return {Object}
   */
  getJobErrors: function(errors) {
    console2.log('error', 'Apply changes failed: ', errors);
    (new LogMessage({
      file: 'manage/edit/ConfigMessage.js',
      message: 'Applying changes failed: ' + JSON.stringify(errors),
    })).save();

    return {
      msg: i18n.t('configEdit.queueApplyError'),
    };
  },

  /**
   * Gets the data object to render a message indicating invalid UCI detected
   *
   * @param {Object} errors
   * @return {Object}
   */
  getInvalidUciErrors: function(errors) {
    console2.log('error', 'Apply changes failed: ', errors);
    (new LogMessage({
      file: 'manage/edit/ConfigMessage.js',
      message: 'Applying changes failed: ' + JSON.stringify(errors),
    })).save();

    return {
      msg: i18n.t('configEdit.invalidUciError'),
    };
  },

  /**
   * Gets the data object to render a message indicating the UI timed out.
   *
   * @return {Object}
   */
  getTimeoutAlert: function() {
    console2.log('error', 'Apply changes timed out');
    (new LogMessage({
      file: 'manage/edit/ConfigMessage.js',
      message: 'Applying changes timed out',
    })).save();

    return {
      msg: i18n.t('configEdit.queueApplyTimeout'),
    };
  },

  /**
   * Gets the data object to render a message indicating the UI timed out.
   *
   * @return {Object}
   */
  getTestTimeoutAlert: function() {
    console2.log('error', 'Operator Test timed out');
    (new LogMessage({
      file: 'manage/edit/ConfigMessage.js',
      message: 'Running test timed out',
    })).save();

    return {
      msg: i18n.t('configEdit.testRunTimeout'),
    };
  },
});
