'use strict';
/* global Storage, sessionStorage*/

var _ = require('lodash');
var $ = require('jquery');
var Backbone = require('backbone');
var UserModel = require('app/UserModel');
var Radio = require('backbone.radio');
var userChannel = Radio.channel('userChannel');
var apiChannel = Radio.channel('apiChannel');
var appConfig = require('./../appConfig.prod.js');
var SSO = require('lib/DattoSsoJwt');

/**
 * Handles the current user's session.
 */
module.exports = Backbone.Model.extend({
  /**
   * @member {Object} app/SessionModel#attributes
   * @property {Boolean} loggedIn
   *   Whether or not the current user is logged-in
   * @property {app/UserModel} user
   * @property {String} redirectForm
   *   If user is being bounced to the login page, store the page they were
   *   trying to go to for later.
   * @property {Boolean} sessionLoading
   *   Indicates if the user session is being retrieved.
   */

  defaults: {
    loggedIn: false,
    sessionLoading: false,
  },

  /**
   * @param {Object} attributes
   * @param {Object} options
   */
  initialize: function(attributes, options) {
    _.bindAll(this, 'login', 'logout', 'expire');

    this.set('user', new UserModel());

    // TODO set up csrf token

    this.sso = new SSO();
    this.sso.clientName = appConfig.ssoClientName;
    this.sso.authURL = appConfig.ssoAuthUrl;
    this.sso.logoutURL = appConfig.ssoLogoutURL;

    this.supportStorage = (Storage && sessionStorage);

    this._listen();
  },

  /**
   * Sets up listeners.
   * @private
   */
  _listen: function() {
    var self = this;

    this.on('change:loggedIn', this._onAuthChange);

    userChannel.reply('login:user', this.login);
    userChannel.reply('logout:user', this.logout);
    userChannel.on('user:timeout', this.expire);

    userChannel.reply('get:user', function() {
      return self.get('user');
    });
  },

  /**
   * @return {boolean}
   */
  isAnonymous: function() {
    return (this.get('loggedIn') === false);
  },

  /**
   * Checks if user is authenticated.
   *
   * API notes:
   * - not logged in, resp = { result: false }
   * - logged in, resp = { result: true }
   *
   * @return {Object} jQuery promise
   */
  checkAuth: function() {
    var self = this;
    var deferred = $.Deferred();
    var token;
    if (this.sso.isAuthenticated()) {
      token = this.sso.getToken();
      this.set('sessionLoading', true);
      this.login({jwt: token})
        .done(function(resp) {
          sessionStorage.setItem('token', token);
          self.sso.cleanUrl();
          deferred.resolve(resp);
        })
        .fail(function(resp) {
          deferred.reject(resp);
        });
    } else {
      self.sso.login(window.location.toString());
    }

    return deferred.promise();
  },

  /**
   * Attempts to log user in.
   *
   * API notes:
   * - When validation fails, resp = { error: { code: #, ... }}
   * - When success, resp = { result: true }
   *
   * @listen userChannel.request#login:user
   * @fires userChannel.trigger#user:loggedIn
   * @param {Object} params
   * @property {String} params.username
   * @propperty {String} params.password
   * @return {jQuery.Promise}
   *   Promise resolves when user is logged in successfully
   *   Promise is rejected if the user entered incorrect credentials
   */
  login: function(params) {
    var self = this;
    var deferred = $.Deferred();

    apiChannel.request('send', 'DNA.Portal.Login.login', params)
      .done(function(resp) {
        var path = '';

        if (typeof resp.error == 'object') {
          deferred.reject(resp.error);
          // TODO radio event to indicate authentication failed?
          return; // BAIL
        }

        self.set({loggedIn: true});

        if (self.get('redirectFrom')) {
          path = self.get('redirectFrom');
          self.unset('redirectFrom');
        }

        Backbone.history.navigate(path, {trigger: true});

        // general-purpose event for any component that cares
        Radio.trigger('userChannel', 'user:loggedIn');

        deferred.resolve(resp.result);
      })
      .fail(function(resp) {
        deferred.reject(resp); // TODO meaningful error data
      });

    return deferred.promise();
  },

  /**
   * Loads user's session information.
   *
   * @private
   * @listens change:isLoggedIn
   * @param {app/SessionModel} model
   * @param {Boolean} isLoggedIn
   */
  _onAuthChange: function(model, isLoggedIn) {
    var self = this;
    this.set('sessionLoading', true);
    if (isLoggedIn) {
      this.get('user').fetch().always(function() {
        self.set('sessionLoading', false);
      });
    }
  },

  /**
   * Logs user out.
   *
   * API notes:
   * - on success, resp = { result: true }
   * - user not logged in, resp = { error: { code: #, ..,} }
   *
   * @listen userChannel.request#logout:user
   * @param {Object} params
   * @return {jQuery.Promise}
   */
  logout: function() {
    var self = this;
    var logout;

    logout = apiChannel.request('send', 'DNA.Portal.Login.logout', {});

    logout.done(function(resp) {
      if (resp.result) {
        sessionStorage.clear();
        self.expire({forced: true});
      }
    });

    return logout;
  },

  /**
   * Cleans the session and redirects user to login page. Used when user logs
   * out or their session timed out.
   *
   * @listen userChannel.trigger#user:timeout
   * @fires userChannel.trigger#user:loggedOut
   * @param {Object} options
   * @property {Boolean} options.forced
   *   If true, it indicates the user chose to log out. The user's session did
   *   not time out.
   */
  expire: function(options) {
    if (this.supportStorage) {
      sessionStorage.clear();
    }

    // general-purpose event for any component that cares
    Radio.trigger('userChannel', 'user:loggedOut');
    this.sso.logout();
  },

});
