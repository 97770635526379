'use strict';

var _ = require('lodash');
var BaseEditWanDns = require('actions/wanDns/EditWanDns');

/**
 * View-model representing DNS LTE. LTE extends WAN as most of the functionality in WAN DNS
 * is the same for LTE except for the task needed to be used.
 */
module.exports = BaseEditWanDns.extend({
  /**
   * @return {Object}
   */
  getTask: function() {
    var data = {id: this.id};

    var apiName = 'dns.createLteDns';
    if (this.get('pendingDelete') === true) {
      apiName = 'dns.deleteLteDns';
    } else {
      data.dns = this.pick(_.without(this._snapshotAttributes, 'pendingDelete'));
    }

    return {
      name: apiName,
      data: data,
    };
  },
});
