'use strict';

var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');

/**
 * Edit model for VPN's "enabled" setting.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} #attributes
   * @property {Boolean} enabled
   *   Whether the VPN service is enabled
   */

  _snapshotAttributes: ['enabled'],

  defaults: {
    enabled: false,
  },

  /**
   * Overridden to always return false (VPN is a fixed group)
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  getOnOffState: function() {
    return !!this.get('enabled');
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var attrs = {};
      var vpn = this.deviceConfig.getVpn();
      if (vpn) {
        attrs.id = vpn.id;
        attrs.enabled = vpn.get('enabled');
      }
      return attrs;
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('networks');
      },
      // improvement: add support for event param filters,
      // so we only react if network type==='vpn' is added
      events: 'add change:enabled',
    },
  ],

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    return {
      name: 'vpn.setOptions',
      data: {
        id: this.id,
        enabled: this.get('enabled'),
        // not currently user-editable, but set by the front end
        // so that it will match the user's locale. A description
        // helps subnet conflict error messages in the LAN cards
        // be meaningful when the conflict is with the VPN subnet
        description: i18n.t('groups.vpn'),
      },
    };
  },

});
