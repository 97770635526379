'use strict';

var i18n = require('i18next');
var EditIntrusionDetection = require('actions/intrusionDetection/EditIntrusionDetection');
var FormView = require('actions/intrusionDetection/FormView');
var StaticView = require('actions/intrusionDetection/StaticView');

/**
 * Action configuration for Intrusion Detection
 */
module.exports = {
  title: i18n.t('actionIntrusionDetection.title'),
  Model: EditIntrusionDetection,
  FormView: FormView,
  TextView: StaticView,
};
