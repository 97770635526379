'use strict';

var _ = require('lodash');
var i18n = require('i18next');
var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var networkUtils = require('lib/network');
var dataTypes = require('lib/dataTypes');

var PROTOCOLS = {
  TCP: 'tcp',
  UDP: 'udp',
  TCPUDP: 'tcpudp',
  ICMP: 'icmp',
  UDPLITE: 'udplite',
  ESP: 'esp',
  AH: 'ah',
  SCTP: 'sctp',
  OSPF: 'ospf',
  ALL: 'all',
  OTHER: 'other',
};

/**
 * Represents an individual traffic policy rule
 */
module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({

  /**
   * @member {Object} #attributes
   * @property {String} ruleId
   *   ID of the rule
   * @property {Boolean} remove
   *   Whether the rule should be removed on save
   * @property {String} description
   *   Description of the rule
   * @property {String} action
   *   Action for the rule: one of the values "ACCEPT"|"DROP"
   * @property {Boolean} order
   *   Priority order for the rule
   * @property {Number} srcPort
   *   Incoming port (or port range) to "ACCEPT"|"DROP"
   * @property {String} proto
   *   protocol to forward; one of the values "tcp"|"udp"|"tcpudp"|"icmp"|"udplite"|"esp"|"ah"|"sctp"| ...
   *   "ospf"|"all"|"other"
   * @property {Number} protocolNumber
   *   user supplied protocol number when protocol 'other' is selected
   * @property {String} destIp
   *   destination IP address
   * @property {Number} destPort
   *   Port(s) to forward to
   * @property {String} srcIp
   *   IP address for source to "ACCEPT"|"DROP"
   */

  idAttribute: 'ruleId',

  _snapshotAttributes: [
    'ruleId',
    'remove',
    'description',
    'action',
    'order',
    'srcPort',
    'proto',
    'protocolNumber',
    'destIp',
    'destPort',
    'srcIp',
  ],

  defaults: {
    remove: false,
    description: null,
    action: 'ACCEPT',
    order: '1',
    proto: PROTOCOLS.TCPUDP,
  },

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (this.get('remove') === true) {
      // rules pending removal or that are disabled are never considered invalid
      return;
    }

    if (_.has(attrs, 'description')) {
      if (!attrs.description || !attrs.description.trim()) {
        errors.description = i18n.t('trafficPolicy.badRuleName');
      }
    }

    // check protocolNumber is valid when either attr.proto or this.get('proto') is "other",
    // since when a new policy is added this.get('proto') still has the default value: "tcpudp"
    // in the model until validation has passed
    if (_.has(attrs, 'proto') && attrs.proto === 'other' ||
        _.has(attrs, 'protocolNumber') && this.get('proto') === 'other') {
      if (!dataTypes.isNumeric(attrs.protocolNumber) ||
          !dataTypes.isInteger(Number(attrs.protocolNumber)) ||
          attrs.protocolNumber < 0 ||
          attrs.protocolNumber > 255
      ) {
        errors.protocolNumber = i18n.t('trafficPolicy.badProtocolNumber');
      }
    }

    if (_.has(attrs, 'srcPort')) {
      if (attrs.srcPort && !networkUtils.validPort(attrs.srcPort)
          && !networkUtils.validPortRange(attrs.srcPort)) {
        errors.srcPort = i18n.t('trafficPolicy.invalidPortRange');
      }
    }

    if (_.has(attrs, 'destIp') && attrs.destIp) {
      if (attrs.destIp && !networkUtils.validIP(attrs.destIp, 'ipv4')
        && !networkUtils.validSubnet(attrs.destIp)) {
        errors.destIp = i18n.t('trafficPolicy.badDestIp');
      }
    }

    if (_.has(attrs, 'destPort') && !this.isRange(attrs.destPort)) {
      if (attrs.destPort && !networkUtils.validPort(attrs.destPort)
          && !networkUtils.validPortRange(attrs.destPort)) {
        errors.destPort = i18n.t('trafficPolicy.badDestinationPort');
      }
    }

    if (_.has(attrs, 'srcIp') && attrs.srcIp) {
      if (attrs.srcIp && !networkUtils.validIP(attrs.srcIp)
          && !networkUtils.validSubnet(attrs.srcIp)) {
        errors.srcIp = i18n.t('trafficPolicy.badSrcIp');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.get('remove') === true) {
      return {
        name: 'firewall.removeFirewallSetting',
        data: {
          id: this.get('ruleId'),
        },
      };
    }

    // send protocolNumber value in proto field when protocol option "Other" is selected
    var protocolValue = this.get('proto');
    var protocolNumber = this.get('protocolNumber');

    if (protocolValue === PROTOCOLS.OTHER) {
      protocolValue = protocolNumber;
    }

    // send all tasks, even if unchanged, so device ends up with sorted rules
    var task = {
      name: 'firewall.addTrafficPolicy', // Used for update if an ID is sent...
      data: {
        name: null,
        description: this.get('description'),
        order: this.get('order'),
        action: this.get('action'),
        srcIp: this.get('srcIp') || null,
        srcPort: this.get('srcPort') || null,
        proto: protocolValue,
        destIp: this.get('destIp') || null,
        destPort: this.get('destPort') || null,
      },
    };
    if (!this.isNew()) {
      // ... so include the ID if it's an update
      task.data.name = this.get('ruleId');
    }
    return task;
  },

  getPortStart: function(portRange) {
    var index = portRange.indexOf('-');
    if (index === -1) {
      return parseInt(portRange);
    }
    return parseInt(portRange.slice(0, index));
  },

  getPortEnd: function(portRange) {
    return parseInt(portRange.slice(portRange.indexOf('-') + 1));
  },

  isRange: function(portRange) {
    return portRange && portRange.indexOf('-') !== -1;
  },

});
