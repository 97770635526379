'use strict';

var Marionette = require('backbone.marionette');
var HostMappingView = require('actions/staticHostMapping/FormHostMappingView');
var tpl = require('actions/staticHostMapping/form.html');
var twig = require('twig').twig;

var HostMappingsListView = Marionette.CollectionView.extend({
  childView: HostMappingView,
});

/**
 * Renders a text-only version of the DHCP reservations form action component.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/staticHostMapping/FormView#model
   * @type {actions/staticHostMapping/StaticHostMapping}
   */

  regions: {
    hostMappings: '.rg-hostMappings',
  },

  ui: {
    add: '.btn-add',
  },

  events: {
    'click @ui.add': 'addHostMapping',
  },

  template: twig({data: tpl}),

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child views.
   */
  initChildViews: function() {
    this.showChildView('hostMappings', new HostMappingsListView({
      collection: this.model.get('items'),
    }));
  },

  /**
   * Add a new blank reservation to the list of static leases
   */
  addHostMapping: function() {
    this.model.addHostMapping();
  },

});
