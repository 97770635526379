'use strict';

var $ = require('jquery');
var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/alertConfiguration/modal/alertConfigurationModal.html');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');
var i18n = require('i18next');

module.exports = Marionette.View.extend({
  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    box: '.alert-subscription-checkbox',
  },

  events: {
    'click @ui.box': 'handleToggle',
  },

  /**
   * Localized category names:
   *
   * i18n.t('alertConfiguration.critical.failover')
   * i18n.t('alertConfiguration.critical.offline-alert')
   * i18n.t('alertConfiguration.major.dirtyboot')
   * i18n.t('alertConfiguration.major.high-lte-usage')
   * i18n.t('alertConfiguration.major.client-vpn')
   * i18n.t('alertConfiguration.major.site-vpn')
   * i18n.t('alertConfiguration.major.dhcp-full')
   * i18n.t('alertConfiguration.major.config-restore')
   * i18n.t('alertConfiguration.info.boot')
   * i18n.t('alertConfiguration.info.reboot')
   * i18n.t('alertConfiguration.info.factory-reset')
   * i18n.t('alertConfiguration.info.network-interface')
   * i18n.t('alertConfiguration.info.device-update-available')
   * i18n.t('alertConfiguration.info.system-upgrade')
   * i18n.t('alertConfiguration.info.lan-ip-conflict')
   * i18n.t('alertConfiguration.info.vlan-clients-new')
   * i18n.t('alertConfiguration.info.wifi')
   * i18n.t('alertConfiguration.info.wifi-ssid')
   * i18n.t('alertConfiguration.info.wifi-global')
   * i18n.t('alertConfiguration.info.network-up')
   * i18n.t('alertConfiguration.info.network-down')
   *
   * The twig template will change all the emitter tags
   * to be `-` delimited rather than `.` delimited
   */

  templateContext: function() {
    return {
      alerts: _.groupBy(this.collection.toJSON(), 'category'),
      getInputLabel: function(category, emitter) {
        var translationString = ['alertConfiguration', category, emitter.replace(/\./g, '-')].join('.');
        return i18n.t(translationString);
      },
    };
  },

  handleToggle: function(event) {
    var $el = $(event.currentTarget);
    var subId = parseInt($el.data('subid'));
    var checked = $el.is(':checked');

    this.collection.get(subId).set({'subscribed': checked}, {commit: true});
  },
});
