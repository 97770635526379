'use strict';

var i18n = require('i18next');
var StaticHostMapping = require('actions/staticHostMapping/StaticHostMapping');
var FormView = require('actions/staticHostMapping/FormView');
var StaticView = require('actions/staticHostMapping/StaticView');

/**
 * Action configuration for the static host mapping action component.
 */
module.exports = {
  title: i18n.t('actionStaticHostMapping.title'),
  Model: StaticHostMapping,
  FormView: FormView,
  TextView: StaticView,
};
