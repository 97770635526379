'use strict';

var _ = require('lodash');
var ReadOnly = require('backbone.readonly');
var detailTypes = require('manage/details/detailTypes');
var wifiTplHelpers = require('lib/tplHelpers/wifiHelpers');
var networkHelpers = require('lib/tplHelpers/networkHelpers');

/**
 * Represents an individual "network". It was written to be used by the
 * "networks" collection in {@link lib/models/DeviceConfiguration}.
 *
 * Note, I tried really hard to keep DeviceConfiguration lightweight and not
 * rely on special Models and Collections. Unfortunately, WiFi networks have
 * this special case where its "name" is a combination of its SSID and description.
 * Within DeviceConfiguration was the only place it made sense to centralize
 * the logic.
 *
 * @property {Number|null} tagId
 *   convenience attribute for the 802.1q VLAN tag, parsed from portsMap
 */
module.exports = ReadOnly.Model.extend({
  /**
   * Sets tagId when portsMap is set
   *
   * @param {String|Object} key
   * @param {*} val
   * @param {Object|undefined} options
   * @return {Object} self
   */
  set: function(key, val, options) {
    // normalize key, value vs. {key: value} style - from Backbone source
    var attrs;
    if (typeof key === 'object') {
      attrs = key;
      options = val;
    } else {
      (attrs = {})[key] = val;
    }
    // end normalize

    if (_.isArray(attrs.portsMap)) {
      var taggedPort = _.find(attrs.portsMap, function(port) {
        return port.indexOf('.') >= 0;
      });
      if (taggedPort) {
        attrs.tagId = parseInt(taggedPort.split('.')[1]);
      } else {
        attrs.tagId = null;
      }
    } else if (_.has(attrs, 'portsMap')) {
      attrs.tagId = null;
    }

    return ReadOnly.Model.prototype.set.call(this, attrs, options);
  },

  /**
   * Gets the name of the network.
   *
   * @return {String}
   */
  getName: function() {
    var networkType = this.get('type');

    switch (networkType) {
      case detailTypes.WIFI:
        return wifiTplHelpers.formatFullName(this.get('ssid'), this.get('description'));

      case detailTypes.VLAN:
        if (this.get('role') === 'external') {
          return networkHelpers.formatWanFullName(this.get('portsMap')[0], this.get('description'));
        }
        return networkHelpers.formatFullName(this.id, this.get('description'));

      default:
        return this.get('description');
    }
  },

  /**
   * Request a callback when the name of this network changes
   *
   * @param {Backbone.Events} listener
   * @param {Function} callback
   */
  onNameChanged: function(listener, callback) {
    var networkType = this.get('type');
    var cb = callback.bind(listener);

    switch (networkType) {
      case detailTypes.WIFI:
        listener.listenTo(this, 'change:ssid change:description', cb);
        break;

      case detailTypes.VLAN:
        if (this.get('role') === 'external') {
          listener.listenTo(this, 'change:portsMap change:description', cb);
        } else {
          listener.listenTo(this, 'change:description', cb);
        }
        break;

      default:
        listener.listenTo(this, 'change:description', cb);
    }
  },

  /**
   * Checks whether this network is an internal network meaning whether it is a vlan or wifi network.
   * @returns {boolean}
   */
  isInternalNetwork: function() {
    return (this.isWifi() || this.isLan());
  },

  /**
   * Checks whether this network is a VLAN or not.
   * @returns {boolean}
   */
  isLan: function() {
    return this.get('role') === 'internal' && this.get('type') === 'vlan';
  },

  /**
   * Checks wther this is a wifi network or not.
   * @returns {boolean}
   */
  isWifi: function() {
    return this.get('role') === 'internal' && this.get('type') === 'wifi';
  },

  /**
   * Checks if this a WAN network or not.
   * @returns {boolean}
   */
  isWan: function() {
    return this.get('role') === 'external';
  },

  /**
   * Based on the status blob this checks the current activeWan as reported by the devices
   * failover status blob and whther if this network is a wan or not.
   * @param {Backbone.Model} status
   * @returns {boolean}
   */
  isActiveWan: function(status) {
    if (status.get('failover').get('activeWan')) {
      if (this.isWan() && status.get('failover').get('activeWan').id === this.get('id')) {
        return true;
      }
    }
    if (status.get('failover').get('loadBalanceInfo') &&
        status.get('failover').get('loadBalanceInfo').configuredVlans) {
      return status.get('failover').get('loadBalanceInfo').configuredVlans.includes(this.get('id'));
    }

    return false;
  },
});
