'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');

/**
 * View-model representing DNS fields for external VLANs.
 */
module.exports = ActionItem.extend({
  _snapshotAttributes: [],
  applySnapshot: _.noop,
  takeSnapshot: _.noop,

  /**
   * @return {Object}
   */
  getTask: function() {
    return {
      name: 'vlan.create',
      data: {dns: 'webFilterDns'},
    };
  },
});
