'use strict';

var Backbone = require('backbone');
var Radio = require('backbone.radio');
var i18n = require('i18next');
var DropdownView = require('components/dropdown/DropdownView');
var layoutChannel = Radio.channel('layoutChannel');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');
var Entities = require('html-entities').XmlEntities;
var entities = new Entities();

/**
 * Renders a button dropdown to facilitate navigating between network status pages.
 */
module.exports = DropdownView.extend({
  useSplitButton: false,

  /**
   * @param {Object} options
   * @property {String} options.deviceMac
   * @property {lib/models/DeviceConfiguration} options.deviceConfig
   * @property {String} options.current
   *   The dropdown item to show at the top (button label)
   */
  initialize: function(options) {
    this.mergeOptions(options, ['deviceMac', 'deviceConfig', 'current']);

    var dropdownList = this.deviceConfig.get('networks')
      .filter(function(network) {
        var type = network.get('type');
        return type === 'vlan' || type === 'wifi' ||
          (type === 'vpn' && network.get('enabled'));
      })
      .map(this.getNetworksListItem.bind(this));

    this.model = new Backbone.Model({
      label: i18n.t('networksDropdown.label'),
      options: dropdownList,
    });
  },

  /**
   * @fires layoutChannel.request~change:page
   * @param {Object} option
   *   The selected option object.
   */
  onDropdownItemSelected: function(option) {
    Backbone.history.navigate(option.href);
    layoutChannel.request('change:page', {
      deviceMac: this.deviceMac,
      page: deviceConfigChannel.request('trim:url', option.href),
    });
  },

  /**
   * @param {Backbone.Model} network
   *   Entity from the "networks" collection in DeviceConfiguration.
   * @return {Object}
   */
  getNetworksListItem: function(network) {
    var url = Radio.request('deviceConfigChannel', 'get:url',
      network.get('type'), this.deviceMac, network.id, network.pick('parentVlan')
    );

    return {
      id: network.id,
      label: entities.decode(network.getName()),
      selected: (this.current === network.id),
      href: url,
    };
  },
});
