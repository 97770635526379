'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/router/hostname/form.html');
var twig = require('twig').twig;
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the "hostname" form field.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/router/hostname/FormView#model
   * @type {actions/router/hostname/EditHostname}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: tpl}),

  ui: {
    'hostname': '[name="hostname"]',
  },

  events: {
    'blur @ui.hostname': 'saveForm',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  /**
   * Checks if hostname value has changed, validates and saves.
   *
   * @param {Event} ev
   */
  saveForm: function() {
    var newHostname = this.ui.hostname.val();
    this.ui.hostname.bs3ui('clearFieldError');
    this.model.set('hostname', newHostname, {commit: true});
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/router/hostname/EditHostname} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    this.ui.hostname.bs3ui('showFieldError', error.hostname);
  },

});
