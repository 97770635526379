'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Backbone = require('backbone');

/**
 * A single configuration item, which wraps an {@link actions/shared/ActionItem|action component}.
 *
 * A {@link config/Group} object can have one or more configuration items
 * and these items represent a logical group of configuration. For example, a
 * VLAN contains a description, a subnet and ports. The subnet configuration
 * item includes an IP address and subnet mask.
 */
module.exports = Backbone.Model.extend({
  /**
   * @member {Object} config/Action#attributes
   *
   * @property {Object} actionConfig
   *   Action configuration (model class, view classes, etc.)
   * @property {actions/shared/ActionItem} actionModel
   *   The model/collection used to configure the action.
   * @property {Object} actionModelCache
   *   A serialized copy of the actionModel's attribute. If provided, it will
   *   be passed to the actionModel attribute when it is instantiated.
   *
   * @property {Boolean} isLoading
   *   View-property to indicate that the action (attributes.actionModel) is
   *   loading (e.g. fetching its data from the router).
   * @property {Boolean} isRequired
   *   Indicates if the associated action item is required by the parent
   *   configuration group.
   * @property {Boolean} isManaged
   *   Indicates if the associated action item's presence is programmatically
   *   managed and thus should not be directly user-addable or -removable.
   * @property {Boolean} isPending
   *   Indicates that an action is new and has not yet been saved.
   *
   * @fires actionModel:*
   *   all events from the wrapped actionModel are re-fired by the wrapper Action
   *   the original event name will be prefixed with "actionModel:"
   *   the original event arguments will be prepended with a reference to the wrapper Action
   */

  /**
   * @param {Object} attributes
   * @param {Object} options
   */
  initialize: function(attributes, options) {
    // checking isValid serves 2 purposes here: it avoids calling _setActionModel,
    // which will probably throw exceptions if we don't have a valid actionConfig,
    // AND it sets this.validationError, which will block this action from being
    // added to its parent collection
    if (this.isValid()) {
      this._setActionModel();
    }
  },

  validate: function() {
    if (this.get('badActionType')) {
      return {badAction: 'Unknown/invalid action type'};
    }
  },

  /**
   * Initializes the associated Action model.
   */
  _setActionModel: function() {
    var ModelClass = this.get('actionConfig').Model;
    var actionModel;
    var cache = this.get('actionModelCache');
    var attrs;

    attrs = {
      actionTitle: this.get('actionConfig').title,
    };

    if (_.has(cache, 'id')) {
      attrs.id = cache.id;
    }

    actionModel = new ModelClass(attrs, {deviceMac: this.get('deviceMac')});

    // forward events from the wrapped action model
    this.listenTo(actionModel, 'all', this._onActionModelEvent);

    this.set({
      isLoading: true,
      actionModel: actionModel,
    });
  },

  /**
   * Forwards events from wrapped actionModel
   *
   * @param {String} event
   * @private
   */
  _onActionModelEvent: function(event) {
    var eventArgs = Array.prototype.slice.call(arguments, 1);

    // prefix forwarded event names to avoid confusion with events originating from this model
    var name = 'actionModel:' + event;

    // prepend the wrapping Action as the first argument
    var args = [name, this].concat(eventArgs);

    this.trigger.apply(this, args);
  },

  /**
   * Provides the associated action model with a map of other action
   * models it has declared as dependencies, indexed by
   * type (e.g. {'ntp': <EditNtp instance>}).
   *
   * @param {Object} deps
   */
  setActionModelDependencies: function(deps) {
    this.get('actionModel').dependencies = deps;
    this.trigger('action:dependencies:ready', this);
  },

  /**
   * Fetches the associated Action model.
   *
   * @return {jQuery.Deferred}
   */
  loadActionModel: function() {
    var self = this;

    return this.restoreAction()
      .fail(function() {
        // TODO trigger event or set model property so that the action displays
        // a message that something went wrong
      })
      .always(function() {
        self.set('isLoading', false);
      });
  },

  /**
   * Restores the action component's data/state.
   *
   * @return {jQuery.Deferred}
   */
  restoreAction: function() {
    var deferred = $.Deferred();
    var actionPromise = $.Deferred();

    if (this.get('isLoading') === true) {
      // Loading/restoring an action is done in two steps:
      // 1. Load the action's base data (i.e. it's current configuration)
      //  - Base data is important because it provides a snapshot to compare
      //    changes against.
      // 2. If providing a previous cache, update the action's attributes to
      //   what is in the cache.
      actionPromise = this._setActionFromConfig()
        .then(_.bind(this._setActionFromCache, this));
    } else {
      actionPromise.resolve();
    }

    actionPromise.fail(function() {
      deferred.reject();
    });

    actionPromise.done(function() {
      deferred.resolve();
    });

    return deferred.promise();
  },

  /**
   * Instructs the Action to load/fetch its base-configuration.
   *
   * @returns {jQuery.Promise}
   */
  _setActionFromConfig: function() {
    var actionModel = this.get('actionModel');
    return actionModel.fetch();
  },

  /**
   * Instructs the Action to update its state with a previously saved version.
   *
   * @returns {jQuery.Promise}
   */
  _setActionFromCache: function() {
    var actionModel = this.get('actionModel');
    var cache = this.get('actionModelCache');
    var promise;

    if (!_.isEmpty(cache)) {
      // Note, we clone the cache to prevent mutations bubbling back up to the source.
      promise = actionModel.fetch({fromCache: true, cache: _.clone(cache)});
    }

    return promise || ($.Deferred().resolve()).promise();
  },

  /**
   * Method to check whether the model supports on/off behavior
   *
   * @return {Boolean}
   */
  actionSupportsOnOff: function() {
    var actionModel = this.get('actionModel');
    return (actionModel.getOnOffState || actionModel.toggledOnOffBy);
  },

  /**
   * Checks that the action has had changes made to it.
   *
   * @return {Boolean}
   */
  actionHasChanges: function() {
    return this.get('actionModel').getSnapshotDiff();
  },

  /**
   * Checks that the action's data passes validation.
   *
   * @return {Boolean}
   */
  actionIsValid: function() {
    return this.get('actionModel').isValid();
  },

  /**
   * Rolls back all changes made to the action's attributes.
   */
  undoActionChanges: function() {
    var action = this.get('actionModel');

    action.applySnapshot();
  },

  /**
   * Removes the action or stages it for removal
   */
  remove: function() {
    if (this.get('isRequired') === true) {
      return;
    }

    if (this.get('isPending') !== true) {
      this.get('actionModel').set('pendingDelete', true);
      this.trigger('action:remove', this);
    } else {
      this.collection.remove(this);
    }
  },

});
