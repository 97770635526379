'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('manage/details/vpn/vpn-details.html');
var networkHelpers = require('lib/tplHelpers/networkHelpers');
var Ip = require('lib/Ip');
var i18n = require('i18next');
var Modal = require('components/modal/ModalView');
var ModalChildView = require('components/modal/GenericChildView');

/**
 * Renders the VPN configuration details View.
 */
module.exports = Marionette.View.extend({
  /**
   * @member #model manage/details/vpn/VpnStatusView#model
   */

  template: twig({data: tpl}),

  events: {
    'click [href="#vpn-secret"]': 'showSecret',
  },

  /**
   * Shows the shared secret to the user.
   *
   * @param {Event} ev
   */
  showSecret: function(ev) {
    ev.preventDefault();

    var vpn = this.model.get('deviceConfig').get('networks').get(this.model.id);

    new Modal({
      model: new Backbone.Model(
        {
          callback: null,
          title: i18n.t('vpnDetails.currentSecret'),
          childView: new ModalChildView({
            content: '<p class="display-password">' + vpn.get('secret') + '</p>',
          }),
          actionButtonText: '',
          closeText: i18n.t('common.close'),
        }
      ),
    }).render();
  },

  /**
   * @return {Object}
   */
  serializeData: function() {
    // everything is provided by templateContext
    return {};
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    var deviceConfig = this.model.get('deviceConfig');
    var network = deviceConfig.get('networks').get(this.model.id);
    var subnet = deviceConfig.getSubnet(this.model.id);

    return {
      subnet: subnet ? this.getSubnetDetails(subnet.toJSON()) : {},
      allowedLanNames: this.getAllowedLanNames(network, deviceConfig),
    };
  },

  /**
   * Gets and formats subnet details.
   *
   * @param {Object} subnetAttrs
   * @return {Object}
   */
  getSubnetDetails: function(subnetAttrs) {
    var ip = subnetAttrs.address + '/' + subnetAttrs.size;
    var ipObj = new Ip(ip, {type: subnetAttrs.type});

    var data = {
      baseIp: subnetAttrs.address,
    };

    // netmask
    data.netmask = networkHelpers.formatNetmask(subnetAttrs.type, ipObj.cidr());

    // subnet range
    data.range = i18n.t('actionStaticAddress.range', {
      start: ipObj.network(),
      end: ipObj.broadcast(),
    });

    return data;
  },

  /**
   * Gets and formats subnet details.
   *
   * @param {lib/models/Network} vpn
   * @param {lib/models/DeviceConfiguration} deviceConfig
   * @return {Array}
   */
  getAllowedLanNames: function(vpn, deviceConfig) {
    if (!vpn) {
      return [];
    }

    var allowed = vpn.get('allowedNetworks');

    return deviceConfig.getInternalVlans()
      .filter(function(lan) {
        return _.includes(allowed, lan.id);
      })
      .map(function(lan) {
        return lan.getName();
      });
  },

});
