'use strict';

var Marionette = require('backbone.marionette');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var StaticNetworkObjectView = require('actions/networkObject/StaticNetworkObjectView');
var tpl = require('actions/networkObject/static.html');
var twig = require('twig').twig;

var StaticNetworkObjectListView = Marionette.CollectionView.extend({
  childView: StaticNetworkObjectView,
  filter: function(child, index) {
    // only show first 5 children max in static view
    return index >= 0 && index < 5;
  },
});

module.exports = BaseStaticView.extend({
  template: twig({data: tpl}),

  regions: {
    specs: '.rg-specs',
  },

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.showChildView('specs', new StaticNetworkObjectListView({
      collection: this.model.get('items'),
    }));
  },
});
