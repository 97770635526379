'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var countries = require('lib/countries');

var euCountries = countries.euCountries;
var northAmerica = countries.northAmerica;
var australasia = countries.australasia;

var supportedCountries = northAmerica.concat(euCountries).concat(australasia);

/**
 * List of channels by frequency.
 *
 * @static
 * @memberof! actions/router/wifi/EditWifi
 * @param {String} countryCode
 * @type {Object}
 * @returns {Object} channel list
 */
function frequencyChannels(countryCode) {
  if (_.includes(northAmerica, countryCode)) {
    return {
      '2.4': ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
      '5': ['36', '40', '44', '48', '149', '153', '157', '161'],
    };
  }
  if (_.includes(euCountries, countryCode)) {
    return {
      '2.4': ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'],
      '5': ['36', '40', '44', '48'],
    };
  }
  if (_.includes(australasia, countryCode)) {
    return {
      '2.4': ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'],
      '5': ['36', '40', '44', '48', '149', '153', '157', '161'],
    };
  }
  return {
    '2.4': [], '5': [],
  };
}

/**
 * Check if country is supported
 *
 * @static
 * @memberof! actions/router/wifi/EditWifi
 * @param {String} country
 * @returns {Boolean} whether country is supported
 */
function checkSupportedCountry(country) {
  return _.includes(supportedCountries, country);
}

/**
 * View-Model for the "Manage global Wi-Fi settings" component.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/router/wifi/EditWifi#attributes
   * @property {String} frequency
   * @property {String} htmode
   * @property {String} channel
   * @property {String} countryError
   */

  _snapshotAttributes: ['frequency', 'channel', 'htmode', 'disabled'],

  defaults: {
    htmode: '20',
  },

  initialize: function(attrs, options) {
    this.addDeviceStatus(options);
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    // global wifi config always exists
    return false;
  },

  getOnOffState: function() {
    return !this.get('disabled');
  },

  onOffStateChangeEvent: 'change:disabled',

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var attrs = {};
      var wifi = this.deviceConfig.get('wifi');
      var country = this.deviceConfig.get('router').get('authorizedCountryCode');

      attrs.frequency = wifi.get('frequency').toString();
      attrs.channel = wifi.get('channel').toString();
      attrs.countryError = !checkSupportedCountry(country);

      if (wifi.has('disabled')) {
        attrs.disabled = wifi.get('disabled');
      }

      if (wifi.has('htmode')) {
        attrs.htmode = wifi.get('htmode').toString();
      }

      return attrs;
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('wifi');
      },
      events: 'change',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (!_.isUndefined(attrs.channel) && !this.isValidChannel(attrs.channel)) {
      errors.channel = i18n.t('actionGlobalWifi.badChannel', this.pick('frequency'));
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Checks that the passed channel is valid based on the current frequency.
   *
   * @param {String} newChannel
   * @return {Boolean}
   */
  isValidChannel: function(newChannel) {
    var frequency = this.get('frequency');
    var country = this.deviceConfig.get('router').get('authorizedCountryCode');
    return _.includes(frequencyChannels(country)[frequency], newChannel);
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    return {
      name: 'wifi.editGlobal',
      data: this.pick(this._snapshotAttributes),
    };
  },

  getFrequencyChannels: function() {
    return frequencyChannels(this.deviceConfig.get('router').get('authorizedCountryCode'));
  },
}, {
  isSupportedCountry: function(country) {
    return checkSupportedCountry(country);
  },
}
);
