'use strict';

var _ = require('lodash');
var $ = require('jquery');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var Radio = require('backbone.radio');
var apiChannel = Radio.channel('apiChannel');

/**
 * Model for Router's 'timezone' setting.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/router/timezone/EditTimezone#attributes
   * @property {String} timezone
   *   Router's timezone
   */

  /**
   * List of timezones.
   * @member {Array} actions/router/timezone/EditTimeZone#timezonesList
   */

  _snapshotAttributes: ['timezone'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    // timezone always exists
    return false;
  },

  /**
   * @param {Object} options
   * @return {jQuery.promise}
   */
  fetch: function(options) {
    var self = this;
    var deferred;

    deferred = $.when(
      apiChannel.request('send', 'DNA.System.Timezone.listTimezones', {}),
      ActionItem.prototype.fetch.apply(this, arguments)
    )
      .done(function(getTimezonesResp, fetchResp) {
        // set timezone list
        self.timezonesList = getTimezonesResp.result;

        // only need to return response for getting current timezone value
        return fetchResp;
      });

    return deferred.promise();
  },

  /**
   * Get the timezone from the rpc response and return a proper object.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      resp = {
        timezone: this.deviceConfig.get('router').get('timezone'),
      };
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('router');
      },
      events: 'change:timezone',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Array|undefined}
   */
  validate: function(attrs, options) {
    if (_.indexOf(this.timezonesList, attrs.timezone) < 0) {
      return {
        timezone: i18n.t('actionTimezone.badTimezone'),
      };
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    return {
      name: 'router.setTimezone',
      data: {
        timezone: this.get('timezone'),
      },
    };
  },

});
