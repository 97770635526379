'use strict';

var i18n = require('i18next');
var EditWanMTU = require('actions/wanMTU/EditWanMTU');
var FormView = require('actions/wanMTU/FormView');
var StaticView = require('actions/wanMTU/StaticView');

/**
 * Action configuration for the MTU configuration for a WAN.
 */
module.exports = {
  title: i18n.t('actionWanMTU.title'),
  Model: EditWanMTU,
  FormView: FormView,
  TextView: StaticView,
};
