'use strict';

var _ = require('lodash');
var MultiActionItem = require('actions/shared/MultiActionItem');
var EditNat = require('actions/advancedNat/Nat');

/**
 * Edit model for Advanced NAT.
 */
module.exports = MultiActionItem.extend({

  collectionModel: EditNat,

  initialize: function(attributes, options) {
    this.attributes.items.comparator = function(a) {
      return parseInt(a.get('order'));
    };
    this.listenTo(this.get('items'), 'change:order', this.resortPolicies);
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('firewall').get('advancedNat');
      },
      events: 'update',
    },
  ],

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      var natRules = this.deviceConfig.get('firewall').get('advancedNat').toJSON();
      var netObjs = _.map(this.deviceConfig.get('firewall').get('networkObjects').toJSON(), function(obj) {
        return _.pick(obj, 'id', 'description');
      });

      var parsedItems = _.map(natRules, function(item) {
        if (item.srcNetObjId) {
          item.srcIp = _.findWhere(netObjs, {id: item.srcNetObjId})
            ? _.findWhere(netObjs, {id: item.srcNetObjId}).description
            : item.srcNetObjDescription;
        }
        // JUCI only returns the src version of each network object, so this will never have the correct id.
        // This is fine since the displayed name is the same and we will translate the id to the dst version
        // before sending the task to the server.
        if (item.destNetObjId) {
          item.destIp = _.findWhere(netObjs, {id: item.destNetObjId})
            ? _.findWhere(netObjs, {id: item.destNetObjId}).description
            : item.destNetObjDescription;
        }
        return _.extend(item, {netObjs: netObjs});
      });

      return {
        items: parsedItems,
        netObjs: netObjs,
      };
    }

    return resp;
  },

  resortPolicies: function() {
    return this.get('items').sort();
  },
});
