'use strict';

var _ = require('lodash');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var tpl = require('actions/dnsLookup/static.html');
var twig = require('twig').twig;

/**
 * Renders a text-only version of the "DNS Lookup" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/dnsLookup/StaticView#model
   * @type {actions/dnsLookup/DnsLookup}
   */

  template: twig({data: tpl}),

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      resultText: this._resultText,
    });
  },

  /**
   * Format model's result value for display
   * Splits into array on newlines
   *
   * @returns {Array}
   * @private
   */
  _resultText: function() {
    return this.result.split('\n');
  },
});
