'use strict';

var i18n = require('i18next');
var SiteToSiteRekeyTime = require('actions/router/siteToSiteRekeyTime/EditSiteToSiteRekeyTime');
var FormView = require('actions/router/siteToSiteRekeyTime/FormView');
var StaticView = require('actions/router/siteToSiteRekeyTime/StaticView');

/**
 * Action configuration for the site-to-site rekey time components
 */
module.exports = {
  title: i18n.t('actionSiteToSiteRekeyTime.title'),
  Model: SiteToSiteRekeyTime,
  FormView: FormView,
  TextView: StaticView,
};
