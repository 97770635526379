'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var Radio = require('backbone.radio');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');
var ConfigMessageTypes = require('manage/edit/ConfigMessageTypes');
var tplOverlay = require('manage/edit/config/group/runTest/overlay.html');
var timeouts = require('lib/saveTimeouts.js');
var i18n = require('i18next');

/**
 * Displays a message that the changes are being saved and then displays a
 * success or error message.
 *
 * Although this is a Behavior it is very tightly coupled
 * to the GroupView and should not be reused as if it was a behavior
 */
module.exports = Marionette.Behavior.extend({
  defaults: {
    message: i18n.t('configEdit.runningTest'),
    longMessage: i18n.t('configEdit.stillRunningTest'),
    template: twig({data: tplOverlay}),
  },

  ui: {
    runBtn: '[name="run"]',
  },

  events: {
    'click @ui.runBtn': 'onRun',
  },

  modelEvents: {
    'change:isFocused': 'onFocusChange',
  },

  longTimer: undefined,
  maxTimer: undefined,

  /**
   * Save configuration changes.
   *
   * @param {Event|null} ev
   *   This param exists to allow use as an event listener, but is not required
   * @param {Object} options
   *   Options to be passed through to the model's save() method
   */
  onRun: function(ev, options) {
    var self = this;

    if (this.view.model.get('isSaveable') === false) {
      return;
    }

    this.view.model.set('isApplying', true);
    deviceConfigChannel.trigger('save:start');

    self.view.alertView.trigger('clear', {animate: false});
    this.showOverlay();

    // only listen when a update is in progress (defining it too early will
    // cause it to be picked up during other actions)
    this.listenTo(this.view.model, 'change:jobResults', this.processResults);

    this.longTimer = setTimeout(this.onLongTime.bind(this), timeouts.OPERATOR_TOOLS_FEEDBACK_TIME);
    this.maxTimer = setTimeout(this.onTimeout.bind(this), this.view.model.getConfigCardTimeout());

    this.view.model.save(options)
      .fail(function(errorObj) {
        // In a validation failure, the error object will already have the proper
        // structure for showErrors, however when the error is a server side RPC
        // error, it won't. The ternary statement will catch that situation and
        // create the proper structure.
        var errors = errorObj && errorObj.type ?
          errorObj : {type: ConfigMessageTypes.JOB_ERRORS, errors: errorObj};
        self.cleanUp();
        self.showError(errors);
      });
  },

  /**
   * @param {config/Group} model
   * @param {Boolean} isFocused
   * @param {Object} options
   */
  onFocusChange: function(model, isFocused, options) {
    if (isFocused === false) {
      this.view.alertView.trigger('clear', {animate: false});
    }
  },

  /**
   * Gets the results of the last job applied
   * and displays the appropriate information
   * based on the jobs results status
   *
   * @param {Backbone.Model} model
   * @param {Object} jobResults
   *   The list of results from running each job.
   * @param {Object} options
   */
  processResults: function(model, jobResults, options) {
    var results = _.first(jobResults);

    this.cleanUp();

    this.view.model.get('actions').models[0].get('actionModel').set('result', results.results[0]);
    this.markAsSaved();
  },

  /**
   * If the save isn't processed in less than
   * FEEDBACK_TIME, this timeout fires and updates
   * the text in the overlay to say that the
   * processing is still happening
   */
  onLongTime: function() {
    var message = this.options.longMessage;
    if (this.$modal) {
      this.$modal.find('.overlay2__message').text(message);
    }
  },

  /**
   * If the save isn't processed in less than
   * the MAX_TIME, this timeout fires
   * and lets the UI function again
   */
  onTimeout: function() {
    this.cleanUp();
    this.showError({type: ConfigMessageTypes.TEST_TIMEOUT});
  },

  /**
   * Displays the processing job overlay
   */
  showOverlay: function() {
    var message = this.options.message;

    this.$modal = $(this.options.template.render({message: message}));
    this.$el.append(this.$modal);
  },

  /**
   * Displays the success message
   */
  showSuccess: function() {
    this.view.alertView.trigger('show', {type: ConfigMessageTypes.QUEUE_APPLIED});
    this.view.scrollView({scroll: false});
  },

  /**
   * Displays the error message.
   *
   * @param {Object} errorObj
   */
  showError: function(errorObj) {
    this.view.alertView.trigger('show', errorObj);
    this.view.scrollView({scroll: false});
  },

  /**
   * Removes overlay and resets listeners, timers,
   * and model state after save succeeds or fails
   */
  cleanUp: function() {
    clearTimeout(this.longTimer);
    clearTimeout(this.maxTimer);
    this.view.model.set('isApplying', false);
    this.stopListening(this.view.model, 'change:jobResults');
    if (this.$modal) {
      this.$modal.remove();
      this.$modal = null;
    }
    deviceConfigChannel.trigger('save:end');
  },

  /**
   * Resets model and view after a successful save
   */
  markAsSaved: function() {
    // remove group-level dirty flag
    this.view.model.set('isDirty', false);

    // remove pending flag for any added actions
    this.view.collection.each(function(actn) {
      actn.set('isPending', false);
    });

    this.view.render();

    // unfocus the group
    this.view.model.set('isFocused', false);
  },
});
