'use strict';

var _ = require('lodash');
var BaseAlertView = require('components/alert/AlertView');
var uiIcons = require('lib/tplHelpers/uiIcons');

/**
 * Displays a notification for any warnings or service interruptions on the
 * UI or the DNA
 */
module.exports = BaseAlertView.extend({
  /**
   * View-Model (see components/alert/AlertView for more model attributes).
   * @member {Backbone.Model} manage/detailsLayout/globalAdminMessage#model
   * @property {Object} attributes.statusCard
   *   The status of the DNA.
   * @property {String} attributes.page
   *   The page the message is being displayed on. Currently allowed values
   *   are: fleetOverview, dnaView.
   */

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    var statusCard = this.model.get('statusCard');

    this.model.set('alertType', 'wide');

    _.bindAll(this, 'getAlertMessage');

    this.listenTo(statusCard, {
      'change:adminMessages': this.render,
    });
  },

  onBeforeRender: function() {
    this.updateSeverity();
    BaseAlertView.prototype.onBeforeRender.apply(this, arguments);

    this.$el.addClass('status-alert mb-0');
    this.toggleAlert();
  },

  templateContext: function() {
    var baseHelpers = BaseAlertView.prototype.templateContext.apply(this);
    var statusMessages = this.model.get('statusCard').get('adminMessages');

    return _.extend({}, baseHelpers, {
      message: this.getAlertMessage,
      header: statusMessages.adminHeader,
      iconClass: uiIcons.icons.warning,
    });
  },

  /**
   * @memberof! manage/detailsLayout/globalDeviceMessage/GlobalAdminMessage!
   * @method templateContext.alertMessage
   * @returns {{beforeClickableText: string, afterClickableText: string}}
   */
  getAlertMessage: function() {
    var statusMessages = this.model.get('statusCard').get('adminMessages');
    var alertMessage = {beforeClickableText: '', afterClickableText: '', clickableText: '', clickableURL: ''};

    alertMessage.beforeClickableText = statusMessages.adminMessage;

    if (statusMessages.adminClickableText !== '' && statusMessages.adminLink !== '') {
      alertMessage.clickableText = statusMessages.adminClickableText;
      alertMessage.clickableURL = statusMessages.adminLink;
    }

    return alertMessage;
  },

  /**
   * Updates severity based on admin message type
   */
  updateSeverity: function() {
    var statusMessages = this.model.get('statusCard').get('adminMessages');
    this.model.set('severity', statusMessages.adminMessageType);
  },

  /**
   * Hides the alert if enabled is false in the database
   */
  toggleAlert: function() {
    var statusMessages = this.model.get('statusCard').get('adminMessages');

    if (statusMessages.adminEnabled !== '1') {
      this.$el.addClass('hidden');
    }
  },
});
