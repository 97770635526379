'use strict';

var _ = require('lodash');
var GroupView = require('manage/edit/config/group/GroupView');
var DnaIsSpokeAlert = require('actionGroups/editSiteVpn/DnaIsSpokeWarning');

/**
 * Custom group view for the Site to Site VPN card
 */
module.exports = GroupView.extend({
  onAttach: function() {
    this.toggleDnaIsSpokeWarning();
    this.listenTo(this.model.deviceConfig.get('remoteSites'), 'add remove', this.toggleDnaIsSpokeWarning);
    // Suuuuper hacky code to wire the temporary "Reset" button in the action to the group's save button
    // FIXME: This code (and probably this entire custom view) should be removed once the Reset button goes away
    var actionModel = this.collection.at(1).get('actionModel');
    this.listenTo(actionModel, 'request:save', this.handleSaveRequested);

    GroupView.prototype.onAttach.apply(this, arguments);
  },

  handleSaveRequested: function(options) {
    // invoke the `onSave` method inside the SaveChanges behavior with this magic syntax
    this.triggerMethod('save', null, options);
  },

  toggleDnaIsSpokeWarning: function() {
    var warningsView = this.getChildView('configWarnings');
    var remoteSites = this.model.deviceConfig.get('remoteSites');
    var isDeviceSpoke = false;
    var hubMac = '';

    var hubSite = remoteSites.find(function(remoteSite) {
      return remoteSite.get('role') === 'hub';
    });

    if (hubSite) {
      // if any remote sites are set as the hub, then the device we are viewing is a spoke
      isDeviceSpoke = true;
      hubMac = hubSite.get('remoteMac');
    }

    warningsView.triggerMethod('remove:alert', DnaIsSpokeAlert.dnaIsSpokeAlertId);
    if (isDeviceSpoke) {
      warningsView.triggerMethod('add:custom:alert', new DnaIsSpokeAlert({
        navChannel: this.navChannel,
        hubMac: hubMac,
      }));
    }
  },

  hasAllowedSubnets: function() {
    var actionModel = this.model.get('actions').get('editSiteVpn').get('actionModel');
    return _.any([
      actionModel.get('allowedLans'),
      actionModel.get('allowedStaticRoutes'),
      actionModel.get('allowedNetObjs'),
    ], function(subs) {
      return !_.isEmpty(subs) && subs[0] !== '';
    });
  },
});
