'use strict';

var _ = require('lodash');
var $ = require('jquery');
var i18n = require('i18next');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tplOverlay = require('lib/behaviors/confirmDelete/overlay.html');

/**
 * Displays a "Are you sure you want to delete this" message.
 *
 * To use this behavior:
 *
 * 1) The View must have an HTML element with its "name" attribute set to
 * "delete" (e.g. `<button name="delete">Remove</button>`).
 *
 * 2) The View is expected to handle what happens when the user confirms the
 * deletion should happen.
 * ```
 * onDeleteItemConfirmed: function(options) {
 * }
 * ```
 */
module.exports = Marionette.Behavior.extend({
  defaults: {
    message: i18n.t('configEdit.deleteGeneric'),
    subMessage: null,
    showDangerMessage: false,
    defaultIsYes: true,
    isRemovable: true,
    template: twig({data: tplOverlay}),
  },

  ui: {
    deleteBtn: '[name="delete"]',
    yesBtn: '[name="confirm-delete-yes"]',
    noBtn: '[name="confirm-delete-no"]',
    okBtn: '[name="confirm-ok-no-action"]',
  },

  triggers: {
    'click @ui.yesBtn': 'delete:item:confirmed',
  },

  events: {
    'click @ui.deleteBtn': 'showConfirmation',
    'click @ui.noBtn': 'cancelDelete',
    'click @ui.yesBtn': 'clearOverlay',
    // Currently does the same thing as the noBtn (cancelDelete). But wanted a new type of button
    'click @ui.okBtn': 'cancelDelete',
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    if (_.isFunction(this.options.message)) {
      // Allow "message" to be a function so that runtime information, like
      // model or collection data, can be used.
      this.options.message = this.options.message.bind(this.view);
    }
    if (_.isFunction(this.options.subMessage)) {
      this.options.subMessage = this.options.subMessage.bind(this.view);
    }
    if (_.isFunction(this.options.defaultIsYes)) {
      this.options.defaultIsYes = this.options.defaultIsYes.bind(this.view);
    }

    if (_.isFunction(this.options.showDangerMessage)) {
      this.options.showDangerMessage = this.options.showDangerMessage.bind(this.view);
    }

    if (_.isFunction(this.options.isRemovable)) {
      this.options.isRemovable = this.options.isRemovable.bind(this.view);
    }
  },

  /**
   * Displays the confirmation message.
   *
   * @param {Event} ev
   */
  showConfirmation: function(ev) {
    // these options can be a string or function
    var message = _.result(this.options, 'message');
    var subMessage = _.result(this.options, 'subMessage');
    var defaultIsYes = _.result(this.options, 'defaultIsYes');
    var showDangerMessage = _.result(this.options, 'showDangerMessage');
    var isRemovable = _.result(this.options, 'isRemovable');

    ev.preventDefault();

    if (this.view.model.get('isSaveable') === false) {
      return;
    }

    this.$confirmModal = $(this.options.template.render({
      t: _.bind(i18n.t, i18n),
      message: message,
      subMessage: subMessage,
      defaultIsYes: defaultIsYes,
      showDangerMessage: showDangerMessage,
      isRemovable: isRemovable,
    }));
    this.$el.append(this.$confirmModal);
  },

  /**
   * Cancels the confirmation message.
   *
   * @param {Event} ev
   */
  cancelDelete: function(ev) {
    ev.preventDefault();
    this.$confirmModal.remove();
  },

  /**
   * Removes the confirmation overlay.
   *
   * @param {Event} ev
   */
  clearOverlay: function(ev) {
    this.$confirmModal.remove();
  },
});
