'use strict';

var i18n = require('i18next');
var Marionette = require('backbone.marionette');
var tpl = require('actions/dhcpAdvOptions/form.html');
var DHCPOptionView = require('actions/dhcpAdvOptions/DHCPOption/DHCPOptionView');
var twig = require('twig').twig;
var RemoveConfig = require('manage/edit/config/action/removeConfig/RemoveConfig');

var DHCPOptionListView = Marionette.CollectionView.extend({
  childView: DHCPOptionView,
});

/**
 * Renders the "dhcp advanced options" form action component.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/dhcpAdvOptions/FormView#model
   * @type {actions/dhcpAdvOptions/EditDhcpAdvOptions}
   */

  behaviors: [
    {
      behaviorClass: RemoveConfig,
    },
  ],

  template: twig({data: tpl}),

  regions: {
    dhcpOptions: '.rg-dhcp-options',
  },

  ui: {
    addDHCPOptionBtn: '[name="add-dhcp-option"]',
  },

  events: {
    'click @ui.addDHCPOptionBtn': 'addDHCPOption',
  },

  modelEvents: {
    'change:noMoreOptions': 'render',
    'reparsedConfigForOptions': 'render',
  },

  /**
   * Initializes the child views.
   */
  initChildViews: function() {
    this.showChildView('dhcpOptions', new DHCPOptionListView({
      collection: this.model.get('items'),
    }));
  },

  templateContext: function() {
    var deleteMsg;
    var dhcpOptions = this.model.get('items');
    if (dhcpOptions && dhcpOptions.length > 0) {
      deleteMsg = i18n.t('actionDhcp.advOptions.pendingActionRemoval', {count: dhcpOptions.length});
    } else {
      deleteMsg = i18n.t('actionDhcp.advOptions.pendingEmptyActionRemoval');
    }

    return {
      pendingDeleteMsg: deleteMsg,
    };
  },

  onBeforeRender: function() {
    this.model.updateAvailableOptions();
  },

  onRender: function() {
    if (this.model.get('pendingDelete') === true) {
      return;
    }

    this.initChildViews();
  },

  /**
   * Add an empty option to the end of sent options.
   */
  addDHCPOption: function() {
    this.model.addDHCPOption();
  },
});
