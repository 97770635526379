'use strict';

var Backbone = require('backbone');
var _ = require('lodash');
var rpcMixin = require('lib/mixins/rpc2');
var Job = require('lib/models/Job');
var Radio = require('backbone.radio');
var socket = Radio.channel('socket');
var Reboot = require('lib/models/Job/Reboot');
var FailoverTest = require('lib/models/Job/FailoverTest');

var JOB_TYPES = {
  FAILOVER_TEST: 'failoverTest',
  TRIGGER_REBOOT: 'reboot',
};

var JOB_CLASSES = {
  'failoverTest': FailoverTest,
  'reboot': Reboot,
};

module.exports = Backbone.Collection.extend(rpcMixin).extend({
  model: function(attrs, options) {
    if (attrs.jobType && !_.isUndefined(JOB_CLASSES[attrs.jobType])) {
      return new JOB_CLASSES[attrs.jobType](attrs, options);
    }

    return new Job(attrs, options);
  },

  rpc: {
    read: {
      method: 'DNA.Portal.DeviceJobs.getJobs',
      params: function() {
        return {
          mac: this.mac,
        };
      },
    },
  },

  initialize: function(models, options) {
    this.mac = options.deviceMac;

    this.listenToOnce(this, 'sync', function() {
      this.listenTo(socket, 'device-jobs', this.updateJobs);
    });
  },

  /**
   * @param {String} type
   *  @see Jobs.JOB_TYPES
   * @param {Boolean} pendingOnly
   *  @default false
   * @return {lib/Model/Job} job
   */
  getJobOfType: function(type, pendingOnly) {
    var job = this.findWhere({jobType: type});

    if (!pendingOnly || (job && !job.isDone())) {
      return job;
    }
  },

  updateJobs: function(jobs) {
    this.set(jobs);
  },

  /**
   * @param {String} type
   *  @see Jobs.JOB_TYPES
   * @return {$.Deferred}
   */
  startJob: function(type) {
    var job = this.findWhere({jobType: type});

    if (job && job.isDone()) {
      this.remove(job);
    }

    var newJob = this.model({jobType: type});

    this.add(newJob);

    return newJob.start(this.mac);
  },

  /**
   * @param {Object} resp
   * @return {Array}
   */
  parse: function(resp) {
    if (resp.result !== false) {
      return resp.result;
    }

    return resp;
  },
}, JOB_TYPES);
