'use strict';

var AdvancedValidationModel = require('lib/models/AdvancedValidation');
var snapshotMixin = require('lib/mixins/snapshot');
var _ = require('lodash');
var i18n = require('i18next');
var networkUtils = require('lib/network');

module.exports = AdvancedValidationModel.extend(snapshotMixin).extend({
  /**
   * @member {Object} #attributes
   * @property {Boolean} remove
   *   Whether the filter should be removed on save
   * @property {String} domain
   *   Domain to filter
   * @property {String} type
   *   List type (whitelist, blacklist)
   */

  _snapshotAttributes: [
    'remove',
    'domain',
    'type',
    'subdomains',
    'id',
  ],

  defaults: {
    remove: false,
    domain: null,
    type: 'whitelist',
    subdomains: false,
    id: null,
  },

  validate: function(attrs, options) {
    var errors = {};
    var validateAll = (options && options.validateAll !== false);

    if (this.get('remove') === true) {
      return;
    }

    if (_.has(attrs, 'domain')) {
      if (_.isEmpty(attrs.domain) || !networkUtils.validDomain(attrs.domain)) {
        errors.domain = i18n.t('actionDomainFilters.badDomain');
      } else if (validateAll && attrs.type === 'blacklist' && networkUtils.isDattoDomain(attrs.domain)) {
        errors.domain = i18n.t('actionDomainFilters.dattoDomainDetected');
      } else if (options.allItems) {
        if (_.any(options.allItems, this.conflictsWithDomain, this)) {
          errors.domain = i18n.t('actionDomainFilters.domainConflict');
        } else if (_.any(options.allItems, this.conflictsWithSubDomain, this)) {
          errors.domain = i18n.t('actionDomainFilters.subdomainConflict');
        } else {
          this.trigger('noConflicts');
        }
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Checks whether this domain conflicts with the provided domain.
   *
   * @param {actions/domainFilter/filter} filter
   *   An instance of this model to check for conflicts
   * @return {Boolean}
   */
  conflictsWithDomain: function(filter) {
    var domain;

    // a user never conflicts with itself
    if (filter === this) {
      return false;
    }

    domain = filter.get('domain');
    if (!domain || !networkUtils.validDomain(domain)) {
      // cannot determine conflict if other domain has an invalid domain name
      return false;
    }

    // currently only the domain has to be unique
    return this.get('domain') === domain;
  },

  /**
   * Checks whether this domain conflicts with subdomains of a provided domain.
   *
   * @param {actions/domainFilter/filter} filter
   *   An instance of this model to check for conflicts
   * @return {Boolean}
   */
  conflictsWithSubDomain: function(filter) {
    // a user never conflicts with itself
    if (filter === this) {
      return false;
    }

    // flag conflict if this domain is a subdomain of another entry with "subdomains included" option
    var filterSubs;
    var thisDomain;
    var filterDomain;
    filterSubs = filter.get('subdomains');
    thisDomain = this.get('domain');
    filterDomain = '.' + filter.get('domain');

    return (filterSubs === '1' || filterSubs === 'true') &&
      thisDomain.endsWith(filterDomain);
  },
});
