'use strict';

var BaseGroup = require('config/Group');
var i18n = require('i18next');
var RenderPositions = require('config/RenderPositions');

/**
 * "Traffic Policy" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'trafficPolicyRules',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
  ],

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.trafficPolicy');
  },

  /**
   * Overridden to prevent base Group class from trying to
   * combine multiple traffic policy rules into one
   */
  shouldMergeTasks: false,
});
