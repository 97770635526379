var _ = require('lodash');
var Marionette = require('backbone.marionette');
var Socket = require('lib/Socket');
var appConfig = require('./../appConfig.prod.js');
var Radio = require('backbone.radio');
var macChannel = Radio.channel('mac');

/**
 * Manages the event stream connection.
 */
module.exports = Marionette.Object.extend({
  /**
   * @param {Object} options
   */

  socket: null,

  initialize: function(userSession) {
    _.bindAll(this, 'connect', 'onLogout', 'close', 'reconnect');
    this.userSession = userSession;
    macChannel.on('mac:change', this.reconnect);
    this.listenTo(this.userSession, 'change:loggedIn', this.onLogout);
    this.connect();
  },

  /**
   * Sets up the socket for receiving real-time data.
   * @param {string} mac
   */
  connect: function(mac) {
    this.socket = Socket.factory({
      url: appConfig.apiAjaxUrl,
      debug: appConfig.debug,
      mac: mac,
      withCredentials: appConfig.apiAjaxWithCredentials,
    });

    this.socket.connect();
  },

  onLogout: function(model, isLoggedIn, options) {
    if (!isLoggedIn) {
      this.close();
    }
  },

  close: function() {
    this.socket.close();
  },

  reconnect: function(mac) {
    this.close();
    this.connect(mac);
  },
});
