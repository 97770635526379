'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');
var networkUtils = require('lib/network');

/**
 * Edit model for "NTP servers" setting.
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/router/ntp/EditNtp#attributes
   * @property {String} primaryNtp
   *   The primary NTP server.
   * @property {String} secondaryNtp
   *   The secondary NTP server.
   */

  _snapshotAttributes: ['primaryNtp', 'secondaryNtp'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    // ntp servers config always exists
    return false;
  },

  /**
   * Parse response for NTP servers attributes.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    var ntpServers;

    if (options && options.fromConfig === true) {
      ntpServers = this.deviceConfig.get('router').get('ntpServers');
      resp = _.object(['primaryNtp', 'secondaryNtp'], ntpServers);
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('router');
      },
      events: 'change:ntpServers',
    },
  ],

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Array|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (_.has(attrs, 'primaryNtp') &&
        !networkUtils.validHostname(attrs.primaryNtp)
    ) {
      errors.ntp1 = i18n.t('actionNtp.invalid');
    }

    if (!_.isEmpty(attrs.secondaryNtp) &&
        !networkUtils.validHostname(attrs.secondaryNtp)
    ) {
      errors.ntp2 = i18n.t('actionNtp.invalid');
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * @return {Object|undefined}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    return {
      name: 'router.setNtp',
      // outer pick() is to remove empty secondaryNtp property
      data: _.pick(this.pick('primaryNtp', 'secondaryNtp'), _.identity),
    };
  },

});
