'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/oneoneNat/form.html');
var NatView = require('actions/oneoneNat/FormNatView');
var Nat = require('actions/oneoneNat/Nat');
var twig = require('twig').twig;
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');

var NatsListView = Marionette.CollectionView.extend({
  childView: NatView,

  intialize: function(options) {
    this.options = options || {};
  },

  childViewOptions: function(model, index) {
    return {
      ips: this.options.ips,
    };
  },

  // wire child views to ToggleFormOff
  childViewTriggers: {
    'render': 'item:render',
    'onoff:status:change': 'item:onoff:status:change',
  },
});

module.exports = Marionette.View.extend({
  behaviors: [
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  regions: {
    rules: '.rg-rules',
  },

  ui: {
    add: '.btn-add',
    rules: '.help-block',
  },

  events: {
    'click @ui.add': 'addRule',
  },

  modelEvents: {
    'invalid': 'onError',
    'change:secondaryIps': 'render',
  },

  initialize: function() {
    this.listenTo(this.model.get('items'), {
      'change': this.onItemChange,
    });
  },

  onRender: function() {
    this.initChildViews();
  },

  onError: function(model, error, options) {
    if (error.duplicateIp) {
      this.ui.rules.bs3ui('showFieldError', error.duplicateIp);
    }
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    var view = new NatsListView({
      collection: this.model.get('items'),
      ips: this.model.get('secondaryIps'),
    });

    this.showChildView('rules', view);
  },

  addRule: function() {
    var nat = new Nat();
    this.model.get('items').add(nat);
  },

  onItemChange: function() {
    this.ui.rules.bs3ui('clearFieldError');
  },
});
