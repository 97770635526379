'use strict';

var $ = require('jquery');

/**
 * jQuery method to attach an "animationEnd" event to an element, which modern
 * browsers support, but require browser-prefixes.
 *
 * @param {Function} callback
 * @return {jQuery}
 */
$.fn.onAnimationEnd = function(callback) {
  callback = callback || function() {};

  return this.each(function() {
    $(this).one(
      'webkitAnimationEnd mozAnimationEnd MSAnimationEnd ' +
      'oAnimationEnd animationend',
      callback
    );
  });
};
