'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Marionette = require('backbone.marionette');
require('jquery.scrollto');

/**
 * Simple behavior for smoothly scrolling a specific element into view.
 *
 * To use, trigger the "scroll:me" event:
 * ```
 * // options are anything supported by the jquery.scrollTo plugin
 * myView.triggerMethod('scroll:me', this.$el, options);
 * ```
 *
 * Note, for an instantaneous scroll, pass a "duration" of zero.
 */
module.exports = Marionette.Behavior.extend({
  defaults: {
    duration: 500,
  },

  /**
   * Scrolls the passed element into view.
   *
   * @param {jQuery} $element
   * @param {Object} options
   */
  onScrollMe: function($element, options) {
    var duration = this.options.duration;
    options = options || {};

    if (_.has(options, 'duration')) {
      duration = options.duration;
    }

    $(window).scrollTo($element, duration, _.omit(options, 'duration'));
  },
});
