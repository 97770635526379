'use strict';

var i18n = require('i18next');
var EditConditionalDnsRules = require('actions/conditionalDns/EditConditionalDns');
var FormView = require('actions/conditionalDns/FormView');
var StaticView = require('actions/conditionalDns/StaticView');

/**
 * Action configuration for the Conditional DNS Forwarding rules action component.
 */
module.exports = {
  title: i18n.t('conditionalDns.rulesTitle'),
  Model: EditConditionalDnsRules,
  FormView: FormView,
  TextView: StaticView,
};
