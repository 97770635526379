'use strict';

var i18n = require('i18next');
var EditNtp = require('actions/router/ntp/EditNtp');
var FormView = require('actions/router/ntp/FormView');
var StaticView = require('actions/router/ntp/StaticView');

/**
 * Action configuration for the NTP action component.
 */
module.exports = {
  title: i18n.t('actionNtp.title'),
  Model: EditNtp,
  FormView: FormView,
  TextView: StaticView,
};
