'use strict';

var Backbone = require('backbone');
var i18n = require('i18next');
var NetworkDisruptionAlert = require('actionGroups/shared/NetworkDisruptionView');

var staticProps = {
  alertId: 'wanSpeedDuplexAlert',
};

/**
 * Custom GroupAlertView for the "ethernet settings" - primarily focusing on speed/duplex settings on WANs.
 */
module.exports = NetworkDisruptionAlert.extend({
  initialize: function(options) {
    this.model = new Backbone.Model({
      id: staticProps.alertId,
      msg: i18n.t('actionSpeedDuplex.speedDuplexWarning'),
    });
  },

}, staticProps);
