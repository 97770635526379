'use strict';

var $ = require('jquery');
var bs3ui = {};
var _ = require('lodash');
var uiIcons = require('lib/tplHelpers/uiIcons');

var fieldErrorQueue = [];
var pauseFieldErrors = false;

// DNA-1537: prevent field validation errors from appearing or disappearing while the user
// is in the middle of a click, so that the control they're interacting with doesn't move
document.addEventListener('mousedown', function() {
  pauseFieldErrors = true;
});

document.addEventListener('mouseup', function() {
  pauseFieldErrors = false;
  processFieldErrors();
});

/**
 * Drains the queue of field errors pending for update in the UI
 */
function processFieldErrors() {
  if (pauseFieldErrors) {
    return;
  }

  while (fieldErrorQueue.length > 0) {
    var job = fieldErrorQueue.shift();
    job.fn.apply(job.context, job.args);
  }
}

/**
 * Adds the "has-error" class to the current element or the closet parent;
 * whichever one has the .form-group class. Also, displays the passed error
 * message within a .help-block element, which must already exist.
 *
 * @param {string} errorMsg
 * @return {jQuery}
 */
bs3ui.showFieldError = function(errorMsg) {
  var fn = function() {
    this.each(function() {
      var wrap = $(this).closest('.form-group');

      wrap.addClass('has-error');
      wrap.find('.help-block.error').text(errorMsg).removeClass('hidden');
    });
  };

  fieldErrorQueue.push({fn: fn, context: this, args: arguments});
  processFieldErrors();

  return this;
};

/**
 * Removes the "has-error" class from the current element or the closest
 * parent, whichever one has the .form-group class. Also, clears out the text
 * from the .help-block element and hides it.
 *
 * @return {jQuery}
 */
bs3ui.clearFieldError = function() {
  var fn = function() {
    this.each(function() {
      var wrap = $(this).closest('.form-group');
      wrap.removeClass('has-error');
      wrap.find('.help-block.error').text('').addClass('hidden');
    });
  };

  fieldErrorQueue.push({fn: fn, context: this, args: arguments});
  processFieldErrors();

  return this;
};

/**
 * Adds the "has-error" class to the current element or the closest parent;
 * whichever one has the .form-item class. Also, displays the passed error
 * message as a list item within the provided list element.
 *
 * In contrast to showFieldError, this is intended for use in situations
 * where multiple fields are within a single "group" and share a common
 * area for error display.
 *
 * @param {string} errorMsg
 * @param {string} errorKey
 *   Unique identifier for the error; pass again to replace the error
 *   or pass to clearGroupedFieldError to remove the error
 * @param {jQuery} msgOutputList
 *   jQuery-wrapped <ul> or <ol> element in which to add errors
 * @return {jQuery}
 */
bs3ui.showGroupedFieldError = function(errorMsg, errorKey, msgOutputList) {
  var fn = function() {
    var msg = msgOutputList.children('[data-key=' + errorKey + ']');
    if (msg.length === 0) {
      msg = $('<li class="error" data-key="' + errorKey + '"></li>');
      msgOutputList.append(msg);
    }
    msg.text(errorMsg);

    this.each(function() {
      $(this).closest('.form-item').addClass('has-error');
    });
  };

  fieldErrorQueue.push({fn: fn, context: this, args: arguments});
  processFieldErrors();

  return this;
};

/**
 * Removes the "has-error" class from the current element or the closest
 * parent, whichever one has the .form-item class. Also, removes the
 * associated list item from the provided list element.
 *
 * @param {string} errorKey
 *   Unique identifier for the error as passed to showGroupedFieldError
 * @param {jQuery} msgOutputList
 *   jQuery-wrapped <ul> or <ol> element from which to remove errors
 * @return {jQuery}
 */
bs3ui.clearGroupedFieldError = function(errorKey, msgOutputList) {
  var fn = function() {
    msgOutputList.children('[data-key=' + errorKey + ']').remove();
    this.each(function() {
      $(this).closest('.form-item').removeClass('has-error');
    });
  };

  fieldErrorQueue.push({fn: fn, context: this, args: arguments});
  processFieldErrors();

  return this;
};

/**
 * Finds all children of the passed element, removing the "has-error" class
 * from any element with it.
 *
 * @return {jQuery}
 */
bs3ui.clearAllFieldErrors = function() {
  var fn = function() {
    this.each(function() {
      var blocks = $(this).find('.has-error');
      blocks.removeClass('has-error');
      blocks.find('.help-block').text('').addClass('hidden');
    });
  };

  fieldErrorQueue.push({fn: fn, context: this, args: arguments});
  processFieldErrors();

  return this;
};

bs3ui.inlineStatus = function($elm, type, message) {
  var fn = function() {
    var iconClass = uiIcons.icons[type];

    $elm.addClass('has-icon');
    $elm.text(message);

    if (!_.isUndefined(iconClass)) {
      $elm.prepend('<i class="' + iconClass + '"></i> ');
    }

    $elm.removeClass('hidden');
  };
  fieldErrorQueue.push({fn: fn, context: this, args: arguments});
  processFieldErrors();

  return this;
};

bs3ui.clearInlineStatus = function($elm) {
  var fn = function() {
    this.addClass('hidden');
    this.removeClass('has-icon');
    this.text('');
  };
  fieldErrorQueue.push({fn: fn, context: this, args: arguments});
  processFieldErrors();

  return this;
};

/**
 * Collection of helpers for rendering Bootstrap-decorated elements.
 *
 * At the moment, this collection is basic and unsophisticated. We'll see
 * where we need to take it as we add more items.
 *
 * @param {string} method
 *   See {@link bs3ui} for list of methods and their arguments.
 * @return {jQuery}
 * @throws {Error}
 *   Throws error if passed method does not exist.
 */
$.fn.bs3ui = function(method) {
  var args = Array.prototype.slice.call(arguments, 1);

  if (typeof bs3ui[method] !== 'function') {
    throw new Error('Method ' + method + ' does not exist on jQuery.bs3Form');
  }

  return bs3ui[method].apply(this, args);
};
