'use strict';

var $ = require('jquery');
var Marionette = require('backbone.marionette');
var NavItemView = require('components/navbar/NavbarItemView');

/**
 * Renders a horizontal navbar.
 *
 * @example
 * var pages = new Backbone.Collection([
 *   {id: 'home', label: 'Home', url: '/some/thing', isActive: true},
 *   {id: 'about', label: 'About', url: '/another/thing'}
 * ]);
 * var navbar = new NavbarView({collecton: pages});
 * navbar.on('navbarItem:selected', function(navItem) {
 *   // do something when nav item clicked
 * });
 */
module.exports = Marionette.CollectionView.extend({
  tagName: 'nav',
  className: 'dui-tabs',
  childView: NavItemView,
  childViewOptions: function(model, index) {
    return {
      grid: this.grid,
    };
  },

  /**
   * @param {Object} options
   * @property {Boolean} options.padded
   *   Indicates whether or not the navbar should have left and right padding.
   * @property {Boolean} options.loose
   *   Indicates whether or not the navbar should use loose spacing between tabs.
   */
  initialize: function(options) {
    this.mergeOptions(options, ['padded', 'large', 'label', 'grid']);
  },

  onBeforeRender: function() {
    if (this.padded) {
      this.$el.addClass('dui-tabs--padded');
    }

    if (this.large) {
      this.$el.addClass('dui-tabs--large');
    }

    if (this.grid) {
      this.$el.addClass('dui-grid');
    }
  },

  onAttach: function() {
    if (this.label) {
      var label = $('<span />', {
        text: this.label,
        class: 'dui-tabs__label',
      });

      this.$el.prepend(label);
    }
  },

  /**
   * @param {components/navbar/NavbarView} childView
   */
  onChildviewNavItemSelected: function(childView) {
    this.onSetActive(childView.model.id);
    this.triggerMethod('navbarItem:selected', childView.model);
  },

  /**
   * Sets the active nav item.
   *
   * @listens set:active
   * @param {String} id
   */
  onSetActive: function(id) {
    this.collection.each(function(navItem) {
      navItem.set('isActive', (navItem.id === id));
    });
  },

  clearInvalidItems: function() {
    this.children.each(function(navItem) {
      navItem.unsetInvalid();
    });
  },
});
