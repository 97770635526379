'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var formTpl = require('actions/wanMTU/form.html');
var RenderChanges = require('lib/behaviors/RenderChanges');

/**
 * Renders the MTU input field for WANs.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/wanMTU/FormView#model
   * @type {actions/wanMTU/EditWanMTU}
   */

  behaviors: [{
    behaviorClass: RenderChanges,
  }],

  template: twig({data: formTpl}),

  ui: {
    mtu: '[name="wan-mtu"]',
  },

  events: {
    'blur @ui.mtu': 'updateModel',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  templateContext: function() {
    return tplHelpers.apply(this);
  },

  /**
   * Decorates the form field to indicate error
   *
   * @param {actions/wanMTU/EditWanMTU} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.mtu) {
      this.ui.mtu.bs3ui('showFieldError', error.mtu);
    }
  },

  /**
   * Clear the given form field error
   * @param {string} key
   */
  clearError: function(key) {
    this.ui[key].bs3ui('clearFieldError');
  },

  /**
   * Update the model on form changes
   *
   * @param {Event} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'wan-mtu':
        this.clearError('mtu');
        this.model.set({mtu: value}, {commit: true});
        break;
      default:
        break;
    }
  },

});
