'use strict';

var _ = require('lodash');
var $ = require('jquery');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tplDropdown = require('components/dropdown/dropdown.html');

/**
 * Simple View representing a (single-level) dropdown widget.
 *
 * Note, this dropdown is highly stylized and does not use a regular ol'
 * SELECT element. At the moment, it is marked up using Bootstrap's
 * button/split-button dropdown component.
 *
 * @example
 * var dropdownModel = new Backbone.Model({
 *   label: 'Toggle favorite colors',   //screen-reader text
 *   options: [
 *     {
 *       id: 'red',
 *       label: 'Red',
 *       selected: false,
 *       href: '', // optional
 *     },
 *     ...
 *   ]
 * });
 *
 * var dropdown = new DropdownView({model: dropdownModel});
 *
 * // To listen for when the user selects an item:
 * dropdown.on('dropdownItem:selected', function(option) {
 *   // option = the selected item in "options" list
 * });
 */
module.exports = Marionette.View.extend({
  /**
   * @member {Backbone.Model} components/dropdown/DropdownView#model
   * @property {String} attributes.name
   *   The name of the dropdown, which will be added as an ID attribute to the HTML.
   * @property {String} attributes.label
   *   The label to display alongside the dropdown.
   * @property {Array} attributes.options
   *   List of options to display in the dropdown. Each option is an object
   *   that looks like `{id: "", label: "", selected: true|false}`.
   */

  template: twig({data: tplDropdown}),
  className: 'btn-group',

  ui: {
    'button': '.dropdown-btn',
    'options': 'li',
  },

  events: {
    'click @ui.button': 'onSelfSelection',
    'click @ui.options': 'onSelection',
  },

  /**
   * Indicates whether to use "Single button" or "split button" style dropdown.
   * @type {Boolean}
   */
  useSplitButton: true,

  /**
   * @param {Object} options
   * @property {Boolean} options.useSplitButton
   */
  intialize: function(options) {
    this.mergeOptions(options, ['useSplitButton']);
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    var optionsList = this.model.get('options');

    return {
      useSplitButton: this.useSplitButton,
      selectedItem: function() {
        var selected = _.find(optionsList, {selected: true});
        return selected.label;
      },
      options: function() {
        return _.where(optionsList, {selected: false});
      },
    };
  },

  /**
   * Updates the dropdown when the user clicks an option. An event is also
   * triggered, passing the clicked option's value.
   *
   * @fires dropdownItem:selected
   * @param {Event} ev
   */
  onSelection: function(ev) {
    ev.preventDefault();

    var value = $(ev.currentTarget).data('value');
    var selected = _.find(this.model.get('options'), {id: value});

    this.triggerMethod('dropdownItem:selected', selected);
  },

  /**
   * Trigger event when the "current" value is selected. In most cases, the
   * application will want to ignore it. But, there are cases, like a breadcrumb,
   * where it may be relevant.
   *
   * @fires dropdownItem:selected
   * @param {Event} ev
   */
  onSelfSelection: function(ev) {
    if (!this.useSplitButton) {
      // If not using split button, the click is to open the dropdown, so be
      // sure to pass that event through.
      return;
    }

    var current = _.find(this.model.get('options'), {selected: true});
    ev.preventDefault();

    this.triggerMethod('dropdownItem:selected', current);
  },
});
