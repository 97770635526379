'use strict';

var Marionette = require('backbone.marionette');
var RuleView = require('actions/conditionalDns/rule/RuleView');
var RuleModel = require('actions/conditionalDns/rule/Rule');
var tpl = require('actions/conditionalDns/form.html');
var twig = require('twig').twig;
require('lib/jquery/bootstrapUI');

var RulesListView = Marionette.CollectionView.extend({
  childView: RuleView,
});

/**
 * Renders the editable form for the collection of conditional DNS forwarding rules
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/conditionalDns/FormView#model
   * @type {actions/conditionalDns/EditConditionalDns}
   */

  template: twig({data: tpl}),

  regions: {
    rules: '.rg-rules',
  },

  ui: {
    add: '.btn-add',
  },

  events: {
    'click @ui.add': 'addRule',
  },

  onRender: function() {
    this.initChildViews();
  },

  initChildViews: function() {
    this.showChildView('rules', new RulesListView({
      collection: this.model.get('items'),
    }));
  },

  addRule: function() {
    var rule = new RuleModel();
    this.model.get('items').add(rule);
  },

});
