'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var BaseStaticView = require('actions/shared/AbstractStaticView');
var PolicyView = require('actions/failoverPolicyControl/policies/StaticPolicyView');
var tpl = require('actions/failoverPolicyControl/static.html');
var twig = require('twig').twig;
var viewFilters = require('lib/viewFilters');

var PoliciesListView = Marionette.CollectionView.extend({
  childView: PolicyView,
  filter: viewFilters.getStaticViewItemsCollectionViewFilter(),
});

/**
 * Renders a text-only version of the "failover policy rules" component.
 */
module.exports = BaseStaticView.extend({
  /**
   * @name actions/failoverPolicyControl/StaticView#model
   * @type {actions/failoverPolicyControl/EditFailoverPolicyControl}
   */

  template: twig({data: tpl}),

  regions: {
    policies: '.rg-policies',
  },

  templateContext: function() {
    var baseHelpers = BaseStaticView.prototype.templateContext.apply(this);

    return _.extend({}, baseHelpers, {
      getItemCountHelp: viewFilters.getItemCountHelpFn({
        // i18n.t('failoverPolicyControl.noPolicies')
        emptyMsgKey: 'failoverPolicyControl.noPolicies',
        // i18n.t('failoverPolicyControl.allDisabled', {count: count})
        allDisabledMsgKey: 'failoverPolicyControl.allDisabled',
        // i18n.t('failoverPolicyControl.viewAll', {count: count})
        moreMsgKey: 'failoverPolicyControl.viewAll',
      }),
    });
  },

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    this.showChildView('policies', new PoliciesListView({
      collection: this.model.get('items'),
    }));
  },

});
