'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var layoutTpl = require('manage/details/connectedDevice/connected-device-details.html');
var Graph = require('components/usage/GraphItem');

/**
 * Renders the selected connected-device View. Items displayed include total
 * usage and IP address.
 */
module.exports = Marionette.View.extend({
  template: twig({allowInlineIncludes: true, data: layoutTpl}),

  className: 'row',

  regions: {
    usage: '.rg-graphs',
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.model.fetch();
  },

  onRender: function() {
    var id = this.model.get('id').toLowerCase();
    var usage = this.model.get('bandwidth');
    var deviceStatus = this.model.get('deviceStatus');

    var graph = new Graph({
      id: id,
      usage: usage,
      deviceStatus: deviceStatus,
      graphType: 'device',
    });

    this.showChildView('usage', graph);
  },
});
