'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('manage/details/ports/aggregated/aggregated-ports-list.html');
var AggregatedPortView = require('manage/details/ports/aggregated/AggregatedPortView');
var LibPorts = require('lib/ports');
var _ = require('lodash');

module.exports = Marionette.View.extend({
  template: twig({allowInlineIncludes: true, data: tpl}),

  ui: {
    header: 'h2',
  },

  regions: {
    aggregatedPortsList: {
      el: '.rg-aggregated-ports-list',
      replaceElement: true,
    },
  },

  initialize: function(options) {
    this.deviceStatus = options.deviceStatus;
    this.deviceConfig = options.deviceConfig;

    var ports = LibPorts.getBondPorts(this.deviceConfig, this.deviceStatus);

    this.collection = new Backbone.Collection(ports);
    this.listenTo(this.collection, 'reset add remove', this.toggleHeader);
    this.listenTo(this.deviceConfig.get('nicBonds'), 'remove', this._onNicBondRemoved);
    this.listenTo(this.deviceConfig.get('nicBonds'), 'add', this._onNicBondAdded);
    this.listenTo(this.deviceConfig.get('nicBonds'), 'change', this._onNicBondChanged);
    this.listenTo(this.deviceStatus.get('nicBonds'), 'change add remove', this._onNicBondStatusChanged);
    this.listenTo(this.deviceStatus.get('ports'), 'change add remove', this._onPortChange);
  },

  onRender: function() {
    var AggregatedPortsList = Marionette.CollectionView.extend({
      collection: this.collection,
      childView: AggregatedPortView,
    });

    this.toggleHeader();
    this.showChildView('aggregatedPortsList', new AggregatedPortsList(this.options));
  },

  toggleHeader: function() {
    this.ui.header.toggleClass('hidden', this.collection.length === 0);
  },

  _onNicBondRemoved: function(bondModel) {
    this.collection.remove(bondModel.id);
  },

  _onNicBondAdded: function(bondModel) {
    this.collection.add(LibPorts.formatAggregatedPort(bondModel, this.deviceConfig, this.deviceStatus));
  },

  _onNicBondChanged: function(bondModel) {
    var newAttrs = LibPorts.formatAggregatedPort(bondModel, this.deviceConfig, this.deviceStatus);
    this.collection.set([newAttrs], {remove: false});
  },

  _onNicBondStatusChanged: function(bondStatus) {
    var bondModel = this.deviceConfig.get('nicBonds').get(bondStatus.id);
    if (bondModel) {
      var newAttrs = LibPorts.formatAggregatedPort(bondModel, this.deviceConfig, this.deviceStatus);
      this.collection.set([newAttrs], {remove: false});
    }
  },

  _onPortChange: function(port) {
    // Getting this information from configuration because a bondStatus is not guaranteed to have the slave
    // port in its status. This is an edge case but possible if timings are just right. This would leave the UI
    // in an invalid state.
    var nicBonds = this.deviceConfig.get('nicBonds');
    var self = this;
    nicBonds.forEach(function(bondConfig) {
      if (bondConfig.has('portsMap')) {
        var slaves = bondConfig.get('portsMap');

        var bondModel = self.deviceConfig.get('nicBonds').get(bondConfig.id);
        // Only updated / add ports that are a part of this bond
        if (_.indexOf(slaves, port.id) >= 0 && bondModel) {
          var newAttrs = LibPorts.formatAggregatedPort(bondModel, self.deviceConfig, self.deviceStatus);
          self.collection.set([newAttrs], {remove: false});
        }
      }
    });
  },
});
