'use strict';

var BaseGroup = require('config/Group');
var i18n = require('i18next');
var RenderPositions = require('config/RenderPositions');
var DnsPlaceholderActionManager = require('actionGroups/shared/DnsPlaceholderActionManager');

/**
 * "Manage LTE" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'lteDns',
      order: 1,
      position: RenderPositions.first,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: true,
      disabledOnEnabledConfig: [
        {id: 'webFiltersTitanHq', reason: i18n.t('configEdit.disabledReason.dnsProvidedByWebFilters')},
      ],
    },
    {
      name: 'wanDnsPlaceholder',
      order: 2,
      position: RenderPositions.first,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: true,
      managed: true,
    },
    {
      name: 'wanWebFilterDns',
      order: 2,
      position: RenderPositions.last,
      required: false,
      renderTitle: true,
      renderStatic: true,
      doubleWide: true,
      requireCapabilities: ['supportsWebFilteringTitanHq'],
      managed: true,
    },
  ],

  actionManagers: [
    DnsPlaceholderActionManager,
  ],

  isConnectivityDisrupted: function(type, target) {
    return false;
  },

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.editLte');
  },
});
