'use strict';

var i18n = require('i18next');
var EditTitanHqSettings = require('actions/webFiltersTitanHq/settings/EditTitanHqSettings');
var FormView = require('actions/webFiltersTitanHq/settings/FormView');
var StaticView = require('actions/webFiltersTitanHq/settings/StaticView');

/**
 * Settings action configuration for Web Filters TitanHQ
 */
module.exports = {
  title: i18n.t('actionWebFilters.title'),
  Model: EditTitanHqSettings,
  FormView: FormView,
  TextView: StaticView,
};
