'use strict';

var i18n = require('i18next');
var EditLoadBalance = require('actions/loadBalance/EditLoadBalance');
var FormView = require('actions/loadBalance/FormView');
var StaticView = require('actions/loadBalance/StaticView');

/**
 * Action configuration for Load Balance
 */
module.exports = {
  title: i18n.t('actionLoadBalance.title'),
  Model: EditLoadBalance,
  FormView: FormView,
  TextView: StaticView,
};
