'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var tpl = require('actions/dnsLookup/form.html');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var RenderChanges = require('lib/behaviors/RenderChanges');
require('lib/jquery/bootstrapUI');

/**
 * Renders the "dnsLookup" form field.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/dnsLookup/FormView#model
   * @type {actions/dnsLookup/DnsLookup}
   */

  behaviors: [
    {
      behaviorClass: RenderChanges,
    },
  ],

  template: twig({data: tpl}),

  ui: {
    targetHostname: '[name="targetHostname"]',
    serverType: '[name="serverType"]',
    customServer: '[name="customServer"]',
    messagesTargetHostname: '.error-targetHostname',
    messagesCustomServer: '.error-customServer',
  },

  events: {
    'blur @ui.targetHostname': 'updateModel',
    'change @ui.serverType': 'updateModel',
    'blur @ui.customServer': 'updateModel',
  },

  modelEvents: {
    'invalid': 'onError',
  },

  /**
   * @returns {Object}
   */
  templateContext: function() {
    var context = {
      lanList: this.model.get('lans'),
    };
    return _.extend(context, tplHelpers.apply(this));
  },

  /**
   * @param {Event} event
   */
  updateModel: function(event) {
    var name = event.currentTarget.name;
    var value = event.currentTarget.value;

    switch (name) {
      case 'targetHostname':
        this.clearError('targetHostname');
        this.model.set({targetHostname: value}, {commit: true});
        break;

      case 'serverType':
        this.clearError('customServer');
        this.model.set({serverType: value});
        this.model.set('server', this.model.get('servers')[value]);
        if (value === 'custom') {
          // This will remove the 'hidden' class and empty the form box
          this.render();
        } else {
          this.ui.customServer.addClass('hidden');
        }
        break;

      case 'customServer':
        this.clearError('customServer');
        this.model.set({server: value}, {commit: true});
        break;

      default:
        break;
    }
  },

  /**
   * @param {Object} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    var errorMessages = {
      targetHostname: this.ui.messagesTargetHostname,
      customServer: this.ui.messagesCustomServer,
    };
    _.forEach(error, function(value, key) {
      this.ui[key].bs3ui('showGroupedFieldError', value, key, errorMessages[key]);
    }, this);
  },

  /**
   * Remove an error message from the view (if present)
   *
   * @param {String} key
   *   Identifier for the error message
   */
  clearError: function(key) {
    var errorMessages = {
      targetHostname: this.ui.messagesTargetHostname,
      customServer: this.ui.messagesCustomServer,
    };
    this.ui[key].bs3ui('clearGroupedFieldError', key, errorMessages[key]);
  },

  /**
   * Because of the way the LANs are looped over in the HTML,
   * whenever the form loses focus and the server was set to a LAN,
   * the form will switch to Google but the model will retain the LAN value
   *
   * This will manually trigger the model to switch to Google
   * if the server isn't custom so that the display and model always match
  */
  onBeforeRender: function() {
    if (this.model.get('serverType') !== 'custom') {
      this.model.set('serverType', 'google');
      this.model.set('server', this.model.get('servers').google);
    }
  },
});
