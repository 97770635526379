'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');

/**
 * Edit model for NetworkObject Description
 */
module.exports = ActionItem.extend({
  /**
   * @member {Object} actions/description/networkObject/EditDescription#attributes
   * @property {String} id
   *   The ID of the associated networkObject
   * @property {string} description
   *   Network description
   */

  _snapshotAttributes: ['description'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return _.isUndefined(this.deviceConfig.get('firewall').get('networkObjects').get(this.id));
  },

  /**
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig && !this.isNew()) {
      return {description: this.getDescriptionFromConfig(this.id)};
    }

    return resp;
  },

  getDescriptionFromConfig: function(id) {
    var networkObjects = this.deviceConfig.get('firewall').get('networkObjects');
    return networkObjects.get(id).get('description');
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('firewall').get('networkObjects').get(this.id);
      },
      events: 'change:description',
    },
  ],

  /**
   * @return {Object}
   */
  getTask: function() {
    var data = {description: this.get('description')};

    if (!this.isNew()) {
      data.id = this.id;
    }

    return {
      name: 'networkObject.createOrEditNetworkObject',
      data: data,
    };
  },

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};
    var validateAll = (options && options.validateAll !== false);

    if (!_.isUndefined(attrs.description) || validateAll) {
      if (_.isEmpty(attrs.description)) {
        errors.description = i18n.t('networkObject.missingDescription');
      }
    }

    if (!attrs.description.match(/^[0-9a-zA-Z-_ ]+$/)) {
      errors.description = i18n.t('networkObject.badDescription');
    }

    if (!errors.description && !this._uniqueDescription(attrs.description)) {
      errors.description = i18n.t('networkObject.duplicateDescription');
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },

  /**
   * Verify that the entered description is not the same as other network objects.
   *
   * @private
   * @param {String} description
   * @returns {Boolean}
   */
  _uniqueDescription: function(description) {
    var self = this;
    // No duplicate descriptions
    var duplicates = this.deviceConfig.get('firewall').get('networkObjects').filter(function(networkObject) {
      return (networkObject.get('description').toLowerCase().trim() === description.toLowerCase().trim() &&
      networkObject.id !== self.id && networkObject.id);
    });

    return _.isEmpty(duplicates);
  },

});
