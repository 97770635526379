'use strict';

var i18n = require('i18next');
var FormView = require('actions/wanDnsPlaceholder/FormView');
var StaticView = require('actions/wanDnsPlaceholder/StaticView');
var PlaceholderModel = require('actions/shared/ActionItemPlaceholderModel');

/**
 * Action configuration for default DNS configuration
 */
module.exports = {
  title: i18n.t('actionWanDnsPlaceholder.title'),
  Model: PlaceholderModel,
  FormView: FormView,
  TextView: StaticView,
};
