'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tpl = require('components/usage/usage.html');
var Radio = require('backbone.radio');
var graphChannel = Radio.channel('graphChannel');
var GraphModel = require('components/usage/GraphModel');
var numberFormatters = require('lib/tplHelpers/numberFormatters');
var Chart = require('chart.js');
var moment = require('moment');

/**
 * Renders the actual svg graph
 *
 * @constructor
 * @name components/usage/UsageView
 */
module.exports = Marionette.View.extend({
  /**
   * @member {boolean} manage/details/bandwidth/BandwidthItemView#haltUpdates
   * Dictates that the graph should or should not update on mouse leave/enter, respectively
   */

  template: twig({data: tpl}),

  ui: {
    canvasEl: '.usage-canvas',
    noData: '.no-data',
  },

  chart: undefined,
  chartAttributes: {
    down: {
      name: 'Download',
      color: '#1DA8DE',
      outlineColor: '#1DA8DE',
    },
    up: {
      name: 'Upload',
      color: '#63D09B',
      outlineColor: '#63D09B',
    },
    lineJoinStyle: 'bevel',
    lineWidth: 1,
  },

  data: [],
  newData: [],

  // 360 translates to 30 minutes of data
  maxDataPoints: 360,

  events: {
    'mouseenter': function() {
      this.haltUpdates = true;
    },
    'mouseleave': function() {
      this.haltUpdates = false;
    },
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.bindUIElements();
    _.bindAll(this,
      'buildChart',
      'subscribeToUpdates',
      'onThroughput',
      'addDataPoints',
      'setData',
      'setTooltipLabelFormat',
      'setTooltipTitleFormat');
    this.haltUpdates = false;
    this.settings = new GraphModel(options.settings);
    this.subscribeToUpdates();
  },

  subscribeToUpdates: function() {
    if (this.settings.get('liveUpdates')) {
      this.listenTo(graphChannel, {'throughput:data': this.onThroughput});
    }
  },

  onThroughput: function() {
    this.addDataPoints();
  },

  onRender: function() {
    var data = this.model.get('data');
    if (data.length > 0) {
      this.setData(data);
      this.trimData(this.data.down);
      this.trimData(this.data.up);
    }
  },

  onDestroy: function() {
    if (this.settings.get('liveUpdates')) {
      this.stopListening(graphChannel, 'throughput:data');
    }
  },

  onDomRefresh: function() {
    if (this.model.get('data').length > 0) {
      this.buildChart();
    }
  },

  onDomRemove: function() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  },

  buildChart: function() {
    this.ui.canvasEl.show();
    this.chart = new Chart(this.ui.canvasEl, {
      type: 'line',
      data: {
        datasets: [
          {
            label: this.chartAttributes.down.name,
            fill: true,
            borderWidth: this.chartAttributes.lineWidth,
            borderColor: this.chartAttributes.down.outlineColor,
            backgroundColor: this.chartAttributes.down.color,
            borderJoinStyle: this.chartAttributes.lineJoinStyle,
            data: this.data.down,
            spanGaps: true,
            pointRadius: 0,
            lineTension: 0,
          },
          {
            label: this.chartAttributes.up.name,
            fill: true,
            borderWidth: this.chartAttributes.lineWidth,
            borderColor: this.chartAttributes.up.outlineColor,
            backgroundColor: this.chartAttributes.up.color,
            borderJoinStyle: this.chartAttributes.lineJoinStyle,
            data: this.data.up,
            spanGaps: true,
            pointRadius: 0,
            lineTension: 0,
          },
        ],
      },
      options: {
        spanGaps: true,
        legend: {
          display: true,
        },
        tooltips: {
          mode: 'index',
          intersect: true,
          callbacks: {
            label: this.setTooltipLabelFormat,
            title: this.setTooltipTitleFormat,
          },
        },
        animation: {
          duration: 500,
        },
        layout: {
          padding: 10,
        },
        scales: {
          yAxes: [{
            ticks: {
              fontSize: 10,
              callback: this.setYAxesFormat,
            },
          }],
          xAxes: [{
            ticks: {
              fontSize: 10,
              maxRotation: 0,
              autoSkip: true,
              autoSkipPadding: 20,
            },
            type: 'time',
            time: {
              displayFormats: {
                millisecond: 'h:mm:ssa',
                second: 'h:mm:ssa',
                minute: 'h:mma',
              },
            },
            position: 'bottom',
          }],
        },
      },
    });
  },

  setTooltipTitleFormat: function(tooltipItem) {
    return moment(_.first(tooltipItem).xLabel).format('h:mm a');
  },

  setTooltipLabelFormat: function(tooltipItem) {
    return this.setYAxesFormat(tooltipItem.yLabel);
  },

  setYAxesFormat: function(value, index, values) {
    value = Math.abs(value);
    return numberFormatters.formatBytes(value);
  },

  updateChart: function() {
    var downData = this.chart.data.datasets[0].data;
    var upData = this.chart.data.datasets[1].data;
    this.deDupe(downData, this.data.down);
    this.deDupe(upData, this.data.up);
    [].push.apply(downData, this.data.down);
    [].push.apply(upData, this.data.up);
    this.trimData(downData);
    this.trimData(upData);
    downData.sort(this.sortCoordinates);
    upData.sort(this.sortCoordinates);
    this.chart.update();
  },

  trimData: function(chartData) {
    // trim the data to the max time length (30 mins)
    if (chartData.length > this.maxDataPoints) {
      chartData.splice(0, chartData.length - this.maxDataPoints);
    }
  },

  sortData: function(a, b) {
    if (a.timestamp > b.timestamp) {
      return 1;
    } else if (a.timestamp < b.timestamp) {
      return -1;
    }
    return 0;
  },

  sortCoordinates: function(a, b) {
    if (a.x > b.x) {
      return 1;
    } else if (a.x < b.x) {
      return -1;
    }
    return 0;
  },

  deDupe: function(chartData, data) {
    if (data.length > 0) {
      data.sort(this.sortCoordinates);
      var minTimestamp = data[0].x;
      for (var i = chartData.length - 1; i >= 0; i--) {
        if (chartData[i].x < minTimestamp) {
          break;
        }
        var ix = _.findIndex(data, this.checkDupe.bind(this, chartData[i]));
        if (ix > -1) {
          if (Math.abs(data[ix].y) > Math.abs(chartData[i].y)) {
            // if the incoming data point has a larger value (and therefore must incorporate
            // data that wasn't available before), replace the existing data point with it
            chartData[i] = data[ix];
          }
          data.splice(ix, 1);
        }
      }
    }
  },

  checkDupe: function(itemB, itemA) {
    return itemA.x === itemB.x;
  },

  fillGaps: function(lastX, timestamp) {
    // throughput is running in 5 second intervals
    var gapTimeInSeconds = 5;
    var gaps = [];
    var last = parseInt(lastX);
    var diff = parseInt(timestamp) - last;

    while (diff > gapTimeInSeconds) {
      last += gapTimeInSeconds;
      diff -= gapTimeInSeconds;

      gaps.push({x: last * 1000, y: 0});
    }
    return gaps;
  },

  addDataPoints: function() {
    // this queues up new data until the chart initial build / render is done
    // otherwise it takes the new data and throws it into the chart
    if (this.model.get('newData') && this.model.get('newData').length > 0) {
      this.newData.push.apply(this.newData, this.model.get('newData'));
    }
    if (!this.haltUpdates && this.newData.length > 0 && this.chart) {
      this.setData(this.newData);
      this.updateChart();
      this.newData = [];
    }
  },

  parseData: function(usage, active, ix, bandwidth) {
    if (ix > 0 && !this.settings.has('dontFill')) {
      var gaps = this.fillGaps(bandwidth[ix - 1].timestamp, active.timestamp);
      usage.down.push.apply(usage.down, gaps);
      usage.up.push.apply(usage.up, gaps);
    }
    usage.down.push({x: parseInt(active.timestamp) * 1000, y: active.downTotal});
    usage.up.push({x: parseInt(active.timestamp) * 1000, y: -active.upTotal});
  },

  setData: function(bandwidth) {
    var usage = {down: [], up: []};
    bandwidth.sort(this.sortData);
    bandwidth.forEach(this.parseData.bind(this, usage));
    this.data.down = usage.down;
    this.data.up = usage.up;
  },
});
