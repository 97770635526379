'use strict';

var EditWifi = require('actionGroups/editWifi/EditWifi');
var i18n = require('i18next');
var configPage = require('lib/configPage');

var staticProps = {
  isCreational: true,
  creationalPage: configPage.NETWORKS,
};

// current wifi radio supports up to 8 SSIDs
var MAX_WIFIS = 8;

/**
 * "Create WiFi Network" configuration group.
 */
module.exports = EditWifi.extend({
  defaults: {
    isDirty: true,
  },

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return true;
  },

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return true;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.addWifi');
  },

  /**
   * Indicates if the user can create a new Wifi network.
   *
   * @param {config/ConfigModel} configOutline
   * @return {Boolean}
   */
  allowNew: function(configOutline) {
    var wifis = configOutline.filterGroups(function(group) {
      var type = group.get('type');
      return type === 'editWifi' || type === 'createWifi';
    });
    return wifis.length < MAX_WIFIS;
  },

  /**
   * Provides a user message indicating why a new Wifi network
   * cannot be created.
   *
   * @return {String}
   */
  disallowedNewReason: function() {
    return i18n.t('groups.addWifiLimit', {limit: MAX_WIFIS});
  },
}, staticProps);
