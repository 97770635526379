'use strict';

var _ = require('lodash');

/**
 * Datto SSO client (using JWT-style token).
 *
 * @class
 * @alias lib/DattoSsoJwt
 *
 * @todo convert this to a Bower component
 * @todo unit tests
 */
function DattoSsoJwt() {
}

DattoSsoJwt.prototype = {
  /**
   * The token ("jwt=...") returned by the SSO server.
   * @type {Boolean}
   * @private
   */
  _token: false,

  /**
   * The SSO server URL.
   * @type {String}
   */
  authURL: '',

  /**
   * The logout URL.
   * @type {String}
   */
  logoutURL: '',

  /**
   * The client name to pass along to the SSO service.
   * @type {String}
   */
  clientName: '',

  /**
   * Checks for token in URL. The presence of the token indicates the user is
   * returning from SSO and has been authenticated.
   *
   * @return {Boolean}
   */
  isAuthenticated: function() {
    var self = this;

    _.find(window.location.search.slice(1).split('&'), function(param) {
      if (param.indexOf('jwt') === 0) {
        self._token = param;
        return true;
      }
    });

    if (sessionStorage.getItem('token')) {
      self._token = 'jwt=' + sessionStorage.getItem('token');
      return true;
    }

    return (this._token !== false);
  },

  /**
   * Redirects the user to the SSO server for authorization.
   *
   * @param {String} returnTo
   *   The full URI the user will be returned to after successfully
   *   authenticating.
   */
  login: function(returnTo) {
    window.location = this.authURL +
      '/jwt/authorize?return_to=' + encodeURIComponent(returnTo) +
      '&client=' + encodeURIComponent(this.clientName);
  },

  /**
   * Redirects the user to the portal server to be logged out.
   *
   * @param {String} returnTo
   */
  logout: function() {
    window.location = this.logoutURL;
  },

  /**
   * Gets the token value provided by the SSO server.
   *
   * @return {String}
   */
  getToken: function() {
    return this._token.substring(4);
  },

  /**
   * Removes the token parameter from the URL.
   */
  cleanUrl: function() {
    var url = window.location.toString();
    var token = this._token;

    if (window.location.search.split('&').length === 1) {
      token = '?' + token;
    }

    url = url.replace(token, '');

    if (this._supportsHistory()) {
      // change URL without reloading the page
      window.history.replaceState({}, document.title, url);
    } else {
      window.location.replace(url);
    }
  },

  /**
   * Checks if the browser supports the HTML5 history API.
   *
   * @private
   * @return {Boolean}
   */
  _supportsHistory: function() {
    return !!(window.history && history.pushState);
  },
};

module.exports = DattoSsoJwt;
