'use strict';

var Backbone = require('backbone');
var i18n = require('i18next');
var GroupAlertView = require('manage/edit/config/group/groupAlerts/GroupAlertView');
var Radio = require('backbone.radio');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');

var staticProps = {
  lanSegregationAlertId: 'lanSegregation',
};

/**
 * Custom GroupAlertView for the "LAN Segregation" warning, which contains a
 * button to navigate to the Security page.
 */
module.exports = GroupAlertView.extend({
  className: 'help-block help-tip has-icon',

  events: {
    'click a': 'navigateToSecurity',
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    this.mergeOptions(options, ['deviceMac', 'isEnabled']);

    this.model = new Backbone.Model({
      id: staticProps.lanSegregationAlertId,
      msg: this.getLanSegregationMessage(),
    });
  },

  /**
   * @param {Event} event
   */
  navigateToSecurity: function(event) {
    // navigate within the app (using code below) instead of a full page load
    event.preventDefault();

    deviceConfigChannel.request('goto:group', 'lanIsolation');
  },

  /**
   * Gets the LAN Segregation message.
   *
   * @return {String}
   */
  getLanSegregationMessage: function() {
    var href = deviceConfigChannel.request('get:group:url', 'lanIsolation');
    var link = '<a href="' + href + '" class="link-inline">' +
      i18n.t('groups.lanIsolation') + '</a>';

    if (this.isEnabled) {
      return i18n.t('actionLanIsolationGlobal.newLanNoticeEnabled', {link: link});
    }
    return i18n.t('actionLanIsolationGlobal.newLanNoticeDisabled', {link: link});
  },
}, staticProps);
