'use strict';

var i18n = require('i18next');
var twig = require('twig').twig;
var tpl = require('components/usageTopApps/key.html');
var layer7Labels = require('lib/tplHelpers/layer7Labels');
var BaseKeyView = require('components/usageShared/BaseKeyView');

/**
 * Renders the top applications pie chart
 */
module.exports = BaseKeyView.extend({

  /**
   * @name #model
   * @type {components/usageTopApps/TopAppsModel}
   */

  template: twig({data: tpl}),

  /**
   * Determines the display name for a given app
   *
   * @param {String} name
   * @returns {String}
   */
  getLabelForName: function(name) {
    if (name === 'other') {
      return i18n.t('usageTopApps.other');
    }

    var label = layer7Labels.getAppLabel(name);
    if (!label) {
      return i18n.t('layer7.unknownAppName', {name: name});
    }
    return label;
  },

  /**
   * Determines the display description for a given app
   *
   * @param {String} name
   * @param {Number} type
   * @returns {String}
   */
  getLabelForType: function(name, type) {
    if (name === 'other') {
      return '';
    }

    var label = layer7Labels.getTypeLabel(type);
    if (!label) {
      return i18n.t('layer7.unknownAppType');
    }
    return label;
  },
});
