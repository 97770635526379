'use strict';

var _ = require('lodash');
var Backbone = require('backbone');
var ReadOnly = require('backbone.readonly');
var nestedModel = require('lib/mixins/nestedModel');

/**
 * Container for models that represent various firewall configuration elements
 *
 * This is basically a repeat of the DeviceConfiguration model's architecture,
 * but used when we're down inside the "firewall" portion of the config blob
 */
module.exports = ReadOnly.Model.extend(nestedModel).extend({
  /**
   * @member {Object} lib/models/Firewall#attributes
   * @property {ReadOnly.Collection} redirect
   *  Port forwarding (redirect) rules for the firewall
   */

  /**
   * Registry of attribute types.
   *
   * @private
   * @type {Object}
   */
  attributeTypes: {
    redirect: ReadOnly.Collection,
    dmz: ReadOnly.Model,
    oneoneNat: ReadOnly.Collection,
    oneManyNat: ReadOnly.Collection,
    advancedNat: ReadOnly.Collection,
    rule: ReadOnly.Collection,
    vlanRouting: ReadOnly.Model,
    networkObjects: ReadOnly.Collection,
    traffic: ReadOnly.Collection,
  },

  /**
   * The parent model will perform a "set" with the entire firewall portion of
   * the config blob. Handle creation of any nested models here that we need
   *
   * @param {Object} attributes
   * @param {Object} options
   * @returns {*}
   */
  set: function(attributes, options) {
    var self = this;
    var attrs = attributes || {};

    _.each(this.attributeTypes, function(AttributeType, attrName) {
      if (self.has(attrName)) {
        self.get(attrName).set(attrs[attrName], options);
        delete attrs[attrName];
      } else if (!attrs[attrName] || attrs[attrName].constructor !== AttributeType) {
        attrs[attrName] = new AttributeType(attrs[attrName], options);
      }
    });

    return Backbone.Model.prototype.set.call(this, attrs, options);
  },

});
