'use strict';

var _ = require('lodash');
var BaseGroup = require('config/Group');
var i18n = require('i18next');
var RenderPositions = require('config/RenderPositions');

/**
 * "Manage Network Object" configuration group.
 */
module.exports = BaseGroup.extend({
  actionList: [
    {
      name: 'networkObjectDescription',
      position: RenderPositions.header,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: false,
    },
    {
      name: 'editNetworkObject',
      position: RenderPositions.full,
      required: true,
      renderTitle: false,
      renderStatic: true,
      doubleWide: true,
    },
  ],
  deleteTaskName: 'networkObject.deleteNetworkObject',

  /**
   * @return {Boolean}
   */
  isRequired: function() {
    return false;
  },

  /**
   * @return {String}
   */
  title: function() {
    return i18n.t('groups.networkObject');
  },

  /**
   * @return {String}
   */
  description: function() {
    var networkObjects = this.deviceConfig.get('firewall').get('networkObjects');
    var id = this.get('typeId');
    return (!_.isUndefined(id) && !_.isUndefined(networkObjects.get(id))) ?
      networkObjects.get(id).get('description') :
      '';
  },

  /**
   * Request a callback when the description of this group changes
   *
   * @param {Backbone.Events} listener
   * @param {Function} callback
   */
  onDescriptionChanged: function(listener, callback) {
    var networkObjects = this.deviceConfig.get('firewall').get('networkObjects');
    var id = this.get('typeId');
    if (!_.isUndefined(id) && !_.isUndefined(networkObjects.get(id))) {
      listener.listenTo(networkObjects.get(id), 'change:description', callback);
    }
  },
});
