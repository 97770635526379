'use strict';

var Backbone = require('backbone');
var PriorityModel = require('actions/alertConfiguration/priority/PriorityModel');

module.exports = Backbone.Collection.extend({
  model: PriorityModel,

  comparator: function(a, b) {
    return b.id - a.id;
  },

  initialize: function(attributes, options) {
    this.set([
      {'id': 4, 'priority': 'critical', 'enabled': true},
      {'id': 3, 'priority': 'major', 'enabled': true},
      {'id': 2, 'priority': 'minor', 'enabled': true},
      {'id': 1, 'priority': 'info', 'enabled': true},
    ]);
  },
});

