'use strict';

/**
 * Object referencing available render positions for each action in a group.
 */
module.exports = {
  header: 'header',
  full: 'full',
  first: 'first',
  middle: 'middle',
  last: 'last',
  none: 'none',
};
