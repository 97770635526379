'use strict';

var i18n = require('i18next');
var EditDhcpPool = require('actions/dhcpPool/EditDhcpPool');
var FormView = require('actions/dhcpPool/FormView');
var StaticView = require('actions/dhcpPool/StaticView');

/**
 * Action configuration for the DHCP Pool action component.
 */
module.exports = {
  title: i18n.t('actionDhcp.title'),
  Model: EditDhcpPool,
  FormView: FormView,
  TextView: StaticView,
  dependsOn: ['lanAddress'],
};
