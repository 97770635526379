'use strict';

var _ = require('lodash');
var ActionItem = require('actions/shared/ActionItem');
var i18n = require('i18next');

/**
 * Edit model for DNA Description
 */
module.exports = ActionItem.extend({
  /**
   * @property {string} description
   *   DNA router description
   */

  _snapshotAttributes: ['description'],

  /**
   * @return {Boolean}
   */
  isNew: function() {
    return false; // there will always be a description!
  },

  /**
   * Parse response for Hostname attribute.
   *
   * @param {Object} resp
   * @param {Object} options
   * @return {Object}
   */
  parse: function(resp, options) {
    if (options && options.fromConfig === true) {
      return {
        description: this.deviceConfig.get('router').get('description'),
      };
    }

    return resp;
  },

  reparseConfigTriggers: [
    {
      getDispatcher: function(config) {
        return config.get('router');
      },
      events: 'change:description',
    },
  ],

  /**
   * @return {Object}
   */
  getTask: function() {
    if (this.getSnapshotDiff() === false) {
      return;
    }

    var data = {description: this.get('description')};

    return {
      name: 'router.setDnaDescription',
      data: data,
    };
  },

  /**
   * @param {Object} attrs
   * @param {Object} options
   * @return {Object|undefined}
   */
  validate: function(attrs, options) {
    var errors = {};

    if (!_.isUndefined(attrs.description)) {
      if (_.isEmpty(attrs.description)) {
        errors.description = i18n.t('dnaDescription.badDescription');
      } else if (!_.inRange(attrs.description.length, 1, 256)) {
        errors.description = i18n.t('dnaDescription.badLength');
      }
    }

    if (_.size(errors) > 0) {
      return errors;
    }
  },
});
