'use strict';

var _ = require('lodash');
var console2 = require('lib/Console');
var ConfigItem = require('lib/models/ConfigItem');

/**
 * Base View-Model for action items. An "action" item is a group of fields
 * that achieve a configuration goal. For example, a port-forwarding action
 * consists of an incoming port, protocol and destination IP and port.
 *
 * This class is meant to be extended and must not be used directly.
 *
 * Subclasses should define a "reparseConfigTriggers" property to
 * enable automated re-synchronization (via a fetch -> parse) when
 * external changes occur in relevant portions of the device
 * configuration model (i.e. due to receiving an updated config
 * from the server). This model will dispatch an event of type
 * 'sync:fromConfig' after this re-sync occurs.
 *
 * @member {Array} #reparseConfigTriggers
 * @property {Function} getDispatcher
 *  function that takes DeviceConfiguration model as an argument,
 *  and returns a specific object on which to listen for events
 * @property {String} events
 *  space-separated names of events to listen for
 */
module.exports = ConfigItem.extend({
  /**
   * @member {Object} actions/shared/ActionItem#attributes
   * @property {String} actionTitle
   *   The (translated) more descriptive action name/title.
   */

  constructor: function(attributes, options) {
    ConfigItem.call(this, attributes, options);

    if (_.isFunction(this.getOnOffState)) {
      this.listenTo(this, this.onOffStateChangeEvent, this.notifyOnOffChange);
    }
  },

  /**
   * Indicates whether or not an action produces a "task" to perform on a DNA.
   * In almost all cases, this will be true.
   *
   * @type {Boolean}
   */
  providesTask: true,

  /**
   *
   * A task entity has the following format:
   * @example
   * {
   *   name: 'the name of the task/action',
   *   data: {}
   * }
   *
   * If the action's state has not changed (use getSnapshotDiff() to
   * check), this method must return undefined.
   *
   * @method actions/shared/ActionItem#getTask
   * @return {Object|undefined}
   */

  /**
   * Retrieve a declared dependency by type.
   *
   * To declare dependencies to be auto-wired here, add
   * a 'dependsOn' array in the action's config.js file.
   *
   * @param {String} type
   * @return {actions/shared/ActionItem|null}
   */
  getDependency: function(type) {
    if (!this.dependencies) {
      return null;
    }

    return this.dependencies[type];
  },

  /**
   * Actions that contain an on/off toggle for a feature should
   * define a function for this property that takes no arguments
   * and returns a boolean based on the state of the toggle
   *
   * Either this or #toggledOnOffBy should be defined, if applicable,
   * but not both
   *
   * If you define this, and the on/off state is controlled via
   * attribute(s) other than "enabled", you must also override
   * onOffStateChangeEvent
   *
   * @type Function|null
   */
  getOnOffState: null,

  /**
   * Actions that contain an on/off toggle for a feature should
   * set this property to a string with the event name(s) that
   * correspond to a change in the value that will be returned
   * by getOnOffState()
   *
   * @default change:enabled
   */
  onOffStateChangeEvent: 'change:enabled',

  /**
   * Actions whose on/off toggle is contained in a different action should
   * indicate the id of that action by overriding this property
   *
   * Either this or #getOnOffState should be defined, if applicable,
   * but not both
   *
   * @type String|null
   */
  toggledOnOffBy: null,

  /**
   * Automatically convert an onOffStateChangeEvent event to a
   * onoff:toggled event that card parent views can handle
   * and send to the appropriate places
   */
  notifyOnOffChange: function() {
    if (!_.isFunction(this.getOnOffState)) {
      console2.log('error',
        'Action model for ' + this.get('actionTitle') + ' cannot notifyOnOffChange()' +
        ' because it does not implement a getOnOffState() method'
      );
      return;
    }

    this.trigger('onoff:toggled', this);
  },

});
