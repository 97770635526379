'use strict';

var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var ConfigCardItemView = require('manage/edit/toolbox/ConfigCardItemView');
var tpl = require('manage/edit/toolbox/wrapper.html');
var Radio = require('backbone.radio');
var deviceConfigChannel = Radio.channel('deviceConfigChannel');

/**
 * Collection View to render the list of active configuration groups.
 *
 * Note, if this View gets more complicated, break it out into its own file.
 */
var ToolboxListView = Marionette.CollectionView.extend({
  /**
   * @type {manage/edit/toolbox/ConfigCardItemView}
   */
  tagName: 'ul',
  className: 'config-nav',
  childView: ConfigCardItemView,
});

/**
 * Renders a list of configuration groups (and, by default, their actions).
 *
 * Note, this View renders a tree structure, which means a slew of nested Views
 * are used to achieve the "look". Due to deep nesting, we don't rely on normal
 * event bubbling (events only bubble to immediate parents). Instead, we rely
 * on Backbone.Radio to provide a channel to fire events from any View within
 * the tree structure. The one caveat is that because this View is reusable, we
 * can't rely on a global Radio channel. That is why this View requires a private
 * Radio channel to be passed in.
 */
module.exports = Marionette.View.extend({
  /**
   * @name manage/edit/toolbox/ToolboxView#collection
   * @type {config/Groups}
   */

  template: twig({data: tpl}),

  regions: {
    list: 'nav',
  },

  /**
   * @param {Object} options
   * @property {String} options.title
   * @property {Radio.Channel} options.channel
   *   A private Radio channel to communicate item selection events. For more
   *   info, see this classes classdoc.
   * @property {boolean} options.showActions
   *   a boolean to determine whether the group should show child actions or not
   * @property {config/ConfigModel} options.configOutline
   *   info about what currently exists to enforce resource limits
   *   required when options.showActions === false (i.e. the "new" toolbox)
   */
  initialize: function(options) {
    this.mergeOptions(options, ['title', 'channel', 'showActions', 'configOutline']);
    this.deviceStatus = deviceConfigChannel.request('get:status');
    this.deviceConfig = deviceConfigChannel.request('get:config');
  },

  /**
   * @return {Object}
   */
  serializeData: function() {
    return {
      title: this.title,
    };
  },

  onRender: function() {
    this.showChildView('list', new ToolboxListView({
      collection: this.collection,
      childViewOptions: {
        showActions: this.showActions,
        tagName: 'li',
        className: 'config-link--parent',
        channel: this.channel,
        deviceStatus: this.deviceStatus,
        deviceConfig: this.deviceConfig,
        configOutline: this.configOutline,
      },
    }));
  },
});
