'use strict';

var Backbone = require('backbone');
var i18n = require('i18next');
var GroupAlertView = require('manage/edit/config/group/groupAlerts/GroupAlertView');

var staticProps = {
  networkDisruptionAlertId: 'networkDisruptionAlert',
};

/**
 * Custom GroupAlertView for the warning that changes will disrupt network connectivity.
 * Extend this View to add more types of alerts that could potentially down a network or a network service.
 */
module.exports = GroupAlertView.extend({

  initialize: function() {
    this.model = new Backbone.Model({
      id: staticProps.networkDisruptionAlertId,
      msg: i18n.t('configEdit.networkDisruption'),
    });
  },
}, staticProps);
