'use strict';

var _ = require('lodash');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var tplHelpers = require('lib/tplHelpers');
var Ip = require('lib/Ip');
var rangeTpl = require('actions/staticAddress/rangebox.html');
var afPickerTpl = require('actions/staticAddress/af-picker.html');

// load range template to be used in address template
twig({id: 'rangeBox', data: rangeTpl});
twig({id: 'afPicker', data: afPickerTpl});

/**
 * Base Address View (for the VLAN subnet component).
 *
 * As its name implies, this model should be treated as an "abstract" class
 * and not used directly.
 *
 * Makes the following context variants available for i18n:
 *   i18n.t('actionStaticAddress.address', {context: 'network'})
 *   i18n.t('actionStaticAddress.address', {context: 'router'})
 *   i18n.t('actionStaticAddress.address', {context: 'client'})
 *   i18n.t('actionStaticAddress.ipv4Example', {context: 'network'})
 *   i18n.t('actionStaticAddress.ipv4Example', {context: 'router'})
 *   i18n.t('actionStaticAddress.ipv4Example', {context: 'client'})
 *   i18n.t('actionStaticAddress.maskText', {context: 'ipv4'})
 *   i18n.t('actionStaticAddress.maskText', {context: 'ipv6'})
 * NOTE: Do not remove the above block, it keeps the i18next scanner
 * from cleaning these variants out of the translations file.
 */
module.exports = Marionette.View.extend({
  /**
   * @name actions/staticAddress/address/AbstractAddressView#model
   * @type {actions/vlan/Address}
   */

  templateContext: tplHelpers,

  ui: {
    address: '[name="address"]',
    mask: '[name="mask"]',
    startRange: '.range-box .pull-left',
    endRange: '.range-box .pull-right',
    rangeBox: '.range-box',
    ipWarning: '.ip-warning',
    autoClearedWarning: '.auto-cleared-warning',
    zeroHint: '.zero-hint',
    formGroup: '.form-group',
  },

  events: {
    'change select': 'checkAddress',
    'blur input': 'checkAddress',
  },

  modelEvents: {
    'invalid': 'flagAddressError updateRange onError',
    'change:address': 'onChangeAddress updateRange',
    'change:size': 'onChangeMask updateRange',
    'change:af': 'render',
    'change:ipconflict': 'onIpConflictChange',
    'updateAddress': 'onUpdateAddress',
  },

  /**
   * @return {Array}
   */
  subnetMaskIpv6: function() {
    var mask = [];
    for (var i = 1; i < 129; i++) {
      mask.push({size: i, mask: ''});
    }

    return mask;
  },

  /**
   * @param {Object} options
   */
  initialize: function(options) {
    // set the models newAddressHasError in case user is loading page directly
    if (!options.test) {
      this.model.set('newAddressHasError', false);
    }
  },

  onRender: function() {
    var ipType = this.model.get('af');

    if (!_.isUndefined(this.model.get('vpn')) && this.model.get('vpn') === true) {
      this.ui.zeroHint.removeClass('hidden');
    }

    if (this.model.get('ipconflict')) {
      this.ui.formGroup.addClass('has-error');
      this.ui.autoClearedWarning.removeClass('hidden');
    }

    if (ipType === 'ipv4') {
      this.$el.removeClass('type-ipv6').addClass('type-ipv4');
    } else {
      this.$el.removeClass('type-ipv4').addClass('type-ipv6');
    }
  },

  onIpConflictChange: function() {
    if (this.model.get('ipconflict')) {
      this.ui.formGroup.addClass('has-error');
      this.ui.autoClearedWarning.removeClass('hidden');
    } else {
      this.ui.formGroup.removeClass('has-error');
      this.ui.autoClearedWarning.addClass('hidden');
    }
  },

  /**
   * Updates the "address" input field when it changes in the model.
   *
   * @listens this.model.attributes.address~change:address
   * @param {actions/vlan/AbstractEditVlan} model
   * @param {String|Number} address
   * @param {Object} options
   */
  onChangeAddress: function(model, address, options) {
    this.ui.address.val(address);
    this.checkForNonStandardIp();
  },

  /**
   * Updates the "mask" select field when it changes in the model.
   *
   * @listens this.model.attributes.address~change:size
   * @param {actions/vlan/AbstractEditVlan} model
   * @param {String|Number} mask
   * @param {Object} options
   */
  onChangeMask: function(model, mask, options) {
    var selected;

    this.ui.mask.val(mask);

    selected = this.ui.mask.find(':selected');

    if (selected.length === 1 && mask !== 0) {
      this.ui.mask.find('option[value="0"]').remove();
    }

    this.checkForNonStandardIp();
  },

  /**
   * Blur event for address-related input fields.
   *
   * @param {Event} ev
   * @return {Boolean}
   *   Returns true if address is valid, false otherwise.
   */
  checkAddress: function(ev) {
    var name = ev.currentTarget.name;
    var fieldValue = ev.currentTarget.value;
    var data = {};
    var isValid;
    var prevAddress;

    this.ui.address.bs3ui('clearFieldError');
    this.ui.mask.bs3ui('clearFieldError');

    if (name === 'address') {
      if (this.model.get('newAddressHasError') === true) {
        prevAddress = this.model.get('address');
      }
      data.address = fieldValue;
    } else if (name === 'mask') {
      data.size = parseInt(fieldValue);
    }

    isValid = this.model.set(data, {commit: true});

    if (name === 'address' &&
        !_.isUndefined(prevAddress) &&
        prevAddress === data.address
    ) {
      // if user previously entered an incorrect value, entering the same
      // value from before the incorrect value will not trigger a change event
      this.updateRange();
    }

    // reset for next go-around
    this.model.set('newAddressHasError', false);

    return isValid;
  },

  /**
   * Default implementation of this does nothing. Subclasses should
   * implement this as needed to check for addresses that do not
   * follow best practices
   */
  checkForNonStandardIp: function() {},

  onUpdateAddress: function(newAddress) {
    this.model.set('address', newAddress);
    this.ui.address.value = newAddress;
  },

  /**
   * Updates the displayed IP range based on the users selections
   *
   * @param {actions/vlan/Address} model
   * @param {mixed} newVal
   */
  updateRange: function(model, newVal) {
    if (this.model.get('af') === 'ipv6') {
      return;
    }

    var ip;
    var network;
    var mask = this.model.get('size');
    var address = this.model.get('address');
    var errors = this.model.validate(
      {address: address, size: mask},
      {validateAll: false}
    );
    if (!_.isUndefined(errors) || this.model.get('newAddressHasError') === true) {
      this.ui.rangeBox.addClass('invisible');
      this.ui.startRange.text('0.0.0.0');
      this.ui.endRange.text('0.0.0.0');
    } else {
      // compose IP address into CIDR notation (e.g. xxx.xxx.xxx.xxx/xx)
      ip = address + '/' + mask;
      try {
        network = new Ip(ip, {type: this.model.get('af')});
        this.ui.startRange.text(network.network());
        this.ui.endRange.text(network.broadcast());
        this.ui.rangeBox.removeClass('invisible');
      } catch (e) {
        this.ui.rangeBox.addClass('invisible');
        this.ui.startRange.text('0.0.0.0');
        this.ui.endRange.text('0.0.0.0');
      }
    }
  },

  /**
   * If invalid address entered, set flag. Used to help with displaying (or
   * not displaying) the calculated network range.
   *
   * @param {actions/vlan/Address} model
   * @param {Object} error
   * @param {Object} options
   */
  flagAddressError: function(model, error, options) {
    if (!_.isUndefined(error.address)) {
      this.model.set('newAddressHasError', true);
    }
  },

  /**
   * Decorates the form field to indicate error.
   *
   * @param {actions/vlan/AbstractEditVlan} model
   * @param {Object} error
   * @param {Object} options
   */
  onError: function(model, error, options) {
    if (error.address) {
      this.ui.address.bs3ui('showFieldError', error.address);
    }

    if (error.size) {
      this.ui.mask.bs3ui('showFieldError', error.size);
    }
  },

});
