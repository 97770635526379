'use strict';
/**
 * List of global options available to the Application for production.
 * For documentation of what each of these options represent, please consult
 * appConfig.default.js
 */
module.exports = {
  apiAjaxUrl: '/api',
  apiAjaxWithCredentials: false,
  apiWebSocketUrl: 'ws://os3-portal.datto.lan',
  apiWebSocketPort: '8686',
  useWebsocket: false,
  debug: false,
  ssoClientName: 'dna.datto.com',
  ssoAuthUrl: 'https://auth.datto.com',
  ssoLogoutURL: 'https://portal.dattobackup.com/logout',
};
