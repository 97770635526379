'use strict';

var $ = require('jquery');
var Marionette = require('backbone.marionette');
var twig = require('twig').twig;
var layoutTpl = require('layouts/tos/tos-layout.html');
var Radio = require('backbone.radio');
var i18next = require('i18next');
var buttonSpinner = require('lib/buttonSpinner');

/**
 * Renders the Terms of Service interstitial page.
 *
 * If you are curious, "terms of service", "terms of use" and "terms and
 * conditions" are interchangeable.
 */
module.exports = Marionette.View.extend({
  template: twig({allowInlineIncludes: true, data: layoutTpl}),

  /**
   * Maps to the translation file to load. For example, the "tos" namespace
   * will load a file a called "tos.json". See i18next's docs for info.
   * @type {String}
   */
  i18nNamespace: 'tos',

  ui: {
    'acceptBtn': '.tos__accept',
  },

  events: {
    'click @ui.acceptBtn': 'onAccept',
  },

  onBeforeDestroy: function() {
    i18next.removeResourceBundle(i18next.language, this.i18nNamespace);
  },

  /**
   * @return {Object}
   */
  templateContext: function() {
    var user = Radio.channel('userChannel').request('get:user');

    return {
      displayName: user.get('displayName'),
    };
  },

  /**
   * Adds an i18next namespace to fetch the terms of use content.
   *
   * Note, naturally, the terms of use is quite long and to embed it with the
   * rest of our translations makes zero sense. i18next makes it really easy
   * to add additional namespaces at runtime.
   *
   * @return {jQuery.Deferred}
   */
  loadTranslations: function() {
    var deferred = $.Deferred();

    // Loading the namespace immediately triggers an ajax call to fetch the
    // new translation file.
    i18next.loadNamespaces(this.i18nNamespace, function(err, t) {
      if (err) {
        deferred.reject(err);
      } else {
        deferred.resolve();
      }
    });

    return deferred.promise();
  },

  /**
   * "click" callback for accept button.
   *
   * @fires tos:accepted
   * @param {Event} ev
   */
  onAccept: function(ev) {
    var self = this;

    buttonSpinner.showButtonSpinner(this.ui.acceptBtn);

    Radio.channel('apiChannel')
      .request('send', 'DNA.Portal.User.acceptTermsAndConditions')
      .done(function(resp) {
        self.trigger('tos:accepted');
      });
  },
});
