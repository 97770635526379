'use strict';

var i18n = require('i18next');
var EditAlertConfiguration = require('actions/alertConfiguration/EditAlertConfiguration');
var FormView = require('actions/alertConfiguration/FormView');
var StaticView = require('actions/alertConfiguration/StaticView');

module.exports = {
  title: i18n.t('alertConfiguration.title'),
  Model: EditAlertConfiguration,
  FormView: FormView,
  TextView: StaticView,
};
