'use strict';

var Marionette = require('backbone.marionette');
var tpl = require('actions/advancedNat/form.html');
var NatView = require('actions/advancedNat/FormNatView');
var Nat = require('actions/advancedNat/Nat');
var twig = require('twig').twig;
var ToggleFormOff = require('lib/behaviors/ToggleFormOff');

var NatsListView = Marionette.CollectionView.extend({
  childView: NatView,

  // wire child views to ToggleFormOff
  childViewTriggers: {
    'render': 'item:render',
    'onoff:status:change': 'item:onoff:status:change',
  },
});

module.exports = Marionette.View.extend({
  behaviors: [
    {
      behaviorClass: ToggleFormOff,
    },
  ],

  template: twig({data: tpl}),

  regions: {
    rules: '.rg-rules',
  },

  ui: {
    add: '.btn-add',
    rules: '.help-block',
  },

  events: {
    'click @ui.add': 'addRule',
  },

  initialize: function(attributes, options) {
    this.listenTo(this.model.get('items'), 'change:remove', this.initChildViews);
  },

  onRender: function() {
    this.initChildViews();
  },

  /**
   * Initializes the child Views.
   */
  initChildViews: function() {
    var view = new NatsListView({
      collection: this.model.get('items'),
    });

    this.showChildView('rules', view);
  },

  addRule: function() {
    var nat = new Nat({netObjs: this.model.get('netObjs')});
    var newRuleOrder = this.model.get('items').length + 1;
    nat.set('order', newRuleOrder.toString(), {silent: true});
    this.model.get('items').add(nat);
    this.initChildViews();
  },
});
