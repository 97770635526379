'use strict';

var _ = require('lodash');
var Memento = require('backbone.memento');

/**
 * Properties and methods for allowing a Collection to take a snapshot of itself.
 *
 * Allows a collection to track which models have been changed, added, or removed
 * since the snapshot was taken.
 *
 * @example
 * var People = Backbone.Collection.extend(snapshotMixin).extend({
 *   ...
 * });
 *
 * var employees = new People([{name: 'Jane'}, {name: 'Tom'}]);
 * employees.takeSnapshot();
 * employees.at(0).set('name', 'Janice');
 * employees.add({name: 'Sanjay'});
 * employees.getSnapshotDiff(); //{0: {name: 'Janice'}, 2: {name: 'Sanjay'}}
 * employees.getFromSnapshot(0);  //{name: 'Jane'}
 *
 * @mixin lib/mixins/snapshotCollection
 */

module.exports = {
  /**
   * Stack structure for collection state. Primarily used for tracking collection
   * changes against an initial snapshot.
   *
   * @member {Backbone.Memento} lib/mixins/snapshotCollection#snapshot
   */

  /**
   * Takes a snapshot of the current collection's contents.
   *
   * Note, this method bastardizes Backbone.Memento a little. That plugin
   * allows you to store any number of state changes on a stack. In this
   * context, taking a snapshot resets the stack.
   */
  takeSnapshot: function() {
    // Snapshot object is always reinitialized when a snapshot is taken. This
    // is by design.
    this.snapshot = new Memento(this, {parse: true});

    this.snapshot.store();
  },

  /**
   * Replaces the collection's content with that of the last snapshot. If a snapshot
   * has not been taken, the collection is unchanged.
   */
  applySnapshot: function() {
    if (_.isUndefined(this.snapshot)) {
      return;
    }

    this.snapshot.restart();
    this.takeSnapshot();
  },

  /**
   * Returns a diff of indices that have changed since the last-taken
   * snapshot, or False if no indices have changed content.
   *
   * The diff will contain the toJSON() representation of the current model
   * at each modified index. If an index existed in the snapshot, but
   * no longer does, that index will be included in the diff with a value
   * of undefined.
   *
   * Note that adding or removing an item from the middle of the collection
   * will result in all higher indices appearing in the diff as modified,
   * due to collection contents shifting.
   *
   * @return {Object|false}
   */
  getSnapshotDiff: function() {
    var changes;

    if (_.has(this, 'snapshot')) {
      changes = this.snapshot.changes();
    }

    if (!_.isUndefined(changes) && !_.isEmpty(changes)) {
      return changes;
    }

    return false;
  },

  /**
   * Gets the toJSON() representation of the model that existed at a
   * single index in the last-taken snapshot.
   *
   * @param {Number} index
   * @return {mixed}
   */
  getFromSnapshot: function(index) {
    var previous;

    if (_.has(this, 'snapshot')) {
      previous = this.snapshot.previousState();
      if (!_.isUndefined(previous)) {
        return previous[index];
      }
    }
  },

  /**
   * Gets the last-taken snapshot.
   *
   * @return {Object}
   */
  getLastSnapshot: function() {
    var previous;

    if (_.isUndefined(this.snapshot)) {
      return [];
    }

    previous = this.snapshot.previousState();

    if (_.isUndefined(previous)) {
      return [];
    }

    return previous;
  },
};
