'use strict';

var Backbone = require('backbone');
var i18n = require('i18next');
var GroupAlertView = require('manage/edit/config/group/groupAlerts/GroupAlertView');

var staticProps = {
  alertId: 'siteToSiteVPNPendingDisable',
};

/**
 * Custom GroupAlertView for the warning that changes will disable Site-to-Site VPNs on the receiver DNA.
 */
module.exports = GroupAlertView.extend({

  initialize: function() {
    this.model = new Backbone.Model({
      id: staticProps.alertId,
      msg: i18n.t('configEdit.siteToSiteVPNPendingDisableAlert'),
    });
  },
}, staticProps);
