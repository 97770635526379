'use strict';

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var i18n = require('i18next');
var Modal = require('components/modal/ModalView');
var ModalChildView = require('components/modal/GenericChildView');
var buttonSpinner = require('lib/buttonSpinner');
var adjacentError = require('lib/mixins/adjacentStatusMessage');

/**
 * Renders the "Factory Reset" button
 */
module.exports = Marionette.View.extend(adjacentError).extend({
  template: false,
  tagName: 'button',
  className: 'btn btn-default btn-xs',

  // make these replaceable for testability
  location: window.location,
  buttonSpinner: buttonSpinner,

  events: {
    'click': 'showModal',
  },

  /**
   * @param {Object} attributes
   * @param {Object} options
   */
  initialize: function(attributes, options) {
    this.triggerFactoryReset = this.triggerFactoryReset.bind(this);
  },

  showModal: function() {
    var modal = this.buildModal();
    modal.render();
  },

  buildModal: function() {
    var model = Backbone.Model.new({
      callback: this.triggerFactoryReset,
      title: i18n.t('resetDna.modalTitle'),
      childView: new ModalChildView({
        content: '<p>' + i18n.t('resetDna.modalBody') + '</p>',
      }),
      actionButtonText: i18n.t('resetDna.resetButton'),
      closeText: i18n.t('configEdit.cancel'),
    });
    return new Modal({model: model});
  },

  onBeforeRender: function() {
    this.el.setAttribute('data-tag', 'do-factory-reset');
  },

  onRender: function() {
    this.$el.text(i18n.t('dnaDetails.factoryReset'));
  },

  triggerFactoryReset: function() {
    var self = this;

    this.hideError();
    this.buttonSpinner.showButtonSpinner(this.$el);
    return this.model.triggerFactoryReset()
      .always(function() {
        self.buttonSpinner.hideButtonSpinner(self.$el);
      })
      .done(function() {
        // if we want this to do something smarter than a full page reload, we'd need to
        // - make sure all config cards sync with external changes (see DNA-2265)
        // - change factory reset API call to return the new config (& config outline)
        //    > apply those to their respective models here
        // - change factory reset API call to return the job ID for the DNA
        //    > use that to trigger an immediate "pending config changes" banner (see DNA-2320)
        self.location.reload();
      })
      .fail(function() {
        self.showError(i18n.t('dnaDetails.factoryResetFailed'));
      });
  },

});
