'use strict';

var CreateSiteVpnGroup = require('actionGroups/createSiteVpnGroup/CreateSiteVpnGroup');
var FormView = require('actionGroups/editSiteVpn/EditSiteVpnView');

module.exports = {
  Model: CreateSiteVpnGroup,

  // The GroupView is currently shared with the editSiteVpn card. It will
  // need to be split up if behavior specific to creating or editing is added.
  GroupView: FormView,
};
